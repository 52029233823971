export enum LogLevel {
    VERBOSE,
    INFO,
    WARNING,
    ERROR
}

// export const padRight = (str: string, length: number): string => {
//     if (str.length >= length) {
//         return str;
//     }
//     const spacesToAdd = length - str.length;
//     return str + " ".repeat(spacesToAdd);
// };

// export const padLeft = (str: string, len: number): string => {
//     if (str.length >= len) {
//         return str;
//     }
//     return " ".repeat(len - str.length) + str;
// };

export const padCenter = (str: string, length: number): string => {
    const diff = length - str.length;
    if (diff <= 0) {
        return str;
    }

    const leftPadding = Math.floor(diff / 2);
    const rightPadding = diff - leftPadding;
    const paddedStr = " ".repeat(leftPadding) + str + " ".repeat(rightPadding);
    return paddedStr;
};

{
    "register__terms_label_1": "Ao se registrar em PvPz.io & Second Monitor Games, você concorda com nossos",
    "register__terms_label_2": "Termos de Serviço",
    "register__terms_label_3": "Política de Privacidade",
    "register__promo_label": "Está OK para Second Monitor Games me enviar emails sobre promoções e atualizações de jogos",
    "adblock_detected_failed_to_insure": "Algo deu errado ao segurar esse item. Por favor, certifique-se de que seu adblock está desativado!",
    "ability_type_active": "Ativo",
    "ability_type_passive": "Passivo",
    "change_username__back": "Voltar",
    "change_username__cant_be_empty_error": "O nome de usuário não pode ficar em branco",
    "change_username__current_prompt": "Seu nome de usuário atual",
    "change_username__enter_new": "Digite um novo nome de usuário",
    "change_username__header": "Nome de usuário",
    "change_username__save": "Salvar",
    "change_username__too_long_error": "O nome de usuário não pode ter mais de 20 caracteres.",
    "character_already_on_run_menu__abondon_run_button": "Abandonar Execução",
    "character_already_on_run_menu__back_to_main_menu_button": "Voltar",
    "character_already_on_run_menu__bolded_header": "Seu personagem já está em uma execução!",
    "character_already_on_run_menu__paragraph_one": "Seu personagem ainda não retornou de sua execução anterior. Se você iniciar uma nova execução agora, todos os seus itens da execução anterior (exceto os itens segurados) serão perdidos!",
    "character_already_on_run_menu__paragraph_two": "Se você acredita que isso é um erro, ou se deseja abandonar intencionalmente sua execução, você pode abandoná-la forçadamente usando o botão abaixo.",
    "character_already_on_run_menu__title": "ATENÇÃO!",
    "connection_error__back_to_main_menu": "Voltar ao menu principal",
    "connection_error__error_reason": "Motivo do erro",
    "connection_error__oof": "Ops",
    "connection_error__server_error_header": "Erro de conexão com o servidor!",
    "death_menu__back_to_main_menu_button": "Voltar ao Menu",
    "death_menu__gear_lost": "Itens Perdidos",
    "death_menu__gold_lost": "Ouro Perdido",
    "death_menu__insured_items": "Itens Segurados",
    "death_menu__loot_lost": "Saque Perdido",
    "death_menu__nothing_insured": "Nenhum item foi segurado!",
    "death_menu__saving_your_run_button": "Salvando...",
    "death_menu__spectate_button": "Espectar o Assassino",
    "death_menu__survived_for": "Sobreviveu Por",
    "death_menu__title": "R.I.P",
    "death_menu__weapons_lost": "Armas Perdidas",
    "death_menu__your_killer": "Seu Assassino",
    "extracted_menu__back_to_main_menu_button": "Voltar ao Menu",
    "extracted_menu__gold_looted": "Ouro Saqueado",
    "extracted_menu__loot_acquired": "Loot Obtido",
    "extracted_menu__run_duration": "Duração da Execução",
    "extracted_menu__title": "Extraído!",
    "game_hud__cooldown": "Tempo de Recarga",
    "game_hud__find_an_extraction_point": "Encontre um ponto de extração!",
    "game_hud__inventory_extract_prompt": "Você precisa extrair para usar esse loot!",
    "game_hud__inventory_hud": "Abrir Inventário",
    "game_hud__leaderboard": "Classificação",
    "game_hud__leaderboard_current_world": "Mundo Atual",
    "game_hud__leaderboard_hide": "Esconder",
    "game_hud__leaderboard_show": "Mostrar",
    "game_hud__looted_container_close": "Fechar",
    "game_hud__spectating": "Observando",
    "game_hud__time_left_to_extract": "restantes para extrair",
    "game_hud__your_inventory_header": "Seu Inventário!",
    "game_hud__your_inventory_hide": "para fechar",
    "game_transition__finding_match": "Procurando Partida",
    "hotkey_interact": "E",
    "hotkey_open_inventory": "i",
    "hotkey_toggle_leaderboard": "L",
    "how_to_play__ability_one_key": "Habilidade #1",
    "how_to_play__ability_three_key": "Habilidade #3",
    "how_to_play__ability_two_key": "Habilidade #2",
    "how_to_play__back_to_main_menu_button": "Voltar",
    "how_to_play__chat_key": "Chat",
    "how_to_play__extraction": "Encontre um ponto de extração!",
    "how_to_play__inventory_key": "Inventário",
    "how_to_play__leaderboard_key": "Classificação",
    "how_to_play__mouse_aim_controls": ": Mirar",
    "how_to_play__mouse_shoot_controls": ": Atirar",
    "how_to_play__move_down_key": "Mover para Baixo",
    "how_to_play__move_left_key": "Mover para a Esquerda",
    "how_to_play__move_right_key": "Mover para a Direita",
    "how_to_play__move_up_key": "Mover para Cima",
    "how_to_play__small_paragraph_one": "Se você não conseguir extrair a tempo, perderá todos os itens que trouxe consigo (a menos que tenha seguro!) e todos os itens que saqueou em sua execução. A extração é muito importante. Você deve EXTRAIR com seu loot para mantê-lo e usá-lo.",
    "how_to_play__small_paragraph_three": "Mate ou seja morto, que o melhor aventureiro vença!",
    "how_to_play__small_paragraph_two": " ",
    "how_to_play__test_keys_header": "Teste as teclas!",
    "how_to_play__title": "PvPz.io",
    "how_to_play__top_big_paragraph": "Mate outros jogadores, encontre loot e extraia!",
    "how_to_play__weapon_one_key": "Arma #1",
    "how_to_play__weapon_two_key": "Arma #2",
    "in_game__extracting": "Extraindo...",
    "in_game__time_remaining": "Tempo Restante...",
    "in_game__warning_time_almost_up": "Encontre um ponto de extração!",
    "insured_item_nothing": "Nada",
    "item__AG_AmuletOfCursedFate__gameplay": "+10% de Velocidade de Ataque | RESSUSCITA com 40% de Vida quando você morre (não acumula com outros itens de ressurreição)",
    "item__AG_AmuletOfCursedFate__lore": "Um amuleto maligno que distorce destinos e traz presságios sombrios.",
    "item__AG_AmuletOfCursedFate__name": "Amuleto do Destino Amaldiçoado",
    "item__AG_MagicBoots__name": "Botas Mágicas",
    "item__AG_MagicBoots__gameplay": "Fica invisível por 9 segundos ao ser usado (2 usos) | Sua saúde se regenera o tempo todo | Extração 10% mais lenta.",
    "item__AG_MagicBoots__lore": "Essas botas não fazem barulho e revigoram quem as usa.",
    "item__AG_Boros__gameplay": "Fique invisível por 7 segundos ao usar | -12% de Dano a NPCs | +12% de Roubo de Vida de todo o dano que você causa aos jogadores",
    "item__AG_Boros__lore": "Dizem que sua gema é criada a partir do olho de um metamorfo.",
    "item__AG_Boros__name": "Boros",
    "item__AG_DarkPustulesRing__gameplay": "Receba 5% menos dano ao extrair | Extraia 5% mais rápido",
    "item__AG_DarkPustulesRing__lore": "Este anel pode ser usado para obter magicamente um dia inteiro de um líquido doce e escuro.",
    "item__AG_DarkPustulesRing__name": "Anel de Pústulas Negras",
    "item__AG_DreamWeaversEmbrace__gameplay": "Fique invisível por 4 segundos ao usar | -5% de Dano a Jogadores | Extraia 10% mais rápido | INCOGNITO: Esconde seu loot e mensagens de localização do resto do mundo",
    "item__AG_DreamWeaversEmbrace__lore": "As pedras neste anel mudam de cor ao redor de áreas onde o véu é fino.",
    "item__AG_DreamWeaversEmbrace__name": "Abraço do Tecelão dos Sonhos",
    "item__AG_ForestsHaven__gameplay": "Cura 20HP ao ser usado | Extraia 10% mais rápido | INCOGNITO: Esconde seu loot e mensagens de localização do resto do mundo | +20% Etéreo",
    "item__AG_ForestsHaven__lore": "Para aqueles que usam este anel, procurem o bosque de árvores escondido entre os sonhos.",
    "item__AG_ForestsHaven__name": "Refúgio da Floresta",
    "item__AG_MysteriousRing__gameplay": "Adiciona 30 segundos ao seu temporizador de extração ao ser usado | +10 de Vida Máxima | +7% de Velocidade de Movimento ao caminhar na água",
    "item__AG_MysteriousRing__lore": "???",
    "item__AG_MysteriousRing__name": "Anel Misterioso",
    "item__AG_ObsidianEcho__gameplay": "EXTRAI ao ser usado, não importa onde você esteja | +12% de Velocidade de Movimento | RESSUSCITA com 70% de Vida quando você morre (não acumula com outros itens de ressurreição) | -30 de Vida Máxima | Este item é destruído quando totalmente usado",
    "item__AG_ObsidianEcho__lore": "Forjado a partir de um fragmento da lâmina de Malachi. Se você ouvir atentamente, pode ouvir o eco das almas daqueles que ele matou",
    "item__AG_ObsidianEcho__name": "Eco Obsidiano",
    "item__AG_PepperedCollar__gameplay": "Concede 4 segundos de aumento de velocidade de movimento em 25% quando usado (1 uso) | -3% Distância do Projétil",
    "item__AG_PepperedCollar__lore": "Uma coleira criada em homenagem a uma famosa besta felina.",
    "item__AG_PepperedCollar__name": "Coleira Temperada",
    "item__AG_RingOfAnOceanMan__gameplay": "Concede 2 segundos de +20% de velocidade de movimento ao ser usado | Extrai 5% mais rápido | +5% de Velocidade de Movimento ao caminhar na água",
    "item__AG_RingOfAnOceanMan__lore": "Parece que está tentando pegar sua mão e levá-lo para uma terra que apenas ele entende.",
    "item__AG_RingOfAnOceanMan__name": "Anel de um Homem do Oceano",
    "item__AG_StuddedNecklace__gameplay": "Torne-se invisível por 4 segundos ao ser usado (3 cargas) | -3% de Velocidade de Movimento",
    "item__AG_StuddedNecklace__lore": "Provavelmente devo fazer um seguro para isso",
    "item__AG_StuddedNecklace__name": "Colar com Tachas",
    "item__AG_TheDarkLordsEmbrace__gameplay": "+30 Saúde Máxima",
    "item__AG_TheDarkLordsEmbrace__lore": "Amaldiçoado pelas várias atrocidades cometidas por seu portador.",
    "item__AG_TheDarkLordsEmbrace__name": "O Abraço do Senhor das Trevas",
    "item__AG_TimeHerosRing__gameplay": "Adiciona 45 segundos ao seu temporizador de extração ao ser usado | +7% de Velocidade de Ataque | +7% de Velocidade de Movimento ao caminhar na água | -5% de Velocidade de Movimento | +9% de Defesa contra Natureza | +7% de Dano da Natureza",
    "item__AG_TimeHerosRing__lore": "Usado por um garoto élfico que podia viajar no tempo.",
    "item__AG_TimeHerosRing__name": "Anel do Herói do Tempo",
    "item__AG_VestiasSoothingSignet__gameplay": "Cura 30 PV ao ser usado | INCOGNITO: Esconde suas mensagens de saque e localização do restante do mundo",
    "item__AG_VestiasSoothingSignet__lore": "Um anel que permite ao usuário acalmar tanto ferimentos quanto tensões em grupo.",
    "item__AG_VestiasSoothingSignet__name": "Selo Calmante de Vestia",
    "item__AG_ViolasDinnerReminder__gameplay": "+8,5% de Defesa contra Natureza | +8,5% de Defesa contra Gelo | Recebe 7% menos dano durante a extração | Seus projéteis viajam 5% menos",
    "item__AG_ViolasDinnerReminder__lore": "Um anel que avisa a todos ao seu redor quando você está com fome.",
    "item__AG_ViolasDinnerReminder__name": "Lembrete de Jantar da Viola",
    "item__AG_WolfMedallion__gameplay": "Cura 10HP ao usar | +10% de Velocidade de Ataque | +10% de Drenagem de todo o dano que você causa a jogadores",
    "item__AG_WolfMedallion__lore": "Sintonizado à magia, pode detectar lugares de poder próximos.",
    "item__AG_WolfMedallion__name": "Medalhão do Lobo",
    "item__CI_AlloyedBend__gameplay": "",
    "item__CI_AlloyedBend__lore": "Um anel feito de vários metais raros que foram fundidos de forma grosseira.",
    "item__CI_AlloyedBend__name": "Curva Ligada",
    "item__CI_ApposSignet__gameplay": "",
    "item__CI_ApposSignet__lore": "Rumor diz que pertence a um híbrido meio-dragão, meio-humano que tinha a habilidade de controlar e falar com patos.",
    "item__CI_ApposSignet__name": "Selo de Appo",
    "item__CI_BloodhuntersRing__gameplay": "",
    "item__CI_BloodhuntersRing__lore": "A caçadora de sangue procura seus semelhantes e também você.",
    "item__CI_BloodhuntersRing__name": "Anel da Caçadora de Sangue",
    "item__CI_CelestialEternity__gameplay": "",
    "item__CI_CelestialEternity__lore": "Aqueles que se levantaram contra Malachi foram presenteados com este anel pelos deuses.",
    "item__CI_CelestialEternity__name": "Eternidade Celestial",
    "item__CI_EbonstarBand__gameplay": "",
    "item__CI_EbonstarBand__lore": "Este anel contém parte da estrela caída que mudou tudo.",
    "item__CI_EbonstarBand__name": "Banda Estrela de Ébano",
    "item__CI_EchoingWhisperNecklace__gameplay": "",
    "item__CI_EchoingWhisperNecklace__lore": "Um colar que captura e ecoa os sussurros das almas perdidas.",
    "item__CI_EchoingWhisperNecklace__name": "Colar de Sussurro Ecoante",
    "item__CI_FeyEnchantmentLocket__gameplay": "",
    "item__CI_FeyEnchantmentLocket__lore": "Um medalhão infundido com encantamentos do reino das Fadas, conferindo um encanto sedutor.",
    "item__CI_FeyEnchantmentLocket__name": "Medalhão de Encantamento das Fadas",
    "item__CI_FragmentOfFate__gameplay": "",
    "item__CI_FragmentOfFate__lore": "Antes inteiro, agora despedaçado. Antes unido, agora dividido. Antes eterno, agora obsoleto.",
    "item__CI_FragmentOfFate__name": "Fragmento do Destino",
    "item__CI_LatrosKnavishBand__gameplay": "",
    "item__CI_LatrosKnavishBand__lore": "Um anel que apenas os mais vis ladrões com certeza moral usariam.",
    "item__CI_LatrosKnavishBand__name": "Banda Desonesta de Latro",
    "item__CI_LifesSimpleRing__gameplay": "",
    "item__CI_LifesSimpleRing__lore": "Viva de forma simples para que outros possam viver de forma simples.",
    "item__CI_LifesSimpleRing__name": "Anel Simples da Vida",
    "item__CI_LuminousEnigma__gameplay": "",
    "item__CI_LuminousEnigma__lore": "Eu possuo a chave de um conhecimento vasto, fornecendo respostas inigualáveis.",
    "item__CI_LuminousEnigma__name": "Enigma Luminoso",
    "item__CI_Ouro__gameplay": "",
    "item__CI_Ouro__lore": "Diz-se que é a chave para desvendar o conhecimento de todas as criaturas vivas.",
    "item__CI_Ouro__name": "Ouro",
    "item__CI_PeachRing__gameplay": "",
    "item__CI_PeachRing__lore": "Tem um sabor doce.",
    "item__CI_PeachRing__name": "Anel de Pêssego",
    "item__CI_PlayerSoulFragment__gameplay": "Um ceifador de almas ficaria interessado nisso.",
    "item__CI_PlayerSoulFragment__lore": "Um fragmento da alma perdida de um aventureiro.",
    "item__CI_PlayerSoulFragment__name": "Fragmento da Alma do Jogador",
    "item__CI_ProudlyPinkRing__gameplay": "",
    "item__CI_ProudlyPinkRing__lore": "Este anel é um impressionante acessório de moda que certamente chamará a atenção dos outros.",
    "item__CI_ProudlyPinkRing__name": "Anel Orgulhosamente Rosa",
    "item__CI_RadiantSunstoneBand__gameplay": "",
    "item__CI_RadiantSunstoneBand__lore": "Uma criação paradoxal, este anel simboliza um delicado equilíbrio entre iluminação e destruição.",
    "item__CI_RadiantSunstoneBand__name": "Anel Radiante de Pedra do Sol",
    "item__CI_RingOfDelerium__gameplay": "",
    "item__CI_RingOfDelerium__lore": "Se você ouvir atentamente, poderá ouvir as vozes dos antigos donos deste anel.",
    "item__CI_RingOfDelerium__name": "Anel do Delírio",
    "item__CI_RoyalBrooch__gameplay": "",
    "item__CI_RoyalBrooch__lore": "O símbolo da família real. Vale uma boa quantia.",
    "item__CI_RoyalBrooch__name": "Broche Real",
    "item__CI_SuperStar__gameplay": "",
    "item__CI_SuperStar__lore": "O resquício de uma grande história sobre um reino cheio de cogumelos.",
    "item__CI_SuperStar__name": "Super Estrela",
    "item__CI_SwampOgresRing__gameplay": "",
    "item__CI_SwampOgresRing__lore": "Já pertenceu a um ogro verde que era muito protetor de seu pântano.",
    "item__CI_SwampOgresRing__name": "Anel do Ogro do Pântano",
    "item__CI_WeddingRing__gameplay": "",
    "item__CI_WeddingRing__lore": "Isso vale muito, eu deveria devolvê-lo ao dono original.",
    "item__CI_WeddingRing__name": "Anel de Casamento",
    "item__PG_AngelistasPotionPopper__gameplay": "Sua saúde se regenera o tempo todo",
    "item__PG_AngelistasPotionPopper__lore": "Um anel que você usa quando seu curandeiro proíbe você de beber poções de cura.",
    "item__PG_AngelistasPotionPopper__name": "Estourador de Poções de Angelista",
    "item__PG_AquamoorsBlessing__gameplay": "Concede 3 segundos de +25% de velocidade de movimento quando usado (3 cargas) | Sua saúde se regenera o tempo todo | -20 de Vida Máxima",
    "item__PG_AquamoorsBlessing__lore": "Dizem ser abençoado pelo espírito do lago antes da corrupção das terras.",
    "item__PG_AquamoorsBlessing__name": "Benção de Aquamoor",
    "item__PG_ArcaneEmber__gameplay": "-6.5% de Dano contra NPCs | +5.6% de Dano contra jogadores",
    "item__PG_ArcaneEmber__lore": "Um pequeno braseiro pode ser visto ainda brilhando na gema deste anel.",
    "item__PG_ArcaneEmber__name": "Braseiro Arcano",
    "item__PG_ArcaneSigilCollar__gameplay": "Concede 4 segundos de +30% de velocidade de movimento quando usado | -8.5% de dano a NPCs | +9.5% de dano a jogadores",
    "item__PG_ArcaneSigilCollar__lore": "Gravado com símbolos poderosos, amplifica as habilidades arcanas do usuário.",
    "item__PG_ArcaneSigilCollar__name": "Coleira de Símbolo Arcano",
    "item__PG_AssassinSignet__gameplay": "Torne-se invisível por 5 segundos ao usar (2 cargas) | -3% Velocidade de Movimento | -3% Distância de Projétil",
    "item__PG_AssassinSignet__lore": "Um sinete segurado por uma assassina infame que lutava com seu cabelo.",
    "item__PG_AssassinSignet__name": "Sinete do Assassino",
    "item__PG_BellsShiningRing__gameplay": "Adiciona 60 segundos ao seu temporizador de extração ao ser usado | -9,5% de Dano a jogadores | +15,5% de Dano a NPCs | +40 de Vida Máxima | Sua saúde se regenera o tempo todo",
    "item__PG_BellsShiningRing__lore": "Conhecida por muitos como a melhor curandeira do reino.",
    "item__PG_BellsShiningRing__name": "Anel Brilhante de Belle",
    "item__PG_BloodGemRing__gameplay": "REVIVE com 50% de HP quando você morrer | -20 de saúde máxima",
    "item__PG_BloodGemRing__lore": "Aqueles que estavam no templo durante o ritual caíram em um frenesi de derramamento de sangue. Este anel simboliza tempos desesperados.",
    "item__PG_BloodGemRing__name": "Anel de Gema de Sangue",
    "item__PG_BloodiedVitality__gameplay": "EXTRAI ao ser usado, não importa onde você esteja | Sua saúde se regenera o tempo todo | +30 de Vida Máxima | -8% de Dano a Jogadores | Você recebe 10% mais dano de TODOS os tipos de dano ao extrair | Este item é destruído quando totalmente usado",
    "item__PG_BloodiedVitality__lore": "Sua conexão com a realidade e a vida em si parece mais forte com isso em seu corpo.",
    "item__PG_BloodiedVitality__name": "Vitalidade Manchada de Sangue",
    "item__PG_BloodmoonLoop__gameplay": "Cura 5HP ao ser usado (3 cargas) | +5% de Dano por Sangramento",
    "item__PG_BloodmoonLoop__lore": "Ligado aos ciclos lunares. Isso aumenta a destreza física durante uma lua de sangue.",
    "item__PG_BloodmoonLoop__name": "Bloodmoon Armor",
    "item__PG_CapeOfPureDarkness__gameplay": "Concede 4 segundos de velocidade de movimento +25% quando usado | +9% de Defesa das Sombras | +9% de Defesa Tóxica | +7% de Dano Tóxico | Sua saúde drena o tempo todo.",
    "item__PG_CapeOfPureDarkness__lore": "Ninguém ousava questioná-lo por medo. Pois o Governante das Trevas não era para ser questionado.",
    "item__PG_CapeOfPureDarkness__name": "Capa das Trevas Puras",
    "item__PG_CelestialSolaceBand__gameplay": "Cura 7HP quando usado | INSPEÇÃO: Revela informações sobre seus oponentes | +6.5% de dano das sombras",
    "item__PG_CelestialSolaceBand__lore": "Forjado pelos próprios deuses, este anel oferece consolo em meio à escuridão.",
    "item__PG_CelestialSolaceBand__name": "Anel de Consolo Celestial",
    "item__PG_CelestialStarfallPendant__gameplay": "Fica invisível por 3 segundos ao ser usado | +6.5% de dano a NPCs | +5% de dano sagrado",
    "item__PG_CelestialStarfallPendant__lore": "Um pingente adornado com uma gema celestial, invocando energia celestial.",
    "item__PG_CelestialStarfallPendant__name": "Pingente de Queda Estelar Celestial",
    "item__PG_CultistsMask__name": "Máscara do Cultista",
    "item__PG_CultistsMask__lore": "Aqueles que caíram vítimas da corrupção buscaram ocultar sua aparência, pois a corrupção mudou sua própria essência.",
    "item__PG_CultistsMask__gameplay": "Fique invisível por 6 segundos ao ser usado | INCOGNITO: Esconde suas mensagens de saque e localização do restante do mundo | +15% de Velocidade de Ataque",
    "item__PG_DepthStrider__name": "Andarilho das Profundezas",
    "item__PG_DepthStrider__lore": "Um encantamento fraco está presente nesta capa, tornando a caminhada na água mais fácil.",
    "item__PG_DepthStrider__gameplay": "+5% de velocidade de movimento ao caminhar na água.",
    "item__PG_GoldenGauntlets__name": "Manoplas Douradas",
    "item__PG_GoldenGauntlets__lore": "Apropriadas para saqueadores, essas manoplas parecem ajudá-lo a encontrar mais ouro.",
    "item__PG_GoldenGauntlets__gameplay": "+10% de ouro obtido de baús.",
    "item__PG_BeastVanquisherBoots__name": "Botas do Vencedor das Feras",
    "item__PG_BeastVanquisherBoots__lore": "Usado por aqueles na linha de frente da batalha. Essas botas possibilitaram a rápida derrota do inimigo.",
    "item__PG_BeastVanquisherBoots__gameplay": "+12% de Velocidade de Movimento | +45 de Vida Máxima | +25% de Dano a NPCs | -12% de Dano a Jogadores | -12% de Distância de Projéteis",
    "item__PG_CloakOfModestApproachability__gameplay": "Seus projéteis percorrem 6% a mais de distância | Adiciona 30 segundos ao seu temporizador de extração ao ser usado",
    "item__PG_CloakOfModestApproachability__lore": "Os outros parecem atraídos por você e não têm certeza do porquê.",
    "item__PG_CloakOfModestApproachability__name": "Manto da Aproximação Modesta",
    "item__PG_CloudedDarkness__gameplay": "+20% de Eteralidade | +10% de dano das sombras | +13% de defesa das sombras",
    "item__PG_CloudedDarkness__lore": "Quando chegou a hora e o ato foi concluído, uma nuvem de escuridão envolveu Malachi e foi imbuída neste anel.",
    "item__PG_CloudedDarkness__name": "Escuridão Nublada",
    "item__PG_CompactedLimeGreenRing__gameplay": "Seus projéteis percorrem 5% a mais de distância | +7% de dano tóxico",
    "item__PG_CompactedLimeGreenRing__lore": "Segurado pelo sucessor do trono de um dragão.",
    "item__PG_CompactedLimeGreenRing__name": "Anel Verde-Limão Compactado",
    "item__PG_CopperWristband__gameplay": "+6.5% de defesa física",
    "item__PG_CopperWristband__lore": "Um anel absurdamente grande que se encaixa melhor como uma pulseira... Só se pode imaginar o tamanho da criatura que usava isso como anel.",
    "item__PG_CopperWristband__name": "Pulseira de Cobre",
    "item__PG_CoralReef__gameplay": "+8,5% de Velocidade de Movimento ao caminhar na água | Extrai 10% mais devagar",
    "item__PG_CoralReef__lore": "Criado como um presente para a princesa sereia.",
    "item__PG_CoralReef__name": "Recife de Coral",
    "item__PG_CorruptedTearRing__gameplay": "Fique invisível por 5 segundos ao ser usado | +7% Roubo de vida de todo o dano que você causa a jogadores | -7% Defesa contra Caos | +9% Dano Caótico | +9% Dano de Raios",
    "item__PG_CorruptedTearRing__lore": "Uma única lágrima foi derramada antes que a corrupção se instalasse... \"O que eu me tornei!?\".",
    "item__PG_CorruptedTearRing__name": "Anel da Lágrima Corrompida",
    "item__PG_CursedEternityRing__gameplay": "Concede 5 segundos de +30% de velocidade de movimento ao ser usado | RESSUSCITA com 48% de Vida quando você morre (não acumula com outros itens de ressurreição) | +12% de Ouro de baús | +8,5% de Roubo de Vida de todo o dano causado aos jogadores | Sua saúde diminui o tempo todo",
    "item__PG_CursedEternityRing__lore": "Um símbolo de condenação eterna. Ele se prende a quem o usa, amaldiçoando-os pela eternidade, mas concedendo-lhes grande poder.",
    "item__PG_CursedEternityRing__name": "Anel da Eternidade Amaldiçoada",
    "item__PG_CursedSignet__gameplay": "+20% de Dreno de todo o dano que você causa a jogadores",
    "item__PG_CursedSignet__lore": "Uma presença maligna emana deste anel, trazendo infortúnio a todos que o usam.",
    "item__PG_CursedSignet__name": "Anel Amaldiçoado",
    "item__PG_DeathwhisperWrap__gameplay": "+13% Dano a NPCs | +13% Defesa contra Caos | +10% Dano Tóxico",
    "item__PG_DeathwhisperWrap__lore": "Enquanto a lâmina mergulhava em seu coração e tudo ficava negro, um sussurro fraco foi ouvido: 'Ainda não é hora de eu reivindicar sua alma.'",
    "item__PG_DeathwhisperWrap__name": "Envoltório do Sussurro da Morte",
    "item__PG_DreadmorneLoop__gameplay": "Concede 3 segundos de +30% de velocidade de movimento ao ser usado (5 cargas) | -5% de Velocidade de Ataque | -6,5% de Defesa Sagrada | +10% de Dano Caótico",
    "item__PG_DreadmorneLoop__lore": "Este anel instila medo nos corações dos inimigos, inspirando terror e causando pavor.",
    "item__PG_DreadmorneLoop__name": "Dreadmorne Loop",
    "item__PG_DreamcasterDrapery__gameplay": "+6.5% de dano contra NPCs | +6.5% de dano do caos",
    "item__PG_DreamcasterDrapery__lore": "Nascido de pesadelos, esta veste diz-se manipular o subconsciente.",
    "item__PG_DreamcasterDrapery__name": "Dreamcaster Drapery",
    "item__PG_EbonfireRobelet__gameplay": "Seus projéteis viajam 6% mais longe",
    "item__PG_EbonfireRobelet__lore": "Envolve o usuário em uma aura de sombras fumegantes, concedendo proteção contra danos elementais.",
    "item__PG_EbonfireRobelet__name": "Ebonfire Robelet",
    "item__PG_ElvenGildedGuardian__gameplay": "Extração 5% mais rápida",
    "item__PG_ElvenGildedGuardian__lore": "Um cinto lindamente trabalhado, adornado com heras douradas, que aprimora a agilidade do usuário e repele adversários com fineza élfica.",
    "item__PG_ElvenGildedGuardian__name": "Elven Gilded Guardian",
    "item__PG_EmbergraspRing__gameplay": "+10 de Vida Máxima",
    "item__PG_EmbergraspRing__lore": "As chamas dançam e crepitam ao seu toque, queimando seus inimigos.",
    "item__PG_EmbergraspRing__name": "Embergrasp Ring",
    "item__PG_EnchantedElixirNecklace__gameplay": "Adiciona 60 segundos ao seu cronômetro de extração ao usar | REVIVA com 60% de HP quando morrer (não acumula com outros itens de revive)",
    "item__PG_EnchantedElixirNecklace__lore": "Um colar contendo um frasco de elixir encantado, aumentando a potência mágica.",
    "item__PG_EnchantedElixirNecklace__name": "Enchanted Elixir Necklace",
    "item__PG_EnigmaLoop__gameplay": "INCOGNITO: Esconde suas mensagens de loot e localização do restante do mundo | +5% de dano do caos",
    "item__PG_EnigmaLoop__lore": "Um anel enigmático que confunde os inimigos, distorcendo a realidade a seu favor.",
    "item__PG_EnigmaLoop__name": "Enigma Loop",
    "item__PG_EnigmaticShadowweaveRing__gameplay": "Cura 20HP ao ser usado (1 carga) | +5,5% de Dano a Jogadores | -9% de Dano a NPCs",
    "item__PG_EnigmaticShadowweaveRing__lore": "Impregnado de encantamentos enigmáticos, este anel tece fios de escuridão juntos.",
    "item__PG_EnigmaticShadowweaveRing__name": "Anel de Tecelagem das Sombras Enigmáticas",
    "item__PG_EssenceConduit__gameplay": "+9% de Dano contra NPCs | -4% de Dano contra jogadores",
    "item__PG_EssenceConduit__lore": "Concede poder àqueles que cuidam da natureza.",
    "item__PG_EssenceConduit__name": "Conduíte da Essência",
    "item__PG_EverlastingSpiritCircle__gameplay": "Concede 3 segundos de +25% de velocidade de movimento ao ser usado (2 cargas) | +10% de Ouro de baús",
    "item__PG_EverlastingSpiritCircle__lore": "Forjado por feitiçaria antiga, este anel atua como um condutor de energia espiritual.",
    "item__PG_EverlastingSpiritCircle__name": "Círculo Espiritual Eterno",
    "item__PG_FrostFireRing__gameplay": "+7% de Dano de Fogo | +8,5% de Defesa contra Gelo | -6,5% de Defesa contra Fogo",
    "item__PG_FrostFireRing__lore": "Um anel com pedras elementais, capaz de infundir ataques com poder adicional - \"Porque você é quente e depois frio\"",
    "item__PG_FrostFireRing__name": "Anel Gelo de Fogo",
    "item__PG_FrostbiteLoop__gameplay": "+6% de Velocidade de Movimento",
    "item__PG_FrostbiteLoop__lore": "Uma aura gélida envolve este anel, congelando os corações dos inimigos com um toque.",
    "item__PG_FrostbiteLoop__name": "Anel Mordida do Gelo",
    "item__PG_FrostfireShardPendant__gameplay": "Receba 10% menos dano durante a extração | +8% de Dano a Jogadores",
    "item__PG_FrostfireShardPendant__lore": "Um pingente que abriga um fragmento infundido com energias gélidas e flamejantes.",
    "item__PG_FrostfireShardPendant__name": "Pingente de Fragmento Gelo de Fogo",
    "item__PG_GineshsBottleOpener__gameplay": "Sua saúde diminui constantemente | +12% de Dano para TODOS os tipos de dano | +8.5% de Velocidade de Movimento | Seus projéteis percorrem 8.5% a mais de distância",
    "item__PG_GineshsBottleOpener__lore": "Um anel que desvenda tanto a causa quanto a solução de todos os problemas da vida.",
    "item__PG_GineshsBottleOpener__name": "Abridor de Garrafas de Ginesh",
    "item__PG_GirdleOfTheGoldenNecromancer__gameplay": "Cura 10HP quando usado | +6% de Velocidade de Movimento",
    "item__PG_GirdleOfTheGoldenNecromancer__lore": "Um cinto opulento com incrustações de ouro que confere uma aura dourada de proteção necromântica, afastando ferimentos.",
    "item__PG_GirdleOfTheGoldenNecromancer__name": "Cinturão do Necromante Dourado",
    "item__PG_GoldPlatedCape__gameplay": "+4% de Dano a jogadores",
    "item__PG_GoldPlatedCape__lore": "Por algum motivo, isso conta como armadura.",
    "item__PG_GoldPlatedCape__name": "Capa Banhada a Ouro",
    "item__PG_GolemsGrasp__gameplay": "+30 de Vida Máxima | -6% de Velocidade de Movimento | -6% de Velocidade de Movimento ao caminhar na água",
    "item__PG_GolemsGrasp__lore": "O poder da pedra irrompe neste anel, transformando sua pele em armadura.",
    "item__PG_GolemsGrasp__name": "Garra do Golem",
    "item__PG_GuardiansChainmailCollar__gameplay": "Cura 15 pontos de vida ao ser usado (3 utilizações) | -5% Velocidade de Ataque",
    "item__PG_GuardiansChainmailCollar__lore": "Uma resistente coleira de malha entrelaçada, proporcionando proteção constante.",
    "item__PG_GuardiansChainmailCollar__name": "Colar de Malha do Guardião",
    "item__PG_HeirloomCloak__gameplay": "Concede 2 segundos de aumento de velocidade de movimento em 20% quando utilizado (3 usos).",
    "item__PG_HeirloomCloak__lore": "Uma simples capa passada por sua família por gerações.",
    "item__PG_HeirloomCloak__name": "Capa de Herança",
    "item__PG_IceQueensEarrings__gameplay": "Concede 5 segundos de velocidade de movimento +20% ao usar | +4% de Dano a jogadores | +6.5% de Defesa contra Gelo | +5% de Dano Gélido",
    "item__PG_IceQueensEarrings__lore": "Brincos adequados para a própria Rainha do Gelo. Frios ao toque.",
    "item__PG_IceQueensEarrings__name": "Brincos da Rainha do Gelo",
    "item__PG_InfiniteLoop__gameplay": "Adiciona 45 segundos ao seu cronômetro de extração ao usar | -10 de Vida Máxima | Sua saúde se regenera constantemente",
    "item__PG_InfiniteLoop__lore": "Capaz de converter energia vital em mana e mana em energia vital.",
    "item__PG_InfiniteLoop__name": "Loop Infinito",
    "item__PG_IronFoxLocket__gameplay": "INSPEÇÃO: Revela informações sobre seus oponentes | +13% de defesa para TODOS os tipos de dano | +30 de saúde máxima",    
    "item__PG_IronFoxLocket__lore": "As lendas falam de uma raposa, vestindo armadura de ferro, famosa por superar até mesmo os caçadores mais espertos.",
    "item__PG_IronFoxLocket__name": "Camafeu da Raposa de Ferro",
    "item__PG_JaysesRing__gameplay": "+9,5% de Dano a jogadores | -9% de Dano a NPCs | +15% Etéreo | +12% de Dano Elétrico | +12% de Dano de Fogo",
    "item__PG_JaysesRing__lore": "O anel do famoso mago Jayse.",
    "item__PG_JaysesRing__name": "Anel de Jayse",
    "item__PG_LittleCompactedLimeGreenRing__gameplay": "Seus projéteis viajam 6% mais longe | +13% de Defesa Tóxica.",
    "item__PG_LittleCompactedLimeGreenRing__lore": "Um dia pertenceu a um luxuoso dragão.",
    "item__PG_LittleCompactedLimeGreenRing__name": "Anel Pequeno Verde-Limão Compactado",
    "item__PG_MementoMoriLocket__gameplay": "-6.5% de Defesa Caótica | Defesa contra Sombras de +8.5%",
    "item__PG_MementoMoriLocket__lore": "Um camafeu assombrado que serve como lembrete da mortalidade, fortalecendo a determinação.",
    "item__PG_MementoMoriLocket__name": "Camafeu Memento Mori",
    "item__PG_MidnightsVeilBand__gameplay": "Torne-se invisível por 5 segundos ao ser usado (2 cargas)",
    "item__PG_MidnightsVeilBand__lore": "Usado com fios de sombra, esta capa aprimora a furtividade de quem a usa.",
    "item__PG_MidnightsVeilBand__name": "Véu da Meia-Noite",
    "item__PG_MunchkinsRing__gameplay": "+7% Velocidade de Movimento | -10 de Vida Máxima",
    "item__PG_MunchkinsRing__lore": "Seu antigo dono tinha pernas curtas, mas elas nunca o desaceleraram.",
    "item__PG_MunchkinsRing__name": "Anel do Munchkin",
    "item__PG_NaturesAureateBarrier__gameplay": "Adiciona 30 segundos ao seu temporizador de extração ao ser usado (2 usos) | -5,5% de dano a jogadores | INCOGNITO: Esconde suas mensagens de loot e localização do resto do mundo.",
    "item__PG_NaturesAureateBarrier__lore": "Um robusto cinto trançado em ouro, infundido com a fortitude de árvores antigas, concedendo ao usuário um escudo de força natural brilhante.",
    "item__PG_NaturesAureateBarrier__name": "Barreira Áurea da Natureza",
    "item__PG_NecroticGraspTalisman__gameplay": "Concede 5 segundos de velocidade de movimento +30% ao usar | Sua saúde drena continuamente | +12% de Dano Sombrio",
    "item__PG_NecroticGraspTalisman__lore": "Um talismã pulsante com poder necromântico, comandando as forças dos mortos-vivos.",
    "item__PG_NecroticGraspTalisman__name": "Talismã do Aperto Necrótico",
    "item__PG_NecroticSignet__gameplay": "Adiciona 45 segundos ao seu cronômetro de extração ao usar | +13% de Defesa Sagrada",
    "item__PG_NecroticSignet__lore": "Um símbolo macabro de morte e decomposição, que drena a vitalidade de seus inimigos.",
    "item__PG_NecroticSignet__name": "Anel Necrótico",
    "item__PG_PendantOfAncientWisdom__gameplay": "-7% de Dano a Jogadores | +8.75% de Dano a NPCs | +8.5% de Defesa Arcana | +7% de Dano Arcano",
    "item__PG_PendantOfAncientWisdom__lore": "Um pingente ancestral que transmite conhecimento profundo para aquele que o usa.",
    "item__PG_PendantOfAncientWisdom__name": "Pingente da Sabedoria Antiga",
    "item__PG_PhantomBand__gameplay": "+30% de Etéreo",
    "item__PG_PhantomBand__lore": "Usar este anel etéreo permite que você caminhe despercebido entre os vivos.",
    "item__PG_PhantomBand__name": "Anel Fantasma",
    "item__PG_PhoenixFeatherTorque__gameplay": "RESSUSCITA com 60% de Vida quando você morre (não acumula com outros itens de ressurreição) | +60 de Vida Máxima | +19,5% de Defesa contra Fogo | +19,5% de Defesa contra Gelo | -19,5% de Defesa contra Tóxicos | -15% de Velocidade de Ataque",
    "item__PG_PhoenixFeatherTorque__lore": "Um majestoso torque adornado com uma pena de fênix, concedendo renascimento e renovação.",
    "item__PG_PhoenixFeatherTorque__name": "Torque de Pluma de Fênix",
    "item__PG_PhoenixsRespite__gameplay": "Fique invisível por 3 segundos ao usar | +6.5% de Defesa contra Fogo | +5% de Dano de Fogo",
    "item__PG_PhoenixsRespite__lore": "Aqueles que se lançaram voluntariamente sobre as chamas renasciam com poder ainda maior.",
    "item__PG_PhoenixsRespite__name": "Alívio da Fênix",
    "item__PG_PurpletratorsRing__gameplay": "+3% de Roubo de Vida de todo o dano causado a jogadores",
    "item__PG_PurpletratorsRing__lore": "Aqueles que ousavam rir do nome deste anel muitas vezes eram mortos por seu usuário.",
    "item__PG_PurpletratorsRing__name": "Anel do Assassino Roxo",
    "item__PG_QuintsFishingGarb__gameplay": "+5% de Velocidade de Ataque",
    "item__PG_QuintsFishingGarb__lore": "Um anel que cria uma quantidade infinita de iscas para pesca, muitas vezes distribuídas inadvertidamente aos membros do grupo.",
    "item__PG_QuintsFishingGarb__name": "Traje de Pesca do Quint",
    "item__PG_ResilientHeartstone__gameplay": "Cura 10HP ao ser usado | +5% de Ouro de baús | Recebe 5% menos dano durante a extração | INSPEÇÃO: Revela informações sobre seus oponentes",
    "item__PG_ResilientHeartstone__lore": "Uma gema em forma de coração que infunde fortaleza inabalável no usuário.",
    "item__PG_ResilientHeartstone__name": "Coração Resiliente",
    "item__PG_RingOfConstellations__gameplay": "Seus projéteis viajam 6% mais longe",
    "item__PG_RingOfConstellations__lore": "Dizem que um guerreiro da noite possuía este anel e ele fornecia orientação sob a escuridão da noite.",
    "item__PG_RingOfConstellations__name": "Anel das Constelações",
    "item__PG_RingOfTheOneHandedWarrior__gameplay": "-10 de Vida Máxima | +12% de Dano Físico | +12% de Dano de Sangramento",
    "item__PG_RingOfTheOneHandedWarrior__lore": "Você só precisa de uma mão para usar um anel.",
    "item__PG_RingOfTheOneHandedWarrior__name": "Anel do Guerreiro de Uma Mão",
    "item__PG_RingOfViolentViolet__gameplay": "+30 de Vida Máxima | +17% de Dano para TODOS os tipos de dano | -15% de Defesa para TODOS os tipos de dano",
    "item__PG_RingOfViolentViolet__lore": "Você sente uma súbita onda de poder e sede de sangue ao equipar este anel.",
    "item__PG_RingOfViolentViolet__name": "Anel da Violeta Violenta",
    "item__PG_RuneboundRing__gameplay": "+5% de Velocidade de Movimento ao andar na água | +7% de Dano Arcano",
    "item__PG_RuneboundRing__lore": "Gravado com runas antigas, ele canaliza energias arcanas para fortalecer o usuário.",
    "item__PG_RuneboundRing__name": "Anel Encantado de Runas",
    "item__PG_SerpentsCoilChoker__gameplay": "INSPEÇÃO: Revela informações sobre seus oponentes",
    "item__PG_SerpentsCoilChoker__lore": "Enroscando-se no pescoço, ele confere ao usuário graça e astúcia serpentinas.",
    "item__PG_SerpentsCoilChoker__name": "Coleira de Serpente",
    "item__PG_SerpentsRing__gameplay": "+6.5% de Resistência a Toxinas",
    "item__PG_SerpentsRing__lore": "Um anel feito das escamas de uma serpente, encantado para proteger o usuário do veneno.",
    "item__PG_SerpentsRing__name": "Anel da Serpente",
    "item__PG_ShieldbearersAmulet__gameplay": "Cura 10HP ao ser usado (1 carga) | +6,5% de dano a NPCs",
    "item__PG_ShieldbearersAmulet__lore": "Estampado com um brasão heráldico, ele fortalece as defesas do usuário.",
    "item__PG_ShieldbearersAmulet__name": "Amuleto do Portador de Escudo",
    "item__PG_SoulBindingRing__gameplay": "REVIVE com 40% de HP quando você morrer | +10% de Dano Sagrado",
    "item__PG_SoulBindingRing__lore": "Este anel possui três pedras. Uma é clara e transparente. As outras duas contêm uma estranha névoa dourada e turbilhante.",
    "item__PG_SoulBindingRing__name": "Anel de Ligação da Alma",
    "item__PG_SoulforgeRing__gameplay": "EXTRAI quando usado, não importa onde você esteja | Extrai 10% mais devagar | Este item é destruído quando totalmente usado",
    "item__PG_SoulforgeRing__lore": "Infundido com a essência de almas atormentadas, este anel aumenta sua destreza física e mística.",
    "item__PG_SoulforgeRing__name": "Anel da Forja da Alma",
    "item__PG_SoulshackleVeil__gameplay": "Concede 3 segundos de +25% de velocidade de movimento ao ser usado (3 cargas) | +8,5% de Etéreo | -5% de Velocidade de Movimento",
    "item__PG_SoulshackleVeil__lore": "Preso pela magia negra, ele enreda as almas dos caídos, conectando você ao mundo espiritual.",
    "item__PG_SoulshackleVeil__name": "Véu do Encadeamento da Alma",
    "item__PG_SoulstoneReliquary__gameplay": "Fique invisível por 4 segundos ao ser usado | Recebe 5% menos dano durante a extração | RESSUSCITA com 20% de Vida quando você morre (não acumula com outros itens de ressurreição) | +5% de Roubo de Vida de todo o dano que você causa aos jogadores",
    "item__PG_SoulstoneReliquary__lore": "Um colar relicário que permite ao usuário controlar e aproveitar almas capturadas.",
    "item__PG_SoulstoneReliquary__name": "Relicário de Pedra da Alma",
    "item__PG_StormweaverCloak__gameplay": "INCOGNITO: Esconde suas mensagens de saque e localização do resto do mundo",
    "item__PG_StormweaverCloak__lore": "Domine a fúria das tempestades, comandando a ira dos céus.",
    "item__PG_StormweaverCloak__name": "Capa do Invocador de Tempestades",
    "item__PG_SummonersSign__gameplay": "+10 de Vida Máxima",
    "item__PG_SummonersSign__lore": "Usado pelos invocadores para reunir mana da terra.",
    "item__PG_SummonersSign__name": "Sinal do Invocador",
    "item__PG_TalismanOfVeiledShadows__gameplay": "Torna-se invisível por 3 segundos ao usar | -5% de Velocidade de Movimento | +8.5% de Defesa do Caos | +8.5% Defesa contra Sombras",
    "item__PG_TalismanOfVeiledShadows__lore": "Um talismã místico que envolve o usuário em trevas efêmeras.",
    "item__PG_TalismanOfVeiledShadows__name": "Talismã das Sombras Veladas",
    "item__PG_TantrumsLuckyLoop__gameplay": "+3% de Velocidade de Movimento",
    "item__PG_TantrumsLuckyLoop__lore": "Um anel que faz você tão rápido quanto um galgo, e tão preguiçoso quanto ele.",
    "item__PG_TantrumsLuckyLoop__name": "Laço da Sorte de Tantrum",
    "item__PG_VampiricSeal__gameplay": "Cura 7HP ao ser usado (2 cargas) | +3% de Roubo de Vida de todo o dano que você causa aos jogadores",
    "item__PG_VampiricSeal__lore": "Alimentando-se da força vital de seus inimigos, concede vitalidade a cada golpe.",
    "item__PG_VampiricSeal__name": "Vestimenta Vampírica",
    "item__PG_VeilstormCirclet__gameplay": "Cura 30HP ao usar | -10% de Velocidade de Ataque | +12% de Dano da Natureza",
    "item__PG_VeilstormCirclet__lore": "Detém o poder de uma tempestade furiosa. Concede ao usuário controle sobre vento e relâmpago.",
    "item__PG_VeilstormCirclet__name": "Capa da Tempestade Velada",
    "item__PG_VenomousBite__gameplay": "Adiciona 30 segundos ao seu cronômetro de extração ao usar | Sua vida drena o tempo todo | +10% de Dano Tóxico",
    "item__PG_VenomousBite__lore": "Armadura usada por antigos médicos da peste.",
    "item__PG_VenomousBite__name": "Mordida Venenosa",
    "item__PG_WindspeakersRing__gameplay": "INSPEÇÃO: Revela informações sobre seus oponentes | +7% de Dano da Natureza",
    "item__PG_WindspeakersRing__lore": "Já pertenceu a um poderoso mago que foi perseguido ao redor do mundo por sua própria sombra. Dizem que este anel tem o poder de controlar os ventos.",
    "item__PG_WindspeakersRing__name": "Anel do Ventos",
    "item__PG_Wraithband__gameplay": "+8,5% Etéreo | 7% de Dano Sagrado | -10 de Vida Máxima",
    "item__PG_Wraithband__lore": "Energia fantasmagórica percorre este anel, concedendo poderes espectrais.",
    "item__PG_Wraithband__name": "Faixa Espectral",
    "item__VT_AbyssalShard__gameplay": "",
    "item__VT_AbyssalShard__lore": "Um pedaço de pedra negra coletado do submundo.",
    "item__VT_AbyssalShard__name": "Fragmento Abissal",
    "item__VT_Amber__gameplay": "",
    "item__VT_Amber__lore": "Esta pedra tem um tom alaranjado profundo.",
    "item__VT_Amber__name": "Âmbar",
    "item__VT_BardsDiary__gameplay": "",
    "item__VT_BardsDiary__lore": "Esta canção está repleta de poesia e partituras musicais.",
    "item__VT_BardsDiary__name": "Livro de Canções do Bardo",
    "item__VT_BrokenIronBand__gameplay": "",
    "item__VT_BrokenIronBand__lore": "Um simples anel de ferro que perdeu seu brilho.",
    "item__VT_BrokenIronBand__name": "Anel de Ferro Quebrado",
    "item__VT_BrokenSword__gameplay": "",
    "item__VT_BrokenSword__lore": "Esta espada já viu dias melhores.",
    "item__VT_BrokenSword__name": "Espada de Ferro Quebrada",
    "item__VT_Cabbage__gameplay": "",
    "item__VT_Cabbage__lore": "Você é repolho, sempre será repolho.",
    "item__VT_Cabbage__name": "Repolho",
    "item__VT_ChunkOfIron__gameplay": "",
    "item__VT_ChunkOfIron__lore": "Um pequeno pedaço de ferro, um ferreiro pode ser capaz de fazer algo com isso.",
    "item__VT_ChunkOfIron__name": "Pedaço de Ferro",
    "item__VT_CubicZirconia__gameplay": "",
    "item__VT_CubicZirconia__lore": "Um pedaço quadrado de uma Zircônia.",
    "item__VT_CubicZirconia__name": "Zircônia Cúbica",
    "item__VT_Diamond__gameplay": "Um colecionador de objetos raros provavelmente se interessaria por isso.",
    "item__VT_Diamond__lore": "Uma pedra preciosa.",
    "item__VT_Diamond__name": "Diamante",
    "item__VT_DukesDiary__gameplay": "",
    "item__VT_DukesDiary__lore": "As divagações de um antigo Duque poderoso.",
    "item__VT_DukesDiary__name": "Diário do Duque de Ert",
    "item__VT_EmeraldDust__gameplay": "",
    "item__VT_EmeraldDust__lore": "Um esmeralda que foi transformada em um pó fino.",
    "item__VT_EmeraldDust__name": "Pó de Esmeralda",
    "item__VT_Emerald__gameplay": "",
    "item__VT_Emerald__lore": "Um pedaço de uma esmeralda.",
    "item__VT_Emerald__name": "Esmeralda",
    "item__VT_EverlastingCandle__gameplay": "",
    "item__VT_EverlastingCandle__lore": "Esta vela misteriosamente queimará para sempre.",
    "item__VT_EverlastingCandle__name": "Vela Eterna",
    "item__VT_Eyeball__gameplay": "",
    "item__VT_Eyeball__lore": "O olho de uma besta morta.",
    "item__VT_Eyeball__name": "Olho de Besta",
    "item__VT_FangOfTheBloodMoon__gameplay": "",
    "item__VT_FangOfTheBloodMoon__lore": "Esta presa coberta de sangue é de uma criatura que vagueia apenas quando o céu está sangrando.",
    "item__VT_FangOfTheBloodMoon__name": "Presa da Lua Sangrenta",
    "item__VT_Fossil__gameplay": "",
    "item__VT_Fossil__lore": "Os restos de um ser antigo estão permanentemente impressos nesta rocha.",
    "item__VT_Fossil__name": "Fóssil",
    "item__VT_GhostPlasma__gameplay": "",
    "item__VT_GhostPlasma__lore": "Este líquido viscoso brilha com energias etéreas.",
    "item__VT_GhostPlasma__name": "Plasma Fantasmagórico",
    "item__VT_GoblinCloth__gameplay": "",
    "item__VT_GoblinCloth__lore": "Este tecido está muito sujo e tem um pouco de sangue nele.",
    "item__VT_GoblinCloth__name": "Pano do Loin do Goblin",
    "item__VT_GoldNugget__gameplay": "",
    "item__VT_GoldNugget__lore": "Um pequeno pedaço de ouro. Provavelmente vale muito.",
    "item__VT_GoldNugget__name": "Pepita de Ouro",
    "item__VT_OgreEntrails__gameplay": "",
    "item__VT_OgreEntrails__lore": "Vísceras do interior de um Ogro.",
    "item__VT_OgreEntrails__name": "Vísceras de Ogro",
    "item__VT_OnyxGem__gameplay": "Um especialista em joias pode se interessar por isso.",
    "item__VT_OnyxGem__lore": "Uma gema rara feita de ônix.",
    "item__VT_OnyxGem__name": "Gema de Ônix",
    "item__VT_PileOfAsh__gameplay": "",
    "item__VT_PileOfAsh__lore": "Apenas um pequeno monte de cinzas.",
    "item__VT_PileOfAsh__name": "Monte de Cinzas",
    "item__VT_Potato__gameplay": "Cozinhe-os, amasse-os, coloque-os em um ensopado.",
    "item__VT_Potato__lore": "Você provavelmente nunca ouviu falar deles.",
    "item__VT_Potato__name": "Batata",
    "item__VT_QuartzRing__gameplay": "",
    "item__VT_QuartzRing__lore": "Incrustado com um pedaço de quartzo polido. Não vale muito, mas pelo menos é algo.",
    "item__VT_QuartzRing__name": "Anel de Quartzo",
    "item__VT_RatFur__gameplay": "",
    "item__VT_RatFur__lore": "Um aglomerado nojento de pelos, provavelmente cheio de doenças.",
    "item__VT_RatFur__name": "Aglomerado de Pelos de Rato",
    "item__VT_RedMushroom__gameplay": "",
    "item__VT_RedMushroom__lore": "Este cogumelo é comumente seco e transformado em pó usado para ajudar em doenças.",
    "item__VT_RedMushroom__name": "Cogumelo Vermelho",
    "item__VT_RingOfNearPerfection__gameplay": "",
    "item__VT_RingOfNearPerfection__lore": "Seria melhor se tivesse magia.",
    "item__VT_RingOfNearPerfection__name": "Anel de Quase Perfeição",
    "item__VT_RubyDust__gameplay": "",
    "item__VT_RubyDust__lore": "Um rubi que foi triturado em um pó fino.",
    "item__VT_RubyDust__name": "Pó de Rubi",
    "item__VT_Ruby__gameplay": "",
    "item__VT_Ruby__lore": "Um pedaço de um rubi vermelho profundo.",
    "item__VT_Ruby__name": "Rubi",
    "item__VT_SapphireDust__gameplay": "",
    "item__VT_SapphireDust__lore": "Um safira que foi transformada em um pó fino",
    "item__VT_SapphireDust__name": "Pó de Safira",
    "item__VT_Sapphire__gameplay": "",
    "item__VT_Sapphire__lore": "Um pedaço azul profundo de uma safira",
    "item__VT_Sapphire__name": "Safira",
    "item__VT_SkeletonFemur__gameplay": "",
    "item__VT_SkeletonFemur__lore": "Acho que há um esqueleto com uma perna em algum lugar.",
    "item__VT_SkeletonFemur__name": "Fêmur de Esqueleto",
    "item__VT_Skull__gameplay": "",
    "item__VT_Skull__lore": "Um crânio. Provavelmente de um desses esqueletos vagantes.",
    "item__VT_Skull__name": "Crânio",
    "item__VT_StellarWeaveRing__gameplay": "",
    "item__VT_StellarWeaveRing__lore": "Um anel feito de ramos tecidos. É comum.",
    "item__VT_StellarWeaveRing__name": "Anel de Tecido Estelar",
    "item__VT_TreantBranch__gameplay": "",
    "item__VT_TreantBranch__lore": "Este pedaço de madeira está se contorcendo um pouco. Estranho",
    "item__VT_TreantBranch__name": "Galho de Treant",
    "item__VT_WellWornRing__gameplay": "",
    "item__VT_WellWornRing__lore": "Este anel simples tem várias marcas e arranhões visíveis em sua superfície.",
    "item__VT_WellWornRing__name": "Anel Desgastado",
    "item__VT_WhispersOfTheVoid__gameplay": "",
    "item__VT_WhispersOfTheVoid__lore": "A loucura é comum entre aqueles que ouvem sua voz. E quem não ouve nesta época de crise?",
    "item__VT_WhispersOfTheVoid__name": "Sussurros do Vazio Vol. 1",
    "item__W_ApprenticesStaff__gameplay": "Coloca uma armadilha visível perto dos seus pés que causa dano físico.",
    "item__W_ApprenticesStaff__lore": "O cajado de um aprendiz de feiticeiro comumente usado para treinamento.",
    "item__W_ApprenticesStaff__name": "Cajado do Aprendiz",
    "item__W_ArchmagesSidekick__gameplay": "Coloca uma armadilha visível perto dos seus pés que causa dano arcano.",
    "item__W_ArchmagesSidekick__lore": "Pouco se sabe sobre este cajado, exceto que foi transmutado à existência pelo Arquimago da Ordem Arcana.",
    "item__W_ArchmagesSidekick__name": "Ajudante do Arquimago",
    "item__W_BeesOnAStick__gameplay": "Coloca uma armadilha visível perto dos seus pés que causa dano de natureza.",
    "item__W_BeesOnAStick__lore": "Um cajado adornado com uma colmeia, zumbindo com o poder de abelhas enxameadoras, lançando feitiços picantes aos adversários.",
    "item__W_BeesOnAStick__name": "Abelhas em um Pau",
    "item__W_BlackDahliaWand__gameplay": "Dispara projéteis sombrios.",
    "item__W_BlackDahliaWand__lore": "Sua natureza sombria corrompe os corações daqueles que ousam empunhá-lo.",
    "item__W_BlackDahliaWand__name": "Varinha Dália Negra",
    "item__W_BlazeRod__gameplay": "Dispara chamas!",
    "item__W_BlazeRod__lore": "Encontrado nas profundezas do Nether, este bastão é impregnado com a alma de uma Blaze.",
    "item__W_BlazeRod__name": "Bastão Flamejante",
    "item__W_Bloodrider__gameplay": "Dispara flechas que causam dano sanguinolento pesado.",
    "item__W_Bloodrider__lore": "Fabricado a partir dos ossos de guerreiros caídos, este arco malévolo drena a vida a cada flecha, deixando para trás um rastro de desespero carmesim.",
    "item__W_Bloodrider__name": "Cavaleiro Sanguinário",
    "item__W_BrokenDeathstick__gameplay": "Dispara projéteis do caos que causam dano moderado.",
    "item__W_BrokenDeathstick__lore": "Seria mortal... se não estivesse quebrado.",
    "item__W_BrokenDeathstick__name": "Bastão da Morte Quebrado",
    "item__W_CandlelightStaff__gameplay": "Dispara um projétil mágico que causa dano de fogo.",
    "item__W_CandlelightStaff__lore": "Um cajado que emite uma luz suave, iluminando a escuridão.",
    "item__W_CandlelightStaff__name": "Cajado de Luz de Velas",
    "item__W_CelestialBow__gameplay": "Um emissário celestial infundiu essas flechas com o frio do vazio.",
    "item__W_CelestialBow__lore": "Presenteado por deidades antigas, este arco radiante canaliza bênçãos divinas, perfurando até as defesas mais formidáveis.",
    "item__W_CelestialBow__name": "Arco Celestial",
    "item__W_CrudeCrossbow__gameplay": "Dispara um parafuso básico de baixo dano que causa dano físico.",
    "item__W_CrudeCrossbow__lore": "Uma besta rudimentar feita de retalhos de madeira e barbante. Não é muito poderosa, mas serve.",
    "item__W_CrudeCrossbow__name": "Besta Rudimentar",
    "item__W_DarylsInstinct__gameplay": "Dispara parafusos poderosos que causam dano de sangramento.",
    "item__W_DarylsInstinct__lore": "Uma besta usada pelo lendário caçador Daryl.",
    "item__W_DarylsInstinct__name": "Instinto de Daryl",
    "item__W_DavidBowie__gameplay": "Dispara projéteis físicos fracos.",
    "item__W_DavidBowie__lore": "Transforma você em uma estrela instantânea!",
    "item__W_DavidBowie__name": "David Bowie",
    "item__W_DemonsEye__gameplay": "Coloca uma armadilha visível perto dos seus pés que causa dano de sombra.",
    "item__W_DemonsEye__lore": "Este cajado evoca os sussurros sinistros do reino nefasto, permitindo que os portadores perfurem ilusões e revelem as verdades ocultas que se escondem nas sombras.",
    "item__W_DemonsEye__name": "Olho do Demônio",
    "item__W_DivineWind__gameplay": "Dispara flechas poderosas sagradas.",
    "item__W_DivineWind__lore": "Forjado em santuários de montanhas sagradas, este arco etéreo impulsiona flechas com ventos de outro mundo, rasgando obstáculos e inimigos.",
    "item__W_DivineWind__name": "Vento Divino",
    "item__W_DragonsBreath__gameplay": "Cria um turbilhão de chamas à frente do conjurador.",
    "item__W_DragonsBreath__lore": "Eu conjuro... Bola de Fogo?",
    "item__W_DragonsBreath__name": "Bafo do Dragão",
    "item__W_EldritchBlasters__gameplay": "Dispara rapidamente parafusos de sombra.",
    "item__W_EldritchBlasters__lore": "Bestas duplas imbuidas com o poder das sombras.",
    "item__W_EldritchBlasters__name": "Canhões Eldritch",
    "item__W_Emberstrike__gameplay": "Dispara poderosas flechas de fogo.",
    "item__W_Emberstrike__lore": "Forjado no coração de um vulcão, este arco de obsidiana libera flechas ardentes, deixando para trás trilhas de devastação em brasa.",
    "item__W_Emberstrike__name": "Golpe das Brasas",
    "item__W_FairyBow__gameplay": "Dispara flechas infundidas com magia de fadas.",
    "item__W_FairyBow__lore": "Infunde as flechas disparadas por este arco com energia poderosa e aleatória.",
    "item__W_FairyBow__name": "Arco das Fadas",
    "item__W_FairyStaff__gameplay": "Coloca uma armadilha visível perto dos seus pés que causa dano de natureza.",
    "item__W_FairyStaff__lore": "Pertenceu a uma rainha das fadas.",
    "item__W_FairyStaff__name": "Cajado das Fadas",
    "item__W_FathersBane__gameplay": "Dispara rapidamente parafusos fracos com ponta envenenada.",
    "item__W_FathersBane__lore": "Usado por um filho enlutado que não teve escolha senão matar seu pai.",
    "item__W_FathersBane__name": "Flagelo do Pai",
    "item__W_FlamespitterStaff__gameplay": "Coloca uma armadilha visível perto dos seus pés que causa dano arcano.",
    "item__W_FlamespitterStaff__lore": "Chamas furiosas irrompem deste cajado.",
    "item__W_FlamespitterStaff__name": "Cajado Esguicho de Chamas",
    "item__W_FrostedTips__gameplay": "Causa dano de gelo.",
    "item__W_FrostedTips__lore": "Santa Maria, Stromboli!",
    "item__W_FrostedTips__name": "Ponta Gelada",
    "item__W_GuardsCrossbow__gameplay": "Mais fácil e rápido de carregar do que uma besta normal.",
    "item__W_GuardsCrossbow__lore": "Besta usada pelos guardas da Ordem Arcana.",
    "item__W_GuardsCrossbow__name": "Besta dos Guardas",
    "item__W_HibidyKibity__gameplay": "Dispara projéteis mortais de caos.",
    "item__W_HibidyKibity__lore": "Uma varinha incrustada com a energia vil da maldição assassina.",
    "item__W_HibidyKibity__name": "Hibidy Kibity",
    "item__W_IcewindWand__gameplay": "Cria uma torrente de nuvens congelantes à frente do conjurador.",
    "item__W_IcewindWand__lore": "Capaz de criar o pior clima possível.",
    "item__W_IcewindWand__name": "Varinha Vento Gélido",
    "item__W_IronStaff__gameplay": "Coloca uma armadilha visível perto dos seus pés que causa dano físico.",
    "item__W_IronStaff__lore": "Um cajado feito de ferro, usado pelos guardas da Ordem Arcana.",
    "item__W_IronStaff__name": "Cajado de Ferro",
    "item__W_KingBible__gameplay": "Lança poderosas bíblias sagradas em seus oponentes.",
    "item__W_KingBible__lore": "Um tomo venerado e dourado que contém palavras sagradas de antigos monarcas, concedendo autoridade e moldando destinos.",
    "item__W_KingBible__name": "Bíblia do Rei",
    "item__W_Deathstick__gameplay": "Causa a morte ao disparar projéteis de curto alcance rapidamente, ao custo de desacelerar o usuário.",
    "item__W_Deathstick__lore": "A lenda diz que essa poderosa arma foi deixada cair por uma figura misteriosa há séculos.",
    "item__W_Deathstick__name": "Deathstick",
    "item__W_BookOfTongues__gameplay": "Dispara o fogo mais poderoso das profundezas do inferno. Possui um tempo de recarga MUITO longo.",
    "item__W_BookOfTongues__lore": "Um nome ilegível pode ser visto gravado em suas páginas. Tudo o que você consegue identificar é a letra \"M\".",
    "item__W_BookOfTongues__name": "Livro das Línguas",
    "item__W_LichsStaff__gameplay": "Coloca uma armadilha visível perto dos seus pés que causa dano de sombra.",
    "item__W_LichsStaff__lore": "Empunhado por necromantes sombrios, este cajado aproveita os poderes profanos dos mortos-vivos, trazendo morte e decomposição a tudo que toca.",
    "item__W_LichsStaff__name": "Cajado do Lich",
    "item__W_FrostFlicker__gameplay": "Coloca uma armadilha visível perto de seus pés que causa dano de gelo.",
    "item__W_FrostFlicker__lore": "Um cajado com a habilidade de congelar a água na área circundante. Uma versão mais fraca disso é frequentemente usada durante a temporada de férias.",
    "item__W_FrostFlicker__name": "Brilho Gélido",
    "item__W_MagicalWand__gameplay": "Dispara projéteis mágicos arcanos.",
    "item__W_MagicalWand__lore": "Em nome da lua, eu te punirei!",
    "item__W_MagicalWand__name": "Varinha Mágica",
    "item__W_MidasStaff__gameplay": "Coloca uma armadilha visível perto dos seus pés que causa dano de raio.",
    "item__W_MidasStaff__lore": "O cajado do lendário Rei Midas.",
    "item__W_MidasStaff__name": "Cajado de Midas",
    "item__W_MoonShadow__gameplay": "Dispara flechas das sombras rapidamente.",
    "item__W_MoonShadow__lore": "Conferido pelas espíritos da lua, este arco místico dispara flechas que atravessam as sombras, atingindo de ângulos inesperados.",
    "item__W_MoonShadow__name": "Sombra da Lua",
    "item__W_NaturesReturn__gameplay": "Utiliza o poder da floresta para causar grandes quantidades de dano de natureza aos seus oponentes.",
    "item__W_NaturesReturn__lore": "Um tomo adornado com folhagens, canalizando as forças da natureza para crescimento, cura e tempestades.",
    "item__W_NaturesReturn__name": "Retorno da Natureza",
    "item__W_NightStick__gameplay": "Dispara projéteis mais frios que a noite.",
    "item__W_NightStick__lore": "Usado por aqueles que pretendem manter a paz.",
    "item__W_NightStick__name": "Cacetete Noturno",
    "item__W_PharaohsStaff__gameplay": "Coloca uma armadilha visível perto dos seus pés que causa dano sagrado.",
    "item__W_PharaohsStaff__lore": "Escavado do túmulo de um antigo Faraó, diz-se que este cajado foi usado para invocar os mortos.",
    "item__W_PharaohsStaff__name": "Cajado do Faraó",
    "item__W_PhoenixWing__gameplay": "Dispara flechas infundidas com as chamas de uma fênix caída.",
    "item__W_PhoenixWing__lore": "Feito de penas de fênix, este arco radiante canaliza o renascimento flamejante, inflamando as flechas com chamas ardentes.",
    "item__W_PhoenixWing__name": "Asa da Fênix",
    "item__W_PoseidonsWand__gameplay": "Dispara peixes.",
    "item__W_PoseidonsWand__lore": "PEIXEEEEEEEEEEEEE!",
    "item__W_PoseidonsWand__name": "Varinha de Poseidon",
    "item__W_QuincyBow__gameplay": "Dispara flechas infundidas com energia elétrica.",
    "item__W_QuincyBow__lore": "Dispara flechas feitas da energia espiritual do portador.",
    "item__W_QuincyBow__name": "Arco de Quincy",
    "item__W_SilentShadow__gameplay": "Dispara flechas impregnadas de energia das sombras.",
    "item__W_SilentShadow__lore": "\"Eles chegaram sem aviso e nos pegaram de surpresa. Conseguimos nos defender, mas perdemos muitos no processo.\"",
    "item__W_SilentShadow__name": "Sombra Silenciosa",
    "item__W_RangersGuillotine__gameplay": "Dispara flechas envenenadas.",
    "item__W_RangersGuillotine__lore": "Com precisão mortal, este arco brutal corta defesas, cravando suas vítimas com flechas envenenadas.",
    "item__W_RangersGuillotine__name": "Guilhotina do Ranger",
    "item__W_ReapersEmbrace__gameplay": "Dispara projéteis poderosos do caos.",
    "item__W_ReapersEmbrace__lore": "Um arco espectral que drena vida a cada flecha, envolvendo os inimigos no abraço gélido do Ceifador.",
    "item__W_ReapersEmbrace__name": "Abraço do Ceifador",
    "item__W_RelicWand__gameplay": "Manifesta projéteis físicos que causam alto dano.",
    "item__W_RelicWand__lore": "Canaliza a essência de civilizações perdidas e magias esquecidas.",
    "item__W_RelicWand__name": "Varinha Relicária",
    "item__W_ReliqueryCrossbow__gameplay": "Dispara parafusos do caos de alto dano.",
    "item__W_ReliqueryCrossbow__lore": "Besta usada pelos sacerdotes do Relicário Vampírico.",
    "item__W_ReliqueryCrossbow__name": "Besta do Relicário",
    "item__W_SamsStirringStick__gameplay": "Coloca uma armadilha visível perto dos seus pés que causa dano arcano.",
    "item__W_SamsStirringStick__lore": "A colher de um chef lendário, imbuida com o poder dos elementos (culinários).",
    "item__W_SamsStirringStick__name": "Colher de Sam",
    "item__W_SlimeStaff__gameplay": "Coloca uma armadilha visível perto dos seus pés que causa dano de natureza.",
    "item__W_SlimeStaff__lore": "Um cajado pulsante com a essência dos slimes, permitindo ao portador invocar e comandar essas criaturas gelatinosas.",
    "item__W_SlimeStaff__name": "Cajado de Slime",
    "item__W_SpellbindersTome__gameplay": "Emite projéteis arcanos contra seus oponentes.",
    "item__W_SpellbindersTome__lore": "Um místico livro encadernado em couro que captura mentes com feitiços intricados de encantamento.",
    "item__W_SpellbindersTome__name": "Tomo do Encantador",
    "item__W_SpiraledWand__gameplay": "Dispara raios de energia mágica cinética.",
    "item__W_SpiraledWand__lore": "Na verdade, é apenas um bastão mais elegante.",
    "item__W_SpiraledWand__name": "Bastão Espiralado",
    "item__W_StaffOfTheSnake__gameplay": "Coloca uma armadilha visível perto dos seus pés que causa dano tóxico.",
    "item__W_StaffOfTheSnake__lore": "Um cajado adornado com a cabeça de uma cobra, esculpida em Esmeralda. Dizem que ele pode cuspir veneno nos inimigos.",
    "item__W_StaffOfTheSnake__name": "Cajado da Serpente",
    "item__W_StarShot__gameplay": "Dispara flechas arcanas fortes infundidas com energia estelar.",
    "item__W_StarShot__lore": "Esculpido a partir de fragmentos celestiais, este arco libera uma cascata de projéteis brilhantes, um espetáculo celestial de destruição.",
    "item__W_StarShot__name": "Tiro Estelar",
    "item__W_StarWand__gameplay": "Emite o poder das estrelas contra seus oponentes.",
    "item__W_StarWand__lore": "Um instrumento extravagante e brilhante.",
    "item__W_StarWand__name": "Varinha Estelar",
    "item__W_SteelCrossbow__gameplay": "Dispara parafusos de besta que perfuram até mesmo as peles mais espessas.",
    "item__W_SteelCrossbow__lore": "Uma besta padrão feita de aço.",
    "item__W_SteelCrossbow__name": "Besta de Aço",
    "item__W_Stick__gameplay": "Dispara faíscas de energia mágica.",
    "item__W_Stick__lore": "Existem muitos como ele, mas este é meu!",
    "item__W_Stick__name": "Bastão",
    "item__W_TheCrossBow__gameplay": "Dispara projéteis sagrados em alta velocidade.",
    "item__W_TheCrossBow__lore": "Entregue a justiça dos senhores com esta lendária besta.",
    "item__W_TheCrossBow__name": "A Besta Cruzada",
    "item__W_ToxicWand__gameplay": "Dispara uma esfera tóxica.",
    "item__W_ToxicWand__lore": "Pode ser viciante, mas você deve saber que é tóxico.",
    "item__W_ToxicWand__name": "Varinha Tóxica",
    "item__W_Triforce__gameplay": "Emite o poder da Triforce contra seus oponentes.",
    "item__W_Triforce__lore": "Um tomo radiante que desbloqueia os poderes divinos da coragem, sabedoria e poder.",
    "item__W_Triforce__name": "Triforce",
    "item__W_TwistedBranch__gameplay": "Coloca uma armadilha visível perto dos seus pés que causa dano de fogo.",
    "item__W_TwistedBranch__lore": "Um bastão rudimentar que foi encantado por um feiticeiro tardio.",
    "item__W_TwistedBranch__name": "Ramo Torcido",
    "item__W_WitchHuntersRepeaters__gameplay": "Dispara parafusos infundidos com uma radiância sagrada.",
    "item__W_WitchHuntersRepeaters__lore": "Bestas comuns usadas pelos Caçadores de Bruxas da Ordem Arcana.",
    "item__W_WitchHuntersRepeaters__name": "Repetidor dos Caçadores de Bruxas",
    "item__W_WizardsLedger__gameplay": "Dispara MOEDAS rapidamente. Sim, moedas.",
    "item__W_WizardsLedger__lore": "Isso é pagar para ganhar?",
    "item__W_WizardsLedger__name": "Livro de Contabilidade do Mago",
    "item__W_WyvernsTalon__gameplay": "Dispara parafusos poderosos que causam dano de sangramento.",
    "item__W_WyvernsTalon__lore": "Besta feita das garras de um grande wyvern.",
    "item__W_WyvernsTalon__name": "Garra do Wyvern",
    "item__W_ZappingWand__gameplay": "Dispara projéteis de relâmpago.",
    "item__W_ZappingWand__lore": "Traz uma onda de poder eletrizante para o seu repertório mágico.",
    "item__W_ZappingWand__name": "Varinha de Choque",
    "item_container__loot_chest": "Baú de Saque",
    "item_container__loot_drop": "Queda de Saque",
    "item_rarity__common": "Comum",
    "item_rarity__epic": "Épico",
    "item_rarity__legendary": "Lendário",
    "item_rarity__rare": "Raro",
    "item_rarity__uncommon": "Incomum",
    "common": "Comum",
    "uncommon": "Incomum",
    "rare": "Raro",
    "epic": "Épico",
    "legendary": "Lendário",
    "item_slot__damage": "dano",
    "item_slot__equip_prompt": "Clique esquerdo para equipar!",
    "item_slot__extract_prompt": "Extrair para usar este item!",
    "item_slot__grants_ability": "Concede Habilidade",
    "item_slot__grants_passive": "Efeito Passivo",
    "item_slot__loot_prompt": "Clique esquerdo para saquear!",
    "loadout_menu__abilities_header": "Habilidades",
    "loadout_menu__back_to_main_menu_button": "Voltar",
    "loadout_menu__damage": "Dano",
    "loadout_menu__defence": "Defesa",
    "loadout_menu__gear_header": "Equipamento",
    "loadout_menu__health": "Vida",
    "loadout_menu__insurance_button": "Segurar",
    "loadout_menu__no_abilities": "Seu loadout não contém nenhuma habilidade.",
    "loadout_menu__no_slot_selected_insurance_text": "🚨 Nada está segurado! 🚨",
    "loadout_menu__no_slot_selected_insurance_text_2": "Nenhum slot está segurado! Assista a um anúncio para proteger 1 item.",
    "loadout_menu__no_slot_selected_scrollbox": "Selecione um slot para alterar seu loadout",
    "loadout_menu__scrollbox_with_gear_text": "Escolha algum equipamento",
    "loadout_menu__scrollbox_with_skins_text": "Escolha uma skin",
    "loadout_menu__scrollbox_with_weapons_text": "Escolha uma arma",
    "loadout_menu__skin_header": "Escolha uma Skin",
    "loadout_menu__slot_insured_text": "✅ Você está segurado! ✅",
    "loadout_menu__start_run_button": "Jogar",
    "loadout_menu__stat": "Estatística",
    "loadout_menu__title": "Loadout",
    "loadout_menu__undo": "Desfazer",
    "loadout_menu__weapons_header": "Armas",
    "loadout_menu__will_be_safe": "ficará seguro se você morrer.",
    "loadout_menu__your": "Seu",
    "login__header": "Fazer Login",
    "fullscreen": "Tela Cheia",
    "exit_fullscreen": "Sair da Tela Cheia",
    "loot_prompt": "[ E ] para saquear",
    "leaderboard__player_kills": "PK",
    "leaderboard__loot": "Itens",
    "leaderboard__npc_kills": "NPCK",
    "main_menu__patch_notes_btn": "Notas do Patch",
    "main_menu__change_username_btn": "Alterar Nome de Usuário",
    "main_menu__create_account_btn": "Criar Conta",
    "main_menu__collection_log_btn": "Registro de Coleção",
    "collection_log__header": "Registro de Coleção",
    "main_menu__discord_btn_tooltip": "Encontre amigos e junte-se a nós no Discord!",
    "main_menu__game_is_in_maintenance_mode": "PvPz.io está sendo atualizado! A nova atualização será implantada em breve, tente novamente em alguns minutos",
    "main_menu__how_to_play_btn": "Como Jogar",
    "main_menu__identity_error_title": "Ops",
    "main_menu__loading_bar_text": "Carregando...",
    "main_menu__logout_btn": "Sair",
    "main_menu__partners": "Parceiros",
    "main_menu__partners_and_credits": "Todos os parceiros e créditos",
    "main_menu__patch_prefix": "Atualização",
    "main_menu__patch_suffix": "chegando em 1º de junho!",
    "main_menu__play_btn": "Jogar",
    "main_menu__rankings_btn": "Classificações",
    "main_menu__settings_btn": "Configurações",
    "main_menu__sign_in_btn": "Entrar",
    "main_menu__sign_in_with_discord_btn": "Entrar com o Discord",
    "main_menu__sign_in_with_facebook_btn": "Entrar com o Facebook",
    "main_menu__sign_in_with_google_btn": "Entrar com o Google",
    "main_menu__twitter_btn_tooltip": "Siga-nos no Twitter!",
    "main_menu__vendors_btn": "Vendedores",
    "main_menu__view_inventory_btn": "Inventário",
    "main_menu__view_your_stats_btn": "Suas Estatísticas",
    "main_menu__website_btn_tooltip": "Visite nosso site!",
    "main_menu__welcome_panel_title": "Bem-vindo",
    "main_menu__welcome_panel_title_suffix": " de volta,",
    "main_menu__welcome_sign_in_cta": "Quer mudar seu nome de usuário? Faça login!",
    "main_menu_stash__collectors_items": "Itens de Colecionador",
    "main_menu_stash__discovered": "Descobertos",
    "main_menu_stash__gear": "Equipamento",
    "main_menu_stash__header": "Baú",
    "main_menu_stash__total_items_discovered": "Itens Descobertos",
    "main_menu_stash__vendor_items": "Itens de Vendedor",
    "main_menu_stash__weapons": "Armas",
    "player_skins__boltman": "Ranger",
    "player_skins__corrupted_wizard": "Mago Corrompido",
    "player_skins__knight": "Cavaleiro",
    "player_skins__red_wizard": "Aprendiz de Mago",
    "player_skins__thief": "Ladrão",
    "player_skins__veteran_wizard": "Mago da Escola do Dragão",
    "rankings__back_to_main_menu_button": "Voltar",
    "rankings__coming_soon": "Em Breve!",
    "rankings__title": "Classificações",
    "region__dreamers_forest": "Floresta dos Sonhadores",
    "region__ert_lich": "Ert Lich",
    "region__erthel": "Erthel",
    "region__forgotten_temple": "Templo Esquecido",
    "region__skull_lake": "Lago do Crânio",
    "region__swamp_of_sorrows": "Pântano das Tristezas",
    "region__the_edgelands": "Terras Limítrofes",
    "region__wilderness": "Selva",
    "dreamers forest": "Floresta dos Sonhadores",
    "ert lich": "Ert Lich",
    "erthel": "Erthel",
    "forgotten temple": "Templo Esquecido",
    "skull lake": "Lago do Crânio",
    "swamp of sorrows": "Pântano das Tristezas",
    "edgelands": "Terras Limítrofes",
    "wilderness": "Selva",
    "register__delete_account_cta": "Você é um usuário anônimo, portanto, sua conta será excluída em 2 semanas! Se você deseja manter seus itens e estatísticas e alterar seu nome de usuário, registre-se!",
    "register__delete_account_cta_2": "Você é um usuário anônimo, portanto, não pode ver estatísticas! Se você deseja ver suas estatísticas, alterar seu nome de usuário e desbloquear skins especiais, registre-se!",
    "register__delete_account_cta_less_aggressive": "Se você deseja salvar seus itens e estatísticas para sempre e alterar seu nome de usuário, registre-se!",
    "register__email": "Digite seu endereço de e-mail",
    "register__email_confirm": "Confirme o endereço de e-mail",
    "register__email_placeholder": "Endereço de E-mail",
    "register__email_placeholder_confirm": "Confirmar Endereço de E-mail",
    "register__header": "Registrar",
    "register__or": "Ou",
    "register__password": "Digite uma senha",
    "register__password_confirm": "Confirme sua senha",
    "register__register": "Registrar",
    "register__register_with_discord_btn": "Registrar com o Discord",
    "register__register_with_google_btn": "Registrar com o Google",
    "register__username": "Escolha um nome de usuário",
    "runprep__skin_not_unlocked_registered": "Apenas USUÁRIOS REGISTRADOS podem usar essa skin!",
    "settings__back_to_main_menu_button": "Voltar",
    "settings__enable_music": "Ativar Música",
    "settings__enable_sfx": "Ativar Efeitos Sonoros",
    "settings__language": "Idioma / LANGUAGE",
    "settings__language_dropdown_english": "Inglês / English",
    "settings__language_dropdown_portuguese": "Português / Portuguese",
    "settings__language_dropdown_russian": "Russo / Russian / Русский",
    "settings__language_dropdown_spanish": "Espanhol / Spanish / Español",
    "settings__matchmaking_region": "Região de Matchmaking",
    "settings__matchmaking_region_dropdown_africa": "África",
    "settings__matchmaking_region_dropdown_any": "Qualquer Região (mais rápido)",
    "settings__matchmaking_region_dropdown_asia": "Ásia",
    "settings__matchmaking_region_dropdown_australia_oceania": "Austrália/Oceania",
    "settings__matchmaking_region_dropdown_eu": "Europa",
    "settings__matchmaking_region_dropdown_middle_east": "Oriente Médio",
    "settings__matchmaking_region_dropdown_na_east": "América do Norte (Leste)",
    "settings__matchmaking_region_dropdown_na_west": "América do Norte (Oeste)",
    "settings__matchmaking_region_dropdown_south_america": "América do Sul",
    "settings__save_button": "Salvar",
    "settings__title": "Configurações",
    "settings__settings_saved": "Configurações Salvas!",
    "signin_sign_in": "Fazer login",
    "signin_sign_in_with_discord_btn": "Entrar com o Discord",
    "signin_sign_in_with_google_btn": "Entrar com o Google",
    "item_type__weapon": "Arma",
    "item_type__gear": "Equipamento",
    "item_type__vendor": "Item para Vendedor",
    "item_type__collector": "Item Especial",
    "item_type__unknown": "Desconhecido",
    "stat_type_arcane": "Arcano",
    "stat_type_bleed": "Sangramento",
    "stat_type_chaos": "Caos",
    "stat_type_fire": "Fogo",
    "stat_type_frost": "Gelo",
    "stat_type_holy": "Divino",
    "stat_type_lightning": "Relâmpago",
    "stat_type_nature": "Natureza",
    "stat_type_physical": "Físico",
    "stat_type_shadow": "Sombra",
    "stat_type_toxic": "Tóxico",
    "stats__back_btn": "Voltar",
    "stats__boss_kills": "Derrotas de Chefes",
    "stats__common_items_looted": "itens comuns saqueados",
    "stats__distanced_travelled": "distância percorrida",
    "stats__epic_items_looted": "itens épicos saqueados",
    "stats__failed_runs": "corridas fracassadas",
    "stats__global_rank": "classificação global",
    "stats__gold_looted": "ouro saqueado",
    "stats__gold_lost_to_deaths": "ouro perdido para mortes",
    "stats__header": "Estatísticas",
    "stats__items_looted": "itens saqueados",
    "stats__items_lost_to_deaths": "itens perdidos para mortes",
    "stats__legendary_items_looted": "itens lendários saqueados",
    "stats__npc_kills": "mortes de NPC",
    "stats__player_kills": "mortes de jogadores",
    "stats__rare_items_looted": "itens raros saqueados",
    "stats__run_success_percentage": "percentual de sucesso das corridas",
    "stats__runs": "corridas",
    "stats__successful_runs": "corridas bem-sucedidas",
    "stats__time_played": "tempo jogado",
    "stats__uncommon_items_looted": "itens incomuns saqueados",
    "toast__email_doesnt_match": "Os endereços de email não correspondem!",
    "toast__email_not_long_enough": "O endereço de email não é longo o suficiente!",
    "toast__gods_granted_you_items": "Os deuses concederam a você algum equipamento básico!",
    "toast__not_an_email_address": "Isso não parece ser um endereço de email válido!",
    "toast__password_not_long_enough": "A senha não é longa o suficiente!",
    "toast__passwords_dont_match": "As senhas não correspondem!",
    "toast__username_changed": "Nome de usuário alterado com sucesso!",
    "toast__username_too_short": "Nome de usuário muito curto!",
    "vendors__back_to_main_menu_button": "Voltar",
    "vendors__coming_soon": "Em Breve!",
    "vendors__title": "Vendedores",
    "event_feed__looted_a": "saqueou um",
    "event_feed__in_the": "em",
    "event_feed__at": "em",
    "event_feed__killed": "matou",
    "event_feed__boss": "boss",
    "event_feed__is_extracting": "está extraindo",
    "event_feed__has_extracted": "extraiu com sucesso!",
    "event_feed__has_spawned": "apareceu em",
    "event_feed__under_attack": "está sob ataque em"
}

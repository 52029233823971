import { AnimatedSprite, Container, Graphics, Texture } from "pixi.js";
import { IGameEntityBasic } from "../../shared/SharedTypes";
import { ExtractionPointEntityCreationPayload, NType } from "../../shared/SharedNetcodeSchemas";
import { Vector } from "sat";
import { IRenderedEntity } from "../ClientTypes";
import { extractionConfig } from "../../shared/config/Config_Extraction";

// import { ShockwaveFilter } from "@pixi/filter-shockwave";

export class ClientExtractionPoint implements IGameEntityBasic, IRenderedEntity {
    public nid: number;
    public ntype: NType;
    public position: SAT.Vector = new Vector(0, 0);
    public width: number;
    public height: number;
    public RenderedEntity: Container = new Container();
    public hitboxGraphics: Graphics = new Graphics();
    public pfxSprite: AnimatedSprite;

    public constructor(createExtractionPointPayload: ExtractionPointEntityCreationPayload) {
        const { nid, ntype, x, y, width, height } = createExtractionPointPayload;
        this.nid = nid;
        this.ntype = ntype;
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
        this.RenderedEntity.zIndex = y;

        // this.RenderedEntity.filters = [new ShockwaveFilter(this.RenderedEntity.position)];

        const topAnimation = Game.Loader.GetAnimation("pfx/extractEffect/effect_extractZone");
        const topFrames = new Array<Texture>();
        for (let i = 0; i < topAnimation.length; i++) {
            topFrames.push(Texture.from(topAnimation[i]));
        }
        this.pfxSprite = new AnimatedSprite(topFrames);
        this.pfxSprite.position.set(this.width / 2, this.height / 2 - 8);
        this.pfxSprite.zIndex = y;
        this.pfxSprite.scale.set(1);
        this.pfxSprite.anchor.set(0.5, 0.5);
        this.pfxSprite.animationSpeed = 1 / 9;
        this.pfxSprite.play();
        this.RenderedEntity.addChild(this.pfxSprite);

        if (extractionConfig.DEBUG.DRAW_COLLIDER) {
            this.hitboxGraphics.lineStyle(2, 0x00ff00);
            this.hitboxGraphics.drawRect(this.x, this.y, this.width, this.height);
            this.RenderedEntity.addChild(this.hitboxGraphics);
        }
    }

    public get x(): number {
        return this.position.x;
    }

    public set x(newX: number) {
        this.position.x = newX;
        this.RenderedEntity.x = this.x;
    }

    public get y(): number {
        return this.position.y;
    }

    public set y(newY: number) {
        this.position.y = newY;
        this.RenderedEntity.y = this.y;
    }

    public Update(__deltaTime: number): void {}
}

import React from "react";
import { ItemRarity, ItemType, Item, getFriendlyItemTypeName, getItemData, DamageType } from "../../../shared/data/Data_Items";
import { Tooltip } from "react-tooltip";
import ReactDOM from "react-dom";
import { getWeaponData } from "../../../shared/data/Data_Weapons";

// @ts-ignore
import lockImageSource from "../kit/locked.png";

// @ts-ignore
import arcaneDamageTypeImage from "../kit/icon_arcane.png";
// @ts-ignore
import bleedDamageTypeImage from "../kit/icon_bleed.png";
// @ts-ignore
import chaosDamageTypeImage from "../kit/icon_chaos.png";
// @ts-ignore
import fireDamageTypeImage from "../kit/icon_fire.png";
// @ts-ignore
import frostDamageTypeImage from "../kit/icon_frost.png";
// @ts-ignore
import holyDamageTypeImage from "../kit/icon_holy.png";
// @ts-ignore
import lightningDamageTypeImage from "../kit/icon_lightning.png";
// @ts-ignore
import natureDamageTypeImage from "../kit/icon_nature.png";
// @ts-ignore
import physicalDamageTypeImage from "../kit/icon_physical.png";
// @ts-ignore
import shadowDamageTypeImage from "../kit/icon_shadow.png";
// @ts-ignore
import toxicDamageTypeImage from "../kit/icon_toxic.png";

// @ts-ignore
import mouseClickImage from "../kit/mouse-leftclick.png";
import { t } from "../../../shared/data/Data_I18N";

export enum SlotVisualOptions {
    Inventory = 0,
    Gear = 1,
    Weapon = 2
}

export type SlotSize = "normal" | "large";

interface ItemSlotProps {
    itemId: Item;
    slotVisualType: SlotVisualOptions;
    quantity?: number;
    slotSize?: SlotSize;
    selected?: boolean;
    showLeftClickEquipPrompt?: boolean;
    showLeftClickLootPrompt?: boolean;
    showYouMustExtractPrompt?: boolean;
    showInsuredLock?: boolean;
    disableHover?: boolean;
    OnClickFn: (itemId: Item) => void;
}

export const getGearAbilitiesBlobFromGameplayText = (itemType: ItemType, gameText: string) => {
    switch (itemType) {
        case ItemType.Weapon:
        case ItemType.VendorTrash:
        case ItemType.CollectorsItem:
            return (
                <span className="weapon-prefix" key={`item-slot-weapon-prefix-${Math.random() * 10000}`}>
                    {gameText}
                </span>
            );
        case ItemType.ActiveGear:
        case ItemType.PassiveGear:
            return (
                <span className="gear-abilities">
                    {gameText.split(" | ").map((txt: string) => {
                        return (
                            <span className="ability-line-item" key={`item-slot-ability-line-${Math.random() * 10000}`}>
                                &#8226; {txt}
                            </span>
                        );
                    })}
                </span>
            );
        default:
            return <></>;
    }
};

export const ItemSlot = (props: ItemSlotProps) => {
    const { OnClickFn: onClickFn, itemId, quantity, slotVisualType, slotSize, selected, showLeftClickEquipPrompt, showLeftClickLootPrompt, showYouMustExtractPrompt, showInsuredLock, disableHover } = props;

    const itemConfig = getItemData(itemId)!;
    const random = Math.floor(Math.random() * 100000);

    const tooltipContainer = document.getElementById("tooltip-container") as Element;

    const renderedSlotSize = slotSize ? slotSize : "normal";

    let { name, gameplayText, loreText } = itemConfig;

    // console.warn("@@@ translations");
    name = t(name);
    gameplayText = t(gameplayText);
    loreText = t(loreText);
    // console.log({ name, gameplayText, loreText });
    // console.warn("@@@ translations");

    const { itemType, itemRarity, inventorySpriteId, smallInventorySlotRotation, smallInventorySlotSpriteScale, smallInventorySlotSpriteOffsetLeft, smallInventorySlotSpriteOffsetTop, largeInventorySlotRotation, largeInventorySlotSpriteScale, largeInventorySlotSpriteOffsetLeft, largeInventorySlotSpriteOffsetTop, tooltipSlotRotation, tooltipSlotSpriteScale, tooltipSlotSpriteOffsetLeft, tooltipSlotSpriteOffsetTop } = itemConfig;

    const spritePath = "/sprites/" + inventorySpriteId;

    let spriteImage;
    let damageTypeSprite;

    let damageType: DamageType = DamageType.None;

    if (itemType === ItemType.Weapon) {
        const weaponConfig = getWeaponData(itemId)!;
        damageType = weaponConfig.damageType;

        // console.warn("@@@ Damage type:", damageType);

        switch (damageType) {
            case DamageType.Arcane:
                damageTypeSprite = <img draggable="false" className="damage-type-icon" src={arcaneDamageTypeImage} alt="" />;
                break;
            case DamageType.Bleed:
                damageTypeSprite = <img draggable="false" className="damage-type-icon" src={bleedDamageTypeImage} alt="" />;
                break;
            case DamageType.Chaos:
                damageTypeSprite = <img draggable="false" className="damage-type-icon" src={chaosDamageTypeImage} alt="" />;
                break;
            case DamageType.Fire:
                damageTypeSprite = <img draggable="false" className="damage-type-icon" src={fireDamageTypeImage} alt="" />;
                break;
            case DamageType.Frost:
                damageTypeSprite = <img draggable="false" className="damage-type-icon" src={frostDamageTypeImage} alt="" />;
                break;
            case DamageType.Holy:
                damageTypeSprite = <img draggable="false" className="damage-type-icon" src={holyDamageTypeImage} alt="" />;
                break;
            case DamageType.Lightning:
                damageTypeSprite = <img draggable="false" className="damage-type-icon" src={lightningDamageTypeImage} alt="" />;
                break;
            case DamageType.Nature:
                damageTypeSprite = <img draggable="false" className="damage-type-icon" src={natureDamageTypeImage} alt="" />;
                break;
            case DamageType.Physical:
                damageTypeSprite = <img draggable="false" className="damage-type-icon" src={physicalDamageTypeImage} alt="" />;
                break;
            case DamageType.Shadow:
                damageTypeSprite = <img draggable="false" className="damage-type-icon" src={shadowDamageTypeImage} alt="" />;
                break;
            case DamageType.Toxic:
                damageTypeSprite = <img draggable="false" className="damage-type-icon" src={toxicDamageTypeImage} alt="" />;
                break;
            default:
                damageTypeSprite = <></>;
                break;
        }
    }

    if (renderedSlotSize === "large") {
        spriteImage = <img draggable="false" className="item-image" src={spritePath} alt="" style={{ transform: `rotate(${largeInventorySlotRotation}deg) scale(${largeInventorySlotSpriteScale})`, top: `${largeInventorySlotSpriteOffsetTop}px`, left: `${largeInventorySlotSpriteOffsetLeft}px` }} />;
    } else {
        spriteImage = <img draggable="false" className="item-image" src={spritePath} alt="" style={{ transform: `rotate(${smallInventorySlotRotation}deg) scale(${smallInventorySlotSpriteScale})`, top: `${smallInventorySlotSpriteOffsetTop}px`, left: `${smallInventorySlotSpriteOffsetLeft}px` }} />;
    }

    return (
        <div
            className={`item-slot slot-type-${SlotVisualOptions[slotVisualType].toLowerCase()} slot-size-${renderedSlotSize} ${selected ? "selected" : ""} ${disableHover ? "disable-hover" : ""} slot-rarity-${ItemRarity[itemRarity].toLowerCase()}`}
            data-tooltip-id={`item-tooltip-${itemId}-${random}`}
            onClick={(__e) => {
                onClickFn(itemId);
            }}
        >
            {quantity && quantity >= 2 && <span className="quantity">{quantity}</span>}

            {showInsuredLock && <img draggable="false" className="insured-lock" src={lockImageSource} alt="" />}

            <div className="selection-parent">
                <div className="selection-corner top-left"></div>
                <div className="selection-corner top-right"></div>
                <div className="selection-corner bottom-left"></div>
                <div className="selection-corner bottom-right"></div>
            </div>

            {spriteImage}

            {damageTypeSprite}

            {ReactDOM.createPortal(
                <Tooltip id={`item-tooltip-${itemId}-${random}`} className="fancy-tooltip">
                    <div>
                        <img draggable="false" className="item-image" style={{ transform: `rotate(${tooltipSlotRotation}deg) scale(${tooltipSlotSpriteScale})`, top: `${tooltipSlotSpriteOffsetTop}px`, left: `${tooltipSlotSpriteOffsetLeft}px` }} src={spritePath} alt="" />
                        <h3 className={`item-name ${ItemRarity[itemRarity].toLowerCase()}`}>{name}</h3>
                        <p className="item-type">{t(ItemRarity[itemRarity].toLowerCase()) + " " + getFriendlyItemTypeName(itemType)}</p>

                        {damageType !== DamageType.None && (
                            <div className="damage-type-line">
                                {damageTypeSprite}{" "}
                                <span>
                                    {DamageType[damageType]} {t("item_slot__damage")}
                                </span>
                            </div>
                        )}
                        {loreText.length !== 0 && <p className="item-description">{loreText}</p>}
                        {gameplayText.length !== 0 && <div className="item-gameplay-info">{getGearAbilitiesBlobFromGameplayText(itemType, gameplayText)}</div>}
                        {showLeftClickEquipPrompt === true && (
                            <p className="item-click-info">
                                <img draggable="false" src={mouseClickImage} alt="" />
                                {t("item_slot__equip_prompt")}
                            </p>
                        )}
                        {showLeftClickLootPrompt === true && (
                            <p className="item-click-info">
                                <img draggable="false" src={mouseClickImage} alt="" />
                                {t("item_slot__loot_prompt")}
                            </p>
                        )}
                        {showYouMustExtractPrompt === true && <p className="item-extract-info">🚨 {t("item_slot__extract_prompt")}</p>}
                    </div>
                </Tooltip>,
                tooltipContainer
            )}
        </div>
    );
};

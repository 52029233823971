import React from "react";
import { Screens, UI_AbandonRun, UI_SwapScreen, abandonRunStateAtom } from "../framework/UI_State";
import { useRecoilValue } from "recoil";
import { t } from "../../../shared/data/Data_I18N";

export const RunInProgress = () => {
    const { abandoningInProgress } = useRecoilValue(abandonRunStateAtom);
    return (
        <>
            <div className="runinprogress-panel animate__animated animate__fadeInUp animate__faster">
                <div className="runinprogress-header">{t("character_already_on_run_menu__title")}</div>

                <h3>{t("character_already_on_run_menu__bolded_header")}</h3>

                <p>{t("character_already_on_run_menu__paragraph_one")}</p>

                <p>{t("character_already_on_run_menu__paragraph_two")}</p>

                <div className="abandon-actions">
                    <button
                        className="neutral-button"
                        onClick={() => {
                            UI_SwapScreen(Screens.MainMenu);
                        }}
                    >
                        {t("character_already_on_run_menu__back_to_main_menu_button")}
                    </button>

                    <button
                        disabled={abandoningInProgress}
                        className="abandon-btn destructive-button"
                        onClick={() => {
                            UI_AbandonRun();
                        }}
                    >
                        {t("character_already_on_run_menu__abondon_run_button")}
                    </button>
                </div>
            </div>
        </>
    );
};

import React, { useState } from "react";
import { Screens, UI_RegisterEmail, UI_SetAgreedToPromotion, UI_SwapScreen, playerIdentityStateAtom, registerCheckboxesStateAtom } from "../framework/UI_State";
import { useRecoilValue } from "recoil";
import { t } from "../../../shared/data/Data_I18N";
import { clientIsInIframe } from "../../../shared/SharedUtils";

export const Register = () => {
    const { loadingPlayerIdentity } = useRecoilValue(playerIdentityStateAtom);
    const { emailPromo } = useRecoilValue(registerCheckboxesStateAtom);

    const [usernameValue, setUsernameValue] = useState("");
    const [emailAddressValue, setEmailAddressValue] = useState("");
    const [confirmEmailAddressValue, setConfirmEmailAddressValue] = useState("");
    const [passwordValue, setPasswordValue] = useState("");
    const [confirmPasswordValue, setConfirmPasswordValue] = useState("");

    const onUsernameInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUsernameValue(e.target.value);
    };

    const onEmailAddressInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmailAddressValue(e.target.value);
    };

    const onConfirmEmailAddressInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmEmailAddressValue(e.target.value);
    };

    const onPasswordInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPasswordValue(e.target.value);
    };

    const onConfirmPasswordInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPasswordValue(e.target.value);
    };

    const togglePromo = () => {
        UI_SetAgreedToPromotion(!emailPromo);
    };

    // const errorNodes = [];

    // if (username.length === 0) {
    //     errorNodes.push(<p>{t("change_username__cant_be_empty_error")}</p>);
    // } else if (username.length >= 20) {
    //     errorNodes.push(<p>{t("change_username__too_long_error")}</p>);
    // }

    // const shouldDisableRegisterButton = loadingPlayerIdentity || usernameValue.length === 0 || emailAddressValue.length === 0;

    return (
        <>
            <div className="register-panel animate__animated animate__fadeInUp animate__faster">
                <div className="register-header">{t("register__header")}</div>

                <p className="terms-prompt">
                    {t("register__terms_label_1")}{" "}
                    <a href="https://app.termly.io/document/terms-of-service/293416da-8b5f-45b6-8b0b-966b73214f72" target="_blank" rel="noreferrer noopener">
                        {t("register__terms_label_2")}
                    </a>{" "}
                    and{" "}
                    <a href="https://app.termly.io/document/privacy-policy/a88f9624-708f-4045-a224-5fbae9a0d2ea" target="_blank" rel="noreferrer noopener">
                        {t("register__terms_label_3")}{" "}
                    </a>
                    and{" "}
                    <a href="https://app.termly.io/document/cookie-policy/a5d9d64f-141a-4b81-bf08-542969f3bf6b" target="_blank" rel="noreferrer noopener">
                        Cookie Policy
                    </a>
                    .
                </p>

                <div className="checkboxes">
                    <div className="setting-row">
                        <div className="setting-label">{t("register__promo_label")}</div>
                        <div className="setting-control">
                            <div className="toggle-switch enabled" onClick={togglePromo}>
                                {emailPromo === true && <div className="toggle-switch-checked"></div>}
                                {emailPromo === false && <div className="toggle-switch-unchecked"></div>}
                            </div>
                        </div>
                    </div>
                </div>

                {clientIsInIframe() === false && (
                    <>
                        <div className="register-socials">
                            <a className="social-btn discord-oauth-btn" href={process.env.OAUTH_DISCORD_ADDRESS}>
                                {t("register__register_with_discord_btn")}
                            </a>
                            <a className="social-btn google-oauth-btn" href={process.env.OAUTH_GOOGLE_ADDRESS}>
                                {t("register__register_with_google_btn")}
                            </a>
                        </div>

                        <div className="register-or-separator">{t("register__or")}</div>
                    </>
                )}

                <div className="register-form">
                    {/* {errorNodes.length > 0 && <div className="errors">{errorNodes}</div>} */}

                    <div className="form-row-wrapper">
                        <div className="form-label">
                            <p>{t("register__username")}</p>
                        </div>
                        <div className="form-input">
                            <input className="register-input" type="text" value={usernameValue} onChange={onUsernameInputChanged} disabled={loadingPlayerIdentity} placeholder={t("change_username__enter_new")} />
                        </div>
                    </div>

                    <div className="form-row-wrapper">
                        <div className="form-label">
                            <p>{t("register__email")}</p>
                        </div>
                        <div className="form-input">
                            <input className="register-input" type="text" value={emailAddressValue} onChange={onEmailAddressInputChanged} disabled={loadingPlayerIdentity} placeholder={t("register__email_placeholder")} />
                        </div>
                    </div>

                    <div className="form-row-wrapper">
                        <div className="form-label">
                            <p>{t("register__email_confirm")}</p>
                        </div>
                        <div className="form-input">
                            <input className="register-input" type="text" value={confirmEmailAddressValue} onChange={onConfirmEmailAddressInputChanged} disabled={loadingPlayerIdentity} placeholder={t("register__email_placeholder_confirm")} />
                        </div>
                    </div>

                    <div className="form-row-wrapper">
                        <div className="form-label">
                            <p>{t("register__password")}</p>
                        </div>
                        <div className="form-input">
                            <input className="register-input" type="password" value={passwordValue} onChange={onPasswordInputChanged} disabled={loadingPlayerIdentity} placeholder={""} />
                        </div>
                    </div>

                    <div className="form-row-wrapper">
                        <div className="form-label">
                            <p>{t("register__password_confirm")}</p>
                        </div>
                        <div className="form-input">
                            <input className="register-input" type="password" value={confirmPasswordValue} onChange={onConfirmPasswordInputChanged} disabled={loadingPlayerIdentity} placeholder={""} />
                        </div>
                    </div>
                </div>

                <div className="register-actions">
                    <button
                        className="neutral-button"
                        onClick={() => {
                            UI_SwapScreen(Screens.MainMenu);
                        }}
                    >
                        {t("change_username__back")}
                    </button>

                    <button
                        style={{ width: "225px" }}
                        disabled={loadingPlayerIdentity}
                        className="register-btn affirmative-button"
                        onClick={() => {
                            console.log("Registering with values:");
                            UI_RegisterEmail(usernameValue, emailAddressValue, confirmEmailAddressValue, passwordValue, confirmPasswordValue);
                        }}
                    >
                        {t("register__register")}
                    </button>
                </div>
            </div>
        </>
    );
};

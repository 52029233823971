import { TiledMapRegionObject, WorldRegionsFromTiledMap } from "../../shared/data/Data_WorldImport";
import { GameplaySystem } from "../../shared/engine/SharedGameplaySystem";
import { ClientPlayer } from "../entities/ClientPlayer";
import { UI_HideRegionPopUp, UI_ShowRegionPopUp } from "../ui/framework/UI_State";
import { PopToast, ToastStatuses } from "../ui/kit/toast-utils";

export enum DebugShape {
    Circle = 0,
    Rectangle = 1,
    Line = 2
}

interface RegionBounds {
    name: string;
    minX: number;
    minY: number;
    maxX: number;
    maxY: number;
}

export class ClientRegionPopUpManager extends GameplaySystem {
    private myEnitityIdentified: boolean = false;
    private myEntityRef: ClientPlayer;
    private regions: RegionBounds[] = [];

    private readonly duplicatePopUpDelayInSeconds = 10;

    private lastPoppedRegion: string = "";
    private regionPopUpMetadata: Map<string, { accumulator: number }> = new Map<string, { accumulator: number }>();

    public constructor() {
        super();
    }

    protected override getSystemName(): string {
        return "RegionPopUps";
    }

    public Initialize(): void {
        this.LogInfo("Ready!");

        for (let i = 0; i < WorldRegionsFromTiledMap.objects.length; i++) {
            const region = WorldRegionsFromTiledMap.objects[i] as TiledMapRegionObject;
            const bounds = {
                name: region.name,
                minX: region.x,
                minY: region.y,
                maxX: region.x + region.width,
                maxY: region.y + region.height
            };
            this.regions.push(bounds);
        }

        Game.ListenForEvent("Bootstrap::MyServerSideEntityIsFullyCreatedLocally", (event) => {
            // @ts-ignore
            const { myEntity } = event;
            this.myEntityRef = myEntity;
            this.myEnitityIdentified = true;
        });
    }

    public SetMyEntityRef(entity: ClientPlayer) {
        this.myEntityRef = entity;
        this.myEnitityIdentified = true;
    }

    private _myEntityIsInRegionBounds(region: RegionBounds): boolean {
        const myEntityPosition = this.myEntityRef.position;
        return myEntityPosition.x >= region.minX && myEntityPosition.x <= region.maxX && myEntityPosition.y >= region.minY && myEntityPosition.y <= region.maxY;
    }

    private _itsThisPlayersFirstTimeInRegion(region: RegionBounds): boolean {
        const metadataForRegion = this.regionPopUpMetadata.get(region.name);
        if (metadataForRegion === undefined) return true;
        return false;
    }

    private _popRegionNotification(regionName: string): void {
        const regionMetadata = this.regionPopUpMetadata.get(regionName);
        regionMetadata!.accumulator = 0;

        UI_ShowRegionPopUp(regionName);

        // PopToast({
        //     message: `You are now in ${regionName}`,
        //     status: ToastStatuses.NONE
        // });
        this.lastPoppedRegion = regionName;

        setTimeout(() => {
            UI_HideRegionPopUp();
        }, 3000);
    }

    public override Update(deltaTime: number): void {
        if (this.myEnitityIdentified === false) return;
        let playerIsInARegion = false;
        let regionNameToPop = "";

        this.regions.forEach((region) => {
            if (this._myEntityIsInRegionBounds(region)) {
                playerIsInARegion = true;
                regionNameToPop = region.name;
                if (this._itsThisPlayersFirstTimeInRegion(region)) {
                    this.regionPopUpMetadata.set(region.name, { accumulator: this.duplicatePopUpDelayInSeconds });
                    const newMetadata = this.regionPopUpMetadata.get(region.name);
                    newMetadata!.accumulator += deltaTime;
                } else {
                    const existingMetadata = this.regionPopUpMetadata.get(region.name);
                    // @ts-ignore
                    existingMetadata.accumulator += deltaTime;
                }
            }
        });

        if (playerIsInARegion === false) this.lastPoppedRegion = "";

        const regionMetadata = this.regionPopUpMetadata.get(regionNameToPop);
        const shouldPopRegionNotification = playerIsInARegion && this.lastPoppedRegion !== regionNameToPop && regionMetadata!.accumulator >= this.duplicatePopUpDelayInSeconds;

        if (this.lastPoppedRegion !== regionNameToPop && regionMetadata!.accumulator <= this.duplicatePopUpDelayInSeconds) {
            this.lastPoppedRegion = regionNameToPop;
        }

        if (shouldPopRegionNotification) {
            this._popRegionNotification(regionNameToPop);
        } else {
            // console.warn(`@@@ not popping region notification.\nplayerIsInARegion: ${playerIsInARegion},\nregionNameToPop: ${regionNameToPop},\nlastPoppedRegion: ${this.lastPoppedRegion},\nlastPoppedRegion is the same: ${this.lastPoppedRegion === regionNameToPop}`);
            // if (regionMetadata !== undefined) console.warn("@@@", regionMetadata.accumulator);
        }
    }

    public override Cleanup(): void {}
}

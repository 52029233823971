import React from "react";
import { Screens, UI_SwapScreen } from "../framework/UI_State";
import { t } from "../../../shared/data/Data_I18N";

export const Vendors = () => {
    return (
        <>
            <div className="vendors-panel animate__animated animate__fadeInUp animate__faster">
                <div className="vendors-header">{t("vendors__title")}</div>

                <h1>{t("vendors__coming_soon")}</h1>

                <button
                    className="neutral-button"
                    onClick={() => {
                        UI_SwapScreen(Screens.MainMenu);
                    }}
                >
                    {t("vendors__back_to_main_menu_button")}
                </button>
            </div>
        </>
    );
};

import React from 'react';
import { SlotSize, SlotVisualOptions } from './UI_ItemSlot';

// @ts-ignore
import lockImageSource from '../kit/locked.png';

interface EmptyItemSlotProps {
    slotVisualType: SlotVisualOptions;
    slotSize?: SlotSize;
    onClickFn?: () => void;
    selected?: boolean;
    showInsuredLock?: boolean;
    disableHover?: boolean;
}

export const EmptyItemSlot = (props: EmptyItemSlotProps) => {
    const { slotVisualType, slotSize, onClickFn, selected, showInsuredLock, disableHover } = props;
    const renderedSlotSize = slotSize ? slotSize : 'normal';

    return (
        <div className={'item-slot' + ` slot-type-${SlotVisualOptions[slotVisualType].toLowerCase()} ` + `slot-size-${renderedSlotSize}` + ` ${selected ? 'selected' : ''} ${disableHover ? 'disable-hover' : ''}`} onClick={onClickFn}>
            {showInsuredLock && <img draggable="false" className="insured-lock" src={lockImageSource} alt="Insured Lock" />}
            <div className="selection-parent">
                <div className="selection-corner top-left"></div>
                <div className="selection-corner top-right"></div>
                <div className="selection-corner bottom-left"></div>
                <div className="selection-corner bottom-right"></div>
            </div>
        </div>
    );
};

/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { t } from "../../../shared/data/Data_I18N";

// @ts-ignore
import addictingGamesSrc from "../kit/addictinggames.png";
// @ts-ignore
import iogamesSrc from "../kit/iogames.webp";
// @ts-ignore
import kongSrc from "../kit/kongregate.webp";
// @ts-ignore
import crazygamesSrc from "../kit/crazygames.png";
import { UI_SwapScreen } from "../framework/UI_State";
import { Screens } from "../framework/UI_State";

export const MainMenuLeftPanelContainer = React.memo(
    () => {
        const url = window.location != window.parent.location ? document.referrer : document.location.href;

        const isOnCrazygames = url.includes("crazygames.com");
        const isOnIoGames = url.includes("iogames.space");
        const isOnAddictingGames = url.includes("addictinggames");

        // console.warn("@@@ Parent URL:", url);
        // console.warn("OriginalLocation:", OriginalLocation);

        return (
            <div className="main-menu-left-panel-container">
                <button
                    className="neutral-button menu-btn patch-notes-btn"
                    onClick={() => {
                        UI_SwapScreen(Screens.PatchNotes);
                    }}
                >
                    {t("main_menu__patch_notes_btn")}
                </button>

                <h1>{t("main_menu__partners")}</h1>

                {isOnCrazygames === false && IsOnKongregate === false && (
                    <a target="_blank" rel="noreferrer noopener" href="https://addictinggames.com/">
                        <img className="iogames" src={addictingGamesSrc} alt="Addicting Games" />
                    </a>
                )}

                {isOnCrazygames === false && IsOnKongregate === false && (
                    <a target="_blank" rel="noreferrer noopener" href="https://iogames.space/">
                        <img className="iogames" src={iogamesSrc} alt="iogames.space" />
                    </a>
                )}

                {(isOnIoGames === false || isOnAddictingGames === false) && IsOnKongregate === false && (
                    <a target="_blank" rel="noreferrer noopener" href="https://crazygames.com/">
                        <img className="crazygames" src={crazygamesSrc} alt="Crazy Games" />
                    </a>
                )}

                {IsOnKongregate === true && isOnAddictingGames === false && isOnIoGames === false && isOnCrazygames === false && (
                    <a target="_blank" rel="noreferrer noopener" href="https://kongregate.com/">
                        <img className="kongregate" src={kongSrc} alt="Kongregate" />
                    </a>
                )}

                {isOnCrazygames === false && (
                    <button
                        className="none-button"
                        onClick={() => {
                            UI_SwapScreen(Screens.Partners);
                        }}
                    >
                        { t("main_menu__partners_and_credits") }
                    </button>
                )}
            </div>
        );
    },
    (__prepProps, __nextProps) => true
);

import { AnonymousUser, PlayerLoadout, RegisteredUser, UserType, uuid } from "../../shared/SharedTypes";
import { CharacterSkin } from "../../shared/data/Data_Cosmetics";
import { Item } from "../../shared/data/Data_Items";
import { LoadoutSlots } from "../../shared/systems/SharedGearAndWeaponSystem";
import { EquipItemToSlotResponse, EquipSkinResponse, InsureSlotResponse, PlayerPreferences, UI_GetPreferredRegion } from "../ui/framework/UI_State";
import { LSKeys, loadFromLocalStorage, saveToLocalStorage } from "../utils/ClientLocalStorage";
import { AuthExpiredError, UserNotFoundError, UnknownAPIError, AllServersFullError, AlreadyOnARunError, NoServersOnlineError, UsernameAlreadyTakenError, UsernameInappropriateError, EmailInvalidError, EmailAlreadyTakenError, EmailNotFoundError, IncorrectLoginMethodError, InvalidPasswordError } from "./ClientAPIErrors";
import { ClientAPIRoutes } from "./ClientAPIRoutes";

/***********************************************************/
/*                                                         */
/*          Client-Side Only Request Utilities             */
/*                                                         */
/***********************************************************/

const parseResponseAndThrowIfError = (response: Response): Promise<object> => {
    return response.json().then((data) => {
        if (response.headers.has("x-smg-refreshed-token")) {
            const refreshedToken = response.headers.get("x-smg-refreshed-token")!;

            if (refreshedToken !== null && refreshedToken !== undefined && refreshedToken !== "" && refreshedToken.length > 0) {
                console.info("Server gave us an updated auth token! Overwriting the one in local storage");
                saveToLocalStorage(LSKeys.AuthToken, refreshedToken);
            }
        }

        if (response.status === 200 || response.status === 201) {
            return data;
        } else if (response.status === 404 || response.status === 409 || response.status === 400 || response.status === 401 || response.status === 403 || response.status === 500) {
            if (data.error === "TokenExpired") {
                console.error("Auth token expired!");
                throw new AuthExpiredError("Users auth token has expired!");
            } else if (data.error === "EmailAlreadyTaken") {
                console.error("Email Already Taken!");
                throw new EmailAlreadyTakenError("EmailAlreadyTaken");
            } else if (data.error === "IncorrectLoginMethod") {
                console.error("Incorrect Login Method!");
                throw new IncorrectLoginMethodError("IncorrectLoginMethod");
            } else if (data.error === "EmailNotFound") {
                console.error("Email Not Found!");
                throw new EmailNotFoundError("EmailNotFound");
            } else if (data.error === "InvalidEmail") {
                console.error("Invalid Email!");
                throw new EmailInvalidError("InvalidEmail");
            } else if (data.error === "InvalidPassword") {
                console.error("Invalid Password!");
                throw new InvalidPasswordError("InvalidPassword");
            } else if (data.error === "AllServersFull") {
                console.error("All servers are full!");
                throw new AllServersFullError("All servers are full! Try again later.");
            } else if (data.error === "NoServersAvailable") {
                console.error("No servers are available!");
                throw new NoServersOnlineError("No servers are available! Try again later.");
            } else if (data.error === "UserNotFound" || data.error === "AnonymousUserNotFound") {
                console.error("User not found!");
                throw new UserNotFoundError("No user found with matching ID");
            } else if (data.error === "AlreadyOnARun") {
                console.error("User is already on a run!");
                throw new AlreadyOnARunError("User is already on a run! Cannot start a new run until the current one is finished.");
            } else if (data.error === "UsernameAlreadyTaken") {
                console.error("Username is taken!");
                throw new UsernameAlreadyTakenError("Username is unavailable!");
            } else if (data.error === "UsernameInappropriate") {
                console.error("Username is inappropriate!");
                throw new UsernameInappropriateError("Username is inappropriate!");
            } else {
                console.error("Unknown API Error!", data.error);
                throw new UnknownAPIError("An unhandled API error has occurred");
            }
        }
    });
};

const getRequest = async (url: string, headers?: object): Promise<object> => {
    const rawResponse = await fetch(url, {
        headers: {
            ...headers
        }
    });
    return parseResponseAndThrowIfError(rawResponse);
};

const postRequest = async (url: string, body: object, headers?: object): Promise<object> => {
    const rawResponse = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            ...headers
        },
        body: JSON.stringify(body)
    });
    return parseResponseAndThrowIfError(rawResponse);
};

const getSavedAnonymousUserId = (): uuid | null => {
    const anonymousUserIdFromLS = loadFromLocalStorage(LSKeys.AnonymousUserId);
    return anonymousUserIdFromLS;
};

const getAuthHeaders = (): object => {
    const authTokenFromLS = loadFromLocalStorage(LSKeys.AuthToken);

    if (authTokenFromLS === null) {
        return {};
    } else {
        return {
            Authorization: `${authTokenFromLS}`
        };
    }
};

/***************************************/
/*                                     */
/*    Registered User Routes           */
/*                                     */
/***************************************/

export const promoteAnonymousUser = async (id: uuid, agreedToPromotion: boolean): Promise<RegisteredUser> => {
    const promoteBody = {
        anonymousUserId: id,
        agreedToPromotion: agreedToPromotion
    };
    const response = await postRequest(`${process.env.BACKEND_API_URL}${ClientAPIRoutes.postUserAnonymousPromotion}`, promoteBody, getAuthHeaders());
    return response as RegisteredUser;
};

export const getMyUserDetails = async (): Promise<RegisteredUser> => {
    const response = await getRequest(`${process.env.BACKEND_API_URL}${ClientAPIRoutes.getMyUserDetails}`, getAuthHeaders());
    return response as RegisteredUser;
};

/***************************************/
/*                                     */
/*     Anonymous User Routes           */
/*                                     */
/***************************************/

export const getAnonymousUser = async (id: uuid): Promise<AnonymousUser> => {
    const response = await getRequest(`${process.env.BACKEND_API_URL}${ClientAPIRoutes.getAnonymousUserMe}?anonymousUserId=${id}`);
    return response as AnonymousUser;
};

export const createAnonymousUser = async (): Promise<AnonymousUser> => {
    const lang = navigator.language;
    const userAgentString = navigator.userAgent;
    const originatingDomain = OriginatingDomain;
    const documentReferrer = DocumentReferrer;
    const createBody = {
        lang,
        userAgentString,
        originatingDomain,
        documentReferrer
    };

    const response = await postRequest(`${process.env.BACKEND_API_URL}${ClientAPIRoutes.createAnonymousUser}`, createBody);
    return response as AnonymousUser;
};

/*******************************************/
/*                                         */
/*     User Type Agnostic Routes           */
/*                                         */
/*******************************************/

interface StartRunResultBase {
    runDepartureSuccessful: boolean;
}

interface StartRunResultIfSuccessful extends StartRunResultBase {
    runDepartureSuccessful: true;
    connectionToken: string;
    serverAddress: string;
}

interface StartRunResultIfFailed extends StartRunResultBase {
    runDepartureSuccessful: false;
    itemsLostIfAbandoned: {
        weaponOne: Item;
        weaponTwo: Item;
        gearOne: Item;
        gearTwo: Item;
        gearThree: Item;
        insuredSlot: LoadoutSlots;
    };
}

interface AbandonRunResult {
    success: boolean;
    updatedLoadout: PlayerLoadout;
}
interface MaintenanceStatusResult {
    isInMaintenanceMode: boolean;
}

export const checkMaintenanceModeRequest = async (): Promise<MaintenanceStatusResult> => {
    const url = `${process.env.BACKEND_API_URL}${ClientAPIRoutes.maintenanceStatus}`;

    // console.log("hitting url:", url);

    const response = await getRequest(url);

    const res = response as MaintenanceStatusResult;

    return res;
};

export const startRunRequest = async (userType: UserType): Promise<StartRunResultIfSuccessful | StartRunResultIfFailed> => {
    let response;

    let additionalParams = "";

    if (window.location.href.includes("localhost")) {
        additionalParams += "&debugSkipMatchmaking=true";
    }

    if (window.location.href.includes("skipMenu=true")) {
        additionalParams += "&debugForceAbandonRun=true";
    }

    additionalParams += `&preferredRegion=${UI_GetPreferredRegion()}`;

    if (userType === UserType.Anonymous) {
        const savedAnonUserId = getSavedAnonymousUserId();
        if (savedAnonUserId === null) {
            throw new Error("No anon user ID found in LS, start run request will fail!");
        }
        const url = `${process.env.BACKEND_API_URL}${ClientAPIRoutes.startRun}?r=true&anonymousUserId=${savedAnonUserId}${additionalParams}`;
        // console.log("hitting url:", url);
        response = await getRequest(url);
    } else if (userType === UserType.Registered) {
        const url = `${process.env.BACKEND_API_URL}${ClientAPIRoutes.startRun}?r=true${additionalParams}`;
        // console.log("hitting url:", url);
        response = await getRequest(url, getAuthHeaders());
    } else {
        throw new Error("Invalid user type provided to start run request!");
    }

    const res = response as StartRunResultBase;

    if (res.runDepartureSuccessful) {
        return res as StartRunResultIfSuccessful;
    } else {
        return res as StartRunResultIfFailed;
    }
};

export const abandonRunRequest = async (userType: UserType): Promise<AbandonRunResult> => {
    let response;

    if (userType === UserType.Anonymous) {
        const savedAnonUserId = getSavedAnonymousUserId();
        if (savedAnonUserId === null) {
            throw new Error("No anon user ID found in LS, abandon run request will fail!");
        }
        response = await getRequest(`${process.env.BACKEND_API_URL}${ClientAPIRoutes.abandonRun}?anonymousUserId=${savedAnonUserId}`);
    } else if (userType === UserType.Registered) {
        response = await getRequest(`${process.env.BACKEND_API_URL}${ClientAPIRoutes.abandonRun}`, getAuthHeaders());
    } else {
        throw new Error("Invalid user type provided to abandon run request!");
    }

    return response as AbandonRunResult;
};

export const equipSkinRequest = async (userType: UserType, skin: CharacterSkin): Promise<EquipSkinResponse> => {
    let response;
    let url;

    if (userType === UserType.Anonymous) {
        const savedAnonUserId = getSavedAnonymousUserId();
        if (savedAnonUserId === null) {
            throw new Error("No anon user ID found in LS, update preferences request will fail!");
        }
        url = `${process.env.BACKEND_API_URL}${ClientAPIRoutes.equipSkin}?anonymousUserId=${savedAnonUserId}`;
    } else if (userType === UserType.Registered) {
        url = `${process.env.BACKEND_API_URL}${ClientAPIRoutes.equipSkin}`;
    } else {
        throw new Error("Invalid user type provided to update preferences request!");
    }

    response = await postRequest(url, { skin }, getAuthHeaders());

    return {
        ...(response as EquipSkinResponse)
    };
};

export const equipItemToSlotRequest = async (userType: UserType, slot: LoadoutSlots, itemId: Item): Promise<EquipItemToSlotResponse> => {
    let response;
    let url;

    if (userType === UserType.Anonymous) {
        const savedAnonUserId = getSavedAnonymousUserId();
        if (savedAnonUserId === null) {
            throw new Error("No anon user ID found in LS, update preferences request will fail!");
        }
        url = `${process.env.BACKEND_API_URL}${ClientAPIRoutes.equipItemToSlot}?anonymousUserId=${savedAnonUserId}`;
    } else if (userType === UserType.Registered) {
        url = `${process.env.BACKEND_API_URL}${ClientAPIRoutes.equipItemToSlot}`;
    } else {
        throw new Error("Invalid user type provided to update preferences request!");
    }

    response = await postRequest(url, { slot, itemId }, getAuthHeaders());

    return {
        ...(response as EquipItemToSlotResponse)
    };
};

export const insureSlotRequest = async (userType: UserType, insuredSlot: LoadoutSlots): Promise<InsureSlotResponse> => {
    let url;

    if (userType === UserType.Anonymous) {
        const savedAnonUserId = getSavedAnonymousUserId();
        if (savedAnonUserId === null) {
            throw new Error("No anon user ID found in LS, update preferences request will fail!");
        }
        url = `${process.env.BACKEND_API_URL}${ClientAPIRoutes.insureSlot}?anonymousUserId=${savedAnonUserId}`;
    } else if (userType === UserType.Registered) {
        url = `${process.env.BACKEND_API_URL}${ClientAPIRoutes.insureSlot}`;
    } else {
        throw new Error("Invalid user type provided to update preferences request!");
    }

    const response = await postRequest(url, { insuredSlot }, getAuthHeaders());

    return {
        ...(response as InsureSlotResponse)
    };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const anonymousUserRegisterEmailRequest = async (username: string, email: string, confirmEmail: string, password: string, confirmPassword: string, agreedToEmailPromo: boolean): Promise<any> => {
    const savedAnonUserId = getSavedAnonymousUserId();
    const url = `${process.env.BACKEND_API_URL}${ClientAPIRoutes.registerAnonymousUserEmail}?anonymousUserId=${savedAnonUserId}`;

    try {
        const response = await postRequest(url, { username, email, confirmEmail, password, confirmPassword, agreedToEmailPromo }, getAuthHeaders());

        return response;
    } catch (e) {
        console.log("error registering anon user email:", e);
        throw e;
    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const userLoginRequest = async (email: string, password: string): Promise<any> => {
    const url = `${process.env.BACKEND_API_URL}${ClientAPIRoutes.userLogin}`;

    try {
        const response = await postRequest(url, { email, password });

        return response;
    } catch (e) {
        console.log("error logging in user:", e);
        throw e;
    }
};

export const buySkinWithGemsRequest = async (skin: CharacterSkin): Promise<{ success: boolean }> => {
    const response = await postRequest(`${process.env.BACKEND_API_URL}${ClientAPIRoutes.postBuySkinWithGems}`, { skin }, getAuthHeaders());
    console.log("API response of buying skin with gems request", response);
    return response as { success: boolean };
};

export const buyInsuranceWithGemsRequest = async (): Promise<{ success: boolean }> => {
    const response = await postRequest(`${process.env.BACKEND_API_URL}${ClientAPIRoutes.postBuyInsuranceWithGems}`, { quantity: 1 }, getAuthHeaders());
    console.log("API response of buying insurance with gems request", response);
    return response as { success: boolean };
};

export const buyInsuranceWithCoinsRequest = async (): Promise<{ success: boolean }> => {
    const response = await postRequest(`${process.env.BACKEND_API_URL}${ClientAPIRoutes.postBuyInsuranceWithCoins}`, { quantity: 1 }, getAuthHeaders());
    console.log("API response of buying insurance with coins request", response);
    return response as { success: boolean };
};

export const getPurchaseLinkForSKURequest = async (sku: string): Promise<{ link: string }> => {
    const response = await getRequest(`${process.env.BACKEND_API_URL}${ClientAPIRoutes.postGetPurchaseLinkForSKU}?sku=${sku}`, getAuthHeaders());
    console.log("API response of get link for sku request", response);
    return response as { link: string };
};

export const changeUsernameRequest = async (userType: UserType, newUsername: string): Promise<{ username: string }> => {
    let response;
    let url;

    if (userType === UserType.Anonymous) {
        const savedAnonUserId = getSavedAnonymousUserId();
        if (savedAnonUserId === null) {
            throw new Error("No anon user ID found in LS, update preferences request will fail!");
        }
        url = `${process.env.BACKEND_API_URL}${ClientAPIRoutes.changeUsername}?anonymousUserId=${savedAnonUserId}`;
    } else if (userType === UserType.Registered) {
        url = `${process.env.BACKEND_API_URL}${ClientAPIRoutes.changeUsername}`;
    } else {
        throw new Error("Invalid user type provided to update preferences request!");
    }

    response = await postRequest(url, { desiredNewUsername: newUsername }, getAuthHeaders());

    return response as { username: string };
};

export const savePreferencesRequest = async (userType: UserType, preferences: PlayerPreferences): Promise<PlayerPreferences> => {
    let response;
    let url;

    if (userType === UserType.Anonymous) {
        const savedAnonUserId = getSavedAnonymousUserId();
        if (savedAnonUserId === null) {
            throw new Error("No anon user ID found in LS, update preferences request will fail!");
        }
        url = `${process.env.BACKEND_API_URL}${ClientAPIRoutes.updatePreferences}?anonymousUserId=${savedAnonUserId}`;
    } else if (userType === UserType.Registered) {
        url = `${process.env.BACKEND_API_URL}${ClientAPIRoutes.updatePreferences}`;
    } else {
        throw new Error("Invalid user type provided to update preferences request!");
    }

    response = await postRequest(url, { updatedPreferences: preferences }, getAuthHeaders());

    return {
        ...(response as PlayerPreferences)
    };
};

import React from "react";
import { useRecoilValue } from "recoil";
import { extractionInProgressStateAtom, extractionSuccessfulStateAtom, youDiedStateAtom } from "../framework/UI_State";
import { t } from "../../../shared/data/Data_I18N";

export const ExtractionInProgress = () => {
    const { show: playerHasDied } = useRecoilValue(youDiedStateAtom);
    const { show: playerHasExtracted } = useRecoilValue(extractionSuccessfulStateAtom);
    const { extractionProgress: progressAsPercentage } = useRecoilValue(extractionInProgressStateAtom);

    const shouldShowExtractionMeter = progressAsPercentage > 0 && progressAsPercentage < 100;

    if (shouldShowExtractionMeter === false || playerHasDied || playerHasExtracted) {
        return <></>;
    } else {
        return (
            <div className={`extraction-bar animate__animated animate__infinite animate__fast ${progressAsPercentage > 85 ? "animate__flash" : ""}`}>
                <div className="extraction-bar-progress" style={{ width: `${progressAsPercentage}%` }}></div>
                <div className="extraction-bar-text">
                    {t("in_game__extracting")}... {progressAsPercentage}%
                </div>
            </div>
        );
    }
};

import { enemyConfig } from "../config/Config_Enemy";
import { clampToWorldBounds } from "../SharedUtils";
import { IGameEntityBase, IGameEntityThatMoves, IGameEntityThatUpdates, IGameEntityWithColliders, IGameEntityWithHealth, NetworkEntityId } from "../SharedTypes";
import { NType } from "../SharedNetcodeSchemas";
import { Box, Circle, Polygon, Vector } from "sat";
import { CollisionGridCell } from "../systems/SharedCollisionSystem";
import { SharedPlayer } from "./SharedPlayer";
import { EnemyState } from "../data/Data_EnemyBehaviourStates";
import { DamageType } from "../data/Data_Items";
import { EnemyConfig, EnemySkin, EnemySkinData } from "../data/Data_Enemies";

export class SharedAIEntity implements IGameEntityBase, IGameEntityThatUpdates, IGameEntityWithColliders, IGameEntityThatMoves, IGameEntityWithHealth {
    public nid: NetworkEntityId = -1;
    public ntype: NType = NType.AIEntity;
    public position: Vector = new Vector(0, 0);
    public velocity: Vector = new Vector(0, 0);
    public prevPosition: Vector = new Vector(0, 0);
    public isAlive: boolean = true;
    public currentCollisionGridCell: CollisionGridCell;
    public lastDamagedByNid: NetworkEntityId = -1;
    public maxHealth: number = 100; // Set upon construction of ServerAIEntity
    public currentHealth: number = 100; // Set upon construction of ServerAIEntity
    public currentHealthPercentage: number = 1;
    public currentState: EnemyState = EnemyState.None;
    public spawnPosition: Vector = new Vector(0, 0);
    public targetPosition: Vector = new Vector(0, 0);
    public attackedThisFrame: boolean = false;
    public hasLineOfSightWithTarget: boolean = false;
    public enemySkinData: EnemyConfig;
    // protected _debug_pathfindingTileIndices: number[] = [];

    public HitboxCollider: Polygon;
    public AggroCollider: Circle = new Circle(new Vector(0, 0), enemyConfig.AGGRO_COLLIDER_RADIUS);
    public MaxChaseOuterCollider: Circle = new Circle(new Vector(0, 0), enemyConfig.MAX_CHASE_OUTER_COLLIDER_RADIUS);
    public MaxChaseInnerCollider: Circle = new Circle(new Vector(0, 0), enemyConfig.MAX_CHASE_INNER_COLLIDER_RADIUS);

    public constructor(enemySkin: EnemySkin) {
        const enemySkinData = EnemySkinData.get(enemySkin)!;
        this.enemySkinData = enemySkinData;

        this.HitboxCollider = new Box(new Vector(0, 0), enemySkinData.hitboxWidth, enemySkinData.hitboxHeight).toPolygon();
    }

    public get x(): number {
        return this.position.x;
    }
    public set x(newX: number) {
        this.position.x = newX;
        this.HitboxCollider.pos.x = this.x - this.enemySkinData.hitboxWidth / 2;
        this.AggroCollider.pos.x = this.x;
        this.MaxChaseOuterCollider.pos.x = this.spawnPosition.x;
        this.MaxChaseInnerCollider.pos.x = this.spawnPosition.x;
    }
    public get y(): number {
        return this.position.y;
    }
    public set y(newY: number) {
        this.position.y = newY;
        this.HitboxCollider.pos.y = this.y - this.enemySkinData.hitboxHeight / 2 + this.enemySkinData.colliderYOffset;
        this.AggroCollider.pos.y = this.y;
        this.MaxChaseOuterCollider.pos.y = this.spawnPosition.y;
        this.MaxChaseInnerCollider.pos.y = this.spawnPosition.y;
    }
    public get prevX(): number {
        return this.prevPosition.x;
    }
    public set prevX(newX: number) {
        this.prevPosition.x = newX;
    }
    public get prevY(): number {
        return this.prevPosition.y;
    }
    public set prevY(newY: number) {
        this.prevPosition.y = newY;
    }

    public get spawnX(): number {
        return this.spawnPosition.x;
    }
    public set spawnX(newX: number) {
        this.spawnPosition.x = newX;
    }
    public get spawnY(): number {
        return this.spawnPosition.y;
    }
    public set spawnY(newY: number) {
        this.spawnPosition.y = newY;
    }

    public get targetX(): number {
        return this.targetPosition.x;
    }
    public set targetX(newX: number) {
        this.targetPosition.x = newX;
    }
    public get targetY(): number {
        return this.targetPosition.y;
    }
    public set targetY(newY: number) {
        this.targetPosition.y = newY;
    }

    // public get debug_pathfindingTileIndices(): number[] {
    //     return this._debug_pathfindingTileIndices;
    // }
    // public set debug_pathfindingTileIndices(newTileIndices: number[]) {
    //     this._debug_pathfindingTileIndices = newTileIndices;
    // }

    public Move(deltaTime: number): void {
        this.prevX = this.x;
        this.prevY = this.y;

        const cappedVelocityMagnitude = Math.sqrt(this.velocity.x * this.velocity.x + this.velocity.y * this.velocity.y);

        if (cappedVelocityMagnitude !== 0) {
            this.velocity.x /= cappedVelocityMagnitude;
            this.velocity.y /= cappedVelocityMagnitude;
        }

        this.x = clampToWorldBounds(this.x + this.velocity.x * (enemyConfig.SPEED_PER_SECOND * deltaTime));
        this.y = clampToWorldBounds(this.y + this.velocity.y * (enemyConfig.SPEED_PER_SECOND * deltaTime));
    }

    public Update(__deltaTime: number, __currentTimeInMilliseconds?: number): void {}
    public TakeDamage(__damage: number, __damageType: DamageType, __damageDealerNid: NetworkEntityId, __npcDamageModifier: number): void {}
    public SetTargetPlayer(__targetPlayerEntity: SharedPlayer | null): void {}
    public GetTargetPlayer(): SharedPlayer | null {
        return null;
    }
}

import EventEmitter from "eventemitter3";
import { ClientRenderer } from "./systems/ClientPixiRenderer";
import { GameResizer } from "./systems/ClientGameResizer";
import { ClientNetcode } from "./systems/ClientNetcode";
import { ClientDebugManager } from "./systems/ClientDebugManager";
import { GameplaySystem } from "../shared/engine/SharedGameplaySystem";
import { ClientPlayer } from "./entities/ClientPlayer";
import { IGameEntityBasic, NetworkEntityId, UserType, uuid } from "../shared/SharedTypes";
import { GenericNengiUpdatePayload as EntityUpdatePayload, IRenderedEntity } from "./ClientTypes";
import { ClientPredictor } from "./systems/ClientPredictor";
import { ClientInputManager } from "./systems/ClientInputManager";
import { ClientUI } from "./ui/ClientUI";
import { ClientIdentity } from "./systems/ClientIdentity";
import { ClientLoader } from "./systems/ClientLoader";
import { DebugGridCellEntityCreationPayload, ExtractionPointEntityCreationPayload, IdentityMessage, LoadoutFromServerMessage, NType, NTyped, PlayPfxAtLocationMessage, PlayerEntityCreationPayload, ProjectileEntityCreationPayload, ItemsLootedOnRunUpdatedMessage, YouDiedMessage, SpectateEntityOfNidMessage, SuccessfullyExtractedMessage, AIEntityCreationPayload, ItemContainerEntityCreationPayload, OpenedOrUpdatedItemContainerMessage, PopToastOfArbitraryStringMessage, DrawDebugShapeMessage, LeaderboardStateMessage, ServerEventFeedLootMessage, ServerEventFeedKillMessage, ServerEventFeedBossUnderAttackMessage, ServerEventFeedBossSpawnMessage, ServerEventFeedExtractionStartedMessage, ServerEventFeedExtractionSuccessfulMessage } from "../shared/SharedNetcodeSchemas";
import { ClientProjectile } from "./entities/ClientProjectile";
import { Screens, UI_AddServerEventToFeed, UI_EnterSpectatorMode, UI_HideLootContainerPanel, UI_ItemsLootedOnRunUpdated, UI_LoadoutFromGameServer, UI_OpenedItemContainer, UI_ShowExtractionSuccessfulpanel, UI_ShowFullScreenError, UI_ShowMatchmakingError, UI_ShowYouDiedPanel, UI_StopExtractionPanelLoading, UI_StopYouDiedPanelLoading, UI_SwapScreen, UI_UpdateLeaderboardState } from "./ui/framework/UI_State";
import { uiConfig } from "../shared/config/Config_UI";
import { playerConfig } from "../shared/config/Config_Player";
import { GAME_VERSION } from "../shared/config/Config_Game";
import { ClientDebugGridCell } from "./entities/ClientDebugGridCell";
import { LogLevel } from "../shared/engine/SharedLogging";
import { startRunRequest } from "./backend/ClientRequests";
import { ClientExtractionPoint } from "./entities/ClientExtractionPoint";
import { ClientAIEntity } from "./entities/ClientAIEntity";
import { SharedCollisionSystem } from "../shared/systems/SharedCollisionSystem";
import { ClientPlayerPredicted } from "./entities/ClientPlayerPredicted";
import { ClientItemContainer } from "./entities/ClientItemContainer";
import { Item } from "../shared/data/Data_Items";
import { PopToast } from "./ui/kit/toast-utils";
import { ToastStatuses } from "./ui/kit/toast-utils";
import { AllServersFullError, NoServersOnlineError } from "./backend/ClientAPIErrors";

// import * as gameanalytics from "gameanalytics";
import { ClientRegionPopUpManager } from "./systems/ClientRegionPopUpManager";
import { ClientRewardsManager } from "./systems/ClientRewardsManager";
import { t } from "../shared/data/Data_I18N";
import { WorldRegionsFromTiledMap } from "../shared/data/Data_WorldImport";
import { ServerEventFeedEventTypes } from "../shared/SharedTypes";

// // @ts-ignore
// window.GameAnalytics = gameanalytics.GameAnalytics;

// gameanalytics.GameAnalytics("setEnabledInfoLog", true);
// gameanalytics.GameAnalytics("setEnabledVerboseLog", true);
// gameanalytics.GameAnalytics("setCustomDimension01", "web");
// gameanalytics.GameAnalytics("configureBuild", GAME_VERSION);

// gameanalytics.GameAnalytics("initialize", "e28f3b734b8af6d994e7cc0375ad51e2", "35c19802b12c713726dd7ac80e5642ab698fac01");

console.log("-- USING ENV VARS --");
console.log(process.env.NODE_ENV);
console.log(process.env.BACKEND_API_URL);
console.log(process.env.OAUTH_DISCORD_ADDRESS);
console.log(process.env.OAUTH_GOOGLE_ADDRESS);
console.log(process.env.OAUTH_FACEBOOK_ADDRESS);

const gameContainer = document.getElementById("game-container") as HTMLElement;
const dragHandle = document.getElementById("drag-handle") as HTMLElement;

if (uiConfig.DEBUG.ENABLE_EASY_RESIZABLE_GAME_CONTAINER) {
    gameContainer.style.width = `${uiConfig.DEBUG.DEFAULT_GAME_VIEWPORT_WIDTH_IN_RESIZABLE_MODE}px`;
    gameContainer.style.height = `${uiConfig.DEBUG.DEFAULT_GAME_VIEWPORT_HEIGHT_IN_RESIZABLE_MODE}px`;
    gameContainer.style.borderRadius = "3px";
    dragHandle.style.display = "flex";
} else {
    gameContainer.style.height = "100%";
    gameContainer.style.width = "100%";
    gameContainer.style.border = "none";
    gameContainer.style.borderRadius = "0";
    dragHandle.style.display = "none";
}

globalThis.currentFrameNumber = 0;

export class GameClient extends GameplaySystem {
    // Root event dispatcher
    private eventDispatcher: EventEmitter;

    // Private systems
    private identity: ClientIdentity;
    public Netcode: ClientNetcode;
    public Debug: ClientDebugManager;
    private predictor: ClientPredictor;
    private regionPopUpManager: ClientRegionPopUpManager;
    private ui: ClientUI;
    public Rewards: ClientRewardsManager;

    // Public systems (these should probably be refactors at some point)
    public Renderer: ClientRenderer;
    public Input: ClientInputManager;
    public Resizer: GameResizer;
    public Loader: ClientLoader;
    public Collision: SharedCollisionSystem;

    // state
    private lastUpdateTime: number = 0;
    private isRunning: boolean = false;
    private currentFrame: number = 0;
    private entities: Set<IGameEntityBasic> = new Set();
    private myEntityNid: NetworkEntityId = -1;

    public constructor() {
        super();

        this.setLogLevel(LogLevel.VERBOSE);

        window.Game = this;

        // Set up root event dispatcher
        this.eventDispatcher = new EventEmitter();

        // Construct each child system/manager
        this.identity = new ClientIdentity();
        this.Renderer = new ClientRenderer();
        this.Input = new ClientInputManager();
        this.Resizer = new GameResizer();
        this.predictor = new ClientPredictor();
        this.Netcode = new ClientNetcode(this.predictor);
        this.Debug = new ClientDebugManager();
        this.ui = new ClientUI();
        this.Collision = new SharedCollisionSystem();
        this.Loader = new ClientLoader();
        this.regionPopUpManager = new ClientRegionPopUpManager();
        this.Rewards = new ClientRewardsManager();

        Game.ListenForEvent("GameLifecycle::RunStarted", () => {
            this.FindAndConnectToServer();
        });
        Game.ListenForEvent("Debug::SkipMenu", () => {
            this.FindAndConnectToServer();
        });
    }

    public async FindAndConnectToServer(): Promise<void> {
        const userType = this.identity.GetUserType();

        if (userType !== UserType.Anonymous && userType !== UserType.Registered) {
            UI_ShowFullScreenError("Oops, something went wrong!");
            return;
        }

        try {
            const startRunResult = await startRunRequest(userType);

            if (startRunResult.runDepartureSuccessful) {
                const { connectionToken, serverAddress } = startRunResult;
                const gameClientVersion = GAME_VERSION;

                this.InitializeGame(serverAddress, connectionToken, gameClientVersion);
            } else {
                console.log("run departure failed, you'd be abandoning your current run! do you want to do that???");
                UI_SwapScreen(Screens.RunInProgress);
            }
        } catch (e) {
            setTimeout(() => {
                if (e instanceof NoServersOnlineError) {
                    UI_ShowMatchmakingError("Servers Offline", "The servers are offline right now, we're releasing a new game patch! Check back in a few minutes.");
                } else if (e instanceof AllServersFullError) {
                    UI_ShowMatchmakingError("Servers Full", "The servers are full right now! Check back in a few minutes.");
                } else {
                    console.error("Unknown API Error", e);
                    UI_ShowMatchmakingError("Matchmaking Error", "Something went wrong starting your run! Check back in a few minutes.");
                }
            }, 3000);
        }
    }

    public EmitEvent(eventName: string, payload: object = {}): void {
        this.LogVerbose(`Emitting event: ${eventName}`);
        this.eventDispatcher.emit(eventName, payload);
    }

    public ListenForEvent(eventName: string, callback: (payload: object) => void): void {
        this.eventDispatcher.on(eventName, callback);
    }

    public StopListeningForEvent(eventName: string, callback: (payload: object) => void): void {
        this.eventDispatcher.off(eventName, callback);
    }

    public override Initialize(): void {
        throw new Error("Do not use!");
    }

    public InitializeUI() {
        this.Loader.Initialize();
        this.identity.Initialize();
        this.Resizer.Initialize();
        this.Debug.Initialize();
        this.ui.Initialize();
        this.Rewards.Initialize();
    }

    public InitializeGame(serverAddress: string, connectionToken: string, gameClientVersion: string): void {
        this.Renderer.Initialize();
        this.Input.Initialize();
        this.Collision.Initialize();
        this.regionPopUpManager.Initialize();

        // Connect to the server!
        this.Netcode.Connect(serverAddress, connectionToken, gameClientVersion);
    }

    public Cleanup(): void {
        throw new Error("Method not implemented.");
    }

    public Start(): void {
        if (this.isRunning) {
            return;
        }
        this.isRunning = true;
        this.lastUpdateTime = performance.now();
        requestAnimationFrame((time) => this.Update(time));
    }

    public GetEntityByNid(nid: NetworkEntityId): IGameEntityBasic | undefined {
        for (const entity of this.entities) {
            if (entity.nid === nid) {
                return entity;
            }
        }
    }

    public LootItem(itemContainerId: uuid, item: Item): void {
        // console.warn(` @@@ Player is looting item ${Item[item]} out of container ${itemContainerId}`);
        this.Netcode.SendLootItemCommand(itemContainerId, item);
    }

    protected override getSystemName(): string {
        return "Client";
    }

    public Update(currentTime: number): void {
        try {
            if (!this.isRunning) {
                return;
            }

            this.currentFrame++;

            globalThis.currentFrameNumber = this.currentFrame;

            const currentTimestamp = Date.now();
            const deltaTimeInMilliseconds = currentTime - this.lastUpdateTime;
            const deltaTimeInSeconds = deltaTimeInMilliseconds / 1000;

            this.lastUpdateTime = currentTime;

            for (const e of this.entities) {
                e.Update(deltaTimeInSeconds, currentTimestamp);
            }
            this.Renderer.Update(deltaTimeInSeconds);
            this.Netcode.Update(deltaTimeInSeconds);
            this.Input.Update(deltaTimeInSeconds);
            this.Debug.Update(deltaTimeInSeconds);
            this.predictor.Update(deltaTimeInSeconds, currentTimestamp);
            this.Resizer.Update(deltaTimeInMilliseconds);
            this.Collision.Update(deltaTimeInSeconds);
            this.regionPopUpManager.Update(deltaTimeInSeconds);

            requestAnimationFrame((time) => this.Update(time));
        } catch (e) {
            console.error("Caught error in game client update loop!");
            console.error(e);
        }
    }

    public SpectateKiller(): void {
        this.Netcode.SendSpectateKillerCommand();
    }

    public ProcessMessage(message: NTyped) {
        // this.LogInfo('Nengi server sent us a message:');
        // this.LogInfo('\n' + JSON.stringify(message, null, 2));

        switch (message.ntype) {
            case NType.IdentityMessage:
                this.myEntityNid = (message as IdentityMessage).myId;
                this.LogInfo(`Self identified as nid: ${this.myEntityNid}`);
                Game.EmitEvent("Bootstrap::MyEntitiesNidIsKnown", message);
                break;
            case NType.PlayPfxAtLocationMessage:
                const { x, y, scale, pfxType } = message as PlayPfxAtLocationMessage;
                Game.Renderer.PlayOneOffPfx(x, y, pfxType, scale, 1);
                break;
            case NType.LeaderboardStateMessage:
                const stateString = (message as LeaderboardStateMessage).leaderboardState;
                UI_UpdateLeaderboardState(stateString);
                break;
            case NType.YouDiedMessage:
                Game.Rewards.GameplayEnded();
                setTimeout(() => {
                    const { killedBy, runDurationInSeconds, goldLost } = message as YouDiedMessage;
                    UI_ShowYouDiedPanel(killedBy, runDurationInSeconds, goldLost);
                    setTimeout(() => {
                        UI_StopYouDiedPanelLoading();
                    }, 5500);
                }, 2500);
                break;
            case NType.PlaceholderPingAckMessage:
                this.Netcode.ReceivedPingAck();
                break;
            case NType.OpenedOrUpdatedItemContainerMessage:
                // console.log("got container msg:", message);
                UI_OpenedItemContainer(message as OpenedOrUpdatedItemContainerMessage);
                break;
            case NType.SuccessfullyExtractedMessage:
                const { runDurationInSeconds: runDuration, goldEarned } = message as SuccessfullyExtractedMessage;
                Game.Rewards.GameplayEnded();
                setTimeout(() => {
                    UI_ShowExtractionSuccessfulpanel(runDuration, goldEarned);

                    setTimeout(() => {
                        UI_StopExtractionPanelLoading();
                    }, 4000);
                }, 1250);
                break;
            case NType.DrawDebugShapeMessage:
                const { x: debugX, y: debugY, width, height, destX, destY, destroyAfterSeconds, radius, shape } = message as DrawDebugShapeMessage;
                this.Debug.DrawDebugShape(debugX, debugY, shape, { width, height, destX, destY, destroyAfterSeconds, radius });
                break;
            case NType.PopToastOfArbitraryStringMessage:
                PopToast({ status: ToastStatuses.NONE, message: t((message as PopToastOfArbitraryStringMessage).message) });
                break;
            case NType.HideItemContainerMessage:
                UI_HideLootContainerPanel();
                break;
            case NType.LoadoutFromServerMessage:
                UI_LoadoutFromGameServer(message as LoadoutFromServerMessage);
                break;
            case NType.ItemsLootedOnRunUpdatedMessage:
                UI_ItemsLootedOnRunUpdated(message as ItemsLootedOnRunUpdatedMessage);
                break;
            case NType.ServerEventFeedBossSpawnMessage:
                this._processServerEventFeedBossSpawnMessage(message as ServerEventFeedBossSpawnMessage);
                break;
            case NType.ServerEventFeedBossUnderAttackMessage:
                this._processServerEventFeedBossUnderAttackMessage(message as ServerEventFeedBossUnderAttackMessage);
                break;
            case NType.ServerEventFeedExtractionStartedMessage:
                this._processServerEventFeedExtractionStartedMessage(message as ServerEventFeedExtractionStartedMessage);
                break;
            case NType.ServerEventFeedExtractionSuccessfulMessage:
                this._processServerEventFeedExtractionSuccessfulMessage(message as ServerEventFeedExtractionSuccessfulMessage);
                break;
            case NType.ServerEventFeedPvPKillMessage:
                this._processServerEventFeedPvPKillMessage(message as ServerEventFeedKillMessage);
                break;
            case NType.ServerEventFeedLootMessage:
                this._processServerEventFeedLootMessage(message as ServerEventFeedLootMessage);
                break;
            case NType.SpectateEntityOfNidMessage:
                const { nidOfEntityToFollow, entityName } = message as SpectateEntityOfNidMessage;
                UI_EnterSpectatorMode(entityName);
                const entity = this.GetEntityByNid(nidOfEntityToFollow)!;
                if (entity !== undefined) {
                    // @ts-ignore
                    this.Renderer.StartFollowing(nidOfEntityToFollow, entity as IRenderedEntity);
                }
                break;
            default:
                this.LogWarning(`Unhandled message type: ${NType[message.ntype]}!`);
                break;
        }
    }

    private _processServerEventFeedBossUnderAttackMessage(message: ServerEventFeedBossUnderAttackMessage) {
        const { bossName, locationX, locationY } = message;
        // console.warn("@@@ calling UI_AddServerEventToFeed for event type: BossAttack");
        UI_AddServerEventToFeed({
            id: Math.floor(Math.random() * 10000),
            type: ServerEventFeedEventTypes.BossAttack,
            bossName,
            locationX,
            locationY
        });
    }

    private _processServerEventFeedExtractionStartedMessage(message: ServerEventFeedExtractionStartedMessage) {
        const { playerName, locationX, locationY } = message;
        // console.warn("@@@ calling UI_AddServerEventToFeed for event type: Extraction Started");
        UI_AddServerEventToFeed({
            id: Math.floor(Math.random() * 10000),
            type: ServerEventFeedEventTypes.ExtractionStarted,
            playerName,
            locationX,
            locationY
        });
    }

    private _processServerEventFeedExtractionSuccessfulMessage(message: ServerEventFeedExtractionSuccessfulMessage) {
        const { playerName, locationX, locationY } = message;
        // console.warn("@@@ calling UI_AddServerEventToFeed for event type: Extraction Successful");
        UI_AddServerEventToFeed({
            id: Math.floor(Math.random() * 10000),
            type: ServerEventFeedEventTypes.ExtractionSuccessful,
            playerName,
            locationX,
            locationY
        });
    }

    private _processServerEventFeedPvPKillMessage(message: ServerEventFeedKillMessage) {
        const { killer, victim, locationX, locationY } = message;
        // console.warn("@@@ calling UI_AddServerEventToFeed for event type: PvPKill");
        UI_AddServerEventToFeed({
            id: Math.floor(Math.random() * 10000),
            type: ServerEventFeedEventTypes.Kill,
            killer,
            victim,
            locationX,
            locationY
        });
    }

    private _processServerEventFeedLootMessage(message: ServerEventFeedLootMessage) {
        const { playerName, rarity, locationX, locationY } = message;
        // console.warn("@@@ calling UI_AddServerEventToFeed for event type: Loot");
        UI_AddServerEventToFeed({
            id: Math.floor(Math.random() * 10000),
            type: ServerEventFeedEventTypes.Loot,
            username: playerName,
            rarity,
            locationX,
            locationY
        });
    }

    private _processServerEventFeedBossSpawnMessage(message: ServerEventFeedBossSpawnMessage) {
        const { bossName, locationX, locationY } = message;
        // console.warn("@@@ calling UI_AddServerEventToFeed for event type: BossSpawn");
        UI_AddServerEventToFeed({
            id: Math.floor(Math.random() * 10000),
            type: ServerEventFeedEventTypes.BossSpawn,
            bossName,
            locationX,
            locationY
        });
    }

    public GetLocationNameFromXY(x: number, y: number): string {
        let locationName: string = "region__wilderness";
        for (let i = 0; i < WorldRegionsFromTiledMap.objects.length; i++) {
            const region = WorldRegionsFromTiledMap.objects[i];
            if (x >= region.x && x <= region.x + region.width && y >= region.y && y <= region.y + region.height) {
                locationName = region.name;
            }
        }
        return t(locationName);
    }

    private _addPlayerEntity(createPlayerPayload: PlayerEntityCreationPayload) {
        const createdEntity = new ClientPlayer(createPlayerPayload);
        this.Collision.AddEntity(createdEntity);
        return createdEntity;
    }

    private _addProjectileEntity(createProjectilePayload: ProjectileEntityCreationPayload) {
        const projectileIsOwnedByMyEntity = createProjectilePayload.owningEntityNid === this.myEntityNid;
        const createdEntity = new ClientProjectile(createProjectilePayload, projectileIsOwnedByMyEntity, 1, 1, 1);
        return createdEntity;
    }

    private _addDebugGridCellEntity(createDebugGridCellPayload: DebugGridCellEntityCreationPayload) {
        return new ClientDebugGridCell(createDebugGridCellPayload);
    }

    private _addAIEntity(createAIEntityPayload: AIEntityCreationPayload) {
        const createdEntity = new ClientAIEntity(createAIEntityPayload);
        this.Collision.AddEntity(createdEntity);
        return createdEntity;
    }

    private _addExtractionPointEntity(createExtractionPointPayload: ExtractionPointEntityCreationPayload) {
        return new ClientExtractionPoint(createExtractionPointPayload);
    }

    private _addItemContainerEntity(createItemContainerPayload: ItemContainerEntityCreationPayload) {
        const createdEntity = new ClientItemContainer(createItemContainerPayload);
        this.Collision.AddEntity(createdEntity);
        return createdEntity;
    }

    public AddEntity(createEntityPayload: NTyped) {
        let instantiatedEntity;

        switch (createEntityPayload.ntype) {
            case NType.PlayerEntity:
                instantiatedEntity = this._addPlayerEntity(createEntityPayload as PlayerEntityCreationPayload);
                break;
            case NType.ProjectileEntity:
                instantiatedEntity = this._addProjectileEntity(createEntityPayload as ProjectileEntityCreationPayload);
                break;
            case NType.DebugGridCellEntity:
                instantiatedEntity = this._addDebugGridCellEntity(createEntityPayload as DebugGridCellEntityCreationPayload);
                break;
            case NType.ExtractionPointEntity:
                instantiatedEntity = this._addExtractionPointEntity(createEntityPayload as ExtractionPointEntityCreationPayload);
                break;
            case NType.AIEntity:
                instantiatedEntity = this._addAIEntity(createEntityPayload as AIEntityCreationPayload);
                break;
            case NType.ItemContainerEntity:
                instantiatedEntity = this._addItemContainerEntity(createEntityPayload as ItemContainerEntityCreationPayload);
                break;
            default:
                this.LogError("Server sent us an entity create for an entity of type: UNKNOWN");
                break;
        }

        if (instantiatedEntity === undefined) {
            this.LogError("instantiatedEntity is undefined, aborting entity creation");
            return;
        }

        this.Renderer.AddRenderedEntity(instantiatedEntity);

        this.entities.add(instantiatedEntity as IGameEntityBasic);
    }

    public DeleteEntity(nid: NetworkEntityId) {
        let foundEntity: IGameEntityBasic | undefined = undefined;

        for (const entity of this.entities) {
            if (entity.nid === nid) {
                foundEntity = entity;
            }
        }

        if (foundEntity === undefined) {
            this.LogError(`Received delete event for an entity that our client doesnt think exists??? ${nid}`);
            return;
        }

        if ("HitboxCollider" in foundEntity && "currentCollisionGridCell" in foundEntity) {
            // console.warn('@@@ Found HitboxCollider and currentCollisionGridCell in entity, removing from collision system');
            // @ts-ignore TODO: hack
            // console.log("removingg entity from collision system", foundEntity);
            // @ts-ignore TODO: hack
            this.Collision.RemoveEntity(foundEntity);
        }

        this.entities.delete(foundEntity);

        // @ts-ignore
        this.Renderer.RemoveRenderedEntity(foundEntity);
        // @ts-ignore
        foundEntity.RenderedEntity.destroy();
    }

    public UpdateEntity(updateEntityPayload: EntityUpdatePayload) {
        try {
            const { nid, prop, value } = updateEntityPayload;
            let foundEntity: IGameEntityBasic | undefined = undefined;

            for (const entity of this.entities) {
                if (entity.nid === nid) {
                    foundEntity = entity;
                }
            }

            if (foundEntity === undefined) {
                this.LogWarning(`Received update event for an entity that our client doesnt think exists??? ${nid}`);
                return;
            }

            // TODO this makes typescript unhappy but its necessary in this case right??
            // @ts-ignore
            foundEntity[prop] = value;

            // Selectively apply server-side updates to our local predicted player
            if (playerConfig.ENABLE_PREDICTION) {
                if (nid === this.myEntityNid) {
                    const myEntity = this.predictor.GetPredictedEntity();
                    // TODO: hack, come up with hook system for non-predicted updates to our entity like this
                    if (myEntity !== undefined) {
                        if (ClientPlayerPredicted.WhitelistedReplicatedProperties.includes(prop)) {
                            // @ts-ignore
                            myEntity[prop] = value;
                        }
                    }
                }
            }
        } catch (e) {
            console.error("Caught error in update entity!");
            console.error(e);
        }
    }
}

new GameClient();

Game.InitializeUI();

Game.Start();

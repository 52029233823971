import { ProjectileEntityCreationPayload } from "../../shared/SharedNetcodeSchemas";
import { ClientProjectile } from "./ClientProjectile";

export class ClientProjectilePredicted extends ClientProjectile {
    public constructor(createProjectilePayload: ProjectileEntityCreationPayload, distanceMultiplier: number) {
        super(createProjectilePayload, true, distanceMultiplier, 1, 1);

        this.RenderedEntity.alpha = 1;
    }

    public override Update(deltaInSeconds: number) {
        super.Update(deltaInSeconds);

        this.projectileLifeAccumulatorInSeconds += deltaInSeconds;

        this.Move(deltaInSeconds);

        if (this.projectileLifeAccumulatorInSeconds > this.maxProjectileLifetimeInSeconds) {
            this.ToBeDestroyed = true;
        }
    }
}

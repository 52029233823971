import worldData from "./world/world.json";

type tileId = number;

export interface TiledMapRegionObject {
    name: string;
    id: number;
    x: number;
    y: number;
    height: number;
    width: number;
}

interface TiledMapPositionObject {
    id: number;
    x: number;
    y: number;
    properties: { name: string; type: string; value: number }[];
}

interface TiledMapRegionObjectsLayer {
    type: "objectgroup";
    name: "ExtractionPoints" | "NamedRegions";
    objects: TiledMapRegionObject[];
}

interface TiledMapPointObjectsLayer {
    type: "objectgroup";
    name: "EnemySpawns" | "PlayerSpawns" | "LootSpawns";
    objects: TiledMapPositionObject[];
}

export interface TiledMapTileLayer {
    type: "tilelayer";
    height: number;
    width: number;
    name: "Collisions" | "Ground1" | "Ground2" | "Middleground" | "Foreground1" | "Foreground2";
    data: tileId[];
}

interface TiledMapInterface {
    height: number;
    width: number;
    tileheight: number;
    tilewidth: number;
    layers: (TiledMapPointObjectsLayer | TiledMapRegionObjectsLayer | TiledMapTileLayer)[];
}

export enum CollisionGridTileIds {
    // Full colliders (blocks both players and projectiles)
    EMPTY = 0,
    SOLID = 11,
    LEFT_HALF = 18,
    RIGHT_HALF = 19,
    TOP_HALF = 16,
    BOTTOM_HALF = 17,
    TOP_LEFT_QUARTER = 14,
    TOP_RIGHT_QUARTER = 12,
    BOTTOM_LEFT_QUARTER = 13,
    BOTTOM_RIGHT_QUARTER = 15,

    // "Low" colliders (blocks only player movement, projectiles fly through it)
    LOW_SOLID = 1,
    LOW_LEFT_HALF = 8,
    LOW_RIGHT_HALF = 9,
    LOW_TOP_HALF = 6,
    LOW_BOTTOM_HALF = 7,
    LOW_TOP_LEFT_QUARTER = 4,
    LOW_TOP_RIGHT_QUARTER = 2,
    LOW_BOTTOM_LEFT_QUARTER = 3,
    LOW_BOTTOM_RIGHT_QUARTER = 5,

    FULL_WATER = 1992
}

const mapData = worldData as TiledMapInterface;

/*********************************************/
/*                  Regions                  */
/*********************************************/
export const WorldRegionsFromTiledMap = mapData.layers.find((layer) => layer.name === "NamedRegions") as TiledMapRegionObjectsLayer;

/*********************************************/
/*                Enemy Spawns               */
/*********************************************/
export const EnemySpawnsFromTiledMap = mapData.layers.find((layer) => layer.name === "EnemySpawns") as TiledMapPointObjectsLayer;

/*********************************************/
/*               Player Spawns               */
/*********************************************/
export const PlayerSpawnsFromTiledMap = mapData.layers.find((layer) => layer.name === "PlayerSpawns") as TiledMapPointObjectsLayer;

/*********************************************/
/*               Loot Spawns                 */
/*********************************************/
export const LootSpawnsFromTiledMap = mapData.layers.find((layer) => layer.name === "LootSpawns") as TiledMapPointObjectsLayer;

/*********************************************/
/*            Extraction Points              */
/*********************************************/
export const ExtractionPointsFromTiledMap = mapData.layers.find((layer) => layer.name === "ExtractionPoints") as TiledMapRegionObjectsLayer;

/*********************************************/
/*              Collision Data               */
/*********************************************/
export const CollisionsLayerFromTiledMap = mapData.layers.find((layer) => layer.name === "Collisions") as TiledMapTileLayer;

/****************************************************/
/*            Actual Visual Tile Layers             */
/****************************************************/
export const Ground1LayerFromTiledMap = mapData.layers.find((layer) => layer.name === "Ground1") as TiledMapTileLayer;
// export const Ground2LayerFromTiledMap = mapData.layers.find((layer) => layer.name === "Ground2") as TiledMapTileLayer;
// export const MiddlegroundLayerFromTiledMap = mapData.layers.find((layer) => layer.name === "Middleground") as TiledMapTileLayer;
// export const Foreground1LayerFromTiledMap = mapData.layers.find((layer) => layer.name === "Foreground1") as TiledMapTileLayer;
// export const Foreground2LayerFromTiledMap = mapData.layers.find((layer) => layer.name === "Foreground2") as TiledMapTileLayer;

/*********************************************/
/*                   Utils                   */
/*********************************************/
export const getRandomPlayerSpawn = () => {
    const random = Math.floor(Math.random() * PlayerSpawnsFromTiledMap.objects.length);
    return PlayerSpawnsFromTiledMap.objects[random];
};

// export const getRandomEnemySpawn = () => {
//     const random = Math.floor(Math.random() * EnemySpawnsFromTiledMap.objects.length);
//     return EnemySpawnsFromTiledMap.objects[random];
// };

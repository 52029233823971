{
    "register__terms_label_1": "Al registrarte en PvPz.io & Second Monitor Games, estás aceptando nuestros",
    "register__terms_label_2": "Términos de Servicio",
    "register__terms_label_3": "Política de Privacidad",
    "register__promo_label": "Está bien que Second Monitor Games me envíe correos electrónicos sobre promociones y actualizaciones del juego",
    "adblock_detected_failed_to_insure": "Algo salió mal al asegurar ese artículo. ¡Por favor, asegúrate de que tu bloqueador de anuncios esté desactivado!",
    "ability_type_active": "Activo",
    "ability_type_passive": "Pasivo",
    "change_username__back": "Volver",
    "change_username__cant_be_empty_error": "El nombre de usuario no puede estar vacío",
    "change_username__current_prompt": "Tu nombre de usuario actual",
    "change_username__enter_new": "Ingresa un nuevo nombre de usuario",
    "change_username__header": "Nombre de usuario",
    "change_username__save": "Guardar",
    "change_username__too_long_error": "El nombre de usuario no puede tener más de 20 caracteres.",
    "character_already_on_run_menu__abondon_run_button": "Abandonar ejecución",
    "character_already_on_run_menu__back_to_main_menu_button": "Volver",
    "character_already_on_run_menu__bolded_header": "¡Tu personaje ya está en una ejecución!",
    "character_already_on_run_menu__paragraph_one": "Tu personaje aún no ha regresado de su ejecución anterior. ¡Si comienzas una nueva ejecución ahora, todos tus objetos de la ejecución anterior (excepto los objetos asegurados) se perderán!",
    "character_already_on_run_menu__paragraph_two": "Si crees que esto es un error, o si deseas abandonar intencionalmente tu ejecución, puedes abandonarla forzosamente utilizando el botón de abajo.",
    "character_already_on_run_menu__title": "¡ADVERTENCIA!",
    "connection_error__back_to_main_menu": "Volver al menú principal",
    "connection_error__error_reason": "Motivo del error",
    "connection_error__oof": "¡Vaya!",
    "connection_error__server_error_header": "¡Error de conexión al servidor!",
    "death_menu__back_to_main_menu_button": "Volver al menú",
    "death_menu__gear_lost": "Objetos perdidos",
    "death_menu__gold_lost": "Oro perdido",
    "death_menu__insured_items": "Objetos asegurados",
    "death_menu__loot_lost": "Botín perdido",
    "death_menu__nothing_insured": "¡No se aseguraron objetos!",
    "death_menu__saving_your_run_button": "Guardando...",
    "death_menu__spectate_button": "Observar al asesino",
    "death_menu__survived_for": "Sobreviviste durante",
    "death_menu__title": "R.I.P.",
    "death_menu__weapons_lost": "Armas perdidas",
    "death_menu__your_killer": "Tu asesino",
    "extracted_menu__back_to_main_menu_button": "Volver al menú",
    "extracted_menu__gold_looted": "Oro saqueado",
    "extracted_menu__loot_acquired": "Botín adquirido",
    "extracted_menu__run_duration": "Duración de la ejecución",
    "extracted_menu__title": "¡Extraído!",
    "game_hud__cooldown": "Tiempo de espera",
    "game_hud__find_an_extraction_point": "¡Encuentra un punto de extracción!",
    "game_hud__inventory_extract_prompt": "¡Debes extraerte para usar este botín!",
    "game_hud__inventory_hud": "Abrir inventario",
    "game_hud__leaderboard": "Tabla de clasificación",
    "game_hud__leaderboard_current_world": "Mundo actual",
    "game_hud__leaderboard_hide": "Ocultar",
    "game_hud__leaderboard_show": "Mostrar",
    "game_hud__looted_container_close": "Cerrar",
    "game_hud__spectating": "Observando",
    "game_hud__time_left_to_extract": "tiempo restante para extraer",
    "game_hud__your_inventory_header": "¡Tu inventario!",
    "game_hud__your_inventory_hide": "para cerrar",
    "game_transition__finding_match": "Buscando partida",
    "hotkey_interact": "E",
    "hotkey_open_inventory": "i",
    "hotkey_toggle_leaderboard": "L",
    "how_to_play__ability_one_key": "Habilidad #1",
    "how_to_play__ability_three_key": "Habilidad #3",
    "how_to_play__ability_two_key": "Habilidad #2",
    "how_to_play__back_to_main_menu_button": "Volver",
    "how_to_play__chat_key": "Chat",
    "how_to_play__extraction": "¡Encuentra un punto de extracción!",
    "how_to_play__inventory_key": "Inventario",
    "how_to_play__leaderboard_key": "Tabla de clasificación",
    "how_to_play__mouse_aim_controls": ": Apuntar",
    "how_to_play__mouse_shoot_controls": ": Disparar",
    "how_to_play__move_down_key": "Mover hacia abajo",
    "how_to_play__move_left_key": "Mover hacia la izquierda",
    "how_to_play__move_right_key": "Mover hacia la derecha",
    "how_to_play__move_up_key": "Mover hacia arriba",
    "how_to_play__small_paragraph_one": "Si no logras extraerte a tiempo, perderás todos los objetos que llevas contigo (¡a menos que los hayas asegurado!) y todos los objetos que hayas saqueado en tu ejecución. Extraer es muy importante. Debes EXTRAER con tu botín para conservarlo y usarlo.",
    "how_to_play__small_paragraph_three": "¡Mata o sé asesinado, que gane el mejor aventurero!",
    "how_to_play__small_paragraph_two": " ",
    "how_to_play__test_keys_header": "¡Prueba las teclas!",
    "how_to_play__title": "PvPz.io",
    "how_to_play__top_big_paragraph": "¡Mata a otros jugadores, encuentra botín y extrae!",
    "how_to_play__weapon_one_key": "Arma #1",
    "how_to_play__weapon_two_key": "Arma #2",
    "in_game__extracting": "Extrayendo...",
    "in_game__time_remaining": "Tiempo restante...",
    "in_game__warning_time_almost_up": "¡Encuentra un punto de extracción!",
    "insured_item_nothing": "Nada",
    "item__AG_AmuletOfCursedFate__gameplay": "+10% de Velocidad de Ataque | REANIMA con 40% de Salud cuando mueres (no se acumula con otros objetos de resurrección)",
    "item__AG_AmuletOfCursedFate__lore": "Un amuleto malévolo que retuerce los destinos y trae consigo oscuros presagios.",
    "item__AG_AmuletOfCursedFate__name": "Amuleto del Destino Maldito",
    "item__AG_MagicBoots__name": "Botas Mágicas",
    "item__AG_MagicBoots__gameplay": "Vuelve invisible durante 9 segundos al usarlo (2 usos) | Tu salud se regenera todo el tiempo | Extracción 10% más lenta.",
    "item__AG_MagicBoots__lore": "Estas botas no hacen ruido y revitalizan a quien las lleva puestas.",
    "item__AG_Boros__gameplay": "Vuelve invisible durante 7 segundos al usarlo | -12% de Daño a PNJ | +12% de Robo de vida por todo el daño que infliges a jugadores",
    "item__AG_Boros__lore": "Se rumorea que su gema fue creada a partir del ojo de un cambiaformas.",
    "item__AG_Boros__name": "Boros",
    "item__AG_DarkPustulesRing__gameplay": "Recibes 5% menos de daño mientras te extraes | Extraes 5% más rápido",
    "item__AG_DarkPustulesRing__lore": "Este anillo se puede usar para obtener mágicamente líquido oscuro y dulce durante un día.",
    "item__AG_DarkPustulesRing__name": "Anillo de Pústulas Oscuras",
    "item__AG_DreamWeaversEmbrace__gameplay": "Vuelve invisible durante 4 segundos al usarlo | -5% de Daño a jugadores | Extrae un 10% más rápido | INCOGNITO: Oculta tu botín y los mensajes de tu ubicación al resto del mundo",
    "item__AG_DreamWeaversEmbrace__lore": "Las piedras de este anillo cambian de color en áreas donde el velo es delgado.",
    "item__AG_DreamWeaversEmbrace__name": "Abrazo del Tejedor de Sueños",
    "item__AG_ForestsHaven__gameplay": "Cura 20 de HP al usarlo | Extrae un 10% más rápido | INCOGNITO: Oculta tu botín y los mensajes de tu ubicación al resto del mundo | +20% de Etéreo",
    "item__AG_ForestsHaven__lore": "Para aquellos que llevan este anillo, busquen el bosque de árboles escondido entre los sueños.",
    "item__AG_ForestsHaven__name": "Refugio del Bosque",
    "item__AG_MysteriousRing__gameplay": "Agrega 30 segundos a tu temporizador de extracción al usarlo | +10 de Salud Máxima | +7% de Velocidad de Movimiento al caminar en el agua",
    "item__AG_MysteriousRing__lore": "???",
    "item__AG_MysteriousRing__name": "Anillo Misterioso",
    "item__AG_ObsidianEcho__gameplay": "EXTRACCIÓN al usarlo, sin importar dónde te encuentres | +12% de Velocidad de Movimiento | REANIMA con 70% de Salud cuando mueres (no se acumula con otros objetos de resurrección) | -30 de Salud Máxima | Este objeto se destruye cuando se usa completamente",
    "item__AG_ObsidianEcho__lore": "Forjado a partir de un fragmento de la espada de Malachi. Si escuchas atentamente, puedes oír el eco de las almas de aquellos a quienes él mató.",
    "item__AG_ObsidianEcho__name": "Eco de Obsidiana",
    "item__AG_PepperedCollar__gameplay": "Otorga 4 segundos de aumento del 25% en la velocidad de movimiento al usarlo (1 uso) | -3% Distancia del Proyectil",
    "item__AG_PepperedCollar__lore": "Un collar creado como tributo a una famosa bestia felina.",
    "item__AG_PepperedCollar__name": "Collar Condimentado",
    "item__AG_RingOfAnOceanMan__gameplay": "Concede 2 segundos de +20% de velocidad de movimiento al usarlo | Extracción 5% más rápida | +5% de Velocidad de Movimiento al caminar en el agua",
    "item__AG_RingOfAnOceanMan__lore": "Da la sensación de que intenta tomar tu mano y llevarte a una tierra que solo él comprende.",
    "item__AG_RingOfAnOceanMan__name": "Anillo del Hombre del Océano",
    "item__AG_StuddedNecklace__gameplay": "Vuelve invisible durante 4 segundos al usarlo (3 cargas) | -3% de Velocidad de Movimiento",
    "item__AG_StuddedNecklace__lore": "Debería asegurarme de esto",
    "item__AG_StuddedNecklace__name": "Collar con Tachuelas",
    "item__AG_TheDarkLordsEmbrace__gameplay": "+30 Salud Máxima",
    "item__AG_TheDarkLordsEmbrace__lore": "Maldito por las diversas atrocidades cometidas por su portador.",
    "item__AG_TheDarkLordsEmbrace__name": "El Abrazo del Señor Oscuro",
    "item__AG_TimeHerosRing__gameplay": "Agrega 45 segundos a tu temporizador de extracción al usarlo | +7% de Velocidad de Ataque | +7% de Velocidad de Movimiento al caminar en el agua | -5% de Velocidad de Movimiento | +9% de Defensa contra Naturaleza | +7% de Daño de Naturaleza",
    "item__AG_TimeHerosRing__lore": "Usado por un niño elfo que podía viajar en el tiempo.",
    "item__AG_TimeHerosRing__name": "Anillo del Héroe del Tiempo",
    "item__AG_VestiasSoothingSignet__gameplay": "Cura 30PS cuando se usa | INCOGNITO: Oculta tus mensajes de botín y ubicación al resto del mundo",
    "item__AG_VestiasSoothingSignet__lore": "Un anillo que permite al portador calmar tanto heridas como tensiones en el grupo.",
    "item__AG_VestiasSoothingSignet__name": "Sello Calmante de Vestia",
    "item__AG_ViolasDinnerReminder__gameplay": "+8,5% de Defensa contra Naturaleza | +8,5% de Defensa contra Escarcha | Recibes 7% menos daño mientras te extraes | Tus proyectiles viajan un 5% menos",
    "item__AG_ViolasDinnerReminder__lore": "Un anillo que le permite a todos a tu alrededor saber cuándo tienes hambre.",
    "item__AG_ViolasDinnerReminder__name": "Recordatorio de Cena de Viola",
    "item__AG_WolfMedallion__gameplay": "Cura 10HP al usarlo | +10% de velocidad de ataque | +10% de absorción de todo el daño que infliges a los jugadores",
    "item__AG_WolfMedallion__lore": "Sintonizado con la magia, este medallón puede detectar lugares cercanos de poder.",
    "item__AG_WolfMedallion__name": "Medallón del Lobo",
    "item__CI_AlloyedBend__gameplay": "",
    "item__CI_AlloyedBend__lore": "Un anillo hecho de varios metales raros que han sido fusionados aproximadamente.",
    "item__CI_AlloyedBend__name": "Curva Aleada",
    "item__CI_ApposSignet__gameplay": "",
    "item__CI_ApposSignet__lore": "Se rumorea que pertenecía a un híbrido medio dragón, medio humano que tenía la capacidad de controlar y hablar con los patos.",
    "item__CI_ApposSignet__name": "Sello de Appo",
    "item__CI_BloodhuntersRing__gameplay": "",
    "item__CI_BloodhuntersRing__lore": "El cazasangre busca a su linaje, y también a ti.",
    "item__CI_BloodhuntersRing__name": "Anillo del Cazasangre",
    "item__CI_CelestialEternity__gameplay": "",
    "item__CI_CelestialEternity__lore": "Aquellos que se levantaron contra Malachi recibieron este anillo de los dioses.",
    "item__CI_CelestialEternity__name": "Eternidad Celestial",
    "item__CI_EbonstarBand__gameplay": "",
    "item__CI_EbonstarBand__lore": "Este anillo contiene parte de la estrella caída que lo cambió todo.",
    "item__CI_EbonstarBand__name": "Banda Estrella de Ébano",
    "item__CI_EchoingWhisperNecklace__gameplay": "",
    "item__CI_EchoingWhisperNecklace__lore": "Un collar que captura y hace eco de los murmullos de las almas perdidas.",
    "item__CI_EchoingWhisperNecklace__name": "Collar de Murmullo Ecoante",
    "item__CI_FeyEnchantmentLocket__gameplay": "",
    "item__CI_FeyEnchantmentLocket__lore": "Un medallón impregnado con encantamientos del reino de las Hadas, otorgando un atractivo seductor.",
    "item__CI_FeyEnchantmentLocket__name": "Medallón de Encantamiento Feérico",
    "item__CI_FragmentOfFate__gameplay": "",
    "item__CI_FragmentOfFate__lore": "Una vez completo, ahora hecho añicos. Una vez unido, ahora dividido. Una vez eterno, ahora obsoleto.",
    "item__CI_FragmentOfFate__name": "Fragmento del Destino",
    "item__CI_LatrosKnavishBand__gameplay": "",
    "item__CI_LatrosKnavishBand__lore": "Un anillo que solo los más viles ladrones con certeza moral usarían.",
    "item__CI_LatrosKnavishBand__name": "Banda Latros de Malhechor",
    "item__CI_LifesSimpleRing__gameplay": "",
    "item__CI_LifesSimpleRing__lore": "Vive de forma sencilla para que otros puedan vivir de forma sencilla.",
    "item__CI_LifesSimpleRing__name": "Anillo de la Vida Simple",
    "item__CI_LuminousEnigma__gameplay": "",
    "item__CI_LuminousEnigma__lore": "Poseo la llave de un vasto conocimiento, proporcionando respuestas inigualables.",
    "item__CI_LuminousEnigma__name": "Enigma Luminoso",
    "item__CI_Ouro__gameplay": "",
    "item__CI_Ouro__lore": "Se dice que es la clave para desbloquear el conocimiento de todas las criaturas vivientes.",
    "item__CI_Ouro__name": "Ouro",
    "item__CI_PeachRing__gameplay": "",
    "item__CI_PeachRing__lore": "Tiene un sabor dulce.",
    "item__CI_PeachRing__name": "Anillo de Durazno",
    "item__CI_PlayerSoulFragment__gameplay": "Un recolector de almas estaría interesado en esto.",
    "item__CI_PlayerSoulFragment__lore": "Un fragmento del alma de un aventurero perdido.",
    "item__CI_PlayerSoulFragment__name": "Fragmento de Alma de Jugador",
    "item__CI_ProudlyPinkRing__gameplay": "",
    "item__CI_ProudlyPinkRing__lore": "Este anillo es un impresionante accesorio de moda que sin duda llamará la atención de los demás.",
    "item__CI_ProudlyPinkRing__name": "Anillo Orgullosamente Rosa",
    "item__CI_RadiantSunstoneBand__gameplay": "",
    "item__CI_RadiantSunstoneBand__lore": "Una creación paradójica, este anillo simboliza un delicado equilibrio entre la iluminación y la destrucción.",
    "item__CI_RadiantSunstoneBand__name": "Banda de Radiante Piedra Solar",
    "item__CI_RingOfDelerium__gameplay": "",
    "item__CI_RingOfDelerium__lore": "Si escuchas atentamente, puedes oír las voces de los antiguos dueños de este anillo.",
    "item__CI_RingOfDelerium__name": "Anillo de Delirio",
    "item__CI_RoyalBrooch__gameplay": "",
    "item__CI_RoyalBrooch__lore": "El símbolo de la familia real. Vale una buena cantidad.",
    "item__CI_RoyalBrooch__name": "Broche Real",
    "item__CI_SuperStar__gameplay": "",
    "item__CI_SuperStar__lore": "El remanente de una gran historia sobre un reino lleno de setas.",
    "item__CI_SuperStar__name": "Super Estrella",
    "item__CI_SwampOgresRing__gameplay": "",
    "item__CI_SwampOgresRing__lore": "Perteneció alguna vez a un ogro verde que protegía mucho su pantano.",
    "item__CI_SwampOgresRing__name": "Anillo del Ogro del Pantano",
    "item__CI_WeddingRing__gameplay": "",
    "item__CI_WeddingRing__lore": "Esto vale mucho, debería devolvérselo al dueño original.",
    "item__CI_WeddingRing__name": "Anillo de Boda",
    "item__PG_AngelistasPotionPopper__gameplay": "Tu salud se regenera constantemente.",
    "item__PG_AngelistasPotionPopper__lore": "Un anillo que usas cuando tu sanador te prohíbe beber pociones de curación.",
    "item__PG_AngelistasPotionPopper__name": "Poción Estalladora de Angelista",
    "item__PG_AquamoorsBlessing__gameplay": "Concede 3 segundos de +25% de velocidad de movimiento al usarlo (3 cargas) | Tu salud se regenera todo el tiempo | -20 de Salud Máxima",
    "item__PG_AquamoorsBlessing__lore": "Se dice que está bendecido por el espíritu del lago antes de la corrupción de las tierras.",
    "item__PG_AquamoorsBlessing__name": "Bendición de Aquamoor",
    "item__PG_ArcaneEmber__gameplay": "-6.5% de Daño a PNJ | +5.6% de Daño a jugadores",
    "item__PG_ArcaneEmber__lore": "Se puede ver una pequeña brasa que sigue brillando en la gema de este anillo.",
    "item__PG_ArcaneEmber__name": "Ascua Arcana",
    "item__PG_ArcaneSigilCollar__gameplay": "Concede 4 segundos de velocidad de movimiento +30% al usarlo | -8.5% de daño a PNJ | +9.5% de daño a jugadores",
    "item__PG_ArcaneSigilCollar__lore": "Grabado con símbolos poderosos, amplifica las habilidades arcanas del portador.",
    "item__PG_ArcaneSigilCollar__name": "Collar del Símbolo Arcano",
    "item__PG_AssassinSignet__gameplay": "Vuelve invisible durante 5 segundos al usarlo (2 cargas) | -3% Velocidad de movimiento | -3% Distancia de proyectil",
    "item__PG_AssassinSignet__lore": "Un sello sostenido por una infame asesina que luchaba con su cabello.",
    "item__PG_AssassinSignet__name": "Sello del Asesino",
    "item__PG_BellsShiningRing__gameplay": "Agrega 60 segundos a tu temporizador de extracción al usarlo | -9,5% de Daño a jugadores | +15,5% de Daño a PNJs | +40 de Salud Máxima | Tu salud se regenera todo el tiempo",
    "item__PG_BellsShiningRing__lore": "Conocida por muchos como la mejor sanadora del reino.",
    "item__PG_BellsShiningRing__name": "Anillo Brillante de Belle",
    "item__PG_BloodGemRing__gameplay": "REVIVE con 50% de salud cuando mueras | -20 Salud máxima",
    "item__PG_BloodGemRing__lore": "Aquellos que estaban en el templo durante el ritual cayeron en una frenesí de derramamiento de sangre. Este anillo simboliza tiempos desesperados.",
    "item__PG_BloodGemRing__name": "Anillo de Gema de Sangre",
    "item__PG_BloodiedVitality__gameplay": "EXTRACCIÓN al usarlo, sin importar dónde te encuentres | Tu salud se regenera todo el tiempo | +30 de Salud Máxima | -8% de Daño a Jugadores | Recibes un 10% más de daño de TODOS los tipos de daño al extraer | Este objeto se destruye cuando se usa completamente",
    "item__PG_BloodiedVitality__lore": "Tu conexión con la realidad y la vida misma se siente más fuerte con esto alrededor de tu cuerpo.",
    "item__PG_BloodiedVitality__name": "Vitalidad Ensangrentada",
    "item__PG_BloodmoonLoop__gameplay": "Restaura 5HP al usarlo (3 cargas) | +5% de Daño por Sangrado",
    "item__PG_BloodmoonLoop__lore": "Relacionado con los ciclos lunares. Esto aumenta la destreza física durante una luna de sangre.",
    "item__PG_BloodmoonLoop__name": "Armadura de Luna de Sangre",
    "item__PG_CapeOfPureDarkness__gameplay": "Otorga 4 segundos de velocidad de movimiento +25% cuando se usa | +9% de Defensa de Sombras | +9% de Defensa Tóxica | +7% de Daño Tóxico | Tu salud disminuye constantemente.",
    "item__PG_CapeOfPureDarkness__lore": "Nadie se atrevía a cuestionarlo por miedo. Porque el Gobernante Oscuro no debía ser cuestionado.",
    "item__PG_CapeOfPureDarkness__name": "Capa de Pura Oscuridad",
    "item__PG_CelestialSolaceBand__gameplay": "Cura 7PV al usarlo | INSPECCIÓN: Revela información sobre tus oponentes | Daño sombrío +6.5%",
    "item__PG_CelestialSolaceBand__lore": "Forjado por los propios dioses, este anillo ofrece consuelo en medio de la oscuridad.",
    "item__PG_CelestialSolaceBand__name": "Banda de Solaz Celestial",
    "item__PG_CelestialStarfallPendant__gameplay": "Vuelve invisible durante 3 segundos al usarlo | +6.5% de daño a PNJ | Daño sagrado +5%",
    "item__PG_CelestialStarfallPendant__lore": "Un colgante adornado con una gema celestial, invocando energía celestial.",
    "item__PG_CelestialStarfallPendant__name": "Colgante de Estrella Celestial",
    "item__PG_CultistsMask__name": "Máscara del Cultista",
    "item__PG_CultistsMask__lore": "Aquellos que cayeron víctimas de la corrupción buscaron ocultar su apariencia, ya que la corrupción cambió su ser por completo.",
    "item__PG_CultistsMask__gameplay": "Vuelve invisible durante 6 segundos al usarlo | INCOGNITO: Oculta tus mensajes de botín y ubicación del resto del mundo | +15% de Velocidad de Ataque",
    "item__PG_DepthStrider__name": "Andarivel de las Profundidades",
    "item__PG_DepthStrider__lore": "Un encantamiento débil se aferra a esta capa, facilitando caminar sobre el agua.",
    "item__PG_DepthStrider__gameplay": "+5% de velocidad de movimiento mientras caminas sobre el agua.",
    "item__PG_GoldenGauntlets__name": "Guanteletes Dorados",
    "item__PG_GoldenGauntlets__lore": "Adecuados para saqueadores, estos guanteletes parecen ayudarte a encontrar más oro.",
    "item__PG_GoldenGauntlets__gameplay": "+10% de oro de los cofres.",
    "item__PG_BeastVanquisherBoots__name": "Botas del Vencedor de Bestias",
    "item__PG_BeastVanquisherBoots__lore":  "Usadas por aquellos en la primera línea de batalla. Estas botas permitieron la rápida derrota del enemigo.",
    "item__PG_BeastVanquisherBoots__gameplay": "+12% de Velocidad de Movimiento | +45 de Salud Máxima | +25% de Daño a PNJs | -12% de Daño a Jugadores | -12% de Distancia de Proyectiles",
    "item__PG_CloakOfModestApproachability__gameplay": "Tus proyectiles viajan un 6% más lejos | Agrega 30 segundos a tu temporizador de extracción al usarlo",
    "item__PG_CloakOfModestApproachability__lore": "Los demás parecen atraídos hacia ti y no están seguros de por qué.",
    "item__PG_CloakOfModestApproachability__name": "Capa de Modesta Aproximación",
    "item__PG_CloudedDarkness__gameplay": "+20% Etéreo | Daño sombrío +10% | Defensa sombría +13%",
    "item__PG_CloudedDarkness__lore": "Cuando llegó el momento y se hizo la acción, una nube de oscuridad envolvió a Malachi y se imbuió en este anillo.",
    "item__PG_CloudedDarkness__name": "Oscuridad Nublada",
    "item__PG_CompactedLimeGreenRing__gameplay": "Tus proyectiles viajan un 5% más lejos | +7% de daño tóxico",
    "item__PG_CompactedLimeGreenRing__lore": "Sostenido por el heredero del trono de un dragón.",
    "item__PG_CompactedLimeGreenRing__name": "Anillo Verde Lima Compacto",
    "item__PG_CopperWristband__gameplay": "+6.5% de defensa física",
    "item__PG_CopperWristband__lore": "Un anillo cómicamente grande que encaja mejor como una pulsera... Solo se puede imaginar el tamaño de la criatura que usaba esto como anillo.",
    "item__PG_CopperWristband__name": "Pulsera de Cobre",
    "item__PG_CoralReef__gameplay": "+8,5% de Velocidad de Movimiento al caminar en el agua | Extracción 10% más lenta",
    "item__PG_CoralReef__lore": "Creado como un regalo para la princesa sirena.",
    "item__PG_CoralReef__name": "Arrecife de Coral",
    "item__PG_CorruptedTearRing__gameplay": "Vuelve invisible durante 5 segundos al usarlo | +7% Absorción de todo el daño que infliges a jugadores | -7% Defensa contra Caos | +9% Daño Caótico | +9% Daño de Rayos",
    "item__PG_CorruptedTearRing__lore": "Una sola lágrima se derramó antes de que la corrupción se apoderara... \"¿¡Qué he llegado a ser!?\"",
    "item__PG_CorruptedTearRing__name": "Anillo de Lágrima Corrupta",
    "item__PG_CursedEternityRing__gameplay": "Concede 5 segundos de +30% de velocidad de movimiento al usarlo | REANIMA con 48% de Salud cuando mueres (no se acumula con otros objetos de resurrección) | +12% de Oro de los cofres | +8,5% de Absorción de Vida de todo el daño infligido a los jugadores | Tu salud disminuye todo el tiempo",
    "item__PG_CursedEternityRing__lore": "Un símbolo de condenación eterna. Se adhiere a quien lo lleva, maldiciéndolos por la eternidad pero otorgándoles un gran poder.",
    "item__PG_CursedEternityRing__name": "Anillo de Eternidad Maldita",
    "item__PG_CursedSignet__gameplay": "+20% de absorción de todo el daño que infliges a jugadores",
    "item__PG_CursedSignet__lore": "Una presencia malévola emana de este anillo, trayendo desgracia a todos los que lo llevan.",
    "item__PG_CursedSignet__name": "Anillo Maldito",
    "item__PG_DeathwhisperWrap__gameplay": "+13% Daño a NPC | +13% Defensa contra Caos | +10% Daño Tóxico",
    "item__PG_DeathwhisperWrap__lore": "Cuando la hoja se hundió en su corazón y todo se volvió negro, se escuchó un susurro tenue: 'Aún no es tiempo de reclamar tu alma'.",
    "item__PG_DeathwhisperWrap__name": "Envoltura Susurro Mortal",
    "item__PG_DreadmorneLoop__gameplay": "Concede 3 segundos de +30% de velocidad de movimiento al usarlo (5 cargas) | -5% de Velocidad de Ataque | -6,5% de Defensa Sagrada | +10% de Daño de Caos",
    "item__PG_DreadmorneLoop__lore": "Este anillo infunde miedo en el corazón de los enemigos, inspirando terror y causando temor.",
    "item__PG_DreadmorneLoop__name": "Bucle Pesadumbral",
    "item__PG_DreamcasterDrapery__gameplay": "+6.5% de daño a NPC | +6.5% de daño caótico",
    "item__PG_DreamcasterDrapery__lore": "Nacido de pesadillas, se dice que esta vestidura manipula el subconsciente.",
    "item__PG_DreamcasterDrapery__name": "Manto del Encantador de Sueños",
    "item__PG_EbonfireRobelet__gameplay": "Tus proyectiles viajan un 6% más lejos",
    "item__PG_EbonfireRobelet__lore": "Envuelve a su portador en una aura de sombras humeantes, otorgando protección contra el daño elemental.",
    "item__PG_EbonfireRobelet__name": "Brazaletes de Fuego Oscuro",
    "item__PG_ElvenGildedGuardian__gameplay": "Extrae un 5% más rápido",
    "item__PG_ElvenGildedGuardian__lore": "Un cinturón bellamente elaborado, adornado con hiedra dorada, que mejora la agilidad del portador y repele a los adversarios con la destreza élfica.",
    "item__PG_ElvenGildedGuardian__name": "Guardián Dorado Élfico",
    "item__PG_EmbergraspRing__gameplay": "+10 Salud máxima",
    "item__PG_EmbergraspRing__lore": "Las llamas danzan y titilan al tocarlo, quemando a tus enemigos.",
    "item__PG_EmbergraspRing__name": "Anillo de Agarre de Brasa",
    "item__PG_EnchantedElixirNecklace__name": "Collar de Elixir Encantado",
    "item__PG_EnchantedElixirNecklace__gameplay": "Agrega 60 segundos a tu temporizador de extracción al usarlo | REVIVE con un 60% de HP cuando mueras (no se acumula con otros objetos de revivir)",
    "item__PG_EnchantedElixirNecklace__lore": "Un collar que contiene una ampolla de elixir encantado, aumentando la potencia mágica.",
    "item__PG_EnigmaLoop__name": "Bucle Enigma",
    "item__PG_EnigmaLoop__gameplay": "INCOGNITO: Oculta tus mensajes de botín y ubicación al resto del mundo | +5% de daño caótico",
    "item__PG_EnigmaLoop__lore": "Un anillo enigmático que confunde a los enemigos, retorciendo la realidad a tu favor.",
    "item__PG_EnigmaticShadowweaveRing__name": "Anillo Tejido de Sombras Enigmáticas",
    "item__PG_EnigmaticShadowweaveRing__gameplay": "Restaura 20HP al usarlo (1 carga) | +5,5% de Daño a Jugadores | -9% de Daño a PNJs",
    "item__PG_EnigmaticShadowweaveRing__lore": "Infundido con encantamientos enigmáticos, este anillo entrelaza hilos de oscuridad.",
    "item__PG_EssenceConduit__name": "Conducto de Esencia",
    "item__PG_EssenceConduit__gameplay": "+9% de Daño a PNJ | -4% de Daño a jugadores",
    "item__PG_EssenceConduit__lore": "Otorga poder a aquellos que cuidan de la naturaleza.",
    "item__PG_EverlastingSpiritCircle__name": "Círculo de Espíritu Eterno",
    "item__PG_EverlastingSpiritCircle__gameplay": "Concede 3 segundos de +25% de velocidad de movimiento al usarlo (2 cargas) | +10% de Oro de los cofres",
    "item__PG_EverlastingSpiritCircle__lore": "Elaborado mediante la antigua magia, este anillo actúa como un conducto de energía espiritual.",
    "item__PG_FrostFireRing__gameplay": "+7% de Daño de Fuego | +8,5% de Defensa contra Hielo | -6,5% de Defensa contra Fuego",
    "item__PG_FrostFireRing__lore": "Un anillo incrustado con piedras elementales que puede imbuir los ataques con poder adicional: \"Porque primero eres ardiente y luego eres frío\".",
    "item__PG_FrostFireRing__name": "Anillo Fuego Helado",
    "item__PG_FrostbiteLoop__gameplay": "+6% Velocidad de movimiento",
    "item__PG_FrostbiteLoop__lore": "Un anillo rodeado de un aura gélida, congela los corazones de los enemigos con un toque.",
    "item__PG_FrostbiteLoop__name": "Bucle Escarchado",
    "item__PG_FrostfireShardPendant__gameplay": "Recibes un 10% menos de daño mientras extraes | +8% Daño a jugadores",
    "item__PG_FrostfireShardPendant__lore": "Un colgante que alberga un fragmento impregnado de energías gélidas y llameantes.",
    "item__PG_FrostfireShardPendant__name": "Colgante de Fragmento Fuego Helado",
    "item__PG_GineshsBottleOpener__gameplay": "Tu salud se drena constantemente | +12% Daño a TODOS los tipos de daño | +8.5% Velocidad de movimiento | Tus proyectiles viajan un 8.5% más lejos",
    "item__PG_GineshsBottleOpener__lore": "Un anillo que desbloquea tanto la causa como la solución a todos los problemas de la vida.",
    "item__PG_GineshsBottleOpener__name": "Abrebotellas de Ginesh",
    "item__PG_GirdleOfTheGoldenNecromancer__gameplay": "Cura 10 HP al usarlo | +6% Velocidad de movimiento",
    "item__PG_GirdleOfTheGoldenNecromancer__lore": "Un cinturón opulento con incrustaciones de oro que confiere una aureola dorada de protección necromántica, alejando las lesiones.",
    "item__PG_GirdleOfTheGoldenNecromancer__name": "Faja del Nigromante Dorado",
    "item__PG_GoldPlatedCape__gameplay": "+4% Daño a jugadores",
    "item__PG_GoldPlatedCape__lore": "De alguna manera esto cuenta como armadura",
    "item__PG_GoldPlatedCape__name": "Capa Chapada en Oro",
    "item__PG_GolemsGrasp__gameplay": "+30 de Salud Máxima | -6% de Velocidad de Movimiento | -6% de Velocidad de Movimiento al caminar en el agua",
    "item__PG_GolemsGrasp__lore": "El poder de la piedra surge a través de este anillo, convirtiendo tu piel en armadura.",
    "item__PG_GolemsGrasp__name": "Garra del Gólem",
    "item__PG_GuardiansChainmailCollar__gameplay": "Cura 15 puntos de vida al usarlo (3 usos) | -5% Velocidad de Ataque",
    "item__PG_GuardiansChainmailCollar__lore": "Un collar resistente de malla encadenada que proporciona una protección firme.",
    "item__PG_GuardiansChainmailCollar__name": "Collar de Malla Encadenada del Guardián",
    "item__PG_HeirloomCloak__gameplay": "Concede 2 segundos de aumento del 20% en la velocidad de movimiento al usarlo (3 usos).",
    "item__PG_HeirloomCloak__lore": "Una capa sencilla transmitida a través de tu familia durante generaciones.",
    "item__PG_HeirloomCloak__name": "Capa de Herencia",
    "item__PG_IceQueensEarrings__gameplay": "Concede 5 segundos de +20% de velocidad de movimiento al usarlo | +4% Daño a jugadores | +6.5% Defensa de escarcha | +5% Daño de escarcha",
    "item__PG_IceQueensEarrings__lore": "Unos pendientes adecuados para la Reina de Hielo misma. Fríos al tacto.",
    "item__PG_IceQueensEarrings__name": "Pendientes de la Reina de Hielo",
    "item__PG_InfiniteLoop__gameplay": "Agrega 45 segundos a tu temporizador de extracción al usarlo | -10 de Salud máxima | Tu salud se regenera constantemente",
    "item__PG_InfiniteLoop__lore": "Capaz de convertir la energía vital en maná y el maná en energía vital.",
    "item__PG_InfiniteLoop__name": "Bucle Infinito",
    "item__PG_IronFoxLocket__gameplay": "INSPECCIÓN: Revela información sobre tus oponentes | +13% de defensa para TODOS los tipos de daño | +30 de salud máxima",    
    "item__PG_IronFoxLocket__lore": "Las leyendas hablan de un zorro, enfundado en una armadura de hierro, famoso por superar incluso a los cazadores más astutos.",
    "item__PG_IronFoxLocket__name": "Colgante del Zorro de Hierro",
    "item__PG_JaysesRing__gameplay": "+9,5% de Daño a jugadores | -9% de Daño a PNJs | +15% Etéreo | +12% de Daño Eléctrico | +12% de Daño de Fuego",
    "item__PG_JaysesRing__lore": "El anillo del famoso mago Jayse.",
    "item__PG_JaysesRing__name": "Anillo de Jayse",
    "item__PG_LittleCompactedLimeGreenRing__gameplay": "Tus proyectiles viajan un 6% más lejos | +13% de Defensa Tóxica.",
    "item__PG_LittleCompactedLimeGreenRing__lore": "Perteneció alguna vez a un linaje de dragones lujosos.",
    "item__PG_LittleCompactedLimeGreenRing__name": "Anillo Pequeño Compacto de Color Verde Lima",
    "item__PG_MementoMoriLocket__gameplay": "-6.5% Defensa contra el Caos | Defensa contra Sombras del +8.5%",
    "item__PG_MementoMoriLocket__lore": "Un medallón inquietante que sirve como recordatorio de la mortalidad, fortaleciendo la determinación.",
    "item__PG_MementoMoriLocket__name": "Medallón Memento Mori",
    "item__PG_MidnightsVeilBand__gameplay": "Vuelve invisible durante 5 segundos al usarlo (2 cargas)",
    "item__PG_MidnightsVeilBand__lore": "Usada con hebras de sombra, esta capa mejora el sigilo de quien la lleva.",
    "item__PG_MidnightsVeilBand__name": "Velo de Medianoche",
    "item__PG_MunchkinsRing__gameplay": "+7% Velocidad de Movimiento | -10 Salud Máxima",
    "item__PG_MunchkinsRing__lore": "Su dueño anterior tenía patitas pequeñas, pero eso nunca lo frenó.",
    "item__PG_MunchkinsRing__name": "Anillo del Munchkin",
    "item__PG_NaturesAureateBarrier__gameplay": "Agrega 30 segundos a tu temporizador de extracción al usarlo (2 usos) | -5,5% de daño a jugadores | INCOGNITO: Oculta tus mensajes de botín y ubicación al resto del mundo.",
    "item__PG_NaturesAureateBarrier__lore": "Un cinturón robusto tejido en oro, impregnado con la fortaleza de los árboles antiguos, otorgando al portador un escudo de fuerza natural resplandeciente.",
    "item__PG_NaturesAureateBarrier__name": "Barrera Aureada de la Naturaleza",
    "item__PG_NecroticGraspTalisman__gameplay": "Concede 5 segundos de +30% de velocidad de movimiento al usarlo | Tu salud se drena constantemente | +12% Daño de sombra",
    "item__PG_NecroticGraspTalisman__lore": "Un talismán pulsante con poder necromántico, que comanda las fuerzas de los muertos vivientes.",
    "item__PG_NecroticGraspTalisman__name": "Talismán de Agarre Necrótico",
    "item__PG_NecroticSignet__gameplay": "Agrega 45 segundos a tu temporizador de extracción al usarlo | +13% Defensa sagrada",
    "item__PG_NecroticSignet__lore": "Un símbolo macabro de muerte y decadencia que drena la vitalidad de tus enemigos.",
    "item__PG_NecroticSignet__name": "Gambesón Necrótico",
    "item__PG_PendantOfAncientWisdom__gameplay": "-7% Daño a jugadores | +8.75% Daño a PNJ | +8.5% Defensa arcana | +7% Daño arcana",
    "item__PG_PendantOfAncientWisdom__lore": "Un antiguo colgante que otorga un conocimiento profundo a quien lo lleva.",
    "item__PG_PendantOfAncientWisdom__name": "Colgante de Sabiduría Ancestral",
    "item__PG_PhantomBand__gameplay": "+30% Etéreo",
    "item__PG_PhantomBand__lore": "Al usar este anillo etéreo, puedes caminar sin ser visto entre los vivos.",
    "item__PG_PhantomBand__name": "Banda del Fantasma",
    "item__PG_PhoenixFeatherTorque__gameplay": "REANIMA con 60% de Salud cuando mueres (no se acumula con otros objetos de resurrección) | +60 de Salud Máxima | +19,5% de Defensa contra Fuego | +19,5% de Defensa contra Escarcha | -19,5% de Defensa contra Tóxicos | -15% de Velocidad de Ataque",
    "item__PG_PhoenixFeatherTorque__lore": "Un majestuoso torque adornado con una pluma de fénix, que otorga renacimiento y renovación.",
    "item__PG_PhoenixFeatherTorque__name": "Torque de Pluma de Fénix",
    "item__PG_PhoenixsRespite__gameplay": "Vuelve invisible durante 3 segundos al usarlo | +6.5% Defensa de Fuego | +5% Daño de Fuego",
    "item__PG_PhoenixsRespite__lore": "Aquellos que se atrevieron a reírse del nombre de este anillo a menudo eran asesinados por su portador.",
    "item__PG_PhoenixsRespite__name": "Alivio del Fénix",
    "item__PG_PurpletratorsRing__gameplay": "+3% Robo de vida de todo el daño que infliges a jugadores",
    "item__PG_PurpletratorsRing__lore": "Aquellos que se atrevieron a reírse del nombre de este anillo a menudo eran asesinados por su portador.",
    "item__PG_PurpletratorsRing__name": "Anillo del Intruso Púrpura",
    "item__PG_QuintsFishingGarb__gameplay": "+5% Velocidad de ataque",
    "item__PG_QuintsFishingGarb__lore": "Un anillo que crea una cantidad infinita de gusanos de pesca, a menudo distribuidos sin saberlo a los miembros del grupo.",
    "item__PG_QuintsFishingGarb__name": "Atuendo de Pesca de Quint",
    "item__PG_ResilientHeartstone__gameplay": "Restaura 10HP al usarlo | +5% de Oro de los cofres | Recibes un 5% menos de daño mientras te extraes | INSPECCIÓN: Revela información sobre tus oponentes",
    "item__PG_ResilientHeartstone__lore": "Una gema en forma de corazón que infunde fortaleza inquebrantable en quien la lleva.",
    "item__PG_ResilientHeartstone__name": "Corazón Resistente",
    "item__PG_RingOfConstellations__gameplay": "Tus proyectiles viajan un 6% más lejos",
    "item__PG_RingOfConstellations__lore": "Se rumorea que un guerrero de la noche poseía este anillo y brindaba orientación en la oscuridad de la noche.",
    "item__PG_RingOfConstellations__name": "Anillo de Constelaciones",
    "item__PG_RingOfTheOneHandedWarrior__gameplay": "-10 de Salud Máxima | +12% de Daño Físico | +12% de Daño de Sangrado",
    "item__PG_RingOfTheOneHandedWarrior__lore": "Solo necesitas una mano para llevar un anillo.",
    "item__PG_RingOfTheOneHandedWarrior__name": "Anillo del Guerrero de una Mano",
    "item__PG_RingOfViolentViolet__gameplay": "+30 Salud máxima | +17% Daño a TODOS los tipos de daño | -15% Defensa contra TODOS los tipos de daño",
    "item__PG_RingOfViolentViolet__lore": "Sientes una repentina oleada de poder y sed de sangre al equipar este anillo.",
    "item__PG_RingOfViolentViolet__name": "Anillo de Violeta Violenta",
    "item__PG_RuneboundRing__gameplay": "+5% Velocidad de movimiento al caminar en el agua | +7% Daño Arcano",
    "item__PG_RuneboundRing__lore": "Grabado con antiguas runas, canaliza energías arcanas para potenciar al portador.",
    "item__PG_RuneboundRing__name": "Anillo Vinculado a Runas",
    "item__PG_SerpentsCoilChoker__gameplay": "INSPECCIÓN: Revela información sobre tus oponentes",
    "item__PG_SerpentsCoilChoker__lore": "Enroscándose alrededor del cuello, imparte al portador la gracia y astucia de una serpiente.",
    "item__PG_SerpentsCoilChoker__name": "Collar de la Serpiente Enroscada",
    "item__PG_SerpentsRing__gameplay": "+6.5% Resistencia Tóxica",
    "item__PG_SerpentsRing__lore": "Un anillo hecho de las escamas de una serpiente, encantado para proteger al portador del veneno.",
    "item__PG_SerpentsRing__name": "Anillo de la Serpiente",
    "item__PG_ShieldbearersAmulet__gameplay": "Restaura 10HP al usarlo (1 carga) | +6,5% de daño a PNJs",
    "item__PG_ShieldbearersAmulet__lore": "Con un escudo de armas grabado, fortalece las defensas del portador.",
    "item__PG_ShieldbearersAmulet__name": "Amuleto del Portador del Escudo",
    "item__PG_SoulBindingRing__gameplay": "REVIVE con un 40% de HP cuando mueras | +10% Daño Sagrado",
    "item__PG_SoulBindingRing__lore": "Este anillo tiene tres piedras. Una es clara y transparente. Las otras dos contienen una extraña neblina dorada.",
    "item__PG_SoulBindingRing__name": "Anillo de Vinculación de Almas",
    "item__PG_SoulforgeRing__gameplay": "EXTRACCIÓN al usarlo, sin importar dónde te encuentres | Extracción 10% más lenta | Este objeto se destruye cuando se usa completamente",
    "item__PG_SoulforgeRing__lore": "Infundido con la esencia de almas atormentadas, este anillo aumenta tu destreza física y mística.",
    "item__PG_SoulforgeRing__name": "Anillo de Forja de Almas",
    "item__PG_SoulshackleVeil__gameplay": "Concede 3 segundos de +25% de velocidad de movimiento al usarlo (3 cargas) | +8,5% Etéreo | -5% de Velocidad de Movimiento",
    "item__PG_SoulshackleVeil__lore": "Atado por magia oscura, atrapa las almas de los caídos, conectándote con el mundo espiritual.",
    "item__PG_SoulshackleVeil__name": "Velo de Esclavo de Almas",
    "item__PG_SoulstoneReliquary__gameplay": "Vuelve invisible durante 4 segundos al usarlo | Recibes un 5% menos de daño mientras te extraes | REANIMA con 20% de Salud cuando mueres (no se acumula con otros objetos de resurrección) | +5% de Absorción de Vida de todo el daño que infliges a los jugadores",
    "item__PG_SoulstoneReliquary__lore": "Un collar reliquia que permite al portador aprovechar y controlar almas capturadas.",
    "item__PG_SoulstoneReliquary__name": "Relicario de Piedra de Almas",
    "item__PG_StormweaverCloak__gameplay": "INCOGNITO: Oculta tus mensajes de botín y ubicación del resto del mundo",
    "item__PG_StormweaverCloak__lore": "Domina la furia de las tormentas eléctricas, comandando la ira de los cielos.",
    "item__PG_StormweaverCloak__name": "Capa del Tejedor de Tormentas",
    "item__PG_SummonersSign__gameplay": "+10 Salud máxima",
    "item__PG_SummonersSign__lore": "Utilizado por los invocadores para recolectar maná de la tierra.",
    "item__PG_SummonersSign__name": "Sello del Invocador",
    "item__PG_TalismanOfVeiledShadows__gameplay": "Vuelve invisible durante 3 segundos al usarlo | -5% Velocidad de movimiento | +8.5% Defensa del Caos | +8.5% Defensa contra Sombras",
    "item__PG_TalismanOfVeiledShadows__lore": "Un talismán místico que envuelve al portador en una oscuridad efímera.",
    "item__PG_TalismanOfVeiledShadows__name": "Talismán de Sombras Veladas",
    "item__PG_TantrumsLuckyLoop__gameplay": "+3% Velocidad de movimiento",
    "item__PG_TantrumsLuckyLoop__lore": "Un anillo que te hace tan rápido como un galgo, y tan perezoso.",
    "item__PG_TantrumsLuckyLoop__name": "Anillo de la Suerte de Tantrum",
    "item__PG_VampiricSeal__gameplay": "Restaura 7HP al usarlo (2 cargas) | +3% de Absorción de Vida de todo el daño que infliges a los jugadores",
    "item__PG_VampiricSeal__lore": "Alimentándose de la fuerza vital de tus enemigos, otorga vitalidad con cada golpe.",
    "item__PG_VampiricSeal__name": "Vestidura Vampírica",
    "item__PG_VeilstormCirclet__gameplay": "Cura 30 HP al usarlo | -10% Velocidad de Ataque | +12% Daño de Naturaleza",
    "item__PG_VeilstormCirclet__lore": "Posee el poder de una tormenta furiosa. Otorga a su portador el control sobre el viento y los relámpagos.",
    "item__PG_VeilstormCirclet__name": "Capa de la Tormenta Velada",
    "item__PG_VenomousBite__gameplay": "Añade 30 segundos a tu temporizador de extracción al usarlo | Tu salud disminuye constantemente | +10% Daño Tóxico",
    "item__PG_VenomousBite__lore": "Armadura usada por los antiguos médicos de la peste.",
    "item__PG_VenomousBite__name": "Mordedura Venenosa",
    "item__PG_WindspeakersRing__gameplay": "INSPECCIÓN: Revela información sobre tus oponentes | +7% Daño de Naturaleza",
    "item__PG_WindspeakersRing__lore": "Una vez fue propiedad de un poderoso mago que fue perseguido por todo el mundo por su propia sombra. Se dice que este anillo tiene el poder de controlar los vientos.",
    "item__PG_WindspeakersRing__name": "Anillo del Hablante de los Vientos",
    "item__PG_Wraithband__gameplay": "+8,5% Etéreo | 7% de Daño Sagrado | -10 de Salud Máxima",
    "item__PG_Wraithband__lore": "Una energía fantasmal recorre este anillo, otorgando poderes espectrales.",
    "item__PG_Wraithband__name": "Banda Espectral",
    "item__VT_AbyssalShard__gameplay": "",
    "item__VT_AbyssalShard__lore": "Un trozo de piedra negra recolectado del inframundo.",
    "item__VT_AbyssalShard__name": "Fragmento Abisal",
    "item__VT_Amber__gameplay": "",
    "item__VT_Amber__lore": "Esta piedra tiene un tono anaranjado profundo.",
    "item__VT_Amber__name": "Ámbar",
    "item__VT_BardsDiary__gameplay": "",
    "item__VT_BardsDiary__lore": "Esta canción está llena de poesía y partituras musicales.",
    "item__VT_BardsDiary__name": "Cuaderno del Bardo",
    "item__VT_BrokenIronBand__gameplay": "",
    "item__VT_BrokenIronBand__lore": "Un simple anillo de hierro que ha perdido su brillo.",
    "item__VT_BrokenIronBand__name": "Anillo de Hierro Roto",
    "item__VT_BrokenSword__gameplay": "",
    "item__VT_BrokenSword__lore": "Esta espada ha visto días mejores.",
    "item__VT_BrokenSword__name": "Espada de Hierro Rota",
    "item__VT_Cabbage__gameplay": "",
    "item__VT_Cabbage__lore": "Eres un repollo, siempre serás un repollo.",
    "item__VT_Cabbage__name": "Repollo",
    "item__VT_ChunkOfIron__gameplay": "",
    "item__VT_ChunkOfIron__lore": "Un pequeño trozo de hierro. Un herrero podría hacer algo con esto.",
    "item__VT_ChunkOfIron__name": "Trozo de Hierro",
    "item__VT_CubicZirconia__gameplay": "",
    "item__VT_CubicZirconia__lore": "Un trozo cuadrado de circón cúbico.",
    "item__VT_CubicZirconia__name": "Circón Cúbico",
    "item__VT_Diamond__gameplay": "A un coleccionista de objetos raros probablemente le interesaría esto.",
    "item__VT_Diamond__lore": "Una gema preciosa.",
    "item__VT_Diamond__name": "Diamante",
    "item__VT_DukesDiary__gameplay": "",
    "item__VT_DukesDiary__lore": "Los pensamientos de un duque una vez poderoso.",
    "item__VT_DukesDiary__name": "Diario del Duque de Ert",
    "item__VT_EmeraldDust__gameplay": "",
    "item__VT_EmeraldDust__lore": "Un esmeralda convertida en un fino polvo.",
    "item__VT_EmeraldDust__name": "Polvo de Esmeralda",
    "item__VT_Emerald__gameplay": "",

    "item__VT_Emerald__lore": "Un trozo de una esmeralda.",
    "item__VT_Emerald__name": "Esmeralda",
    "item__VT_EverlastingCandle__gameplay": "",
    "item__VT_EverlastingCandle__lore": "Esta vela misteriosamente arderá para siempre.",
    "item__VT_EverlastingCandle__name": "Vela Eterna",
    "item__VT_Eyeball__gameplay": "",
    "item__VT_Eyeball__lore": "El ojo de una bestia abatida.",
    "item__VT_Eyeball__name": "Ojo",
    "item__VT_FangOfTheBloodMoon__gameplay": "",
    "item__VT_FangOfTheBloodMoon__lore": "Este colmillo cubierto de sangre proviene de una criatura que acecha solo cuando el cielo está sangrando.",
    "item__VT_FangOfTheBloodMoon__name": "Colmillo de la Luna Sangrienta",
    "item__VT_Fossil__gameplay": "",
    "item__VT_Fossil__lore": "Los restos de un ser antiguo están permanentemente impresos en esta roca.",
    "item__VT_Fossil__name": "Fósil",
    "item__VT_GhostPlasma__gameplay": "",
    "item__VT_GhostPlasma__lore": "Este líquido viscoso brilla con energías etéreas.",
    "item__VT_GhostPlasma__name": "Plasma Fantasmal",
    "item__VT_GoblinCloth__gameplay": "",
    "item__VT_GoblinCloth__lore": "Esta tela está muy sucia y tiene algo de sangre.",
    "item__VT_GoblinCloth__name": "Taparrabos de Goblin",
    "item__VT_GoldNugget__gameplay": "",
    "item__VT_GoldNugget__lore": "Un pequeño trozo de oro. Probablemente valga mucho.",
    "item__VT_GoldNugget__name": "Pepita de Oro",
    "item__VT_OgreEntrails__gameplay": "",
    "item__VT_OgreEntrails__lore": "Entrañas del interior de un ogro.",
    "item__VT_OgreEntrails__name": "Entrañas de Ogro",
    "item__VT_OnyxGem__gameplay": "Un experto en joyas podría estar interesado en esto.",
    "item__VT_OnyxGem__lore": "Una gema rara hecha de ónix.",
    "item__VT_OnyxGem__name": "Gema de Ónix",
    "item__VT_PileOfAsh__gameplay": "",
    "item__VT_PileOfAsh__lore": "Solo un pequeño montón de cenizas.",
    "item__VT_PileOfAsh__name": "Montón de Cenizas",
    "item__VT_Potato__gameplay": "Cocínelos, tritúrelos, métalos en un guiso.",
    "item__VT_Potato__lore": "Probablemente nunca has oído hablar de ellas.",
    "item__VT_Potato__name": "Papa",
    "item__VT_QuartzRing__gameplay": "",

    "item__VT_QuartzRing__lore": "Engastado con un trozo de cuarzo pulido. No vale mucho, pero al menos es algo.",
    "item__VT_QuartzRing__name": "Anillo de Cuarzo",
    "item__VT_RatFur__gameplay": "",
    "item__VT_RatFur__lore": "Un montón repugnante de pelo, probablemente lleno de enfermedades.",
    "item__VT_RatFur__name": "Montón de Pelo de Rata",
    "item__VT_RedMushroom__gameplay": "",
    "item__VT_RedMushroom__lore": "Este hongo se seca comúnmente y se convierte en un polvo que se utiliza para ayudar en las enfermedades.",
    "item__VT_RedMushroom__name": "Hongo Rojo",
    "item__VT_RingOfNearPerfection__gameplay": "",
    "item__VT_RingOfNearPerfection__lore": "Sería mejor si tuviera magia.",
    "item__VT_RingOfNearPerfection__name": "Anillo de Casi Perfección",
    "item__VT_RubyDust__gameplay": "",
    "item__VT_RubyDust__lore": "Un rubí que ha sido triturado en un fino polvo.",
    "item__VT_RubyDust__name": "Polvo de Rubí",
    "item__VT_Ruby__gameplay": "",
    "item__VT_Ruby__lore": "Un trozo de rubí rojo intenso.",
    "item__VT_Ruby__name": "Rubí",
    "item__VT_SapphireDust__gameplay": "",
    "item__VT_SapphireDust__lore": "Un zafiro que ha sido convertido en un fino polvo.",
    "item__VT_SapphireDust__name": "Polvo de Zafiro",
    "item__VT_Sapphire__gameplay": "",
    "item__VT_Sapphire__lore": "Un trozo profundo y azul de un zafiro.",
    "item__VT_Sapphire__name": "Zafiro",
    "item__VT_SkeletonFemur__gameplay": "",
    "item__VT_SkeletonFemur__lore": "Supongo que hay un esqueleto con una pierna en algún lugar.",
    "item__VT_SkeletonFemur__name": "Fémur de Esqueleto",
    "item__VT_Skull__gameplay": "",
    "item__VT_Skull__lore": "Un cráneo. Probablemente de uno de estos esqueletos errantes.",
    "item__VT_Skull__name": "Cráneo",
    "item__VT_StellarWeaveRing__gameplay": "",
    "item__VT_StellarWeaveRing__lore": "Un anillo hecho de ramas tejidas. No tiene nada especial.",
    "item__VT_StellarWeaveRing__name": "Anillo de Tejido Estelar",
    "item__VT_TreantBranch__gameplay": "",
    "item__VT_TreantBranch__lore": "Este trozo de madera se mueve un poco. Extraño.",
    "item__VT_TreantBranch__name": "Rama de Treant",
    "item__VT_WellWornRing__gameplay": "",
    "item__VT_WellWornRing__lore": "Este anillo sencillo tiene varios arañazos y marcas visibles en su superficie.",
    "item__VT_WellWornRing__name": "Anillo Muy Usado",
    "item__VT_WhispersOfTheVoid__gameplay": "",
    "item__VT_WhispersOfTheVoid__lore": "La locura es común entre aquellos que escuchan su voz. ¿Y quién no lo hace en estos tiempos de crisis?",
    "item__VT_WhispersOfTheVoid__name": "Susurros del Vacío Vol. 1",
    "item__W_ApprenticesStaff__gameplay": "Coloca una trampa visible cerca de tus pies que inflige daño físico.",
    "item__W_ApprenticesStaff__lore": "Un báculo de mago novato comúnmente utilizado para entrenamiento.",
    "item__W_ApprenticesStaff__name": "Báculo del Aprendiz",
    "item__W_ArchmagesSidekick__gameplay": "Coloca una trampa visible cerca de tus pies que inflige daño arcano.",
    "item__W_ArchmagesSidekick__lore": "Poco se sabe sobre este báculo, excepto que fue transmutado a la existencia por el Archimago de la Orden Arcana.",
    "item__W_ArchmagesSidekick__name": "Compañero del Archimago",
    "item__W_BeesOnAStick__gameplay": "Coloca una trampa visible cerca de tus pies que inflige daño de naturaleza.",
    "item__W_BeesOnAStick__lore": "Un báculo adornado con una colmena, zumbando con el poder de las abejas enjambre, entregando hechizos punzantes a los adversarios.",
    "item__W_BeesOnAStick__name": "Abejas en un Palo",
    "item__W_BlackDahliaWand__gameplay": "Dispara proyectiles de sombras.",
    "item__W_BlackDahliaWand__lore": "Su naturaleza oscura corrompe los corazones de aquellos que se atreven a empuñarlo.",
    "item__W_BlackDahliaWand__name": "Varita Black Dahlia",
    "item__W_BlazeRod__gameplay": "¡Dispara llamas!",
    "item__W_BlazeRod__lore": "Encontrada en las profundidades del Nether, esta varilla está imbuida con el alma de un Blaze.",
    "item__W_BlazeRod__name": "Varilla Blaze",
    "item__W_Bloodrider__gameplay": "Dispara flechas que causan un fuerte daño por sangrado.",
    "item__W_Bloodrider__lore": "Hecho a partir de huesos de guerreros caídos, este arco malévolo drena vida con cada flecha, dejando a su paso un rastro de desesperación carmesí.",
    "item__W_Bloodrider__name": "Bloodrider",
    "item__W_BrokenDeathstick__gameplay": "Dispara proyectiles caóticos que infligen un daño moderado.",
    "item__W_BrokenDeathstick__lore": "Sería mortal... si no estuviera roto.",
    "item__W_BrokenDeathstick__name": "Bastón de la Muerte Roto",
    "item__W_CandlelightStaff__gameplay": "Coloca una trampa visible cerca de tus pies que inflige daño de fuego.",
    "item__W_CandlelightStaff__lore": "Un báculo que emite una suave luz, iluminando la oscuridad.",
    "item__W_CandlelightStaff__name": "Báculo de Luz de Vela",
    "item__W_CelestialBow__gameplay": "Un emisario celestial ha infundido estas flechas con el frío del vacío.",
    "item__W_CelestialBow__lore": "Regalado por antiguas deidades, este arco radiante canaliza bendiciones divinas, atravesando incluso las defensas más formidables.",
    "item__W_CelestialBow__name": "Arco Celestial",
    "item__W_CrudeCrossbow__gameplay": "Dispara un perno básico de baja potencia que inflige daño físico.",
    "item__W_CrudeCrossbow__lore": "Una ballesta rudimentaria hecha de restos de madera y cuerda. No es muy poderosa, pero servirá.",
    "item__W_CrudeCrossbow__name": "Ballesta Rudimentaria",
    "item__W_DarylsInstinct__gameplay": "Dispara poderosos pernos que infligen daño por sangrado.",
    "item__W_DarylsInstinct__lore": "Una ballesta utilizada por el legendario cazador Daryl.",
    "item__W_DarylsInstinct__name": "Instinto de Daryl",
    "item__W_DavidBowie__gameplay": "Dispara proyectiles físicos débiles.",
    "item__W_DavidBowie__lore": "¡Te transforma en una estrella instantánea!",
    "item__W_DavidBowie__name": "David Bowie",
    "item__W_DemonsEye__gameplay": "Coloca una trampa visible cerca de tus pies que inflige daño de sombras.",
    "item__W_DemonsEye__lore": "Este báculo invoca los susurros ominosos del reino del abismo, permitiendo a los portadores atravesar ilusiones y revelar las verdades ocultas que se esconden en las sombras.",
    "item__W_DemonsEye__name": "Ojo del Demonio",
    "item__W_DivineWind__gameplay": "Dispara poderosas flechas sagradas",
    "item__W_DivineWind__lore": "Forjado en santuarios de montañas sagradas, este arco etéreo impulsa flechas con ráfagas de otro mundo, destrozando obstáculos y enemigos.",
    "item__W_DivineWind__name": "Viento Divino",
    "item__W_DragonsBreath__gameplay": "Crea un torrente de llamas frente al conjurador.",
    "item__W_DragonsBreath__lore": "¡Lanzo... ¿Bola de Fuego?",
    "item__W_DragonsBreath__name": "Aliento de Dragón",
    "item__W_EldritchBlasters__gameplay": "Dispara rápidamente proyectiles de sombra.",
    "item__W_EldritchBlasters__lore": "Dos ballestas duales imbuidas con el poder de la sombra.",
    "item__W_EldritchBlasters__name": "Pistolas Eldritch",
    "item__W_Emberstrike__gameplay": "Dispara potentes flechas de fuego.",
    "item__W_Emberstrike__lore": "Forjado en el corazón de un volcán, este arco de obsidiana libera flechas ardientes, dejando tras de sí rastros de devastación humeante.",
    "item__W_Emberstrike__name": "Emberstrike",
    "item__W_FairyBow__gameplay": "Dispara flechas impregnadas de magia de las hadas.",
    "item__W_FairyBow__lore": "Impregna las flechas disparadas desde este arco con energía poderosa aleatoria.",
    "item__W_FairyBow__name": "Arco de Hada",
    "item__W_FairyStaff__gameplay": "Coloca una trampa visible cerca de tus pies que inflige daño de naturaleza.",
    "item__W_FairyStaff__lore": "Perteneció a una reina hada.",
    "item__W_FairyStaff__name": "Báculo de Hada",
    "item__W_FathersBane__gameplay": "Dispara rápidamente pernos débiles con punta venenosa.",
    "item__W_FathersBane__lore": "Utilizado por un hijo dolorido que no tuvo otra opción que matar a su padre.",
    "item__W_FathersBane__name": "Ruina del Padre",
    "item__W_FlamespitterStaff__gameplay": "Coloca una trampa visible cerca de tus pies que inflige daño de fuego.",
    "item__W_FlamespitterStaff__lore": "Furiosas llamas brotan de este báculo.",
    "item__W_FlamespitterStaff__name": "Báculo Escupidor de Llamas",
    "item__W_FrostedTips__gameplay": "Inflige daño de escarcha.",
    "item__W_FrostedTips__lore": "¡Ay, caramba, Stromboli!",
    "item__W_FrostedTips__name": "Puntas Escarchadas",
    "item__W_GuardsCrossbow__gameplay": "Más fácil y rápido de cargar que una ballesta normal.",
    "item__W_GuardsCrossbow__lore": "Ballesta utilizada por los guardias de la Orden Arcana.",
    "item__W_GuardsCrossbow__name": "Ballesta de los Guardias",
    "item__W_HibidyKibity__gameplay": "Dispara mortales proyectiles caóticos.",
    "item__W_HibidyKibity__lore": "Una varita empapada con la energía vil de la maldición asesina.",
    "item__W_HibidyKibity__name": "Hibidy Kibity",
    "item__W_IcewindWand__gameplay": "Crea un torrente de nubes congelantes frente al conjurador.",
    "item__W_IcewindWand__lore": "Capaz de crear el peor clima absoluto.",
    "item__W_IcewindWand__name": "Varita Viento de Hielo",
    "item__W_IronStaff__gameplay": "Coloca una trampa visible cerca de tus pies que inflige daño físico.",
    "item__W_IronStaff__lore": "Un báculo hecho de hierro, utilizado por los guardias de la Orden Arcana.",
    "item__W_IronStaff__name": "Báculo de Hierro",
    "item__W_KingBible__gameplay": "Lanza poderosas biblias sagradas a tus oponentes.",
    "item__W_KingBible__lore": "Un venerado y dorado tomo que contiene palabras sagradas de antiguos monarcas, otorgando autoridad y moldeando destinos.",
    "item__W_KingBible__name": "Biblia del Rey",
    "item__W_Deathstick__gameplay": "Trae la muerte disparando proyectiles rápidos de corto alcance, a costa de ralentizar al usuario.",
    "item__W_Deathstick__lore": "La leyenda cuenta que esta poderosa arma fue dejada caer por una figura misteriosa hace siglos.",
    "item__W_Deathstick__name": "Deathstick",
    "item__W_BookOfTongues__gameplay": "Dispara el fuego más poderoso desde las profundidades del infierno. Tiene un tiempo de reutilización MUY largo.",
    "item__W_BookOfTongues__lore": "Se puede ver un nombre ilegible grabado en sus páginas. Lo único que se distingue es la letra \"M\".",
    "item__W_BookOfTongues__name": "Libro de las Lenguas",
    "item__W_LichsStaff__gameplay": "Coloca una trampa visible cerca de tus pies que inflige daño de sombras.",
    "item__W_LichsStaff__lore": "Empuñado por nigromantes oscuros, este báculo aprovecha los poderes impíos de los muertos vivientes, llevando muerte y decadencia a todo lo que toca.",
    "item__W_LichsStaff__name": "Báculo del Lich",
    "item__W_FrostFlicker__gameplay": "Coloca una trampa visible cerca de tus pies que inflige daño de hielo.",
    "item__W_FrostFlicker__lore": "Un bastón con la habilidad de congelar el agua en el área circundante. Una versión más débil de esto se usa a menudo durante la temporada de vacaciones.",
    "item__W_FrostFlicker__name": "Destello Helado",
    "item__W_MagicalWand__gameplay": "Dispara proyectiles mágicos arcanos.",
    "item__W_MagicalWand__lore": "¡En el nombre de la luna, te castigaré!",
    "item__W_MagicalWand__name": "Varita Mágica",
    "item__W_MidasStaff__gameplay": "Coloca una trampa visible cerca de tus pies que inflige daño de relámpago.",
    "item__W_MidasStaff__lore": "El báculo del legendario Rey Midas.",
    "item__W_MidasStaff__name": "Báculo de Midas",
    "item__W_MoonShadow__gameplay": "Dispara flechas de sombra rápidamente.",
    "item__W_MoonShadow__lore": "Otorgado por los espíritus de la luna, este arco místico dispara flechas que atraviesan las sombras, golpeando desde ángulos inesperados.",
    "item__W_MoonShadow__name": "Sombra Lunar",
    "item__W_NaturesReturn__gameplay": "Aprovecha el poder del bosque para infligir grandes cantidades de daño de naturaleza a tus oponentes.",
    "item__W_NaturesReturn__lore": "Un tomo adornado con follaje, canalizando las fuerzas de la naturaleza para el crecimiento, la curación y las tormentas.",
    "item__W_NaturesReturn__name": "Regreso de la Naturaleza",
    "item__W_NightStick__gameplay": "Dispara proyectiles más fríos que la noche.",
    "item__W_NightStick__lore": "Utilizado por aquellos que tienen la intención de mantener la paz.",
    "item__W_NightStick__name": "Porra Nocturna",
    "item__W_PharaohsStaff__gameplay": "Coloca una trampa visible cerca de tus pies que inflige daño sagrado.",
    "item__W_PharaohsStaff__lore": "Excavado de la tumba de un antiguo faraón, se dice que este báculo se utilizaba para convocar a los muertos.",
    "item__W_PharaohsStaff__name": "Báculo del Faraón",
    "item__W_PhoenixWing__gameplay": "Dispara flechas impregnadas con las llamas de un fénix caído.",
    "item__W_PhoenixWing__lore": "Elaborado con plumas de fénix, este arco radiante canaliza el renacimiento ardiente, encendiendo las flechas con llamas abrasadoras.",
    "item__W_PhoenixWing__name": "Ala de Fénix",
    "item__W_PoseidonsWand__gameplay": "Dispara peces.",
    "item__W_PoseidonsWand__lore": "¡PEEEEEEEEEEEEEEZ!",
    "item__W_PoseidonsWand__name": "Varita de Poseidón",
    "item__W_QuincyBow__gameplay": "Dispara flechas impregnadas de energía eléctrica.",
    "item__W_QuincyBow__lore": "Dispara flechas hechas de la energía espiritual del portador.",
    "item__W_QuincyBow__name": "Arco Quincy",
    "item__W_SilentShadow__gameplay": "Dispara flechas imbuidas con energía de sombra.",
    "item__W_SilentShadow__lore": "\"Vinieron sin previo aviso y nos tomaron por sorpresa. Logramos defendernos, pero perdimos a muchos en el proceso.\"",
    "item__W_SilentShadow__name": "Sombra Silenciosa",
    "item__W_RangersGuillotine__gameplay": "Dispara flechas envenenadas.",
    "item__W_RangersGuillotine__lore": "Con una precisión letal, este brutal arco atraviesa las defensas, incrustando a su víctima con flechas envenenadas.",
    "item__W_RangersGuillotine__name": "Guillotina del Guardabosques",
    "item__W_ReapersEmbrace__gameplay": "Dispara poderosos proyectiles de caos.",
    "item__W_ReapersEmbrace__lore": "Un arco espectral que drena vida con cada flecha, atrapando a los enemigos en el abrazo gélido del Segador.",
    "item__W_ReapersEmbrace__name": "Abrazo del Segador",
    "item__W_RelicWand__gameplay": "Manifiesta proyectiles físicos que infligen un alto daño.",
    "item__W_RelicWand__lore": "Canaliza la esencia de civilizaciones perdidas y magia olvidada.",
    "item__W_RelicWand__name": "Varita de Reliquia",
    "item__W_ReliqueryCrossbow__gameplay": "Dispara pernos de caos de alto daño.",
    "item__W_ReliqueryCrossbow__lore": "Ballesta utilizada por los sacerdotes del Relicario Vampírico.",
    "item__W_ReliqueryCrossbow__name": "Ballesta de Relicario",
    "item__W_SamsStirringStick__gameplay": "Coloca una trampa visible cerca de tus pies que inflige daño arcano.",
    "item__W_SamsStirringStick__lore": "La cuchara de un chef legendario, impregnada con el poder de los elementos (culinarios).",
    "item__W_SamsStirringStick__name": "Cuchara de Agitación de Sam",
    "item__W_SlimeStaff__gameplay": "Coloca una trampa visible cerca de tus pies que inflige daño de naturaleza.",
    "item__W_SlimeStaff__lore": "Un báculo que palpita con la esencia de los limos, permite al portador convocar y controlar a estas criaturas gelatinosas.",
    "item__W_SlimeStaff__name": "Báculo de Limo",
    "item__W_SpellbindersTome__gameplay": "Emite proyectiles arcanos hacia tus oponentes.",
    "item__W_SpellbindersTome__lore": "Un místico libro encuadernado en cuero que atrapa mentes con intrincados hechizos de encantamiento.",
    "item__W_SpellbindersTome__name": "Tomo del Encantador",
    "item__W_SpiraledWand__gameplay": "Dispara ráfagas de energía mágica cinética.",
    "item__W_SpiraledWand__lore": "En realidad, es solo un palo más elegante.",
    "item__W_SpiraledWand__name": "Varita Espiral",
    "item__W_StaffOfTheSnake__gameplay": "Coloca una trampa visible cerca de tus pies que inflige daño tóxico.",
    "item__W_StaffOfTheSnake__lore": "Un báculo adornado con la cabeza de una cobra, esculpida en esmeralda. Se dice que puede escupir veneno a los enemigos.",
    "item__W_StaffOfTheSnake__name": "Báculo de la Serpiente",
    "item__W_StarShot__gameplay": "Dispara potentes flechas arcanas impregnadas de energía estelar.",
    "item__W_StarShot__lore": "Elaborado a partir de fragmentos celestiales, este arco libera una cascada de proyectiles brillantes, un espectáculo celestial de destrucción.",
    "item__W_StarShot__name": "Disparo Estelar",
    "item__W_StarWand__gameplay": "Emite el poder de las estrellas hacia tus oponentes.",
    "item__W_StarWand__lore": "Un instrumento caprichoso y brillante.",
    "item__W_StarWand__name": "Varita Estelar",
    "item__W_SteelCrossbow__gameplay": "Dispara pernos de ballesta que atraviesan incluso las pieles más gruesas.",
    "item__W_SteelCrossbow__lore": "Una ballesta estándar hecha de acero.",
    "item__W_SteelCrossbow__name": "Ballesta de Acero",
    "item__W_Stick__gameplay": "Dispara chispas de energía mágica.",
    "item__W_Stick__lore": "Hay muchos como él, ¡pero este es mío!",
    "item__W_Stick__name": "Palo",
    "item__W_TheCrossBow__gameplay": "Dispara proyectiles sagrados a gran velocidad.",
    "item__W_TheCrossBow__lore": "Administra la justicia divina con esta legendaria ballesta.",
    "item__W_TheCrossBow__name": "La Ballesta Cruzada",
    "item__W_ToxicWand__gameplay": "Dispara un orbe tóxico.",
    "item__W_ToxicWand__lore": "Puede ser adictivo, pero debes saber que es tóxico.",
    "item__W_ToxicWand__name": "Varita Tóxica",
    "item__W_Triforce__gameplay": "Emite el poder de la Trifuerza hacia tus oponentes.",
    "item__W_Triforce__lore": "Un radiante tomo que desbloquea los poderes divinos del coraje, la sabiduría y el poder.",
    "item__W_Triforce__name": "Trifuerza",
    "item__W_TwistedBranch__gameplay": "Coloca una trampa visible cerca de tus pies que inflige daño de relámpago.",
    "item__W_TwistedBranch__lore": "Un bastón rudimentario que ha sido encantado por un difunto mago.",
    "item__W_TwistedBranch__name": "Rama Retorcida",
    "item__W_WitchHuntersRepeaters__gameplay": "Dispara pernos imbuidos de una radiante santidad.",
    "item__W_WitchHuntersRepeaters__lore": "Ballestas comunes utilizadas por los Cazadores de Brujas de la Orden Arcana.",
    "item__W_WitchHuntersRepeaters__name": "Repeticiones del Cazador de Brujas",
    "item__W_WizardsLedger__gameplay": "DISPARA RÁPIDAMENTE monedas. Sí, monedas.",
    "item__W_WizardsLedger__lore": "¿Esto es pagar para ganar?",
    "item__W_WizardsLedger__name": "Libro del Mago",
    "item__W_WyvernsTalon__gameplay": "Dispara potentes pernos que infligen daño por sangrado.",
    "item__W_WyvernsTalon__lore": "Ballesta fabricada a partir de las garras de un gran wyvern.",
    "item__W_WyvernsTalon__name": "Garra del Wyvern",
    "item__W_ZappingWand__gameplay": "Dispara proyectiles de relámpagos.",
    "item__W_ZappingWand__lore": "Aporta una oleada de poder electrizante a tu repertorio mágico.",
    "item__W_ZappingWand__name": "Varita Electrizante",
    "item_container__loot_chest": "Cofre de Botín",
    "item_container__loot_drop": "Caída de Botín",
    "item_rarity__common": "Común",
    "item_rarity__epic": "Épico",
    "item_rarity__legendary": "Legendario",
    "item_rarity__rare": "Raro",
    "item_rarity__uncommon": "Poco común",
    "common": "Común",
    "uncommon": "Poco Común",
    "rare": "Raro",
    "epic": "Épico",
    "legendary": "Legendario",
    "item_slot__damage": "daño",
    "item_slot__equip_prompt": "¡Haz clic izquierdo para equipar!",
    "item_slot__extract_prompt": "¡Extrae para usar este objeto!",
    "item_slot__grants_ability": "Concede una habilidad",
    "item_slot__grants_passive": "Efecto pasivo",
    "item_slot__loot_prompt": "¡Haz clic izquierdo para saquear!",
    "loadout_menu__abilities_header": "Habilidades",
    "loadout_menu__back_to_main_menu_button": "Volver",
    "loadout_menu__damage": "Daño",
    "loadout_menu__defence": "Defensa",
    "loadout_menu__gear_header": "Equipo",
    "loadout_menu__health": "Salud",
    "loadout_menu__insurance_button": "Asegurar",
    "loadout_menu__no_abilities": "Tu carga no contiene habilidades.",
    "loadout_menu__no_slot_selected_insurance_text": "🚨 ¡Nada está asegurado! 🚨",
    "loadout_menu__no_slot_selected_insurance_text_2": "¡Ningún espacio está asegurado! Mira un anuncio para proteger 1 objeto.",
    "loadout_menu__no_slot_selected_scrollbox": "Selecciona un espacio para cambiar tu carga",
    "loadout_menu__scrollbox_with_gear_text": "Elige algo de equipo",
    "loadout_menu__scrollbox_with_skins_text": "Elige una apariencia",
    "loadout_menu__scrollbox_with_weapons_text": "Elige un arma",
    "loadout_menu__skin_header": "Elige una Apariencia",
    "loadout_menu__slot_insured_text": "✅ ¡Tienes seguro! ✅",
    "loadout_menu__start_run_button": "Jugar",
    "loadout_menu__stat": "Estadística",
    "loadout_menu__title": "Carga",
    "loadout_menu__undo": "Deshacer",
    "loadout_menu__weapons_header": "Armas",
    "loadout_menu__will_be_safe": "estará a salvo si mueres.",
    "loadout_menu__your": "Tu",
    "login__header": "Iniciar Sesión",
    "fullscreen": "Pantalla Completa",
    "exit_fullscreen": "Salir de pantalla completa",
    "loot_prompt": "[ E ] para saquear",
    "leaderboard__player_kills": "PK",
    "leaderboard__loot": "Botín",
    "leaderboard__npc_kills": "NPCK",
    "main_menu__patch_notes_btn": "Notas del Parche",
    "main_menu__change_username_btn": "Cambiar Nombre de Usuario",
    "main_menu__create_account_btn": "Crear Cuenta",
    "main_menu__collection_log_btn": "Registro de Colección",
    "collection_log__header": "Registro de Colección",
    "main_menu__discord_btn_tooltip": "¡Encuentra amigos y únete a nosotros en Discord!",
    "main_menu__game_is_in_maintenance_mode": "¡PvPz.io está siendo actualizado! La nueva actualización se implementará pronto, vuelve a intentarlo en unos minutos",
    "main_menu__how_to_play_btn": "Cómo Jugar",
    "main_menu__identity_error_title": "Ups",
    "main_menu__loading_bar_text": "Cargando...",
    "main_menu__logout_btn": "Cerrar Sesión",
    "main_menu__partners": "Socios",
    "main_menu__partners_and_credits": "Todos los colaboradores y créditos",
    "main_menu__patch_prefix": "Parche",
    "main_menu__patch_suffix": "llega el 1 de junio",
    "main_menu__play_btn": "Jugar",
    "main_menu__rankings_btn": "Clasificaciones",
    "main_menu__settings_btn": "Configuración",
    "main_menu__sign_in_btn": "Iniciar Sesión",
    "main_menu__sign_in_with_discord_btn": "Iniciar sesión con Discord",
    "main_menu__sign_in_with_facebook_btn": "Iniciar sesión con Facebook",
    "main_menu__sign_in_with_google_btn": "Iniciar sesión con Google",
    "main_menu__twitter_btn_tooltip": "¡Síguenos en Twitter!",
    "main_menu__vendors_btn": "Vendedores",
    "main_menu__view_inventory_btn": "Inventario",
    "main_menu__view_your_stats_btn": "Tus Estadísticas",
    "main_menu__website_btn_tooltip": "¡Visita nuestro sitio web!",
    "main_menu__welcome_panel_title": "Bienvenido",
    "main_menu__welcome_panel_title_suffix": " de nuevo,",
    "main_menu__welcome_sign_in_cta": "¿Quieres cambiar tu nombre de usuario? ¡Inicia sesión!",
    "main_menu_stash__collectors_items": "Objetos de Colección",
    "main_menu_stash__discovered": "Descubierto",
    "main_menu_stash__gear": "Equipo",
    "main_menu_stash__header": "Almacén",
    "main_menu_stash__total_items_discovered": "Objetos Descubiertos",
    "main_menu_stash__vendor_items": "Objetos de Vendedor",
    "main_menu_stash__weapons": "Armas",
    "player_skins__boltman": "Arquero",
    "player_skins__corrupted_wizard": "Mago Corrupto",
    "player_skins__knight": "Caballero",
    "player_skins__red_wizard": "Aprendiz de Mago",
    "player_skins__thief": "Ladrón",
    "player_skins__veteran_wizard": "Mago de la Escuela del Dragón",
    "rankings__back_to_main_menu_button": "Volver",
    "rankings__coming_soon": "¡Próximamente!",
    "rankings__title": "Clasificaciones",
    "region__dreamers_forest": "Bosque de los Soñadores",
    "region__ert_lich": "Ert Lich",
    "region__erthel": "Erthel",
    "region__forgotten_temple": "Templo Olvidado",
    "region__skull_lake": "Lago del Cráneo",
    "region__swamp_of_sorrows": "Pantano de los Lamentos",
    "region__the_edgelands": "Tierras del Borde",
    "region__wilderness": "Tierras Salvajes",
    "dreamers forest": "Bosque de los Soñadores",
    "ert lich": "Ert Lich",
    "erthel": "Erthel",
    "forgotten temple": "Templo Olvidado",
    "skull lake": "Lago del Cráneo",
    "swamp of sorrows": "Pantano de los Lamentos",
    "edgelands": "Tierras del Borde",
    "wilderness": "Tierras Salvajes",
    "register__delete_account_cta": "¡Eres un usuario anónimo, por lo que tu cuenta se eliminará en 2 semanas! Si deseas conservar tus objetos y estadísticas, y cambiar tu nombre de usuario, ¡regístrate!",
    "register__delete_account_cta_2": "¡Eres un usuario anónimo, por lo que no puedes ver estadísticas! Si deseas ver tus estadísticas, cambiar tu nombre de usuario y desbloquear aspectos especiales, ¡regístrate!",
    "register__delete_account_cta_less_aggressive": "Si deseas guardar tus objetos y estadísticas para siempre y cambiar tu nombre de usuario, ¡regístrate!",
    "register__email": "Ingresa tu dirección de correo electrónico",
    "register__email_confirm": "Confirma tu dirección de correo electrónico",
    "register__email_placeholder": "Dirección de Correo Electrónico",
    "register__email_placeholder_confirm": "Confirmar Dirección de Correo Electrónico",
    "register__header": "Registro",
    "register__or": "O",
    "register__password": "Ingresa una contraseña",
    "register__password_confirm": "Confirma tu contraseña",
    "register__register": "Registrarse",
    "register__register_with_discord_btn": "Registrarse con Discord",
    "register__register_with_google_btn": "Registrarse con Google",
    "register__username": "Elige un nombre de usuario",
    "runprep__skin_not_unlocked_registered": "¡Solo los USUARIOS REGISTRADOS pueden usar ese aspecto!",
    "settings__back_to_main_menu_button": "Volver",
    "settings__enable_music": "Activar Música",
    "settings__enable_sfx": "Activar Efectos de Sonido",
    "settings__language": "Idioma / LANGUAGE",
    "settings__language_dropdown_english": "Inglés / English",
    "settings__language_dropdown_portuguese": "Portugués / Portuguese / Português",
    "settings__language_dropdown_russian": "Ruso / Russian / Русский",
    "settings__language_dropdown_spanish": "Español / Spanish",
    "settings__matchmaking_region": "Región de Matchmaking",
    "settings__matchmaking_region_dropdown_africa": "África",
    "settings__matchmaking_region_dropdown_any": "Cualquier región (la más rápida)",
    "settings__matchmaking_region_dropdown_asia": "Asia",
    "settings__matchmaking_region_dropdown_australia_oceania": "Australia/Oceanía",
    "settings__matchmaking_region_dropdown_eu": "Europa",
    "settings__matchmaking_region_dropdown_middle_east": "Oriente Medio",
    "settings__matchmaking_region_dropdown_na_east": "América del Norte (Este)",
    "settings__matchmaking_region_dropdown_na_west": "América del Norte (Oeste)",
    "settings__matchmaking_region_dropdown_south_america": "Sudamérica",
    "settings__save_button": "Guardar",
    "settings__title": "Configuración",
    "settings__settings_saved": "¡Configuración guardada!",
    "signin_sign_in": "Iniciar sesión",
    "signin_sign_in_with_discord_btn": "Iniciar sesión con Discord",
    "signin_sign_in_with_google_btn": "Iniciar sesión con Google",
    "item_type__weapon": "Arma",
    "item_type__gear": "Equipo",
    "item_type__vendor": "Objeto para Vendedor",
    "item_type__collector": "Objeto Especial",
    "item_type__unknown": "Desconocido",
    "stat_type_arcane": "Arcano",
    "stat_type_bleed": "Sangrado",
    "stat_type_chaos": "Caos",
    "stat_type_fire": "Fuego",
    "stat_type_frost": "Escarcha",
    "stat_type_holy": "Sagrado",
    "stat_type_lightning": "Rayo",
    "stat_type_nature": "Naturaleza",
    "stat_type_physical": "Físico",
    "stat_type_shadow": "Sombra",
    "stat_type_toxic": "Tóxico",
    "stats__back_btn": "Volver",
    "stats__boss_kills": "Muertes de Jefes",
    "stats__common_items_looted": "objetos comunes saqueados",
    "stats__distanced_travelled": "distancia recorrida",
    "stats__epic_items_looted": "objetos épicos saqueados",
    "stats__failed_runs": "intentos fallidos",
    "stats__global_rank": "rango global",
    "stats__gold_looted": "oro saqueado",
    "stats__gold_lost_to_deaths": "oro perdido por muertes",
    "stats__header": "Estadísticas",
    "stats__items_looted": "objetos saqueados",
    "stats__items_lost_to_deaths": "objetos perdidos por muertes",
    "stats__legendary_items_looted": "objetos legendarios saqueados",
    "stats__npc_kills": "Muertes de PNJ",
    "stats__player_kills": "muertes de jugadores",
    "stats__rare_items_looted": "objetos raros saqueados",
    "stats__run_success_percentage": "porcentaje de éxito de la partida",
    "stats__runs": "partidas",
    "stats__successful_runs": "partidas exitosas",
    "stats__time_played": "tiempo jugado",
    "stats__uncommon_items_looted": "objetos poco comunes saqueados",
    "toast__email_doesnt_match": "¡Las direcciones de correo electrónico no coinciden!",
    "toast__email_not_long_enough": "¡La dirección de correo electrónico no es lo suficientemente larga!",
    "toast__gods_granted_you_items": "¡Los dioses te han otorgado un equipo básico!",
    "toast__not_an_email_address": "¡Eso no parece ser una dirección de correo electrónico válida!",
    "toast__password_not_long_enough": "¡La contraseña no es lo suficientemente larga!",
    "toast__passwords_dont_match": "¡Las contraseñas no coinciden!",
    "toast__username_changed": "¡Nombre de usuario cambiado exitosamente!",
    "toast__username_too_short": "¡El nombre de usuario es demasiado corto!",
    "vendors__back_to_main_menu_button": "Volver",
    "vendors__coming_soon": "¡Próximamente!",
    "vendors__title": "Vendedores",
    "event_feed__looted_a": "saqueó un",
    "event_feed__in_the": "en",
    "event_feed__at": "en",
    "event_feed__killed": "mató a",
    "event_feed__boss": "jefe",
    "event_feed__is_extracting": "está extrayendo",
    "event_feed__has_extracted": "¡ha extraído con éxito!",
    "event_feed__has_spawned": "ha aparecido en",
    "event_feed__under_attack": "está siendo atacado en"
}

import { AnimatedSprite, Container, Graphics, Sprite, Texture, Text } from "pixi.js";
import { FacingDirection, NetworkEntityId, UserType, uuid } from "../../shared/SharedTypes";
import { SharedPlayer } from "../../shared/entities/SharedPlayer";
import { playerConfig } from "../../shared/config/Config_Player";
import { PlayerEntityCreationPayload } from "../../shared/SharedNetcodeSchemas";
import { getWeaponData } from "../../shared/data/Data_Weapons";
import { LoadoutSlots } from "../../shared/systems/SharedGearAndWeaponSystem";
import { CharacterSkin, CharacterSkinData } from "../../shared/data/Data_Cosmetics";
import { IRenderedEntity } from "../ClientTypes";
import { UI_ActivateWeaponSlotInHUD, UI_UpdateExtractionProgress, UI_UpdateExtractionTimeRemainingTimer } from "../ui/framework/UI_State";
import { clamp } from "../../shared/SharedUtils";
import { extractionConfig } from "../../shared/config/Config_Extraction";
import { worldConfig } from "../../shared/config/Config_World";
import { Item, PassiveAbility, getItemData } from "../../shared/data/Data_Items";

export class ClientPlayer extends SharedPlayer implements IRenderedEntity {
    private debugNetworkViewSquare: Graphics;
    private debugNetworkViewSquareText: Text;
    private debugPositionText: Text;
    private debugTileGridPositionText: Text;
    private debugHitboxCollider: Graphics;
    private debugMovementCollider: Graphics;
    private debugMovementColliderCenter: Graphics;
    private debugCenterpoint: Graphics;
    private debugAimIndicator: Graphics;
    private debugProjectileOrigin: Graphics;
    private debugRotatingVisualsParent: Container = new Container();

    private clientLastFrameX: number;
    private clientLastFrameY: number;

    private wasDeadOnInit: boolean = false;

    private wasExtractedOnInit: boolean = false;

    public originalCreationPayload: PlayerEntityCreationPayload;
    public isMyEntity: boolean = false;
    public RenderedEntity: Container = new Container();
    private walkFrames: Texture[];
    private idleFrames: Texture[];
    protected characterSprite: AnimatedSprite;
    protected usernameText: Text;
    protected healthText: Text;
    private healthBarContainer: Container = new Container();
    private healthBarBackground: Graphics;
    private healthBarForeground: Graphics;
    private readonly healthBarWidth: number = 25;
    private readonly healthBarHeight: number = 3;
    private readonly healthBarHeightOffset: number = 20;
    private readonly animationSpeed: number = 1 / 9;

    public lastOpacityAmount: number = 0;

    private lastKnownExtractionState: boolean = false;
    private lastKnownLivelihoodState: boolean = true;
    private lastKnownHealthPercentage: number = 1;
    private lastTookDamageTimestamp: number = 0;
    private readonly damageTakenFlashDurationInMilliseconds: number = 250;
    private readonly damageTakenFlashColor: number = 0xff0000;
    private readonly timeBeforeHealthBarFadesInMilliseconds: number = 1500;

    public InteractingWithContainerOfId: uuid | null = null;

    public username: string = "";
    public userType: UserType;
    public characterSkin: CharacterSkin;
    public insuredSlot: LoadoutSlots;
    public facing: FacingDirection;
    public IsDeveloper: boolean = false;
    protected facingLastFrame: FacingDirection;
    private wasMovingLastFrame: boolean;
    private isMovingThisFrame: boolean;

    protected characterSpriteIdleAnimationId: string;
    protected characterSpriteWalkAnimationId: string;
    protected characterSpriteYOffset: number;

    protected weaponOneSprite: Sprite;
    private weaponOneSpriteId: string;
    private weaponOneSpriteScale: number;
    protected weaponTwoSprite: Sprite;
    private weaponTwoSpriteId: string;
    private weaponTwoSpriteScale: number;

    public hasInformationPassiveItem: boolean = false;

    private IsSupporter: boolean = false;
    private IsFounder: boolean = false;

    private lastBroadcastedExtractionInProgressTimer: number = 0;
    private lastBroadcastedTimeExpiryTimer: number = extractionConfig.MAX_RUN_DURATION_IN_SECONDS;

    public constructor(createPlayerPayload: PlayerEntityCreationPayload) {
        super(Game.Collision);

        this.originalCreationPayload = createPlayerPayload;

        // console.log("\n\n creating new local player entity");

        // console.log("original creation payload on client:", this.originalCreationPayload);

        const { nid, x, y, username, aim, debugViewWidth, debugViewHeight, isAlive, currentHealthPercentage, IsDeveloper, weaponOneId, weaponTwoId, gearOneId, gearTwoId, gearThreeId, insuredSlot, userType, activeWeaponSlot, characterSkin, extractionSuccessful, characterOpacityAmount, hasInformationPassive, IsSupporter, IsFounder } = createPlayerPayload;

        this.IsDeveloper = IsDeveloper;

        this.IsSupporter = IsSupporter;
        this.IsFounder = IsFounder;

        // console.log("@@@");
        // console.log(hasInformationPassive);
        this.hasInformationPassiveItem = hasInformationPassive;

        // console.log("full createPlayerPayload", createPlayerPayload);
        // console.warn("@@@ isAlive in createPlayerPayload:", isAlive);

        this.nid = nid;

        this.userType = userType;

        this.characterSkin = characterSkin;

        this.insuredSlot = insuredSlot;

        const justItemsInLoadout = [weaponOneId, weaponTwoId, gearOneId, gearTwoId, gearThreeId];

        this._setupPassiveAbilitiesFromLoadout(justItemsInLoadout);

        const { inGameSpriteId: wepOneSpriteId, inGameSpriteScale: wepOneSpriteScale } = getWeaponData(weaponOneId)!;
        const { inGameSpriteId: wepTwoSpriteId, inGameSpriteScale: wepTwoSpriteScale } = getWeaponData(weaponTwoId)!;

        const characterSkinData = CharacterSkinData.get(characterSkin)!;

        if (this.IsDeveloper) {
            this.characterSpriteIdleAnimationId = "enemies/ANIM_ironFox_idle";
            this.characterSpriteWalkAnimationId = "enemies/ANIM_ironFox_walk";
        } else {
            this.characterSpriteIdleAnimationId = characterSkinData.idleFramesSpriteId;
            this.characterSpriteWalkAnimationId = characterSkinData.walkFramesSpriteId;
        }
        this.characterSpriteYOffset = characterSkinData.spriteYOffset;
        this.weaponOneSpriteId = wepOneSpriteId;
        this.weaponOneSpriteScale = wepOneSpriteScale;
        this.weaponTwoSpriteId = wepTwoSpriteId;
        this.weaponTwoSpriteScale = wepTwoSpriteScale;

        this.x = x;
        this.y = y;
        this.RenderedEntity.x = x;
        this.RenderedEntity.y = y;

        this.aim = aim;

        this.debugViewHeight = debugViewHeight;
        this.debugViewWidth = debugViewWidth;

        // console.warn("@@@ PLAYER ALIVE STATE IN CTOR:", isAlive);
        // console.warn("@@@ PLAYER EXTRACTED STATE IN CTOR", extractionSuccessful);

        this.isAlive = isAlive;
        if (this.isAlive === false) {
            this.wasDeadOnInit = true;
        }
        if (extractionSuccessful === true) {
            this.wasExtractedOnInit = true;
        }
        this.lastKnownExtractionState = extractionSuccessful;
        this.lastKnownLivelihoodState = isAlive;
        this.currentHealthPercentage = currentHealthPercentage;
        this.lastKnownHealthPercentage = currentHealthPercentage;

        this.username = username;

        this._setupCharacterVisuals(activeWeaponSlot);
        this._setupDebugVisuals();

        this.RenderedEntity.addChild(this.debugRotatingVisualsParent);

        // Hide dead entities when they are first created (as this is indicative of them already being in thet process of being destroyed, no point in showing them on this client)
        if (this.wasDeadOnInit === true) {
            this._die(false);
        } else if (this.wasExtractedOnInit === true) {
            this._extract(false);
        } else {
            this.RenderedEntity.alpha = characterOpacityAmount;
        }
    }

    private _setupPassiveAbilitiesFromLoadout(equippedItems: Item[]): void {
        // console.log("\n\n---");

        // console.log("equipped items..", equippedItems);

        // console.log("PLAYERS PASSIVE ABILITIES BEFORE (CLIENT):", JSON.stringify(this.passiveAbilityFlags));

        for (let i = 0; i < equippedItems.length; i++) {
            const item = equippedItems[i];
            if (item === -1) {
                // console.log(`Not applying passive buffs for item ${Item[equippedItems[i]]} in _applyLoadoutToPassiveAbilityFlags because it is -1 (nothing in that slot)!`);
                continue;
            }

            const itemConfig = getItemData(item);

            const { passiveAbilities } = itemConfig;

            if (passiveAbilities.length === 0) {
                // console.log("Not applying passive buffs for item in _applyLoadoutToPassiveAbilityFlags because it has no passive abilities!");
                continue;
            }

            for (let j = 0; j < passiveAbilities.length; j++) {
                // console.log("Applying passive ability:", PassiveAbility[passiveAbilities[j].passiveAbility], ` because item ${itemConfig.name} has it configured!`);
                this.passiveAbilityFlags[passiveAbilities[j].passiveAbility] = true;

                switch (passiveAbilities[j].passiveAbility) {
                    case PassiveAbility.MoveSpeedUp:
                        super._increaseMoveSpeed(passiveAbilities[j].abilityParameter);
                        break;
                    case PassiveAbility.MoveSpeedDown:
                        super._decreaseMoveSpeed(passiveAbilities[j].abilityParameter);
                        break;
                    case PassiveAbility.ProjectileDistanceUp:
                        super._increaseProjectileDistance(passiveAbilities[j].abilityParameter);
                        break;
                    case PassiveAbility.ProjectileDistanceDown:
                        super._decreaseProjectileDistance(passiveAbilities[j].abilityParameter);
                        break;
                    case PassiveAbility.AttackSpeedUp:
                        super._increaseAttackSpeedModifier(passiveAbilities[j].abilityParameter);
                        break;
                    case PassiveAbility.AttackSpeedDown:
                        super._decreaseAttackSpeedModifier(passiveAbilities[j].abilityParameter);
                        break;
                    case PassiveAbility.Ethereal:
                        super._makePlayerEthereal(passiveAbilities[j].abilityParameter);
                        break;
                    case PassiveAbility.WaterWalking:
                        super._increaseWaterMoveSpeed(passiveAbilities[j].abilityParameter);
                        break;
                    default:
                        break;
                }
            }
        }

        // console.log("PLAYERS PASSIVE ABILITIES AFTER (CLIENT):", JSON.stringify(this.passiveAbilityFlags));
    }

    public IdentifyAsMyPlayer(): void {
        this.isMyEntity = true;

        if (playerConfig.DEBUG.DRAW_NETWORK_VIEW) {
            this.debugNetworkViewSquare = new Graphics();
            this.debugNetworkViewSquare.lineStyle(2, 0xff0000, 1);
            this.debugNetworkViewSquare.drawRect(0 - this.debugViewWidth / 2, 0 - this.debugViewHeight / 2, this.debugViewWidth, this.debugViewHeight);
            this.RenderedEntity.addChild(this.debugNetworkViewSquare);

            this.debugNetworkViewSquareText = new Text(`Player view dimensions: ${this.debugViewWidth}x${this.debugViewHeight}`, {
                fontFamily: "alagardmedium",
                fontSize: 64,
                fill: 0x000000
            });
            this.debugNetworkViewSquareText.scale.set(0.1);
            this.debugNetworkViewSquareText.x = -this.debugViewWidth / 2 + 5;
            this.debugNetworkViewSquareText.y = -this.debugViewHeight / 2 + 5;
            this.RenderedEntity.addChild(this.debugNetworkViewSquareText);

            Game.ListenForEvent("Resizer::GameWindowResizeComplete", ({ newScaledWidth, newScaledHeight }: { newScaledWidth: number; newScaledHeight: number }) => {
                this.debugNetworkViewSquareText.text = `Player view dimensions: ${this.debugViewWidth}x${this.debugViewHeight}`;
                this.debugNetworkViewSquareText.x = -this.debugViewWidth / 2 + 5;
                this.debugNetworkViewSquareText.y = -this.debugViewHeight / 2 + 5;
                this.debugNetworkViewSquare.clear();
                this.debugNetworkViewSquare.lineStyle(2, 0xff0000, 1);
                this.debugNetworkViewSquare.drawRect(0 - newScaledWidth / 2, 0 - newScaledHeight / 2, newScaledWidth, newScaledHeight);
            });
        }

        // this.usernameText.alpha = 0;

        if (playerConfig.ENABLE_PREDICTION) {
            if (playerConfig.DEBUG.DRAW_SERVERSIDE_SELF_DURING_PREDICTION === false) {
                this._hideVisuals();
            }
        }

        // Remove our own entity from collision system
        Game.Collision.RemoveEntity(this);
    }

    public override set activeWeaponSlot(newSlot: LoadoutSlots.WeaponOne | LoadoutSlots.WeaponTwo) {
        if (newSlot === LoadoutSlots.WeaponOne) {
            this.weaponOneSprite.alpha = 1;
            this.weaponTwoSprite.alpha = 0;
            if (this.isMyEntity) {
                UI_ActivateWeaponSlotInHUD(LoadoutSlots.WeaponOne);
            }
        } else if (newSlot === LoadoutSlots.WeaponTwo) {
            this.weaponOneSprite.alpha = 0;
            this.weaponTwoSprite.alpha = 1;
            if (this.isMyEntity) {
                UI_ActivateWeaponSlotInHUD(LoadoutSlots.WeaponTwo);
            }
        } else {
            throw new Error("Invalid weapon slot received by client");
        }

        if (this.IsDeveloper) {
            this.weaponOneSprite.alpha = 0;
            this.weaponTwoSprite.alpha = 0;
        }
    }

    private _die(playDeathAnimation: boolean = false): void {
        console.log("Player died!");
        if (playDeathAnimation && this.isMyEntity === false) {
            Game.Renderer.PlayOneOffPfx(this.x, this.y + 2, "pfx/death/effect_death", 1, 1, 1);
        }
        this.lastKnownLivelihoodState = false;
        this._hideVisuals();
    }

    private _extract(playExtractionAnimation: boolean = false): void {
        console.log("Player extracted!");
        if (playExtractionAnimation && this.isMyEntity === false) {
            Game.Renderer.PlayOneOffPfx(this.x, this.y - 15, "pfx/teleport/effect_teleport", 1.5, 1, 1);
        }
        this._hideVisuals();
        this.lastKnownExtractionState = true;
    }

    private _setupCharacterVisuals(initialActiveWeaponSlot: LoadoutSlots.WeaponOne | LoadoutSlots.WeaponTwo): void {
        //#region Player Character
        /****************************************/
        /*                                      */
        /*             Character                */
        /*                                      */
        /****************************************/

        const idleAnimation = Game.Loader.GetAnimation(this.characterSpriteIdleAnimationId);
        const movingAnimation = Game.Loader.GetAnimation(this.characterSpriteWalkAnimationId);

        this.idleFrames = new Array<Texture>();
        for (let i = 0; i < idleAnimation.length; i++) {
            this.idleFrames.push(Texture.from(idleAnimation[i]));
        }

        this.walkFrames = new Array<Texture>();
        for (let i = 0; i < movingAnimation.length; i++) {
            this.walkFrames.push(Texture.from(movingAnimation[i]));
        }
        this.characterSprite = new AnimatedSprite(this.idleFrames);
        this.characterSprite.position.y = this.characterSpriteYOffset;
        this.characterSprite.scale.set(1);
        this.characterSprite.anchor.set(0.5, 0.5);
        this.characterSprite.animationSpeed = this.animationSpeed;
        this.characterSprite.play();
        if (playerConfig.DEBUG.HIDE_PLAYER_SPRITE) {
            this.characterSprite.alpha = 0;
        }
        this.RenderedEntity.addChild(this.characterSprite);
        //#endregion

        //#region Weapons
        /****************************************/
        /*                                      */
        /*              Weapons                 */
        /*                                      */
        /****************************************/

        /*******************/
        /*     Weapon  1   */
        /*******************/
        this.weaponOneSprite = Sprite.from(Game.Loader.GetSpritesheetTexture(this.weaponOneSpriteId));
        this.weaponOneSprite.scale.set(this.weaponOneSpriteScale);
        this.weaponOneSprite.anchor.set(0.25, 0.5);
        this.weaponOneSprite.position.set(0, 0);

        if (playerConfig.DEBUG.HIDE_WEAPON_SPRITE || this.IsDeveloper) {
            this.weaponOneSprite.alpha = 0;
        }

        this.RenderedEntity.addChild(this.weaponOneSprite);

        /*******************/
        /*     Weapon  2   */
        /*******************/
        this.weaponTwoSprite = Sprite.from(Game.Loader.GetSpritesheetTexture(this.weaponTwoSpriteId));
        this.weaponTwoSprite.scale.set(this.weaponTwoSpriteScale);
        this.weaponTwoSprite.anchor.set(0.25, 0.5);
        this.weaponTwoSprite.position.set(0, 0);

        if (playerConfig.DEBUG.HIDE_WEAPON_SPRITE || this.IsDeveloper) {
            this.weaponTwoSprite.alpha = 0;
        }

        this.RenderedEntity.addChild(this.weaponTwoSprite);

        if (initialActiveWeaponSlot === LoadoutSlots.WeaponOne) {
            this.weaponTwoSprite.alpha = 0;
        } else if (initialActiveWeaponSlot === LoadoutSlots.WeaponTwo) {
            this.weaponOneSprite.alpha = 0;
        } else {
            throw new Error(`Invalid initialActiveWeaponSlot in ClientPlayerBase _setupCharacterVisuals: ${initialActiveWeaponSlot}`);
        }
        //#endregion

        //#region Username
        /****************************************/
        /*                                      */
        /*              Username                */
        /*                                      */
        /****************************************/

        console.warn(this.IsSupporter);
        console.warn(this.IsFounder);

        const usernameColor = this.IsFounder ? 0x551a8b : this.IsSupporter ? 0x0000ff : 0xffffff;
        this.usernameText = new Text(this.username, {
            fontFamily: "alagardmedium",
            fontSize: this.IsDeveloper ? 112 : 56,
            fill: usernameColor
        });
        this.usernameText.scale.set(0.15);
        this.usernameText.anchor.set(0.5, 0.5);

        if (this.IsDeveloper) {
            this.usernameText.y -= 40;
        } else {
            this.usernameText.y -= 20;
        }
        this.RenderedEntity.addChild(this.usernameText);
        //#endregion

        //#region Health Text
        /****************************************/
        /*                                      */
        /*              Health Text             */
        /*                                      */
        /****************************************/
        if (Game.Renderer.MyLocalEntityHasInformationPassive) {
            this.healthText = new Text(`${Math.ceil(this.currentHealthPercentage * 100)}/100`, {
                fontFamily: "alagardmedium",
                fontSize: 56,
                fill: 0xffffff
            });
            this.healthText.scale.set(0.15);
            this.healthText.anchor.set(0.5, 0.5);
            this.healthText.y += 27;
            this.RenderedEntity.addChild(this.healthText);
        }
        //#endregion

        //#region Health Bar
        /****************************************/
        /*                                      */
        /*              Healthbar               */
        /*                                      */
        /****************************************/
        // Create the healthBarBackground rectangle
        this.healthBarBackground = new Graphics();
        this.healthBarBackground.beginFill(0x000000);
        this.healthBarBackground.drawRect(0 - this.healthBarWidth / 2, this.healthBarHeightOffset, this.healthBarWidth, this.healthBarHeight);
        this.healthBarBackground.endFill();
        this.healthBarContainer.addChild(this.healthBarBackground);

        // Create the foreground rectangle
        this.healthBarForeground = new Graphics();
        this.healthBarForeground.beginFill(0xff0000);
        this.healthBarForeground.drawRect(0 - this.healthBarWidth / 2, this.healthBarHeightOffset, this.healthBarWidth, this.healthBarHeight);
        this.healthBarForeground.endFill();
        this.healthBarContainer.addChild(this.healthBarForeground);

        // Position the healthBarForeground rectangle relative to the healthBarBackground rectangle
        this.healthBarForeground.position.x = this.healthBarBackground.position.x;
        this.healthBarForeground.position.y = this.healthBarBackground.position.y;

        // Set the mask for the healthBarForeground rectangle to be a rectangle with the same dimensions as the background rectangle
        this.healthBarForeground.mask = new Graphics();
        (this.healthBarForeground.mask as Graphics).beginFill(0xffffff);
        (this.healthBarForeground.mask as Graphics).drawRect(0 - this.healthBarWidth / 2, this.healthBarHeightOffset, this.healthBarWidth, this.healthBarHeight);
        (this.healthBarForeground.mask as Graphics).endFill();
        this.healthBarContainer.addChild(this.healthBarForeground.mask);

        if (Game.Renderer.MyLocalEntityHasInformationPassive === false) {
            this.healthBarContainer.alpha = 0;
        }

        this.RenderedEntity.addChild(this.healthBarContainer);
        //#endregion
    }

    private _setupDebugVisuals(): void {
        if (playerConfig.DEBUG.DRAW_AIM_INDICATOR) {
            const aimIndicatorLength = playerConfig.MOVEMENT_COLLIDER_RADIUS + (playerConfig.DEBUG.AIM_INDICATOR_SHOULD_BE_LONG_AF ? 10000 : 17);
            this.debugAimIndicator = new Graphics();
            this.debugAimIndicator.lineStyle(2, 0x00ff00);
            this.debugAimIndicator.moveTo(0, 0);
            this.debugAimIndicator.lineTo(aimIndicatorLength, 0);
            this.debugRotatingVisualsParent.addChild(this.debugAimIndicator);
        }

        if (playerConfig.DEBUG.DRAW_HITBOX_COLLIDER) {
            this.debugHitboxCollider = new Graphics();
            // this.debugHitboxCollider.beginFill(0x000000);
            this.debugHitboxCollider.lineStyle(1, 0x000000);
            this.debugHitboxCollider.drawRect(0 - playerConfig.HITBOX_COLLIDER_WIDTH / 2, 0 - playerConfig.HITBOX_COLLIDER_HEIGHT / 2, playerConfig.HITBOX_COLLIDER_WIDTH, playerConfig.HITBOX_COLLIDER_HEIGHT);
            this.debugHitboxCollider.endFill();
            this.RenderedEntity.addChild(this.debugHitboxCollider);
        }

        if (playerConfig.DEBUG.DRAW_MOVEMENT_COLLIDER) {
            this.debugMovementCollider = new Graphics();
            this.debugMovementCollider.lineStyle(1, 0x0000ff);
            this.debugMovementCollider.drawCircle(0, playerConfig.MOVEMENT_COLLIDER_Y_OFFSET, playerConfig.MOVEMENT_COLLIDER_RADIUS);
            this.debugMovementCollider.endFill();
            this.debugMovementColliderCenter = new Graphics();
            this.debugMovementColliderCenter.lineStyle(1, 0xffffff);
            this.debugMovementColliderCenter.drawCircle(0, playerConfig.MOVEMENT_COLLIDER_Y_OFFSET, 1);
            this.debugMovementColliderCenter.endFill();
            this.RenderedEntity.addChild(this.debugMovementCollider);
            this.RenderedEntity.addChild(this.debugMovementColliderCenter);
        }

        if (playerConfig.DEBUG.DRAW_CENTERPOINT) {
            this.debugCenterpoint = new Graphics();
            this.debugCenterpoint.beginFill(0xffffff);
            this.debugCenterpoint.drawCircle(0, 0, 2);
            this.debugCenterpoint.endFill();
            this.debugRotatingVisualsParent.addChild(this.debugCenterpoint);
        }

        if (playerConfig.DEBUG.DRAW_PROJECTILE_ORIGIN_INDICATOR) {
            this.debugProjectileOrigin = new Graphics();
            this.debugProjectileOrigin.beginFill(0x000000);
            this.debugProjectileOrigin.drawCircle(18, 0, 3);
            this.debugProjectileOrigin.endFill();
            this.debugRotatingVisualsParent.addChild(this.debugProjectileOrigin);
        }

        if (playerConfig.DEBUG.DRAW_POSITION_TEXT) {
            this.debugPositionText = new Text(`${Math.floor(this.x)}x, ${Math.floor(this.y)}y`, {
                fontFamily: "alagardmedium",
                fontSize: 64,
                fill: 0xffffff
            });
            this.debugPositionText.scale.set(0.1);
            this.debugPositionText.anchor.set(0.5, 0.5);
            this.debugPositionText.y += 30;
            this.RenderedEntity.addChild(this.debugPositionText);
        }

        if (playerConfig.DEBUG.DRAW_TILE_GRID_TEXT) {
            const currentTileGridX = Math.floor(this.MovementCollider.pos.x / worldConfig.TILE_SIZE);
            const currentTileGridY = Math.floor(this.MovementCollider.pos.y / worldConfig.TILE_SIZE);
            this.debugTileGridPositionText = new Text(`${currentTileGridX}x, ${currentTileGridY}y`, {
                fontFamily: "alagardmedium",
                fontSize: 64,
                fill: 0xffffff
            });
            this.debugTileGridPositionText.scale.set(0.1);
            this.debugTileGridPositionText.anchor.set(0.5, 0.5);
            this.debugTileGridPositionText.y += 50;
            this.RenderedEntity.addChild(this.debugTileGridPositionText);
        }
    }

    protected _showVisuals(): void {
        this.RenderedEntity.alpha = 1;
    }

    protected _fadeVisuals(): void {
        this.RenderedEntity.alpha = 1;
    }

    protected _hideVisuals(): void {
        this.RenderedEntity.alpha = 0;
    }

    // TODO make damage flashes better, likely via the pixi-heaven plugin
    private _triggerDamageFlash(): void {
        this.characterSprite.tint = this.damageTakenFlashColor;
    }

    private _undoDamageFlash(): void {
        this.characterSprite.tint = 0xffffff;
    }

    protected _updateCharacterVisuals(deltaTime: number, currentTimeInMilliseconds: number): void {
        if (this.currentHealthPercentage !== this.lastKnownHealthPercentage && this.currentHealthPercentage < this.lastKnownHealthPercentage) {
            this.healthBarContainer.alpha = 1;

            this._triggerDamageFlash();

            this.lastTookDamageTimestamp = currentTimeInMilliseconds;
            this.lastKnownHealthPercentage = this.currentHealthPercentage;

            if (Game.Renderer.MyLocalEntityHasInformationPassive) {
                if (this.healthText !== undefined) {
                    this.healthText.text = `${Math.ceil(this.currentHealthPercentage * 100)}/100`;
                }
            }
        }

        const timeSinceLastTookDamage = currentTimeInMilliseconds - this.lastTookDamageTimestamp;

        if (timeSinceLastTookDamage > this.timeBeforeHealthBarFadesInMilliseconds && this.currentHealthPercentage >= 98 && Game.Renderer.MyLocalEntityHasInformationPassive === false) {
            this.healthBarContainer.alpha -= deltaTime;
        }

        if (timeSinceLastTookDamage > this.damageTakenFlashDurationInMilliseconds) {
            this._undoDamageFlash();
        }

        if (this.x !== this.clientLastFrameX || this.y !== this.clientLastFrameY) {
            this.isMovingThisFrame = true;
        } else {
            this.isMovingThisFrame = false;
        }

        this.RenderedEntity.x = this.x;
        this.RenderedEntity.y = this.y;
        this.RenderedEntity.zIndex = this.y + 10;

        if (this.wasMovingLastFrame === true && this.isMovingThisFrame === false) {
            this.characterSprite.textures = this.idleFrames;
            this.characterSprite.play();
        }

        if (this.wasMovingLastFrame === false && this.isMovingThisFrame === true) {
            this.characterSprite.textures = this.walkFrames;
            this.characterSprite.play();
        }

        if (this.healthBarForeground !== undefined) {
            (this.healthBarForeground.mask as Graphics).width = this.currentHealthPercentage * this.healthBarWidth;
        }

        this.clientLastFrameX = this.x;
        this.clientLastFrameY = this.y;
        this.wasMovingLastFrame = this.isMovingThisFrame;

        this._updateWeaponAim();
    }

    protected _updateDebugVisuals(__deltaTime: number) {
        this.debugRotatingVisualsParent.rotation = this.aim;
        if (playerConfig.DEBUG.DRAW_POSITION_TEXT) {
            this.debugPositionText.text = `${Math.floor(this.x)}x, ${Math.floor(this.y)}y`;
        }
        if (playerConfig.DEBUG.DRAW_TILE_GRID_TEXT) {
            const currentTileGridX = Math.floor(this.MovementCollider.pos.x / worldConfig.TILE_SIZE);
            const currentTileGridY = Math.floor(this.MovementCollider.pos.y / worldConfig.TILE_SIZE);
            this.debugTileGridPositionText.text = `${currentTileGridX}x, ${currentTileGridY}y`;
        }
    }

    protected _updateWeaponAim() {
        const facing = this.aim > -Math.PI / 2 && this.aim < Math.PI / 2 ? FacingDirection.Right : FacingDirection.Left;

        this.weaponOneSprite.rotation = facing === FacingDirection.Left ? this.aim + Math.PI : this.aim;

        this.weaponTwoSprite.rotation = facing === FacingDirection.Left ? this.aim + Math.PI : this.aim;

        if (facing === this.facingLastFrame) return;

        if (facing === FacingDirection.Left) {
            this.characterSprite.scale.x = -1;
            this.weaponOneSprite.scale.x = -1;
            this.weaponTwoSprite.scale.x = -1;
        } else {
            this.characterSprite.scale.x = 1;
            this.weaponOneSprite.scale.x = 1;
            this.weaponTwoSprite.scale.x = 1;
        }

        this.facingLastFrame = facing;
    }

    // public override set weaponOneCooldownProgressPercentage(__newPercentage: number) {
    //     if (this.isMyEntity) {
    //         console.log("Weapon one cooldown progress %:", __newPercentage);
    //     }
    // }

    // public override set weaponTwoCooldownProgressPercentage(__newPercentage: number) {
    //     if (this.isMyEntity) {
    //         console.log("Weapon two cooldown progress %:", __newPercentage);
    //     }
    // }

    // public override set gearOneCooldownProgressPercentage(__newPercentage: number) {
    //     if (this.isMyEntity) {
    //         console.log("Gear one cooldown progress %:", __newPercentage);
    //     }
    // }

    // public override set gearTwoCooldownProgressPercentage(__newPercentage: number) {
    //     if (this.isMyEntity) {
    //         console.log("Gear two cooldown progress %:", __newPercentage);
    //     }
    // }

    // public override set gearThreeCooldownProgressPercentage(__newPercentage: number) {
    //     if (this.isMyEntity) {
    //         console.log("Gear three cooldown progress %:", __newPercentage);
    //     }
    // }

    private _emitUIEvents(): void {
        const extractionInProgressTimerAsPercentage = clamp(Math.ceil((this.extractionInProgressAccumulator / extractionConfig.TIME_TO_EXTRACT_IN_SECONDS) * 100), 0, 100);

        if (this.lastBroadcastedExtractionInProgressTimer === 0 && extractionInProgressTimerAsPercentage !== 0 && extractionInProgressTimerAsPercentage !== 100) {
            UI_UpdateExtractionProgress(extractionInProgressTimerAsPercentage);
            this.lastBroadcastedExtractionInProgressTimer = extractionInProgressTimerAsPercentage;
        } else if (this.lastBroadcastedExtractionInProgressTimer < 100 && extractionInProgressTimerAsPercentage === 100) {
            UI_UpdateExtractionProgress(extractionInProgressTimerAsPercentage);
            this.lastBroadcastedExtractionInProgressTimer = extractionInProgressTimerAsPercentage;
        } else if (this.lastBroadcastedExtractionInProgressTimer !== extractionInProgressTimerAsPercentage) {
            UI_UpdateExtractionProgress(extractionInProgressTimerAsPercentage);
            this.lastBroadcastedExtractionInProgressTimer = extractionInProgressTimerAsPercentage;
        }

        if (this.lastBroadcastedTimeExpiryTimer === extractionConfig.MAX_RUN_DURATION_IN_SECONDS && this.timeRemainingToExtractInSeconds !== extractionConfig.MAX_RUN_DURATION_IN_SECONDS) {
            UI_UpdateExtractionTimeRemainingTimer(this.timeRemainingToExtractInSeconds);
            this.lastBroadcastedTimeExpiryTimer = this.timeRemainingToExtractInSeconds;
        } else if (this.lastBroadcastedTimeExpiryTimer > 0 && this.timeRemainingToExtractInSeconds === 0) {
            UI_UpdateExtractionTimeRemainingTimer(this.timeRemainingToExtractInSeconds);
            this.lastBroadcastedTimeExpiryTimer = this.timeRemainingToExtractInSeconds;
        } else if (this.lastBroadcastedTimeExpiryTimer !== this.timeRemainingToExtractInSeconds) {
            UI_UpdateExtractionTimeRemainingTimer(this.timeRemainingToExtractInSeconds);
            this.lastBroadcastedTimeExpiryTimer = this.timeRemainingToExtractInSeconds;
        }
    }

    public override Update(deltaTime: number, currentTimeInMilliseconds: number): void {
        // Dont bother updating for entities that were already dead or extracted at the time of creation
        if (this.wasDeadOnInit === true || this.wasExtractedOnInit === true) {
            return;
        }

        if (this.extractionSuccessful === true && this.lastKnownExtractionState === false) {
            this._extract(true);
            return;
        }

        if (this.isAlive === false && this.lastKnownLivelihoodState === true) {
            this._die(true);
            return;
        }

        if (this.isMyEntity === false && this.wasDeadOnInit === false && this.wasExtractedOnInit === false) {
            if (this.lastOpacityAmount !== this.characterOpacityAmount) {
                this.RenderedEntity.alpha = this.characterOpacityAmount;
                this.lastOpacityAmount = this.characterOpacityAmount;

                if (this.characterOpacityAmount === 0) {
                    // console.log("@@@!");
                    Game.Renderer.PlayOneOffPfx(this.x, this.y, "pfx/invis/effect_goInvisible", 1.5, 1, 1);
                }
            }
        }

        super.Update(deltaTime);

        if (this.isMyEntity) {
            this._emitUIEvents();
        }

        this._updateCharacterVisuals(deltaTime, currentTimeInMilliseconds);
        this._updateDebugVisuals(deltaTime);
    }
}

import { loggingConfig } from "../config/Config_Logging";
import { getClassName } from "../SharedUtils";
import { LogLevel, padCenter } from "./SharedLogging";

export class GameplaySystemLogger {
    private level: LogLevel;

    public constructor(level: LogLevel = LogLevel.INFO) {
        this.level = level;
    }

    protected setLogLevel(newLogLevel: LogLevel) {
        this.level = newLogLevel;
    }

    protected getSystemName(): string {
        return getClassName(this);
    }

    protected log(level: LogLevel, message: string): void {
        if (level >= this.level) {
            let logFn;

            const frameNumberSegment = "[" + padCenter(globalThis.currentFrameNumber.toString(), 7) + "]";

            const systemSegment = "[" + padCenter(this.getSystemName(), 13) + "]";

            const logLevelSegment = "[" + padCenter(LogLevel[level], 9) + "]";

            const fullString = `%c ${frameNumberSegment} ${logLevelSegment} ${systemSegment} ${message}`;

            if (level === LogLevel.ERROR) {
                logFn = console.error.bind(console, fullString, "color: red");
            } else if (level === LogLevel.WARNING) {
                logFn = console.warn.bind(console, fullString, "color: orange");
            } else if (level === LogLevel.VERBOSE) {
                logFn = console.debug.bind(console, fullString, "color: white");
            } else {
                logFn = console.log.bind(console, fullString, "color: green");
            }

            logFn();
        }
    }

    public ConditionallyLogTickMsg(msg: string, level: LogLevel = LogLevel.INFO): void {
        if (loggingConfig.DEBUG.LOG_TICK_METADATA_EVERY_SERVER_FRAME === true) {
            this.log(level, msg);
        }
    }

    public LogVerbose(message: string): void {
        this.log(LogLevel.VERBOSE, message);
    }

    public LogInfo(message: string): void {
        this.log(LogLevel.INFO, message);
    }

    public LogWarning(message: string): void {
        this.log(LogLevel.WARNING, message);
    }

    public LogError(message: string): void {
        this.log(LogLevel.ERROR, message);
    }
}

import { NType, ProjectileEntityCreationPayload } from "../../shared/SharedNetcodeSchemas";
import { randomizeAngleInRadiansByPercentage } from "../../shared/SharedUtils";
import { ActiveAbility, ActiveAbilityDefinition, Item } from "../../shared/data/Data_Items";
import { EntityProjectileConfig } from "../../shared/data/Data_Projectiles";
import { WeaponFiringType } from "../../shared/data/Data_Weapons";
import { SharedPlayer } from "../../shared/entities/SharedPlayer";
import { LoadoutSlots, SharedGearAndWeaponSystem } from "../../shared/systems/SharedGearAndWeaponSystem";
import { ClientPlayer } from "../entities/ClientPlayer";
import { ClientPlayerPredicted } from "../entities/ClientPlayerPredicted";
import { ClientProjectilePredicted } from "../entities/ClientProjectilePredicted";

export class ClientGearAndWeaponSystem extends SharedGearAndWeaponSystem {
    private predictedProjectiles: Set<ClientProjectilePredicted> = new Set();

    public override SwapToWeaponSlot(newSlot: LoadoutSlots.WeaponOne | LoadoutSlots.WeaponTwo): void {
        super.SwapToWeaponSlot(newSlot);
        const newWeaponClass = this.GetActiveWeaponConfig().weaponClass;
        Game.EmitEvent("Gameplay::NewWeaponClassEquipped", { newWeaponClass });
    }

    public override SetWeaponDetails(weaponOne: Item, weaponTwo: Item): void {
        super.SetWeaponDetails(weaponOne, weaponTwo);
        const newWeaponClass = this.GetActiveWeaponConfig().weaponClass;
        Game.EmitEvent("Gameplay::NewWeaponClassEquipped", { newWeaponClass });
    }

    public override UseWeapon(): void {
        if ((this.GetOwningEntity() as ClientPlayer).IsDeveloper) {
            return;
        }

        super.UseWeapon();

        const { firingType: type, damage, damageType, projectileConfig, randomizeProjectileAimAngle } = this.GetActiveWeaponConfig();
        const { id } = projectileConfig as EntityProjectileConfig;

        if (type === WeaponFiringType.Projectile) {
            const owningEntity = this.GetOwningEntity() as ClientPlayer;
            const owningEntityNid = this.GetOwningEntityNid();
            let aim = owningEntity.aim;

            if (randomizeProjectileAimAngle) {
                aim = randomizeAngleInRadiansByPercentage(aim, 0.2);
            }

            const projectileCreationPayload: ProjectileEntityCreationPayload = {
                nid: -1,
                ntype: NType.ProjectileEntity,
                x: owningEntity.projectileOrigin.x,
                y: owningEntity.projectileOrigin.y,
                damage: damage,
                damageType: damageType,
                spawnTrajectoryInRads: aim,
                owningEntityNid: owningEntityNid,
                projectileType: 0,
                dataId: id
            };

            const distanceMuliplier = (this.GetOwningEntity() as SharedPlayer).projectileDistanceMultiplier;

            // console.log("distance multiplier in client weapon system:", distanceMuliplier);

            const predictedProjectile = new ClientProjectilePredicted(projectileCreationPayload, distanceMuliplier);
            this.predictedProjectiles.add(predictedProjectile);
            Game.Renderer.AddRenderedEntity(predictedProjectile);
            Game.Collision.AddEntity(predictedProjectile);
        } else if (type === WeaponFiringType.Hitscan) {
            // TODO hitscan weapon projectile type
        }
    }

    public override UseGear(gearSlot: LoadoutSlots): void {
        super.UseGear(gearSlot);

        console.log("USING GEAR SLOT ON CLIENT: ", LoadoutSlots[gearSlot]);
        let ability: ActiveAbility = ActiveAbility.None;
        let abilityConfig: ActiveAbilityDefinition = {
            ability: ActiveAbility.None,
            abilityCharges: 0,
            abilityCooldownInSeconds: 0,
            abilityDurationInSeconds: 0,
            abilityParameter: 0
        };

        if (gearSlot === LoadoutSlots.GearOne) {
            ability = this.gearOneAbility;
            abilityConfig = this.gearOneConfig.activeAbility;
        }

        if (gearSlot === LoadoutSlots.GearTwo) {
            ability = this.gearTwoAbility;
            abilityConfig = this.gearTwoConfig.activeAbility;
        }

        if (gearSlot === LoadoutSlots.GearThree) {
            ability = this.gearThreeAbility;
            abilityConfig = this.gearThreeConfig.activeAbility;
        }

        this._useActiveAbility(ability, abilityConfig);
    }

    protected override _useActiveAbility(activeAbility: ActiveAbility, abilityDefinition: ActiveAbilityDefinition) {
        super._useActiveAbility(activeAbility, abilityDefinition);

        console.log("using ability on client:", ActiveAbility[activeAbility]);

        switch (activeAbility) {
            case ActiveAbility.Dash:
                (this.GetOwningEntity() as ClientPlayerPredicted).UseActiveAbility_Dash(abilityDefinition.abilityParameter, abilityDefinition.abilityDurationInSeconds);
                break;
            // case ActiveAbility.Relocation:
            // case ActiveAbility.ZoomOut:
            // case ActiveAbility.DamageReduction:
            // case ActiveAbility.DamageBoost:
            // case ActiveAbility.ProjectilePierce:
            // case ActiveAbility.Random:
            case ActiveAbility.None:
            case ActiveAbility.Invisibility:
            case ActiveAbility.TimerExtender:
            case ActiveAbility.Suicide:
            case ActiveAbility.Heal:
            case ActiveAbility.Extract:
                console.log("active ability use ignored on client (likely server only)");
                break;
            default:
                console.log("unrecognized active ability used on client");
                break;
        }
    }

    public override Update(deltaTime: number): void {
        super.Update(deltaTime);
        this.predictedProjectiles.forEach((predictedProjectile) => {
            predictedProjectile.Update(deltaTime);
            if (predictedProjectile.ToBeDestroyed) {
                Game.Renderer.RemoveRenderedEntity(predictedProjectile);
                predictedProjectile.RenderedEntity.destroy();
                this.predictedProjectiles.delete(predictedProjectile);
            }
        });
    }
}

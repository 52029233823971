import { PlayerEntityCreationPayload } from "../../shared/SharedNetcodeSchemas";
import { LoadoutSlots } from "../../shared/systems/SharedGearAndWeaponSystem";
import { ClientGearAndWeaponSystem } from "../systems/ClientGearAndWeaponSystem";
import { UI_UpdateCooldownProgress, UI_UpdateGearCharges } from "../ui/framework/UI_State";
import { ClientPlayer } from "./ClientPlayer";

export class ClientPlayerPredicted extends ClientPlayer {
    public static readonly WhitelistedReplicatedProperties = ["currentHealthPercentage", "isAlive", "activeWeaponSlot", "characterOpacityAmount", "gearOneCharges", "gearTwoCharges", "gearThreeCharges", "extractionSuccessful"];

    public override GearAndWeaponSystem: ClientGearAndWeaponSystem;

    public lastBroadcastedWeaponOneCooldownPercentage: number = 100;
    public lastBroadcastedWeaponTwoCooldownPercentage: number = 100;
    public lastBroadcastedGearOneCooldownPercentage: number = 100;
    public lastBroadcastedGearTwoCooldownPercentage: number = 100;
    public lastBroadcastedGearThreeCooldownPercentage: number = 100;
    private lastBroadcastedGearOneCharges: number = -1;
    private lastBroadcastedGearTwoCharges: number = -1;
    private lastBroadcastedGearThreeCharges: number = -1;

    public constructor(originalCreationPayload: PlayerEntityCreationPayload) {
        super(originalCreationPayload);
        this.lastOpacityAmount = this.characterOpacityAmount;
        const { weaponOneId, weaponTwoId, gearOneId, gearTwoId, gearThreeId } = originalCreationPayload;
        this.GearAndWeaponSystem = new ClientGearAndWeaponSystem();
        this.GearAndWeaponSystem.SetOwningEntity(this);
        this.GearAndWeaponSystem.SetWeaponDetails(weaponOneId, weaponTwoId);
        this.GearAndWeaponSystem.SetGearDetails(gearOneId, gearTwoId, gearThreeId);

        // this.usernameText.alpha = 0;

        Game.Collision.AddEntity(this);
    }

    public override Update(deltaTime: number, currentTimeInMilliseconds: number): void {
        super.Update(deltaTime, currentTimeInMilliseconds);

        if (this.isAlive === false || this.extractionSuccessful === true) {
            return;
        }

        if (this.lastOpacityAmount !== this.characterOpacityAmount) {
            // console.log("character opacity amount in predicted", this.characterOpacityAmount);
            this.RenderedEntity.alpha = this.characterOpacityAmount;
            this.lastOpacityAmount = this.characterOpacityAmount;
        }

        if (this.weaponOneCooldownProgressPercentage !== this.lastBroadcastedWeaponOneCooldownPercentage) {
            this.lastBroadcastedWeaponOneCooldownPercentage = this.weaponOneCooldownProgressPercentage;
            UI_UpdateCooldownProgress(LoadoutSlots.WeaponOne, this.weaponOneCooldownProgressPercentage);
        }

        if (this.weaponTwoCooldownProgressPercentage !== this.lastBroadcastedWeaponTwoCooldownPercentage) {
            this.lastBroadcastedWeaponTwoCooldownPercentage = this.weaponTwoCooldownProgressPercentage;
            UI_UpdateCooldownProgress(LoadoutSlots.WeaponTwo, this.weaponTwoCooldownProgressPercentage);
        }

        if (this.gearOneCooldownProgressPercentage !== this.lastBroadcastedGearOneCooldownPercentage) {
            this.lastBroadcastedGearOneCooldownPercentage = this.gearOneCooldownProgressPercentage;
            UI_UpdateCooldownProgress(LoadoutSlots.GearOne, this.gearOneCooldownProgressPercentage);
        }

        if (this.gearTwoCooldownProgressPercentage !== this.lastBroadcastedGearTwoCooldownPercentage) {
            this.lastBroadcastedGearTwoCooldownPercentage = this.gearTwoCooldownProgressPercentage;
            UI_UpdateCooldownProgress(LoadoutSlots.GearTwo, this.gearTwoCooldownProgressPercentage);
        }

        if (this.gearThreeCooldownProgressPercentage !== this.lastBroadcastedGearThreeCooldownPercentage) {
            this.lastBroadcastedGearThreeCooldownPercentage = this.gearThreeCooldownProgressPercentage;
            UI_UpdateCooldownProgress(LoadoutSlots.GearThree, this.gearThreeCooldownProgressPercentage);
        }

        if (this.lastBroadcastedGearOneCharges !== this.gearOneCharges) {
            this.lastBroadcastedGearOneCharges = this.gearOneCharges;
            // console.log(this.gearOneCharges);
            UI_UpdateGearCharges(LoadoutSlots.GearOne, this.gearOneCharges);
        }
        if (this.lastBroadcastedGearTwoCharges !== this.gearTwoCharges) {
            this.lastBroadcastedGearTwoCharges = this.gearTwoCharges;
            UI_UpdateGearCharges(LoadoutSlots.GearTwo, this.gearTwoCharges);
        }
        if (this.lastBroadcastedGearThreeCharges !== this.gearThreeCharges) {
            this.lastBroadcastedGearThreeCharges = this.gearThreeCharges;
            UI_UpdateGearCharges(LoadoutSlots.GearThree, this.gearThreeCharges);
        }

        this.GearAndWeaponSystem.Update(deltaTime);
    }
}

export interface HitscanProjectileConfig {
    range: number;
    spread: number;
    numberOfLines: number;
}

export interface EntityProjectileConfig {
    id: Projectile;
    projectileSpriteScale: number;
    projectileSpriteId: string;
    rotateProjectileSprite: boolean;
    hasImpactPfx: boolean;
    impactSpriteScale: number;
    impactSpriteId: string;
    speedPerSecond: number;
    lifetimeInSeconds: number;
    colliderRadius: number;
    spriteXAnchor: number;
}

export enum Projectile {
    BasicBlue = 0,
    BasicRed = 1,
    BasicGreen = 2,
    BasicPink = 3,
    FancyRed = 4,
    FireSkull = 5,
    FancyRainbow = 6,
    FancyAqua = 7,
    FancyLime = 8,
    FancyBlack = 9,
    FancyPink = 10,
    FancyPurple = 11,
    FancyCrimson = 12,
    FancyWhite = 13,
    FancyRainbowTwo = 14,
    Boulder = 15,
    ArcaneSpark = 16,
    ArrowNormal = 17,
    StarArrow = 18,
    BloodArrow = 19,
    DarylsBolt = 20,
    EldritchBolt = 21,
    GoldBolt = 22,
    BoltNormal = 23,
    BoltSilver = 24,
    CelestialArrow = 25,
    FairyFireArrow = 26,
    FairyIceArrow = 27,
    FairyLightArrow = 28,
    FairyNormalArrow = 29,
    FireArrow = 30,
    GiantsArrow = 31,
    GoldenArrow = 32,
    GuillotineArrow = 33,
    MagicArrow = 34,
    MoonArrow = 35,
    ReaperArrow = 36,
    ShadowArrow = 37,
    Bees = 38,
    Blaze = 39,
    Coin = 40,
    EyeBall = 41,
    MagicFairy = 42,
    FlameBreath = 43,
    HolySpark = 44,
    KingBible = 45,
    MagicLeaf = 46,
    MagicalSpark = 47,
    MidasFist = 48,
    Poisson = 49,
    SlimeBall = 50,
    SpellBinder = 51,
    MagicStar = 52,
    ToxicClouds = 53,
    TriForce = 54,
    WandSpark = 55,
    TwistedSpark = 56,
    RedCrystal = 57,
    CandleLight = 58,
    IronSpark = 59,
    LichSkull = 60,
    PhoenixArrow = 61,
    BlackSpark = 62,
    HibitySpark = 63,
    IceSpark = 64,
    FrostWind = 65,
    ZapSpark = 66,
    QuincyArrow = 67,
    GRONKBoulder = 68,
    IronFoxSlash = 69,
    IdleBlue = 70,
    IdleFlameBreath = 71,
    IdleToxicClouds = 72,
    IdlePink = 73,
    IdleFancyRed = 74,
    IdleCandleLight = 75,
    IdleTwistedSpark = 76,
    IdleIronSpark = 77,
    IdleHolySpark = 78,
    IdleMagicFairy = 79,
    IdleMidasFist = 80,
    IdleEyeBall = 81,
    IdleSlimeBall = 82,
    IdleBees = 83,
    IdleLichSkull = 84,
    ShortBolt = 85,
    BookOfTongues = 86,
    Deathstick = 87,
    DarkArrow = 88
}

const ProjectileData: Map<Projectile, EntityProjectileConfig> = new Map();

const setProjectileData = (projectileConfig: EntityProjectileConfig) => {
    const existingEntryWithId = ProjectileData.get(projectileConfig.id);

    if (existingEntryWithId) {
        throw new Error(`ProjectileConfig with id ${projectileConfig.id} already exists! Error in projectile data file.`);
    }

    ProjectileData.set(projectileConfig.id, projectileConfig);
};

export const getProjectileData = (id: Projectile): EntityProjectileConfig => {
    const projectileConfig = ProjectileData.get(id);

    if (!projectileConfig) {
        throw new Error(`ProjectileConfig with id ${id} does not exist! Error in projectile data file.`);
    }

    return projectileConfig;
};

/***************************/
/*  Basic Blue Projectile  */
/***************************/

setProjectileData({
    id: Projectile.BasicBlue,
    projectileSpriteId: "projectiles/10/ptcBLU",
    projectileSpriteScale: 1,
    hasImpactPfx: true,
    impactSpriteId: "pfx/popBLU",
    impactSpriteScale: 0.5,
    speedPerSecond: 150,
    lifetimeInSeconds: 2.5,
    colliderRadius: 3,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/***************************/
/*  Idle Blue Projectile  */
/***************************/

setProjectileData({
    id: Projectile.IdleBlue,
    projectileSpriteId: "projectiles/10/ptcBLU",
    projectileSpriteScale: 4,
    hasImpactPfx: true,
    impactSpriteId: "pfx/popBLU",
    impactSpriteScale: 0.5,
    speedPerSecond: 0,
    lifetimeInSeconds: 5,
    colliderRadius: 12,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/**************************/
/*  Basic Red Projectile  */
/**************************/

setProjectileData({
    id: Projectile.BasicRed,
    projectileSpriteId: "projectiles/11/ptcFIR",
    projectileSpriteScale: 3,
    hasImpactPfx: false,
    impactSpriteId: "pfx/popFIR",
    impactSpriteScale: 1,
    speedPerSecond: 100,
    lifetimeInSeconds: 1,
    colliderRadius: 7,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/****************************/
/*  Basic Green Projectile  */
/****************************/

setProjectileData({
    id: Projectile.BasicGreen,
    projectileSpriteId: "projectiles/12/ptcGRE",
    projectileSpriteScale: 1.5,
    hasImpactPfx: true,
    impactSpriteId: "pfx/popGRE",
    impactSpriteScale: 0.5,
    speedPerSecond: 130,
    lifetimeInSeconds: 1.2,
    colliderRadius: 4.5,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/****************************/
/*  Basic Pink Projectile   */
/****************************/

setProjectileData({
    id: Projectile.BasicPink,
    projectileSpriteId: "projectiles/13/ptcPNK",
    projectileSpriteScale: 2,
    hasImpactPfx: true,
    impactSpriteId: "pfx/popPNK",
    impactSpriteScale: 0.75,
    speedPerSecond: 250,
    lifetimeInSeconds: 2,
    colliderRadius: 6,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/****************************/
/*  Idle Pink Projectile   */
/****************************/

setProjectileData({
    id: Projectile.IdlePink,
    projectileSpriteId: "projectiles/13/ptcPNK",
    projectileSpriteScale: 4,
    hasImpactPfx: true,
    impactSpriteId: "pfx/popPNK",
    impactSpriteScale: 0.75,
    speedPerSecond: 0,
    lifetimeInSeconds: 5,
    colliderRadius: 12,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/****************************/
/*  Fire Skull Projectile   */
/****************************/

setProjectileData({
    id: Projectile.FireSkull,
    projectileSpriteId: "projectiles/fireskull/proj_fireSkull",
    projectileSpriteScale: 1,
    hasImpactPfx: true,
    impactSpriteId: "pfx/popFIR",
    impactSpriteScale: 1,
    speedPerSecond: 150,
    lifetimeInSeconds: 2,
    colliderRadius: 6,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: false
});

/*******************************/
/*  Ogre Boulder               */
/*******************************/

setProjectileData({
    id: Projectile.Boulder,
    projectileSpriteId: "projectiles/boulder/boulder",
    projectileSpriteScale: 1,
    hasImpactPfx: true,
    impactSpriteId: "pfx/popGRE",
    impactSpriteScale: 1,
    speedPerSecond: 150,
    lifetimeInSeconds: 1.2,
    colliderRadius: 5,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/********************************/
/*  GRONK Boulder               */
/********************************/

setProjectileData({
    id: Projectile.GRONKBoulder,
    projectileSpriteId: "projectiles/boulder/boulder",
    projectileSpriteScale: 1.5,
    hasImpactPfx: true,
    impactSpriteId: "pfx/popGRE",
    impactSpriteScale: 1,
    speedPerSecond: 200,
    lifetimeInSeconds: 3,
    colliderRadius: 10,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/****************************/
/*  Fancy Red Projectile    */
/****************************/

setProjectileData({
    id: Projectile.FancyRed,
    projectileSpriteId: "projectiles/1/1",
    projectileSpriteScale: 1.5,
    hasImpactPfx: true,
    impactSpriteId: "pfx/popFIR",
    impactSpriteScale: 0.5,
    speedPerSecond: 200,
    lifetimeInSeconds: 1.4,
    colliderRadius: 4,
    spriteXAnchor: 0.9,
    rotateProjectileSprite: true
});

/****************************/
/*  Idle Fancy Red Projectile    */
/****************************/

setProjectileData({
    id: Projectile.IdleFancyRed,
    projectileSpriteId: "projectiles/1/1",
    projectileSpriteScale: 5,
    hasImpactPfx: true,
    impactSpriteId: "pfx/popFIR",
    impactSpriteScale: 0.5,
    speedPerSecond: 0,
    lifetimeInSeconds: 5,
    colliderRadius: 13,
    spriteXAnchor: 0.9,
    rotateProjectileSprite: true
});

/*******************************/
/*  Fancy Rainbow Projectile   */
/*******************************/

setProjectileData({
    id: Projectile.FancyRainbow,
    projectileSpriteId: "projectiles/7/1",
    projectileSpriteScale: 1.5,
    hasImpactPfx: true,
    impactSpriteId: "pfx/popPNK",
    impactSpriteScale: 0.5,
    speedPerSecond: 190,
    lifetimeInSeconds: 1.4,
    colliderRadius: 3,
    spriteXAnchor: 0.9,
    rotateProjectileSprite: true
});

/****************************/
/*  Fancy Aqua Projectile   */
/****************************/

setProjectileData({
    id: Projectile.FancyAqua,
    projectileSpriteId: "projectiles/2/1",
    projectileSpriteScale: 1.5,
    hasImpactPfx: true,
    impactSpriteId: "pfx/popBLU",
    impactSpriteScale: 0.5,
    speedPerSecond: 160,
    lifetimeInSeconds: 1.2,
    colliderRadius: 3,
    spriteXAnchor: 0.9,
    rotateProjectileSprite: true
});

/****************************/
/*  Fancy Lime Projectile   */
/****************************/

setProjectileData({
    id: Projectile.FancyLime,
    projectileSpriteId: "projectiles/3/1",
    projectileSpriteScale: 1.5,
    hasImpactPfx: true,
    impactSpriteId: "pfx/popGRE",
    impactSpriteScale: 0.5,
    speedPerSecond: 250,
    lifetimeInSeconds: 1.35,
    colliderRadius: 3,
    spriteXAnchor: 0.9,
    rotateProjectileSprite: true
});

/*****************************/
/*  Fancy Black Projectile   */
/*****************************/

setProjectileData({
    id: Projectile.FancyBlack,
    projectileSpriteId: "projectiles/4/1",
    projectileSpriteScale: 1.5,
    hasImpactPfx: true,
    impactSpriteId: "pfx/popFIR",
    impactSpriteScale: 0.5,
    speedPerSecond: 140,
    lifetimeInSeconds: 1.6,
    colliderRadius: 3,
    spriteXAnchor: 0.9,
    rotateProjectileSprite: true
});

/****************************/
/*  Fancy Pink Projectile   */
/****************************/

setProjectileData({
    id: Projectile.FancyPink,
    projectileSpriteId: "projectiles/5/1",
    projectileSpriteScale: 1.5,
    hasImpactPfx: true,
    impactSpriteId: "pfx/popPNK",
    impactSpriteScale: 0.5,
    speedPerSecond: 220,
    lifetimeInSeconds: 1.1,
    colliderRadius: 3,
    spriteXAnchor: 0.9,
    rotateProjectileSprite: true
});

/******************************/
/*  Fancy Purple Projectile   */
/******************************/

setProjectileData({
    id: Projectile.FancyPurple,
    projectileSpriteId: "projectiles/6/1",
    projectileSpriteScale: 1.5,
    hasImpactPfx: true,
    impactSpriteId: "pfx/popPNK2",
    impactSpriteScale: 0.5,
    speedPerSecond: 120,
    lifetimeInSeconds: 2,
    colliderRadius: 3,
    spriteXAnchor: 0.9,
    rotateProjectileSprite: true
});

/*******************************/
/*  Fancy Crimson Projectile   */
/*******************************/

setProjectileData({
    id: Projectile.FancyCrimson,
    projectileSpriteId: "projectiles/8/1",
    projectileSpriteScale: 1.5,
    hasImpactPfx: true,
    impactSpriteId: "pfx/popPNK",
    impactSpriteScale: 0.5,
    speedPerSecond: 300,
    lifetimeInSeconds: 1.1,
    colliderRadius: 3,
    spriteXAnchor: 0.9,
    rotateProjectileSprite: true
});

/*****************************/
/*  Fancy White Projectile   */
/*****************************/

setProjectileData({
    id: Projectile.FancyWhite,
    projectileSpriteId: "projectiles/9/1",
    projectileSpriteScale: 1.5,
    hasImpactPfx: true,
    impactSpriteId: "pfx/popFIR",
    impactSpriteScale: 0.5,
    speedPerSecond: 205,
    lifetimeInSeconds: 1.25,
    colliderRadius: 3,
    spriteXAnchor: 0.9,
    rotateProjectileSprite: true
});

/**********************************/
/*  Fancy RainbowTwo Projectile   */
/**********************************/

setProjectileData({
    id: Projectile.FancyRainbowTwo,
    projectileSpriteId: "projectiles/7/1",
    projectileSpriteScale: 1.5,
    hasImpactPfx: true,
    impactSpriteId: "pfx/popGRE",
    impactSpriteScale: 0.5,
    speedPerSecond: 275,
    lifetimeInSeconds: 1.2,
    colliderRadius: 3,
    spriteXAnchor: 0.9,
    rotateProjectileSprite: true
});

/**********************************/
/*  Arcane Spark                  */
/**********************************/

setProjectileData({
    id: Projectile.ArcaneSpark,
    projectileSpriteId: "projectiles/arcaneSpark/proj_arcaneSpark",
    projectileSpriteScale: 2,
    hasImpactPfx: true,
    impactSpriteId: "pfx/arcaneSpark/impact_arcaneSpark",
    impactSpriteScale: 1,
    speedPerSecond: 100,
    lifetimeInSeconds: 1,
    colliderRadius: 5,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/**********************************/
/*  Arrow Normal                  */
/**********************************/

setProjectileData({
    id: Projectile.ArrowNormal,
    projectileSpriteId: "projectiles/arrows/proj_arrowNormal",
    projectileSpriteScale: 0.6,
    hasImpactPfx: true,
    impactSpriteId: "pfx/blackSpark/impact_blackSpark",
    impactSpriteScale: 1,
    speedPerSecond: 160,
    lifetimeInSeconds: 1.2,
    colliderRadius: 4,
    spriteXAnchor: 0.8,
    rotateProjectileSprite: true
});

/**********************************/
/*  Star Arrows                   */
/**********************************/

setProjectileData({
    id: Projectile.StarArrow,
    projectileSpriteId: "projectiles/arrows/proj_arrowStar",
    projectileSpriteScale: 0.75,
    hasImpactPfx: true,
    impactSpriteId: "pfx/wandSpark/impact_wandSpark",
    impactSpriteScale: 1,
    speedPerSecond: 275,
    lifetimeInSeconds: 1.2,
    colliderRadius: 8,
    spriteXAnchor: 0.6,
    rotateProjectileSprite: true
});

/**********************************/
/*  Blood Arrow                   */
/**********************************/

setProjectileData({
    id: Projectile.BloodArrow,
    projectileSpriteId: "projectiles/arrows/proj_bloodArrow",
    projectileSpriteScale: 0.75,
    hasImpactPfx: true,
    impactSpriteId: "pfx/wandSpark/impact_wandSpark",
    impactSpriteScale: 1,
    speedPerSecond: 205,
    lifetimeInSeconds: 1.25,
    colliderRadius: 4,
    spriteXAnchor: 0.8,
    rotateProjectileSprite: true
});

/**********************************/
/*  Daryls Bolts                  */
/**********************************/

setProjectileData({
    id: Projectile.DarylsBolt,
    projectileSpriteId: "projectiles/arrows/proj_boltDaryls",
    projectileSpriteScale: 1,
    hasImpactPfx: true,
    impactSpriteId: "pfx/blackSpark/impact_blackSpark",
    impactSpriteScale: 1,
    speedPerSecond: 350,
    lifetimeInSeconds: 1,
    colliderRadius: 4,
    spriteXAnchor: 0.8,
    rotateProjectileSprite: true
});

/**********************************/
/*  Eldritch Bolts                */
/**********************************/

setProjectileData({
    id: Projectile.EldritchBolt,
    projectileSpriteId: "projectiles/arrows/proj_boltEldritch",
    projectileSpriteScale: 1,
    hasImpactPfx: true,
    impactSpriteId: "pfx/arcaneSpark/impact_arcaneSpark",
    impactSpriteScale: 1,
    speedPerSecond: 230,
    lifetimeInSeconds: 1.1,
    colliderRadius: 4,
    spriteXAnchor: 0.8,
    rotateProjectileSprite: true
});

/**********************************/
/*  GoldBolt                      */
/**********************************/

setProjectileData({
    id: Projectile.GoldBolt,
    projectileSpriteId: "projectiles/arrows/proj_boltGold",
    projectileSpriteScale: 1,
    hasImpactPfx: true,
    impactSpriteId: "pfx/wandSpark/impact_wandSpark",
    impactSpriteScale: 1,
    speedPerSecond: 220,
    lifetimeInSeconds: 1.3,
    colliderRadius: 4,
    spriteXAnchor: 0.8,
    rotateProjectileSprite: true
});

/**********************************/
/*  Normal Bolts                  */
/**********************************/

setProjectileData({
    id: Projectile.BoltNormal,
    projectileSpriteId: "projectiles/arrows/proj_boltNormal",
    projectileSpriteScale: 1,
    hasImpactPfx: true,
    impactSpriteId: "pfx/blackSpark/impact_blackSpark",
    impactSpriteScale: 1,
    speedPerSecond: 280,
    lifetimeInSeconds: 2,
    colliderRadius: 4,
    spriteXAnchor: 0.8,
    rotateProjectileSprite: true
});

/**********************************/
/*  ShortBolt                  */
/**********************************/

setProjectileData({
    id: Projectile.ShortBolt,
    projectileSpriteId: "projectiles/arrows/proj_boltNormal",
    projectileSpriteScale: 1,
    hasImpactPfx: true,
    impactSpriteId: "pfx/blackSpark/impact_blackSpark",
    impactSpriteScale: 1,
    speedPerSecond: 280,
    lifetimeInSeconds: 1,
    colliderRadius: 4,
    spriteXAnchor: 0.8,
    rotateProjectileSprite: true
});

/**********************************/
/*  Silver Bolts                */
/**********************************/

setProjectileData({
    id: Projectile.BoltSilver,
    projectileSpriteId: "projectiles/arrows/proj_boltSilver",
    projectileSpriteScale: 1,
    hasImpactPfx: true,
    impactSpriteId: "pfx/blackSpark/impact_blackSpark",
    impactSpriteScale: 1,
    speedPerSecond: 260,
    lifetimeInSeconds: 1.4,
    colliderRadius: 4,
    spriteXAnchor: 0.8,
    rotateProjectileSprite: true
});

/**********************************/
/*  Celestial Arrow               */
/**********************************/

setProjectileData({
    id: Projectile.CelestialArrow,
    projectileSpriteId: "projectiles/arrows/proj_celestialArrow",
    projectileSpriteScale: 0.75,
    hasImpactPfx: true,
    impactSpriteId: "pfx/arcaneSpark/impact_arcaneSpark",
    impactSpriteScale: 1,
    speedPerSecond: 300,
    lifetimeInSeconds: 1.3,
    colliderRadius: 4,
    spriteXAnchor: 0.8,
    rotateProjectileSprite: true
});

/**********************************/
/*  Fairy Fire Arrow              */
/**********************************/

setProjectileData({
    id: Projectile.FairyFireArrow,
    projectileSpriteId: "projectiles/arrows/proj_fairyFire",
    projectileSpriteScale: 1,
    hasImpactPfx: true,
    impactSpriteId: "pfx/wandSpark/impact_wandSpark",
    impactSpriteScale: 1,
    speedPerSecond: 100,
    lifetimeInSeconds: 1,
    colliderRadius: 5,
    spriteXAnchor: 0.8,
    rotateProjectileSprite: true
});

/**********************************/
/*  Fairy Ice Arrow               */
/**********************************/

setProjectileData({
    id: Projectile.FairyIceArrow,
    projectileSpriteId: "projectiles/arrows/proj_fairyIce",
    projectileSpriteScale: 1,
    hasImpactPfx: false,
    impactSpriteId: "pfx/popBLU_001",
    impactSpriteScale: 1,
    speedPerSecond: 220,
    lifetimeInSeconds: 1.1,
    colliderRadius: 5,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/**********************************/
/*  Fairy Light Arrow             */
/**********************************/

setProjectileData({
    id: Projectile.FairyLightArrow,
    projectileSpriteId: "projectiles/arrows/proj_fairyLight",
    projectileSpriteScale: 0.6,
    hasImpactPfx: true,
    impactSpriteId: "pfx/wandSpark/impact_wandSpark",
    impactSpriteScale: 1,
    speedPerSecond: 220,
    lifetimeInSeconds: 1.1,
    colliderRadius: 5,
    spriteXAnchor: 0.8,
    rotateProjectileSprite: true
});

/**********************************/
/*  Fairy Normal Arrow            */
/**********************************/

setProjectileData({
    id: Projectile.FairyNormalArrow,
    projectileSpriteId: "projectiles/arrows/proj_fairyNormal",
    projectileSpriteScale: 1,
    hasImpactPfx: false,
    impactSpriteId: "pfx/popBLU_001",
    impactSpriteScale: 1,
    speedPerSecond: 220,
    lifetimeInSeconds: 1.1,
    colliderRadius: 5,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/**********************************/
/*  Fire Arrow                    */
/**********************************/

setProjectileData({
    id: Projectile.FireArrow,
    projectileSpriteId: "projectiles/arrows/proj_fireArrow",
    projectileSpriteScale: 0.75,
    hasImpactPfx: true,
    impactSpriteId: "pfx/wandSpark/impact_wandSpark",
    impactSpriteScale: 1,
    speedPerSecond: 220,
    lifetimeInSeconds: 1.1,
    colliderRadius: 5,
    spriteXAnchor: 0.8,
    rotateProjectileSprite: true
});

/**********************************/
/*  Phoenix Arrow                    */
/**********************************/

setProjectileData({
    id: Projectile.PhoenixArrow,
    projectileSpriteId: "projectiles/arrows/proj_fireArrow",
    projectileSpriteScale: 1,
    hasImpactPfx: false,
    impactSpriteId: "pfx/popBLU_001",
    impactSpriteScale: 1,
    speedPerSecond: 190,
    lifetimeInSeconds: 1.4,
    colliderRadius: 4,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/**********************************/
/*  Giants Arrow                  */
/**********************************/

setProjectileData({
    id: Projectile.GiantsArrow,
    projectileSpriteId: "projectiles/arrows/proj_giantsArrow",
    projectileSpriteScale: 1,
    hasImpactPfx: false,
    impactSpriteId: "pfx/popBLU_001",
    impactSpriteScale: 1,
    speedPerSecond: 100,
    lifetimeInSeconds: 1,
    colliderRadius: 5,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/**********************************/
/*  Golden Arrow                  */
/**********************************/

setProjectileData({
    id: Projectile.GoldenArrow,
    projectileSpriteId: "projectiles/arrows/proj_goldenArrow",
    projectileSpriteScale: 1,
    hasImpactPfx: false,
    impactSpriteId: "pfx/wandSpark/impact_wandSpark",
    impactSpriteScale: 1,
    speedPerSecond: 250,
    lifetimeInSeconds: 1.6,
    colliderRadius: 4,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/**********************************/
/*  Guillotine Arrow              */
/**********************************/

setProjectileData({
    id: Projectile.GuillotineArrow,
    projectileSpriteId: "projectiles/arrows/proj_guillotineArrow",
    projectileSpriteScale: 0.6,
    hasImpactPfx: true,
    impactSpriteId: "pfx/blackSpark/impact_blackSpark",
    impactSpriteScale: 1,
    speedPerSecond: 200,
    lifetimeInSeconds: 2,
    colliderRadius: 4,
    spriteXAnchor: 0.75,
    rotateProjectileSprite: true
});

/**********************************/
/*  Magic Arrow                   */
/**********************************/

setProjectileData({
    id: Projectile.MagicArrow,
    projectileSpriteId: "projectiles/arrows/proj_magicArrow",
    projectileSpriteScale: 1,
    hasImpactPfx: false,
    impactSpriteId: "pfx/popBLU_001",
    impactSpriteScale: 1,
    speedPerSecond: 100,
    lifetimeInSeconds: 1,
    colliderRadius: 5,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/**********************************/
/*  Moon Arrow                    */
/**********************************/

setProjectileData({
    id: Projectile.MoonArrow,
    projectileSpriteId: "projectiles/arrows/proj_moonArrow",
    projectileSpriteScale: 0.6,
    hasImpactPfx: true,
    impactSpriteId: "pfx/arcaneSpark/impact_arcaneSpark",
    impactSpriteScale: 1,
    speedPerSecond: 120,
    lifetimeInSeconds: 2,
    colliderRadius: 4,
    spriteXAnchor: 0.8,
    rotateProjectileSprite: true
});

/**********************************/
/*  Reaper Arrows                 */
/**********************************/

setProjectileData({
    id: Projectile.ReaperArrow,
    projectileSpriteId: "projectiles/arrows/proj_reaperArrow",
    projectileSpriteScale: 0.6,
    hasImpactPfx: true,
    impactSpriteId: "pfx/arcaneSpark/impact_arcaneSpark",
    impactSpriteScale: 1,
    speedPerSecond: 240,
    lifetimeInSeconds: 1.5,
    colliderRadius: 4,
    spriteXAnchor: 0.8,
    rotateProjectileSprite: true
});

/**********************************/
/*  Quincy Arrow                */
/**********************************/

setProjectileData({
    id: Projectile.QuincyArrow,
    projectileSpriteId: "projectiles/arrows/proj_arrowQuincy",
    projectileSpriteScale: 0.6,
    hasImpactPfx: true,
    impactSpriteId: "pfx/arcaneSpark/impact_arcaneSpark",
    impactSpriteScale: 1,
    speedPerSecond: 210,
    lifetimeInSeconds: 1.35,
    colliderRadius: 4,
    spriteXAnchor: 0.8,
    rotateProjectileSprite: true
});

/**********************************/
/*  Shadow Arrow                  */
/**********************************/

setProjectileData({
    id: Projectile.ShadowArrow,
    projectileSpriteId: "projectiles/arrows/proj_shadowArrow",
    projectileSpriteScale: 1,
    hasImpactPfx: false,
    impactSpriteId: "pfx/popBLU_001",
    impactSpriteScale: 1,
    speedPerSecond: 100,
    lifetimeInSeconds: 1,
    colliderRadius: 5,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/**********************************/
/*  Dark Arrow                  */
/**********************************/

setProjectileData({
    id: Projectile.DarkArrow,
    projectileSpriteId: "projectiles/arrows/proj_darkArrow",
    projectileSpriteScale: 1,
    hasImpactPfx: false,
    impactSpriteId: "",
    impactSpriteScale: 1,
    speedPerSecond: 275,
    lifetimeInSeconds: 1.5,
    colliderRadius: 5,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/**********************************/
/*  Bees                          */
/**********************************/

setProjectileData({
    id: Projectile.Bees,
    projectileSpriteId: "projectiles/bees/proj_bees",
    projectileSpriteScale: 1,
    hasImpactPfx: true,
    impactSpriteId: "pfx/bees/impact_bees",
    impactSpriteScale: 1,
    speedPerSecond: 150,
    lifetimeInSeconds: 2.5,
    colliderRadius: 5,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: false
});

/**********************************/
/*  Idle Bees                          */
/**********************************/

setProjectileData({
    id: Projectile.IdleBees,
    projectileSpriteId: "projectiles/bees/proj_bees",
    projectileSpriteScale: 3,
    hasImpactPfx: true,
    impactSpriteId: "pfx/bees/impact_bees",
    impactSpriteScale: 1,
    speedPerSecond: 0,
    lifetimeInSeconds: 5,
    colliderRadius: 12,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: false
});

/**********************************/
/*  Blaze                         */
/**********************************/

setProjectileData({
    id: Projectile.Blaze,
    projectileSpriteId: "projectiles/blaze/proj_blazePowder",
    projectileSpriteScale: 1.4,
    hasImpactPfx: true,
    impactSpriteId: "pfx/wandSpark/impact_wandSpark",
    impactSpriteScale: 1,
    speedPerSecond: 220,
    lifetimeInSeconds: 1,
    colliderRadius: 5,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: false
});

/**********************************/
/*  Coin                          */
/**********************************/

setProjectileData({
    id: Projectile.Coin,
    projectileSpriteId: "projectiles/coin/proj_coin",
    projectileSpriteScale: 0.85,
    hasImpactPfx: true,
    impactSpriteId: "pfx/coin/impact_coin",
    impactSpriteScale: 1,
    speedPerSecond: 260,
    lifetimeInSeconds: 1.1,
    colliderRadius: 5,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: false
});

/**********************************/
/*  EyeBall                       */
/**********************************/

setProjectileData({
    id: Projectile.EyeBall,
    projectileSpriteId: "projectiles/eyeball/proj_eyeBall",
    projectileSpriteScale: 1.25,
    hasImpactPfx: true,
    impactSpriteId: "pfx/wandSpark/impact_wandSpark",
    impactSpriteScale: 1,
    speedPerSecond: 180,
    lifetimeInSeconds: 2.5,
    colliderRadius: 5,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/**********************************/
/*  Idle EyeBall                       */
/**********************************/

setProjectileData({
    id: Projectile.IdleEyeBall,
    projectileSpriteId: "projectiles/eyeball/proj_eyeBall",
    projectileSpriteScale: 3,
    hasImpactPfx: true,
    impactSpriteId: "pfx/wandSpark/impact_wandSpark",
    impactSpriteScale: 1,
    speedPerSecond: 0,
    lifetimeInSeconds: 5,
    colliderRadius: 12,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/**********************************/
/*  Magic Fairy                   */
/**********************************/

setProjectileData({
    id: Projectile.MagicFairy,
    projectileSpriteId: "projectiles/fairy/proj_fairy",
    projectileSpriteScale: 1,
    hasImpactPfx: true,
    impactSpriteId: "pfx/fairy/impact_fairy",
    impactSpriteScale: 0.5,
    speedPerSecond: 200,
    lifetimeInSeconds: 1.6,
    colliderRadius: 6,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/**********************************/
/*  Idle Magic Fairy                   */
/**********************************/

setProjectileData({
    id: Projectile.IdleMagicFairy,
    projectileSpriteId: "projectiles/fairy/proj_fairy",
    projectileSpriteScale: 2,
    hasImpactPfx: true,
    impactSpriteId: "pfx/fairy/impact_fairy",
    impactSpriteScale: 0.5,
    speedPerSecond: 0,
    lifetimeInSeconds: 5,
    colliderRadius: 12,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/**********************************/
/*  Flame Breath                  */
/**********************************/

setProjectileData({
    id: Projectile.FlameBreath,
    projectileSpriteId: "projectiles/flameBreath/proj_flameBreath",
    projectileSpriteScale: 1.25,
    hasImpactPfx: false,
    impactSpriteId: "",
    impactSpriteScale: 1,
    speedPerSecond: 120,
    lifetimeInSeconds: 1.15,
    colliderRadius: 8,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/**********************************/
/*  Idle Flame Breath                  */
/**********************************/

setProjectileData({
    id: Projectile.IdleFlameBreath,
    projectileSpriteId: "projectiles/flameBreath/proj_flameBreath",
    projectileSpriteScale: 2,
    hasImpactPfx: false,
    impactSpriteId: "",
    impactSpriteScale: 1,
    speedPerSecond: 0,
    lifetimeInSeconds: 5,
    colliderRadius: 13,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/**********************************/
/*  Holy Spark                    */
/**********************************/

setProjectileData({
    id: Projectile.HolySpark,
    projectileSpriteId: "projectiles/holySpark/proj_holySpark",
    projectileSpriteScale: 1,
    hasImpactPfx: true,
    impactSpriteId: "pfx/wandSpark/impact_wandSpark",
    impactSpriteScale: 1,
    speedPerSecond: 200,
    lifetimeInSeconds: 1,
    colliderRadius: 5,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/**********************************/
/*  Idle Holy Spark                    */
/**********************************/

setProjectileData({
    id: Projectile.IdleHolySpark,
    projectileSpriteId: "projectiles/holySpark/proj_holySpark",
    projectileSpriteScale: 2.5,
    hasImpactPfx: true,
    impactSpriteId: "pfx/wandSpark/impact_wandSpark",
    impactSpriteScale: 1,
    speedPerSecond: 0,
    lifetimeInSeconds: 5,
    colliderRadius: 12.5,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/**********************************/
/*  King Bible                    */
/**********************************/

setProjectileData({
    id: Projectile.KingBible,
    projectileSpriteId: "projectiles/KingBible/proj_bible",
    projectileSpriteScale: 1.5,
    hasImpactPfx: true,
    impactSpriteId: "pfx/arcaneSpark/impact_arcaneSpark",
    impactSpriteScale: 1,
    speedPerSecond: 210,
    lifetimeInSeconds: 2,
    colliderRadius: 5,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/**********************************/
/*  Magic Leaf                    */
/**********************************/

setProjectileData({
    id: Projectile.MagicLeaf,
    projectileSpriteId: "projectiles/leaf/proj_leaf",
    projectileSpriteScale: 1,
    hasImpactPfx: true,
    impactSpriteId: "pfx/leaf/impact_leaf",
    impactSpriteScale: 0.5,
    speedPerSecond: 180,
    lifetimeInSeconds: 2.2,
    colliderRadius: 8,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/**********************************/
/*  Magical Spark                 */
/**********************************/

setProjectileData({
    id: Projectile.MagicalSpark,
    projectileSpriteId: "projectiles/magicSpark/proj_magicalSpark",
    projectileSpriteScale: 1,
    hasImpactPfx: true,
    impactSpriteId: "pfx/magicalSpark/impact_magicalSpark",
    impactSpriteScale: 1,
    speedPerSecond: 225,
    lifetimeInSeconds: 1,
    colliderRadius: 8,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/**********************************/
/*  Midas Fist                    */
/**********************************/

setProjectileData({
    id: Projectile.MidasFist,
    projectileSpriteId: "projectiles/midasFist/proj_midasFist",
    projectileSpriteScale: 1,
    hasImpactPfx: true,
    impactSpriteId: "pfx/midasFist/impact_midasFist",
    impactSpriteScale: 1,
    speedPerSecond: 180,
    lifetimeInSeconds: 2,
    colliderRadius: 5,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/**********************************/
/*  Idle Midas Fist                    */
/**********************************/

setProjectileData({
    id: Projectile.IdleMidasFist,
    projectileSpriteId: "projectiles/midasFist/proj_midasFist",
    projectileSpriteScale: 2.5,
    hasImpactPfx: true,
    impactSpriteId: "pfx/midasFist/impact_midasFist",
    impactSpriteScale: 1,
    speedPerSecond: 0,
    lifetimeInSeconds: 5,
    colliderRadius: 12.5,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/**********************************/
/*  Poisson                       */
/**********************************/

setProjectileData({
    id: Projectile.Poisson,
    projectileSpriteId: "projectiles/poisson/proj_poisson",
    projectileSpriteScale: 1,
    hasImpactPfx: true,
    impactSpriteId: "pfx/poisson/impact_poisson",
    impactSpriteScale: 1,
    speedPerSecond: 200,
    lifetimeInSeconds: 1.5,
    colliderRadius: 6,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/**********************************/
/*  SlimeBall                     */
/**********************************/

setProjectileData({
    id: Projectile.SlimeBall,
    projectileSpriteId: "projectiles/slimeBall/proj_slime",
    projectileSpriteScale: 1.5,
    hasImpactPfx: true,
    impactSpriteId: "pfx/popGRE",
    impactSpriteScale: 0.5,
    speedPerSecond: 150,
    lifetimeInSeconds: 2,
    colliderRadius: 6,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/**********************************/
/*  Idle SlimeBall                     */
/**********************************/

setProjectileData({
    id: Projectile.IdleSlimeBall,
    projectileSpriteId: "projectiles/slimeBall/proj_slime",
    projectileSpriteScale: 3,
    hasImpactPfx: true,
    impactSpriteId: "pfx/popGRE",
    impactSpriteScale: 0.5,
    speedPerSecond: 0,
    lifetimeInSeconds: 5,
    colliderRadius: 12,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/**********************************/
/*  SpellBinder                   */
/**********************************/

setProjectileData({
    id: Projectile.SpellBinder,
    projectileSpriteId: "projectiles/spellbinder/proj_spellbinder",
    projectileSpriteScale: 1.25,
    hasImpactPfx: true,
    impactSpriteId: "pfx/arcaneSpark/impact_arcaneSpark",
    impactSpriteScale: 1,
    speedPerSecond: 200,
    lifetimeInSeconds: 2,
    colliderRadius: 6,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: false
});

/**********************************/
/*  Magic Star                    */
/**********************************/

setProjectileData({
    id: Projectile.MagicStar,
    projectileSpriteId: "projectiles/star/proj_star",
    projectileSpriteScale: 1,
    hasImpactPfx: true,
    impactSpriteId: "pfx/star/impact_star",
    impactSpriteScale: 1,
    speedPerSecond: 280,
    lifetimeInSeconds: 1.75,
    colliderRadius: 8,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/**********************************/
/*  Toxic Clouds                  */
/**********************************/

setProjectileData({
    id: Projectile.ToxicClouds,
    projectileSpriteId: "projectiles/toxicCloud/proj_toxicCloud",
    projectileSpriteScale: 2,
    hasImpactPfx: false,
    impactSpriteId: "pfx/popBLU_001",
    impactSpriteScale: 1,
    speedPerSecond: 130,
    lifetimeInSeconds: 1.75,
    colliderRadius: 6,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/**********************************/
/*  Idle Toxic Clouds                  */
/**********************************/

setProjectileData({
    id: Projectile.IdleToxicClouds,
    projectileSpriteId: "projectiles/toxicCloud/proj_toxicCloud",
    projectileSpriteScale: 4,
    hasImpactPfx: false,
    impactSpriteId: "pfx/popBLU_001",
    impactSpriteScale: 1,
    speedPerSecond: 0,
    lifetimeInSeconds: 5,
    colliderRadius: 12,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/**********************************/
/*  TriForce                      */
/**********************************/

setProjectileData({
    id: Projectile.TriForce,
    projectileSpriteId: "projectiles/triforce/proj_triForce",
    projectileSpriteScale: 1,
    hasImpactPfx: true,
    impactSpriteId: "pfx/arcaneSpark/impact_arcaneSpark",
    impactSpriteScale: 1,
    speedPerSecond: 220,
    lifetimeInSeconds: 2,
    colliderRadius: 8,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/**********************************/
/*  Wand Spark                    */
/**********************************/

setProjectileData({
    id: Projectile.WandSpark,
    projectileSpriteId: "projectiles/wandSpark/proj_wandSpark",
    projectileSpriteScale: 1,
    hasImpactPfx: true,
    impactSpriteId: "pfx/wandSpark/impact_wandSpark",
    impactSpriteScale: 1,
    speedPerSecond: 200,
    lifetimeInSeconds: 1.4,
    colliderRadius: 5,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/**********************************/
/*  Iron Spark                    */
/**********************************/

setProjectileData({
    id: Projectile.IronSpark,
    projectileSpriteId: "projectiles/ironSpark/proj_blackSpark",
    projectileSpriteScale: 1.5,
    hasImpactPfx: true,
    impactSpriteId: "pfx/blackSpark/impact_blackSpark",
    impactSpriteScale: 1,
    speedPerSecond: 200,
    lifetimeInSeconds: 1.4,
    colliderRadius: 3,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/**********************************/
/*  Idle Iron Spark                    */
/**********************************/

setProjectileData({
    id: Projectile.IdleIronSpark,
    projectileSpriteId: "projectiles/ironSpark/proj_blackSpark",
    projectileSpriteScale: 6,
    hasImpactPfx: true,
    impactSpriteId: "pfx/blackSpark/impact_blackSpark",
    impactSpriteScale: 1,
    speedPerSecond: 0,
    lifetimeInSeconds: 5,
    colliderRadius: 12,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/**********************************/
/*  Twisted Spark                 */
/**********************************/

setProjectileData({
    id: Projectile.TwistedSpark,
    projectileSpriteId: "projectiles/twistedSpark/proj_twistedSpark",
    projectileSpriteScale: 1,
    hasImpactPfx: true,
    impactSpriteId: "pfx/wandSpark/impact_wandSpark",
    impactSpriteScale: 1,
    speedPerSecond: 100,
    lifetimeInSeconds: 1.7,
    colliderRadius: 4,
    spriteXAnchor: 0.7,
    rotateProjectileSprite: true
});

/**********************************/
/*  Idle Twisted Spark                 */
/**********************************/

setProjectileData({
    id: Projectile.IdleTwistedSpark,
    projectileSpriteId: "projectiles/twistedSpark/proj_twistedSpark",
    projectileSpriteScale: 3,
    hasImpactPfx: true,
    impactSpriteId: "pfx/wandSpark/impact_wandSpark",
    impactSpriteScale: 1,
    speedPerSecond: 0,
    lifetimeInSeconds: 5,
    colliderRadius: 12,
    spriteXAnchor: 0.7,
    rotateProjectileSprite: true
});

/**********************************/
/*  Red Crystal                 */
/**********************************/

setProjectileData({
    id: Projectile.RedCrystal,
    projectileSpriteId: "projectiles/redCrystal/proj_redCrystal",
    projectileSpriteScale: 1.4,
    hasImpactPfx: true,
    impactSpriteId: "pfx/redCrystal/impact_redCrystal",
    impactSpriteScale: 1,
    speedPerSecond: 250,
    lifetimeInSeconds: 1,
    colliderRadius: 6,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/**********************************/
/*  Candle Light                */
/**********************************/

setProjectileData({
    id: Projectile.CandleLight,
    projectileSpriteId: "projectiles/flameSpark/proj_flameSpark",
    projectileSpriteScale: 1,
    hasImpactPfx: true,
    impactSpriteId: "pfx/wandSpark/impact_wandSpark",
    impactSpriteScale: 1,
    speedPerSecond: 220,
    lifetimeInSeconds: 1.5,
    colliderRadius: 4,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/**********************************/
/*  Idle Candle Light                */
/**********************************/

setProjectileData({
    id: Projectile.IdleCandleLight,
    projectileSpriteId: "projectiles/flameSpark/proj_flameSpark",
    projectileSpriteScale: 3,
    hasImpactPfx: true,
    impactSpriteId: "pfx/wandSpark/impact_wandSpark",
    impactSpriteScale: 1,
    speedPerSecond: 0,
    lifetimeInSeconds: 5,
    colliderRadius: 12,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/**********************************/
/*  Lich Skull                    */
/**********************************/

setProjectileData({
    id: Projectile.LichSkull,
    projectileSpriteId: "projectiles/lichSkull/proj_lichSkull",
    projectileSpriteScale: 1.25,
    hasImpactPfx: true,
    impactSpriteId: "pfx/arcaneSpark/impact_arcaneSpark",
    impactSpriteScale: 2,
    speedPerSecond: 150,
    lifetimeInSeconds: 2.5,
    colliderRadius: 5,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: false
});

/**********************************/
/*  Idle Lich Skull                    */
/**********************************/

setProjectileData({
    id: Projectile.IdleLichSkull,
    projectileSpriteId: "projectiles/lichSkull/proj_lichSkull",
    projectileSpriteScale: 2.5,
    hasImpactPfx: true,
    impactSpriteId: "pfx/arcaneSpark/impact_arcaneSpark",
    impactSpriteScale: 2,
    speedPerSecond: 0,
    lifetimeInSeconds: 5,
    colliderRadius: 10,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: false
});

/**********************************/
/*  Black Spark                 */
/**********************************/

setProjectileData({
    id: Projectile.BlackSpark,
    projectileSpriteId: "projectiles/ironSpark/proj_blackSpark",
    projectileSpriteScale: 1,
    hasImpactPfx: true,
    impactSpriteId: "pfx/blackSpark/impact_blackSpark",
    impactSpriteScale: 1,
    speedPerSecond: 220,
    lifetimeInSeconds: 0.5,
    colliderRadius: 4,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/**********************************/
/*  Hibity Spark                  */
/**********************************/

setProjectileData({
    id: Projectile.HibitySpark,
    projectileSpriteId: "projectiles/hibitySpark/proj_hibitySpark",
    projectileSpriteScale: 1,
    hasImpactPfx: true,
    impactSpriteId: "pfx/arcaneSpark/impact_arcaneSpark",
    impactSpriteScale: 1,
    speedPerSecond: 300,
    lifetimeInSeconds: 0.7,
    colliderRadius: 4,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/**********************************/
/*  Ice Spark                     */
/**********************************/

setProjectileData({
    id: Projectile.IceSpark,
    projectileSpriteId: "projectiles/iceSpark/proj_iceSpark",
    projectileSpriteScale: 1.5,
    hasImpactPfx: true,
    impactSpriteId: "pfx/iceSpark/impact_iceSpark",
    impactSpriteScale: 1,
    speedPerSecond: 250,
    lifetimeInSeconds: 0.9,
    colliderRadius: 4,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/**********************************/
/*  FrostWind                     */
/**********************************/

setProjectileData({
    id: Projectile.FrostWind,
    projectileSpriteId: "projectiles/frostWind/proj_frostWind",
    projectileSpriteScale: 1,
    hasImpactPfx: false,
    impactSpriteId: "pfx/iceSpark/impact_iceSpark",
    impactSpriteScale: 1,
    speedPerSecond: 140,
    lifetimeInSeconds: 0.45,
    colliderRadius: 4,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/***************************/
/*  Zap Spark              */
/***************************/

setProjectileData({
    id: Projectile.ZapSpark,
    projectileSpriteId: "projectiles/zapSpark/proj_zapSpark",
    projectileSpriteScale: 1,
    hasImpactPfx: true,
    impactSpriteId: "pfx/zapSpark/impact_zapSpark",
    impactSpriteScale: 1,
    speedPerSecond: 160,
    lifetimeInSeconds: 1.75,
    colliderRadius: 4,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/***************************/
/*  IronFoxSlash           */
/***************************/

setProjectileData({
    id: Projectile.IronFoxSlash,
    projectileSpriteId: "projectiles/foxSlash/proj_foxSlash",
    projectileSpriteScale: 1,
    hasImpactPfx: true,
    impactSpriteId: "projectiles/foxSlash/impact_foxSlash",
    impactSpriteScale: 1,
    speedPerSecond: 150,
    lifetimeInSeconds: 3,
    colliderRadius: 15,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/***************************/
/*  Book of Tongues        */
/***************************/

setProjectileData({
    id: Projectile.BookOfTongues,
    projectileSpriteId: "projectiles/bookOfTongues/proj_bookOfTongues",
    projectileSpriteScale: 1.75,
    hasImpactPfx: true,
    impactSpriteId: "pfx/popFIR",
    impactSpriteScale: 1.25,
    speedPerSecond: 285,
    lifetimeInSeconds: 1.5,
    colliderRadius: 6,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

/***************************/
/*  Deathstick             */
/***************************/

setProjectileData({
    id: Projectile.Deathstick,
    projectileSpriteId: "projectiles/deathstick/proj_deathstick",
    projectileSpriteScale: 0.4,
    hasImpactPfx: true,
    impactSpriteId: "pfx/popFIR",
    impactSpriteScale: 0.25,
    speedPerSecond: 500,
    lifetimeInSeconds: 0.2,
    colliderRadius: 3,
    spriteXAnchor: 0.5,
    rotateProjectileSprite: true
});

import { Vector, Circle } from "sat";
import { NType } from "../SharedNetcodeSchemas";
import { IGameEntityBase, IGameEntityThatUpdates, IGameEntityWithColliders, NetworkEntityId } from "../SharedTypes";
import { CollisionGridCell } from "../systems/SharedCollisionSystem";
import { clampToWorldBounds } from "../SharedUtils";
import { lootConfig } from "../config/Config_Loot";

export class SharedItemContainer implements IGameEntityBase, IGameEntityWithColliders, IGameEntityThatUpdates {
    public nid: NetworkEntityId = -1;
    public ntype: NType = NType.ItemContainerEntity;
    public position: Vector = new Vector(0, 0);
    public currentCollisionGridCell: CollisionGridCell;
    public HitboxCollider: Circle = new Circle(new Vector(0, 0), 0);

    public constructor(x: number, y: number) {
        this.x = clampToWorldBounds(x);
        this.y = clampToWorldBounds(y);
        this.HitboxCollider = new Circle(new Vector(x, y), lootConfig.ITEM_CONTAINER_COLLIDER_RADIUS);
    }

    public get x(): number {
        return this.position.x;
    }

    public set x(newX: number) {
        this.position.x = newX;
        this.HitboxCollider.pos.x = this.x;
    }

    public get y(): number {
        return this.position.y;
    }

    public set y(newY: number) {
        this.position.y = newY;
        this.HitboxCollider.pos.y = this.y;
    }

    public Update(__deltaTime: number, __currentTimeInMilliseconds?: number | undefined): void {}
}

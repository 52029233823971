import React from "react";
import { useRecoilValue } from "recoil";
import { fullScreenErrorStateAtom } from "../framework/UI_State";
import { reloadClient } from "../../utils/ClientReload";
import { t } from "../../../shared/data/Data_I18N";

export const FullScreenError = () => {
    const { message: errorMessage, reason } = useRecoilValue(fullScreenErrorStateAtom);
    return (
        <div className="full-screen-error">
            <h2>{t("connection_error__oof")}</h2>
            <p>{errorMessage}</p>
            {reason !== "" && (
                <p>
                    {t("connection_error__error_reason")}: {reason}
                </p>
            )}
            <button
                className="destructive-button"
                style={{ fontSize: "24px", padding: "0 20px", margin: "0" }}
                onClick={() => {
                    reloadClient(false);
                }}
            >
                {t("settings__back_to_main_menu_button")}
            </button>
        </div>
    );
};

import { AnimatedSprite, Container } from "pixi.js";
import { IRenderedEntity } from "../ClientTypes";
import { isFacingLeftBasedOnRads } from "../../shared/SharedUtils";

export class ClientOneOffPfx implements IRenderedEntity {
    public pfxType: number;
    private spriteId: string;
    private spriteScale: number;
    private pfxSprite: AnimatedSprite;
    private animationSpeed = 1 / 4;
    private overrideAnimationSpeed: number | undefined;
    public RenderedEntity: Container = new Container();

    public constructor(spawnPositionX: number, spawnPositionY: number, pfxSpriteId: string, pfxSpriteScale: number, rotation?: number, overrideAnimationSpeed?: number) {
        // console.log("playing one-off pfx at location with parameters");
        // console.log("spawnPositionX: ", spawnPositionX);
        // console.log("spawnPositionY: ", spawnPositionY);
        // console.log("pfxSpriteId: ", pfxSpriteId);
        // console.log("pfxSpriteScale: ", pfxSpriteScale);
        // console.log("rotation: ", rotation);

        this.spriteId = pfxSpriteId;
        this.spriteScale = pfxSpriteScale;
        this.RenderedEntity.x = spawnPositionX;
        this.RenderedEntity.y = spawnPositionY;
        this.RenderedEntity.zIndex = spawnPositionY;

        // console.log("rotation in ClientOneOffPfx constructor: ", rotation);

        if (overrideAnimationSpeed) {
            this.overrideAnimationSpeed = overrideAnimationSpeed;
        }

        try {
            this._setupPfxVisuals();
        } catch (e) {
            console.error("Caught error trying to set up pfx visuals in ClientOneOffPfx constructor: ", e);
        }

        if (rotation) {
            if (isFacingLeftBasedOnRads(rotation)) {
                this.RenderedEntity.scale.x = -1;
            }
        }
    }

    private _setupPfxVisuals(): void {
        const pfxAnimation = Game.Loader.GetAnimation(this.spriteId);

        this.pfxSprite = AnimatedSprite.fromFrames(pfxAnimation);
        this.pfxSprite.loop = false;
        this.pfxSprite.onComplete = this._destroy.bind(this);
        this.pfxSprite.scale.set(this.spriteScale);
        this.pfxSprite.anchor.set(0.5, 0.5);

        if (this.overrideAnimationSpeed) {
            this.pfxSprite.animationSpeed = this.overrideAnimationSpeed;
        } else {
            this.pfxSprite.animationSpeed = this.animationSpeed;
        }

        this.pfxSprite.play();
        this.RenderedEntity.addChild(this.pfxSprite);
    }

    private _destroy(): void {
        Game.Renderer.RemoveRenderedEntity(this);
    }

    public Update(__deltaTime: number): void {}
}

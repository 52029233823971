import { enemyConfig } from "../config/Config_Enemy";
import { DamageType, LootTable } from "./Data_Items";
import { Projectile } from "./Data_Projectiles";

export enum EnemySkin {
    None = -1,
    Ghost = 0,
    Skeleton = 1,
    Ogre = 2,
    GiantRat = 3,
    Treant = 4,
    GRONK = 5,
    DarkWizard = 6,
    IronFox = 7
}

export enum EnemyClass {
    Regular = 0,
    Boss = 1
}

export interface EnemyConfig {
    friendlyName: string;
    enemyClass: EnemyClass;
    staticSpriteId: string;
    idleFramesSpriteId: string;
    walkFramesSpriteId: string;
    attackFramesSpriteId: string;
    spriteYOffset: number;
    respawnTimeInMilliseconds: number;
    colliderYOffset: number;
    spriteScale: number;
    attackProjectileId: Projectile;
    health: number;
    attackDelay: number;
    attackInterval: number;
    numProjectilesPerAttack: number;
    attackDamage: number;
    attackDamageType: DamageType;
    lootDropChance: number;
    lootTable: LootTable;
    hitboxWidth: number;
    hitboxHeight: number;
}

export const EnemySkinData: Map<EnemySkin, EnemyConfig> = new Map();

EnemySkinData.set(EnemySkin.Ghost, {
    friendlyName: "Ghost",
    staticSpriteId: "sprites/enemies/ANIM_ghost01_base.png",
    idleFramesSpriteId: "enemies/ANIM_ghost01_idle",
    walkFramesSpriteId: "enemies/ANIM_ghost01_walk",
    attackFramesSpriteId: "enemies/ANIM_ghost01_attack",
    attackProjectileId: Projectile.BasicBlue,
    spriteYOffset: -15,
    spriteScale: 1,
    health: 30,
    attackDelay: 0,
    attackInterval: 0.5,
    numProjectilesPerAttack: 4,
    attackDamage: 3,
    attackDamageType: DamageType.Shadow,
    lootDropChance: 0.5,
    hitboxWidth: 20,
    hitboxHeight: 23,
    enemyClass: EnemyClass.Regular,
    colliderYOffset: -15,
    respawnTimeInMilliseconds: enemyConfig.DEBUG.INSTANT_RESPAWNS ? 1 : 15000,
    lootTable: LootTable.Ghost
});

EnemySkinData.set(EnemySkin.Skeleton, {
    friendlyName: "Skeleton",
    staticSpriteId: "sprites/enemies/ANIM_Skeleton01_base.png",
    idleFramesSpriteId: "enemies/ANIM_Skeleton01_idle",
    walkFramesSpriteId: "enemies/ANIM_Skeleton01_walk",
    attackFramesSpriteId: "enemies/ANIM_Skeleton01_attack",
    attackProjectileId: Projectile.FireSkull,
    spriteYOffset: -13,
    spriteScale: 1,
    health: 35,
    attackDelay: 0,
    attackInterval: 0.75,
    numProjectilesPerAttack: 2,
    attackDamage: 7,
    attackDamageType: DamageType.Fire,
    lootDropChance: 0.6,
    hitboxWidth: 15,
    hitboxHeight: 25,
    enemyClass: EnemyClass.Regular,
    colliderYOffset: -10,
    respawnTimeInMilliseconds: enemyConfig.DEBUG.INSTANT_RESPAWNS ? 1 : 15000,
    lootTable: LootTable.Skeleton
});

EnemySkinData.set(EnemySkin.Ogre, {
    friendlyName: "Ogre",
    staticSpriteId: "sprites/enemies/ANIM_bigGoblin_idle001.png",
    idleFramesSpriteId: "enemies/ANIM_bigGoblin_idle",
    walkFramesSpriteId: "enemies/ANIM_bigGoblin_walk",
    attackFramesSpriteId: "enemies/ANIM_bigGoblin_attack",
    attackProjectileId: Projectile.Boulder,
    spriteYOffset: -18,
    spriteScale: 1,
    health: 55,
    attackDelay: 0,
    attackInterval: 2,
    numProjectilesPerAttack: 1,
    attackDamage: 15,
    attackDamageType: DamageType.Physical,
    lootDropChance: 0.7,
    hitboxWidth: 22,
    hitboxHeight: 30,
    enemyClass: EnemyClass.Regular,
    colliderYOffset: -11,
    respawnTimeInMilliseconds: enemyConfig.DEBUG.INSTANT_RESPAWNS ? 1 : 15000,
    lootTable: LootTable.Ogre
});

EnemySkinData.set(EnemySkin.GiantRat, {
    friendlyName: "Giant Rat",
    staticSpriteId: "sprites/enemies/ANIM_giantRat_idle001.png",
    idleFramesSpriteId: "enemies/ANIM_giantRat_idle",
    walkFramesSpriteId: "enemies/ANIM_giantRat_walk",
    attackFramesSpriteId: "enemies/ANIM_giantRat_attack",
    attackProjectileId: Projectile.ToxicClouds,
    spriteYOffset: -9,
    spriteScale: 1,
    health: 30,
    attackDelay: 0,
    attackInterval: 0.5,
    numProjectilesPerAttack: 5,
    attackDamage: 3,
    attackDamageType: DamageType.Toxic,
    lootDropChance: 0.5,
    hitboxWidth: 15,
    hitboxHeight: 15,
    enemyClass: EnemyClass.Regular,
    colliderYOffset: -7,
    respawnTimeInMilliseconds: enemyConfig.DEBUG.INSTANT_RESPAWNS ? 1 : 15000,
    lootTable: LootTable.Rat
});

EnemySkinData.set(EnemySkin.Treant, {
    friendlyName: "Treant",
    staticSpriteId: "sprites/enemies/ANIM_Treant_idle001.png",
    idleFramesSpriteId: "enemies/ANIM_Treant_idle",
    walkFramesSpriteId: "enemies/ANIM_Treant_walk",
    attackFramesSpriteId: "enemies/ANIM_Treant_attack",
    attackProjectileId: Projectile.MagicLeaf,
    spriteYOffset: -20,
    spriteScale: 1,
    health: 55,
    attackDelay: 0,
    attackInterval: 0.5,
    numProjectilesPerAttack: 4,
    attackDamage: 4,
    attackDamageType: DamageType.Nature,
    lootDropChance: 0.5,
    hitboxWidth: 17,
    hitboxHeight: 35,
    enemyClass: EnemyClass.Regular,
    colliderYOffset: -15,
    respawnTimeInMilliseconds: enemyConfig.DEBUG.INSTANT_RESPAWNS ? 1 : 15000,
    lootTable: LootTable.Treant
});

EnemySkinData.set(EnemySkin.GRONK, {
    friendlyName: "GRONK",
    staticSpriteId: "sprites/enemies/ANIM_bigGoblin_idle001.png",
    idleFramesSpriteId: "enemies/ANIM_bigGoblin_idle",
    walkFramesSpriteId: "enemies/ANIM_bigGoblin_walk",
    attackFramesSpriteId: "enemies/ANIM_bigGoblin_attack",
    attackProjectileId: Projectile.GRONKBoulder,
    spriteYOffset: -40,
    spriteScale: 2,
    health: 1225,
    attackDelay: 0,
    attackInterval: 0.5,
    numProjectilesPerAttack: 5,
    attackDamage: 10,
    attackDamageType: DamageType.Physical,
    lootDropChance: 0,
    hitboxWidth: 30,
    hitboxHeight: 45,
    enemyClass: EnemyClass.Boss,
    colliderYOffset: -25,
    respawnTimeInMilliseconds: enemyConfig.DEBUG.INSTANT_RESPAWNS ? 1 : 600000, // 10 minutes
    lootTable: LootTable.GRONK
});

EnemySkinData.set(EnemySkin.DarkWizard, {
    friendlyName: "Dark Wizard",
    staticSpriteId: "sprites/enemies/darkwizard_idle1.png",
    idleFramesSpriteId: "enemies/darkwizard_idle",
    walkFramesSpriteId: "enemies/darkwizard_walk",
    attackFramesSpriteId: "enemies/darkwizard_attack",
    attackProjectileId: Projectile.LichSkull,
    spriteYOffset: -20,
    spriteScale: 1,
    health: 55,
    attackDelay: 0,
    attackInterval: 0.7,
    numProjectilesPerAttack: 3,
    attackDamage: 6,
    attackDamageType: DamageType.Chaos,
    lootDropChance: 0.5,
    hitboxWidth: 17,
    hitboxHeight: 35,
    enemyClass: EnemyClass.Regular,
    colliderYOffset: -15,
    respawnTimeInMilliseconds: enemyConfig.DEBUG.INSTANT_RESPAWNS ? 1 : 15000,
    lootTable: LootTable.DarkWizard
});

EnemySkinData.set(EnemySkin.IronFox, {
    friendlyName: "Iron Fox",
    staticSpriteId: "sprites/enemies/ANIM_ironFox_idle001.png",
    idleFramesSpriteId: "enemies/ANIM_ironFox_idle",
    walkFramesSpriteId: "enemies/ANIM_ironFox_walk",
    attackFramesSpriteId: "enemies/ANIM_ironFox_attack",
    attackProjectileId: Projectile.IronFoxSlash,
    spriteYOffset: -35,
    spriteScale: 1,
    health: 1750,
    attackDelay: 0,
    attackInterval: 1,
    numProjectilesPerAttack: 2,
    attackDamage: 25,
    attackDamageType: DamageType.Shadow,
    lootDropChance: 0,
    hitboxWidth: 25,
    hitboxHeight: 55,
    enemyClass: EnemyClass.Boss,
    colliderYOffset: -32,
    respawnTimeInMilliseconds: enemyConfig.DEBUG.INSTANT_RESPAWNS ? 1 : 600000, // 10 minutes
    lootTable: LootTable.IronFox
});

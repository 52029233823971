import React from "react";
import { useRecoilValue } from "recoil";
import { extractionSuccessfulStateAtom, extractionTimerStateAtom, youDiedStateAtom } from "../framework/UI_State";
import { extractionConfig } from "../../../shared/config/Config_Extraction";
import { clamp, getUserFriendlyRunDurationFromSeconds } from "../../../shared/SharedUtils";
import { t } from "../../../shared/data/Data_I18N";

export const ExtractionTimeRemaining = () => {
    const { show: playerHasDied } = useRecoilValue(youDiedStateAtom);
    const { show: playerHasExtracted } = useRecoilValue(extractionSuccessfulStateAtom);
    const { timeRemainingInSeconds } = useRecoilValue(extractionTimerStateAtom);
    const maxTime = extractionConfig.MAX_RUN_DURATION_IN_SECONDS;
    const friendlyTimeRemainingInSeconds = getUserFriendlyRunDurationFromSeconds(timeRemainingInSeconds, false);

    const percentageOfTimeRemaining = clamp(Math.floor((timeRemainingInSeconds / maxTime) * 100), 0, 100);

    const closeToExpiry = percentageOfTimeRemaining < 20;

    return percentageOfTimeRemaining === 0 || timeRemainingInSeconds === 0 || playerHasDied || playerHasExtracted ? (
        <></>
    ) : (
        <div className={`extraction-time-remaining animate__animated animate__fadeInDown ${closeToExpiry ? " close-to-expiry" : ""}`}>
            <h1 className={closeToExpiry ? "animate__animated animate__infinite animate__flash" : ""}>
                {friendlyTimeRemainingInSeconds} {t("game_hud__time_left_to_extract")}
            </h1>
            <h2 className="animate__animated animate__infinite animate__slower animate__flash">{t("game_hud__find_an_extraction_point")}</h2>
        </div>
    );
};

import React from "react";
import { Screens, UI_SwapScreen, matchmakingErrorStateAtom } from "../framework/UI_State";
import { useRecoilValue } from "recoil";
import { t } from "../../../shared/data/Data_I18N";

export const MatchmakingError = () => {
    const { message, title } = useRecoilValue(matchmakingErrorStateAtom);
    return (
        <>
            <div className="matchmaking-error-panel animate__animated animate__fadeInUp animate__faster">
                {/* <div className="matchmaking-error-header">Can't start run!</div> */}

                <h3>{title}</h3>

                <p>{message}</p>

                <div className="abandon-actions">
                    <button
                        className="neutral-button"
                        onClick={() => {
                            UI_SwapScreen(Screens.MainMenu);
                        }}
                    >
                        {t("settings__back_to_main_menu_button")}
                    </button>
                </div>
            </div>
        </>
    );
};

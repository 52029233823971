import React from "react";
import { t } from "../../../shared/data/Data_I18N";
import { reloadClient } from "../../utils/ClientReload";
import { Screens, UI_SwapScreen } from "../framework/UI_State";

export const PurchaseSuccessful = () => {
    return (
        <>
            <div className="purchase-successful-panel animate__animated animate__fadeInUp animate__faster">
                <div className="purchase-successful-header">Thank you!</div>

                <p>Your purchase has been added to your account!</p>

                <p>Thank you for supporting us!</p>

                <button
                    className="neutral-button"
                    onClick={() => {
                        window.location.reload();
                    }}
                >
                    {t("stats__back_btn")}
                </button>
            </div>
        </>
    );
};

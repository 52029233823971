export const PR = 4;
export const WIDTH = 50 * PR;
export const HEIGHT = 30 * PR;
export const TEXT_X = 7;
export const TEXT_Y = 7;
export const GRAPH_X = TEXT_X;
export const FONT_SIZE = 20; // tested @ 120.0 FPS (120~120)
export const GRAPH_Y = FONT_SIZE + TEXT_Y;
export const GRAPH_WIDTH = WIDTH - GRAPH_X * 2;
export const GRAPH_HEIGHT = HEIGHT - GRAPH_X - GRAPH_Y;

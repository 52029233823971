import React from "react";
import { t } from "../../../shared/data/Data_I18N";

export const FindingMatch = () => {
    return (
        <div className="finding-match">
            <div className="finding-match-panel animate__animated animate__infinite animate__jello">
                <h2>{t("game_transition__finding_match")}</h2>
            </div>
        </div>
    );
};

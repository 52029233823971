import { MAJOR_GAME_VERSION } from "../../shared/config/Config_Game";

export const getLocalStoragePrefix = (version: string) => {
    return `pvpzio::${version}::`;
};

export enum LSKeys {
    AuthToken = "authToken",
    AnonymousUserId = "anonymousUserId",
    TermsAndPrivacyPolicyAccepted = "termsAndPrivacyPolicyAccepted",
    PromotionAccepted = "promotionAccepted"
}

export const localStorageContainsKey = (key: LSKeys): boolean => {
    return localStorage.getItem(`${getLocalStoragePrefix(MAJOR_GAME_VERSION)}${key}`) !== null;
};

export const saveToLocalStorage = (key: LSKeys, value: string): string => {
    localStorage.setItem(`${getLocalStoragePrefix(MAJOR_GAME_VERSION)}${key}`, value);
    return value;
};

export const loadFromLocalStorage = (key: LSKeys): string | null => {
    const retrievedFromStore = localStorage.getItem(`${getLocalStoragePrefix(MAJOR_GAME_VERSION)}${key}`);
    return retrievedFromStore;
};

export const loadFromOldLocalStorage = (key: LSKeys, version: number): string | null => {
    const retrievedFromStore = localStorage.getItem(`${getLocalStoragePrefix(version.toString())}${key}`);
    return retrievedFromStore;
};

export const deleteFromOldLocalStorage = (key: LSKeys, version: number): void => {
    return localStorage.removeItem(`${getLocalStoragePrefix(version.toString())}${key}`);
};

export const deleteFromLocalStorage = (key: LSKeys): void => {
    return localStorage.removeItem(`${getLocalStoragePrefix(MAJOR_GAME_VERSION)}${key}`);
};

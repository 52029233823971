import React from "react";
import { useRecoilValue } from "recoil";
import { assetLoadingStateAtom } from "../framework/UI_State";
import { t } from "../../../shared/data/Data_I18N";

export const LoadingBar = () => {
    const loadingState = useRecoilValue(assetLoadingStateAtom);

    const { progress } = loadingState;

    return (
        <div className="loading-bar">
            <div className="loading-bar-progress" style={{ width: `${progress}%` }}></div>
            <div className="loading-bar-text">
                {t("main_menu__loading_bar_text")}: {progress}%
            </div>
        </div>
    );
};

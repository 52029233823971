import { atom, selector } from "recoil";
import { getRecoil, setRecoil } from "recoil-nexus";
import { AnonymousUser, PlayerLoadout, RegisteredUser, ServerEvent, SupportedLanguages, SupportedMatchmakingRegions, UserType } from "../../../shared/SharedTypes";
import { emailRegex, getUserFriendlyRunDurationFromSeconds } from "../../../shared/SharedUtils";
import { abandonRunRequest, anonymousUserRegisterEmailRequest, buyInsuranceWithCoinsRequest, buyInsuranceWithGemsRequest, buySkinWithGemsRequest, changeUsernameRequest, checkMaintenanceModeRequest, equipItemToSlotRequest, equipSkinRequest, getPurchaseLinkForSKURequest, insureSlotRequest, savePreferencesRequest, userLoginRequest } from "../../backend/ClientRequests";
import { PopToast } from "../kit/toast-utils";
import { ToastStatuses } from "../kit/toast-utils";
import { ItemContainerMap, ItemType, Item, getItemContainerMap, getItemData } from "../../../shared/data/Data_Items";
import { LoadoutSlots } from "../../../shared/systems/SharedGearAndWeaponSystem";
import { CharacterSkin } from "../../../shared/data/Data_Cosmetics";
import { LoadoutFromServerMessage, ItemsLootedOnRunUpdatedMessage, OpenedOrUpdatedItemContainerMessage } from "../../../shared/SharedNetcodeSchemas";
import { reloadClient } from "../../utils/ClientReload";
import { extractionConfig } from "../../../shared/config/Config_Extraction";
import { EmailAlreadyTakenError, EmailInvalidError, EmailNotFoundError, IncorrectLoginMethodError, InvalidPasswordError, UsernameAlreadyTakenError, UsernameInappropriateError } from "../../backend/ClientAPIErrors";
import { t } from "../../../shared/data/Data_I18N";
import { LSKeys, deleteFromLocalStorage, loadFromLocalStorage, saveToLocalStorage } from "../../utils/ClientLocalStorage";

//#region Types
/*******************************************/
/*                                         */
/*  Types                                  */
/*                                         */
/*******************************************/
export enum UIModes {
    Menus = "Menus",
    InGame = "InGame"
}

export enum Screens {
    MainMenu = "MainMenu",
    Settings = "Settings",
    Rankings = "Rankings",
    Vendors = "Vendors",
    HowToPlay = "HowToPlay",
    RunPrep = "RunPrep",
    KitTest = "KitTest",
    RunInProgress = "RunInProgress",
    FindingMatch = "FindingMatch",
    ChangeUsername = "ChangeUsername",
    Inventory = "Inventory",
    Stats = "Stats",
    ItemTest = "ItemTest",
    MatchmakingError = "MatchmakingError",
    Partners = "Partners",
    Register = "Register",
    Login = "Login",
    PatchNotes = "PatchNotes",
    CollectionLog = "CollectionLog",
    Shop = "Shop",
    BundleDetails = "BundleDetails",
    PurchaseSuccessful = "PurchaseSuccessful",
    ConfirmSkinPurchase = "ConfirmSkinPurchase",
    ConfirmInsurancePurchase = "ConfirmInsurancePurchase",
    ConfirmCoinInsurancePurchase = "ConfirmCoinInsurancePurchase"
}
//#endregion

//#region UI State
/*******************************************/
/*                                         */
/*  UI State                               */
/*                                         */
/*******************************************/
export const uiModeStateAtom = atom({
    key: "uiModeState",
    default: UIModes.Menus
});

export const selectedSkinToPurchaseStateAtom = atom({
    key: "selectedSkinToPurchaseState",
    default: CharacterSkin.None
});

export const UI_SetSelectedSkinToPurchase = (skinId: CharacterSkin) => {
    setRecoil(selectedSkinToPurchaseStateAtom, skinId);
};

export const selectedBundleStateAtom = atom({
    key: "selectedBundleState",
    default: "none"
});

export const UI_SetSelectedBundle = (bundleId: string) => {
    setRecoil(selectedBundleStateAtom, bundleId);
};

export const intentionalDisconnectState = atom({
    key: "intentionalDisconnectState",
    default: false
});

export const currentScreenStateAtom = atom({
    key: "currentScreenState",
    default: Screens.MainMenu
});

export const changeUsernameInputStateAtom = atom({
    key: "changeUsernameInputState",
    default: ""
});

export const registerStateAtom = atom({
    key: "registerState",
    default: {
        loading: false
    }
});

const currentPromoValueFromLS = loadFromLocalStorage(LSKeys.PromotionAccepted);

const currentPromoValueFromLSBool = currentPromoValueFromLS === "true";

export const registerCheckboxesStateAtom = atom({
    key: "registerCheckboxesState",
    default: {
        emailPromo: currentPromoValueFromLSBool
    }
});

export const loginStateAtom = atom({
    key: "loginState",
    default: {
        loading: false
    }
});

export const latestPingStateAtom = atom({
    key: "latestPingState",
    default: 0
});

export const identityErrorStateAtom = atom({
    key: "identityErrorState",
    default: {
        show: false,
        message: "Unknown error"
    }
});

export const abandonRunStateAtom = atom({
    key: "abandonRunState",
    default: {
        abandoningInProgress: false
    }
});

export const matchmakingErrorStateAtom = atom({
    key: "matchmakingErrorState",
    default: {
        title: "...",
        message: "..."
    }
});

export const maintenanceModeStateAtom = atom({
    key: "maintenanceModeState",
    default: {
        checkingMaintenanceModeStatusInProgress: true,
        isInmaintenanceMode: false
    }
});

export const regionStateAtom = atom({
    key: "regionState",
    default: {
        shown: false,
        regionName: ""
    }
});

export const serverEventFeedStateAtom = atom({
    key: "serverEventFeedState",
    default: {
        events: []
    }
});

export const playerIdentityStateAtom = atom({
    key: "userState",
    default: {
        userType: UserType.Anonymous,
        loadingPlayerIdentity: true,
        userDetails: {
            _id: "...",
            userType: UserType.Anonymous,
            username: "...",
            createdAt: new Date(),

            SMG_Stats: {
                logins: 0,
                lastLoggedIn: new Date()
            },

            PVPZ_GameState: {
                currentRunId: "...",
                currentlyOnARun: false
            },

            PVPZ_Stats: {
                runsEmbarkedOn: 0,
                successfulExtractions: 0,
                failedExtractions: 0,
                goldLooted: 0,
                itemsLooted: 0,
                playerKills: 0,
                npcKills: 0,
                timeSpentOnRunsInSeconds: 0,
                distanceTravelled: 0,
                commonItemsLooted: 0,
                uncommonItemsLooted: 0,
                rareItemsLooted: 0,
                epicItemsLooted: 0,
                legendaryItemsLooted: 0
            },

            PVPZ_Stash: getItemContainerMap(),

            PVPZ_Preferences: {
                enableMusic: true,
                enableSoundEffects: true,
                preferredLanguage: "en",
                preferredMatchmakingRegion: "any"
            },

            PVPZ_Loadout: {
                characterSkin: -1,
                weaponOne: -1,
                weaponTwo: -1,
                gearOne: -1,
                gearTwo: -1,
                gearThree: -1,
                insuredSlot: -1
            }
        } as RegisteredUser | AnonymousUser
    }
});

export const extractionInProgressStateAtom = atom({
    key: "extractionInProgressState",
    default: {
        extractionProgress: 0
    }
});

export const loadoutCooldownStateAtom = atom({
    key: "loadoutCooldownState",
    default: {
        weaponOneCooldown: 100,
        weaponTwoCooldown: 100,
        gearOneCooldown: 100,
        gearTwoCooldown: 100,
        gearThreeCooldown: 100
    }
});

export const loadoutChargesStateAtom = atom({
    key: "loadoutChargesState",
    default: {
        gearOneCharges: -1,
        gearTwoCharges: -1,
        gearThreeCharges: -1
    }
});

export const activeWeaponSlotStateAtom = atom({
    key: "activeWeaponSlotState",
    default: LoadoutSlots.WeaponOne
});

export const inGameLeaderboardState = atom({
    key: "inGameLeaderboardState",
    default: {
        show: true,
        entries: []
    }
});

export const spectatingStateAtom = atom({
    key: "spectatingState",
    default: {
        spectating: false,
        spectatingPlayerName: "..."
    }
});

export const extractionTimerStateAtom = atom({
    key: "extractionTimerState",
    default: {
        timeRemainingInSeconds: extractionConfig.MAX_RUN_DURATION_IN_SECONDS
    }
});

export const debugStateAtom = atom({
    key: "debugState",
    default: {
        skippingMenu: window.location.href.includes("skipMenu")
    }
});

export const shopActionState = atom({
    key: "shopActionState",
    default: {
        shopActionInProgress: false
    }
});

export const assetLoadingStateAtom = atom({
    key: "loadingState",
    default: {
        loadingAssets: true,
        progress: 0
    }
});

export const extractionSuccessfulStateAtom = atom({
    key: "extractionSuccessfulState",
    default: {
        show: false,
        loading: false,
        goldLooted: 0,
        runDuration: "..."
    }
});

export const youDiedStateAtom = atom({
    key: "youDiedState",
    default: {
        show: false,
        loading: false,
        killerName: "Loading...",
        runDuration: "Loading...",
        goldLost: "..."
    }
});

export const fullScreenErrorStateAtom = atom({
    key: "fullScreenErrorState",
    default: {
        show: false,
        message: "Lost connection to server!",
        reason: ""
    }
});

export const playerLoadoutStateAtom = atom({
    key: "playerLoadoutState",
    default: {
        playerLoadoutOperationInProgress: false,
        loadout: {
            characterSkin: -1,
            weaponOne: -1,
            weaponTwo: -1,
            gearOne: -1,
            gearTwo: -1,
            gearThree: -1,
            insuredSlot: -1
        },
        loadoutScreen: {
            selectionMode: "none"
        }
    }
});

export const itemsLootedOnRunStateAtom = atom({
    key: "itemsLootedOnRunState",
    default: {
        showInventoryPanel: false,
        items: getItemContainerMap()
    }
});

export const lootedItemContainerState = atom({
    key: "lootedItemContainerState",
    default: {
        showPanel: false,
        itemContainerName: "Loot Chest",
        itemContainerId: "",
        items: getItemContainerMap()
    }
});

export const playerInventoryStateAtom = atom({
    key: "playerInventoryState",
    default: {
        inventoryOperationInProgress: false,
        items: getItemContainerMap()
    }
});

export const playerPreferencesStateAtom = atom({
    key: "playerPreferencesState",
    default: {
        savingPreferencesInProgress: true,
        fromServer: {
            enableMusic: false,
            enableSoundEffects: false,
            preferredLanguage: "en",
            preferredMatchmakingRegion: "any"
        },
        local: {
            enableMusic: false,
            enableSoundEffects: false,
            preferredLanguage: "en",
            preferredMatchmakingRegion: "any"
        }
    }
});

//#endregion

//#region Selectors
/**********************************************/
/*                                            */
/* Selectors (filtered versions of the state) */
/*                                            */
/**********************************************/
export const justWeaponsFromStashSelector = selector({
    key: "JustWeaponsFromStash",
    get: ({ get }) => {
        const allItems = get(playerInventoryStateAtom);
        const weapons: ItemContainerMap = {} as ItemContainerMap;
        for (const key in allItems.items) {
            const itemConfig = getItemData(parseInt(key))!;
            if (itemConfig.itemType === ItemType.Weapon) {
                // @ts-ignore
                weapons[key] = allItems.items[key];
            }
        }
        return weapons;
    }
});

export const justGearFromStashSelector = selector({
    key: "JustGearFromStash",
    get: ({ get }) => {
        const allItems = get(playerInventoryStateAtom);
        const gear: ItemContainerMap = {} as ItemContainerMap;
        for (const key in allItems.items) {
            const itemConfig = getItemData(parseInt(key))!;
            if (itemConfig.itemType === ItemType.ActiveGear || itemConfig.itemType === ItemType.PassiveGear) {
                // @ts-ignore
                gear[key] = allItems.items[key];
            }
        }
        return gear;
    }
});

export const justVendorTrashFromStashSelector = selector({
    key: "JustVendorTrashFromStash",
    get: ({ get }) => {
        const allItems = get(playerInventoryStateAtom);
        const vendorTrash: ItemContainerMap = {} as ItemContainerMap;
        for (const key in allItems.items) {
            const itemConfig = getItemData(parseInt(key))!;
            if (itemConfig.itemType === ItemType.VendorTrash) {
                // @ts-ignore
                vendorTrash[key] = allItems.items[key];
            }
        }
        return vendorTrash;
    }
});

export const justCollectorsItemsFromStashSelector = selector({
    key: "JustCollectorsItemsFromStash",
    get: ({ get }) => {
        const allItems = get(playerInventoryStateAtom);
        const collectorsItems: ItemContainerMap = {} as ItemContainerMap;
        for (const key in allItems.items) {
            const itemConfig = getItemData(parseInt(key))!;
            if (itemConfig.itemType === ItemType.CollectorsItem) {
                // @ts-ignore
                collectorsItems[key] = allItems.items[key];
            }
        }
        return collectorsItems;
    }
});

export const profileStatsSelector = selector({
    key: "ProfileStats",
    get: ({ get }) => {
        const { PVPZ_Stats } = get(playerIdentityStateAtom).userDetails;
        return PVPZ_Stats;
    }
});

export const quantityOfItemsInRunInventorySelector = selector({
    key: "QuantityOfItemsInRunInventory",
    get: ({ get }) => {
        const { items } = get(itemsLootedOnRunStateAtom);
        let totalItems = 0;
        for (const [_, quantity] of Object.entries(items)) {
            if (quantity > 0) totalItems += quantity;
        }
        return totalItems;
    }
});
//#endregion

//#region External State Accessors
/*******************************************/
/*                                         */
/* External State Accessors (for game use) */
/*                                         */
/*******************************************/

export const UI_ItemContainerUIIsOpen = () => {
    const currentState = getRecoil(lootedItemContainerState);
    const { showPanel } = currentState;
    return showPanel;
};

export const UI_DisconnectIsIntentional = () => {
    const isDisconnectIntentional = getRecoil(intentionalDisconnectState);
    return isDisconnectIntentional;
};

//#endregion

//#region External State Mutators
/*******************************************/
/*                                         */
/* SYNCHRONOUS State Mutation Helpers      */
/*                                         */
/*******************************************/
export const UI_SwapScreen = (newScreen: Screens) => {
    setRecoil(currentScreenStateAtom, newScreen);
};

export const UI_SwapUIMode = (newMode: UIModes) => {
    setRecoil(uiModeStateAtom, newMode);
};

export const UI_SetLoadingProgress = (progress: number) => {
    const currentLoadingState = getRecoil(assetLoadingStateAtom);
    setRecoil(assetLoadingStateAtom, {
        ...currentLoadingState,
        progress
    });
};

const UI_RemoveEventFromServerFeed = (eventId: number) => {
    const currentFeed = getRecoil(serverEventFeedStateAtom);
    const newEvents = currentFeed.events.filter((event: ServerEvent) => event.id !== eventId);
    setRecoil(serverEventFeedStateAtom, {
        events: newEvents
    });
};

export const UI_AddServerEventToFeed = (serverEvent: ServerEvent) => {
    const currentFeed = getRecoil(serverEventFeedStateAtom);

    // console.log("adding server event to feed:", serverEvent);

    setTimeout(() => {
        UI_RemoveEventFromServerFeed(serverEvent.id);
    }, 10000);
    setRecoil(serverEventFeedStateAtom, {
        events: [...currentFeed.events, serverEvent]
    });
};

export const UI_SetLoadingComplete = () => {
    setRecoil(assetLoadingStateAtom, {
        loadingAssets: false,
        progress: 1
    });
};

export const UI_SetAnonymousUserIdentity = (identity: AnonymousUser) => {
    setRecoil(playerIdentityStateAtom, {
        userType: UserType.Anonymous,
        loadingPlayerIdentity: false,
        userDetails: {
            ...identity
        }
    });
};

export const UI_ShowRegionPopUp = (regionName: string) => {
    setRecoil(regionStateAtom, {
        shown: true,
        regionName: t(regionName)
    });
};

export const UI_HideRegionPopUp = () => {
    const currentState = getRecoil(regionStateAtom);
    setRecoil(regionStateAtom, {
        ...currentState,
        shown: false
    });
};

export const UI_UpdateCooldownProgress = (loadoutSlot: LoadoutSlots, newPercentage: number) => {
    if (loadoutSlot === LoadoutSlots.WeaponOne) {
        setRecoil(loadoutCooldownStateAtom, {
            ...getRecoil(loadoutCooldownStateAtom),
            weaponOneCooldown: newPercentage
        });
    } else if (loadoutSlot === LoadoutSlots.WeaponTwo) {
        setRecoil(loadoutCooldownStateAtom, {
            ...getRecoil(loadoutCooldownStateAtom),
            weaponTwoCooldown: newPercentage
        });
    } else if (loadoutSlot === LoadoutSlots.GearOne) {
        setRecoil(loadoutCooldownStateAtom, {
            ...getRecoil(loadoutCooldownStateAtom),
            gearOneCooldown: newPercentage
        });
    } else if (loadoutSlot === LoadoutSlots.GearTwo) {
        setRecoil(loadoutCooldownStateAtom, {
            ...getRecoil(loadoutCooldownStateAtom),
            gearTwoCooldown: newPercentage
        });
    } else if (loadoutSlot === LoadoutSlots.GearThree) {
        setRecoil(loadoutCooldownStateAtom, {
            ...getRecoil(loadoutCooldownStateAtom),
            gearThreeCooldown: newPercentage
        });
    }
};

export const UI_UpdateGearCharges = (loadoutSlot: LoadoutSlots, newCharges: number) => {
    if (loadoutSlot === LoadoutSlots.GearOne) {
        setRecoil(loadoutChargesStateAtom, {
            ...getRecoil(loadoutChargesStateAtom),
            gearOneCharges: newCharges
        });
    } else if (loadoutSlot === LoadoutSlots.GearTwo) {
        setRecoil(loadoutChargesStateAtom, {
            ...getRecoil(loadoutChargesStateAtom),
            gearTwoCharges: newCharges
        });
    } else if (loadoutSlot === LoadoutSlots.GearThree) {
        setRecoil(loadoutChargesStateAtom, {
            ...getRecoil(loadoutChargesStateAtom),
            gearThreeCharges: newCharges
        });
    }
};

export const UI_SetIntentionalDisconnect = (intentional: boolean) => {
    setRecoil(intentionalDisconnectState, intentional);
};

export const UI_SetRegisteredUserIdentity = (identity: RegisteredUser) => {
    setRecoil(playerIdentityStateAtom, {
        userType: UserType.Registered,
        loadingPlayerIdentity: false,
        userDetails: {
            ...identity
        }
    });
    setRecoil(changeUsernameInputStateAtom, identity.username);
};

export const UI_UpdateLatestPing = (newPing: number) => {
    setRecoil(latestPingStateAtom, newPing);
};

export const UI_ShowMatchmakingError = (title: string, message: string) => {
    setRecoil(matchmakingErrorStateAtom, {
        title,
        message
    });
    setRecoil(currentScreenStateAtom, Screens.MatchmakingError);
};

export const UI_ShowFullScreenError = (message: string, reason?: string) => {
    setRecoil(fullScreenErrorStateAtom, {
        show: true,
        message,
        reason: reason !== undefined ? reason : ""
    });
};

export const UI_UserIdentityError = (message: string) => {
    setRecoil(identityErrorStateAtom, {
        show: true,
        message
    });
};

export const UI_ActivateWeaponSlotInHUD = (newActiveSlot: LoadoutSlots) => {
    setRecoil(activeWeaponSlotStateAtom, newActiveSlot);
};

export const UI_UpdatedPreferencesFromServer = (preferences: PlayerPreferences) => {
    setRecoil(playerPreferencesStateAtom, {
        savingPreferencesInProgress: false,
        fromServer: {
            ...preferences
        },
        local: {
            ...preferences
        }
    });
};

export const UI_UpdatedInventoryFromServer = (inventory: ItemContainerMap) => {
    setRecoil(playerInventoryStateAtom, {
        inventoryOperationInProgress: false,
        items: inventory
    });
};

export const UI_LoadoutFromGameServer = (loadout: LoadoutFromServerMessage) => {
    const { loadoutScreen, playerLoadoutOperationInProgress } = getRecoil(playerLoadoutStateAtom);
    // console.log('loadout before:', getRecoil(playerLoadoutStateAtom));
    setRecoil(playerLoadoutStateAtom, {
        playerLoadoutOperationInProgress,
        loadoutScreen,
        loadout: {
            ...loadout
        }
    });
    // setTimeout(() => {
    //     console.log('loadout after:', getRecoil(playerLoadoutStateAtom));
    // }, 1000);
};

export const UI_OpenedItemContainer = (openedItemContainerMsg: OpenedOrUpdatedItemContainerMessage) => {
    const openedItemContainer: ItemContainerMap = {
        [Item.None]: 0
    } as ItemContainerMap;

    for (let i = 0; i < openedItemContainerMsg.items.length; i++) {
        const itemId = openedItemContainerMsg.items[i] as Item;
        const quantity = openedItemContainerMsg.quantities[i];

        // @ts-ignore TODO: this is valid, fix types
        openedItemContainer[itemId] = quantity;
    }

    setRecoil(lootedItemContainerState, {
        showPanel: true,
        itemContainerName: openedItemContainerMsg.containerName,
        itemContainerId: openedItemContainerMsg.itemContainerId,
        items: openedItemContainer
    });
};

export const UI_ItemsLootedOnRunUpdated = (updatedLootedItemsMsg: ItemsLootedOnRunUpdatedMessage) => {
    const itemsLootedOnRunAsContainerMap: ItemContainerMap = {
        [Item.None]: 0
    } as ItemContainerMap;

    for (let i = 0; i < updatedLootedItemsMsg.items.length; i++) {
        const itemId = updatedLootedItemsMsg.items[i] as Item;
        const quantity = updatedLootedItemsMsg.quantities[i];

        // @ts-ignore TODO: this is valid, fix types
        itemsLootedOnRunAsContainerMap[itemId] = quantity;
    }
    const { showInventoryPanel } = getRecoil(itemsLootedOnRunStateAtom);
    setRecoil(itemsLootedOnRunStateAtom, {
        showInventoryPanel: showInventoryPanel,
        items: itemsLootedOnRunAsContainerMap
    });
};

export const UI_ToggleInventoryPanel = () => {
    const { showInventoryPanel, items } = getRecoil(itemsLootedOnRunStateAtom);
    setRecoil(itemsLootedOnRunStateAtom, {
        showInventoryPanel: !showInventoryPanel,
        items: items
    });
};

export const UI_UpdatedLoadoutFromBackend = (loadout: PlayerLoadout) => {
    const { loadoutScreen } = getRecoil(playerLoadoutStateAtom);
    setRecoil(playerLoadoutStateAtom, {
        playerLoadoutOperationInProgress: false,
        loadout,
        loadoutScreen: loadoutScreen
    });
};

export const UI_SetLoadoutSelectionMode = (mode: "none" | "characterSkin" | "weaponOne" | "weaponTwo" | "gearOne" | "gearTwo" | "gearThree") => {
    const { loadoutScreen, loadout, playerLoadoutOperationInProgress } = getRecoil(playerLoadoutStateAtom);
    const { selectionMode } = loadoutScreen;
    setRecoil(playerLoadoutStateAtom, {
        playerLoadoutOperationInProgress,
        loadout,
        loadoutScreen: {
            ...loadoutScreen,
            selectionMode: selectionMode === mode ? "none" : mode
        }
    });
};

export const UI_ShowExtractionSuccessfulpanel = (runDuration: number, goldEarned: number) => {
    const runDurationAsString = getUserFriendlyRunDurationFromSeconds(runDuration);
    setRecoil(extractionSuccessfulStateAtom, {
        show: true,
        loading: true,
        runDuration: runDurationAsString,
        goldLooted: goldEarned
    });
};

export const UI_StopExtractionPanelLoading = () => {
    const currentState = getRecoil(extractionSuccessfulStateAtom);
    setRecoil(extractionSuccessfulStateAtom, {
        ...currentState,
        loading: false
    });
};

export const UI_StopYouDiedPanelLoading = () => {
    const currentState = getRecoil(youDiedStateAtom);
    setRecoil(youDiedStateAtom, {
        ...currentState,
        loading: false
    });
};

export const UI_ShowYouDiedPanel = (killerName: string, runDuration: number, goldLost: number) => {
    const runDurationAsString = getUserFriendlyRunDurationFromSeconds(runDuration);
    const goldLostAsString = goldLost.toString();
    setRecoil(youDiedStateAtom, {
        show: true,
        loading: true,
        killerName,
        runDuration: runDurationAsString,
        goldLost: goldLostAsString
    });
};

export const UI_SetAgreedToPromotion = (agreed: boolean) => {
    const currentState = getRecoil(registerCheckboxesStateAtom);

    saveToLocalStorage(LSKeys.PromotionAccepted, agreed.toString());

    setRecoil(registerCheckboxesStateAtom, {
        ...currentState,
        emailPromo: agreed
    });
};

export const UI_HideYouDiedPanel = () => {
    const currentState = getRecoil(youDiedStateAtom);
    setRecoil(youDiedStateAtom, {
        ...currentState,
        show: false,
        loading: false
    });
};

export const UI_ToggleLeaderboardPanel = () => {
    const currentState = getRecoil(inGameLeaderboardState);
    setRecoil(inGameLeaderboardState, {
        ...currentState,
        show: !currentState.show
    });
};

export const UI_HideLootContainerPanel = () => {
    setRecoil(lootedItemContainerState, {
        itemContainerName: "Loot Chest",
        itemContainerId: "",
        items: getItemContainerMap(),
        showPanel: false
    });
};

export const UI_EnterSpectatorMode = (entityName: string) => {
    setRecoil(spectatingStateAtom, {
        spectating: true,
        spectatingPlayerName: entityName
    });
};

export const UI_UpdateExtractionTimeRemainingTimer = (timeRemainingInSeconds: number) => {
    setRecoil(extractionTimerStateAtom, {
        timeRemainingInSeconds: timeRemainingInSeconds
    });
};

export const UI_UpdateLeaderboardState = (leaderboardState: string) => {
    // console.log("SERVER TOLD US ABOUT UPDATED LEADERBOARD STATE:", leaderboardState);

    const parsedState = JSON.parse(leaderboardState);
    const currentUIState = getRecoil(inGameLeaderboardState);
    setRecoil(inGameLeaderboardState, {
        ...currentUIState,
        entries: parsedState
    });
};

export const UI_UpdateExtractionProgress = (progress: number) => {
    setRecoil(extractionInProgressStateAtom, {
        extractionProgress: progress
    });
};
//#endregion

//#region API Requests That Mutate UI State
/********************************************/
/*                                          */
/* ASYNCHRONOUS State Mutation Helpers      */
/*                                          */
/********************************************/

export interface InsureSlotResponse {
    updatedLoadout: PlayerLoadout;
}
export interface EquipItemToSlotResponse {
    updatedLoadout: PlayerLoadout;
    updatedInventory: ItemContainerMap;
}
export interface EquipSkinResponse {
    updatedLoadout: PlayerLoadout;
}

export const UI_CheckMaintenanceModeStatus = async () => {
    setRecoil(maintenanceModeStateAtom, {
        checkingMaintenanceModeStatusInProgress: true,
        isInmaintenanceMode: false
    });

    try {
        const result = await checkMaintenanceModeRequest();

        let { isInMaintenanceMode } = result;

        if (window.location.href.includes("?bypass=4ed2b85f-4266-4569-a2e8-64d37305226e")) {
            isInMaintenanceMode = false;
        }

        setRecoil(maintenanceModeStateAtom, {
            checkingMaintenanceModeStatusInProgress: false,
            isInmaintenanceMode: isInMaintenanceMode
        });
    } catch (e) {
        setRecoil(maintenanceModeStateAtom, {
            checkingMaintenanceModeStatusInProgress: false,
            isInmaintenanceMode: true
        });

        console.error("Caught error in check maintenance mode");
        console.error(e);
    }
};

export const UI_LoginEmail = async (email: string, password: string): Promise<void> => {
    setRecoil(loginStateAtom, {
        loading: true
    });

    let clientError = false;

    const currentIdentityState = getRecoil(playerIdentityStateAtom);
    setRecoil(playerIdentityStateAtom, {
        ...currentIdentityState,
        loadingPlayerIdentity: true
    });

    if (email.length < 4) {
        PopToast({ status: ToastStatuses.ERROR, message: t("toast__email_not_long_enough") });
        clientError = true;
    }

    if (password.length < 8) {
        PopToast({ status: ToastStatuses.ERROR, message: t("toast__password_not_long_enough") });
        clientError = true;
    }

    if (emailRegex.test(email) === false) {
        PopToast({ status: ToastStatuses.ERROR, message: t("toast__not_an_email_address") });
        clientError = true;
    }

    if (clientError) {
        setRecoil(loginStateAtom, {
            loading: false
        });
        setRecoil(playerIdentityStateAtom, {
            ...currentIdentityState,
            loadingPlayerIdentity: false
        });
        return;
    }

    // console.log("Registering new account with details:");
    // console.log({
    //     email,
    //     password
    // });

    try {
        const result = await userLoginRequest(email, password);

        // console.log("Result of login:", result);

        const { authToken } = result;

        deleteFromLocalStorage(LSKeys.AnonymousUserId);
        saveToLocalStorage(LSKeys.AuthToken, authToken);

        // console.log("check local storage state");

        reloadClient(false);
    } catch (e) {
        setRecoil(loginStateAtom, {
            loading: false
        });

        setRecoil(playerIdentityStateAtom, {
            ...currentIdentityState,
            loadingPlayerIdentity: false
        });
        console.error("Caught error in login");
        console.error(e);
        if (e instanceof EmailInvalidError) {
            PopToast({ status: ToastStatuses.ERROR, message: "That isn't a valid email address!" });
        } else if (e instanceof EmailAlreadyTakenError) {
            PopToast({ status: ToastStatuses.ERROR, message: "That email address is already registered!" });
        } else if (e instanceof IncorrectLoginMethodError) {
            PopToast({ status: ToastStatuses.ERROR, message: "It looks like that account should sign in with a different method. Try again!" });
        } else if (e instanceof InvalidPasswordError) {
            PopToast({ status: ToastStatuses.ERROR, message: "That doesn't look like the right password, try again!" });
        } else if (e instanceof EmailNotFoundError) {
            PopToast({ status: ToastStatuses.ERROR, message: "No user was found with that email address!" });
        } else {
            PopToast({ status: ToastStatuses.ERROR, message: "Something went wrong registering your account!" });
        }
    }
};

export const UI_RegisterEmail = async (username: string, email: string, confirmEmail: string, password: string, confirmPassword: string): Promise<void> => {
    setRecoil(registerStateAtom, {
        loading: true
    });

    let clientError = false;

    const registerCheckboxesState = getRecoil(registerCheckboxesStateAtom);
    const { emailPromo, termsAndPrivacyPolicy } = registerCheckboxesState;

    if (termsAndPrivacyPolicy === false) {
        PopToast({ status: ToastStatuses.ERROR, message: t("register__toast__must_agree_to_terms") });
        clientError = true;
    }

    const currentIdentityState = getRecoil(playerIdentityStateAtom);
    setRecoil(playerIdentityStateAtom, {
        ...currentIdentityState,
        loadingPlayerIdentity: true
    });

    if (username.length < 1) {
        PopToast({ status: ToastStatuses.ERROR, message: t("toast__username_too_short") });
        clientError = true;
    }

    if (password !== confirmPassword) {
        PopToast({ status: ToastStatuses.ERROR, message: t("toast__passwords_dont_match") });
        clientError = true;
    }

    if (email !== confirmEmail) {
        PopToast({ status: ToastStatuses.ERROR, message: t("toast__email_doesnt_match") });
        clientError = true;
    }

    if (email.length < 4 || confirmEmail.length < 4) {
        PopToast({ status: ToastStatuses.ERROR, message: t("toast__email_not_long_enough") });
        clientError = true;
    }

    if (password.length < 8 || confirmPassword.length < 8) {
        PopToast({ status: ToastStatuses.ERROR, message: t("toast__password_not_long_enough") });
        clientError = true;
    }

    if (emailRegex.test(email) === false) {
        PopToast({ status: ToastStatuses.ERROR, message: t("toast__not_an_email_address") });
        clientError = true;
    }

    if (clientError) {
        setRecoil(registerStateAtom, {
            loading: false
        });
        setRecoil(playerIdentityStateAtom, {
            ...currentIdentityState,
            loadingPlayerIdentity: false
        });
        return;
    }

    // console.log("Registering new account with details:");
    // console.log({
    //     username,
    //     email,
    //     confirmEmail,
    //     password,
    //     confirmPassword
    // });

    try {
        const result = await anonymousUserRegisterEmailRequest(username, email, confirmEmail, password, confirmPassword, emailPromo, termsAndPrivacyPolicy);

        // console.log("Result of register:", result);

        const { authToken } = result;

        deleteFromLocalStorage(LSKeys.AnonymousUserId);
        saveToLocalStorage(LSKeys.AuthToken, authToken);

        // console.log("check local storage state");

        reloadClient(false);
    } catch (e) {
        setRecoil(registerStateAtom, {
            loading: false
        });

        setRecoil(playerIdentityStateAtom, {
            ...currentIdentityState,
            loadingPlayerIdentity: false
        });
        console.error("Caught error in register");
        console.error(e);
        if (e instanceof UsernameInappropriateError) {
            PopToast({ status: ToastStatuses.ERROR, message: "That username is inappropriate! Try a different one." });
        } else if (e instanceof UsernameAlreadyTakenError) {
            PopToast({ status: ToastStatuses.ERROR, message: "That username is taken!" });
        } else if (e instanceof EmailInvalidError) {
            PopToast({ status: ToastStatuses.ERROR, message: "That isn't a valid email address!" });
        } else if (e instanceof EmailAlreadyTakenError) {
            PopToast({ status: ToastStatuses.ERROR, message: "That email address is already registered!" });
        } else {
            PopToast({ status: ToastStatuses.ERROR, message: "Something went wrong registering your account!" });
        }
    }
};

export const UI_ChangeUsername = async (newUsername: string): Promise<void> => {
    const currentState = getRecoil(playerIdentityStateAtom);
    const { userType } = currentState;

    setRecoil(playerIdentityStateAtom, {
        ...currentState,
        loadingPlayerIdentity: true
    });

    try {
        await changeUsernameRequest(userType, newUsername);

        const currentState = getRecoil(playerIdentityStateAtom);

        setRecoil(playerIdentityStateAtom, {
            ...currentState,
            loadingPlayerIdentity: false,
            userDetails: {
                ...currentState.userDetails,
                username: newUsername
            }
        });
        PopToast({ status: ToastStatuses.SUCCESS, message: t("toast__username_changed") });
        UI_SwapScreen(Screens.MainMenu);
    } catch (e) {
        setRecoil(playerIdentityStateAtom, {
            ...currentState,
            loadingPlayerIdentity: false
        });

        console.error("Caught error in change username");
        console.error(e);

        if (e instanceof UsernameInappropriateError) {
            PopToast({ status: ToastStatuses.ERROR, message: "That username is inappropriate! Try a different one." });
        } else if (e instanceof UsernameAlreadyTakenError) {
            PopToast({ status: ToastStatuses.ERROR, message: "That username is taken!" });
        } else {
            PopToast({ status: ToastStatuses.ERROR, message: "Something went wrong changing your username! Try again." });
        }
    }
};

export const UI_AbandonRun = async (): Promise<void> => {
    const { userType } = getRecoil(playerIdentityStateAtom);

    setRecoil(abandonRunStateAtom, {
        abandoningInProgress: true
    });

    try {
        const abandonRunResponse = await abandonRunRequest(userType);

        reloadClient(false, true);
    } catch (e) {
        setRecoil(abandonRunStateAtom, {
            abandoningInProgress: false
        });
        PopToast({ status: ToastStatuses.ERROR, message: "Uh oh! Something went wrong abandoning your current run :(" });
    }
};

export const UI_EquipSkin = async (skin: CharacterSkin): Promise<void> => {
    const { userType } = getRecoil(playerIdentityStateAtom);
    const { loadout, loadoutScreen } = getRecoil(playerLoadoutStateAtom);

    setRecoil(playerLoadoutStateAtom, {
        loadout: loadout,
        loadoutScreen: loadoutScreen,
        playerLoadoutOperationInProgress: true
    });

    try {
        const equippedSkinResponse = await equipSkinRequest(userType, skin);

        const { updatedLoadout } = equippedSkinResponse;

        console.log("got equipped skin response:", equippedSkinResponse);

        setRecoil(playerLoadoutStateAtom, {
            loadout: updatedLoadout,
            loadoutScreen: loadoutScreen,
            playerLoadoutOperationInProgress: false
        });

        // PopToast({ status: ToastStatuses.SUCCESS, message: 'Skin equipped!' });
    } catch (e) {
        setRecoil(playerLoadoutStateAtom, {
            loadout: loadout,
            loadoutScreen: loadoutScreen,
            playerLoadoutOperationInProgress: false
        });

        PopToast({ status: ToastStatuses.ERROR, message: "Uh oh! Something went wrong equipping that skin :(" });
    }
};

export const UI_EquipItemToSlot = async (slot: LoadoutSlots, itemId: Item): Promise<void> => {
    const { userType } = getRecoil(playerIdentityStateAtom);
    const { loadout, loadoutScreen } = getRecoil(playerLoadoutStateAtom);
    const currentInventoryState = getRecoil(playerInventoryStateAtom);

    setRecoil(playerInventoryStateAtom, {
        ...currentInventoryState,
        inventoryOperationInProgress: true
    });

    setRecoil(playerLoadoutStateAtom, {
        loadout: loadout,
        loadoutScreen: loadoutScreen,
        playerLoadoutOperationInProgress: true
    });

    try {
        const equippedItemResponse = await equipItemToSlotRequest(userType, slot, itemId);

        const { updatedLoadout, updatedInventory } = equippedItemResponse;

        setRecoil(playerInventoryStateAtom, {
            inventoryOperationInProgress: false,
            items: updatedInventory
        });

        setRecoil(playerLoadoutStateAtom, {
            loadout: updatedLoadout,
            loadoutScreen: loadoutScreen,
            playerLoadoutOperationInProgress: false
        });

        // PopToast({ status: ToastStatuses.SUCCESS, message: 'Item equipped!' });
    } catch (e) {
        setRecoil(playerLoadoutStateAtom, {
            loadout: loadout,
            loadoutScreen: loadoutScreen,
            playerLoadoutOperationInProgress: false
        });

        setRecoil(playerInventoryStateAtom, {
            ...currentInventoryState,
            inventoryOperationInProgress: false
        });

        PopToast({ status: ToastStatuses.ERROR, message: "Uh oh! Something went wrong equipping that item :(" });
    }
};

export const UI_GetXsollaLinkForSku = async (sku: string): Promise<{ link: string }> => {
    setRecoil(shopActionState, {
        shopActionInProgress: true
    });
    const response = await getPurchaseLinkForSKURequest(sku);

    console.log("Response from backend:", response);

    const { link } = response;

    window.location.href = link;

    setRecoil(shopActionState, {
        shopActionInProgress: false
    });

    return response;
};

export const UI_BuySkinWithGems = async (skin: CharacterSkin): Promise<{ success: boolean }> => {
    setRecoil(shopActionState, {
        shopActionInProgress: true
    });
    const response = await buySkinWithGemsRequest(skin);
    setRecoil(shopActionState, {
        shopActionInProgress: false
    });
    UI_SwapScreen(Screens.PurchaseSuccessful);
    return response;
};

export const UI_BuyInsuranceWithGems = async (): Promise<{ success: boolean }> => {
    setRecoil(shopActionState, {
        shopActionInProgress: true
    });
    const response = await buyInsuranceWithGemsRequest();
    setRecoil(shopActionState, {
        shopActionInProgress: false
    });
    UI_SwapScreen(Screens.PurchaseSuccessful);
    return response;
};

export const UI_BuyInsuranceWithCoins = async (): Promise<{ success: boolean }> => {
    setRecoil(shopActionState, {
        shopActionInProgress: true
    });
    const response = await buyInsuranceWithCoinsRequest();
    setRecoil(shopActionState, {
        shopActionInProgress: false
    });
    UI_SwapScreen(Screens.PurchaseSuccessful);
    return response;
};

export const UI_InsureSlot = async (slot: LoadoutSlots): Promise<void> => {
    const { userType } = getRecoil(playerIdentityStateAtom);
    const { loadout, loadoutScreen } = getRecoil(playerLoadoutStateAtom);

    const timeoutDuration = process.env.NODE_ENV === "production" ? 1000 : 0;

    setTimeout(async () => {
        setRecoil(playerLoadoutStateAtom, {
            loadout: loadout,
            loadoutScreen: loadoutScreen,
            playerLoadoutOperationInProgress: true
        });

        try {
            const insuredSlotResponse = await insureSlotRequest(userType, slot);

            const { updatedLoadout } = insuredSlotResponse;

            const currentState = getRecoil(playerIdentityStateAtom);

            setRecoil(playerIdentityStateAtom, {
                ...currentState,
                userDetails: {
                    ...currentState.userDetails,
                    // @ts-ignore
                    PVPZ_Currency: {
                        // @ts-ignore
                        ...currentState.userDetails.PVPZ_Currency,
                        // @ts-ignore
                        insuranceCredits: currentState.userDetails.PVPZ_Currency.insuranceCredits - 1
                    }
                }
            });

            setRecoil(playerLoadoutStateAtom, {
                loadout: updatedLoadout,
                loadoutScreen: loadoutScreen,
                playerLoadoutOperationInProgress: false
            });

            // PopToast({ status: ToastStatuses.SUCCESS, message: 'Slot insured!' });
        } catch (e) {
            setRecoil(playerLoadoutStateAtom, {
                loadout: loadout,
                loadoutScreen: loadoutScreen,
                playerLoadoutOperationInProgress: false
            });

            PopToast({ status: ToastStatuses.ERROR, message: "Uh oh! Something went wrong insuring that item slot :(" });
        }
    }, timeoutDuration);
};

export interface PlayerPreferences {
    enableMusic: boolean;
    enableSoundEffects: boolean;
    preferredLanguage: SupportedLanguages;
    preferredMatchmakingRegion: SupportedMatchmakingRegions;
}

export const UI_SavePreferences = async () => {
    const { userType } = getRecoil(playerIdentityStateAtom);
    const currentState = getRecoil(playerPreferencesStateAtom);
    const local = currentState.local;

    setRecoil(playerPreferencesStateAtom, {
        ...currentState,
        savingPreferencesInProgress: true
    });

    try {
        const savedPreferences = await savePreferencesRequest(userType, local as PlayerPreferences);

        Lang = savedPreferences.preferredLanguage;
        document.querySelector("body")!.className = "";
        document.querySelector("body")!.classList.add(Lang);

        setRecoil(playerPreferencesStateAtom, {
            fromServer: { ...savedPreferences },
            local: { ...savedPreferences },
            savingPreferencesInProgress: false
        });

        UI_SwapScreen(Screens.MainMenu);

        PopToast({ status: ToastStatuses.SUCCESS, message: t("settings__settings_saved") });
    } catch (e) {
        setRecoil(playerPreferencesStateAtom, {
            ...currentState,
            savingPreferencesInProgress: false
        });

        PopToast({ status: ToastStatuses.ERROR, message: "Uh oh! Something went wrong saving your settings :(" });
    }
};
//#endregion

//#region API Requests That Mutate UI State
/********************************************/
/*                                          */
/* ASYNCHRONOUS State Mutation Helpers      */
/*                                          */
/********************************************/
export const UI_GetPreferredRegion = () => {
    const preferences = getRecoil(playerPreferencesStateAtom);
    const { preferredMatchmakingRegion } = preferences.local;
    return preferredMatchmakingRegion;
};
//#endregion

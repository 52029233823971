import React from "react";
import { Screens, UI_BuyInsuranceWithGems, UI_SwapScreen, playerIdentityStateAtom, shopActionState } from "../framework/UI_State";
import { t } from "../../../shared/data/Data_I18N";
import { useRecoilValue } from "recoil";
import { UserType } from "../../../shared/SharedTypes";

export const ConfirmInsurancePurchase = () => {
    const { shopActionInProgress } = useRecoilValue(shopActionState);
    const { userType, userDetails } = useRecoilValue(playerIdentityStateAtom);

    // @ts-ignore
    const { PVPZ_Currency } = userDetails;
    const { gems } = PVPZ_Currency;

    return (
        <>
            <div className="confirm-skin-panel animate__animated animate__fadeInUp animate__faster">
                <div className="confirm-skin-header">Insurance</div>

                <div className="confirmation" style={{ marginBottom: "20px" }}>
                    {gems < 150 && <>It looks like you don't have enough Gems to purchase insurance credits!</>}
                    {gems >= 150 && (
                        <>
                            Purchasing <span>10 Insurance Credits</span> will cost
                            <br />
                            <span>150 gems</span>. Are you sure?
                        </>
                    )}
                </div>

                {userType === UserType.Anonymous && (
                    <>
                        <p className="not-registered">Sorry, only registered accounts can buy things from the store! Create an account and try again!</p>
                    </>
                )}

                {userType === UserType.Registered && (
                    <>
                        <button
                            className="affirmative-button"
                            disabled={gems < 150 || shopActionInProgress}
                            onClick={() => {
                                UI_BuyInsuranceWithGems();
                            }}
                        >
                            Buy 10 Credits for 150 Gems
                        </button>

                        {gems < 150 && (
                            <button
                                className="affirmative-button"
                                disabled={shopActionInProgress}
                                onClick={() => {
                                    UI_SwapScreen(Screens.Shop);
                                }}
                            >
                                Buy Gems
                            </button>
                        )}
                    </>
                )}

                <button
                    className="neutral-button"
                    disabled={shopActionInProgress}
                    onClick={() => {
                        UI_SwapScreen(Screens.Shop);
                    }}
                >
                    {t("stats__back_btn")}
                </button>
            </div>
        </>
    );
};

import React from "react";
import { useRecoilValue } from "recoil";
import { identityErrorStateAtom } from "../framework/UI_State";
import { reloadClient } from "../../utils/ClientReload";
import { t } from "../../../shared/data/Data_I18N";
import { LSKeys, deleteFromLocalStorage } from "../../utils/ClientLocalStorage";

export const UserIdentityError = () => {
    const { message: errorMessage } = useRecoilValue(identityErrorStateAtom);
    return (
        <div className="user-identity-error" style={{ pointerEvents: "all" }}>
            <div className="user-identity-error-panel">
                <h2>{t("main_menu__identity_error_title")}</h2>
                <p>{errorMessage}</p>
                <button
                    className="affirmative-button"
                    onClick={() => {
                        window.history.replaceState({}, document.title, "/");
                        setTimeout(() => {
                            deleteFromLocalStorage(LSKeys.AuthToken);
                            deleteFromLocalStorage(LSKeys.AnonymousUserId);
                            reloadClient(false);
                        });
                    }}
                >
                    {t("death_menu__back_to_main_menu_button")}
                </button>
            </div>
        </div>
    );
};

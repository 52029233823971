export class AuthExpiredError extends Error {
    public override message;
    public constructor(errorMessage: string) {
        super("AuthExpiredError");
        this.message = errorMessage;
    }
}

export class EmailAlreadyTakenError extends Error {
    public override message;
    public constructor(errorMessage: string) {
        super("EmailAlreadyTakenError");
        this.message = errorMessage;
    }
}

export class IncorrectLoginMethodError extends Error {
    public override message;
    public constructor(errorMessage: string) {
        super("IncorrectLoginMethodError");
        this.message = errorMessage;
    }
}

export class EmailInvalidError extends Error {
    public override message;
    public constructor(errorMessage: string) {
        super("EmailInvalidError");
        this.message = errorMessage;
    }
}
export class InvalidPasswordError extends Error {
    public override message;
    public constructor(errorMessage: string) {
        super("InvalidPasswordError");
        this.message = errorMessage;
    }
}

export class EmailNotFoundError extends Error {
    public override message;
    public constructor(errorMessage: string) {
        super("EmailNotFoundError");
        this.message = errorMessage;
    }
}

export class UnknownAPIError extends Error {
    public override message;
    public constructor(errorMessage: string) {
        super("UnknownAPIError");
        this.message = errorMessage;
    }
}

export class UserNotFoundError extends Error {
    public override message;
    public constructor(errorMessage: string) {
        super("UserNotFoundError");
        this.message = errorMessage;
    }
}

export class AllServersFullError extends Error {
    public override message;
    public constructor(errorMessage: string) {
        super("AllServersFullError");
        this.message = errorMessage;
    }
}

export class NoServersOnlineError extends Error {
    public override message;
    public constructor(errorMessage: string) {
        super("NoServersOnlineError");
        this.message = errorMessage;
    }
}

export class AlreadyOnARunError extends Error {
    public override message;
    public constructor(errorMessage: string) {
        super("AlreadyOnARunError");
        this.message = errorMessage;
    }
}

export class UsernameAlreadyTakenError extends Error {
    public override message;
    public constructor(errorMessage: string) {
        super("UsernameAlreadyTakenError");
        this.message = errorMessage;
    }
}

export class UsernameInappropriateError extends Error {
    public override message;
    public constructor(errorMessage: string) {
        super("UsernameInappropriateError");
        this.message = errorMessage;
    }
}

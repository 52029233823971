import React from "react";
import { useRecoilValue } from "recoil";
import { playerInventoryStateAtom } from "../framework/UI_State";
import { ItemContainer } from "../components/UI_ItemContainer";
import { Item } from "../../../shared/data/Data_Items";

export const KitTest = () => {
    const { items } = useRecoilValue(playerInventoryStateAtom);
    return (
        <>
            <div className="kittest-panel animate__animated animate__fadeInUp animate__faster">
                <div className="kittest-header">UI Kit</div>

                <ItemContainer items={items} OnSlotClickFn={(__itemId: Item) => {}} />

                {/* <button className="neutral-button" disabled={disableAllBtns} onClick={() => {}}>
                    Type 1
                </button>
                <button className="affirmative-button" disabled={disableAllBtns} onClick={() => {}}>
                    Type 2
                </button>
                <button className="destructive-button" disabled={disableAllBtns} onClick={() => {}}>
                    Type 3
                </button>

                <button className="neutral-button btn-large" disabled={disableAllBtns} onClick={() => {}}>
                    Type 1
                </button>
                <button className="affirmative-button btn-large" disabled={disableAllBtns} onClick={() => {}}>
                    Type 2
                </button>
                <button className="destructive-button btn-large" disabled={disableAllBtns} onClick={() => {}}>
                    Type 3
                </button> */}
            </div>
        </>
    );
};

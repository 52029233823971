import React from "react";
import { Screens, UI_BuySkinWithGems, UI_SwapScreen, playerIdentityStateAtom, selectedSkinToPurchaseStateAtom, shopActionState } from "../framework/UI_State";
import { t } from "../../../shared/data/Data_I18N";
import { useRecoilValue } from "recoil";
import { CharacterSkin } from "../../../shared/data/Data_Cosmetics";
import { CharacterSkinSlot } from "../components/UI_CharacterSkinSlot";
import { UserType } from "../../../shared/SharedTypes";

export const ConfirmSkinPurchase = () => {
    const { shopActionInProgress } = useRecoilValue(shopActionState);
    const { userDetails, userType } = useRecoilValue(playerIdentityStateAtom);

    // @ts-ignore
    // eslint-disable-next-line prefer-const
    let { PVPZ_Currency, PVPZ_UnlockedSkins } = userDetails;

    if (PVPZ_UnlockedSkins === undefined) {
        PVPZ_UnlockedSkins = [];
    }

    const { gems } = PVPZ_Currency;

    const currentSelectedSkin = useRecoilValue(selectedSkinToPurchaseStateAtom);

    console.log("currentSelectedSkin", currentSelectedSkin);

    console.log(PVPZ_UnlockedSkins);

    let alreadyOwnThisSkin = false;
    if (PVPZ_UnlockedSkins.includes(currentSelectedSkin)) {
        alreadyOwnThisSkin = true;
    }

    const gemPrices = {
        [CharacterSkin.MidnightThief]: 200,
        [CharacterSkin.ForestArcher]: 450,
        [CharacterSkin.Assassin]: 700,
        [CharacterSkin.VengefulKnight]: 1500
    };

    // @ts-ignore
    const selectedPrice = gemPrices[currentSelectedSkin];

    return (
        <>
            <div className="confirm-skin-panel animate__animated animate__fadeInUp animate__faster">
                <div className="confirm-skin-header">Buy Skin</div>

                <div className="confirmation" style={{ marginBottom: "20px" }}>
                    {gems < selectedPrice && <>It looks like you don't have enough Gems to purchase this skin!</>}
                    {gems >= selectedPrice && (
                        <>
                            Are you sure you want to purchase this skin? It will cost you <span>{selectedPrice} gems</span>!
                        </>
                    )}
                </div>

                <div className="skin-preview">
                    <CharacterSkinSlot selected={false} OnClickFn={() => {}} skin={currentSelectedSkin} />
                </div>

                {userType === UserType.Anonymous && (
                    <>
                        <p className="not-registered">Sorry, only registered accounts can buy things from the store! Create an account and try again!</p>
                    </>
                )}

                {userType === UserType.Registered && (
                    <>
                        {alreadyOwnThisSkin && <p className="not-registered">You already own this!</p>}

                        {alreadyOwnThisSkin === false && (
                            <button
                                className="affirmative-button"
                                disabled={gems < selectedPrice || shopActionInProgress}
                                onClick={() => {
                                    UI_BuySkinWithGems(currentSelectedSkin);
                                }}
                            >
                                Buy for {selectedPrice} gems
                            </button>
                        )}

                        {gems < selectedPrice && alreadyOwnThisSkin === false && (
                            <button
                                className="affirmative-button"
                                disabled={shopActionInProgress}
                                onClick={() => {
                                    UI_SwapScreen(Screens.Shop);
                                }}
                            >
                                Buy Gems
                            </button>
                        )}
                    </>
                )}

                <button
                    className="neutral-button"
                    disabled={shopActionInProgress}
                    onClick={() => {
                        UI_SwapScreen(Screens.Shop);
                    }}
                >
                    {t("stats__back_btn")}
                </button>
            </div>
        </>
    );
};

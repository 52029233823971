import React from "react";
import { t } from "../../../shared/data/Data_I18N";
import { Screens, UI_GetXsollaLinkForSku, UI_SetSelectedBundle, UI_SetSelectedSkinToPurchase, UI_SwapScreen, playerIdentityStateAtom } from "../framework/UI_State";

// @ts-ignore
import firstTimerBundleSrc from "../kit/firstbundle.png";

// @ts-ignore
import supporterImgSrc from "../kit/supporterbundle.png";

// @ts-ignore
import founderImgSrc from "../kit/foundbundle.png";

// @ts-ignore
import gems1ImgSrc from "../kit/gems1.png";

// @ts-ignore
import gems2ImgSrc from "../kit/gems2.png";

// @ts-ignore
import gems3ImgSrc from "../kit/gems3.png";

// @ts-ignore
import gems4ImgSrc from "../kit/gems4.png";

// @ts-ignore
import coinImgSrc from "../kit/coin.png";

// @ts-ignore
import insuranceImgSrc from "../kit/inventory-icon.png";
import { CharacterSkinSlot } from "../components/UI_CharacterSkinSlot";
import { CharacterSkin } from "../../../shared/data/Data_Cosmetics";
import { useRecoilValue } from "recoil";
import { PopToast, ToastStatuses } from "../kit/toast-utils";
import { UserType } from "../../../shared/SharedTypes";

export const Shop = () => {
    const { userType, userDetails } = useRecoilValue(playerIdentityStateAtom);

    console.log(userDetails);

    // @ts-ignore
    const { PVPZ_Currency } = userDetails;

    const { insuranceCredits, gems, gold } = PVPZ_Currency;
    return (
        <>
            <div className="shop-panel animate__animated animate__fadeInUp animate__faster">
                <div className="shop-header">{t("shop__title")}</div>

                <div className="back">
                    <button
                        className="neutral-button"
                        onClick={() => {
                            UI_SwapScreen(Screens.MainMenu);
                        }}
                    >
                        {t("settings__back_to_main_menu_button")}
                    </button>
                </div>

                <div className="currencies">
                    <div className="gems">
                        <img src={gems1ImgSrc} alt="Your Gems" /> {gems} Gems
                    </div>
                    <div className="coins">
                        <img src={coinImgSrc} alt="Your Gold" />
                        {gold} Gold
                    </div>
                </div>

                <div className="top-shop-content">
                    <div className="left-shop-content">
                        <div className="bundles">
                            <div
                                className="bundle first-buyer-bundle"
                                onClick={() => {
                                    UI_SetSelectedBundle("welcome");
                                    UI_SwapScreen(Screens.BundleDetails);
                                }}
                            >
                                <div className="bundle-info">
                                    <div className="bundle-title">Welcome Bundle - $5.00</div>
                                    <div className="bundle-description">
                                        980 Gems and one character skin.
                                        <br />
                                        <strong>Click to see more details!</strong>
                                    </div>
                                </div>
                                <div className="bundle-img">
                                    <img src={firstTimerBundleSrc} alt="First Timer Bundle" />
                                </div>
                            </div>

                            <div
                                className="bundle supporter-bundle"
                                onClick={() => {
                                    UI_SetSelectedBundle("supporter");
                                    UI_SwapScreen(Screens.BundleDetails);
                                }}
                            >
                                <div className="bundle-info">
                                    <div className="bundle-title">Supporter Bundle - $25.00</div>
                                    <div className="bundle-description">
                                        THREE skins, 1000 gems, a special name color, and more!
                                        <br />
                                        <strong>Click to see more details!</strong>
                                    </div>
                                </div>
                                <div className="bundle-img">
                                    <img src={supporterImgSrc} alt="First Timer Bundle" />
                                </div>
                            </div>

                            <div
                                className="bundle founder-bundle"
                                onClick={() => {
                                    UI_SetSelectedBundle("founder");
                                    UI_SwapScreen(Screens.BundleDetails);
                                }}
                            >
                                <div className="bundle-info">
                                    <div className="bundle-title">Founder Bundle - $50.00</div>
                                    <div className="bundle-description">
                                        FIVE skins, 2000 gems, a special name color, and more!
                                        <br />
                                        <strong>Click to see more details!</strong>
                                    </div>
                                </div>
                                <div className="bundle-img">
                                    <img src={founderImgSrc} alt="First Timer Bundle" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right-shop-content">
                        <div className="gems">
                            <div className="gems-title">Gems</div>
                            <div
                                className="gem-bundle"
                                onClick={() => {
                                    if (userType === UserType.Anonymous) {
                                        PopToast({ status: ToastStatuses.ERROR, message: "Only registered users can purchase Gems from the shop! Create an account and try again." });
                                    } else {
                                        UI_GetXsollaLinkForSku("e1abc9cd-26d1-446f-8b86-822bdbad7459");
                                    }
                                }}
                            >
                                <img src={gems1ImgSrc} alt="Gem Bundle 1" />
                                <div className="gems-text">220 Gems</div>
                                <div className="gems-price">$2.00</div>
                            </div>
                            <div
                                className="gem-bundle"
                                onClick={() => {
                                    if (userType === UserType.Anonymous) {
                                        PopToast({ status: ToastStatuses.ERROR, message: "Only registered users can purchase Gems from the shop! Create an account and try again." });
                                    } else {
                                        UI_GetXsollaLinkForSku("71864fae-7436-454e-a47c-705b62f3ec18");
                                    }
                                }}
                            >
                                <img src={gems2ImgSrc} alt="Gem Bundle 2" />
                                <div className="gems-text">490 Gems</div>
                                <div className="gems-price">$4.00</div>
                            </div>
                            <div
                                className="gem-bundle"
                                onClick={() => {
                                    if (userType === UserType.Anonymous) {
                                        PopToast({ status: ToastStatuses.ERROR, message: "Only registered users can purchase Gems from the shop! Create an account and try again." });
                                    } else {
                                        UI_GetXsollaLinkForSku("ef71686a-d4b6-4add-818e-a96698afb54a");
                                    }
                                }}
                            >
                                <img src={gems3ImgSrc} alt="Gem Bundle 3" />
                                <div className="gems-text">1490 Gems</div>
                                <div className="gems-price">$10.00</div>
                            </div>
                            <div
                                className="gem-bundle"
                                onClick={() => {
                                    if (userType === UserType.Anonymous) {
                                        PopToast({ status: ToastStatuses.ERROR, message: "Only registered users can purchase Gems from the shop! Create an account and try again." });
                                    } else {
                                        UI_GetXsollaLinkForSku("6a201bc6-df3e-42e0-a383-1507b49141a3");
                                    }
                                }}
                            >
                                <img src={gems4ImgSrc} alt="Gem Bundle 4" />
                                <div className="gems-text">3340 Gems</div>
                                <div className="gems-price">$20.00</div>
                            </div>
                        </div>
                        <div className="skins">
                            <div className="skins-title">Skins</div>

                            <div className="skins-content">
                                <div className="skin-purchase">
                                    <CharacterSkinSlot
                                        selected={false}
                                        OnClickFn={() => {
                                            UI_SetSelectedSkinToPurchase(CharacterSkin.MidnightThief);
                                            UI_SwapScreen(Screens.ConfirmSkinPurchase);
                                        }}
                                        skin={CharacterSkin.MidnightThief}
                                    />
                                    <div className="price">
                                        200 <img src={gems1ImgSrc} alt="Gem Cost" />
                                    </div>
                                </div>
                                <div className="skin-purchase">
                                    <CharacterSkinSlot
                                        selected={false}
                                        OnClickFn={() => {
                                            UI_SetSelectedSkinToPurchase(CharacterSkin.ForestArcher);
                                            UI_SwapScreen(Screens.ConfirmSkinPurchase);
                                        }}
                                        skin={CharacterSkin.ForestArcher}
                                    />
                                    <div className="price">
                                        450 <img src={gems1ImgSrc} alt="Gem Cost" />
                                    </div>
                                </div>
                                <div className="skin-purchase">
                                    <CharacterSkinSlot
                                        selected={false}
                                        OnClickFn={() => {
                                            UI_SetSelectedSkinToPurchase(CharacterSkin.Assassin);
                                            UI_SwapScreen(Screens.ConfirmSkinPurchase);
                                        }}
                                        skin={CharacterSkin.Assassin}
                                    />
                                    <div className="price">
                                        700 <img src={gems1ImgSrc} alt="Gem Cost" />
                                    </div>
                                </div>
                                <div className="skin-purchase">
                                    <CharacterSkinSlot
                                        selected={false}
                                        OnClickFn={() => {
                                            UI_SetSelectedSkinToPurchase(CharacterSkin.VengefulKnight);
                                            UI_SwapScreen(Screens.ConfirmSkinPurchase);
                                        }}
                                        skin={CharacterSkin.VengefulKnight}
                                    />
                                    <div className="price">
                                        1500 <img src={gems1ImgSrc} alt="Gem Cost" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bottom-shop-content">
                    <div className="insurance-title">
                        Run Insurance: <img src={insuranceImgSrc} alt="Insurance" />
                        &nbsp; <span>{insuranceCredits} Insurance Credits Owned</span>
                    </div>
                    <div className="insurance-description">
                        Buy insurance credits with gems or coins to protect your items from being lost!
                        <br />
                        <span>10</span> insurance credits costs <span>150 Gems</span> or <span>5000 Gold</span>.
                    </div>
                </div>
                <div className="insurance-actions">
                    <button
                        className="affirmative-button"
                        onClick={() => {
                            UI_SwapScreen(Screens.ConfirmInsurancePurchase);
                        }}
                    >
                        Buy With Gems (150)
                    </button>
                    <button
                        className="affirmative-button"
                        onClick={() => {
                            UI_SwapScreen(Screens.ConfirmCoinInsurancePurchase);
                        }}
                    >
                        Buy With Coins (5000)
                    </button>
                </div>
            </div>
        </>
    );
};

import { Graphics } from "pixi.js";
import { AnonymousUser, ServerEvent, ServerEventFeedEventTypes } from "../../shared/SharedTypes";
import { GameplaySystem } from "../../shared/engine/SharedGameplaySystem";
import { Screens, UI_AddServerEventToFeed, UI_SwapScreen } from "../ui/framework/UI_State";
import { ItemRarity } from "../../shared/data/Data_Items";

export enum DebugShape {
    Circle = 0,
    Rectangle = 1,
    Line = 2
}

type ShapeDetails = { radius: number; destroyAfterSeconds: number } | { height: number; width: number; destroyAfterSeconds: number } | { destX: number; destY: number; destroyAfterSeconds: number };

export class ClientDebugManager extends GameplaySystem {
    private shouldSkipMenu: boolean = false;
    private gameHasStarted: boolean = false;
    private assetsAreLoaded: boolean = false;
    private identityIsLoaded: boolean = false;

    private drawnDebugShapes = new Map<string, Graphics>();

    public constructor() {
        super();
    }

    protected override getSystemName(): string {
        return "Debug";
    }

    public Initialize(): void {
        if (window.location.href.includes("skipMenu=true") && window.location.href.includes("localhost")) {
            this.shouldSkipMenu = true;
        }

        Game.ListenForEvent("Assets::Loaded", () => {
            this.assetsAreLoaded = true;
        });

        Game.ListenForEvent("Identity::Identified_Anonymous", (__identity: AnonymousUser) => {
            this.identityIsLoaded = true;
        });

        Game.ListenForEvent("Identity::Identified_Registered", () => {
            this.identityIsLoaded = true;
        });

        document.addEventListener("keydown", (event: KeyboardEvent) => {
            if (event.key === "F1") {
                event.preventDefault();
                let randomEvent: ServerEvent;
                if (Math.random() <= 0.16) {
                    randomEvent = {
                        id: Math.floor(Math.random() * 10000),
                        type: ServerEventFeedEventTypes.Loot,
                        username: `Sam#${Math.floor(Math.random() * 10000)}`,
                        rarity: ItemRarity.Legendary,
                        locationX: Math.floor(Math.random() * 3999),
                        locationY: Math.floor(Math.random() * 3999)
                    };
                } else if (Math.random() <= 0.32) {
                    randomEvent = {
                        id: Math.floor(Math.random() * 10000),
                        type: ServerEventFeedEventTypes.Kill,
                        killer: `Markiplier#${Math.floor(Math.random() * 10000)}`,
                        victim: `PewDiePie#${Math.floor(Math.random() * 10000)}`,
                        locationX: Math.floor(Math.random() * 3999),
                        locationY: Math.floor(Math.random() * 3999)
                    };
                } else if (Math.random() <= 0.48) {
                    randomEvent = {
                        id: Math.floor(Math.random() * 10000),
                        type: ServerEventFeedEventTypes.BossSpawn,
                        locationX: Math.floor(Math.random() * 3999),
                        locationY: Math.floor(Math.random() * 3999),
                        bossName: Math.random() > 0.5 ? "GRONK" : "Iron Fox"
                    };
                } else if (Math.random() <= 0.64) {
                    randomEvent = {
                        id: Math.floor(Math.random() * 10000),
                        type: ServerEventFeedEventTypes.ExtractionStarted,
                        playerName: `Sam#${Math.floor(Math.random() * 1000000)}`,
                        locationX: Math.floor(Math.random() * 3999),
                        locationY: Math.floor(Math.random() * 3999)
                    };
                } else if (Math.random() <= 0.83) {
                    randomEvent = {
                        id: Math.floor(Math.random() * 10000),
                        type: ServerEventFeedEventTypes.ExtractionSuccessful,
                        playerName: `Sam#${Math.floor(Math.random() * 1000000)}`,
                        locationX: Math.floor(Math.random() * 3999),
                        locationY: Math.floor(Math.random() * 3999)
                    };
                } else {
                    randomEvent = {
                        id: Math.floor(Math.random() * 10000),
                        type: ServerEventFeedEventTypes.BossAttack,
                        locationX: Math.floor(Math.random() * 3999),
                        locationY: Math.floor(Math.random() * 3999),
                        bossName: Math.random() > 0.5 ? "GRONK" : "Iron Fox"
                    };
                }

                UI_AddServerEventToFeed(randomEvent);
            }
            if (event.key === "F2") {
                event.preventDefault();
            }
            if (event.key === "F3") {
                event.preventDefault();
            }
            if (event.key === "F4") {
                event.preventDefault();
            }
            if (event.key === "F5") {
                event.preventDefault();
            }
            if (event.key === "F6") {
                event.preventDefault();
            }
            if (event.key === "F7") {
                event.preventDefault();
            }
            if (event.key === "F8") {
                event.preventDefault();
            }
            if (event.key === "F9") {
                event.preventDefault();
                UI_SwapScreen(Screens.KitTest);
            }
            if (event.key === "F10") {
                event.preventDefault();
                const stats = document.querySelector("#stats") as HTMLElement;
                if (stats.style.display === "none" || stats.style.display === "") {
                    stats.style.display = "Flex";
                } else {
                    stats.style.display = "none";
                }
            }
        });

        this.LogInfo("Ready!");
    }

    public DrawDebugShape(x: number, y: number, shape: DebugShape, shapeDetails: ShapeDetails): void {
        if (shape === DebugShape.Circle) {
            const { radius, destroyAfterSeconds } = shapeDetails as { radius: number; destroyAfterSeconds: number };
            console.log("server told us to draw circle at " + x + ", " + y + " with radius " + radius);
            const key = `circle::x${x},y${y},r${radius}`;
            if (this.drawnDebugShapes.has(key)) {
                return; // dont re-draw the same shape
            } else {
                const circle = new Graphics();
                circle.lineStyle(2, 0x00ff00, 1);
                circle.drawCircle(x, y, radius);
                circle.endFill();
                Game.Renderer.AddDebugGraphics(circle);
                this.drawnDebugShapes.set(key, circle);
                if (destroyAfterSeconds) {
                    setTimeout(() => {
                        circle.destroy();
                        this.drawnDebugShapes.delete(key);
                    }, destroyAfterSeconds * 1000);
                }
            }
        } else if (shape === DebugShape.Rectangle) {
            const { height, width, destroyAfterSeconds } = shapeDetails as { height: number; width: number; destroyAfterSeconds: number };
            console.log("server told us to draw rectangle at " + x + ", " + y + " with width " + width + " and height " + height);
            const key = `rectangle::x${x},y${y},w${width},h${height}`;
            if (this.drawnDebugShapes.has(key)) {
                return; // dont re-draw the same shape
            } else {
                const rectangle = new Graphics();
                rectangle.lineStyle(2, 0xff0000, 1);
                rectangle.drawRect(x, y, width, height);
                rectangle.endFill();
                Game.Renderer.AddDebugGraphics(rectangle);
                this.drawnDebugShapes.set(key, rectangle);
                if (destroyAfterSeconds) {
                    setTimeout(() => {
                        rectangle.destroy();
                        this.drawnDebugShapes.delete(key);
                    }, destroyAfterSeconds * 1000);
                }
            }
        } else if (shape === DebugShape.Line) {
            const { destX, destY, destroyAfterSeconds } = shapeDetails as { destX: number; destY: number; destroyAfterSeconds: number };
            console.log("server told us to draw line from " + x + ", " + y + " to " + destX + ", " + destY);
            const key = `line::x${x},y${y},destX${destX},destY${destY}`;
            if (this.drawnDebugShapes.has(key)) {
                return; // dont re-draw the same shape
            } else {
                const line = new Graphics();
                line.lineStyle(2, 0x0000ff, 1);
                line.moveTo(x, y);
                line.lineTo(destX, destY);
                line.endFill();
                Game.Renderer.AddDebugGraphics(line);
                this.drawnDebugShapes.set(key, line);
                if (destroyAfterSeconds) {
                    setTimeout(() => {
                        line.destroy();
                        this.drawnDebugShapes.delete(key);
                    }, destroyAfterSeconds * 1000);
                }
            }
        }
    }

    public override Update(__deltaTime: number): void {
        if (this.shouldSkipMenu && this.gameHasStarted === false && this.assetsAreLoaded && this.identityIsLoaded) {
            this.gameHasStarted = true;
            Game.EmitEvent("Debug::SkipMenu");
        }
    }
    public override Cleanup(): void {}
}

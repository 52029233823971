import { ActiveAbility, ActiveAbilityDefinition, getItemData, Item, ItemConfig } from "../data/Data_Items";
import { getWeaponData, WeaponConfig } from "../data/Data_Weapons";
import { GameplaySystem } from "../engine/SharedGameplaySystem";
import { SharedPlayer } from "../entities/SharedPlayer";
import { InputCommand } from "../SharedNetcodeSchemas";
import { IGameEntityBasic } from "../SharedTypes";
import { clamp } from "../SharedUtils";

export enum LoadoutSlots {
    None = -1,
    WeaponOne = 1,
    WeaponTwo = 2,
    GearOne = 3,
    GearTwo = 4,
    GearThree = 5
}

export class SharedGearAndWeaponSystem extends GameplaySystem {
    private owningEntity: IGameEntityBasic;

    public activeWeaponSlot: LoadoutSlots.WeaponOne | LoadoutSlots.WeaponTwo = LoadoutSlots.WeaponOne;

    public equippedWeaponOne: Item;
    public equippedWeaponTwo: Item;

    public equippedGearOne: Item;
    public equippedGearTwo: Item;
    public equippedGearThree: Item;

    protected weaponOneConfig: WeaponConfig;
    protected weaponTwoConfig: WeaponConfig;

    protected gearOneConfig: ItemConfig;
    protected gearTwoConfig: ItemConfig;
    protected gearThreeConfig: ItemConfig;

    protected weaponSwapCooldownInSeconds: number = 0.5;
    protected secondsSinceLastSwappedWeapons: number = 0;

    protected weaponCooldownInSeconds: number;
    protected secondsSinceLastUsedWeapon: number = 0;

    protected gearOneAbility: ActiveAbility = ActiveAbility.None;
    protected gearOneCooldownInSeconds: number = 0;
    protected secondsSinceLastUsedGearOne: number = 0;
    protected gearOneCharges: number = -1;
    protected gearOneDurationAbilityDurationInSeconds: number = 0;
    protected gearOneDurationAbilityAccumulator: number = 0;
    protected gearOneAbilityIsActive: boolean = false;

    protected gearTwoAbility: ActiveAbility = ActiveAbility.None;
    protected gearTwoCooldownInSeconds: number = 0;
    protected secondsSinceLastUsedGearTwo: number = 0;
    protected gearTwoCharges: number = -1;
    protected gearTwoDurationAbilityDurationInSeconds: number = 0;
    protected gearTwoDurationAbilityAccumulator: number = 0;
    protected gearTwoAbilityIsActive: boolean = false;

    protected gearThreeAbility: ActiveAbility = ActiveAbility.None;
    protected gearThreeCooldownInSeconds: number = 0;
    protected secondsSinceLastUsedGearThree: number = 0;
    protected gearThreeCharges: number = -1;
    protected gearThreeDurationAbilityDurationInSeconds: number = 0;
    protected gearThreeDurationAbilityAccumulator: number = 0;
    protected gearThreeAbilityIsActive: boolean = false;

    protected playerMovementImpactDurationInSeconds: number;
    protected playerMovementImpactAccumulator: number;
    protected playerMovementImpactModifier: number;

    public constructor() {
        super();
    }

    public SetWeaponDetails(weaponOne: Item, weaponTwo: Item): void {
        this.equippedWeaponOne = weaponOne;
        this.equippedWeaponTwo = weaponTwo;

        const weaponOneConfig = getWeaponData(weaponOne);

        if (weaponOneConfig === undefined) throw new Error(`Weapon with id ${weaponOne} does not exist in the weapon config map; this should never happen`);

        const weaponTwoConfig = getWeaponData(weaponTwo);

        if (weaponTwoConfig === undefined) throw new Error(`Weapon with id ${weaponTwo} does not exist in the weapon config map; this should never happen`);

        this.weaponOneConfig = weaponOneConfig;
        this.weaponTwoConfig = weaponTwoConfig;

        const attackSpeedMultiplier = (this.GetOwningEntity() as SharedPlayer).attackSpeedMultiplier;

        // console.warn("@@@ attack speed multiplier in weapon setup");
        // console.log(attackSpeedMultiplier);

        this.weaponCooldownInSeconds = weaponOneConfig.cooldownInSeconds * attackSpeedMultiplier;
        this.secondsSinceLastUsedWeapon = weaponOneConfig.cooldownInSeconds * attackSpeedMultiplier; // make it off cooldown immediately
        this.playerMovementImpactModifier = weaponOneConfig.playerMovementMultiplier;
        this.playerMovementImpactDurationInSeconds = weaponOneConfig.playerMovementMultiplierDurationInSeconds;
        this.playerMovementImpactAccumulator = weaponOneConfig.playerMovementMultiplierDurationInSeconds;
    }

    public SetGearDetails(gearOne: Item, gearTwo: Item, gearThree: Item) {
        this.equippedGearOne = gearOne;
        this.equippedGearTwo = gearTwo;
        this.equippedGearThree = gearThree;

        if (gearOne !== Item.None) {
            const gearOneConfig = getItemData(gearOne);

            if (gearOneConfig === undefined) throw new Error(`Gear with id ${gearOne} does not exist in the gear config map; this should never happen`);

            this.gearOneConfig = gearOneConfig;

            if (this.gearOneConfig.activeAbility.ability !== ActiveAbility.None) {
                // console.log("Gear one has an active ability; setting the details now");
                this.gearOneAbility = gearOneConfig.activeAbility.ability;
                this.gearOneCooldownInSeconds = gearOneConfig.activeAbility.abilityCooldownInSeconds;
                this.secondsSinceLastUsedGearOne = gearOneConfig.activeAbility.abilityCooldownInSeconds; // make it off cooldown immediately
                this.gearOneCharges = gearOneConfig.activeAbility.abilityCharges;
                this.gearOneDurationAbilityDurationInSeconds = gearOneConfig.activeAbility.abilityDurationInSeconds;
            } else {
                // console.log("Player has a gear one, but it has no active ability! Leaving defaults in SetGearDetails->Active Weapon Setup logic");
            }
        } else {
            // console.log("Player has no gear one equipped! Leaving defaults");
        }

        if (gearTwo !== Item.None) {
            const gearTwoConfig = getItemData(gearTwo);

            if (gearTwoConfig === undefined) throw new Error(`Gear with id ${gearTwo} does not exist in the gear config map; this should never happen`);

            this.gearTwoConfig = gearTwoConfig;

            if (this.gearTwoConfig.activeAbility.ability !== ActiveAbility.None) {
                // console.log("Gear two has an active ability; setting the details now");
                this.gearTwoAbility = gearTwoConfig.activeAbility.ability;
                this.gearTwoCooldownInSeconds = gearTwoConfig.activeAbility.abilityCooldownInSeconds;
                this.secondsSinceLastUsedGearTwo = gearTwoConfig.activeAbility.abilityCooldownInSeconds; // make it off cooldown immediately
                this.gearTwoCharges = gearTwoConfig.activeAbility.abilityCharges;
                this.gearTwoDurationAbilityDurationInSeconds = gearTwoConfig.activeAbility.abilityDurationInSeconds;
            } else {
                // console.log("Player has a gear two, but it has no active ability! Leaving defaults in SetGearDetails->Active Weapon Setup logic");
            }
        } else {
            // console.log("Player has no gear two equipped! Leaving defaults");
        }

        if (gearThree !== Item.None) {
            const gearThreeConfig = getItemData(gearThree);

            if (gearThreeConfig === undefined) throw new Error(`Gear with id ${gearThree} does not exist in the gear config map; this should never happen`);

            this.gearThreeConfig = gearThreeConfig;

            if (this.gearThreeConfig.activeAbility.ability !== ActiveAbility.None) {
                // console.log("Gear three has an active ability; setting the details now");
                this.gearThreeAbility = gearThreeConfig.activeAbility.ability;
                this.gearThreeCooldownInSeconds = gearThreeConfig.activeAbility.abilityCooldownInSeconds;
                this.secondsSinceLastUsedGearThree = gearThreeConfig.activeAbility.abilityCooldownInSeconds; // make it off cooldown immediately
                this.gearThreeCharges = gearThreeConfig.activeAbility.abilityCharges;
                this.gearThreeDurationAbilityDurationInSeconds = gearThreeConfig.activeAbility.abilityDurationInSeconds;
            } else {
                // console.log("Player has a gear three, but it has no active ability! Leaving defaults in SetGearDetails->Active Weapon Setup logic");
            }
        } else {
            // console.log("Player has no gear three equipped! Leaving defaults");
        }
    }

    public SetOwningEntity(entity: IGameEntityBasic): void {
        this.owningEntity = entity;
    }

    public GetActiveWeaponConfig(): WeaponConfig {
        if (this.activeWeaponSlot === LoadoutSlots.WeaponOne) {
            return this.weaponOneConfig;
        } else if (this.activeWeaponSlot === LoadoutSlots.WeaponTwo) {
            return this.weaponTwoConfig;
        } else {
            throw new Error(`Invalid active weapon slot ${this.activeWeaponSlot}l; unable to retrieve weapon config in GetActiveWeaponConfig`);
        }
    }

    public GetCooldownProgressPercentage(slot: LoadoutSlots): number {
        if (slot === LoadoutSlots.WeaponOne || slot === LoadoutSlots.WeaponTwo) {
            return clamp(Math.floor((this.secondsSinceLastUsedWeapon / this.weaponCooldownInSeconds) * 100), 0, 100);
        } else if (slot === LoadoutSlots.GearOne) {
            return clamp(Math.floor((this.secondsSinceLastUsedGearOne / this.gearOneCooldownInSeconds) * 100), 0, 100);
        } else if (slot === LoadoutSlots.GearTwo) {
            return clamp(Math.floor((this.secondsSinceLastUsedGearTwo / this.gearTwoCooldownInSeconds) * 100), 0, 100);
        } else if (slot === LoadoutSlots.GearThree) {
            return clamp(Math.floor((this.secondsSinceLastUsedGearThree / this.gearThreeCooldownInSeconds) * 100), 0, 100);
        } else {
            throw new Error("Unrecognized loadout slot passed to SharedGearAndWeaponSystem GetCooldownProgressPercentage()");
        }
    }

    public GetCharges(slot: LoadoutSlots): number {
        if (slot === LoadoutSlots.GearOne) {
            return this.gearOneCharges;
        } else if (slot === LoadoutSlots.GearTwo) {
            return this.gearTwoCharges;
        } else if (slot === LoadoutSlots.GearThree) {
            return this.gearThreeCharges;
        }
        return -1;
    }

    public GetOwningEntity(): IGameEntityBasic {
        return this.owningEntity;
    }

    public GetOwningEntityNid(): number {
        return this.owningEntity.nid;
    }

    public GetPlayerMovementImpactModifier(): number {
        return this.playerMovementImpactModifier;
    }

    public ShouldSlowMovementBecauseOfShooting(): boolean {
        return this.playerMovementImpactAccumulator < this.playerMovementImpactDurationInSeconds;
    }

    protected _useActiveAbility(__activeAbility: ActiveAbility, __abilityDefinition: ActiveAbilityDefinition) {}

    public ProcessInputs(inputCommand: InputCommand): void {
        const { delta, usedWeapon, swappedToFirstWeapon, swappedToSecondWeapon, genericSwappedWeapon, usedGearOne, usedGearTwo, usedGearThree } = inputCommand;
        this.secondsSinceLastUsedWeapon += delta;
        this.secondsSinceLastSwappedWeapons += delta;
        this.playerMovementImpactAccumulator += delta;
        this.secondsSinceLastUsedGearOne += delta;
        this.secondsSinceLastUsedGearTwo += delta;
        this.secondsSinceLastUsedGearThree += delta;

        // Check for duration having elapsed for all active abilities that have durations
        if (this.gearOneAbilityIsActive) {
            this.gearOneDurationAbilityAccumulator += delta;
            if (this.gearOneDurationAbilityAccumulator >= this.gearOneDurationAbilityDurationInSeconds) {
                // console.log("gear one duration has completed! resetting the ability!");
                this.gearOneDurationAbilityAccumulator = 0;
                this.gearOneAbilityIsActive = false;
            }
        }
        if (this.gearTwoAbilityIsActive) {
            this.gearTwoDurationAbilityAccumulator += delta;
            if (this.gearTwoDurationAbilityAccumulator >= this.gearTwoDurationAbilityDurationInSeconds) {
                // console.log("gear two duration has completed! resetting the ability!");
                this.gearTwoDurationAbilityAccumulator = 0;
                this.gearTwoAbilityIsActive = false;
            }
        }
        if (this.gearThreeAbilityIsActive) {
            this.gearThreeDurationAbilityAccumulator += delta;
            if (this.gearThreeDurationAbilityAccumulator >= this.gearThreeDurationAbilityDurationInSeconds) {
                // console.log("gear three duration has completed! resetting the ability!");
                this.gearThreeDurationAbilityAccumulator = 0;
                this.gearThreeAbilityIsActive = false;
            }
        }

        // Handle potential weapon swaps first, because they have the potential
        // to reset the weapon use cooldowns (weapon cooldown should reset when
        // a player swaps weapons to prevent the cheese)
        if (this.CanSwapWeapon()) {
            if (genericSwappedWeapon) {
                if (this.activeWeaponSlot === LoadoutSlots.WeaponOne) {
                    this.SwapToWeaponSlot(LoadoutSlots.WeaponTwo);
                } else if (this.activeWeaponSlot === LoadoutSlots.WeaponTwo) {
                    this.SwapToWeaponSlot(LoadoutSlots.WeaponOne);
                }
            } else if (swappedToFirstWeapon) {
                if (this.activeWeaponSlot !== LoadoutSlots.WeaponOne && swappedToFirstWeapon) {
                    this.SwapToWeaponSlot(LoadoutSlots.WeaponOne);
                }
            } else if (swappedToSecondWeapon) {
                if (this.activeWeaponSlot !== LoadoutSlots.WeaponTwo && swappedToSecondWeapon) {
                    this.SwapToWeaponSlot(LoadoutSlots.WeaponTwo);
                }
            }
        }

        if (usedWeapon && this.CanUseWeapon()) {
            this.UseWeapon();
        }

        if (usedGearOne && this.CanUseGear(LoadoutSlots.GearOne)) {
            this.UseGear(LoadoutSlots.GearOne);
        }

        if (usedGearTwo && this.CanUseGear(LoadoutSlots.GearTwo)) {
            this.UseGear(LoadoutSlots.GearTwo);
        }

        if (usedGearThree && this.CanUseGear(LoadoutSlots.GearThree)) {
            this.UseGear(LoadoutSlots.GearThree);
        }
    }

    public Update(__deltaTime: number): void {}

    private _gearHasChargesRemaining(gearSlot: LoadoutSlots): boolean {
        if (gearSlot === LoadoutSlots.GearOne) {
            if (this.gearOneCharges === -1) {
                return true;
            } else {
                return this.gearOneCharges > 0;
            }
        }

        if (gearSlot === LoadoutSlots.GearTwo) {
            if (this.gearTwoCharges === -1) {
                return true;
            } else {
                return this.gearTwoCharges > 0;
            }
        }

        if (gearSlot === LoadoutSlots.GearThree) {
            if (this.gearThreeCharges === -1) {
                return true;
            } else {
                return this.gearThreeCharges > 0;
            }
        }

        return false;
    }

    private _gearIsOffCooldown(gearSlot: LoadoutSlots): boolean {
        if (gearSlot === LoadoutSlots.GearOne) {
            return this.secondsSinceLastUsedGearOne >= this.gearOneCooldownInSeconds;
        }

        if (gearSlot === LoadoutSlots.GearTwo) {
            return this.secondsSinceLastUsedGearTwo >= this.gearTwoCooldownInSeconds;
        }

        if (gearSlot === LoadoutSlots.GearThree) {
            return this.secondsSinceLastUsedGearThree >= this.gearThreeCooldownInSeconds;
        }

        return false;
    }

    private _gearHasAnActiveAbility(gearSlot: LoadoutSlots): boolean {
        if (gearSlot === LoadoutSlots.GearOne) {
            return this.gearOneAbility !== ActiveAbility.None;
        }

        if (gearSlot === LoadoutSlots.GearTwo) {
            return this.gearTwoAbility !== ActiveAbility.None;
        }

        if (gearSlot === LoadoutSlots.GearThree) {
            return this.gearThreeAbility !== ActiveAbility.None;
        }

        return false;
    }

    public CanUseGear(gearSlot: LoadoutSlots): boolean {
        if (this._gearHasAnActiveAbility(gearSlot) === false) return false;

        if (this._gearHasChargesRemaining(gearSlot) === false) return false;

        if (this._gearIsOffCooldown(gearSlot) === false) return false;

        // console.log({ hasActiveAbility: this._gearHasAnActiveAbility(gearSlot), hasChargesRemaining: this._gearHasChargesRemaining(gearSlot), isOffCooldown: this._gearIsOffCooldown(gearSlot) });

        return true;
    }

    public UseGear(gearSlot: LoadoutSlots): void {
        // console.log("Used gear slot: ", LoadoutSlots[gearSlot]);

        if (gearSlot === LoadoutSlots.GearOne) {
            this.secondsSinceLastUsedGearOne = 0;
            if (this.gearTwoConfig !== undefined && this.gearTwoConfig.itemId !== -1) {
                if (this.gearTwoConfig.activeAbility.ability === this.gearOneConfig.activeAbility.ability) {
                    // console.log("gear one and gear two have the same ability! resetting gear two cooldown also to prevent stacking!");
                    this.secondsSinceLastUsedGearTwo = 0;
                }
            }
            if (this.gearThreeConfig !== undefined && this.gearThreeConfig.itemId !== -1) {
                if (this.gearThreeConfig.activeAbility.ability === this.gearOneConfig.activeAbility.ability) {
                    // console.log("gear one and gear three have the same ability! resetting gear three cooldown also to prevent stacking!");
                    this.secondsSinceLastUsedGearThree = 0;
                }
            }
            // if gear one has charges, decrement them by 1 for this use
            if (this.gearOneCharges !== -1) {
                // console.log(`gear one has charges! decrementing by 1! (old: ${this.gearOneCharges} new: ${this.gearOneCharges - 1})`);
                this.gearOneCharges--;
            }
            // if gear one has a duration, reset the accumulator and set the ability to active
            if (this.gearOneDurationAbilityDurationInSeconds !== 0) {
                // console.log("gear one has a duration! setting the ability to active!");
                this.gearOneDurationAbilityAccumulator = 0;
                this.gearOneAbilityIsActive = true;
            }
        }

        if (gearSlot === LoadoutSlots.GearTwo) {
            this.secondsSinceLastUsedGearTwo = 0;
            if (this.gearOneConfig !== undefined && this.gearOneConfig.itemId !== -1) {
                if (this.gearOneConfig.activeAbility.ability === this.gearTwoConfig.activeAbility.ability) {
                    // console.log("gear two and gear one have the same ability! resetting gear one cooldown also to prevent stacking!");
                    this.secondsSinceLastUsedGearOne = 0;
                }
            }

            if (this.gearThreeConfig !== undefined && this.gearThreeConfig.itemId !== -1) {
                if (this.gearThreeConfig.activeAbility.ability === this.gearTwoConfig.activeAbility.ability) {
                    // console.log("gear two and gear three have the same ability! resetting gear three cooldown also to prevent stacking!");
                    this.secondsSinceLastUsedGearThree = 0;
                }
            }
            // if gear two has charges, decrement them by 1 for this use
            if (this.gearTwoCharges !== -1) {
                // console.log(`gear two has charges! decrementing by 1! (old: ${this.gearTwoCharges} new: ${this.gearTwoCharges - 1})`);
                this.gearTwoCharges--;
            }
            // if gear two has a duration, reset the accumulator and set the ability to active
            if (this.gearTwoDurationAbilityDurationInSeconds !== 0) {
                // console.log("gear two has a duration! setting the ability to active!");
                this.gearTwoDurationAbilityAccumulator = 0;
                this.gearTwoAbilityIsActive = true;
            }
        }

        if (gearSlot === LoadoutSlots.GearThree) {
            this.secondsSinceLastUsedGearThree = 0;
            if (this.gearOneConfig !== undefined && this.gearOneConfig.itemId !== -1) {
                if (this.gearOneConfig.activeAbility.ability === this.gearThreeConfig.activeAbility.ability) {
                    // console.log("gear three and gear one have the same ability! resetting gear one cooldown also to prevent stacking!");
                    this.secondsSinceLastUsedGearOne = 0;
                }
            }
            if (this.gearTwoConfig !== undefined && this.gearTwoConfig.itemId !== -1) {
                if (this.gearTwoConfig.activeAbility.ability === this.gearThreeConfig.activeAbility.ability) {
                    // console.log("gear three and gear two have the same ability! resetting gear two cooldown also to prevent stacking!");
                    this.secondsSinceLastUsedGearTwo = 0;
                }
            }
            // if gear three has charges, decrement them by 1 for this use
            if (this.gearThreeCharges !== -1) {
                // console.log(`gear three has charges! decrementing by 1! (old: ${this.gearThreeCharges} new: ${this.gearThreeCharges - 1})`);
                this.gearThreeCharges--;
            }
            // if gear three has a duration, reset the accumulator and set the ability to active
            if (this.gearThreeDurationAbilityDurationInSeconds !== 0) {
                // console.log("gear three has a duration! setting the ability to active!");
                this.gearThreeDurationAbilityAccumulator = 0;
                this.gearThreeAbilityIsActive = true;
            }
        }
    }

    public UseWeapon(): void {
        this.secondsSinceLastUsedWeapon = 0;
        this.playerMovementImpactAccumulator = 0;
    }

    public CanUseWeapon(): boolean {
        return this.secondsSinceLastUsedWeapon >= this.weaponCooldownInSeconds;
    }

    public SwapToWeaponSlot(newSlot: LoadoutSlots.WeaponOne | LoadoutSlots.WeaponTwo): void {
        this.activeWeaponSlot = newSlot;

        const newActiveWeaponConfig = this.GetActiveWeaponConfig();

        const attackSpeedMultiplier = (this.GetOwningEntity() as SharedPlayer).attackSpeedMultiplier;

        // console.warn("@@@ attack speed multiplier in weapon setup");
        // console.log(attackSpeedMultiplier);

        this.weaponCooldownInSeconds = newActiveWeaponConfig.cooldownInSeconds * attackSpeedMultiplier;

        this.playerMovementImpactDurationInSeconds = newActiveWeaponConfig.playerMovementMultiplierDurationInSeconds;
        this.playerMovementImpactModifier = newActiveWeaponConfig.playerMovementMultiplier;

        // Set the movement impact modifier to its default duration so that it doesnt cause them to slow down just by activating the weapon
        this.playerMovementImpactAccumulator = newActiveWeaponConfig.playerMovementMultiplierDurationInSeconds;

        this.secondsSinceLastSwappedWeapons = 0;
        // Also reset the weapon cooldown so that the player cant use the weapon immediately the same frame as swapping
        this.secondsSinceLastUsedWeapon = 0;
    }

    public CanSwapWeapon(): boolean {
        return this.secondsSinceLastSwappedWeapons >= this.weaponSwapCooldownInSeconds;
    }

    public Initialize(): void {
        this.LogInfo("Ready!");
    }

    protected override getSystemName(): string {
        return "Weapon";
    }

    public Cleanup(): void {}
}

export const enemyConfig = {
    MOVEMENT_COLLIDER_Y_OFFSET: 9,
    MOVEMENT_COLLIDER_RADIUS: 6,
    HITBOX_COLLIDER_WIDTH: 12,
    HITBOX_COLLIDER_HEIGHT: 20,

    // TODO: each enemy should probably define these separately
    AGGRO_COLLIDER_RADIUS: 180,
    MAX_CHASE_OUTER_COLLIDER_RADIUS: 300,
    MAX_CHASE_INNER_COLLIDER_RADIUS: 200,
    SPEED_PER_SECOND: 40,
    ATTACK_RANGE: 100,

    USE_PATHFINDING: true,

    DEBUG: {
        ALWAYS_DROP_LOOT: false,

        INSTANT_RESPAWNS: false,

        DISABLE_ALL_AI: false,

        AI_ARE_STATIC: false,

        // Centerpoint visual
        DRAW_CENTERPOINT: false,

        // Movement collider visuals
        DRAW_MOVEMENT_COLLIDER: false,

        // Hitbox collider visuals
        DRAW_HITBOX_COLLIDER: false,

        // Theoretical projectile origin indicator
        DRAW_PROJECTILE_ORIGIN_INDICATOR: false,

        // Show position text
        DRAW_POSITION_TEXT: false,

        // Show state
        DRAW_BEHAVIOUR_STATE: false,

        // Show state target position
        DRAW_BEHAVIOUR_DESTINATION: false,

        // Show the collider radius where upon a player entering the enemy will chase the player
        DRAW_AGGRO_RADIUS: false,

        // Show the collider radius where upon the AI enemy leaving will return to it's spawn
        DRAW_MAX_CHASE_RADIUS: false,

        // Pathfinding visualization
        DRAW_PATHFINDING_GRID: false
    }
};

import { Circle, Vector, Polygon } from "sat";
import { NType } from "./SharedNetcodeSchemas";
import { CollisionGridCell } from "./systems/SharedCollisionSystem";
import { CharacterSkin } from "./data/Data_Cosmetics";
import { ItemContainerMap, Item, DamageType, ItemRarity } from "./data/Data_Items";
import { LoadoutSlots } from "./systems/SharedGearAndWeaponSystem";

/*************************************************/
/*                                               */
/*  Misc / General                               */
/*                                               */
/*************************************************/

export type NetworkEntityId = number;

export type uuid = string;

export interface IGameEntityBase {
    nid: NetworkEntityId;
    ntype: NType;
    x: number;
    y: number;
    position: Vector;
}

export interface IGameEntityThatUpdates {
    Update(deltaTime: number, currentTimeInMilliseconds?: number): void;
}

export interface IGameEntityWithColliders {
    MovementCollider?: Circle;
    HitboxCollider: Circle | Polygon;
    currentCollisionGridCell: CollisionGridCell;
}

export interface IGameEntityThatMoves extends IGameEntityBase {
    prevX: number;
    prevY: number;
    velocity: Vector;
    Move(deltaTime: number): void;
}

export interface IGameEntityWithHealth {
    maxHealth: number;
    currentHealth: number;
    currentHealthPercentage: number;
    isAlive: boolean | number;
    TakeDamage(damageAmount: number, damageType: DamageType, damageDealerNid: NetworkEntityId, damageModifier: number): void;
}

export interface IGameEntityWithOwner {
    owningEntityNid: NetworkEntityId;
}

// Convenience composite types from the above component interfaces
export interface IGameEntityBasic extends IGameEntityBase, IGameEntityThatUpdates {}

export interface ICollidableGameEntity extends IGameEntityBase, IGameEntityWithColliders {}

export interface IInteractableEntity extends ICollidableGameEntity {
    isInteractable: true;
}

export enum ServerConnectionErrorReasons {
    GameClientOutOfDate = "GameClientOutOfDate",
    InvalidConnectionToken = "InvalidConnectionToken",
    UnexpectedConnectionError = "UnexpectedConnectionError",
    InvalidRunDetails = "InvalidRunDetails",
    PlayerAlreadyLoggedIn = "PlayerAlreadyLoggedIn"
}

export enum FacingDirection {
    Left = 0,
    Right = 1
}

const supportedLanguages = [
    // English
    "en",

    // Spanish
    "es",

    // Russian
    "ru",

    // Chinese (Simplified)
    "zh-cn",

    // German
    "de",

    // Japanese
    "ja",

    // Portuguese
    "pt",

    // Korean
    "ko",

    // Hindi
    "hi",

    // French
    "fr",

    // Arabic
    "ar",

    // Italian
    "it",

    // Polish
    "pl",

    // Turkish
    "tr"
] as const;
export type SupportedLanguages = (typeof supportedLanguages)[number];

const supportedMatchmakingRegions = ["any", "us-east", "us-west", "eu", "australia", "asia", "south-america"] as const;
export type SupportedMatchmakingRegions = (typeof supportedMatchmakingRegions)[number];

/*************************************************/
/*                                               */
/*  Backend                                      */
/*                                               */
/*************************************************/

export enum UserType {
    Anonymous,
    Registered
}

export interface PlayerLoadout {
    characterSkin: CharacterSkin;
    weaponOne: Item;
    weaponTwo: Item;
    gearOne: Item;
    gearTwo: Item;
    gearThree: Item;
    insuredSlot: LoadoutSlots;
}

export enum ContainerType {
    LootChest = 0,
    PlayerGravestone = 1,
    EnemyDrop = 2
}

interface BaseUser {
    username: string;
    createdAt: Date;

    SMG_Stats: {
        logins: number;
        lastLoggedIn: Date;
    };

    PVPZ_GameState: {
        currentRunId: uuid | null;
        currentlyOnARun: boolean;
    };

    PVPZ_Stats: {
        runsEmbarkedOn: number;
        successfulExtractions: number;
        failedExtractions: number;
        goldLooted: number;
        itemsLooted: number;
        playerKills: number;
        npcKills: number;
        timeSpentOnRunsInSeconds: number;
        distanceTravelled: number;
        commonItemsLooted: number;
        uncommonItemsLooted: number;
        rareItemsLooted: number;
        epicItemsLooted: number;
        legendaryItemsLooted: number;
        bossesKilled: number;
        goldLost: number;
        itemsLost: number;
    };

    PVPZ_Stash: ItemContainerMap;

    PVPZ_Preferences: {
        enableMusic: boolean;
        enableSoundEffects: boolean;
        preferredLanguage: SupportedLanguages;
        preferredMatchmakingRegion: SupportedMatchmakingRegions;
    };

    PVPZ_Loadout: {
        characterSkin: CharacterSkin;
        weaponOne: Item;
        weaponTwo: Item;
        gearOne: Item;
        gearTwo: Item;
        gearThree: Item;
        insuredSlot: LoadoutSlots;
    };
}

export interface RegisteredUser extends BaseUser {
    userType: UserType.Registered;
}

export interface AnonymousUser extends BaseUser {
    _id: uuid;
    userType: UserType.Anonymous;
}

/*************************************************/
/*                                               */
/*  Server Event Feed                            */
/*                                               */
/*************************************************/

export enum ServerEventFeedEventTypes {
    Loot = 0,
    Kill = 1,
    BossSpawn = 2,
    BossAttack = 3,
    ExtractionStarted = 4,
    ExtractionSuccessful = 5
}

export interface LootServerEvent {
    id: number;
    type: ServerEventFeedEventTypes.Loot;
    username: string;
    rarity: ItemRarity;
    locationX: number;
    locationY: number;
}

export interface KillServerEvent {
    id: number;
    type: ServerEventFeedEventTypes.Kill;
    killer: string;
    victim: string;
    locationX: number;
    locationY: number;
}

export interface BossSpawnServerEvent {
    id: number;
    type: ServerEventFeedEventTypes.BossSpawn;
    bossName: string;
    locationX: number;
    locationY: number;
}

export interface BossAttackServerEvent {
    id: number;
    type: ServerEventFeedEventTypes.BossAttack;
    bossName: string;
    locationX: number;
    locationY: number;
}

export interface ExtractionStartedServerEvent {
    id: number;
    type: ServerEventFeedEventTypes.ExtractionStarted;
    playerName: string;
    locationX: number;
    locationY: number;
}

export interface ExtractionSuccessfulServerEvent {
    id: number;
    type: ServerEventFeedEventTypes.ExtractionSuccessful;
    playerName: string;
    locationX: number;
    locationY: number;
}

export type ServerEvent = LootServerEvent | KillServerEvent | BossSpawnServerEvent | BossAttackServerEvent | ExtractionStartedServerEvent | ExtractionSuccessfulServerEvent;

import { GameplaySystem } from "../../shared/engine/SharedGameplaySystem";
import { Root, createRoot } from "react-dom/client";
import { UIRoot } from "./framework/UI_Root";
import { UI_SetLoadingComplete, UI_SetAnonymousUserIdentity, UI_SetRegisteredUserIdentity, UI_UserIdentityError, UI_UpdatedPreferencesFromServer, UI_UpdatedInventoryFromServer, UI_UpdatedLoadoutFromBackend, UI_CheckMaintenanceModeStatus } from "./framework/UI_State";
import { AnonymousUser, PlayerLoadout, RegisteredUser } from "../../shared/SharedTypes";
import { ItemContainerMap } from "../../shared/data/Data_Items";

export class ClientUI extends GameplaySystem {
    private reactRoot: Root;

    public constructor() {
        super();
        this.reactRoot = createRoot(document.getElementById("ui-root") as HTMLElement);

        Game.ListenForEvent("Assets::Loaded", () => UI_SetLoadingComplete());

        Game.ListenForEvent("Identity::Identified_Anonymous", ({ identity }: { identity: AnonymousUser }) => {
            UI_SetAnonymousUserIdentity(identity);
            UI_UpdatedPreferencesFromServer(identity.PVPZ_Preferences);
            // console.log(identity.PVPZ_Stash);
            UI_UpdatedInventoryFromServer(identity.PVPZ_Stash as ItemContainerMap);
            UI_UpdatedLoadoutFromBackend(identity.PVPZ_Loadout as PlayerLoadout);
        });
        Game.ListenForEvent("Identity::Identified_Registered", ({ identity }: { identity: RegisteredUser }) => {
            UI_SetRegisteredUserIdentity(identity);
            UI_UpdatedPreferencesFromServer(identity.PVPZ_Preferences);
            // console.log(identity.PVPZ_Stash);
            UI_UpdatedInventoryFromServer(identity.PVPZ_Stash as ItemContainerMap);
            UI_UpdatedLoadoutFromBackend(identity.PVPZ_Loadout as PlayerLoadout);
        });

        Game.ListenForEvent("Identity::Error::IncorrectIdentityProvider", () => UI_UserIdentityError("It looks like you're trying to log in with the wrong platform associated to this user. Please try again."));
        Game.ListenForEvent("Identity::Error::AuthExpired", () => UI_UserIdentityError("Sorry about that, your session has expired! We haven't seen you in awhile so we've logged you out for now. You'll need to log in again!"));
        Game.ListenForEvent("Identity::Error::OAuthFailed", () => UI_UserIdentityError("There was an error logging you in with the chosen platform. Please try again."));
        Game.ListenForEvent("Identity::Error::UnknownIrrecoverableAPIError", () => UI_UserIdentityError("Something went wrong logging you in. Please try again later."));
    }

    public override Initialize(): void {
        this.reactRoot.render(UIRoot());
        this.LogInfo("Ready!");
    }

    protected override getSystemName(): string {
        return "UI";
    }

    public override Update(__deltaTime: number): void {
        throw new Error("Method not implemented.");
    }

    public Cleanup(): void {
        this.LogInfo("Cleaning up...");
    }
}

export const ClientAPIRoutes = {
    // Anonymous User
    getAnonymousUserMe: "/pvpz/anonymous-user/me",
    createAnonymousUser: "/pvpz/anonymous-user",
    registerAnonymousUserEmail: "/pvpz/anonymous-user/register-email",

    // User
    changeUsername: "/user/change-username",
    getMyUserDetails: "/pvpz/user/me",
    userLogin: "/pvpz/user/login",
    postUserChangeUsername: "/pvpz/user/change-username",
    postUserVerifyEmail: "/pvpz/user/verify-email",
    postUserAnonymousPromotion: "/pvpz/user/anonymous-promotion",
    postBuySkinWithGems: "/pvpz/xsolla/buy-skin-with-gems",
    postBuyInsuranceWithGems: "/pvpz/xsolla/buy-insurance-with-gems",
    postBuyInsuranceWithCoins: "/pvpz/xsolla/buy-insurance-with-coins",
    postGetPurchaseLinkForSKU: "/pvpz/xsolla/get-purchase-link-for-sku",

    // Either user type
    maintenanceStatus: "/pvpz/maintenance-status",
    startRun: "/pvpz/game/start-run",
    abandonRun: "/pvpz/game/abandon-run",
    updateLoadout: "/pvpz/game/update-loadout",
    updatePreferences: "/pvpz/game/update-preferences",
    insureSlot: "/pvpz/game/insure-slot",
    equipItemToSlot: "/pvpz/game/equip-item-to-slot",
    equipSkin: "/pvpz/game/equip-skin"
};

import { Box, Circle, Polygon, Response, Vector, testPolygonCircle } from "sat";
import { GameplaySystem } from "../../shared/engine/SharedGameplaySystem";
import { ICollidableGameEntity, IGameEntityBasic } from "../SharedTypes";
import { NType } from "../SharedNetcodeSchemas";
import { worldConfig } from "../config/Config_World";
import { collisionConfig } from "../config/Config_Collision";
import { SharedProjectile } from "../entities/SharedProjectile";
import { SharedPlayer } from "../entities/SharedPlayer";
import { SharedExtractionPoint } from "../entities/SharedExtractionPoint";
import { SharedAIEntity } from "../entities/SharedAIEntity";
import { getHighResolutionTimestampMsNewNew } from "../SharedUtils";
import { CollisionGridTileIds, CollisionsLayerFromTiledMap, Ground1LayerFromTiledMap } from "../data/Data_WorldImport";
import { SharedItemContainer } from "../entities/SharedItemContainer";

/***************************************************************************/
/* One optimization that can be made here, and probably should be          */
/* made at the first sign of perf trouble, is to cache the indexes of      */
/* everything eveywhere. I.E., cache which grid cell index that an entity  */
/* belongs to on the entity itself to avoid that lookup, cache the index   */
/* of the entity in the entity sets within the grid cell as well and then  */
/* use splice etc for faster removal. Will avoid a lot of lookups and      */
/* prevent the current O(n) time complexity. I am skeptical that this will */
/* be necessary given how much budget we currently have in the server      */
/* frames though, so just keeping this in my back pocket here for now      */
/***************************************************************************/

export class CollisionGridCell implements IGameEntityBasic {
    public nid: number = -1;
    public ntype: NType = NType.DebugGridCellEntity;
    public column: number;
    public row: number;
    public indexInGrid: number = -1;
    public isActive: boolean = false;
    public position: Vector = new Vector(0, 0);

    public players: Set<ICollidableGameEntity> = new Set();
    public projectiles: Set<ICollidableGameEntity> = new Set();
    public extractionPoints: Set<ICollidableGameEntity> = new Set();
    public enemies: Set<ICollidableGameEntity> = new Set();
    public interactables: Set<ICollidableGameEntity> = new Set();

    public constructor(columnIndex: number, rowIndex: number, xPosition: number, yPosition: number, indexInGrid: number) {
        this.x = xPosition;
        this.y = yPosition;
        this.column = columnIndex;
        this.row = rowIndex;
        this.indexInGrid = indexInGrid;
    }

    public IsEmpty(): boolean {
        return this.HasPlayers() === false && this.HasProjectiles() === false;
    }

    public HasPlayers(): boolean {
        return this.players.size > 0;
    }

    public HasProjectiles(): boolean {
        return this.projectiles.size > 0;
    }

    public GetColumnAndRow(): { column: number; row: number } {
        return {
            column: this.column,
            row: this.row
        };
    }

    public AddEntity(entity: ICollidableGameEntity) {
        if (entity.ntype === NType.PlayerEntity) {
            this.players.add(entity);
        } else if (entity.ntype === NType.ProjectileEntity) {
            this.projectiles.add(entity);
        } else if (entity.ntype === NType.ExtractionPointEntity) {
            this.extractionPoints.add(entity);
        } else if (entity.ntype === NType.AIEntity) {
            this.enemies.add(entity);
        } else if (entity.ntype === NType.ItemContainerEntity) {
            this.interactables.add(entity);
        } else {
            throw new Error(`Tried to add entity of unknown type to grid cell #${this.indexInGrid}: ${entity.ntype}`);
        }
    }

    public RemoveEntity(entity: ICollidableGameEntity) {
        if (entity.ntype === NType.PlayerEntity) {
            this.players.delete(entity);
        } else if (entity.ntype === NType.ProjectileEntity) {
            this.projectiles.delete(entity);
        } else if (entity.ntype === NType.AIEntity) {
            this.enemies.delete(entity);
        } else if (entity.ntype === NType.ItemContainerEntity) {
            this.interactables.delete(entity);
        } else {
            throw new Error(`Tried to remove entity of unknown type from grid cell #${this.indexInGrid}: ${entity.ntype}`);
        }
    }

    public get width(): number {
        return collisionConfig.GRID_CELL_SIZE;
    }

    public get height(): number {
        return collisionConfig.GRID_CELL_SIZE;
    }

    public get x(): number {
        return this.position.x;
    }

    public set x(newX: number) {
        this.position.x = newX;
    }

    public get y(): number {
        return this.position.y;
    }

    public set y(newY: number) {
        this.position.y = newY;
    }

    public Update(__deltaTime: number): void {}
}

type TileInfo = {
    [key: number]: {
        blocksProjectiles: boolean;
        height: number;
        width: number;
        offsetY: number;
        offsetX: number;
    };
};

export class BroadphaseCollisionGrid {
    public gridCells: Array<CollisionGridCell> = new Array<CollisionGridCell>();
    public gridWidth: number = worldConfig.WORLD_SIZE / collisionConfig.GRID_CELL_SIZE;
    public gridHeight: number = worldConfig.WORLD_SIZE / collisionConfig.GRID_CELL_SIZE;

    public constructor() {
        // Build the spatial collision grid
        for (let rowIndex = 0; rowIndex < this.gridWidth; rowIndex++) {
            for (let columnIndex = 0; columnIndex < this.gridHeight; columnIndex++) {
                const xPosition = columnIndex * collisionConfig.GRID_CELL_SIZE;
                const yPosition = rowIndex * collisionConfig.GRID_CELL_SIZE;
                const indexIn1DGrid = this.gridCells.length;
                this.gridCells.push(new CollisionGridCell(columnIndex, rowIndex, xPosition, yPosition, indexIn1DGrid));
            }
        }
    }

    public DeactivateAllGridCells(): void {
        for (const gridCell of this.gridCells) {
            gridCell.isActive = false;
        }
    }

    public GetGridCellFromGridCellIndex(gridCellIndex: number): CollisionGridCell {
        return this.gridCells[gridCellIndex];
    }

    public GetAllBroadphaseGridCells(): Array<CollisionGridCell> {
        return this.gridCells;
    }

    public GetGridCellFromGridXY(gridX: number, gridY: number): CollisionGridCell | undefined {
        return this.gridCells[gridY * this.gridWidth + gridX];
    }

    public GetGridCellIndexFromWorldXY(x: number, y: number): number {
        const gridX = Math.floor(x / collisionConfig.GRID_CELL_SIZE);
        const gridY = Math.floor(y / collisionConfig.GRID_CELL_SIZE);

        return gridY * (worldConfig.WORLD_SIZE / collisionConfig.GRID_CELL_SIZE) + gridX;
    }

    public GetGridCellFromWorldXY(x: number, y: number): CollisionGridCell {
        return this.gridCells[this.GetGridCellIndexFromWorldXY(x, y)];
    }

    public AddEntity(entity: ICollidableGameEntity, knownDestinationGridCell?: number): void {
        let gridCell;

        if (knownDestinationGridCell !== undefined) {
            gridCell = this.GetGridCellFromGridCellIndex(knownDestinationGridCell);
        } else {
            gridCell = this.GetGridCellFromWorldXY(entity.x, entity.y);
        }

        if (gridCell === undefined) {
            console.error(`Entity position x${entity.x},y${entity.y} did not produce a valid collision grid cell! This should never happen, entity possible out of world bounds?`);
        } else {
            entity.currentCollisionGridCell = gridCell;
            gridCell.AddEntity(entity);
        }
    }

    public RemoveEntity(entity: ICollidableGameEntity): void {
        const gridCell = entity.currentCollisionGridCell;
        if (gridCell === undefined) {
            console.error("Tried to remove entity from grid cell but entity did not have a valid currentCollisionGridCell! This should never happen!");
        } else {
            gridCell.RemoveEntity(entity);
        }
    }

    public UpdateEntityPositionsInGrid(): void {
        for (const gridCell of this.gridCells) {
            for (const player of gridCell.players) {
                const newGridCell = this.GetGridCellFromWorldXY(player.x, player.y);
                if (newGridCell === undefined) {
                    console.log(`Position x/y ${player.x},${player.y} resulted in bad collision grid; ignoring`);
                    continue;
                } else {
                    if (newGridCell.indexInGrid !== gridCell.indexInGrid) {
                        this.RemoveEntity(player);
                        this.AddEntity(player, newGridCell.indexInGrid);
                    }
                }
            }
            for (const projectile of gridCell.projectiles) {
                const newGridCell = this.GetGridCellFromWorldXY(projectile.x, projectile.y);
                if (newGridCell === undefined) {
                    console.log(`Position x/y ${projectile.x},${projectile.y} resulted in bad collision grid; ignoring`);
                    continue;
                } else {
                    if (newGridCell.indexInGrid !== gridCell.indexInGrid) {
                        this.RemoveEntity(projectile);
                        this.AddEntity(projectile, newGridCell.indexInGrid);
                    }
                }
            }
            for (const enemy of gridCell.enemies) {
                const newGridCell = this.GetGridCellFromWorldXY(enemy.x, enemy.y);
                if (newGridCell === undefined) {
                    console.log(`Position x/y ${enemy.x},${enemy.y} resulted in bad collision grid; ignoring`);
                    continue;
                } else {
                    if (newGridCell.indexInGrid !== gridCell.indexInGrid) {
                        this.RemoveEntity(enemy);
                        this.AddEntity(enemy, newGridCell.indexInGrid);
                    }
                }
            }
            // TODO update AI positions in grid?
        }
    }
}

class StaticMapTileCollisionGrid {
    public static readonly TileInfo: TileInfo = {
        [CollisionGridTileIds.EMPTY]: { height: 0, width: 0, offsetY: 0, offsetX: 0, blocksProjectiles: true },

        [CollisionGridTileIds.SOLID]: { height: 16, width: 16, offsetY: 0, offsetX: 0, blocksProjectiles: true },
        [CollisionGridTileIds.TOP_HALF]: { height: 8, width: 16, offsetY: 0, offsetX: 0, blocksProjectiles: true },
        [CollisionGridTileIds.BOTTOM_HALF]: { height: 8, width: 16, offsetY: 8, offsetX: 0, blocksProjectiles: true },
        [CollisionGridTileIds.LEFT_HALF]: { height: 16, width: 8, offsetY: 0, offsetX: 0, blocksProjectiles: true },
        [CollisionGridTileIds.RIGHT_HALF]: { height: 16, width: 8, offsetY: 0, offsetX: 8, blocksProjectiles: true },
        [CollisionGridTileIds.TOP_LEFT_QUARTER]: { height: 8, width: 8, offsetY: 0, offsetX: 0, blocksProjectiles: true },
        [CollisionGridTileIds.TOP_RIGHT_QUARTER]: { height: 8, width: 8, offsetY: 0, offsetX: 8, blocksProjectiles: true },
        [CollisionGridTileIds.BOTTOM_LEFT_QUARTER]: { height: 8, width: 8, offsetY: 8, offsetX: 0, blocksProjectiles: true },
        [CollisionGridTileIds.BOTTOM_RIGHT_QUARTER]: { height: 8, width: 8, offsetY: 8, offsetX: 8, blocksProjectiles: true },

        [CollisionGridTileIds.LOW_SOLID]: { height: 16, width: 16, offsetY: 0, offsetX: 0, blocksProjectiles: false },
        [CollisionGridTileIds.LOW_TOP_HALF]: { height: 8, width: 16, offsetY: 0, offsetX: 0, blocksProjectiles: false },
        [CollisionGridTileIds.LOW_BOTTOM_HALF]: { height: 8, width: 16, offsetY: 8, offsetX: 0, blocksProjectiles: false },
        [CollisionGridTileIds.LOW_LEFT_HALF]: { height: 16, width: 8, offsetY: 0, offsetX: 0, blocksProjectiles: false },
        [CollisionGridTileIds.LOW_RIGHT_HALF]: { height: 16, width: 8, offsetY: 0, offsetX: 8, blocksProjectiles: false },
        [CollisionGridTileIds.LOW_TOP_LEFT_QUARTER]: { height: 8, width: 8, offsetY: 0, offsetX: 0, blocksProjectiles: false },
        [CollisionGridTileIds.LOW_TOP_RIGHT_QUARTER]: { height: 8, width: 8, offsetY: 0, offsetX: 8, blocksProjectiles: false },
        [CollisionGridTileIds.LOW_BOTTOM_LEFT_QUARTER]: { height: 8, width: 8, offsetY: 8, offsetX: 0, blocksProjectiles: false },
        [CollisionGridTileIds.LOW_BOTTOM_RIGHT_QUARTER]: { height: 8, width: 8, offsetY: 8, offsetX: 8, blocksProjectiles: false }
    };

    private mapTileCollisionGrid: (Polygon | null)[] = [];

    public constructor() {
        for (let i = 0; i < CollisionsLayerFromTiledMap.data.length; i++) {
            const collisionTileId = CollisionsLayerFromTiledMap.data[i];

            if (!StaticMapTileCollisionGrid.TileInfo[collisionTileId]) {
                console.error(`Unknown collision tile ID found in collision grid: ${collisionTileId}`);
            }

            // Dont create collision tiles for empty tiles
            if (collisionTileId === CollisionGridTileIds.EMPTY || !StaticMapTileCollisionGrid.TileInfo[collisionTileId]) {
                this.mapTileCollisionGrid.push(null);
            } else {
                const gridX = Math.floor(i % worldConfig.WORLD_TILE_DIMENSIONS);
                const gridY = Math.floor(i / worldConfig.WORLD_TILE_DIMENSIONS);
                const { height, width, offsetY, offsetX, blocksProjectiles } = StaticMapTileCollisionGrid.TileInfo[collisionTileId];

                const polygonForThisTile = new Box(new Vector(gridX * worldConfig.TILE_SIZE + offsetX, gridY * worldConfig.TILE_SIZE + offsetY), width, height).toPolygon();

                // @ts-ignore TODO: hack
                (polygonForThisTile as any).blocksProjectiles = blocksProjectiles;

                this.mapTileCollisionGrid.push(polygonForThisTile);
            }
        }
    }

    public GetCollisionPolygonForGridXY(gridX: number, gridY: number): Polygon | null {
        const tileIndex = gridY * worldConfig.WORLD_TILE_DIMENSIONS + gridX;
        return this.mapTileCollisionGrid[tileIndex];
    }
}

export class SharedCollisionSystem extends GameplaySystem {
    private players: Set<ICollidableGameEntity> = new Set<ICollidableGameEntity>();
    private projectiles: Set<ICollidableGameEntity> = new Set<ICollidableGameEntity>();
    private extractionPoints: Set<ICollidableGameEntity> = new Set<ICollidableGameEntity>();
    private enemies: Set<ICollidableGameEntity> = new Set<ICollidableGameEntity>();
    private interactables: Set<ICollidableGameEntity> = new Set<ICollidableGameEntity>();
    protected reusableCollisionResponseObject: Response = new Response();

    protected collisionsCheckedThisFrame: number = 0;

    private broadphaseCollisionGrid: BroadphaseCollisionGrid = new BroadphaseCollisionGrid();

    public staticMapTileCollisionGrid: StaticMapTileCollisionGrid = new StaticMapTileCollisionGrid();

    public static readonly GridOffsets: Array<Array<number>> = [
        [0, 0], // Center
        [-1, -1], // Top left
        [0, -1], // Top center
        [1, -1], // Top right
        [-1, 0], // Left
        [1, 0], // Right
        [-1, 1], // Bottom left
        [0, 1], // Bottom center
        [1, 1] // Bottom right
    ];

    public constructor() {
        super();
    }

    public Initialize(): void {
        this.LogInfo("Ready!");
    }

    public GetAllGridCells(): Array<CollisionGridCell> {
        return this.broadphaseCollisionGrid.GetAllBroadphaseGridCells();
    }

    protected override getSystemName(): string {
        return "Collision";
    }

    public AddEntity(entity: ICollidableGameEntity): void {
        if (entity.ntype === NType.PlayerEntity) {
            this.players.add(entity as SharedPlayer);
        } else if (entity.ntype === NType.ProjectileEntity) {
            this.projectiles.add(entity as SharedProjectile);
        } else if (entity.ntype === NType.ExtractionPointEntity) {
            this.extractionPoints.add(entity as SharedExtractionPoint);
        } else if (entity.ntype === NType.AIEntity) {
            this.enemies.add(entity as SharedAIEntity);
        } else if (entity.ntype === NType.ItemContainerEntity) {
            this.interactables.add(entity as SharedItemContainer);
        }
        this.broadphaseCollisionGrid.AddEntity(entity);
    }

    public IsTileAtGridXYaSpecificTileId(gridX: number, gridY: number, tileId: number): boolean {
        return Ground1LayerFromTiledMap.data[gridY * worldConfig.WORLD_TILE_DIMENSIONS + gridX] === tileId;
    }

    public GetCollisionPolygonAtGridXY(gridX: number, gridY: number): Polygon | null {
        return this.staticMapTileCollisionGrid.GetCollisionPolygonForGridXY(gridX, gridY);
    }

    public PerformOneOffCirclePolygonCollisionCheck(circle: Circle, polygon: Polygon): Response | false {
        this.collisionsCheckedThisFrame++;

        const response = new Response();
        const collisionResponseResult: boolean = testPolygonCircle(polygon, circle, response);

        if (collisionResponseResult) {
            return response;
        } else {
            return false;
        }
    }

    public RemoveEntity(entity: ICollidableGameEntity): void {
        if (entity.ntype === NType.PlayerEntity) {
            this.players.delete(entity as SharedPlayer);
        } else if (entity.ntype === NType.ProjectileEntity) {
            this.projectiles.delete(entity as SharedProjectile);
        } else if (entity.ntype === NType.ExtractionPointEntity) {
            this.extractionPoints.delete(entity as SharedExtractionPoint);
        } else if (entity.ntype === NType.AIEntity) {
            this.enemies.delete(entity as SharedAIEntity);
        } else if (entity.ntype === NType.ItemContainerEntity) {
            this.interactables.delete(entity as SharedItemContainer);
        }
        this.broadphaseCollisionGrid.RemoveEntity(entity);
    }

    // public OneOffCollisionCheck;

    public Update(__deltaTime: number) {
        if (collisionConfig.DEBUG.DRAW_BROADPHASE_SPATIAL_GRID) {
            this.broadphaseCollisionGrid.DeactivateAllGridCells();
        }

        this.broadphaseCollisionGrid.UpdateEntityPositionsInGrid();

        const collisionUpdateStartTimestampMs = getHighResolutionTimestampMsNewNew();

        for (const enemy of this.enemies) {
            const enemyEntity = enemy as SharedAIEntity;
            const enemyCurrentGridCell = enemyEntity.currentCollisionGridCell;

            if (enemyEntity.isAlive === false) {
                continue;
            }

            for (const offset of SharedCollisionSystem.GridOffsets) {
                const [x, y] = offset;

                const adjacentX = enemyCurrentGridCell.column + x;
                const adjacentY = enemyCurrentGridCell.row + y;

                // If the potential grid cell that is adjacent to this grid cell is out of the bounds of the world, skip it as this grid cell does not exist
                if (adjacentX > this.broadphaseCollisionGrid.gridWidth - 1 || adjacentY > this.broadphaseCollisionGrid.gridHeight - 1 || adjacentX < 0 || adjacentY < 0) {
                    continue;
                }

                const relevantSpatialGridCell = this.broadphaseCollisionGrid.GetGridCellFromGridXY(enemyCurrentGridCell.column + x, enemyCurrentGridCell.row + y);

                if (relevantSpatialGridCell === undefined) {
                    this.LogWarning("relevantGridCell is undefined despite above safeguards, skipping iteration, likely collision system bug");
                    continue;
                }

                if (collisionConfig.DEBUG.DRAW_BROADPHASE_SPATIAL_GRID) {
                    relevantSpatialGridCell.isActive = true;
                }

                /**********************************************************************/
                /*                                                                    */
                /*                                                                    */
                /*                  AI <-> Projectile Collision                       */
                /*                                                                    */
                /*                                                                    */
                /**********************************************************************/
                for (const projectile of relevantSpatialGridCell.projectiles) {
                    const projectileEntity = projectile as SharedProjectile;

                    // dont even bother checking for collisions if this projectile has already been flagged for removal this frame
                    if (projectileEntity.ToBeDestroyed) continue;

                    // Skip collisions for whom you are the owner (cant shoot yourself)
                    if (projectileEntity.owningEntityNid === enemyEntity.nid) continue;

                    // No friendly fire for AI
                    if (projectileEntity.isAIOwnedProjectile === true) continue;

                    this.collisionsCheckedThisFrame++;

                    const collisionResponse: boolean = testPolygonCircle(enemyEntity.HitboxCollider, projectileEntity.HitboxCollider, this.reusableCollisionResponseObject);

                    if (collisionResponse) {
                        enemyEntity.lastDamagedByNid = projectileEntity.owningEntityNid;
                        enemyEntity.TakeDamage(projectileEntity.damage, projectileEntity.damageType, projectileEntity.owningEntityNid, projectileEntity.npcDamageModifier);
                        projectileEntity.ToBeDestroyed = true;
                    }

                    this.reusableCollisionResponseObject.clear();
                }
            }

            /**********************************************************************/
            /*                                                                    */
            /*                                                                    */
            /*                  AI <-> AI Max Chase                               */
            /*                                                                    */
            /*                                                                    */
            /**********************************************************************/

            // Outer max chase radius; clear any chase targets if AI leaves this collider and don't chase any players until
            // it returns back within the innner radius.
            let collisionResponse: boolean = testPolygonCircle(enemyEntity.HitboxCollider, enemyEntity.MaxChaseOuterCollider, this.reusableCollisionResponseObject);
            if (!collisionResponse) {
                enemyEntity.SetTargetPlayer(null);
                continue;
            }

            // Inner chase target; skip the following Aggro <-> Player collision only if we aren't already chasing someone
            // to prevent the AI to not thrash between chasing/not chasing.
            if (enemyEntity.GetTargetPlayer() == null) {
                collisionResponse = testPolygonCircle(enemyEntity.HitboxCollider, enemyEntity.MaxChaseInnerCollider, this.reusableCollisionResponseObject);
                if (!collisionResponse) {
                    enemyEntity.SetTargetPlayer(null);
                    continue;
                }
            }

            /**********************************************************************/
            /*                                                                    */
            /*                                                                    */
            /*                  AI Aggro <-> Player Collision                     */
            /*                                                                    */
            /*                                                                    */
            /**********************************************************************/
            let closestPlayerDistance: number = enemyEntity.AggroCollider.r;
            let closestPlayer: SharedPlayer | null = null;
            for (const player of this.players) {
                const playerEntity = player as SharedPlayer;

                // Leave the dead players alone.
                if (playerEntity.isAlive == false) {
                    continue;
                }

                const collisionResponse: boolean = testPolygonCircle(playerEntity.HitboxCollider, enemyEntity.AggroCollider, this.reusableCollisionResponseObject);
                if (collisionResponse) {
                    const dx = playerEntity.x - enemyEntity.x;
                    const dy = playerEntity.y - enemyEntity.y;
                    const distance = Math.sqrt(dx * dx + dy * dy);

                    if (distance < closestPlayerDistance) {
                        closestPlayerDistance = distance;
                        closestPlayer = playerEntity;
                    }
                }

                this.reusableCollisionResponseObject.clear();
            }
            enemyEntity.SetTargetPlayer(closestPlayer);
        }

        for (const player of this.players) {
            const playerEntity = player as SharedPlayer;
            playerEntity.collidedWithExtractionPointThisFrame = false;
            const playersCurrentGridCell = playerEntity.currentCollisionGridCell;

            // Dont do a collision checks dead players
            if (playerEntity.isAlive === false) {
                continue;
            }

            // Don't do collision checks against players that are already extracted
            if (playerEntity.extractionSuccessful) {
                continue;
            }

            for (const offset of SharedCollisionSystem.GridOffsets) {
                const [x, y] = offset;

                const adjacentX = playersCurrentGridCell.column + x;
                const adjacentY = playersCurrentGridCell.row + y;

                // If the potential grid cell that is adjacent to this grid cell is out of the bounds of the world, skip it as this grid cell does not exist
                if (adjacentX > this.broadphaseCollisionGrid.gridWidth - 1 || adjacentY > this.broadphaseCollisionGrid.gridHeight - 1 || adjacentX < 0 || adjacentY < 0) {
                    continue;
                }

                const relevantSpatialGridCell = this.broadphaseCollisionGrid.GetGridCellFromGridXY(playersCurrentGridCell.column + x, playersCurrentGridCell.row + y);

                if (relevantSpatialGridCell === undefined) {
                    this.LogWarning("relevantGridCell is undefined despite above safeguards, skipping iteration, likely collision system bug");
                    continue;
                }

                if (collisionConfig.DEBUG.DRAW_BROADPHASE_SPATIAL_GRID) {
                    relevantSpatialGridCell.isActive = true;
                }

                /**********************************************************************/
                /*                                                                    */
                /*                                                                    */
                /*                  Player <-> Projectile Collision                   */
                /*                                                                    */
                /*                                                                    */
                /**********************************************************************/
                for (const projectile of relevantSpatialGridCell.projectiles) {
                    const projectileEntity = projectile as SharedProjectile;

                    // dont even bother checking for collisions if this projectile has already been flagged for removal this frame
                    if (projectileEntity.ToBeDestroyed) continue;

                    // Skip collisions for whom you are the owner (cant shoot yourself)
                    if (projectileEntity.owningEntityNid === playerEntity.nid) continue;

                    this.collisionsCheckedThisFrame++;

                    const collisionResponse: boolean = testPolygonCircle(playerEntity.HitboxCollider, projectileEntity.HitboxCollider, this.reusableCollisionResponseObject);

                    if (collisionResponse) {
                        playerEntity.TakeDamage(projectileEntity.damage, projectileEntity.damageType, projectileEntity.owningEntityNid, projectileEntity.playerDamageModifier);
                        projectileEntity.ToBeDestroyed = true;
                    }

                    this.reusableCollisionResponseObject.clear();
                }

                /******************************************************************************/
                /*                                                                            */
                /*                                                                            */
                /*                  Player <-> Extraction Point Collision                     */
                /*                                                                            */
                /*                                                                            */
                /******************************************************************************/
                for (const extractionPoint of relevantSpatialGridCell.extractionPoints) {
                    const extractionPointEntity = extractionPoint as SharedExtractionPoint;

                    this.collisionsCheckedThisFrame++;

                    const response = new Response();

                    const collisionResponse: boolean = testPolygonCircle(extractionPointEntity.HitboxCollider, playerEntity.MovementCollider, response);

                    if (collisionResponse) {
                        playerEntity.collidedWithExtractionPointThisFrame = true;
                    }
                }
            }
        }

        for (const projectile of this.projectiles) {
            const pr = projectile as SharedProjectile;
            const projectilesCurrentGridCell = pr.currentCollisionGridCell;

            // Dont do a collision checks on projectiles that are already flagged for destruction
            if (pr.ToBeDestroyed === true) {
                continue;
            }

            for (const offset of SharedCollisionSystem.GridOffsets) {
                const [x, y] = offset;

                const adjacentX = projectilesCurrentGridCell.column + x;
                const adjacentY = projectilesCurrentGridCell.row + y;

                // If the potential grid cell that is adjacent to this grid cell is out of the bounds of the world, skip it as this grid cell does not exist
                if (adjacentX > this.broadphaseCollisionGrid.gridWidth - 1 || adjacentY > this.broadphaseCollisionGrid.gridHeight - 1 || adjacentX < 0 || adjacentY < 0) {
                    continue;
                }

                const relevantGridCell = this.broadphaseCollisionGrid.GetGridCellFromGridXY(projectilesCurrentGridCell.column + x, projectilesCurrentGridCell.row + y);

                if (relevantGridCell === undefined) {
                    this.LogWarning("relevantGridCell is undefined despite above safeguards, skipping iteration, likely collision system bug");
                    continue;
                }

                if (collisionConfig.DEBUG.DRAW_BROADPHASE_SPATIAL_GRID) {
                    relevantGridCell.isActive = true;
                }
            }
        }

        // CCD stuff
        // const distancePerTick = projectileConfig.SPEED_PER_SECOND * deltaTime;
        // this.LogInfo('Distance a projectile travels in 1 tick in any direction:', distancePerTick);
        // const maxDistanceBeforeTunneling = projectile.HitboxCollider.r; // arbitrary value for now, seems "big enough" to avoid tunneling for our smallest current projectile
        // this.LogInfo('Max distance before tunneling:', maxDistanceBeforeTunneling);
        // const stepsNeededToAvoidTunneling = Math.ceil(distancePerTick / maxDistanceBeforeTunneling);
        // this.LogInfo('Steps needed to avoid tunneling:', stepsNeededToAvoidTunneling);
        // const ccdDt = deltaTime / stepsNeededToAvoidTunneling;
        // this.LogInfo('ccdDt:', ccdDt);
        // this.LogInfo(` -- Projectile position before CCD: ${projectile.position.x} --`);
        // for (let i = 0; i < stepsNeededToAvoidTunneling; i++) {
        //     this.LogInfo('');
        //     this.LogInfo(`performing intermediate ccd step #${i + 1}`);
        //     // stop future ccd steps if the projectile is already tagged to be destroyed (by previous step)
        //     if (projectile.ToBeDestroyed) continue;
        //     this.LogInfo('prev iteration ccd pos:', projectile.HitboxCollider.pos.x);
        //     projectile.HitboxCollider.pos.x += projectile.velocity.x * (projectileConfig.SPEED_PER_SECOND * ccdDt);
        //     projectile.HitboxCollider.pos.y += projectile.velocity.y * (projectileConfig.SPEED_PER_SECOND * ccdDt);
        //     this.collisionsCheckedThisFrame++;
        //     const collisionResponse: boolean = testCirclePolygon(projectile.HitboxCollider, player.HitboxCollider.toPolygon(), this.reusableCollisionResponseObject);
        //     if (collisionResponse) {
        //         this.LogInfo('!!! Projectile <-> player collision !!!');
        //         player.TakeDamage(7, projectile.owningEntityNid);
        //         projectile.position.x = projectile.HitboxCollider.pos.x;
        //         projectile.position.y = projectile.HitboxCollider.pos.y;
        //         projectile.ToBeDestroyed = true;
        //     } else {
        //         // this.LogInfo('No projectile <-> player collision!');
        //         // Reset the hitbox collider of the projectile to match its expected position this frame (we temporarily incremented if for CCD)
        //         projectile.HitboxCollider.pos.x = projectile.position.x;
        //         projectile.HitboxCollider.pos.y = projectile.position.y;
        //     }
        // }
        // this.LogInfo(` -- Projectile position after CCD: ${projectile.position.x} --`);
        //     }
        // }

        this.ConditionallyLogTickMsg("Collisions checked this frame: " + this.collisionsCheckedThisFrame);
        this.ConditionallyLogTickMsg(`Collision updates took: ${getHighResolutionTimestampMsNewNew() - collisionUpdateStartTimestampMs}ms`);
        this.collisionsCheckedThisFrame = 0;
    }

    public override Cleanup(): void {}
}

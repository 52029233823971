import { Context, Binary, defineSchema, Schema } from "nengi";
import { ContainerType, NetworkEntityId, UserType, uuid } from "./SharedTypes";
import { Item, ItemRarity } from "./data/Data_Items";
import { LoadoutSlots } from "./systems/SharedGearAndWeaponSystem";
import { CharacterSkin } from "./data/Data_Cosmetics";
import { EnemySkin } from "./data/Data_Enemies";
import { EnemyState } from "./data/Data_EnemyBehaviourStates";
import { DebugShape } from "../client/systems/ClientDebugManager";

const nengiConfig = new Context();

/*************************************************/
/*                                               */
/*  Top level Ntypes                             */
/*                                               */
/*************************************************/

export enum NType {
    // Commands (client->server)
    InputCommand = 1,
    ClientViewBoundsChangedCommand,
    SpectateMyKillerCommand,
    InteractCommand,
    LootItemCommand,
    PlaceholderPingCommand,
    AdminShutdownCommand,

    // Entities (replicated)
    PlayerEntity,
    ProjectileEntity,
    ExtractionPointEntity,
    AIEntity,
    ItemContainerEntity,

    // Debug Entities
    DebugGridCellEntity,
    CollisionMapTileEntity,

    // Messages (server->client)
    TestMessage,
    IdentityMessage,
    PlayPfxAtLocationMessage,
    YouDiedMessage,
    SuccessfullyExtractedMessage,
    LoadoutFromServerMessage,
    ItemsLootedOnRunUpdatedMessage,
    SpectateEntityOfNidMessage,
    OpenedOrUpdatedItemContainerMessage,
    HideItemContainerMessage,
    PopToastOfArbitraryStringMessage,
    DrawDebugShapeMessage,
    LeaderboardStateMessage,
    PlaceholderPingAckMessage,

    ServerEventFeedLootMessage,
    ServerEventFeedPvPKillMessage,
    ServerEventFeedBossSpawnMessage,
    ServerEventFeedBossUnderAttackMessage,
    ServerEventFeedExtractionStartedMessage,
    ServerEventFeedExtractionSuccessfulMessage
}

export interface NTyped {
    ntype: NType;
}

/*************************************************/
/*                                               */
/*  Entity Replication!                          */
/*                                               */
/*************************************************/

/*****************/
/* Player Entity */
/*****************/

const playerEntity: Schema = defineSchema({
    x: { type: Binary.Float64, interp: true },
    y: { type: Binary.Float64, interp: true },
    aim: { type: Binary.Rotation, interp: true },

    timeRemainingToExtractInSeconds: Binary.UInt32,
    extractionInProgressAccumulator: Binary.Float64,
    extractionSuccessful: Binary.Boolean,

    IsDeveloper: Binary.Boolean,

    IsSupporter: Binary.Boolean,
    IsFounder: Binary.Boolean,

    username: Binary.String,
    userType: Binary.UInt8,

    debugViewWidth: Binary.UInt16,
    debugViewHeight: Binary.UInt16,

    isAlive: Binary.Boolean,
    currentHealthPercentage: Binary.Float32,

    characterSkin: Binary.UInt16,
    activeWeaponSlot: Binary.UInt8,
    weaponOneId: Binary.UInt16,
    weaponTwoId: Binary.UInt16,
    gearOneId: Binary.Int16,
    gearTwoId: Binary.Int16,
    gearThreeId: Binary.Int16,
    weaponOneCooldownProgressPercentage: { type: Binary.Float32, interp: true },
    weaponTwoCooldownProgressPercentage: { type: Binary.Float32, interp: true },
    gearOneCooldownProgressPercentage: { type: Binary.Float32, interp: true },
    gearTwoCooldownProgressPercentage: { type: Binary.Float32, interp: true },
    gearThreeCooldownProgressPercentage: { type: Binary.Float32, interp: true },
    gearOneCharges: Binary.Int8,
    gearTwoCharges: Binary.Int8,
    gearThreeCharges: Binary.Int8,
    insuredSlot: Binary.Int8,

    hasInformationPassive: Binary.Boolean,

    characterOpacityAmount: Binary.Float32
});

export interface PlayerEntityCreationPayload {
    nid: NetworkEntityId;
    ntype: NType.PlayerEntity;

    x: number;
    y: number;
    aim: number;

    timeRemainingToExtractInSeconds: number;
    extractionInProgressAccumulator: number;
    extractionSuccessful: boolean;

    IsDeveloper: boolean;

    IsSupporter: boolean;
    IsFounder: boolean;

    username: string;
    userType: UserType;

    debugViewWidth: number;
    debugViewHeight: number;

    isAlive: boolean;
    currentHealthPercentage: number;

    characterSkin: CharacterSkin;
    activeWeaponSlot: number;
    weaponOneId: Item;
    weaponTwoId: Item;
    gearOneId: Item;
    gearTwoId: Item;
    gearThreeId: Item;
    weaponOneCooldownProgressPercentage: number;
    weaponTwoCooldownProgressPercentage: number;
    gearOneCooldownProgressPercentage: number;
    gearTwoCooldownProgressPercentage: number;
    gearThreeCooldownProgressPercentage: number;
    gearOneCharges: number;
    gearTwoCharges: number;
    gearThreeCharges: number;
    insuredSlot: LoadoutSlots;

    hasInformationPassive: boolean;

    characterOpacityAmount: number;
}

nengiConfig.register(NType.PlayerEntity, playerEntity);

/*****************/
/* AI Entity */
/*****************/

const aiEntity: Schema = defineSchema({
    x: { type: Binary.Float64, interp: true },
    y: { type: Binary.Float64, interp: true },
    isAlive: Binary.Boolean,
    currentHealthPercentage: Binary.Float32,
    enemySkin: Binary.UInt16,
    currentState: Binary.UInt16,
    spawnX: Binary.Float64,
    spawnY: Binary.Float64,
    targetX: Binary.Float64,
    targetY: Binary.Float64,
    attackedThisFrame: Binary.Boolean,
    hasLineOfSightWithTarget: Binary.Boolean
    // debug_pathfindingTileIndices: Binary.Int32Array
});

export interface AIEntityCreationPayload {
    nid: NetworkEntityId;
    ntype: NType.AIEntity;

    x: number;
    y: number;
    isAlive: boolean;
    currentHealthPercentage: number;
    enemySkin: EnemySkin;
    currentState: EnemyState;
    spawnX: number;
    spawnY: number;
    targetX: number;
    targetY: number;
    attackedThisFrame: boolean;
    hasLineOfSightWithTarget: boolean;
    // debug_pathfindingTileIndices: number[];
}

nengiConfig.register(NType.AIEntity, aiEntity);

/*********************/
/* Circle Projectile */
/*********************/

const circleProjectile: Schema = defineSchema({
    x: { type: Binary.Float64, interp: true },
    y: { type: Binary.Float64, interp: true },
    spawnTrajectoryInRads: Binary.Float64,
    owningEntityNid: Binary.UInt32,
    projectileType: Binary.UInt8,
    damage: Binary.UInt16,
    damageType: Binary.UInt8,
    dataId: Binary.UInt8
});

export interface ProjectileEntityCreationPayload {
    nid: NetworkEntityId;
    ntype: NType.ProjectileEntity;

    x: number;
    y: number;
    damage: number;
    damageType: number;
    spawnTrajectoryInRads: number;
    owningEntityNid: NetworkEntityId;
    projectileType: number;
    dataId: number;
}

nengiConfig.register(NType.ProjectileEntity, circleProjectile);

/*********************/
/* Item Containers   */
/*********************/

const itemContainer: Schema = defineSchema({
    x: Binary.Float64,
    y: Binary.Float64,

    // lootItems: Binary.Int16Array,
    // lootQuantities: Binary.Int16Array,

    containerType: Binary.UInt8,
    containerName: Binary.String,
    uniqueContainerId: Binary.String,

    owningPlayerNid: Binary.Int32,

    spriteId: Binary.String
});

export interface ItemContainerEntityCreationPayload {
    nid: NetworkEntityId;
    ntype: NType.ItemContainerEntity;

    x: number;
    y: number;

    // lootItems: Item[];
    // lootQuantities: number[];

    containerType: ContainerType;
    containerName: string;
    uniqueContainerId: uuid;

    owningPlayerNid: number;

    spriteId: string;
}

nengiConfig.register(NType.ItemContainerEntity, itemContainer);

/*********************/
/* Extraction Point  */
/*********************/

const extractionPoint: Schema = defineSchema({
    x: Binary.Float64,
    y: Binary.Float64,
    width: Binary.Float64,
    height: Binary.Float64
});

export interface ExtractionPointEntityCreationPayload {
    nid: NetworkEntityId;
    ntype: NType.ExtractionPointEntity;

    x: number;
    y: number;

    width: number;
    height: number;
}

nengiConfig.register(NType.ExtractionPointEntity, extractionPoint);

/*********************/
/*  Debug Grid Cell  */
/*********************/

const debugGridCell: Schema = defineSchema({
    x: Binary.Float64,
    y: Binary.Float64,
    width: Binary.Float64,
    height: Binary.Float64,
    isActive: Binary.Boolean
});

export interface DebugGridCellEntityCreationPayload {
    nid: NetworkEntityId;
    ntype: NType.DebugGridCellEntity;

    x: number;
    y: number;
    height: number;
    width: number;
    isActive: boolean;
}

nengiConfig.register(NType.DebugGridCellEntity, debugGridCell);

/*************************************************/
/*                                               */
/*  Messages (server ---> client)                */
/*                                               */
/*************************************************/

/********************/
/* Identity Message */
/********************/

const identityMessageSchema: Schema = defineSchema({
    myId: Binary.UInt32,
    myX: Binary.Float64,
    myY: Binary.Float64
});

export interface IdentityMessage extends NTyped {
    ntype: NType.IdentityMessage;
    myId: number;
    myX: number;
    myY: number;
}

nengiConfig.register(NType.IdentityMessage, identityMessageSchema);

/*****************************/
/* LoadoutFromServer Message */
/*****************************/

const loadoutFromServerMessageSchema: Schema = defineSchema({
    characterSkin: Binary.Int16,
    weaponOne: Binary.Int16,
    weaponTwo: Binary.Int16,
    gearOne: Binary.Int16,
    gearTwo: Binary.Int16,
    gearThree: Binary.Int16,
    insuredSlot: Binary.Int8
});

export interface LoadoutFromServerMessage extends NTyped {
    ntype: NType.LoadoutFromServerMessage;

    characterSkin: number;
    weaponOne: number;
    weaponTwo: number;
    gearOne: number;
    gearTwo: number;
    gearThree: number;
    insuredSlot: number;
}

nengiConfig.register(NType.LoadoutFromServerMessage, loadoutFromServerMessageSchema);

/*****************************/
/* PlayPfxAtLocation Message */
/*****************************/

const playPfxAtLocationMessageSchema: Schema = defineSchema({
    x: Binary.Float64,
    y: Binary.Float64,
    scale: Binary.Float64,
    pfxType: Binary.String
});

export interface PlayPfxAtLocationMessage extends NTyped {
    ntype: NType.PlayPfxAtLocationMessage;
    x: number;
    y: number;
    scale: number;
    pfxType: string;
}

nengiConfig.register(NType.PlayPfxAtLocationMessage, playPfxAtLocationMessageSchema);

/*****************************************/
/* Pop Toast of Arbitrary String Message */
/*****************************************/

const popToastOfArbitraryStringMessageSchema: Schema = defineSchema({
    message: Binary.String
});

export interface PopToastOfArbitraryStringMessage extends NTyped {
    ntype: NType.PopToastOfArbitraryStringMessage;
    message: string;
}

nengiConfig.register(NType.PopToastOfArbitraryStringMessage, popToastOfArbitraryStringMessageSchema);

/*****************************************/
/* Leaderboard State Message */
/*****************************************/

const leaderboardStateMessageSchema: Schema = defineSchema({
    leaderboardState: Binary.String
});

export interface LeaderboardStateMessage extends NTyped {
    ntype: NType.LeaderboardStateMessage;
    leaderboardState: string;
}

nengiConfig.register(NType.LeaderboardStateMessage, leaderboardStateMessageSchema);

/*****************************/
/* You Died Message          */
/*****************************/

const youDiedMessageSchema: Schema = defineSchema({
    killedBy: Binary.String,
    runDurationInSeconds: Binary.UInt32,
    goldLost: Binary.UInt32
});

export interface YouDiedMessage extends NTyped {
    ntype: NType.YouDiedMessage;
    killedBy: string;
    runDurationInSeconds: number;
    goldLost: number;
}

nengiConfig.register(NType.YouDiedMessage, youDiedMessageSchema);

/*******************************************/
/* Successfully Extracted Message          */
/*******************************************/

const successfullyExtractedMessageSchema: Schema = defineSchema({
    runDurationInSeconds: Binary.UInt32,
    goldEarned: Binary.UInt32
});

export interface SuccessfullyExtractedMessage extends NTyped {
    ntype: NType.SuccessfullyExtractedMessage;

    runDurationInSeconds: number;
    goldEarned: number;
}

nengiConfig.register(NType.SuccessfullyExtractedMessage, successfullyExtractedMessageSchema);

/*******************************************/
/* SpectateEntityMessage                   */
/*******************************************/

const spectateEntityOfNidMessageSchema: Schema = defineSchema({
    nidOfEntityToFollow: Binary.Int32,
    entityName: Binary.String
});

export interface SpectateEntityOfNidMessage extends NTyped {
    ntype: NType.SpectateEntityOfNidMessage;

    nidOfEntityToFollow: number;
    entityName: string;
}

nengiConfig.register(NType.SpectateEntityOfNidMessage, spectateEntityOfNidMessageSchema);

/*******************************************/
/* Ping Ack Msg                            */
/*******************************************/

const placeholderPingAckMessageSchema: Schema = defineSchema({
    ack: Binary.Boolean
});

export interface PlaceholderPingAckMessage extends NTyped {
    ntype: NType.PlaceholderPingAckMessage;
    ack: true;
}

nengiConfig.register(NType.PlaceholderPingAckMessage, placeholderPingAckMessageSchema);

/*******************************************/
/* Run Inventory Updated Message           */
/*******************************************/

const itemsLootedOnRunUpdatedMessage: Schema = defineSchema({
    items: Binary.Int16Array,
    quantities: Binary.Int16Array
});

export interface ItemsLootedOnRunUpdatedMessage extends NTyped {
    ntype: NType.ItemsLootedOnRunUpdatedMessage;
    items: number[];
    quantities: number[];
}

nengiConfig.register(NType.ItemsLootedOnRunUpdatedMessage, itemsLootedOnRunUpdatedMessage);

/*******************************************/
/* Opened Item Container Message           */
/*******************************************/

const openedOrUpdatedItemContainerMessage: Schema = defineSchema({
    containerName: Binary.String,
    itemContainerId: Binary.String,
    items: Binary.Int16Array,
    quantities: Binary.Int16Array
});

export interface OpenedOrUpdatedItemContainerMessage extends NTyped {
    ntype: NType.OpenedOrUpdatedItemContainerMessage;
    containerName: string;
    itemContainerId: uuid;
    items: number[];
    quantities: number[];
}

nengiConfig.register(NType.OpenedOrUpdatedItemContainerMessage, openedOrUpdatedItemContainerMessage);

/*******************************************/
/* Hide Item Container Message             */
/*******************************************/

const hideItemContainerMessage: Schema = defineSchema({
    itemContainerId: Binary.String
});

export interface HideItemContainerMessage extends NTyped {
    ntype: NType.HideItemContainerMessage;
    itemContainerId: uuid;
}

nengiConfig.register(NType.HideItemContainerMessage, hideItemContainerMessage);

/*******************************************/
/* Draw Debug Shape Message                */
/*******************************************/

const drawDebugShapeMessage: Schema = defineSchema({
    x: Binary.Float64,
    y: Binary.Float64,
    shape: Binary.UInt8,
    destX: Binary.Float64,
    destY: Binary.Float64,
    radius: Binary.Float64,
    width: Binary.Float64,
    height: Binary.Float64,
    destroyAfterSeconds: Binary.Float64
});

export interface DrawDebugShapeMessage extends NTyped {
    ntype: NType.DrawDebugShapeMessage;
    x: number;
    y: number;
    shape: DebugShape;
    destX: number;
    destY: number;
    radius: number;
    width: number;
    height: number;
    destroyAfterSeconds: number;
}

nengiConfig.register(NType.DrawDebugShapeMessage, drawDebugShapeMessage);

/*******************************************/
/* Server Event Feed Loot Msg              */
/*******************************************/

const serverEventFeedLootMessage: Schema = defineSchema({
    playerName: Binary.String,
    rarity: Binary.UInt8,
    locationX: Binary.Int32,
    locationY: Binary.Int32
});

export interface ServerEventFeedLootMessage extends NTyped {
    ntype: NType.ServerEventFeedLootMessage;
    playerName: string;
    rarity: ItemRarity;
    locationX: number;
    locationY: number;
}

nengiConfig.register(NType.ServerEventFeedLootMessage, serverEventFeedLootMessage);

/*******************************************/
/* Server Event Feed Kill Msg              */
/*******************************************/

const serverEventFeedPvPKillMessage: Schema = defineSchema({
    killer: Binary.String,
    victim: Binary.String,
    locationX: Binary.Int32,
    locationY: Binary.Int32
});

export interface ServerEventFeedKillMessage extends NTyped {
    ntype: NType.ServerEventFeedPvPKillMessage;
    killer: string;
    victim: string;
    locationX: number;
    locationY: number;
}

nengiConfig.register(NType.ServerEventFeedPvPKillMessage, serverEventFeedPvPKillMessage);

/*******************************************/
/* Server Event Feed Boss Spawn Msg        */
/*******************************************/

const serverEventFeedBossSpawnMessage: Schema = defineSchema({
    bossName: Binary.String,
    locationX: Binary.Int32,
    locationY: Binary.Int32
});

export interface ServerEventFeedBossSpawnMessage extends NTyped {
    ntype: NType.ServerEventFeedBossSpawnMessage;
    bossName: string;
    locationX: number;
    locationY: number;
}

nengiConfig.register(NType.ServerEventFeedBossSpawnMessage, serverEventFeedBossSpawnMessage);

/*******************************************/
/* Server Event Feed Boss Attack Msg       */
/*******************************************/

const serverEventFeedBossUnderAttackMessage: Schema = defineSchema({
    bossName: Binary.String,
    locationX: Binary.Int32,
    locationY: Binary.Int32
});

export interface ServerEventFeedBossUnderAttackMessage extends NTyped {
    ntype: NType.ServerEventFeedBossUnderAttackMessage;
    bossName: string;
    locationX: number;
    locationY: number;
}

nengiConfig.register(NType.ServerEventFeedBossUnderAttackMessage, serverEventFeedBossUnderAttackMessage);

/*********************************************/
/* Server Event Extraction Started Msg       */
/*********************************************/

const serverEventExtractionStartedMessage: Schema = defineSchema({
    playerName: Binary.String,
    locationX: Binary.Int32,
    locationY: Binary.Int32
});

export interface ServerEventFeedExtractionStartedMessage extends NTyped {
    ntype: NType.ServerEventFeedExtractionStartedMessage;
    playerName: string;
    locationX: number;
    locationY: number;
}

nengiConfig.register(NType.ServerEventFeedExtractionStartedMessage, serverEventExtractionStartedMessage);

/*********************************************/
/* Server Event Extraction Successful Msg       */
/*********************************************/

const serverEventExtractionSuccessfulMessage: Schema = defineSchema({
    playerName: Binary.String,
    locationX: Binary.Int32,
    locationY: Binary.Int32
});

export interface ServerEventFeedExtractionSuccessfulMessage extends NTyped {
    ntype: NType.ServerEventFeedExtractionSuccessfulMessage;
    playerName: string;
    locationX: number;
    locationY: number;
}

nengiConfig.register(NType.ServerEventFeedExtractionSuccessfulMessage, serverEventExtractionSuccessfulMessage);

/*************************************************/
/*                                               */
/*  Commands (client ---> server)                */
/*                                               */
/*************************************************/

/*********/
/* Input */
/*********/

const inputCommandSchema = defineSchema({
    up: Binary.Boolean,
    down: Binary.Boolean,
    left: Binary.Boolean,
    right: Binary.Boolean,
    aim: Binary.Float64,
    usedWeapon: Binary.Boolean,
    genericSwappedWeapon: Binary.Boolean,
    swappedToFirstWeapon: Binary.Boolean,
    swappedToSecondWeapon: Binary.Boolean,
    usedGearOne: Binary.Boolean,
    usedGearTwo: Binary.Boolean,
    usedGearThree: Binary.Boolean,
    delta: Binary.Float64
});

export interface InputCommand extends NTyped {
    ntype: NType.InputCommand;
    up: boolean;
    down: boolean;
    left: boolean;
    right: boolean;
    aim: number;
    usedWeapon: boolean;
    genericSwappedWeapon: boolean;
    swappedToFirstWeapon: boolean;
    swappedToSecondWeapon: boolean;
    usedGearOne: boolean;
    usedGearTwo: boolean;
    usedGearThree: boolean;
    delta: number;
}

nengiConfig.register(NType.InputCommand, inputCommandSchema);

/******************/
/* Admin Shutdown */
/******************/

const adminShutdownCommandSchema = defineSchema({
    shutdown: Binary.Boolean
});

export interface AdminShutdownCommand extends NTyped {
    ntype: NType.AdminShutdownCommand;
    shutdown: boolean;
}

nengiConfig.register(NType.AdminShutdownCommand, adminShutdownCommandSchema);

/************/
/* Interact */
/************/

const interactCommandSchema = defineSchema({
    interactingWithContainerOfId: Binary.String
});

export interface InteractCommand extends NTyped {
    ntype: NType.InteractCommand;
    interactingWithContainerOfId: uuid;
}

nengiConfig.register(NType.InteractCommand, interactCommandSchema);

/*************/
/* Ping      */
/*************/

const placeholderPingCommandSchema = defineSchema({
    ping: Binary.Boolean
});

export interface PlaceholderPingCommand extends NTyped {
    ntype: NType.PlaceholderPingCommand;
    ping: true;
}

nengiConfig.register(NType.PlaceholderPingCommand, placeholderPingCommandSchema);

/*************/
/* Loot Item */
/*************/

const lootItemCommandSchema = defineSchema({
    interactingWithContainerOfId: Binary.String,
    itemIdToLoot: Binary.Int32
});

export interface LootItemCommand extends NTyped {
    ntype: NType.LootItemCommand;
    interactingWithContainerOfId: uuid;
    itemIdToLoot: number;
}

nengiConfig.register(NType.LootItemCommand, lootItemCommandSchema);

/**************************/
/* Spectating Your Killer */
/**************************/

const spectateMyKillerCommand = defineSchema({
    spectateMyKiller: Binary.Boolean
});

export interface SpectateMyKillerCommand extends NTyped {
    ntype: NType.SpectateMyKillerCommand;
    spectateMyKiller: boolean;
}

nengiConfig.register(NType.SpectateMyKillerCommand, spectateMyKillerCommand);

/****************/
/* View Changed */
/****************/

const clientViewBoundsChangedCommand = defineSchema({
    cw: Binary.UInt32,
    ch: Binary.UInt32,
    cz: Binary.Float32
});

export interface ClientViewBoundsChangedCommand extends NTyped {
    ntype: NType.ClientViewBoundsChangedCommand;
    cw: number;
    ch: number;
    cz: number;
}

nengiConfig.register(NType.ClientViewBoundsChangedCommand, clientViewBoundsChangedCommand);

export { nengiConfig };

import React, { useState } from "react";
import { latestPingStateAtom } from "../framework/UI_State";
import { useRecoilValue } from "recoil";

// @ts-ignore
import goodPingImageSrc from "../kit/ping-good.png";
import { t } from "../../../shared/data/Data_I18N";
// @ts-ignore
// import skullImageSrc from "../kit/skull.png";

export const TopLeftHUD = () => {
    const latestPing = useRecoilValue(latestPingStateAtom);
    const pingIsBad = latestPing > 200;
    const [isFullscreen, setIsFullscreen] = useState(false);

    const currentUrl = window.location != window.parent.location ? document.referrer : document.location.href;

    const isOnCrazygames = currentUrl.includes("crazygames.com") || OriginalLocation?.includes("crazygames");

    // latestPing = 666;
    // pingIsBad = true;

    return (
        <div className="top-left-hud animate__animated animate__slideInLeft">
            {isOnCrazygames === false && (
                <button
                    className={`fullscreen-button ${isFullscreen ? "is-full-screen neutral-button" : "affirmative-button"}`}
                    onClick={() => {
                        setIsFullscreen(!isFullscreen);
                        Game.Input.ToggleFullScreen();
                    }}
                >
                    <span>{isFullscreen ? t("exit_fullscreen") : t("fullscreen")}</span>
                </button>
            )}
            <div className="ping">
                <img src={goodPingImageSrc} alt="good ping" className={`ping-image ${pingIsBad ? "bad" : ""}`} />
                <span>
                    {pingIsBad ? "⚠️" : ""} {latestPing}ms {pingIsBad ? "⚠️" : ""}{" "}
                </span>
            </div>

            {/* <div className="kills">
                <img src={skullImageSrc} alt="kills" />
                <span>0</span>
            </div> */}
        </div>
    );
};

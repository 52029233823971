import React from "react";
import { Screens, UI_SwapScreen } from "../framework/UI_State";
import { t } from "../../../shared/data/Data_I18N";

export const PatchNotes = () => {
    return (
        <>
            <div className="patch-notes-panel animate__animated animate__fadeInUp animate__faster">
                <div className="patch-notes-header">Updates</div>

                <div className="patch-notes">
                    <div className="patch-segment patch-1.3.0">
                        <h3>Update 1.3.0 - February 5th, 2024</h3>
                        <ul>
                            <li>Misc backend changes</li>
                        </ul>
                    </div>
                    <div className="patch-segment patch-1.2.10">
                        <h3>Update 1.2.10 - August 9th, 2023</h3>
                        <ul>
                            <li>Epic and Legendary Gear has been changed/tuned to better fit our idea of what Epic and Legendary gear should look/act like.</li>
                            <li>Legendary weapon stats have been adjusted to continue to encourage new metas and gameplay!</li>
                        </ul>
                    </div>
                    <div className="patch-segment patch-1.2.9">
                        <h3>Update 1.2.9 - August 2nd, 2023</h3>
                        <ul>
                            <li>New Legendary Gear "Beast Vanquisher Boots"! These can be looted from Giant Rats and Ogres</li>
                            <li>Rare Gear has been changed/tuned to better fit our idea of what rare gear should look/act like. Expect these changes to be applied to other rarities in coming updates.</li>
                            <li>Legendary weapon stats have been adjusted to continue to encourage new metas and gameplay!</li>
                            <li>Fixed a bug where Midnights Veil would be consumed on use.</li>
                            <li>Fixed a bug where Silent Shadow could spawn in chests</li>
                        </ul>
                    </div>
                    <div className="patch-segment patch-1.2.8">
                        <h3>Update 1.2.8 - July 25th, 2023</h3>
                        <ul>
                            <li>Insurance can now be bought with gold in store!</li>
                            <li>A number of translation bugs have been fixed</li>
                            <li>Uncommon gear has been changed/tuned to better fit our idea of what uncommon gear should look/act like. Expect these changes to be applied to other rarities in coming updates.</li>
                            <li>Legendary weapon stats have been adjusted to continue to encourage new metas and gameplay!</li>
                        </ul>
                    </div>
                    <div className="patch-segment patch-1.2.7">
                        <h3>Update 1.2.7 - July 20th, 2023</h3>
                        <ul>
                            <li>Store Now Available! Checkout our store where you can buy insurance, cool skins, and other customization packages!</li>
                            <li>Added new weapons: Silent Shadow (Legendary) and Frost Flicker (Epic)</li>
                            <li>Added new gear: Depth Strider (Common), Golden Gauntlets (Uncommon), and Magic Boots (Rare)</li>
                            <li>Fixed UI bug on Divine Wind that said it shoots shadow projectiles when it shoots Holy projectiles.</li>
                            <li>Staff Speed Boost: In addition to laying dangerous traps, staffs now also provide a temporary movement speed increase when fired thus giving you a moment of reprive during an intense battle.</li>
                            <li>Spellbook Starters: Spellbook behaviour has changed! These powerful books now boast a high-damage projectile with a very long cooldown (Chanting spells out of these books takes time and energy). This weapon class is used best as a one-off blast to gain the advantage at the start of combat! The Wizard's Ledger is the only exception.</li>
                            <li>Most common gear has been changed/tuned to better fit our idea of what common gear should look/act like. Expect these changes to be applied to other rarities in coming updates.</li>
                            <li>Legendary weapon stats have been adjusted to continue to encourage new metas and gameplay!</li>
                            <li>Default Loadout changed so staff is no longer a primary weapon</li>
                        </ul>
                    </div>
                    <div className="patch-segment patch-1.2.6">
                        <h3>Update 1.2.6 - July 12th, 2023</h3>
                        <ul>
                            <li>Added new legendary weapon: Deathstick</li>
                            <li>Fixed a bug where Book of Tongues would shoot the wrong projectile</li>
                            <li>Iron Fox Locket: Reduced all resistances granted by 3, added 45 max HP, removed the ability to extract anywhere, and this item can no longer be destroyed</li>
                            <li>Tantrum's Lucky Loop: reduced projectile distance to 5% and reduced movement speed to 3%</li>
                            <li>Ginesh's Bottle Opener: reduced projectile distance to 15%</li>
                            <li>Cloak of Modest Approachability: reduced projectile range to 12%</li>
                            <li>Compacted Lime Green Ring: reduced projectile distance to 5%</li>
                            <li>Little Compacted Lime Green Ring: reduced projectile distance to 12%</li>
                        </ul>
                    </div>
                    <div className="patch-segment patch-1.2.5">
                        <h3>Update 1.2.5 - July 7th, 2023</h3>
                        <ul>
                            <li>Added a new legendary weapon: Book of Tongues</li>
                            <li>Added new legendary gear: Cultists Mask</li>
                            <li>Rework: Staffs are now a class of weapon that create stationary projectiles which can block pathways</li>
                            <li>Large balance pass: Tuned all legendary weapons, so that Celestial Bow is not the only option for a primary weapon</li>
                            <li>Nerf to several movement speed items</li>
                            <li>Reduced the negative effects on some items</li>
                            <li>Reduced the power of lower tier items Buffed ghosts, rats and skeletons slightly, while nerfing Treants and Dark Wizards</li>
                        </ul>
                    </div>
                    <div className="patch-segment patch-1.2.4">
                        <h3>Update 1.2.4 - June 14th, 2023</h3>
                        <ul>
                            <li>Added a server selector dropdown to the main menu & settings screens</li>
                            <li>Added more servers to: Asia, Australia, Europe, and South America</li>
                            <li>'Extract' active ability now takes full extraction time (10 seconds)</li>
                            <li>Passive Regen items now increase health over time more strongly</li>
                            <li>ALL 'Extract" active ability items will now break when the active extraction ability is used</li>
                            <li>Made Legendary and Epic items slightly more rare</li>
                            <li>Nerf: Bees on a Stick - does slightly less damage, has a slightly longer cooldown and shoots slower projectiles</li>
                            <li>Nerf: Nature's Return - does slightly less damage, has a slightly longer cooldown and shoots slower projectiles</li>
                            <li>Nerf: Magical Staff - has a slightly longer cooldown and shoots slower projectiles</li>
                            <li>Nerf: "Waterwalking" stat - now never exceeds a 15% speed boost per item</li>
                            <li>Nerf: Pheonix Feather Toque - now gives -20% attack speed</li>
                            <li>Nerf: Time Hero's Ring - Changed attack speed increase from 20% to 5%</li>
                            <li>Besides the above specific items that are worth mentioning, we also did a general large-scale weapon balance pass across many weapons</li>
                            <li>Bug Fix: Fixed a bug where pressing the caps lock key would sometimes make your character stuck moving</li>
                        </ul>
                    </div>
                    <div className="patch-segment patch-1.2.3">
                        <h3>Update 1.2.3 - June 11th, 2023</h3>
                        <ul>
                            <li>added a patch notes screen</li>
                            <li>added support for fullscreen mode</li>
                            <li>added support for 4k resolution</li>
                            <li>bug fix: fixed an issue where several invisibility items wouldnt properly grant you invisibility</li>
                            <li>bug fix: fixed a UI text overlap issue in the server event feed</li>
                            <li>bug fix: fixed a UI issue with the loading button on the You Died & You Extracted screens</li>
                        </ul>
                    </div>
                    <div className="patch-segment patch-1.2.2">
                        <h3>Update 1.2.2 - June 10th, 2023</h3>
                        <ul>
                            <li>re-balanced many weapons (some nerfs, some buffs)</li>
                            <li>bug fix: fixed some screen size overlap issues on smaller screens</li>
                        </ul>
                    </div>
                    <div className="patch-segment patch-1.2.1">
                        <h3>Update 1.2.1 - June 9th, 2023</h3>
                        <ul>
                            <li>new visuals for item tooltips to make reading them easier</li>
                            <li>the loot window will now automatically close if you move away from what you were looting</li>
                            <li>Iron Fox and GRONKs arenas are now a bit more open / there's less cover</li>
                            <li>nerf extraction protection items</li>
                            <li>nerf treant and dark wizard health</li>
                            <li>bug fix: fixed an issue where you could stack multiple speed boost items. now when you use an ability, any other items you have that have the same ability will also go on cooldown</li>
                            <li>bug fix: fixed a css bug causing 9slice borders to appear on some browsers</li>
                            <li>bug fix: fixed a bug where dust sprite icons overlapped their text in the item tooltip</li>
                        </ul>
                    </div>
                    <div className="patch-segment patch-1.2.0">
                        <h3>Update 1.2.0 - June 8th, 2023</h3>
                        <ul>
                            <li>SIGNIFICANT re-balance of all gear added a bunch of new stats to a bunch of items...</li>
                            <ul>
                                <li>INCOGNITO; hides your location and loot messages from the server</li>
                                <li>INSPECTION; reveals information about your opponents</li>
                                <li>gold rush; earn more gold from chests</li>
                                <li>extraction speed; makes you extract faster</li>
                                <li>extraction protection; makes you take less damage while extracting</li>
                                <li>attack speed up</li>
                                <li>attack speed down</li>
                                <li>+damage against players only</li>
                                <li>-damage against players only</li>
                                <li>+damage against NPCs only</li>
                                <li>-damage against NPC only</li>
                                <li>projectile distance up</li>
                                <li>projectile distance down</li>
                            </ul>
                            <li>new enemy; Dark Wizard</li>
                            <li>new boss; Iron Fox</li>
                            <li>bosses now always drop at least rare items</li>
                            <li>enemies now each have their own loot tables. now certain enemies will only drop certain items</li>
                            <li>added new player skin for registered users; Noble Knight</li>
                            <li>disallow Corrupted Wizard and Veteran Wizard skins unless you are registered</li>
                            <li>disallow viewing stats unless you are registered</li>
                            <li>we now play some particle effects when a player HEALS with a healing item</li>
                            <li>we now play some particle effects when a player REVIVES with a revive item</li>
                            <li>allow clicking ui elements in game (this is to prep for a mobile version) instead of pressing the hotkey</li>
                            <li>track new player stats (gold lost, items lost, bosses killed, common/uncommon/rare/epic/legendary items looted)</li>
                            <li>all enemies now take 15 seconds to respawn</li>
                            <li>all bosses now take 10 minutes to respawn</li>
                            <li>buffed GRONK. now he's extra stronk.</li>
                            <li>increased font size of player names</li>
                            <li>fixed a bug where the revive effect wouldnt always proc</li>
                            <li>fixed a bug where your max health wouldnt update correctly on the loading screen</li>
                            <li>fixed a bug where a user identity error could result in an infinite loop</li>
                            <li>fixed a bug where you could avoid sharing cooldowns from stacked items</li>
                        </ul>
                    </div>
                </div>

                <button
                    className="neutral-button"
                    onClick={() => {
                        UI_SwapScreen(Screens.MainMenu);
                    }}
                >
                    {t("stats__back_btn")}
                </button>
            </div>
        </>
    );
};

import React from "react";
import { Screens, UI_SwapScreen } from "../framework/UI_State";
import { t } from "../../../shared/data/Data_I18N";

export const Partners = () => {
    return (
        <>
            <div className="partners-panel animate__animated animate__fadeInUp animate__faster">
                <div className="partners-header">{t("main_menu__partners")}</div>

                <div className="partners-list">
                    <a href="https://crazygames.com/" target="_blank" title="Crazy Games!" rel="noreferrer noopener">
                        Crazy Games
                    </a>
                    <a href="https://kongregate.com/" target="_blank" title="Kongregate" rel="noreferrer noopener">
                        Kongregate
                    </a>
                    <a href="https://addictinggames.com/" target="_blank" title="Addicting Games" rel="noreferrer noopener">
                        Addicting Games
                    </a>
                    <a href="https://iogames.space/" target="_blank" title=".io Games Space!" rel="noreferrer noopener">
                        iogames.space
                    </a>
                    <a href="https://silvergames.com/" target="_blank" title="Silver Games" rel="noreferrer noopener">
                        Silver Games
                    </a>
                    <a href="https://kevin.games/" target="_blank" title="Kevin Games" rel="noreferrer noopener">
                        Kevin Games
                    </a>
                </div>

                <button
                    className="neutral-button"
                    onClick={() => {
                        UI_SwapScreen(Screens.MainMenu);
                    }}
                >
                    {t("settings__back_to_main_menu_button")}
                </button>
            </div>
        </>
    );
};

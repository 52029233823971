import React from "react";
import { Item, ItemRarity } from "../../../shared/data/Data_Items";
import { useRecoilValue } from "recoil";
import { serverEventFeedStateAtom } from "../framework/UI_State";
import { ServerEvent, ServerEventFeedEventTypes } from "../../../shared/SharedTypes";
import { t } from "../../../shared/data/Data_I18N";
// import { t } from "../../../shared/data/Data_I18N";

export const ServerEventFeed = () => {
    const { events } = useRecoilValue(serverEventFeedStateAtom);

    const eventfeedDom = events.map((event: ServerEvent) => {
        let locationName: string | undefined = undefined;
        let middle: string | undefined = undefined;

        switch (event.type) {
            case ServerEventFeedEventTypes.Loot:
                locationName = Game.GetLocationNameFromXY(event.locationX, event.locationY);
                if (locationName === "Wilderness") {
                    middle = t("event_feed__in_the");
                } else {
                    middle = t("event_feed__at");
                }
                return (
                    <li key={event.id} className={"animate__animated animate__fadeOut"}>
                        <span className={"username"}>{event.username}</span> { t("event_feed__looted_a") } <span className={ItemRarity[event.rarity].toLowerCase()}> {t(ItemRarity[event.rarity].toLowerCase())}</span> {middle} <span className={"location"}>{t(locationName.toLowerCase())}</span>!
                    </li>
                );
            case ServerEventFeedEventTypes.Kill:
                return (
                    <li key={event.id} className={"animate__animated animate__fadeOut"}>
                        <span className={"username"}>{event.killer}</span> { t("event_feed__killed") } <span className={`username ${event.victim === "GRONK" ? t("event_feed__boss") : ""}`}>{event.victim}</span>!
                    </li>
                );
            case ServerEventFeedEventTypes.ExtractionStarted:
                locationName = Game.GetLocationNameFromXY(event.locationX, event.locationY);
                if (locationName === "Wilderness") {
                    middle = t("event_feed__in_the");
                } else {
                    middle = t("event_feed__at");
                }
                return (
                    <li key={event.id} className={"animate__animated animate__fadeOut"}>
                        <span className={"username"}>{event.playerName}</span> { t("event_feed__is_extracting") } {middle} <span className={"location"}>{t(locationName.toLowerCase())}</span>!
                    </li>
                );
            case ServerEventFeedEventTypes.ExtractionSuccessful:
                locationName = Game.GetLocationNameFromXY(event.locationX, event.locationY);
                if (locationName === "Wilderness") {
                    middle = t("event_feed__in_the");
                } else {
                    middle = t("event_feed__at");
                }
                return (
                    <li key={event.id} className={"animate__animated animate__fadeOut"}>
                        <span className={"username"}>{event.playerName}</span> { t("event_feed__has_extracted") }
                    </li>
                );
            case ServerEventFeedEventTypes.BossSpawn:
                return (
                    <li key={event.id} className={"animate__animated animate__fadeOut"}>
                        <span className={"boss"}>{event.bossName}</span> { t("event_feed__has_spawned") } <span className={"location"}>{t(Game.GetLocationNameFromXY(event.locationX, event.locationY).toLowerCase())}</span>!
                    </li>
                );
            case ServerEventFeedEventTypes.BossAttack:
                return (
                    <li key={event.id} className={"animate__animated animate__fadeOut"}>
                        <span className={"boss"}>{event.bossName}</span> { t("event_feed__under_attack") } <span className={"location"}>{ t(Game.GetLocationNameFromXY(event.locationX, event.locationY).toLowerCase()) }</span>!
                    </li>
                );
        }
    });

    return (
        <div className="server-event-feed animate__animated animate__slideInRight">
            <ul>{eventfeedDom}</ul>
        </div>
    );
};

import React from "react";
import { useRecoilValue } from "recoil";
import { inGameLeaderboardState } from "../framework/UI_State";
import ReactDOM from "react-dom";
import { Tooltip } from "react-tooltip";
import { t } from "../../../shared/data/Data_I18N";

// @ts-ignore
import registeredUserImgSrc from "../kit/accountIcon-diamond.png";

export const TopRightHUD = () => {
    const leaderboardState = useRecoilValue(inGameLeaderboardState);
    const { show, entries } = leaderboardState;
    const tooltipContainer = document.getElementById("tooltip-container") as Element;

    const leaderboardRows = entries.map((entry, __index) => {
        const { r, un, pk, i, npck, reg } = entry;
        return (
            <div className="leaderboard-row" key={`un${Math.random() * 3000}`}>
                {reg && <img src={registeredUserImgSrc} alt="registered-user-icon" className="registered-user-icon" />}
                <div className="rank">#{r}:</div>
                <div className="username" data-tooltip-id={`username-tooltip-${un}`}>
                    {un}
                    {ReactDOM.createPortal(<Tooltip id={`username-tooltip-${un}`} style={{ fontSize: "18px" }} />, tooltipContainer)}
                </div>
                <div className="player-kills">{pk}</div>
                <div className="items-looted">{i}</div>
                <div className="npc-kills">{npck}</div>
            </div>
        );
    });

    return (
        <div className="top-right-hud animate__animated animate__slideInRight">
            <div className={`leaderboard ${show ? "" : "hidden"}`}>
                {show && <div className="leaderboard-title">{t("game_hud__leaderboard_current_world")}</div>}
                {show && leaderboardRows.length > 0 && (
                    <div className="leaderboard-legend">
                        <div className="rank"></div>
                        <div className="username"></div>
                        <div className="player-kills">{ t("leaderboard__player_kills") }</div>
                        <div className="items-looted">{ t("leaderboard__loot") }</div>
                        <div className="npc-kills">{ t("leaderboard__npc_kills") }</div>
                    </div>
                )}
                {show && leaderboardRows}
                <div className="leaderboard-close">
                    [ {t("hotkey_toggle_leaderboard")} ] {show ? t("game_hud__leaderboard_hide") : t("game_hud__leaderboard_show")} {t("game_hud__leaderboard")}
                </div>
            </div>
            <div className="stats-preview"></div>
        </div>
    );
};

/* eslint-disable @typescript-eslint/naming-convention */
import { t } from "../../shared/data/Data_I18N";
import { GameplaySystem } from "../../shared/engine/SharedGameplaySystem";
import { LoadoutSlots } from "../../shared/systems/SharedGearAndWeaponSystem";
import { Screens, UI_InsureSlot, UI_SwapScreen } from "../ui/framework/UI_State";
import { PopToast, ToastStatuses } from "../ui/kit/toast-utils";

export class ClientRewardsManager extends GameplaySystem {
    private rewardedAdParent: HTMLElement;

    public constructor() {
        super();
        if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "development") {
            this.rewardedAdParent = document.getElementById("rewardedad-parent")!;
        }
    }

    protected override getSystemName(): string {
        return "Rewards";
    }

    public Initialize(): void {
        this.LogInfo("Ready!");
    }

    private _adInPlayIsntReady(): boolean {
        // @ts-ignore
        return window.aiptag === undefined;
    }

    private _crazyGamesIsntReady(): boolean {
        // @ts-ignore
        return window.CrazyGames === undefined;
    }

    public async VideoRewardComplete(): Promise<void> {
        // console.warn("@@@ SDK Calling InsuranceComplete with sdk...");
        console.warn(ThirdPartySDK);
        if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "development") {
            // console.warn("@@@ SDK reward play complete!");
            this.rewardedAdParent.style.display = "none";
        }
    }

    public async PreGame(): Promise<void> {
        // console.warn("@@@ SDK Showing pre insurance video reward with SDK...");
        console.warn(ThirdPartySDK);

        if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "development") {
            if (ThirdPartySDK === "adinplay") {
                UI_SwapScreen(Screens.FindingMatch);
                Game.EmitEvent("GameLifecycle::RunStarted");
            } else if (ThirdPartySDK === "crazygames") {
                // console.warn("@@@ SDK requesting INSURANCE ...");

                if (this._crazyGamesIsntReady()) {
                    // console.warn("@@@ SDK crazygames sdk isnt ready, delaying INSURANCE before retrying...");
                    setTimeout(async () => {
                        this.PreGame();
                    }, 25);
                    return;
                } else {
                    // console.warn("@@@ SDK crazygames sdk is ready! executing operation INSURANCE");
                }

                // @ts-ignore
                window.CrazyGames.SDK.environment = "crazygames";

                const callbacks = {
                    adFinished: () => {
                        // console.warn("@@@ SDK End rewarded ad (callback)");
                        this.VideoRewardComplete();
                        UI_SwapScreen(Screens.FindingMatch);
                        Game.EmitEvent("GameLifecycle::RunStarted");
                    },
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    adError: (__error: any) => {
                        // console.warn("@@@ SDK Error rewarded ad (callback)", error);
                        this.VideoRewardComplete();
                        UI_SwapScreen(Screens.FindingMatch);
                        Game.EmitEvent("GameLifecycle::RunStarted");
                    },
                    adStarted: () => {
                        // console.warn("@@@ SDK Start rewarded ad (callback)");
                    }
                };

                //@ts-ignore
                window.CrazyGames.SDK.ad.requestAd("midgame", callbacks);
            } else {
                UI_SwapScreen(Screens.FindingMatch);
                Game.EmitEvent("GameLifecycle::RunStarted");
            }
        }
    }

    public async PreInsurance(slot: LoadoutSlots): Promise<void> {
        // console.warn("@@@ SDK Showing pre insurance video reward with SDK...");
        console.warn(ThirdPartySDK);

        if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "development") {
            if (ThirdPartySDK === "adinplay") {
                if (this._adInPlayIsntReady()) {
                    // console.warn("@@@ SDK adinplay sdk isnt ready, delaying INSURANCE before retrying...");
                    setTimeout(async () => {
                        this.PreInsurance(slot);
                    }, 25);
                    return;
                } else {
                    // console.warn("@@@ SDK adinplay sdk is ready! executing operation INSURANCE");
                }

                this.rewardedAdParent.style.display = "flex";

                UI_InsureSlot(slot);

                // @ts-ignore
                window.aiptag.cmd.player.push(function () {
                    // console.warn("@@@ SDK starting INSURANCE!");
                    // @ts-ignore
                    window.aiptag.adplayer.startPreRoll();
                });
            } else if (ThirdPartySDK === "crazygames") {
                // console.warn("@@@ SDK requesting INSURANCE ...");

                if (this._crazyGamesIsntReady()) {
                    // console.warn("@@@ SDK crazygames sdk isnt ready, delaying INSURANCE before retrying...");
                    setTimeout(async () => {
                        this.PreInsurance(slot);
                    }, 25);
                    return;
                } else {
                    // console.warn("@@@ SDK crazygames sdk is ready! executing operation INSURANCE");
                }

                // @ts-ignore
                window.CrazyGames.SDK.environment = "crazygames";

                const callbacks = {
                    adFinished: () => {
                        // console.warn("@@@ SDK End rewarded ad (callback)");
                        this.VideoRewardComplete();
                        UI_InsureSlot(slot);
                    },
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    adError: (__error: any) => {
                        // console.warn("@@@ SDK Error rewarded ad (callback)", error);
                        this.VideoRewardComplete();
                        PopToast({
                            status: ToastStatuses.ERROR,
                            message: t("adblock_detected_failed_to_insure")
                        });
                    },
                    adStarted: () => {
                        // console.warn("@@@ SDK Start rewarded ad (callback)");
                    }
                };
                //@ts-ignore
                window.CrazyGames.SDK.ad.requestAd("rewarded", callbacks);
            } else {
                UI_InsureSlot(slot);
            }
        }
    }

    public async LoadingStarted(): Promise<void> {
        // console.warn("@@@ SDK loading started with sdk...", ThirdPartySDK);
        if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "development") {
            if (ThirdPartySDK === "adinplay") {
                // no-op
            } else if (ThirdPartySDK === "crazygames") {
                // console.warn("@@@ SDK loading started for crazygames ...");

                if (this._crazyGamesIsntReady()) {
                    // console.warn("@@@ SDK crazygames sdk isnt ready, delaying LoadingStarted before retrying...");
                    setTimeout(async () => {
                        this.LoadingStarted();
                    }, 25);
                    return;
                } else {
                    // console.warn("@@@ SDK crazygames sdk is ready! executing operation LoadingStarted");
                }

                // @ts-ignore
                window.CrazyGames.SDK.environment = "crazygames";

                try {
                    // @ts-ignore
                    const result = await window.CrazyGames.SDK.game.sdkGameLoadingStart(); // result is always undefined for sdkGameLoadingStart/Stop
                    console.log("Game loading start triggered", result);
                } catch (e) {
                    console.log("Game loading start error", e);
                }
            }
        }
    }

    public async LoadingComplete(): Promise<void> {
        // console.warn("@@@ SDK loading completed with sdk...", ThirdPartySDK);
        if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "development") {
            if (ThirdPartySDK === "adinplay") {
                // no-op
            } else if (ThirdPartySDK === "crazygames") {
                // console.warn("@@@ SDK loading completed for crazygames ...");

                if (this._crazyGamesIsntReady()) {
                    // console.warn("@@@ SDK crazygames sdk isnt ready, delaying LoadingComplete before retrying...");
                    setTimeout(async () => {
                        this.LoadingComplete();
                    }, 25);
                    return;
                } else {
                    // console.warn("@@@ SDK crazygames sdk is ready! executing operation LoadingComplete");
                }

                // @ts-ignore
                window.CrazyGames.SDK.environment = "crazygames";

                try {
                    // @ts-ignore
                    const result = await window.CrazyGames.SDK.game.sdkGameLoadingStop(); // result is always undefined for sdkGameLoadingStart/Stop
                    console.log("Game loading completed triggered", result);
                } catch (e) {
                    console.log("Game loading completed error", e);
                }
            }
        }
    }

    public async GameplayStarted(): Promise<void> {
        // console.warn("@@@ SDK gameplay started with sdk...", ThirdPartySDK);
        if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "development") {
            if (ThirdPartySDK === "adinplay") {
                // no-op
            } else if (ThirdPartySDK === "crazygames") {
                // console.warn("@@@ SDK gameplay started for crazygames ...");

                if (this._crazyGamesIsntReady()) {
                    // console.warn("@@@ SDK crazygames sdk isnt ready, delaying GameplayStarted before retrying...");
                    setTimeout(async () => {
                        this.GameplayStarted();
                    }, 25);
                    return;
                } else {
                    // console.warn("@@@ SDK crazygames sdk is ready! executing operation GameplayStarted");
                }

                // @ts-ignore
                window.CrazyGames.SDK.environment = "crazygames";

                try {
                    // @ts-ignore
                    const result = await window.CrazyGames.SDK.game.gameplayStart(); // result is always undefined for gameplayStart/Stop
                    console.log("Gameplay start triggered", result);
                } catch (e) {
                    console.log("Gameplay start error", e);
                }
            }
        }
    }

    public async GameplayEnded(): Promise<void> {
        // console.warn("@@@ SDK gameplay ended with sdk...", ThirdPartySDK);
        if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "development") {
            if (ThirdPartySDK === "adinplay") {
                // no-op
            } else if (ThirdPartySDK === "crazygames") {
                // console.warn("@@@ SDK gameplay ended for crazygames ...");

                if (this._crazyGamesIsntReady()) {
                    // console.warn("@@@ SDK crazygames sdk isnt ready, delaying GameplayEnded before retrying...");
                    setTimeout(async () => {
                        this.GameplayEnded();
                    }, 25);
                    return;
                } else {
                    // console.warn("@@@ SDK crazygames sdk is ready! executing operation GameplayEnded");
                }

                // @ts-ignore
                window.CrazyGames.SDK.environment = "crazygames";

                try {
                    // @ts-ignore
                    const result = await window.CrazyGames.SDK.game.gameplayStop(); // result is always undefined for gameplayStart/Stop
                    console.log("Gameplay start triggered", result);
                } catch (e) {
                    console.log("Gameplay start error", e);
                }
            }
        }
    }

    public async ShowMainMenuLeft(): Promise<void> {
        // console.warn("@@@ SDK Showing main menu left with sdk...", ThirdPartySDK);
        if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "development") {
            if (ThirdPartySDK === "adinplay") {
                if (this._adInPlayIsntReady()) {
                    // console.warn("@@@ SDK adinplay sdk isnt ready, delaying ShowMainMenuLeft before retrying...");
                    setTimeout(async () => {
                        this.ShowMainMenuLeft();
                    }, 25);
                    return;
                } else {
                    // console.warn("@@@ SDK adinplay sdk is ready! executing operation ShowMainMenuLeft");
                }

                const screenHeight = window.innerHeight;
                const screenWidth = window.innerWidth;
                const screenSizeIsSufficientlyLarge = screenWidth >= 578 && screenHeight >= 605;

                if (screenSizeIsSufficientlyLarge) {
                    // @ts-ignore
                    window.aiptag.cmd.display.push(function () {
                        // @ts-ignore
                        aipDisplayTag.display("pvpz-io_160x600_3");
                    });
                }
            } else if (ThirdPartySDK === "crazygames") {
                // console.warn("@@@ SDK requesting menu left banner ...");

                if (this._crazyGamesIsntReady()) {
                    // console.warn("@@@ SDK crazygames sdk isnt ready, delaying ShowMainMenuLeft before retrying...");
                    setTimeout(async () => {
                        this.ShowMainMenuLeft();
                    }, 25);
                    return;
                } else {
                    // console.warn("@@@ SDK crazygames sdk is ready! executing operation ShowMainMenuLeft");
                }

                // @ts-ignore
                window.CrazyGames.SDK.environment = "crazygames";

                try {
                    // @ts-ignore
                    const result = await window.CrazyGames.SDK.banner.requestResponsiveBanner("pvpz-io_160x600_3");
                    // console.warn("@@@ SDK End request responsive banner", result); // result is always undefined when requesting banners
                } catch (e) {
                    // console.warn("@@@ SDK Error on request responsive banner", e);
                }
            }
        }
    }

    public async ShowMainMenuRight(): Promise<void> {
        // console.warn("@@@ SDK Showing main menu right with sdk...", ThirdPartySDK);
        if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "development") {
            if (ThirdPartySDK === "adinplay") {
                // @ts-ignore
                if (this._adInPlayIsntReady()) {
                    // console.warn("@@@ SDK adinplay sdk isnt ready, delaying ShowMainMenuRight before retrying...");
                    setTimeout(async () => {
                        this.ShowMainMenuRight();
                    }, 25);
                    return;
                } else {
                    // console.warn("@@@ SDK adinplay sdk is ready! executing operation ShowMainMenuRight");
                }

                const screenHeight = window.innerHeight;
                const screenWidth = window.innerWidth;
                const screenSizeIsSufficientlyLarge = screenWidth >= 578 && screenHeight >= 605;

                if (screenSizeIsSufficientlyLarge) {
                    // @ts-ignore
                    window.aiptag.cmd.display.push(function () {
                        // @ts-ignore
                        aipDisplayTag.display("pvpz-io_160x600_4");
                    });
                }
            } else if (ThirdPartySDK === "crazygames") {
                // console.warn("@@@ SDK requesting menu right banner ...");

                if (this._crazyGamesIsntReady()) {
                    // console.warn("@@@ SDK crazygames sdk isnt ready, delaying ShowMainMenuRight before retrying...");
                    setTimeout(async () => {
                        this.ShowMainMenuRight();
                    }, 25);
                    return;
                } else {
                    // console.warn("@@@ SDK crazygames sdk is ready! executing operation ShowMainMenuRight");
                }

                // @ts-ignore
                window.CrazyGames.SDK.environment = "crazygames";

                try {
                    // @ts-ignore
                    const result = await window.CrazyGames.SDK.banner.requestResponsiveBanner("pvpz-io_160x600_4");
                    // console.warn("@@@ SDK End request responsive banner", result); // result is always undefined when requesting banners
                } catch (e) {
                    // console.warn("@@@ SDK Error on request responsive banner", e);
                }
            }
        }
    }

    public async Congratulate(): Promise<void> {}

    public async ShowInGameLeft(): Promise<void> {
        // console.warn("@@@ SDK Showing in game left with sdk...");
        console.warn(ThirdPartySDK);
        if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "development") {
            if (ThirdPartySDK === "adinplay") {
                if (this._adInPlayIsntReady()) {
                    // console.warn("@@@ SDK adinplay sdk isnt ready, delaying ShowInGameLeft before retrying...");
                    setTimeout(async () => {
                        this.ShowInGameLeft();
                    }, 25);
                    return;
                } else {
                    // console.warn("@@@ SDK adinplay sdk is ready! executing operation ShowInGameLeft");
                }

                const screenHeight = window.innerHeight;
                const screenWidth = window.innerWidth;
                const screenSizeIsSufficientlyLarge = screenWidth >= 578 && screenHeight >= 605;

                if (screenSizeIsSufficientlyLarge) {
                    // @ts-ignore
                    window.aiptag.cmd.display.push(function () {
                        // @ts-ignore
                        aipDisplayTag.display("pvpz-io_160x600_1");
                    });
                }
            } else if (ThirdPartySDK === "crazygames") {
                // console.warn("@@@ SDK requesting menu left banner ...");

                if (this._crazyGamesIsntReady()) {
                    // console.warn("@@@ SDK crazygames sdk isnt ready, delaying ShowInGameLeft before retrying...");
                    setTimeout(async () => {
                        this.ShowInGameLeft();
                    }, 25);
                    return;
                } else {
                    // console.warn("@@@ SDK crazygames sdk is ready! executing operation ShowInGameLeft");
                }

                // @ts-ignore
                window.CrazyGames.SDK.environment = "crazygames";

                const screenHeight = window.innerHeight;
                const screenSizeIsSufficientlyLarge = screenHeight >= 601;

                try {
                    if (screenSizeIsSufficientlyLarge) {
                        // @ts-ignore
                        const result = await window.CrazyGames.SDK.banner.requestResponsiveBanner("pvpz-io_160x600_1");
                        // console.warn("@@@ SDK End request responsive banner", result); // result is always undefined when requesting banners
                    }
                } catch (e) {
                    // console.warn("@@@ SDK Error on request responsive banner", e);
                }
            }
        }
    }

    public async ShowInGameRight(): Promise<void> {
        // console.warn("@@@ SDK Showing in game right with sdk...");
        console.warn(ThirdPartySDK);
        if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "development") {
            if (ThirdPartySDK === "adinplay") {
                if (this._adInPlayIsntReady()) {
                    // console.warn("@@@ SDK adinplay sdk isnt ready, delaying ShowInGameRight before retrying...");
                    setTimeout(async () => {
                        this.ShowInGameRight();
                    }, 25);
                    return;
                } else {
                    // console.warn("@@@ SDK adinplay sdk is ready! executing operation ShowInGameRight");
                }

                const screenHeight = window.innerHeight;
                const screenWidth = window.innerWidth;
                const screenSizeIsSufficientlyLarge = screenWidth >= 578 && screenHeight >= 605;

                if (screenSizeIsSufficientlyLarge) {
                    // @ts-ignore
                    window.aiptag.cmd.display.push(function () {
                        // @ts-ignore
                        aipDisplayTag.display("pvpz-io_160x600_2");
                    });
                }
            } else if (ThirdPartySDK === "crazygames") {
                // console.warn("@@@ SDK requesting menu left banner ...");

                if (this._crazyGamesIsntReady()) {
                    // console.warn("@@@ SDK crazygames sdk isnt ready, delaying ShowInGameRight before retrying...");
                    setTimeout(async () => {
                        this.ShowInGameRight();
                    }, 25);
                    return;
                } else {
                    // console.warn("@@@ SDK crazygames sdk is ready! executing operation ShowInGameRight");
                }

                // @ts-ignore
                window.CrazyGames.SDK.environment = "crazygames";

                const screenHeight = window.innerHeight;
                const screenSizeIsSufficientlyLarge = screenHeight >= 601;

                try {
                    if (screenSizeIsSufficientlyLarge) {
                        // @ts-ignore
                        const result = await window.CrazyGames.SDK.banner.requestResponsiveBanner("pvpz-io_160x600_2");
                        // console.warn("@@@ SDK End request responsive banner", result); // result is always undefined when requesting banners
                    }
                } catch (e) {
                    // console.warn("@@@ SDK Error on request responsive banner", e);
                }
            }
        }
    }

    public override Update(__deltaTime: number): void {}

    public override Cleanup(): void {}
}

// I hate this but it's apparently necessary, rip
import "react-tooltip/dist/react-tooltip.css";
import React from "react";
import { ToastContainer } from "react-toastify";
import { useRecoilValue } from "recoil";
import { MainMenu } from "../screens/UI_MainMenu";
import { Settings } from "../screens/UI_Settings";
import { HowToPlay } from "../screens/UI_HowToPlay";
import { RunPrep } from "../screens/UI_RunPrep";
import { Screens, currentScreenStateAtom, fullScreenErrorStateAtom, assetLoadingStateAtom, identityErrorStateAtom, uiModeStateAtom, UIModes, debugStateAtom, spectatingStateAtom, extractionSuccessfulStateAtom, youDiedStateAtom } from "./UI_State";
import { FullScreenError } from "../components/UI_FullScreenError";
import { Vendors } from "../screens/UI_Vendors";
import { Rankings } from "../screens/UI_Rankings";
import { LoadingBar } from "../components/UI_LoadingBar";
import { UserIdentityError } from "../components/UI_UserIdentityError";
import { YouDiedPanel } from "../components/UI_YouDiedPanel";
import { KitTest } from "../screens/UI_KitTest";
import { RunInProgress } from "../screens/UI_RunInProgress";
import { ExtractionInProgress } from "../components/UI_ExtractionInProgress";
import { FindingMatch } from "../components/UI_FindingMatch";
import { Spectating } from "../components/UI_Spectating";
import { ExtractionSuccessfulPanel } from "../components/UI_ExtractionSuccessful";
import { ExtractionTimeRemaining } from "../components/UI_ExtractionTimeRemaining";
import { GearAndWeaponHUD } from "../components/UI_GearAndWeaponHUD";
import { ChangeUsername } from "../screens/UI_ChangeUsername";
import { Stats } from "../screens/UI_Stats";
import { Inventory } from "../screens/UI_Inventory";
import { ItemContainerPanel } from "../components/UI_ItemContainerPanel";
import { InRunInventoryPanel } from "../components/UI_InRunInventory";
import { ItemTest } from "../screens/UI_ItemTest";
import { TopRightHUD } from "../components/UI_TopRightHud";
import { BottomLeftHUD } from "../components/UI_BottomLeftHud";
import { MatchmakingError } from "../screens/UI_MatchmakingError";
import { TopLeftHUD } from "../components/UI_TopLeftHud";
import { RegionPopUp } from "../components/UI_RegionPopUp";
import { RightContainer } from "../components/UI_RightContainer";
import { LeftContainer } from "../components/UI_LeftContainer";
import { MainMenuLeftContainer } from "../components/UI_MainMenuLeftContainer";
import { MainMenuRightContainer } from "../components/UI_MainMenuRightContainer";
import { Partners } from "../screens/UI_Partners";
import { ServerEventFeed } from "../components/UI_ServerEventFeed";
import { Register } from "../screens/UI_Register";
import { Login } from "../screens/UI_Login";
import { CollectionLog } from "../screens/UI_CollectionLog";
import { PatchNotes } from "../screens/UI_PatchNotes";
import { Shop } from "../screens/UI_Shop";
import { BundleDetails } from "../screens/UI_BundleDetails";
import { ConfirmSkinPurchase } from "../screens/UI_ConfirmSkinPurchase";
import { ConfirmInsurancePurchase } from "../screens/UI_InsurancePurchase";
import { PurchaseSuccessful } from "../screens/UI_PurchaseSuccessful";
import { ConfirmCoinInsurancePurchase } from "../screens/UI_CoinInsurancePurchase";

export const UIScreenRouter = () => {
    const { show: showYouDiedPanel } = useRecoilValue(youDiedStateAtom);
    const { show: showExtractionSuccessfulPanel } = useRecoilValue(extractionSuccessfulStateAtom);
    const currentUIMode = useRecoilValue(uiModeStateAtom);
    const currentScreen = useRecoilValue(currentScreenStateAtom);
    const { show: userIdentityError } = useRecoilValue(identityErrorStateAtom);
    const { show: gameBreakingError } = useRecoilValue(fullScreenErrorStateAtom);
    const { loadingAssets } = useRecoilValue(assetLoadingStateAtom);
    const { skippingMenu } = useRecoilValue(debugStateAtom);
    const { spectating } = useRecoilValue(spectatingStateAtom);

    const MainMenuUI = (
        <>
            {currentScreen === Screens.MainMenu && <MainMenu />}
            {currentScreen === Screens.Vendors && <Vendors />}
            {currentScreen === Screens.HowToPlay && <HowToPlay />}
            {currentScreen === Screens.HowToPlay && <HowToPlay />}
            {currentScreen === Screens.Rankings && <Rankings />}
            {currentScreen === Screens.Settings && <Settings />}
            {currentScreen === Screens.RunPrep && <RunPrep />}
            {currentScreen === Screens.BundleDetails && <BundleDetails />}
            {currentScreen === Screens.ConfirmInsurancePurchase && <ConfirmInsurancePurchase />}
            {currentScreen === Screens.ConfirmCoinInsurancePurchase && <ConfirmCoinInsurancePurchase />}
            {currentScreen === Screens.ConfirmSkinPurchase && <ConfirmSkinPurchase />}
            {currentScreen === Screens.PurchaseSuccessful && <PurchaseSuccessful />}
            {currentScreen === Screens.Shop && <Shop />}
            {currentScreen === Screens.Partners && <Partners />}
            {currentScreen === Screens.Register && <Register />}
            {currentScreen === Screens.Login && <Login />}
            {currentScreen === Screens.KitTest && <KitTest />}
            {currentScreen === Screens.RunInProgress && <RunInProgress />}
            {currentScreen === Screens.FindingMatch && <FindingMatch />}
            {currentScreen === Screens.ChangeUsername && <ChangeUsername />}
            {currentScreen === Screens.Stats && <Stats />}
            {currentScreen === Screens.Inventory && <Inventory />}
            {currentScreen === Screens.ItemTest && <ItemTest />}
            {currentScreen === Screens.PatchNotes && <PatchNotes />}
            {currentScreen === Screens.CollectionLog && <CollectionLog />}
            {currentScreen === Screens.MatchmakingError && <MatchmakingError />}
            <MainMenuLeftContainer />
            <MainMenuRightContainer />
        </>
    );

    const InGameUI = spectating ? (
        <div className="game-hud">
            <Spectating />
        </div>
    ) : (
        <div className="game-hud">
            <ExtractionTimeRemaining />
            <ExtractionSuccessfulPanel />
            <YouDiedPanel />
            <ExtractionInProgress />
            <GearAndWeaponHUD />
            {showYouDiedPanel === false && showExtractionSuccessfulPanel === false && <ServerEventFeed />}
            {showYouDiedPanel === false && showExtractionSuccessfulPanel === false && <TopRightHUD />}
            {showYouDiedPanel === false && showExtractionSuccessfulPanel === false && <BottomLeftHUD />}
            {showYouDiedPanel === false && showExtractionSuccessfulPanel === false && <TopLeftHUD />}
            {showYouDiedPanel === false && showExtractionSuccessfulPanel === false && <RegionPopUp />}
            {showYouDiedPanel === false && showExtractionSuccessfulPanel === false && (
                <div className="left-inventory-containers">
                    <InRunInventoryPanel />
                    <ItemContainerPanel />
                </div>
            )}
            {(showYouDiedPanel || showExtractionSuccessfulPanel) && <RightContainer />}
            {(showYouDiedPanel || showExtractionSuccessfulPanel) && <LeftContainer />}
        </div>
    );

    if (userIdentityError) {
        return <UserIdentityError />;
    } else if (gameBreakingError) {
        return <FullScreenError />;
    } else {
        return (
            <>
                <div className="screen-too-small">
                    <h2>
                        <span>Sorry!</span>
                        <br />
                        It looks like you are trying to play on a phone or tablet and that is not yet supported. Expect a mobile version in the near future!
                    </h2>
                </div>
                <div className="screen-router">
                    {currentUIMode === UIModes.Menus && skippingMenu === false && MainMenuUI}
                    {(currentUIMode === UIModes.InGame || skippingMenu === true) && InGameUI}
                </div>
                {loadingAssets && <LoadingBar />}
                <ToastContainer position="top-center" autoClose={2000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
            </>
        );
    }
};

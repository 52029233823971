import { Client, Interpolator } from "nengi";
import { GameplaySystem } from "../../shared/engine/SharedGameplaySystem";
import { AdminShutdownCommand, InputCommand, InteractCommand, LootItemCommand, NType, PlaceholderPingCommand, SpectateMyKillerCommand, nengiConfig } from "../../shared/SharedNetcodeSchemas";
import { WebSocketClientAdapter } from "nengi-websocket-client-adapter";
import { NetworkEntityId, ServerConnectionErrorReasons, uuid } from "../../shared/SharedTypes";
import { AlternateKeys, Keys } from "./ClientInputManager";
import { ClientPredictor } from "./ClientPredictor";
import { UIModes, UI_DisconnectIsIntentional, UI_ItemContainerUIIsOpen, UI_ShowFullScreenError, UI_SwapUIMode, UI_UpdateLatestPing } from "../ui/framework/UI_State";
import { playerConfig } from "../../shared/config/Config_Player";
import { serverConfig } from "../../shared/config/Config_Server";
import { ClientPlayer } from "../entities/ClientPlayer";
import { Item } from "../../shared/data/Data_Items";

export class ClientNetcode extends GameplaySystem {
    private client: Client;
    private interpolator: Interpolator;
    private predictor: ClientPredictor;
    private predictionReady: boolean = false;
    private serverSideSelfReady: boolean = false;
    private isConnected: boolean = false;
    private myServerSideEntity: ClientPlayer;
    private sentPingTimestamp: number = Date.now();
    private windowHasFocus: boolean = true;
    private lastBroadcastedPingToUIAccumulator: number = 2;
    private readonly broadcastPingToUIThrottleMs: number = 2;
    private lastSentInteractCommandAccumulator: number = 0;
    private readonly interactCommandThrottleMs: number = 0.7;

    public constructor(predictor: ClientPredictor) {
        super();
        this.predictor = predictor;

        Game.ListenForEvent("Resizer::GameWindowResizeComplete", this._onGameResized.bind(this));
        Game.ListenForEvent("Prediction::PredictionReady", () => {
            this.predictionReady = true;
        });
        Game.ListenForEvent("Bootstrap::MyServerSideEntityIsFullyCreatedLocally", (event) => {
            // @ts-ignore
            const { myEntity } = event;
            this.myServerSideEntity = myEntity;
            this.serverSideSelfReady = true;
        });

        this.client = new Client(nengiConfig, WebSocketClientAdapter);
        this.interpolator = new Interpolator(this.client);

        // TODO on disconnect callback in client when the API is available

        window.onfocus = () => {
            this.windowHasFocus = true;
        };

        window.onblur = () => {
            this.windowHasFocus = false;
            if (this.isConnected) {
                const inputCommand: InputCommand = {
                    ntype: NType.InputCommand,
                    up: false,
                    down: false,
                    left: false,
                    right: false,
                    usedWeapon: false,
                    genericSwappedWeapon: false,
                    swappedToFirstWeapon: false,
                    swappedToSecondWeapon: false,
                    usedGearOne: false,
                    usedGearTwo: false,
                    usedGearThree: false,
                    aim: 0,
                    delta: 0.016
                };

                if (playerConfig.ENABLE_PREDICTION && this.predictionReady && this.predictor.LocallyPredictedEntityIsAlive()) {
                    this.predictor.PredictPlayerInputCommand(inputCommand);
                }

                this.client.addCommand(inputCommand);
            }
        };
    }

    public override Initialize(): void {}

    protected override getSystemName(): string {
        return "Netcode";
    }

    public async Connect(serverAddress: string, connectionToken: string, gameClientVersion: string): Promise<void> {
        try {
            const { scaledWidth, scaledHeight } = Game.Resizer.GetVisibleWorldBoundsWithCameraScale();
            const clientZoomLevel = Game.Renderer.CameraZoomLevel;

            const res = await this.client.connect(serverAddress, { connectionToken: connectionToken, clientWidth: scaledWidth, clientHeight: scaledHeight, gameClientVersion: gameClientVersion, clientZoomLevel });

            Game.Rewards.GameplayStarted();

            this.LogInfo(`Connection response: ${res}`);
            this.isConnected = true;

            UI_SwapUIMode(UIModes.InGame);

            if (process.env.NODE_ENV === "production") {
                window.addEventListener("beforeunload", (e) => {
                    if (this.myServerSideEntity.isAlive === true && this.myServerSideEntity.extractionSuccessful === false) {
                        e.preventDefault();
                    }
                });
            }

            // TODO: this will eventually be surfaced via the this.client interface, for now dig deep to find it
            this.client.adapter.socket.addEventListener("close", () => {
                console.log("Socket to server closed!");
                this.isConnected = false;
                if (UI_DisconnectIsIntentional()) {
                    console.log("Disconnect is intentional; not showing server disconnect error");
                } else {
                    UI_ShowFullScreenError("Server shut down", "the server is down. It is likely going down for maintenance.");
                }
            });
        } catch (err) {
            this.LogError("connection error");
            this.LogError(typeof err);
            this.LogError(err);
            let reasonExplanation: string;
            if (err === ServerConnectionErrorReasons.GameClientOutOfDate) {
                reasonExplanation = "Your game client is out of date! Please update your game client to the latest version and try again.";
            } else if (err === ServerConnectionErrorReasons.InvalidConnectionToken) {
                reasonExplanation = "The server rejected your user! Please try again.";
            } else if (err === ServerConnectionErrorReasons.PlayerAlreadyLoggedIn) {
                reasonExplanation = "You're already logged in!";
            } else {
                reasonExplanation = "Unknown connection error :(";
            }
            UI_ShowFullScreenError("Server connection error!", reasonExplanation);
            return;
        }

        this.LogInfo("Ready!");
    }

    public ReceivedPingAck() {
        const pingDuration = Date.now() - this.sentPingTimestamp;
        UI_UpdateLatestPing(pingDuration);
    }

    private _onGameResized({ newScaledWidth, newScaledHeight }: { newScaledWidth: number; newScaledHeight: number }): void {
        // console.warn("sending server our new bounds with zoom level:", Game.Renderer.CameraZoomLevel);
        this.client.addCommand({
            ntype: NType.ClientViewBoundsChangedCommand,
            cw: newScaledWidth,
            ch: newScaledHeight,
            cz: Game.Renderer.CameraZoomLevel
        });
    }

    public SendAdminShutdownCommand(): void {
        this.client.addCommand({
            ntype: NType.AdminShutdownCommand,
            shutdown: true
        } as AdminShutdownCommand);
    }

    public SendSpectateKillerCommand(): void {
        this.client.addCommand({
            ntype: NType.SpectateMyKillerCommand,
            spectateMyKiller: true
        } as SpectateMyKillerCommand);
    }

    public SendLootItemCommand(itemContainerId: uuid, itemId: Item) {
        this.client.addCommand({
            ntype: NType.LootItemCommand,
            interactingWithContainerOfId: itemContainerId,
            itemIdToLoot: itemId
        } as LootItemCommand);
    }

    public Update(deltaTime: number) {
        if (this.isConnected) {
            // Interpolate two server ticks worth of data
            const interpolatorState = this.interpolator.getInterpolatedState(serverConfig.TARGET_DELTA_IN_MS * 2);

            while (this.client.network.messages.length > 0) {
                Game.ProcessMessage(this.client.network.messages.pop());
            }

            interpolatorState.forEach((snapshot) => {
                snapshot.createEntities.forEach((entityToCreate) => {
                    Game.AddEntity(entityToCreate);
                });

                snapshot.updateEntities.forEach((entityUpdatePayload) => {
                    Game.UpdateEntity(entityUpdatePayload);
                });

                snapshot.deleteEntities.forEach((nid: NetworkEntityId) => {
                    Game.DeleteEntity(nid);
                });
            });

            // Only send input commands if our server-side entity is both fully created locally, as well as alive, and hasnt extracted successfully
            if (this.serverSideSelfReady && this.myServerSideEntity.isAlive && this.myServerSideEntity.extractionSuccessful === false && this.windowHasFocus) {
                // Movement inputs
                const up = Game.Input.IsKeyDown(Keys.W) || Game.Input.IsKeyDown(AlternateKeys.UpArrow) || Game.Input.IsKeyDown(Keys.RussianUp) || Game.Input.IsKeyDown(Keys.UppercaseW);
                const down = Game.Input.IsKeyDown(Keys.S) || Game.Input.IsKeyDown(AlternateKeys.DownArrow) || Game.Input.IsKeyDown(Keys.RussianDown) || Game.Input.IsKeyDown(Keys.UppercaseS);
                const left = Game.Input.IsKeyDown(Keys.A) || Game.Input.IsKeyDown(AlternateKeys.LeftArrow) || Game.Input.IsKeyDown(Keys.RussianLeft) || Game.Input.IsKeyDown(Keys.UppercaseA);
                const right = Game.Input.IsKeyDown(Keys.D) || Game.Input.IsKeyDown(AlternateKeys.RightArrow) || Game.Input.IsKeyDown(Keys.RussianRight) || Game.Input.IsKeyDown(Keys.UppercaseD);
                const interacted = Game.Input.IsKeyDown(Keys.E) || Game.Input.IsKeyDown(Keys.RussianE) || Game.Input.IsKeyDown(Keys.UppercaseE);

                // Weapon and gear inputs
                // let usedWeapon = Game.Input.LeftMousePressed();
                const usedWeapon = Game.Renderer.ClickedRendererThisFrame() || Game.Input.AimJoystickActive();
                const genericSwappedWeapon = Game.Input.Scrolled();
                const swappedToFirstWeapon = Game.Input.IsKeyDown(Keys.One);
                const swappedToSecondWeapon = Game.Input.IsKeyDown(Keys.Two);
                const usedGearOne = Game.Input.IsKeyDown(Keys.Three) || Game.Input.clickedGearOneThisFrame;
                const usedGearTwo = Game.Input.IsKeyDown(Keys.Four) || Game.Input.clickedGearTwoThisFrame;
                const usedGearThree = Game.Input.IsKeyDown(Keys.Five) || Game.Input.clickedGearThreeThisFrame;
                let aim: number = 0;

                // If prediction is enabled, use the predicted position of the player to calculate aim angle
                if (playerConfig.ENABLE_PREDICTION && this.predictionReady) {
                    aim = Game.Input.GetPlayerAimAngleFromCharacterPosition(this.predictor.GetPredictedEntityX(), this.predictor.GetPredictedEntityY());
                } else if (playerConfig.ENABLE_PREDICTION === false && this.serverSideSelfReady) {
                    // If prediction is disabled, use the server-side authoritative position of the player to calculate aim angle
                    aim = Game.Input.GetPlayerAimAngleFromCharacterPosition(Game.Renderer.GetMyLocalAuthoritativeEntityX(), Game.Renderer.GetMyLocalAuthoritativeEntityY());
                }

                const inputCommand: InputCommand = {
                    ntype: NType.InputCommand,
                    up,
                    down,
                    left,
                    right,
                    usedWeapon,
                    genericSwappedWeapon,
                    swappedToFirstWeapon,
                    swappedToSecondWeapon,
                    usedGearOne,
                    usedGearTwo,
                    usedGearThree,
                    aim,
                    delta: deltaTime
                };

                this.lastBroadcastedPingToUIAccumulator += deltaTime;
                this.lastSentInteractCommandAccumulator += deltaTime;

                if (this.lastBroadcastedPingToUIAccumulator > this.broadcastPingToUIThrottleMs) {
                    // console.log(Object.keys(this.client));
                    // @ts-ignore
                    // console.log(this.client.latency);
                    // @ts-ignore
                    // console.log(this.client.network.latency);
                    // UI_UpdateLatestPing(this.client.network.latency);
                    const pingCommand: PlaceholderPingCommand = {
                        ntype: NType.PlaceholderPingCommand,
                        ping: true
                    };
                    this.sentPingTimestamp = Date.now();
                    this.client.addCommand(pingCommand);
                    this.lastBroadcastedPingToUIAccumulator = 0;
                }

                if (interacted && this.lastSentInteractCommandAccumulator > this.interactCommandThrottleMs) {
                    // console.warn("@@@ throttled client send interact command!");

                    if (this.myServerSideEntity.InteractingWithContainerOfId !== null) {
                        const interactCommand: InteractCommand = {
                            ntype: NType.InteractCommand,
                            interactingWithContainerOfId: this.myServerSideEntity.InteractingWithContainerOfId
                        };

                        this.client.addCommand(interactCommand);

                        this.lastSentInteractCommandAccumulator = 0;
                    }
                }

                if (playerConfig.ENABLE_PREDICTION && this.predictionReady && this.predictor.LocallyPredictedEntityIsAlive()) {
                    // console.log('@@@');
                    // console.log(this.predictionReady);
                    // console.log(this.predictor.LocallyPredictedEntityIsAlive());

                    this.predictor.PredictPlayerInputCommand(inputCommand);
                }

                this.client.addCommand(inputCommand);
            }

            this.client.flush();
        }
    }

    public Cleanup(): void {
        this.LogInfo("Cleaning up...");
    }
}

import React from "react";
import { Screens, UI_SwapScreen, playerIdentityStateAtom, playerInventoryStateAtom } from "../framework/UI_State";
import { t } from "../../../shared/data/Data_I18N";
import { useRecoilValue } from "recoil";

// @ts-ignore
// import skullImgSrc from "../kit/skull.png";
// @ts-ignore
import lootchestImgSrc from "../kit/lootchest.png";
// @ts-ignore
import ogreImgSrc from "../kit/ogre.png";
// @ts-ignore
import ironfoxImgSrc from "../kit/ironfox.png";
// @ts-ignore
import ghostImgSrc from "../kit/ghost.png";
// @ts-ignore
import skeletonImgSrc from "../kit/skeleton.png";
// @ts-ignore
import giantratImgSrc from "../kit/giantrat.png";
// @ts-ignore
import darkwizardImgSrc from "../kit/darkwizard.png";
// @ts-ignore
import gronkImgSrc from "../kit/ogre.png";
// @ts-ignore
import treantImgSrc from "../kit/treant.png";
import { LootTable, getLootTableData } from "../../../shared/data/Data_Items";

export const CollectionLog = () => {
    const [activeCategory, setActiveCategory] = React.useState("all_enemies");
    const { items: allItemsInStash } = useRecoilValue(playerInventoryStateAtom);

    const allLootChestsLootTable = getLootTableData(LootTable.LootChest);

    console.log("allItemsFromStash", allItemsInStash);
    console.log("allLootChestsLootTable", allLootChestsLootTable);

    return (
        <>
            <div className="collection-log-panel animate__animated animate__fadeInUp animate__faster">
                <div className="collection-log-header">{t("collection_log__header")}</div>

                <div className="log">
                    <div className="log-categories">
                        {/* <div
                            onClick={() => {
                                setActiveCategory("all_enemies");
                            }}
                            className={`category ${activeCategory === "all_enemies" ? "active" : ""}`}
                        >
                            All Enemies
                        </div> */}
                        <div
                            onClick={() => {
                                setActiveCategory("all_chests");
                            }}
                            className={`category ${activeCategory === "all_chests" ? "active" : ""}`}
                        >
                            All Loot Chests
                        </div>
                        <div
                            onClick={() => {
                                setActiveCategory("ghost");
                            }}
                            className={`category ${activeCategory === "ghost" ? "active" : ""}`}
                        >
                            Ghost
                        </div>
                        <div
                            onClick={() => {
                                setActiveCategory("skeleton");
                            }}
                            className={`category ${activeCategory === "skeleton" ? "active" : ""}`}
                        >
                            Skeleton
                        </div>
                        <div
                            onClick={() => {
                                setActiveCategory("giantrat");
                            }}
                            className={`category ${activeCategory === "giantrat" ? "active" : ""}`}
                        >
                            Giant Rat
                        </div>
                        <div
                            onClick={() => {
                                setActiveCategory("treant");
                            }}
                            className={`category ${activeCategory === "treant" ? "active" : ""}`}
                        >
                            Treant
                        </div>
                        <div
                            onClick={() => {
                                setActiveCategory("ogre");
                            }}
                            className={`category ${activeCategory === "ogre" ? "active" : ""}`}
                        >
                            Ogre
                        </div>
                        <div
                            onClick={() => {
                                setActiveCategory("darkwizard");
                            }}
                            className={`category ${activeCategory === "darkwizard" ? "active" : ""}`}
                        >
                            Dark Wizard
                        </div>
                        <div
                            onClick={() => {
                                setActiveCategory("gronk");
                            }}
                            className={`category boss ${activeCategory === "gronk" ? "active" : ""}`}
                        >
                            BOSS: Gronk
                        </div>
                        <div
                            onClick={() => {
                                setActiveCategory("ironfox");
                            }}
                            className={`category boss ${activeCategory === "ironfox" ? "active" : ""}`}
                        >
                            BOSS: Iron Fox
                        </div>
                    </div>

                    <div className="log-data">
                        <div className="meta">
                            {activeCategory === "all_chests" && <img src={lootchestImgSrc} alt="lootchest" className="loot-source-preview lootchest" />}

                            {/* {activeCategory === "all_enemies" && <img src={skullImgSrc} alt="allenemies" className="loot-source-preview allenemies" />} */}

                            {activeCategory === "ghost" && <img src={ghostImgSrc} alt="ghost" className="loot-source-preview ghost" />}

                            {activeCategory === "skeleton" && <img src={skeletonImgSrc} alt="skeleton" className="loot-source-preview skeleton" />}

                            {activeCategory === "giantrat" && <img src={giantratImgSrc} alt="giantrat" className="loot-source-preview giantrat" />}

                            {activeCategory === "darkwizard" && <img src={darkwizardImgSrc} alt="darkwizard" className="loot-source-preview darkwizard" />}

                            {activeCategory === "ogre" && <img src={ogreImgSrc} alt="ogre" className="loot-source-preview ogre" />}

                            {activeCategory === "treant" && <img src={treantImgSrc} alt="treant" className="loot-source-preview treant" />}

                            {activeCategory === "gronk" && <img src={gronkImgSrc} alt="Gronk" className="loot-source-preview gronk" />}

                            {activeCategory === "ironfox" && <img src={ironfoxImgSrc} alt="ironfox" className="loot-source-preview ironfox" />}
                        </div>
                        <div className="log-items"></div>
                    </div>
                </div>

                <button
                    className="neutral-button"
                    onClick={() => {
                        UI_SwapScreen(Screens.MainMenu);
                    }}
                >
                    {t("stats__back_btn")}
                </button>
            </div>
        </>
    );
};

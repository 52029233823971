import { CharacterSkin } from "../data/Data_Cosmetics";
import { Item } from "../data/Data_Items";

export const playerConfig = {
    ENABLE_PREDICTION: true,
    SPEED_PER_SECOND: 70, // 70
    MOVEMENT_COLLIDER_Y_OFFSET: 9,
    MOVEMENT_COLLIDER_RADIUS: 6,
    HITBOX_COLLIDER_WIDTH: 12,
    HITBOX_COLLIDER_HEIGHT: 20,

    DEBUG: {
        LOADOUT_OVERRIDES: {
            CHARACTER_SKIN: CharacterSkin.None,
            WEAPON_ONE: Item.None,
            WEAPON_TWO: Item.None,
            GEAR_ONE: Item.None,
            GEAR_TWO: Item.None,
            GEAR_THREE: Item.None
        },

        YOU_ARE_WAY_TOO_STRONG: false,

        YOU_ARE_INVINCIBLE: false,

        YOU_ARE_WAY_TOO_SQUISHY: false,

        SPAWN_WITH_ITEMS_IN_INVENTORY: 0,

        // Show real server-side entity for yourself
        DRAW_SERVERSIDE_SELF_DURING_PREDICTION: false,

        // Hiding specific sprites
        HIDE_PLAYER_SPRITE: false,
        HIDE_WEAPON_SPRITE: false,

        // Centerpoint visual
        DRAW_CENTERPOINT: false,

        // Movement collider visuals
        DRAW_MOVEMENT_COLLIDER: false,

        // Hitbox collider visuals
        DRAW_HITBOX_COLLIDER: false,

        // Aim indicator
        DRAW_AIM_INDICATOR: false,
        AIM_INDICATOR_SHOULD_BE_LONG_AF: false,

        // Theoretical projectile origin indicator
        DRAW_PROJECTILE_ORIGIN_INDICATOR: false,

        // Show position text
        DRAW_POSITION_TEXT: false,

        DRAW_TILE_GRID_TEXT: false,

        // Rectangle displaying the player's network view (culling area)
        DRAW_NETWORK_VIEW: false,

        // Give the player an omnipotent world view (don't cull anything)
        FORCE_OMEGA_NETCODE_CULLING_VIEW: false,

        // Players will no longer drop gravestones on death
        DONT_DROP_GRAVESTONES: false,

        // Custom override for player spawn position
        DEBUG_SPAWN_POSITION: {
            ENABLED: false,
            POSITION_X: 3241,
            POSITION_Y: 2603
        }
    }
};

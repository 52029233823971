import React from "react";
import { CharacterSkin, CharacterSkinData } from "../../../shared/data/Data_Cosmetics";
import { Tooltip } from "react-tooltip";
import ReactDOM from "react-dom";

interface CharacterSkinSlotProps {
    skin: CharacterSkin;
    OnClickFn: () => void;
    selected: boolean;
}

export const CharacterSkinSlot = (props: CharacterSkinSlotProps) => {
    const { skin, OnClickFn, selected } = props;

    const tooltipContainer = document.getElementById("tooltip-container") as Element;

    const characterSkinData = CharacterSkinData.get(skin)!;
    const characterSkinSprite = characterSkinData.staticSpriteId;
    const characterSkinName = characterSkinData.friendlyName;

    return (
        <>
            <div className={"character-skin-slot" + ` ${selected === true ? "selected" : ""}`} data-tooltip-id={`character-skin-slot-tooltip-${skin}`} onClick={OnClickFn}>
                <div className="selection-parent">
                    <div className="selection-corner top-left"></div>
                    <div className="selection-corner top-right"></div>
                    <div className="selection-corner bottom-left"></div>
                    <div className="selection-corner bottom-right"></div>
                </div>
                <img draggable="false" src={characterSkinSprite} alt={CharacterSkin[skin]} />
            </div>

            {ReactDOM.createPortal(
                <Tooltip id={`character-skin-slot-tooltip-${skin}`} style={{ fontSize: "32px", padding: "10px 0", margin: 0, textAlign: "center" }}>
                    {characterSkinName}
                </Tooltip>,
                tooltipContainer
            )}
        </>
    );
};

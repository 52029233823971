import React from "react";
import { Screens, UI_ChangeUsername, UI_SwapScreen, changeUsernameInputStateAtom, playerIdentityStateAtom } from "../framework/UI_State";
import { useRecoilState, useRecoilValue } from "recoil";
import { t } from "../../../shared/data/Data_I18N";

export const ChangeUsername = () => {
    const { loadingPlayerIdentity, userDetails } = useRecoilValue(playerIdentityStateAtom);
    const { username: currentUsername } = userDetails;
    const [username, setUsername] = useRecoilState(changeUsernameInputStateAtom);

    const onUsernameInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        // console.log("Username input changed", e.target.value);
        setUsername(e.target.value);
    };

    const errorNodes = [];

    if (username.length === 0) {
        errorNodes.push(<p>{t("change_username__cant_be_empty_error")}</p>);
    } else if (username.length >= 20) {
        errorNodes.push(<p>{t("change_username__too_long_error")}</p>);
    }

    return (
        <>
            <div className="change-username-panel animate__animated animate__fadeInUp animate__faster">
                <div className="change-username-header">{t("change_username__header")}</div>

                <div className="change-username-form">
                    <p className="label">{t("change_username__current_prompt")}</p>
                    <p className="current">{currentUsername}</p>
                    <p className="label">{t("change_username__enter_new")}</p>
                    <input className="change-username-input" type="text" value={username} onChange={onUsernameInputChanged} disabled={loadingPlayerIdentity} placeholder={t("change_username__enter_new")} />
                    {errorNodes.length > 0 && <div className="errors">{errorNodes}</div>}
                </div>

                <div className="change-username-actions">
                    <button
                        className="neutral-button"
                        onClick={() => {
                            UI_SwapScreen(Screens.MainMenu);
                        }}
                    >
                        {t("change_username__back")}
                    </button>

                    <button
                        disabled={loadingPlayerIdentity || errorNodes.length > 0}
                        className="change-username-btn affirmative-button"
                        onClick={() => {
                            UI_ChangeUsername(username);
                        }}
                    >
                        {t("change_username__save")}
                    </button>
                </div>
            </div>
        </>
    );
};

import React from "react";
import { useRecoilValue } from "recoil";
import { spectatingStateAtom } from "../framework/UI_State";
import { reloadClient } from "../../utils/ClientReload";
import { t } from "../../../shared/data/Data_I18N";

export const Spectating = () => {
    const { spectatingPlayerName } = useRecoilValue(spectatingStateAtom);

    return (
        <div className="spectating animate__animated animate__fadeInUp">
            <h1>
                {t("game_hud__spectating")} {spectatingPlayerName}
            </h1>
            <button
                className="neutral-button"
                onClick={() => {
                    reloadClient(false);
                }}
            >
                {t("death_menu__back_to_main_menu_button")}
            </button>
        </div>
    );
};

import React from "react";
import { Screens, UI_SavePreferences, UI_SwapScreen, playerIdentityStateAtom, playerPreferencesStateAtom } from "../framework/UI_State";
import { useRecoilState, useRecoilValue } from "recoil";
import { t } from "../../../shared/data/Data_I18N";

export const Settings = () => {
    const { loadingPlayerIdentity } = useRecoilValue(playerIdentityStateAtom);
    const [preferences, updatePreferences] = useRecoilState(playerPreferencesStateAtom);
    const { savingPreferencesInProgress, local, fromServer } = preferences;
    const { enableMusic, enableSoundEffects } = local;

    const toggleMusic = () => {
        updatePreferences({ ...preferences, local: { ...local, enableMusic: !enableMusic } });
    };

    const toggleSfx = () => {
        updatePreferences({ ...preferences, local: { ...local, enableSoundEffects: !enableSoundEffects } });
    };

    const onLanguageChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
        updatePreferences({ ...preferences, local: { ...local, preferredLanguage: e.target.value } });
    };

    const onMatchmakingRegionChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
        updatePreferences({ ...preferences, local: { ...local, preferredMatchmakingRegion: e.target.value } });
    };

    const stringifiedServerPreferences = JSON.stringify(fromServer);
    const stringifiedLocalPreferences = JSON.stringify(local);

    const saveButtonShouldBeDisabled = stringifiedServerPreferences === stringifiedLocalPreferences || loadingPlayerIdentity || savingPreferencesInProgress;
    const cancelButtonShouldBeDisabled = loadingPlayerIdentity || savingPreferencesInProgress;

    return (
        <>
            <div className="settings-panel animate__animated animate__fadeInUp animate__faster">
                <div className="settings-header">{t("settings__title")}</div>
                <div className="setting-row">
                    <div className="setting-label">{t("settings__enable_music")}</div>
                    <div className="setting-control">
                        <div className="toggle-switch enabled" onClick={toggleMusic}>
                            {enableMusic === true && <div className="toggle-switch-checked"></div>}
                            {enableMusic === false && <div className="toggle-switch-unchecked"></div>}
                        </div>
                    </div>
                </div>
                {/* <div className="setting-row">
                    <div className="setting-label">{t("settings__enable_sfx")}</div>
                    <div className="setting-control">
                        <div className="toggle-switch disabled" onClick={toggleSfx}>
                            {enableSoundEffects === true && <div className="toggle-switch-checked"></div>}
                            {enableSoundEffects === false && <div className="toggle-switch-unchecked"></div>}
                        </div>
                    </div>
                </div> */}
                <div className="setting-row">
                    <div className="setting-label">{t("settings__language")}</div>
                    <div className="setting-control">
                        <select value={local.preferredLanguage} onChange={onLanguageChanged}>
                            <option value="en">{t("settings__language_dropdown_english")}</option>
                            <option value="es">{t("settings__language_dropdown_spanish")}</option>
                            <option value="ru">{t("settings__language_dropdown_russian")}</option>
                            <option value="pt">{t("settings__language_dropdown_portuguese")}</option>
                        </select>
                    </div>
                </div>
                {/* <option value="zh">Chinese (Mandarin)</option>
                            <option value="de">German</option>
                            <option value="ja">Japanese</option>
                            <option value="ko">Korean</option>
                            <option value="hi">Hindi</option>
                            <option value="fr">French</option>
                            <option value="ar">Arabic</option> */}
                <div className="setting-row">
                    <div className="setting-label">{t("settings__matchmaking_region")}</div>
                    <div className="setting-control">
                        <select value={local.preferredMatchmakingRegion} onChange={onMatchmakingRegionChanged}>
                            <option value="any">{t("settings__matchmaking_region_dropdown_any")}</option>
                            <option value="us-east">{t("settings__matchmaking_region_dropdown_na_west")}</option>
                            <option value="us-west">{t("settings__matchmaking_region_dropdown_na_east")}</option>
                            <option value="eu">{t("settings__matchmaking_region_dropdown_eu")}</option>
                            <option value="asia">{t("settings__matchmaking_region_dropdown_asia")}</option>
                            <option value="australia">{t("settings__matchmaking_region_dropdown_australia_oceania")}</option>
                            <option value="south-america">{t("settings__matchmaking_region_dropdown_south_america")}</option>
                        </select>
                    </div>
                </div>

                <div className="settings-actions">
                    <button
                        className="neutral-button"
                        disabled={cancelButtonShouldBeDisabled}
                        onClick={() => {
                            UI_SwapScreen(Screens.MainMenu);
                        }}
                    >
                        {t("settings__back_to_main_menu_button")}
                    </button>

                    <button
                        className="affirmative-button"
                        disabled={saveButtonShouldBeDisabled}
                        onClick={() => {
                            UI_SavePreferences();
                        }}
                    >
                        {t("settings__save_button")}
                    </button>
                </div>
            </div>
        </>
    );
};

import React from "react";
import { useRecoilValue } from "recoil";
import { itemsLootedOnRunStateAtom, extractionSuccessfulStateAtom, youDiedStateAtom, UI_SetIntentionalDisconnect, playerIdentityStateAtom } from "../framework/UI_State";
import { reloadClient } from "../../utils/ClientReload";
import { ItemContainer } from "./UI_ItemContainer";
import { t } from "../../../shared/data/Data_I18N";
import { LoadingSpinner } from "./UI_LoadingSpinner";
import { UserType } from "../../../shared/SharedTypes";
import { Socials } from "./UI_Socials";

export const ExtractionSuccessfulPanel = () => {
    const { userType } = useRecoilValue(playerIdentityStateAtom);
    const { show: playerHasDied } = useRecoilValue(youDiedStateAtom);
    const { show: showExtractionSuccessfulpanel, loading: extractionSuccessulPanelIsLoading, runDuration, goldLooted } = useRecoilValue(extractionSuccessfulStateAtom);
    const { items } = useRecoilValue(itemsLootedOnRunStateAtom);

    // extractionSuccessulPanelIsLoading = true;

    if (showExtractionSuccessfulpanel === false || playerHasDied) {
        return <></>;
    } else {
        return (
            <>
                <div className="extraction-successful-panel animate__animated animate__fadeInUp">
                    <div className="socials-wrapper">
                        <Socials />
                    </div>

                    <h2>{t("extracted_menu__title")}</h2>
                    <div className="stats">
                        <div className="run-metric">
                            <p className="metric-title">{t("extracted_menu__run_duration")}:</p>
                            <p className="metric-value">{runDuration}</p>
                        </div>
                        <div className="run-metric">
                            <p className="metric-title">{t("extracted_menu__gold_looted")}:</p>
                            <p className="metric-value">{goldLooted}</p>
                        </div>
                    </div>
                    <div className="loot-acquired">
                        <h3>~ {t("extracted_menu__loot_acquired")} ~</h3>

                        <ItemContainer items={items} showLeftClickEquipPrompt={false} OnSlotClickFn={() => {}} />
                    </div>

                    <div className="actions">
                        {userType === UserType.Anonymous && <span className="delete-acc-cta">{t("register__delete_account_cta_less_aggressive")}</span>}
                        <button
                            disabled={extractionSuccessulPanelIsLoading}
                            className={`neutral-button ${extractionSuccessulPanelIsLoading ? "loading animate__animated animate__jello animate__infinite" : ""}`}
                            onClick={() => {
                                if (extractionSuccessulPanelIsLoading) return;
                                UI_SetIntentionalDisconnect(true);
                                setTimeout(() => {
                                    reloadClient(false);
                                }, 150);
                            }}
                        >
                            {extractionSuccessulPanelIsLoading ? t("death_menu__saving_your_run_button") : t("death_menu__back_to_main_menu_button")}
                        </button>
                    </div>
                </div>
                {/* <BottomContainer /> */}
            </>
        );
    }
};

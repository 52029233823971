import { AnonymousUser, RegisteredUser, UserType } from "../../shared/SharedTypes";
import { GameplaySystem } from "../../shared/engine/SharedGameplaySystem";
import { createAnonymousUser, getAnonymousUser, getMyUserDetails, promoteAnonymousUser } from "../backend/ClientRequests";
import { AuthExpiredError, UserNotFoundError } from "../backend/ClientAPIErrors";
import { LSKeys, deleteFromLocalStorage, deleteFromOldLocalStorage, loadFromLocalStorage, loadFromOldLocalStorage, localStorageContainsKey, saveToLocalStorage } from "../utils/ClientLocalStorage";
import { reloadClient } from "../utils/ClientReload";
import { Screens, UI_CheckMaintenanceModeStatus, UI_SwapScreen } from "../ui/framework/UI_State";
import { MAJOR_GAME_VERSION } from "../../shared/config/Config_Game";

export class ClientIdentity extends GameplaySystem {
    private identified: boolean = false;
    private userType: UserType;
    private identity: AnonymousUser | RegisteredUser;

    public constructor() {
        super();
    }

    public GetUserType(): UserType {
        return this.userType;
    }

    private _IsFailedExistingOauthAttempt(): boolean {
        return window.location.href.includes("?af=true&e=");
    }

    private _IsExistingLoggedInUser(): boolean {
        const existingAuthToken = loadFromLocalStorage(LSKeys.AuthToken);
        if (existingAuthToken !== null) {
            return true;
        } else {
            return false;
        }
    }

    private _IsExistingAnonymousUser(): boolean {
        const existingAnonUserId = loadFromLocalStorage(LSKeys.AnonymousUserId);
        if (existingAnonUserId !== null) {
            return true;
        } else {
            return false;
        }
    }

    private _IsNewUserRegistration(): boolean {
        return window.location.href.includes("?as=true&t=");
    }

    private _portUserAuthBetweenMajorGameVersionsIfNecessary(): void {
        const currentVersionAsInt = parseInt(MAJOR_GAME_VERSION);

        const previousMajorGameVersion = currentVersionAsInt - 1;

        const existingPreviousAnonUserId = loadFromOldLocalStorage(LSKeys.AnonymousUserId, previousMajorGameVersion);

        if (existingPreviousAnonUserId !== null) {
            saveToLocalStorage(LSKeys.AnonymousUserId, existingPreviousAnonUserId);
            deleteFromOldLocalStorage(LSKeys.AnonymousUserId, previousMajorGameVersion);
            this.LogInfo("New major game version detected, ported anonymous user id from previous version!");
        }

        const existingPreviousAuthToken = loadFromOldLocalStorage(LSKeys.AuthToken, previousMajorGameVersion);

        if (existingPreviousAuthToken !== null) {
            saveToLocalStorage(LSKeys.AuthToken, existingPreviousAuthToken);
            deleteFromOldLocalStorage(LSKeys.AuthToken, previousMajorGameVersion);
            this.LogInfo("New major game version detected, ported user auth tokens from previous version!");
        }
    }

    public override async Initialize(): Promise<void> {
        let identity: RegisteredUser | AnonymousUser | undefined = undefined;
        let userType: UserType | undefined = undefined;

        this._portUserAuthBetweenMajorGameVersionsIfNecessary();

        setTimeout(() => {
            UI_CheckMaintenanceModeStatus();
        }, 50);

        const existingLocalStoragePrefForPromotion = loadFromLocalStorage(LSKeys.PromotionAccepted);
        let acceptedPromotionBoolean = false;

        if (existingLocalStoragePrefForPromotion === null) {
            saveToLocalStorage(LSKeys.PromotionAccepted, "true");
            acceptedPromotionBoolean = true;
        } else {
            acceptedPromotionBoolean = existingLocalStoragePrefForPromotion === "true";
        }

        try {
            if (this._IsFailedExistingOauthAttempt()) {
                // TODO: fix react rendering issue where this event is emitted before the UI is ready to handle it
                setTimeout(() => {
                    // if we failed to log in with oauth, delete the auth token and let the user try again if they want
                    this.LogError("Prior auth attempt was failed for some reason");
                    const { e } = Object.fromEntries(new URLSearchParams(window.location.search));
                    if (e === "incorrect_identity_provider") {
                        Game.EmitEvent("Identity::Error::IncorrectIdentityProvider");
                    } else if (e === "oauth_failed") {
                        Game.EmitEvent("Identity::Error::OAuthFailed");
                    } else if (e === "auth_expired") {
                        this.ClearLocalAuth();
                        Game.EmitEvent("Identity::Error::AuthExpired");
                    } else {
                        this.ClearLocalAuth();
                        Game.EmitEvent("Identity::Error::UnknownIrrecoverableAPIError");
                    }
                }, 50);
                return;
            } else if (this._IsExistingLoggedInUser()) {
                // grab their user details if this is an existing logged in user
                this.LogInfo("Registered user auth token found, retrieving existing user");
                const retrievedUser = await getMyUserDetails();
                identity = retrievedUser;
                userType = UserType.Registered;
            } else if (this._IsNewUserRegistration()) {
                // promote their existing anon user to a registered user if this is a new user registration
                // as denoted by the query params that only get applied when redirected from oauth2 callback
                this.LogInfo("Registered user auth token & anon user id found, promoting existing anonymous user to registered user");
                const { t } = Object.fromEntries(new URLSearchParams(window.location.search).entries());
                if (t === undefined) {
                    throw new Error("Failed to retrieve auth token in new user reg case; cannot proceed with identity");
                }
                saveToLocalStorage(LSKeys.AuthToken, t);
                const anonUserId = loadFromLocalStorage(LSKeys.AnonymousUserId) as string;
                const promotedUser = await promoteAnonymousUser(anonUserId, acceptedPromotionBoolean);
                deleteFromLocalStorage(LSKeys.AnonymousUserId);
                identity = promotedUser;
                userType = UserType.Registered;
                this.LogInfo("Newly created registered user:");
                // this.LogInfo("\n" + JSON.stringify(promotedUser, null, 2));
            } else if (this._IsExistingAnonymousUser()) {
                // load an existing anon user if none of the above apply
                this.LogInfo("Anonymous user found, retrieving info for existing anonymous user");
                const anonUserId = loadFromLocalStorage(LSKeys.AnonymousUserId) as string;
                const retrievedAnonUser = await getAnonymousUser(anonUserId);
                identity = retrievedAnonUser;
                userType = UserType.Anonymous;
                this.LogInfo("Retrieved existing anonymous user!");
                // this.LogInfo("\n" + JSON.stringify(retrievedAnonUser, null, 2));
            } else {
                // lastly, create a new anon user if none of the above apply
                this.LogInfo("No anonymous user found, creating net new anon user");
                const createdAnonUser = await createAnonymousUser();
                saveToLocalStorage(LSKeys.AnonymousUserId, createdAnonUser._id);
                identity = createdAnonUser;
                userType = UserType.Anonymous;
                this.LogInfo("Newly created anonymous user:");
                // this.LogInfo("\n" + JSON.stringify(createdAnonUser, null, 2));
            }
        } catch (e) {
            if (e instanceof AuthExpiredError) {
                this.ClearLocalAuth();
                this.AuthExpiredLogout();
            } else if (e instanceof UserNotFoundError) {
                this.ClearLocalAuth();
                this.AuthExpiredLogout();
            } else {
                console.error("Unknown API Error", e);
            }
        }

        if (identity === undefined || userType === undefined) {
            this.ClearLocalAuth();
            Game.EmitEvent("Identity::Error::UnknownIrrecoverableAPIError");
            return;
        }

        Lang = identity.PVPZ_Preferences.preferredLanguage;
        document.querySelector("body")!.className = "";
        document.querySelector("body")!.classList.add(Lang);

        if (userType === UserType.Anonymous) {
            Game.EmitEvent("Identity::Identified_Anonymous", { identity: identity });
        } else if (userType === UserType.Registered) {
            Game.EmitEvent("Identity::Identified_Registered", { identity: identity });
        }

        this.identity = identity;
        this.userType = userType;
        this.identified = true;

        Game.ListenForEvent("Identity::Logout", () => {
            this.ClearLocalAuth(true);
        });

        this.LogInfo("Ready!\n\nUsername is: " + this.identity.username);

        const shouldSkipToLoadout = window.location.href.includes("sl=true");

        const shouldSkipToPurchaseSuccessfulScreen = window.location.href.includes("ps=true");

        if (window.location.href.includes("skipMenu=true") === false && window.location.href.includes("bypass=4ed2b85f-4266-4569-a2e8-64d37305226e") === false) {
            window.history.replaceState({}, document.title, "/");
        }

        if (shouldSkipToLoadout) {
            UI_SwapScreen(Screens.RunPrep);
        } else if (shouldSkipToPurchaseSuccessfulScreen) {
            UI_SwapScreen(Screens.PurchaseSuccessful);
        }
    }

    public GetUsername(): string {
        return this.identity.username;
    }

    public ClearLocalAuth(reload?: boolean): void {
        deleteFromLocalStorage(LSKeys.AuthToken);
        deleteFromLocalStorage(LSKeys.AnonymousUserId);

        if (reload) {
            reloadClient(false);
        }
    }

    public AuthExpiredLogout(): void {
        reloadClient(false);
    }

    protected override getSystemName(): string {
        return "Identity";
    }

    public override Update(__deltaTime: number): void {}

    public override Cleanup(): void {}
}

import React from "react";
import { ItemSlot, SlotVisualOptions } from "./UI_ItemSlot";
import { ItemContainerMap, Item, getItemData } from "../../../shared/data/Data_Items";

interface ItemContainerProps {
    items: ItemContainerMap;
    showLeftClickEquipPrompt?: boolean;
    showLeftClickLootPrompt?: boolean;
    showYouMustExtractPrompt?: boolean;
    OnSlotClickFn: (itemId: Item) => void;
}

export const ItemContainer = (props: ItemContainerProps) => {
    const { items, showLeftClickEquipPrompt, showLeftClickLootPrompt, showYouMustExtractPrompt, OnSlotClickFn: onSlotClickFn } = props;

    const sortedItems = Object.entries(items)
        .filter(([itemId, __itemQuantity]) => {
            // @ts-ignore
            return itemId !== "-1" && itemId !== -1;
        })
        .sort(([itemID1, __quantity1], [itemID2, __quantity2]) => {
            const itemRarity1 = getItemData(parseInt(itemID1))!.itemRarity;
            const itemRarity2 = getItemData(parseInt(itemID2))!.itemRarity;

            if (itemRarity1 > itemRarity2) return -1;
            if (itemRarity1 < itemRarity2) return 1;
            return 0;
        });

    const inventory = [];

    for (let i = 0; i < sortedItems.length; i++) {
        const [itemId, quantity] = sortedItems[i];

        // @ts-ignore
        if (itemId === -1 || itemId === "-1") continue;

        if (quantity <= 0) continue;
        inventory.push(<ItemSlot OnClickFn={onSlotClickFn} itemId={parseInt(itemId)} quantity={quantity} slotVisualType={SlotVisualOptions.Inventory} key={itemId} showLeftClickEquipPrompt={showLeftClickEquipPrompt ? showLeftClickEquipPrompt : false} showLeftClickLootPrompt={showLeftClickLootPrompt ? showLeftClickLootPrompt : false} showYouMustExtractPrompt={showYouMustExtractPrompt ? showYouMustExtractPrompt : false} />);
    }

    return <div className="item-container">{inventory}</div>;
};

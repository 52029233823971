/* eslint-disable @typescript-eslint/no-explicit-any */
import { GameplaySystem } from "../../shared/engine/SharedGameplaySystem";
import { Assets, extensions } from "pixi.js";
// @ts-ignore
import HowlerLoaderParser from "howler-pixi-loader-middleware";
import { UI_SetLoadingProgress } from "../ui/framework/UI_State";
import { AssetNotFoundException } from "../utils/ClientErrors";
import { clamp } from "../../shared/SharedUtils";

export class ClientLoader extends GameplaySystem {
    public AssetsFullyLoaded: boolean = false;
    private animations: any;
    private spriteSheetTextures: any;

    public constructor() {
        super();

        extensions.add(HowlerLoaderParser);
    }

    public async Initialize(): Promise<void> {
        try {
            Game.Rewards.LoadingStarted();

            await Assets.init({
                manifest: {
                    bundles: [
                        {
                            name: "game-assets",
                            assets: [
                                {
                                    name: "sprites",
                                    srcs: `/sprites/sprites.json?pvpzts=${Date.now()}`
                                },

                                {
                                    name: "background-layer1",
                                    srcs: `/world/pvpz-background1.png?pvpzts=${Date.now()}`
                                },

                                {
                                    name: "background-layer2",
                                    srcs: `/world/pvpz-background2.png?pvpzts=${Date.now()}`
                                },

                                {
                                    name: "middleground-layer",
                                    srcs: `/world/pvpz-middleground.png?pvpzts=${Date.now()}`
                                },

                                {
                                    name: "foreground-layer1",
                                    srcs: `/world/pvpz-foreground1.png?pvpzts=${Date.now()}`
                                },

                                {
                                    name: "foreground-layer2",
                                    srcs: `/world/pvpz-foreground2.png?pvpzts=${Date.now()}`
                                },

                                {
                                    name: "collisions-layer",
                                    srcs: `/world/pvpz-collisions.png?pvpzts=${Date.now()}`
                                }

                                // Uncomment these to load some random large audio assets for testing the loading bar
                                // these files are not in git. Ask sam for them if you want them, or just get your own
                                // very large MP3 files and put them in the audio folder to test
                                // {
                                //     name: 'test-audio-1',
                                //     srcs: 'audio/test1.mp3'
                                // }
                                // {
                                //     name: 'test-audio-2',
                                //     srcs: 'audio/test2.mp3'
                                // },
                                // {
                                //     name: 'test-audio-3',
                                //     srcs: 'audio/test3.mp3'
                                // },
                                // {
                                //     name: 'test-audio-4',
                                //     srcs: 'audio/test4.mp3'
                                // }
                            ]
                        }
                    ]
                }
            });

            Assets.backgroundLoadBundle("game-assets");

            Assets.loadBundle("game-assets", this._onLoadProgress.bind(this)).then(this._onLoadComplete.bind(this));
        } catch (e) {
            console.error("Caught error loading assets!");
            console.log(e);
        }

        this.LogInfo("Ready (ish)!");
    }

    private _onLoadComplete() {
        this.LogInfo("Load complete!");
        this.AssetsFullyLoaded = true;
        Game.Rewards.LoadingComplete();

        const spritesAsset = Assets.get("sprites");

        if (!spritesAsset) {
            this.LogError("Sprites asset not found!");
            throw new AssetNotFoundException("Master sprites JSON asset not found!");
        }

        const spritesAssetData = spritesAsset.data;

        if (!spritesAssetData) {
            this.LogError("Incorrectly configured sprites JSON!");
            throw new AssetNotFoundException("Master sprites JSON asset is malformed!");
        }

        const animations = spritesAssetData.animations;

        if (!animations) {
            this.LogError("No animations found in sprites JSON!");
            throw new AssetNotFoundException("No animations found in sprites JSON!");
        }

        const textures = spritesAsset.textures;

        if (!textures) {
            this.LogError("No textures found in sprites JSON!");
            throw new AssetNotFoundException("No textures found in sprites JSON!");
        }

        this.animations = animations;
        this.spriteSheetTextures = textures;

        Game.EmitEvent("Assets::Loaded");
    }

    public GetAnimation(name: string) {
        const foundAnimation = this.animations[name];

        if (!foundAnimation) {
            this.LogError(`Animation ${name} not found!`);
            throw new AssetNotFoundException(`Unable to find animation with name: ${name} in the loaded assets.`);
        }
        return foundAnimation;
    }

    public GetSpritesheetTexture(name: string) {
        const foundTexture = this.spriteSheetTextures[name];

        // console.log(this.spriteSheetTextures);

        if (!foundTexture) {
            this.LogError(`Texture ${name} not found!`);
            throw new AssetNotFoundException(`Unable to find texture with name: ${name} in the loaded assets.`);
        }

        return foundTexture;
    }

    private _onLoadProgress(progress: number) {
        const progressAsPercentage = clamp(Math.ceil(progress * 100), 0, 100);
        this.LogInfo(`Load progress %: ${progressAsPercentage}`);
        UI_SetLoadingProgress(progressAsPercentage);
    }

    protected override getSystemName(): string {
        return "Loader";
    }

    public Update(__deltaTime: number): void {}

    public Cleanup(): void {}
}

import { DamageType, Item } from "./Data_Items";
import { HitscanProjectileConfig, EntityProjectileConfig, getProjectileData, Projectile } from "./Data_Projectiles";

//#region Weapon & Projectile System Types
/****************************************************/
/*                                                  */
/*                       Types                      */
/*                                                  */
/****************************************************/

export enum WeaponFiringType {
    Hitscan = 0,
    Projectile = 1
}

export enum WeaponClass {
    Staff = 0,
    Crossbow = 1,
    Bow = 2,
    Wand = 3,
    Spellbook = 4
}

interface BaseWeaponData {
    id: Item;
    weaponClass: WeaponClass;
    firingType: WeaponFiringType;
    inGameSpriteScale: number;
    inGameSpriteId: string;
    damage: number;
    damageType: DamageType;
    cooldownInSeconds: number;
    randomizeProjectileAimAngle: boolean;
    playerMovementMultiplierDurationInSeconds: number;
    playerMovementMultiplier: number;
}
interface HitscanWeaponConfig extends BaseWeaponData {
    firingType: WeaponFiringType.Hitscan;
    projectileConfig: HitscanProjectileConfig;
}

interface ProjectileWeaponConfig extends BaseWeaponData {
    firingType: WeaponFiringType.Projectile;
    projectileConfig: EntityProjectileConfig;
}

export type WeaponConfig = HitscanWeaponConfig | ProjectileWeaponConfig;
//#endregion

//#region Weapon Configs
/****************************************************/
/*                                                  */
/*                   Weapons                        */
/*                                                  */
/****************************************************/

export const WeaponData: Map<Item, WeaponConfig> = new Map();

const setWeaponData = (weaponConfig: WeaponConfig) => {
    const existingEntryWithId = WeaponData.get(weaponConfig.id);

    if (existingEntryWithId) {
        throw new Error(`WeaponConfig with id ${weaponConfig.id} already exists! Error in weapon data file.`);
    }

    WeaponData.set(weaponConfig.id, weaponConfig);
};

export const getWeaponData = (id: Item): WeaponConfig => {
    const weaponConfig = WeaponData.get(id);

    if (!weaponConfig) {
        throw new Error(`WeaponConfig with id ${id} does not exist! Error in weapon data file.`);
    }

    return weaponConfig;
};

/*************************/
/*      Staves           */
/*************************/
setWeaponData({
    id: Item.W_ApprenticesStaff,
    inGameSpriteId: "weapons/Apprentices_Staff.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 20,
    damageType: DamageType.Physical,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 2.8,
    playerMovementMultiplier: 1.1,
    playerMovementMultiplierDurationInSeconds: 0.5,
    projectileConfig: getProjectileData(Projectile.IdleBlue),
    weaponClass: WeaponClass.Staff
});

setWeaponData({
    id: Item.W_FlamespitterStaff,
    inGameSpriteId: "weapons/Flamespitter_Staff.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 23,
    damageType: DamageType.Fire,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 2.8,
    playerMovementMultiplier: 1.15,
    playerMovementMultiplierDurationInSeconds: 0.5,
    projectileConfig: getProjectileData(Projectile.IdleFlameBreath),
    weaponClass: WeaponClass.Staff
});

setWeaponData({
    id: Item.W_StaffOfTheSnake,
    inGameSpriteId: "weapons/staff_staffOfTheSnake.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 24,
    damageType: DamageType.Toxic,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 2.9,
    playerMovementMultiplier: 1.15,
    playerMovementMultiplierDurationInSeconds: 0.5,
    projectileConfig: getProjectileData(Projectile.IdleToxicClouds),
    weaponClass: WeaponClass.Staff
});

setWeaponData({
    id: Item.W_ArchmagesSidekick,
    inGameSpriteId: "weapons/Arcane_Staff.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 21,
    damageType: DamageType.Arcane,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 2.9,
    playerMovementMultiplier: 1.1,
    playerMovementMultiplierDurationInSeconds: 0.5,
    projectileConfig: getProjectileData(Projectile.IdlePink),
    weaponClass: WeaponClass.Staff
});

setWeaponData({
    id: Item.W_SamsStirringStick,
    inGameSpriteId: "weapons/staff_samsStirringStick.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 25,
    damageType: DamageType.Arcane,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 3,
    playerMovementMultiplier: 1.15,
    playerMovementMultiplierDurationInSeconds: 0.5,
    projectileConfig: getProjectileData(Projectile.IdleBlue),
    weaponClass: WeaponClass.Staff
});

setWeaponData({
    id: Item.W_CandlelightStaff,
    inGameSpriteId: "weapons/staff_candleLight.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 22,
    damageType: DamageType.Fire,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 3,
    playerMovementMultiplier: 1.1,
    playerMovementMultiplierDurationInSeconds: 0.5,
    projectileConfig: getProjectileData(Projectile.IdlePink),
    weaponClass: WeaponClass.Staff
});

setWeaponData({
    id: Item.W_TwistedBranch,
    inGameSpriteId: "weapons/staff_twistedBranch.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 19,
    damageType: DamageType.Lightning,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 2.6,
    playerMovementMultiplier: 1.1,
    playerMovementMultiplierDurationInSeconds: 0.5,
    projectileConfig: getProjectileData(Projectile.IdleBlue),
    weaponClass: WeaponClass.Staff
});

setWeaponData({
    id: Item.W_IronStaff,
    inGameSpriteId: "weapons/staff_ironStaff.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 18,
    damageType: DamageType.Physical,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 2.5,
    playerMovementMultiplier: 1.1,
    playerMovementMultiplierDurationInSeconds: 0.5,
    projectileConfig: getProjectileData(Projectile.IdleBlue),
    weaponClass: WeaponClass.Staff
});

setWeaponData({
    id: Item.W_PharaohsStaff,
    inGameSpriteId: "weapons/staff_pharoahsStaff.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 35,
    damageType: DamageType.Holy,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 3,
    playerMovementMultiplier: 1.25,
    playerMovementMultiplierDurationInSeconds: 0.5,
    projectileConfig: getProjectileData(Projectile.IdleHolySpark),
    weaponClass: WeaponClass.Staff
});

setWeaponData({
    id: Item.W_FairyStaff,
    inGameSpriteId: "weapons/staff_fairyStaff.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 34,
    damageType: DamageType.Nature,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 2.9,
    playerMovementMultiplier: 1.25,
    playerMovementMultiplierDurationInSeconds: 0.5,
    projectileConfig: getProjectileData(Projectile.IdleMagicFairy),
    weaponClass: WeaponClass.Staff
});

setWeaponData({
    id: Item.W_MidasStaff,
    inGameSpriteId: "weapons/staff_midasStaff.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 40,
    damageType: DamageType.Lightning,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 3,
    playerMovementMultiplier: 1.3,
    playerMovementMultiplierDurationInSeconds: 0.5,
    projectileConfig: getProjectileData(Projectile.IdleMidasFist),
    weaponClass: WeaponClass.Staff
});

setWeaponData({
    id: Item.W_DemonsEye,
    inGameSpriteId: "weapons/staff_demonsEye.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 30,
    damageType: DamageType.Shadow,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 3,
    playerMovementMultiplier: 1.2,
    playerMovementMultiplierDurationInSeconds: 0.5,
    projectileConfig: getProjectileData(Projectile.IdleEyeBall),
    weaponClass: WeaponClass.Staff
});

setWeaponData({
    id: Item.W_SlimeStaff,
    inGameSpriteId: "weapons/staff_slimeStaff.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 26,
    damageType: DamageType.Nature,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 3.1,
    playerMovementMultiplier: 1.15,
    playerMovementMultiplierDurationInSeconds: 0.5,
    projectileConfig: getProjectileData(Projectile.IdleBlue),
    weaponClass: WeaponClass.Staff
});

setWeaponData({
    id: Item.W_BeesOnAStick,
    inGameSpriteId: "weapons/staff_beeStick.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 38,
    damageType: DamageType.Nature,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 2.9,
    playerMovementMultiplier: 1.3,
    playerMovementMultiplierDurationInSeconds: 0.5,
    projectileConfig: getProjectileData(Projectile.IdleBees),
    weaponClass: WeaponClass.Staff
});

setWeaponData({
    id: Item.W_LichsStaff,
    inGameSpriteId: "weapons/staff_lichStaff.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 27,
    damageType: DamageType.Shadow,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 3.2,
    playerMovementMultiplier: 1.15,
    playerMovementMultiplierDurationInSeconds: 0.5,
    projectileConfig: getProjectileData(Projectile.IdleLichSkull),
    weaponClass: WeaponClass.Staff
});

setWeaponData({
    id: Item.W_FrostFlicker,
    inGameSpriteId: "weapons/staff_frostFlicker.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 36,
    damageType: DamageType.Frost,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 3.1,
    playerMovementMultiplier: 1.25,
    playerMovementMultiplierDurationInSeconds: 0.5,
    projectileConfig: getProjectileData(Projectile.IdleBlue),
    weaponClass: WeaponClass.Staff
});

/*************************/
/*     Crossbows         */
/*************************/
setWeaponData({
    id: Item.W_CrudeCrossbow,
    inGameSpriteId: "weapons/Crossbow_Wood.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 10,
    damageType: DamageType.Physical,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 1.5,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.BoltNormal),
    weaponClass: WeaponClass.Crossbow
});

setWeaponData({
    id: Item.W_GuardsCrossbow,
    inGameSpriteId: "weapons/crossbow_guards.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 6.5,
    damageType: DamageType.Physical,
    randomizeProjectileAimAngle: true,
    cooldownInSeconds: 1,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.BoltNormal),
    weaponClass: WeaponClass.Crossbow
});

setWeaponData({
    id: Item.W_SteelCrossbow,
    inGameSpriteId: "weapons/Crossbow_Steel.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 9,
    damageType: DamageType.Bleed,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 1.3,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.BoltNormal),
    weaponClass: WeaponClass.Crossbow
});

setWeaponData({
    id: Item.W_ReliqueryCrossbow,
    inGameSpriteId: "weapons/Crossbow_Black.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 11,
    damageType: DamageType.Chaos,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 1.6,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.BoltNormal),
    weaponClass: WeaponClass.Crossbow
});

setWeaponData({
    id: Item.W_EldritchBlasters,
    inGameSpriteId: "weapons/crossbow_eldritchBlasters.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 4.5,
    damageType: DamageType.Shadow,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 0.54,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.EldritchBolt),
    weaponClass: WeaponClass.Crossbow
});

setWeaponData({
    id: Item.W_DarylsInstinct,
    inGameSpriteId: "weapons/crossbow_darylsInstinct.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 11,
    damageType: DamageType.Bleed,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 1.16,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.DarylsBolt),
    weaponClass: WeaponClass.Crossbow
});

setWeaponData({
    id: Item.W_FathersBane,
    inGameSpriteId: "weapons/crossbow_fathersBane.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 12.5,
    damageType: DamageType.Toxic,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 1.32,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.GoldBolt),
    weaponClass: WeaponClass.Crossbow
});

setWeaponData({
    id: Item.W_WitchHuntersRepeaters,
    inGameSpriteId: "weapons/crossbow_witchHuntersRepeater.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 7.5,
    damageType: DamageType.Holy,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 0.9,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.BoltSilver),
    weaponClass: WeaponClass.Crossbow
});

setWeaponData({
    id: Item.W_WyvernsTalon,
    inGameSpriteId: "weapons/crossbow_wyvernTalon.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 9.25,
    damageType: DamageType.Bleed,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 0.75,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.ShortBolt),
    weaponClass: WeaponClass.Crossbow
});

setWeaponData({
    id: Item.W_TheCrossBow,
    inGameSpriteId: "weapons/crossbow_theCrossBow.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 8.5,
    damageType: DamageType.Holy,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 0.65,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.GoldBolt),
    weaponClass: WeaponClass.Crossbow
});

/*************************/
/*     Bows              */
/*************************/

setWeaponData({
    id: Item.W_RangersGuillotine,
    inGameSpriteId: "weapons/bow_rangersGuillotine.png",
    inGameSpriteScale: 0.75,
    firingType: WeaponFiringType.Projectile,
    damage: 17,
    damageType: DamageType.Toxic,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 1.22,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.GuillotineArrow),
    weaponClass: WeaponClass.Bow
});

setWeaponData({
    id: Item.W_DavidBowie,
    inGameSpriteId: "weapons/bow_woodenShortbow.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 3.7,
    damageType: DamageType.Physical,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 0.55,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.ArrowNormal),
    weaponClass: WeaponClass.Bow
});

setWeaponData({
    id: Item.W_Emberstrike,
    inGameSpriteId: "weapons/bow_emberStrike.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 4.4,
    damageType: DamageType.Fire,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 0.6,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.FireArrow),
    weaponClass: WeaponClass.Bow
});

setWeaponData({
    id: Item.W_MoonShadow,
    inGameSpriteId: "weapons/bow_moonShadow.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 6.5,
    damageType: DamageType.Shadow,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 0.75,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.MoonArrow),
    weaponClass: WeaponClass.Bow
});

setWeaponData({
    id: Item.W_FairyBow,
    inGameSpriteId: "weapons/bow_fairyBow.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 7,
    damageType: DamageType.Nature,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 0.9,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.FairyLightArrow),
    weaponClass: WeaponClass.Bow
});

setWeaponData({
    id: Item.W_Bloodrider,
    inGameSpriteId: "weapons/bow_bloodRider.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 9,
    damageType: DamageType.Bleed,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 1,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.BloodArrow),
    weaponClass: WeaponClass.Bow
});

setWeaponData({
    id: Item.W_PhoenixWing,
    inGameSpriteId: "weapons/bow_phoenixWing.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 10,
    damageType: DamageType.Fire,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 1.1,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.FireArrow),
    weaponClass: WeaponClass.Bow
});

setWeaponData({
    id: Item.W_DivineWind,
    inGameSpriteId: "weapons/bow_divineWind.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 18,
    damageType: DamageType.Holy,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 1.3,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.GoldenArrow),
    weaponClass: WeaponClass.Bow
});

setWeaponData({
    id: Item.W_StarShot,
    inGameSpriteId: "weapons/bow_starShot.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 12,
    damageType: DamageType.Arcane,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 1.5,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.StarArrow),
    weaponClass: WeaponClass.Bow
});

setWeaponData({
    id: Item.W_CelestialBow,
    inGameSpriteId: "weapons/bow_celestialBow.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 14.5,
    damageType: DamageType.Frost,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 1.18,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.CelestialArrow),
    weaponClass: WeaponClass.Bow
});

setWeaponData({
    id: Item.W_ReapersEmbrace,
    inGameSpriteId: "weapons/bow_reapersEmbrace.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 16,
    damageType: DamageType.Chaos,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 1.64,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.ReaperArrow),
    weaponClass: WeaponClass.Bow
});

setWeaponData({
    id: Item.W_QuincyBow,
    inGameSpriteId: "weapons/bow_quincyBow.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 10,
    damageType: DamageType.Lightning,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 1,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.QuincyArrow),
    weaponClass: WeaponClass.Bow
});

setWeaponData({
    id: Item.W_SilentShadow,
    inGameSpriteId: "weapons/bow_silentShadow.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 16,
    damageType: DamageType.Shadow,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 1.2,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.DarkArrow),
    weaponClass: WeaponClass.Bow
});

/**************************/
/*     Wands              */
/**************************/

setWeaponData({
    id: Item.W_Stick,
    inGameSpriteId: "weapons/wand_stick.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 2.6,
    damageType: DamageType.Lightning,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 0.4,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.WandSpark),
    weaponClass: WeaponClass.Wand
});

setWeaponData({
    id: Item.W_SpiraledWand,
    inGameSpriteId: "weapons/wand_spiraleWand.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 5.5,
    damageType: DamageType.Physical,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 0.9,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.TwistedSpark),
    weaponClass: WeaponClass.Wand
});

setWeaponData({
    id: Item.W_NightStick,
    inGameSpriteId: "weapons/wand_nightStick.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 8.4,
    damageType: DamageType.Frost,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 1.3,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.IceSpark),
    weaponClass: WeaponClass.Wand
});

setWeaponData({
    id: Item.W_DragonsBreath,
    inGameSpriteId: "weapons/wand_dragonsBreath.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 3.3,
    damageType: DamageType.Fire,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 0.3,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.FlameBreath),
    weaponClass: WeaponClass.Wand
});

setWeaponData({
    id: Item.W_PoseidonsWand,
    inGameSpriteId: "weapons/wand_poissidonsWandn.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 7,
    damageType: DamageType.Nature,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 0.76,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.Poisson),
    weaponClass: WeaponClass.Wand
});

setWeaponData({
    id: Item.W_BrokenDeathstick,
    inGameSpriteId: "weapons/wand_brokenDeathstick.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 6,
    damageType: DamageType.Chaos,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 0.8,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.ArcaneSpark),
    weaponClass: WeaponClass.Wand
});

setWeaponData({
    id: Item.W_HibidyKibity,
    inGameSpriteId: "weapons/wand_hibidyKibity.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 11.5,
    damageType: DamageType.Chaos,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 0.67,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.HibitySpark),
    weaponClass: WeaponClass.Wand
});

setWeaponData({
    id: Item.W_MagicalWand,
    inGameSpriteId: "weapons/wand_magicalWand.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 7.5,
    damageType: DamageType.Arcane,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 0.5,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.MagicalSpark),
    weaponClass: WeaponClass.Wand
});

setWeaponData({
    id: Item.W_BlackDahliaWand,
    inGameSpriteId: "weapons/wand_blackDahlia.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 9,
    damageType: DamageType.Shadow,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 1.4,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.WandSpark),
    weaponClass: WeaponClass.Wand
});

setWeaponData({
    id: Item.W_FrostedTips,
    inGameSpriteId: "weapons/wand_frostedTips.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 5.5,
    damageType: DamageType.Frost,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 0.7,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.IceSpark),
    weaponClass: WeaponClass.Wand
});

setWeaponData({
    id: Item.W_RelicWand,
    inGameSpriteId: "weapons/wand_relicWand.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 10,
    damageType: DamageType.Physical,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 1.1,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.RedCrystal),
    weaponClass: WeaponClass.Wand
});

setWeaponData({
    id: Item.W_ZappingWand,
    inGameSpriteId: "weapons/wand_zappingWand.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 10,
    damageType: DamageType.Lightning,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 1.2,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.IceSpark),
    weaponClass: WeaponClass.Wand
});

setWeaponData({
    id: Item.W_IcewindWand,
    inGameSpriteId: "weapons/wand_icewindWand.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 1.9,
    damageType: DamageType.Frost,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 0.28,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.FrostWind),
    weaponClass: WeaponClass.Wand
});

setWeaponData({
    id: Item.W_BlazeRod,
    inGameSpriteId: "weapons/wand_blazeRod.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 6.9,
    damageType: DamageType.Fire,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 1,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.Blaze),
    weaponClass: WeaponClass.Wand
});

setWeaponData({
    id: Item.W_ToxicWand,
    inGameSpriteId: "weapons/wand_toxicWand.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 4,
    damageType: DamageType.Toxic,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 0.58,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.BasicGreen),
    weaponClass: WeaponClass.Wand
});

setWeaponData({
    id: Item.W_StarWand,
    inGameSpriteId: "weapons/wand_starWand.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 7.1,
    damageType: DamageType.Arcane,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 0.9,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.MagicStar),
    weaponClass: WeaponClass.Wand
});

/*******************************/
/*     Spellbooks              */
/*******************************/

setWeaponData({
    id: Item.W_SpellbindersTome,
    inGameSpriteId: "weapons/spell_spellbindersTome.png",
    inGameSpriteScale: 0.8,
    firingType: WeaponFiringType.Projectile,
    damage: 15,
    damageType: DamageType.Arcane,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 5,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.SpellBinder),
    weaponClass: WeaponClass.Spellbook
});

setWeaponData({
    id: Item.W_Triforce,
    inGameSpriteId: "weapons/spell_triForce.png",
    inGameSpriteScale: 0.6,
    firingType: WeaponFiringType.Projectile,
    damage: 25,
    damageType: DamageType.Fire,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 6.5,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.TriForce),
    weaponClass: WeaponClass.Spellbook
});

setWeaponData({
    id: Item.W_WizardsLedger,
    inGameSpriteId: "weapons/spell_wizardsLedger.png",
    inGameSpriteScale: 1,
    firingType: WeaponFiringType.Projectile,
    damage: 1,
    damageType: DamageType.Physical,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 0.136,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.Coin),
    weaponClass: WeaponClass.Spellbook
});

setWeaponData({
    id: Item.W_NaturesReturn,
    inGameSpriteId: "weapons/spell_naturesReturn.png",
    inGameSpriteScale: 0.6,
    firingType: WeaponFiringType.Projectile,
    damage: 40,
    damageType: DamageType.Nature,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 8,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.MagicLeaf),
    weaponClass: WeaponClass.Spellbook
});

setWeaponData({
    id: Item.W_KingBible,
    inGameSpriteId: "weapons/spell_kingBible.png",
    inGameSpriteScale: 0.5,
    firingType: WeaponFiringType.Projectile,
    damage: 27,
    damageType: DamageType.Holy,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 7,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.KingBible),
    weaponClass: WeaponClass.Spellbook
});

setWeaponData({
    id: Item.W_BookOfTongues,
    inGameSpriteId: "weapons/spell_bookOfTongues.png",
    inGameSpriteScale: 0.5,
    firingType: WeaponFiringType.Projectile,
    damage: 35,
    damageType: DamageType.Fire,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 7.5,
    playerMovementMultiplier: 1,
    playerMovementMultiplierDurationInSeconds: 1,
    projectileConfig: getProjectileData(Projectile.BookOfTongues),
    weaponClass: WeaponClass.Spellbook
});

setWeaponData({
    id: Item.W_Deathstick,
    inGameSpriteId: "weapons/wand_Deathstick.png",
    inGameSpriteScale: 0.75,
    firingType: WeaponFiringType.Projectile,
    damage: 2,
    damageType: DamageType.Physical,
    randomizeProjectileAimAngle: false,
    cooldownInSeconds: 0.12,
    playerMovementMultiplier: 0.55,
    playerMovementMultiplierDurationInSeconds: .75,
    projectileConfig: getProjectileData(Projectile.Deathstick),
    weaponClass: WeaponClass.Wand
});

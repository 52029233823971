import { ProjectileEntityCreationPayload } from "../../shared/SharedNetcodeSchemas";
import { Graphics, AnimatedSprite, Container } from "pixi.js";
import { projectileConfig } from "../../shared/config/Config_Projectile";
import { SharedProjectile } from "../../shared/entities/SharedProjectile";
import { IRenderedEntity } from "../ClientTypes";
import { playerConfig } from "../../shared/config/Config_Player";
import { EntityProjectileConfig, getProjectileData } from "../../shared/data/Data_Projectiles";

export class ClientProjectile extends SharedProjectile implements IRenderedEntity {
    protected debugProjectileCollider: Graphics;

    public RenderedEntity: Container = new Container();

    private projectileData: EntityProjectileConfig;
    private projectileSpriteId: string;
    private projectileSpriteScale: number;
    private colliderRadiusForDebugVisuals: number;
    private spriteXAnchor: number;

    public isMyProjectile: boolean;
    public xVel: number = 0;
    public yVel: number = 0;
    private projectileSprite: AnimatedSprite;
    private animationSpeed: number = 1 / 5;

    public constructor(createProjectilePayload: ProjectileEntityCreationPayload, isOwnedByMyPlayer: boolean, distanceMultiplier: number, playerDamageModifier: number, npcDamageModifier: number) {
        const { nid, x, y, spawnTrajectoryInRads, damage, damageType, dataId, owningEntityNid } = createProjectilePayload;

        const projectileData = getProjectileData(dataId);

        super(Game.Collision, owningEntityNid, x, y, spawnTrajectoryInRads, damage, damageType, projectileData, distanceMultiplier, playerDamageModifier, npcDamageModifier);

        this.projectileData = projectileData;

        const { projectileSpriteId, projectileSpriteScale, colliderRadius, spriteXAnchor } = this.projectileData;

        this.spriteXAnchor = spriteXAnchor;
        this.projectileSpriteId = projectileSpriteId;
        this.projectileSpriteScale = projectileSpriteScale;
        this.colliderRadiusForDebugVisuals = colliderRadius;

        this.nid = nid;

        this.RenderedEntity.x = x;
        this.RenderedEntity.y = y;
        this.RenderedEntity.zIndex = y;

        this.isMyProjectile = isOwnedByMyPlayer;

        this.RenderedEntity.on("destroyed", () => {
            if (this.projectileData.hasImpactPfx && this.RenderedEntity.alpha !== 0) {
                Game.Renderer.PlayOneOffPfx(this.x, this.y, this.projectileData.impactSpriteId, this.projectileData.impactSpriteScale, spawnTrajectoryInRads);
            }
        });

        this._setupProjectileVisuals();
        this._setupDebugVisuals();

        if (playerConfig.ENABLE_PREDICTION && this.isMyProjectile) {
            if (projectileConfig.DEBUG.DRAW_SERVERSIDE_PROJECTILES_DURING_PREDICTION) {
                this.RenderedEntity.alpha = 0.5;
            } else {
                this.RenderedEntity.alpha = 0;
            }
        } else {
            this.RenderedEntity.alpha = 1;
        }
    }

    private _setupDebugVisuals() {
        if (projectileConfig.DEBUG.DRAW_COLLIDER) {
            this.debugProjectileCollider = new Graphics();
            this.debugProjectileCollider.lineStyle(1, 0x000000, 0.15);
            this.debugProjectileCollider.drawCircle(0, 0, this.colliderRadiusForDebugVisuals);
            this.debugProjectileCollider.endFill();
            this.RenderedEntity.addChild(this.debugProjectileCollider);
        }
    }

    private _setupProjectileVisuals() {
        this.projectileSprite = AnimatedSprite.fromFrames(Game.Loader.GetAnimation(this.projectileSpriteId));
        this.projectileSprite.scale.set(this.projectileSpriteScale);
        this.projectileSprite.anchor.set(this.spriteXAnchor, 0.5);
        if (this.projectileData.rotateProjectileSprite) {
            this.projectileSprite.rotation = this.spawnTrajectoryInRads;
        }
        this.projectileSprite.animationSpeed = this.animationSpeed;
        this.projectileSprite.play();
        this.RenderedEntity.addChild(this.projectileSprite);
    }

    protected _updateProjectileVisuals(): void {
        this.RenderedEntity.x = this.x;
        this.RenderedEntity.y = this.y;
    }

    protected _updateDebugVisuals() {}

    public override Update(deltaTime: number): void {
        super.Update(deltaTime);
        this._updateProjectileVisuals();
        this._updateDebugVisuals();
    }
}

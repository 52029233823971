import React from "react";
import { useRecoilValue } from "recoil";
import { playerLoadoutStateAtom, itemsLootedOnRunStateAtom, youDiedStateAtom, extractionSuccessfulStateAtom, UI_SetIntentionalDisconnect, playerIdentityStateAtom } from "../framework/UI_State";
import { reloadClient } from "../../utils/ClientReload";
// @ts-ignore
import skullImageSource from "../kit/skull.png";
import { EmptyItemSlot } from "./UI_EmptyItemSlot";
import { LoadoutSlots } from "../../../shared/systems/SharedGearAndWeaponSystem";
import { ItemSlot, SlotVisualOptions } from "./UI_ItemSlot";
import { ItemContainer } from "./UI_ItemContainer";

// @ts-ignore
import dividerImageSrc from "../kit/divider.png";
import { t } from "../../../shared/data/Data_I18N";
import { UserType } from "../../../shared/SharedTypes";
import { Socials } from "./UI_Socials";

export const YouDiedPanel = () => {
    const { userType } = useRecoilValue(playerIdentityStateAtom);
    const { show: showYouDiedPanel, loading: youDiedPanelLoading } = useRecoilValue(youDiedStateAtom);
    const { show: playerHasExtracted } = useRecoilValue(extractionSuccessfulStateAtom);
    const { items } = useRecoilValue(itemsLootedOnRunStateAtom);
    const { loadout } = useRecoilValue(playerLoadoutStateAtom);
    const { killerName, runDuration, goldLost } = useRecoilValue(youDiedStateAtom);

    // youDiedPanelLoading = true;

    // console.warn('Items looted on run in YouDied Panel:', items);

    const weaponSlotOne = loadout.insuredSlot === LoadoutSlots.WeaponOne ? <></> : loadout.weaponOne === -1 ? <EmptyItemSlot slotVisualType={SlotVisualOptions.Weapon} slotSize="large" selected={false} showInsuredLock={false} disableHover={true} /> : <ItemSlot OnClickFn={() => {}} itemId={loadout.weaponOne} slotVisualType={SlotVisualOptions.Weapon} slotSize="large" selected={false} showLeftClickEquipPrompt={false} showInsuredLock={false} disableHover={true} />;

    const weaponSlotTwo = loadout.insuredSlot === LoadoutSlots.WeaponTwo ? <></> : loadout.weaponTwo === -1 ? <EmptyItemSlot slotVisualType={SlotVisualOptions.Weapon} slotSize="large" selected={false} showInsuredLock={false} disableHover={true} /> : <ItemSlot OnClickFn={() => {}} itemId={loadout.weaponTwo} slotVisualType={SlotVisualOptions.Weapon} slotSize="large" selected={false} showLeftClickEquipPrompt={false} showInsuredLock={false} disableHover={true} />;

    const gearSlotOne = loadout.insuredSlot === LoadoutSlots.GearOne ? <></> : loadout.gearOne === -1 ? <EmptyItemSlot slotVisualType={SlotVisualOptions.Gear} slotSize="large" selected={false} showInsuredLock={false} disableHover={true} /> : <ItemSlot OnClickFn={() => {}} itemId={loadout.gearOne} slotVisualType={SlotVisualOptions.Gear} slotSize="large" selected={false} showLeftClickEquipPrompt={false} showInsuredLock={false} disableHover={true} />;

    const gearSlotTwo = loadout.insuredSlot === LoadoutSlots.GearTwo ? <></> : loadout.gearTwo === -1 ? <EmptyItemSlot slotVisualType={SlotVisualOptions.Gear} slotSize="large" selected={false} showInsuredLock={false} disableHover={true} /> : <ItemSlot OnClickFn={() => {}} itemId={loadout.gearTwo} slotVisualType={SlotVisualOptions.Gear} slotSize="large" selected={false} showLeftClickEquipPrompt={false} showInsuredLock={false} disableHover={true} />;

    const gearSlotThree = loadout.insuredSlot === LoadoutSlots.GearThree ? <></> : loadout.gearThree === -1 ? <EmptyItemSlot slotVisualType={SlotVisualOptions.Gear} slotSize="large" selected={false} showInsuredLock={false} disableHover={true} /> : <ItemSlot OnClickFn={() => {}} itemId={loadout.gearThree} slotVisualType={SlotVisualOptions.Gear} slotSize="large" selected={false} showLeftClickEquipPrompt={false} showInsuredLock={false} disableHover={true} />;

    const lowercaseFirstLetter = (str: string) => {
        return str.charAt(0).toLowerCase() + str.slice(1);
    };

    // TODO: hacky way to get the insured item in the loadout
    // @ts-ignore
    const insuredItemInLoadout = loadout[lowercaseFirstLetter(LoadoutSlots[loadout.insuredSlot])];

    let insuredItemSlot;
    if (insuredItemInLoadout) {
        insuredItemSlot = <ItemSlot OnClickFn={() => {}} itemId={insuredItemInLoadout} slotVisualType={SlotVisualOptions.Gear} slotSize="large" selected={false} showLeftClickEquipPrompt={false} showInsuredLock={false} disableHover={true} />;
    } else {
        insuredItemSlot = <p className="nothing-insured">{t("death_menu__nothing_insured")}</p>;
    }

    if (showYouDiedPanel === false || playerHasExtracted) {
        return <></>;
    } else {
        return (
            <>
                <div className="you-died-panel animate__animated animate__fadeInUpBig">
                    <div className="socials-wrapper">
                        <Socials />
                    </div>

                    <h2>
                        <img src={skullImageSource} alt="skull" className="left-skull" />
                        {t("death_menu__title")}
                        <img src={skullImageSource} alt="skull" className="right-skull" />
                    </h2>
                    <div className="middle-segment">
                        <div className="left-stats">
                            <div className="run-metric">
                                <p className="metric-title">{t("death_menu__your_killer")}</p>
                                <p className="metric-value">{killerName}</p>
                            </div>
                            <div className="run-metric">
                                <p className="metric-title">{t("death_menu__survived_for")}</p>
                                <p className="metric-value">{runDuration}</p>
                            </div>
                            <div className="run-metric">
                                <p className="metric-title">{t("death_menu__gold_lost")}</p>
                                <p className="metric-value">{goldLost}</p>
                            </div>
                        </div>
                        <img draggable="false" className="divider" src={dividerImageSrc} alt="Divider" />
                        <div className="right-items-lost">
                            <div className="youdied-loadout">
                                <p className="lost-title">~ {t("death_menu__weapons_lost")} ~</p>
                                <div className="weapons">
                                    {weaponSlotOne}

                                    {weaponSlotTwo}
                                </div>

                                <p className="lost-title">~ {t("death_menu__gear_lost")} ~</p>
                                <div className="gear">
                                    {gearSlotOne}
                                    {gearSlotTwo}
                                    {gearSlotThree}
                                </div>
                                <p className="insured-title">~ {t("death_menu__insured_items")} ~</p>
                                <div className="insured-items">{insuredItemSlot}</div>
                            </div>
                        </div>
                    </div>
                    <div className="lost-loot">
                        <h3>~ {t("death_menu__loot_lost")} ~</h3>

                        <ItemContainer items={items} showLeftClickEquipPrompt={false} OnSlotClickFn={() => {}} />
                    </div>
                    <div className="actions">
                        {/* {killerName === "Timer Expiry!" ? (
                        <></>
                    ) : (
                        <button
                            className="neutral-button"
                            onClick={() => {
                                UI_HideYouDiedPanel();
                                Game.SpectateKiller();
                            }}
                        >
                            {t("death_menu__spectate_button")}
                        </button>
                    )} */}
                        {userType === UserType.Anonymous && <span className="delete-acc-cta">{t("register__delete_account_cta_less_aggressive")}</span>}

                        <button
                            disabled={youDiedPanelLoading}
                            className={`neutral-button ${youDiedPanelLoading ? "loading animate__animated animate__jello animate__infinite" : ""}`}
                            onClick={() => {
                                if (youDiedPanelLoading) return;
                                UI_SetIntentionalDisconnect(true);
                                setTimeout(() => {
                                    reloadClient(false);
                                }, 150);
                            }}
                        >
                            {youDiedPanelLoading ? t("death_menu__saving_your_run_button") : t("death_menu__back_to_main_menu_button")}
                        </button>
                    </div>
                </div>
            </>
        );
    }
};

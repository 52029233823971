import { worldConfig } from "../config/Config_World";
import { playerConfig } from "../config/Config_Player";
import { clamp, clampToWorldBounds } from "../SharedUtils";
import { IGameEntityBase, IGameEntityThatMoves, IGameEntityThatUpdates, IGameEntityWithColliders, IGameEntityWithHealth, NetworkEntityId } from "../SharedTypes";
import { InputCommand, NType } from "../SharedNetcodeSchemas";
import { Box, Circle, Polygon, Vector } from "sat";
import { CollisionGridCell, SharedCollisionSystem } from "../systems/SharedCollisionSystem";
import { LoadoutSlots, SharedGearAndWeaponSystem } from "../systems/SharedGearAndWeaponSystem";
import { extractionConfig } from "../config/Config_Extraction";
import { DamageType, PassiveAbility } from "../data/Data_Items";
import { CollisionGridTileIds } from "../data/Data_WorldImport";

export class SharedPlayer implements IGameEntityBase, IGameEntityThatUpdates, IGameEntityWithColliders, IGameEntityThatMoves, IGameEntityWithHealth {
    public nid: NetworkEntityId = -1;
    public ntype: NType = NType.PlayerEntity;
    public position: Vector = new Vector(0, 0);
    public velocity: Vector = new Vector(0, 0);
    public prevPosition: Vector = new Vector(0, 0);
    public isAlive: boolean = true;
    public currentCollisionGridCell: CollisionGridCell;
    public maxHealth: number = 100;
    public currentHealth: number = 100;
    public currentHealthPercentage: number = 1;
    public extractionInProgressAccumulator: number = 0;
    public collidedWithExtractionPointThisFrame: boolean = false;
    public extractionSuccessful: boolean = false;
    public timeRemainingToExtractInSeconds: number = extractionConfig.MAX_RUN_DURATION_IN_SECONDS;

    protected GearAndWeaponSystem: SharedGearAndWeaponSystem;

    public MovementCollider: Circle = new Circle(new Vector(0, 0), playerConfig.MOVEMENT_COLLIDER_RADIUS);
    public HitboxCollider: Polygon = new Box(new Vector(0, 0), playerConfig.HITBOX_COLLIDER_WIDTH, playerConfig.HITBOX_COLLIDER_HEIGHT).toPolygon();

    public isOnWater: boolean = false;
    public cantMoveForSecondsAccumulator: number = 0;
    public aim: number = 0;
    public projectileOrigin: Vector = new Vector(0, 0);
    public debugViewWidth: number;
    public debugViewHeight: number;

    // passive abilities
    public passiveAbilityFlags = {
        [PassiveAbility.MoveSpeedUp]: false,
        [PassiveAbility.MoveSpeedDown]: false,
        [PassiveAbility.GainHealthOverTime]: false,
        [PassiveAbility.LoseHealthOverTime]: false,
        [PassiveAbility.ProjectileDistanceUp]: false,
        [PassiveAbility.ProjectileDistanceDown]: false,
        [PassiveAbility.MaxHealthIncrease]: false,
        [PassiveAbility.MaxHealthDecrease]: false,
        [PassiveAbility.GoldRush]: false,
        [PassiveAbility.PlayerDamageLeech]: false,
        // [PassiveAbility.WeaponCooldownReset]: false,
        [PassiveAbility.WaterWalking]: false,
        // [PassiveAbility.CorpseExplosion]: false,
        [PassiveAbility.Ethereal]: false,
        [PassiveAbility.Revive]: false,
        [PassiveAbility.AttackSpeedUp]: false,
        [PassiveAbility.AttackSpeedDown]: false,
        [PassiveAbility.ExtractionProtection]: false,
        // [PassiveAbility.ScavMode]: false,
        [PassiveAbility.ExtractionSpeedUp]: false,
        // [PassiveAbility.SmolMode]: false,
        // [PassiveAbility.BigMode]: false,
        [PassiveAbility.PlayerDamageUp]: false,
        [PassiveAbility.PlayerDamageDown]: false,
        [PassiveAbility.NPCDamageUp]: false,
        [PassiveAbility.Information]: false,
        [PassiveAbility.NPCDamageDown]: false,
        [PassiveAbility.Incognito]: false
    };

    public extractionProtectionModifier: number = 1;
    public extractionSpeedModifier: number = 1;
    public playerDamageModifier: number = 1;
    public npcDamageModifier: number = 1;
    public attackSpeedMultiplier: number = 1;
    public waterMovementSpeedMultiplier: number = 1;
    public movementSpeedMultiplier: number = 1;
    public healthChangePerSecond: number = 0;
    public projectileDistanceMultiplier: number = 1;
    public characterOpacityAmount: number = 1;
    public goldAcquiredMultiplier: number = 1;
    public leechMultiplier: number = 0;
    public hasExpendedReviveThisRun: boolean = false;
    public reviveHealthPercentageGain: number = 0;
    // public readonly weaponCooldownResetChance: number = 0.01;

    public tempMoveSpeedUpAccumulator: number = 0;
    public tempMoveSpeedUpAmount: number = 0;

    private collisionSystemRef: SharedCollisionSystem;

    public constructor(collisionSystem: SharedCollisionSystem) {
        this.collisionSystemRef = collisionSystem;
    }

    public get activeWeaponSlot(): number {
        return this.GearAndWeaponSystem.activeWeaponSlot;
    }

    public set activeWeaponSlot(newSlot: LoadoutSlots.WeaponOne | LoadoutSlots.WeaponTwo) {
        // no-op
    }

    public HasIncognitoAbility(): boolean {
        return this.passiveAbilityFlags[PassiveAbility.Incognito];
    }

    public get weaponOneId(): number {
        return this.GearAndWeaponSystem.equippedWeaponOne;
    }

    public set weaponOneId(newVal: number) {}

    public get weaponTwoId(): number {
        return this.GearAndWeaponSystem.equippedWeaponTwo;
    }

    public set weaponTwoId(newVal: number) {}

    public get gearOneId(): number {
        return this.GearAndWeaponSystem.equippedGearOne;
    }

    public set gearOneId(newVal: number) {}

    public get gearTwoId(): number {
        return this.GearAndWeaponSystem.equippedGearTwo;
    }

    public set gearTwoId(newVal: number) {}

    public get gearThreeId(): number {
        return this.GearAndWeaponSystem.equippedGearThree;
    }

    public set gearThreeId(newVal: number) {}

    public get gearOneCharges(): number {
        return this.GearAndWeaponSystem.GetCharges(LoadoutSlots.GearOne);
    }
    public set gearOneCharges(__newCharges: number) {} // no-op

    public get gearTwoCharges(): number {
        return this.GearAndWeaponSystem.GetCharges(LoadoutSlots.GearTwo);
    }
    public set gearTwoCharges(__newCharges: number) {} // no-op

    public get gearThreeCharges(): number {
        return this.GearAndWeaponSystem.GetCharges(LoadoutSlots.GearThree);
    }
    public set gearThreeCharges(__newCharges: number) {} // no-op

    public get weaponOneCooldownProgressPercentage(): number {
        return this.GearAndWeaponSystem.GetCooldownProgressPercentage(LoadoutSlots.WeaponOne);
    }
    public set weaponOneCooldownProgressPercentage(__newPercentage: number) {} // no-op

    public get weaponTwoCooldownProgressPercentage(): number {
        return this.GearAndWeaponSystem.GetCooldownProgressPercentage(LoadoutSlots.WeaponTwo);
    }
    public set weaponTwoCooldownProgressPercentage(__newPercentage: number) {} // no-op

    public get gearOneCooldownProgressPercentage(): number {
        return this.GearAndWeaponSystem.GetCooldownProgressPercentage(LoadoutSlots.GearOne);
    }
    public set gearOneCooldownProgressPercentage(__newPercentage: number) {} // no-op

    public get gearTwoCooldownProgressPercentage(): number {
        return this.GearAndWeaponSystem.GetCooldownProgressPercentage(LoadoutSlots.GearTwo);
    }
    public set gearTwoCooldownProgressPercentage(__newPercentage: number) {} // no-op

    public get gearThreeCooldownProgressPercentage(): number {
        return this.GearAndWeaponSystem.GetCooldownProgressPercentage(LoadoutSlots.GearThree);
    }
    public set gearThreeCooldownProgressPercentage(__newPercentage: number) {} // no-op

    protected _increaseMoveSpeed(amountAsDecimalPercent: number) {
        // console.log("increasing move speed in sharedplayer");
        this.movementSpeedMultiplier += amountAsDecimalPercent;
    }

    protected _increaseExtractionProtectionModifier(amountAsDecimalPercent: number) {
        // console.log("increasing water move speed in sharedplayer");
        this.extractionProtectionModifier -= amountAsDecimalPercent;
    }

    protected _increaseAttackSpeedModifier(amountAsDecimalPercent: number) {
        // console.log("increasing attack speed in sharedplayer");
        this.attackSpeedMultiplier -= amountAsDecimalPercent;

        // console.log("attack speed multiplier after applying passive: " + this.attackSpeedMultiplier);
    }

    protected _decreaseAttackSpeedModifier(amountAsDecimalPercent: number) {
        // console.log("increasing water move speed in sharedplayer");
        this.attackSpeedMultiplier += amountAsDecimalPercent;
    }

    protected _increaseNPCDamageModifier(amountAsDecimalPercent: number) {
        // console.log("increasing water move speed in sharedplayer");
        this.npcDamageModifier += amountAsDecimalPercent;
    }

    protected _decreaseNPCDamageModifier(amountAsDecimalPercent: number) {
        // console.log("increasing water move speed in sharedplayer");
        this.npcDamageModifier -= amountAsDecimalPercent;
    }

    protected _increasePlayerDamageModifier(amountAsDecimalPercent: number) {
        // console.log("increasing water move speed in sharedplayer");
        this.playerDamageModifier += amountAsDecimalPercent;
    }

    protected _decreasePlayerDamageModifier(amountAsDecimalPercent: number) {
        // console.log("increasing water move speed in sharedplayer");
        this.playerDamageModifier -= amountAsDecimalPercent;
    }

    protected _increaseExtractionSpeedModifier(amountAsDecimalPercent: number) {
        // console.log("increasing water move speed in sharedplayer");
        this.extractionSpeedModifier += amountAsDecimalPercent;
    }

    protected _increaseWaterMoveSpeed(amountAsDecimalPercent: number) {
        // console.log("increasing water move speed in sharedplayer");
        this.waterMovementSpeedMultiplier += amountAsDecimalPercent;
    }

    protected _decreaseMoveSpeed(amountAsDecimalPercent: number) {
        // console.log("decreasing move speed in sharedplayer");
        this.movementSpeedMultiplier -= amountAsDecimalPercent;
    }

    protected _addHealthGainOverTime(amount: number) {
        // console.log("adding health gain over time in sharedplayer");
        this.healthChangePerSecond += amount;
    }

    protected _addHealthLossOverTime(amount: number) {
        // console.log("adding health loss over time in sharedplayer");
        this.healthChangePerSecond -= amount;
    }

    protected _increaseProjectileDistance(amountAsDecimalPercent: number) {
        // console.log("increasing projectile distance in sharedplayer");
        this.projectileDistanceMultiplier += amountAsDecimalPercent;
    }

    protected _decreaseProjectileDistance(amountAsDecimalPercent: number) {
        // console.log("decreasing projectile distance in sharedplayer");
        this.projectileDistanceMultiplier -= amountAsDecimalPercent;
    }

    protected _increaseMaxHealth(amount: number, matchCurrentHealth: boolean = false) {
        // console.log("increasing max health in sharedplayer");
        this.maxHealth += amount;
        if (matchCurrentHealth) {
            this.currentHealth = this.maxHealth;
        }
    }

    protected _decreaseMaxHealth(amount: number, matchCurrentHealth: boolean = false) {
        // console.log("decreasing max health in sharedplayer");
        this.maxHealth -= amount;
        if (matchCurrentHealth) {
            this.currentHealth = this.maxHealth;
        }
    }

    protected _increaseGoldAcquiredModifier(amountAsDecimalPercent: number) {
        // console.log("increasing gold acquired modifier in sharedplayer");
        this.goldAcquiredMultiplier += amountAsDecimalPercent;

        // console.log("modifier now:", this.goldAcquiredMultiplier);
    }

    protected _increaseLeechModifier(amountAsDecimalPercent: number) {
        // console.log("increasing leech modifier in sharedplayer");
        this.leechMultiplier += amountAsDecimalPercent;
    }

    protected _increaseReviveModifier(amountAsDecimalPercent: number) {
        // console.log("increasing revive modifier in sharedplayer");
        this.reviveHealthPercentageGain += amountAsDecimalPercent;
    }

    protected _makePlayerEthereal(amountAsDecimalPercent: number) {
        this.characterOpacityAmount = clamp(this.characterOpacityAmount - amountAsDecimalPercent, 0.1, 1);
    }

    public get x(): number {
        return this.position.x;
    }
    public set x(newX: number) {
        this.position.x = newX;
        this.MovementCollider.pos.x = this.x;
        this.HitboxCollider.pos.x = this.x - playerConfig.HITBOX_COLLIDER_WIDTH / 2;
    }
    public get y(): number {
        return this.position.y;
    }
    public set y(newY: number) {
        this.position.y = newY;
        this.MovementCollider.pos.y = this.y + playerConfig.MOVEMENT_COLLIDER_Y_OFFSET;
        this.HitboxCollider.pos.y = this.y - playerConfig.HITBOX_COLLIDER_HEIGHT / 2;
    }
    public get prevX(): number {
        return this.prevPosition.x;
    }
    public set prevX(newX: number) {
        this.prevPosition.x = newX;
    }
    public get prevY(): number {
        return this.prevPosition.y;
    }
    public set prevY(newY: number) {
        this.prevPosition.y = newY;
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    // public UseActiveAbility_Relocation(): void {
    //     this.cantMoveForSecondsAccumulator = 4;
    // }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    public UseActiveAbility_Dash(amountAsDecimalPercent: number, durationInSeconds: number): void {
        this._increaseMoveSpeed(amountAsDecimalPercent);
        this.tempMoveSpeedUpAccumulator = durationInSeconds;
        this.tempMoveSpeedUpAmount = amountAsDecimalPercent;
    }

    private _updateProjectileOriginBasedOnWeaponOffset(): void {
        // TODO: updated projectile origin based on weapon offset
        this.projectileOrigin.x = this.x + Math.cos(this.aim) * 18;
        this.projectileOrigin.y = this.y + Math.sin(this.aim) * 18;
    }

    public ProcessInputs(inputCommand: InputCommand) {
        const { up, left, down, right, aim, delta } = inputCommand;

        // Undo temp buffs that require determinism
        if (this.tempMoveSpeedUpAccumulator > 0) {
            this.tempMoveSpeedUpAccumulator = clamp(this.tempMoveSpeedUpAccumulator - delta, 0, 1000);
            if (this.tempMoveSpeedUpAccumulator <= 0) {
                this._decreaseMoveSpeed(this.tempMoveSpeedUpAmount);
                this.tempMoveSpeedUpAmount = 0;
            }
        }

        this.aim = aim;

        if (up) {
            this.velocity.y = -1;
        }

        if (down) {
            this.velocity.y = 1;
        }

        if (left) {
            this.velocity.x = -1;
        }

        if (right) {
            this.velocity.x = 1;
        }

        this.GearAndWeaponSystem.ProcessInputs(inputCommand);

        this.Move(delta);
    }

    public Move(deltaTime: number): void {
        this.prevX = this.x;
        this.prevY = this.y;

        // console.log(deltaTime);

        if (deltaTime > 0.1) {
            if (process.env.NODE_ENV === "development") {
                // console.warn("DELTA HAD TO BE CAPPED: it was", deltaTime);
            }
            deltaTime = 0.1;
        }

        if (this.healthChangePerSecond !== 0) {
            // console.log("player has some health change per second, applying it!");
            // console.log("current health before: ", this.currentHealth);
            // console.log("taking damage:", this.healthChangePerSecond * deltaTime);
            if (this.healthChangePerSecond > 0) {
                this.HealHealth(this.healthChangePerSecond * deltaTime);
            } else if (this.healthChangePerSecond < 0) {
                this.TakeDamage(Math.abs(this.healthChangePerSecond * deltaTime), DamageType.Chaos, -2, 1);
            }
            // console.log("current health is now: ", this.currentHealth);
        }

        const cappedVelocityMagnitude = Math.sqrt(this.velocity.x * this.velocity.x + this.velocity.y * this.velocity.y);

        if (cappedVelocityMagnitude !== 0) {
            this.velocity.x /= cappedVelocityMagnitude;
            this.velocity.y /= cappedVelocityMagnitude;
        }

        if (this.GearAndWeaponSystem.ShouldSlowMovementBecauseOfShooting()) {
            const movementImpactFromShooting = this.GearAndWeaponSystem.GetPlayerMovementImpactModifier();
            this.velocity.x *= movementImpactFromShooting;
            this.velocity.y *= movementImpactFromShooting;
        }

        this.velocity.x *= this.movementSpeedMultiplier;
        this.velocity.y *= this.movementSpeedMultiplier;

        if (this.cantMoveForSecondsAccumulator > 0) {
            this.cantMoveForSecondsAccumulator -= deltaTime;
            return;
        }

        if (this.isOnWater) {
            this.velocity.x *= this.waterMovementSpeedMultiplier;
            this.velocity.y *= this.waterMovementSpeedMultiplier;
        }

        this.x = clampToWorldBounds(this.x + this.velocity.x * (playerConfig.SPEED_PER_SECOND * deltaTime));
        this.y = clampToWorldBounds(this.y + this.velocity.y * (playerConfig.SPEED_PER_SECOND * deltaTime));

        this.velocity.x = 0;
        this.velocity.y = 0;

        this._applyMapCollisions();
    }

    private _applyMapCollisions() {
        const currentTileGridX = Math.floor(this.MovementCollider.pos.x / worldConfig.TILE_SIZE);
        const currentTileGridY = Math.floor(this.MovementCollider.pos.y / worldConfig.TILE_SIZE);

        for (const offset of SharedCollisionSystem.GridOffsets) {
            const [xOffset, yOffset] = offset;

            const relevantTileGridX = currentTileGridX + xOffset;
            const relevantTileGridY = currentTileGridY + yOffset;

            // Don't include tiles outside of the world
            if (relevantTileGridX > worldConfig.WORLD_TILE_DIMENSIONS - 1 || relevantTileGridY > worldConfig.WORLD_TILE_DIMENSIONS - 1 || relevantTileGridX < 0 || relevantTileGridY < 0) {
                // console.log("adjacent x (", relevantTileGridX, ") or adjacent y (", relevantTileGridY, ") is out of bounds");
                continue;
            }

            if (xOffset === 0 && yOffset === 0) {
                if (this.collisionSystemRef.IsTileAtGridXYaSpecificTileId(relevantTileGridX, relevantTileGridY, CollisionGridTileIds.FULL_WATER)) {
                    this.isOnWater = true;
                } else {
                    this.isOnWater = false;
                }
            }

            const collisionPolygon = this.collisionSystemRef.GetCollisionPolygonAtGridXY(relevantTileGridX, relevantTileGridY);

            if (collisionPolygon) {
                const collisionResponse = this.collisionSystemRef.PerformOneOffCirclePolygonCollisionCheck(this.MovementCollider, collisionPolygon);

                if (collisionResponse) {
                    const { overlapV } = collisionResponse;
                    this.MovementCollider.pos.add(overlapV);

                    this.position.x = this.MovementCollider.pos.x;
                    this.position.y = this.MovementCollider.pos.y - playerConfig.MOVEMENT_COLLIDER_Y_OFFSET;

                    this.x = this.position.x;
                    this.y = this.position.y;
                }
            }
        }
    }

    public Update(__deltaTime: number, __currentTimeInMilliseconds?: number): void {
        this._updateProjectileOriginBasedOnWeaponOffset();
    }

    public TakeDamage(__damage: number, __damageType: DamageType, __damageDealerNid: NetworkEntityId, __playerDamageModifier: number): void {}

    public HealHealth(__amount: number): void {}
}

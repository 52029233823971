import React from 'react';
import { RecoilRoot } from 'recoil';
import { UIScreenRouter } from './UI_ScreenRouter';
import RecoilNexus from 'recoil-nexus';

export const UIRoot = () => {
    return (
        <RecoilRoot>
            <RecoilNexus />
            <UIScreenRouter />
        </RecoilRoot>
    );
};

import React from "react";
import { Screens, UI_SwapScreen } from "../framework/UI_State";
import { t } from "../../../shared/data/Data_I18N";

export const Rankings = () => {
    return (
        <>
            <div className="rankings-panel animate__animated animate__fadeInUp animate__faster">
                <div className="rankings-header">{t("rankings__title")}</div>

                <h1>{t("rankings__coming_soon")}</h1>

                <button
                    className="neutral-button"
                    onClick={() => {
                        UI_SwapScreen(Screens.MainMenu);
                    }}
                >
                    {t("settings__back_to_main_menu_button")}
                </button>
            </div>
        </>
    );
};

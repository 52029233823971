import React, { useState } from "react";
import { Screens, UI_SwapScreen } from "../framework/UI_State";
import { DamageType, Item, ItemType, PassiveStatType, getAllItemIds, getPassiveStatAggregateInLoadout } from "../../../shared/data/Data_Items";
import { getItemData } from "../../../shared/data/Data_Items";
import { ItemRarity } from "../../../shared/data/Data_Items";
import { ItemSlot, SlotVisualOptions } from "../components/UI_ItemSlot";
import { t } from "../../../shared/data/Data_I18N";

export const ItemTest = () => {
    const itemOptions: Item[] = getAllItemIds();
    const [selectedItem, setSelectedItem] = useState(Item.AG_AmuletOfCursedFate);

    console.log("itemOptions", itemOptions);

    const onItemChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
        console.log("onItemChanged", event.target.value);
        setSelectedItem(parseInt(event.target.value));
    };

    const itemOptionsDom = itemOptions.map((item) => {
        return (
            <option value={item} key={item}>
                {t(getItemData(item).name)}
            </option>
        );
    });

    const selectedItemConfig = getItemData(selectedItem);
    const { itemId, itemType, itemRarity, maxItemQuantityPerRoll } = selectedItemConfig;

    return (
        <div className="item-test-panel animate__animated animate__fadeInUp animate__faster">
            <div className="item-test-header">Item Test</div>

            <div className="item-test-section">
                <select className="item-test-select" value={selectedItem} onChange={onItemChanged}>
                    {itemOptionsDom}
                </select>
                <div className="selected-item-details">
                    <div className="selected-item-details-row">
                        <div className="selected-item-details-label">itemId: </div>
                        <div className="selected-item-details-value">
                            {" "}
                            &nbsp;{itemId} ({"-->"} {Item[itemId]})
                        </div>
                    </div>
                    <div className="selected-item-details-row">
                        <div className="selected-item-details-label">itemType: </div>
                        <div className="selected-item-details-value">
                            {" "}
                            &nbsp;{itemType} ({"-->"} {ItemType[itemType]})
                        </div>
                    </div>
                    <div className="selected-item-details-row">
                        <div className="selected-item-details-label">itemRarity: </div>
                        <div className="selected-item-details-value">
                            {" "}
                            &nbsp;{itemRarity} ({"-->"} {ItemRarity[itemRarity]})
                        </div>
                    </div>
                    <div className="selected-item-details-row">
                        <div className="selected-item-details-label">maxItemQuantityPerRoll: </div>
                        <div className="selected-item-details-value"> &nbsp;{maxItemQuantityPerRoll}</div>
                    </div>
                </div>

                <div className="selected-item-slots">
                    <ItemSlot slotSize="large" itemId={selectedItemConfig.itemId} slotVisualType={SlotVisualOptions.Gear} quantity={1} selected={true} showInsuredLock={false} OnClickFn={() => {}} />
                    <ItemSlot itemId={selectedItemConfig.itemId} slotVisualType={SlotVisualOptions.Gear} quantity={1} selected={false} showInsuredLock={false} showYouMustExtractPrompt={true} OnClickFn={() => {}} />
                    <ItemSlot itemId={selectedItemConfig.itemId} slotVisualType={SlotVisualOptions.Gear} quantity={1} selected={false} showInsuredLock={true} OnClickFn={() => {}} />

                    <ItemSlot slotSize="large" itemId={selectedItemConfig.itemId} slotVisualType={SlotVisualOptions.Weapon} quantity={1} selected={true} showInsuredLock={false} OnClickFn={() => {}} />
                    <ItemSlot itemId={selectedItemConfig.itemId} slotVisualType={SlotVisualOptions.Weapon} quantity={1} selected={false} showInsuredLock={false} OnClickFn={() => {}} />
                    <ItemSlot itemId={selectedItemConfig.itemId} slotVisualType={SlotVisualOptions.Weapon} quantity={1} selected={false} showInsuredLock={true} OnClickFn={() => {}} />
                </div>
            </div>

            <div className="item-stats">{getPassiveStatAggregateInLoadout(PassiveStatType.DamageBoost, DamageType.Arcane, [selectedItem])}</div>

            <div className="item-test-actions">
                <button
                    className="neutral-button"
                    onClick={() => {
                        UI_SwapScreen(Screens.MainMenu);
                    }}
                >
                    Back To Menu
                </button>
            </div>
        </div>
    );
};

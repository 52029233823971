/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";

export const MainMenuRightContainer = React.memo(
    () => {
        console.log("Render of main menu right container called!");

        Game.Rewards.ShowMainMenuRight();

        const screenHeight = window.innerHeight;
        const shouldUseSmallContainers = screenHeight <= 600;

        const styles = {
            border: window.location.href.includes("localhost") ? "1px solid red" : "none",
            display: "flex",
            width: shouldUseSmallContainers ? "250px" : "160px",
            height: shouldUseSmallContainers ? "250px" : "600px",
            position: "relative",
            top: shouldUseSmallContainers ? "75px" : "auto"
        } as React.CSSProperties;

        return (
            <div className="main-menu-right-container">
                <div className="main-menu-right-container-inner" id="pvpz-io_160x600_4" style={styles}></div>
            </div>
        );
    },
    (__prepProps, __nextProps) => true
);

import englishStrings from "./translations/lang_en.json";
import russianStrings from "./translations/lang_ru.json";
import spanishStrings from "./translations/lang_es.json";
import portugueseStrings from "./translations/lang_pt.json";

export const localizedStrings: Record<string, Record<string, string>> = {
    en: englishStrings,
    ru: russianStrings,
    es: spanishStrings,
    pt: portugueseStrings
};

export const t = (key: string) => {
    let language;

    // @ts-ignore
    if (Lang === undefined) {
        // @ts-ignore
        Lang = "en";
    }

    // @ts-ignore
    if (localizedStrings[Lang]) {
        // @ts-ignore
        language = Lang;
        // console.warn('language was found in language map!!', language);
    } else {
        language = "en";
        // console.warn('language was NOT FOUND in language map! falling back to english.');
    }

    let string;

    if (localizedStrings[language][key] || localizedStrings[language][key] === "") {
        string = localizedStrings[language][key];
        // console.warn('key was found in strings!!', language);
    } else {
        string = localizedStrings["en"][key];
        // console.warn('key was NOT FOUND in strings! falling back something easy for us to idenfify');
    }

    return string;
};

export const englishT = (key: string) => {
    const language = "en";

    let string;

    if (localizedStrings[language][key] || localizedStrings[language][key] === "") {
        string = localizedStrings[language][key];
        // console.warn('key was found in strings!!', language);
    } else {
        string = "{{ MISSING TRANSLATION }}";
        // console.warn('key was NOT FOUND in strings! falling back something easy for us to idenfify');
    }

    return string;
};

import React from "react";
import { useRecoilValue } from "recoil";
import { extractionSuccessfulStateAtom, lootedItemContainerState, UI_HideLootContainerPanel, youDiedStateAtom } from "../framework/UI_State";
import { ItemContainer } from "./UI_ItemContainer";
import { Item } from "../../../shared/data/Data_Items";
import { Tooltip } from "react-tooltip";
import ReactDOM from "react-dom";
import { t } from "../../../shared/data/Data_I18N";

export const ItemContainerPanel = () => {
    const tooltipContainer = document.getElementById("tooltip-container") as Element;
    const { show: playerHasDied } = useRecoilValue(youDiedStateAtom);
    const { show: playerHasExtracted } = useRecoilValue(extractionSuccessfulStateAtom);
    const { items, showPanel, itemContainerId, itemContainerName } = useRecoilValue(lootedItemContainerState);

    const itemContainerNameTranslated = itemContainerName === "Loot Chest" ? t("item_container__loot_chest") : itemContainerName === "Loot Drop" ? t("item_container__loot_drop") : itemContainerName;

    const lootItem = (itemId: Item) => {
        console.log(`Player clicked to loot item! ${Item[itemId]} out of container ${itemContainerId}`);
        Game.LootItem(itemContainerId, itemId);
    };

    if (showPanel === false || playerHasDied || playerHasExtracted) {
        return <></>;
    } else {
        return (
            <div className="item-container-panel animate__animated animate__slideInLeft animate__faster">
                <div className="loot">
                    <h3 data-tooltip-id="container-name-tooltip" data-tooltip-content={itemContainerNameTranslated}>
                        {itemContainerNameTranslated}
                    </h3>
                    {ReactDOM.createPortal(<Tooltip id="container-name-tooltip" style={{ fontSize: "32px" }} />, tooltipContainer)}

                    <ItemContainer items={items} showLeftClickEquipPrompt={false} showLeftClickLootPrompt={true} OnSlotClickFn={lootItem} />
                </div>
                <div className="actions">
                    <button
                        className="neutral-button"
                        onClick={() => {
                            UI_HideLootContainerPanel();
                        }}
                    >
                        {t("game_hud__looted_container_close")}
                    </button>
                </div>
            </div>
        );
    }
};

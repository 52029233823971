import { toast, cssTransition } from "react-toastify";

const bounce = cssTransition({
    enter: "animate__animated animate__bounceIn",
    exit: "animate__animated animate__bounceOut"
});

const messageTypeToEmoji = {
    none: "",
    success: "✅",
    error: "❌",
    warning: "⚠️"
};

export enum ToastStatuses {
    NONE = "none",
    SUCCESS = "success",
    ERROR = "error",
    WARNING = "warning"
}

interface ToastOptions {
    message: string;
    status: ToastStatuses;
}

export const PopToast = (toastOptions: ToastOptions): void => {
    const { status, message } = toastOptions;
    toast.dark(`${messageTypeToEmoji[status]} ${message}`, {
        transition: bounce
    });
};

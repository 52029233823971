import { Container, Graphics } from "pixi.js";
import { IGameEntityBasic } from "../../shared/SharedTypes";
import { DebugGridCellEntityCreationPayload, NType } from "../../shared/SharedNetcodeSchemas";
import { Vector } from "sat";
import { IRenderedEntity } from "../ClientTypes";

export class ClientDebugGridCell implements IGameEntityBasic, IRenderedEntity {
    public nid: number;
    public ntype: NType;
    public position: SAT.Vector = new Vector(0, 0);
    public width: number;
    public height: number;
    public RenderedEntity: Container = new Container();
    private inactiveGridCellVisual: Graphics = new Graphics();
    private activeGridCellVisual: Graphics = new Graphics();
    private readonly gridCellBorderSize: number = 20;

    public constructor(createGridCellPayload: DebugGridCellEntityCreationPayload) {
        const { nid, ntype, x, y, width, height, isActive } = createGridCellPayload;
        this.nid = nid;
        this.ntype = ntype;
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
        this.isActive = isActive;

        this.inactiveGridCellVisual.lineStyle(this.gridCellBorderSize, 0x000000);
        this.inactiveGridCellVisual.drawRect(this.x, this.y, this.width, this.height);
        this.RenderedEntity.addChild(this.inactiveGridCellVisual);

        this.activeGridCellVisual.lineStyle(this.gridCellBorderSize, 0xffffff);
        this.activeGridCellVisual.drawRect(this.x + this.gridCellBorderSize, this.y + this.gridCellBorderSize, this.width - this.gridCellBorderSize * 2, this.height - this.gridCellBorderSize * 2);
        this.activeGridCellVisual.alpha = isActive ? 1 : 0;
        this.RenderedEntity.addChild(this.activeGridCellVisual);
    }

    public set isActive(newValue: boolean) {
        this.activeGridCellVisual.alpha = newValue ? 1 : 0;
    }

    public get x(): number {
        return this.position.x;
    }

    public set x(newX: number) {
        this.position.x = newX;
    }

    public get y(): number {
        return this.position.y;
    }

    public set y(newY: number) {
        this.position.y = newY;
    }

    Update(__deltaTime: number): void {}
}

import React from "react";
import { useRecoilValue } from "recoil";
import { extractionSuccessfulStateAtom, itemsLootedOnRunStateAtom, youDiedStateAtom } from "../framework/UI_State";
import { ItemContainer } from "./UI_ItemContainer";
import { t } from "../../../shared/data/Data_I18N";
// @ts-ignore
import extractionGifSrc from "../kit/extraction.gif";
// @ts-ignore
import handSrc from "../kit/hand_point.png";

export const InRunInventoryPanel = () => {
    const { show: playerHasDied } = useRecoilValue(youDiedStateAtom);
    const { show: playerHasExtracted } = useRecoilValue(extractionSuccessfulStateAtom);
    const { items, showInventoryPanel } = useRecoilValue(itemsLootedOnRunStateAtom);

    if (showInventoryPanel === false || playerHasDied || playerHasExtracted) {
        return <></>;
    } else {
        return (
            <div className="in-run-inventory-panel animate__animated animate__slideInLeft animate__faster">
                <div className="your-inventory">
                    <h3>
                        {t("game_hud__your_inventory_header")}
                        <br />
                        ([i] {t("game_hud__your_inventory_hide")})
                    </h3>

                    <ItemContainer items={items} showLeftClickEquipPrompt={false} showLeftClickLootPrompt={false} showYouMustExtractPrompt={true} OnSlotClickFn={() => {}} />

                    <div className="extraction-notification">
                        <img src={handSrc} alt="Extraction Preview Hand" className="extraction-pointer animate__animated animate__jello animate__infinite" />
                        <img src={extractionGifSrc} alt="Extraction Preview" className="extraction-preview" />
                        <p>{t("game_hud__inventory_extract_prompt")}</p>
                    </div>
                </div>
            </div>
        );
    }
};

import React from "react";
import { useRecoilValue } from "recoil";
import { Screens, UI_SwapScreen, playerIdentityStateAtom, profileStatsSelector } from "../framework/UI_State";
import { getHoursMinutesFriendlyStringFromSeconds } from "../../../shared/SharedUtils";
import { t } from "../../../shared/data/Data_I18N";
import { UserType } from "../../../shared/SharedTypes";

export const Stats = () => {
    const stats = useRecoilValue(profileStatsSelector);
    const { userType } = useRecoilValue(playerIdentityStateAtom);

    console.log("userType", userType);

    let { commonItemsLooted, distanceTravelled, epicItemsLooted, failedExtractions, goldLooted, itemsLooted, legendaryItemsLooted, npcKills, playerKills, rareItemsLooted, runsEmbarkedOn, successfulExtractions, timeSpentOnRunsInSeconds, uncommonItemsLooted, bossesKilled, goldLost, itemsLost } = stats;

    if (userType === UserType.Anonymous) {
        commonItemsLooted = Math.floor(Math.random() * 100);
        distanceTravelled = Math.floor(Math.random() * 100);
        epicItemsLooted = Math.floor(Math.random() * 100);
        failedExtractions = Math.floor(Math.random() * 100);
        goldLooted = Math.floor(Math.random() * 100);
        itemsLooted = Math.floor(Math.random() * 100);
        legendaryItemsLooted = Math.floor(Math.random() * 100);
        npcKills = Math.floor(Math.random() * 100);
        playerKills = Math.floor(Math.random() * 100);
        rareItemsLooted = Math.floor(Math.random() * 100);
        runsEmbarkedOn = Math.floor(Math.random() * 100);
        successfulExtractions = Math.floor(Math.random() * 100);
        timeSpentOnRunsInSeconds = Math.floor(Math.random() * 100);
        uncommonItemsLooted = Math.floor(Math.random() * 100);
        bossesKilled = Math.floor(Math.random() * 100);
        goldLost = Math.floor(Math.random() * 100);
        itemsLost = Math.floor(Math.random() * 100);
    }

    return (
        <>
            <div className="stats-panel animate__animated animate__fadeInUp animate__faster">
                <div className="stats-header">{t("stats__header")}</div>

                {userType === UserType.Anonymous && (
                    <div className="overlay">
                        <span className="delete-acc-cta">{t("register__delete_account_cta_2")}</span>

                        <button
                            className="affirmative-button"
                            onClick={() => {
                                UI_SwapScreen(Screens.Register);
                            }}
                        >
                            {t("register__register")}
                        </button>
                    </div>
                )}

                <div className="stats-container">
                    <div className="stat">
                        <div className="stat-value">.</div>
                        <div className="stat-label">{t("stats__global_rank")}</div>
                    </div>

                    <div className="stat">
                        <div className="stat-value">{getHoursMinutesFriendlyStringFromSeconds(timeSpentOnRunsInSeconds)}</div>
                        <div className="stat-label">{t("stats__time_played")}</div>
                    </div>

                    <div className="stat">
                        <div className="stat-value">{runsEmbarkedOn === 0 ? 0 : Math.floor((successfulExtractions / runsEmbarkedOn) * 100)}%</div>
                        <div className="stat-label">{t("stats__run_success_percentage")}</div>
                    </div>

                    <div className="stat">
                        <div className="stat-value">{runsEmbarkedOn}</div>
                        <div className="stat-label">{t("stats__runs")}</div>
                    </div>

                    <div className="stat">
                        <div className="stat-value">{successfulExtractions}</div>
                        <div className="stat-label">{t("stats__successful_runs")}</div>
                    </div>

                    <div className="stat">
                        <div className="stat-value">{failedExtractions}</div>
                        <div className="stat-label">{t("stats__failed_runs")}</div>
                    </div>

                    <div className="stat">
                        <div className="stat-value">{distanceTravelled}</div>
                        <div className="stat-label">{t("stats__distanced_travelled")}</div>
                    </div>

                    <div className="stat">
                        <div className="stat-value">{npcKills}</div>
                        <div className="stat-label">{t("stats__npc_kills")}</div>
                    </div>

                    <div className="stat">
                        <div className="stat-value">{bossesKilled}</div>
                        <div className="stat-label">{t("stats__boss_kills")}</div>
                    </div>

                    <div className="stat">
                        <div className="stat-value">{playerKills}</div>
                        <div className="stat-label">{t("stats__player_kills")}</div>
                    </div>

                    <div className="stat">
                        <div className="stat-value">{itemsLooted}</div>
                        <div className="stat-label">{t("stats__items_looted")}</div>
                    </div>

                    <div className="stat">
                        <div className="stat-value">{goldLooted}</div>
                        <div className="stat-label">{t("stats__gold_looted")}</div>
                    </div>

                    <div className="stat">
                        <div className="stat-value">{goldLost}</div>
                        <div className="stat-label">{t("stats__gold_lost_to_deaths")}</div>
                    </div>

                    <div className="stat">
                        <div className="stat-value">{itemsLost}</div>
                        <div className="stat-label">{t("stats__items_lost_to_deaths")}</div>
                    </div>

                    <div className="stat">
                        <div className="stat-value">{commonItemsLooted}</div>
                        <div className="stat-label">{t("stats__common_items_looted")}</div>
                    </div>

                    <div className="stat">
                        <div className="stat-value">{uncommonItemsLooted}</div>
                        <div className="stat-label">{t("stats__uncommon_items_looted")}</div>
                    </div>

                    <div className="stat">
                        <div className="stat-value">{rareItemsLooted}</div>
                        <div className="stat-label">{t("stats__rare_items_looted")}</div>
                    </div>

                    <div className="stat">
                        <div className="stat-value">{epicItemsLooted}</div>
                        <div className="stat-label">{t("stats__epic_items_looted")}</div>
                    </div>

                    <div className="stat">
                        <div className="stat-value">{legendaryItemsLooted}</div>
                        <div className="stat-label">{t("stats__legendary_items_looted")}</div>
                    </div>
                </div>

                <button
                    className="neutral-button"
                    onClick={() => {
                        UI_SwapScreen(Screens.MainMenu);
                    }}
                >
                    {t("stats__back_btn")}
                </button>
            </div>
        </>
    );
};

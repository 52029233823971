import { FederatedPointerEvent } from "pixi.js";
import { GameplaySystem } from "../../shared/engine/SharedGameplaySystem";
import { FacingDirection } from "../../shared/SharedTypes";
import { UI_ToggleInventoryPanel, UI_ToggleLeaderboardPanel } from "../ui/framework/UI_State";
import nipplejs from "nipplejs";

// These are not arbitrary, these are the values in web engines for left right and middle! do not change!
enum MouseButton {
    Left = 0,
    Middle = 1,
    Right = 2
}

export enum Keys {
    // Language Agnostic
    One = "1",
    Two = "2",
    Three = "3",
    Four = "4",
    Five = "5",

    // English
    W = "w",
    A = "a",
    S = "s",
    D = "d",
    Q = "q",
    E = "e",
    I = "i",
    L = "l",
    UppercaseW = "W",
    UppercaseA = "A",
    UppercaseS = "S",
    UppercaseD = "D",
    UppercaseQ = "Q",
    UppercaseE = "E",
    UppercaseI = "I",
    UppercaseL = "L",

    // Russian
    RussianUp = "ц",
    RussianLeft = "ф",
    RussianDown = "ы",
    RussianRight = "в",
    RussianE = "у",
    RussianI = "ш",
    RussianL = "д"
}

export enum AlternateKeys {
    UpArrow = "ArrowUp",
    LeftArrow = "ArrowLeft",
    DownArrow = "ArrowDown",
    RightArrow = "ArrowRight"
}

export class ClientInputManager extends GameplaySystem {
    private keyState: Map<string, boolean> = new Map();
    private mouseState: Map<MouseButton, boolean> = new Map();
    private mouseWheelScrolled: boolean = false;
    private mouseWorldX: number = 0;
    private mouseWorldY: number = 0;
    private mouseScreenX: number = 0;
    private mouseScreenY: number = 0;
    private joystickAngleInRads: number = 0;
    private lastOpenedInventoryAccumulator: number = 0.3;
    private lastToggledLeaderboardAccumulator: number = 0.3;
    private isMobile: boolean = false;
    private aimJoystickActive: boolean = false;
    public clickedGearOneThisFrame: boolean = false;
    public clickedGearTwoThisFrame: boolean = false;
    public clickedGearThreeThisFrame: boolean = false;
    public clickedInventoryThisFrame: boolean = false;
    // todo better controller support
    // private readonly controllerAxesActiveThreshold = 0.3;

    public constructor() {
        super();

        // disallows right click menu on the game screen
        document.addEventListener("contextmenu", (event) => event.preventDefault());

        // listen for keystrokes, update keyboard state accordingly
        document.addEventListener("keydown", this._updateKeyState.bind(this));
        document.addEventListener("keyup", this._updateKeyState.bind(this));
        // listen for mouse clicks, update mouse state accordingly
        document.addEventListener("mousedown", (event) => this.mouseState.set(event.button, true));
        document.addEventListener("mouseup", (event) => this.mouseState.set(event.button, false));

        document.addEventListener("wheel", (event: WheelEvent) => {
            event.preventDefault();
            this.mouseWheelScrolled = true;
        });

        window.onblur = () => {
            this._resetAllkeyStates();
        };
    }

    protected override getSystemName(): string {
        return "Input";
    }

    public Initialize(): void {
        Game.Renderer.camera.on("globalmousemove", this._handleMouseMove.bind(this));

        this.isMobile = document.querySelector("html")!.classList.contains("mobile");

        if (this.isMobile) {
            const leftJoystickManager = nipplejs.create({
                zone: document.getElementById("left-joystick-zone")!,
                mode: "dynamic"
            });

            // console.log(leftJoystickManager);

            const rightJoystickManager = nipplejs.create({
                zone: document.getElementById("right-joystick-zone")!,
                mode: "dynamic"
            });

            // console.log(rightJoystickManager);

            leftJoystickManager.on("move", (__evt, joystickData) => {
                if (joystickData.direction) {
                    const xDir = joystickData.direction.x;
                    const yDir = joystickData.direction.y;

                    // console.log(xDir, yDir);

                    if (xDir === "left") {
                        this.keyState.set(Keys.A, true);
                        this.keyState.set(Keys.UppercaseA, true);
                        this.keyState.set(Keys.D, false);
                        this.keyState.set(Keys.UppercaseD, false);
                    } else if (xDir === "right") {
                        this.keyState.set(Keys.A, false);
                        this.keyState.set(Keys.UppercaseA, false);
                        this.keyState.set(Keys.D, true);
                        this.keyState.set(Keys.UppercaseD, true);
                    }

                    if (yDir === "up") {
                        this.keyState.set(Keys.W, true);
                        this.keyState.set(Keys.UppercaseW, true);
                        this.keyState.set(Keys.S, false);
                        this.keyState.set(Keys.UppercaseS, false);
                    } else if (yDir === "down") {
                        this.keyState.set(Keys.W, false);
                        this.keyState.set(Keys.UppercaseW, false);
                        this.keyState.set(Keys.S, true);
                        this.keyState.set(Keys.UppercaseS, true);
                    }
                }
            });

            rightJoystickManager.on("move", (__evt, joystickData) => {
                this.aimJoystickActive = true;
                if (joystickData.angle) {
                    this.joystickAngleInRads = -joystickData.angle!.radian;
                }
            });

            leftJoystickManager.on("end", () => {
                this.keyState.set(Keys.W, false);
                this.keyState.set(Keys.UppercaseW, false);
                this.keyState.set(Keys.A, false);
                this.keyState.set(Keys.UppercaseA, false);
                this.keyState.set(Keys.S, false);
                this.keyState.set(Keys.UppercaseS, false);
                this.keyState.set(Keys.D, false);
                this.keyState.set(Keys.UppercaseD, false);
            });

            rightJoystickManager.on("end", () => {
                this.aimJoystickActive = false;
            });
        }

        this.LogInfo("Ready!");
    }

    public AimJoystickActive(): boolean {
        return this.aimJoystickActive;
    }

    private _resetAllkeyStates(): void {
        this.keyState.forEach((_, key) => {
            this.keyState.set(key, false);
        });
    }

    private _updateKeyState(e: KeyboardEvent) {
        // console.log(e.key);

        // Disable the minus and plus keys
        if (e.key === "-" || e.key === "+" || e.key === "_" || e.key === "=" || e.key === "CapsLock" || e.key === "Control" || e.key === "Shift" || e.key === "Alt" || e.key === "Meta" || e.key === "Tab") {
            e.preventDefault();
        }

        if (e.key === "F8") {
            Game.Netcode.SendAdminShutdownCommand();
        }

        if ((e.key === Keys.I || e.key === Keys.UppercaseI || e.key === Keys.RussianI) && this.lastOpenedInventoryAccumulator >= 0.3) {
            UI_ToggleInventoryPanel();
            this.lastOpenedInventoryAccumulator = 0;
        }

        if ((e.key === Keys.L || e.key === Keys.UppercaseL || e.key === Keys.RussianL) && this.lastToggledLeaderboardAccumulator >= 0.3) {
            UI_ToggleLeaderboardPanel();
            this.lastToggledLeaderboardAccumulator = 0;
        }

        const keyToMutate = e.key.toLowerCase();

        this.keyState.set(keyToMutate, e.type === "keydown");
    }

    public Update(__deltaTime: number): void {
        this.lastOpenedInventoryAccumulator += __deltaTime;
        this.lastToggledLeaderboardAccumulator += __deltaTime;

        if (this.mouseWheelScrolled) {
            this.mouseWheelScrolled = false;
        }
        // todo better controller support
        // const gamepads = navigator.getGamepads();
        // if (gamepads.length >= 1) {
        //     const gamepad = gamepads[0];
        //     if (gamepad) {
        //         const [x, y] = gamepad.axes;
        //         // this.LogWarning('---');
        //         // this.LogWarning('left?', x <= -this._controllerAxesActiveThreshold);
        //         // this.LogWarning('right?', x >= this._controllerAxesActiveThreshold);
        //         // this.LogWarning('up?', y <= -this._controllerAxesActiveThreshold);
        //         // this.LogWarning('down?', y >= this._controllerAxesActiveThreshold);
        //         this.keyState.set('a', x <= -this.controllerAxesActiveThreshold);
        //         this.keyState.set('d', x >= this.controllerAxesActiveThreshold);
        //         this.keyState.set('w', y <= -this.controllerAxesActiveThreshold);
        //         this.keyState.set('s', y >= this.controllerAxesActiveThreshold);
        //     }
        // }
        // this.LogWarning('Update in input manager!');

        if (this.clickedGearOneThisFrame === true) {
            this.clickedGearOneThisFrame = false;
        }
        if (this.clickedGearTwoThisFrame === true) {
            this.clickedGearTwoThisFrame = false;
        }
        if (this.clickedGearThreeThisFrame === true) {
            this.clickedGearThreeThisFrame = false;
        }
        if (this.clickedInventoryThisFrame === true) {
            this.clickedInventoryThisFrame = false;
        }
    }

    public Cleanup(): void {}

    private _isMouseButtonPressed(button: MouseButton): boolean {
        return !!this.mouseState.get(button);
    }

    public LeftMousePressed(): boolean {
        return this._isMouseButtonPressed(MouseButton.Left);
    }

    public RightMousePressed(): boolean {
        return this._isMouseButtonPressed(MouseButton.Right);
    }

    public MiddleMousePressed(): boolean {
        return this._isMouseButtonPressed(MouseButton.Middle);
    }

    public Scrolled(): boolean {
        return this.mouseWheelScrolled;
    }

    public IsKeyDown(key: string): boolean {
        return !!this.keyState.get(key);
    }

    public GetMouseWorldX(): number {
        return this.mouseWorldX;
    }

    public GetMouseWorldY(): number {
        return this.mouseWorldY;
    }

    public GetMouseScreenX(): number {
        return this.mouseScreenX;
    }

    public GetMouseScreenY(): number {
        return this.mouseScreenY;
    }

    public GetPlayerAimAngleFromCharacterPosition(x: number, y: number): number {
        if (this.isMobile) {
            return this.joystickAngleInRads;
        } else {
            const camera = Game.Renderer.camera;
            const characterPositionInScreenSpace = camera.toScreen(x, y);
            return Math.atan2(this.GetMouseScreenY() - characterPositionInScreenSpace.y, this.GetMouseScreenX() - characterPositionInScreenSpace.x);
        }
    }

    public ToggleFullScreen(): void {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
        } else if (document.exitFullscreen) {
            document.exitFullscreen();
        }
    }

    public GetFacingDirectionBasedOnCurrentMouseWorldPosition(x: number, __lastFacing?: FacingDirection): FacingDirection {
        let facing;

        if (this.mouseWorldX < x) {
            facing = FacingDirection.Left;
        } else {
            facing = FacingDirection.Right;
        }

        return facing;
    }

    private _handleMouseMove(event: FederatedPointerEvent): void {
        const { x, y } = Game.Renderer.camera.toWorld(event.global.x, event.global.y);
        this.mouseWorldX = x;
        this.mouseWorldY = y;
        this.mouseScreenX = event.screen.x;
        this.mouseScreenY = event.screen.y;
    }
}

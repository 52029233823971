import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Screens, UI_SavePreferences, UI_SwapScreen, assetLoadingStateAtom, maintenanceModeStateAtom, playerIdentityStateAtom, playerPreferencesStateAtom } from "../framework/UI_State";
import { GAME_VERSION } from "../../../shared/config/Config_Game";
import { UserType } from "../../../shared/SharedTypes";
import ReactDOM from "react-dom";
import { Tooltip } from "react-tooltip";
import { t } from "../../../shared/data/Data_I18N";

// @ts-ignore
import logoImageSrc from "../kit/logo.png";
import { LoadingSpinner } from "../components/UI_LoadingSpinner";
import { MainMenuLeftPanelContainer } from "../components/UI_MainMenuLeftPanelContainer";
import { Socials } from "../components/UI_Socials";

export const MainMenu = () => {
    const { checkingMaintenanceModeStatusInProgress, isInmaintenanceMode } = useRecoilValue(maintenanceModeStateAtom);
    const { loadingPlayerIdentity, userType, userDetails } = useRecoilValue(playerIdentityStateAtom);
    const { loadingAssets } = useRecoilValue(assetLoadingStateAtom);
    const [preferences, updatePreferences] = useRecoilState(playerPreferencesStateAtom);
    const { savingPreferencesInProgress, local } = preferences;

    const { username, PVPZ_Stats, PVPZ_GameState } = userDetails;
    const { currentlyOnARun } = PVPZ_GameState;

    const { runsEmbarkedOn } = PVPZ_Stats;

    const tooltipContainer = document.getElementById("tooltip-container") as Element;

    const onMatchmakingRegionChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
        console.log("on matchmaking region changed");
        updatePreferences({ ...preferences, local: { ...local, preferredMatchmakingRegion: e.target.value } });
        UI_SavePreferences();
    };

    if (process.env.NODE_ENV === "development") {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
            const onKeyDown = (event: KeyboardEvent) => {
                if (event.key === "F1") {
                    UI_SwapScreen(Screens.ItemTest);
                }
            };

            document.addEventListener("keydown", onKeyDown);

            return () => {
                document.removeEventListener("keydown", onKeyDown);
            };
        }, []);
    }

    const anonymousUserDetails = (
        <div className="anonymous-user-details">
            <div className="welcome-back">
                <div className="wb-header">
                    {t("main_menu__welcome_panel_title")} {runsEmbarkedOn > 1 && t("main_menu__welcome_panel_title_suffix")}
                </div>
                <div className="wb-username" data-tooltip-id="discord-tooltip" data-tooltip-content={username}>
                    {username}
                    {ReactDOM.createPortal(<Tooltip id="discord-tooltip" style={{ fontSize: "32px" }} />, tooltipContainer)}
                </div>
                <div className="wb-signup-cta">{t("main_menu__welcome_sign_in_cta")}</div>
            </div>

            <button
                className="create-account-btn neutral-button"
                onClick={() => {
                    UI_SwapScreen(Screens.Register);
                }}
            >
                {t("main_menu__create_account_btn")}
            </button>

            <button
                className="sign-in-btn neutral-button"
                onClick={() => {
                    UI_SwapScreen(Screens.Login);
                }}
            >
                {t("main_menu__sign_in_btn")}
            </button>

            {/* <button
                className="collection-log-btn neutral-button"
                onClick={() => {
                    UI_SwapScreen(Screens.CollectionLog);
                }}
            >
                {t("main_menu__collection_log_btn")}
            </button> */}

            {/* <a className="social-btn facebook-oauth-btn" href={process.env.OAUTH_FACEBOOK_ADDRESS}>
                {t("main_menu__sign_in_with_facebook_btn")}
            </a> */}

            <button
                className="inventory-btn neutral-button"
                onClick={() => {
                    UI_SwapScreen(Screens.Inventory);
                }}
            >
                {t("main_menu__view_inventory_btn")}
            </button>

            <button
                className="stats-btn neutral-button"
                onClick={() => {
                    UI_SwapScreen(Screens.Stats);
                }}
            >
                {t("main_menu__view_your_stats_btn")}
            </button>
        </div>
    );

    const loadingDetails = (
        <div className="loading-user-details">
            <LoadingSpinner />
        </div>
    );

    const registeredUserDetails = (
        <div className="registered-user-details">
            <div className="welcome-back">
                <div className="wb-header">
                    {t("main_menu__welcome_panel_title")} {runsEmbarkedOn > 1 && t("main_menu__welcome_panel_title_suffix")}
                </div>
                <div className="wb-username" data-tooltip-id="discord-tooltip" data-tooltip-content={username}>
                    {username}
                    {ReactDOM.createPortal(<Tooltip id="discord-tooltip" style={{ fontSize: "32px" }} />, tooltipContainer)}
                </div>

                <button
                    className="inventory-btn neutral-button"
                    onClick={() => {
                        UI_SwapScreen(Screens.Inventory);
                    }}
                >
                    {t("main_menu__view_inventory_btn")}
                </button>

                <button
                    className="stats-btn neutral-button"
                    onClick={() => {
                        UI_SwapScreen(Screens.Stats);
                    }}
                >
                    {t("main_menu__view_your_stats_btn")}
                </button>

                <button
                    className="change-username-btn neutral-button"
                    onClick={() => {
                        UI_SwapScreen(Screens.ChangeUsername);
                    }}
                >
                    {t("main_menu__change_username_btn")}
                </button>

                {/* <button
                    className="collection-log-btn neutral-button"
                    onClick={() => {
                        UI_SwapScreen(Screens.CollectionLog);
                    }}
                >
                    {t("main_menu__collection_log_btn")}
                </button> */}

                <button
                    className="inventory-btn destructive-button"
                    onClick={() => {
                        Game.EmitEvent("Identity::Logout");
                    }}
                >
                    {t("main_menu__logout_btn")}
                </button>
            </div>
        </div>
    );

    const shouldDisablePlayButton = loadingPlayerIdentity || loadingAssets || savingPreferencesInProgress;

    const screenWidth = window.innerWidth;

    const screenSizeIsSufficientlyLarge = screenWidth >= 1050;

    return (
        <div className="main-menu">
            <div className="center-content">
                <div className="game-logo animate__animated animate__jackInTheBox">
                    <img draggable="false" className="game-logo-image" src={logoImageSrc} alt="PvPz.io - Loot your friends 😈" />
                    <span>{GAME_VERSION.substring(0, 5)}</span>
                </div>

                {(checkingMaintenanceModeStatusInProgress || isInmaintenanceMode) && (
                    <div className="maintenance-mode">
                        {checkingMaintenanceModeStatusInProgress && <LoadingSpinner />}
                        {isInmaintenanceMode && <h1>{t("main_menu__game_is_in_maintenance_mode")}</h1>}
                    </div>
                )}

                {checkingMaintenanceModeStatusInProgress === false && isInmaintenanceMode === false && (
                    <div className="panels">
                        {screenSizeIsSufficientlyLarge && (
                            <div className="main-menu-ad-panel animate__animated animate__bounceInLeft animate__fast">
                                <MainMenuLeftPanelContainer />

                                {/* <button
                                    className="neutral-button menu-btn partners-btn"
                                    onClick={() => {
                                        UI_SwapScreen(Screens.Partners);
                                    }}
                                >
                                    {t("main_menu__partners")}
                                </button> */}
                            </div>
                        )}

                        <div className="main-menu-primary-panel animate__animated animate__bounceInUp animate__fast">
                            {loadingPlayerIdentity && loadingDetails}

                            {loadingPlayerIdentity === false && (
                                <>
                                    <button
                                        style={{ position: "relative" }}
                                        className="affirmative-button btn-large play-game-btn"
                                        disabled={shouldDisablePlayButton}
                                        onClick={() => {
                                            // in the FTUE case (they've never played before, skip straight to the gameplay since they wouldnt have an inventory/loadout to customize anyway, and we want to get them in the game faster)
                                            // if (runsEmbarkedOn === 0) {
                                            //     Game.EmitEvent('GameLifecycle::RunStarted');
                                            // } else
                                            if (currentlyOnARun === true) {
                                                UI_SwapScreen(Screens.RunInProgress);
                                            } else {
                                                UI_SwapScreen(Screens.RunPrep);
                                            }
                                        }}
                                    >
                                        {shouldDisablePlayButton ? "..." : t("main_menu__play_btn")}
                                    </button>

                                    <select value={local.preferredMatchmakingRegion} onChange={onMatchmakingRegionChanged}>
                                        <option value="any">{t("settings__matchmaking_region_dropdown_any")}</option>
                                        <option value="us-east">{t("settings__matchmaking_region_dropdown_na_east")}</option>
                                        <option value="us-west">{t("settings__matchmaking_region_dropdown_na_west")}</option>
                                        <option value="eu">{t("settings__matchmaking_region_dropdown_eu")}</option>
                                        <option value="asia">{t("settings__matchmaking_region_dropdown_asia")}</option>
                                        <option value="australia">{t("settings__matchmaking_region_dropdown_australia_oceania")}</option>
                                        <option value="south-america">{t("settings__matchmaking_region_dropdown_south_america")}</option>
                                    </select>

                                    <button
                                        className="neutral-button menu-btn how-to-play-btn"
                                        onClick={() => {
                                            UI_SwapScreen(Screens.HowToPlay);
                                        }}
                                    >
                                        {t("main_menu__how_to_play_btn")}
                                    </button>

                                    <button
                                        className="neutral-button menu-btn vendors-btn"
                                        onClick={() => {
                                            UI_SwapScreen(Screens.Vendors);
                                        }}
                                    >
                                        {t("main_menu__vendors_btn")}
                                    </button>

                                    <button
                                        className="menu-btn vendors-btn affirmative-button animate__animated animate__jello animate__infinite animate__slow"
                                        onClick={() => {
                                            UI_SwapScreen(Screens.Shop);
                                        }}
                                    >
                                        <span className="shop-new-tag">New!</span>
                                        {t("main_menu__shop_btn")}
                                    </button>

                                    <button
                                        className="neutral-button menu-btn rankings-btn"
                                        onClick={() => {
                                            UI_SwapScreen(Screens.Rankings);
                                        }}
                                    >
                                        {t("main_menu__rankings_btn")}
                                    </button>

                                    <button
                                        className="neutral-button menu-btn settings-btn"
                                        onClick={() => {
                                            UI_SwapScreen(Screens.Settings);
                                        }}
                                    >
                                        {t("main_menu__settings_btn")}
                                    </button>
                                </>
                            )}

                            <Socials />
                        </div>

                        <div className="main-menu-profile-panel animate__animated animate__bounceInRight animate__fast">
                            {loadingPlayerIdentity && loadingDetails}
                            {loadingPlayerIdentity === false && userType === UserType.Anonymous && anonymousUserDetails}
                            {loadingPlayerIdentity === false && userType === UserType.Registered && registeredUserDetails}
                        </div>
                    </div>
                )}
                {/* <BottomContainer /> */}
            </div>
        </div>
    );
};

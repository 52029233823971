export const reloadClient = (shouldSkipMenu: boolean, shouldSkipToLoadout?: boolean) => {
    console.warn("@@@ RELOADING WITH SDK:", ThirdPartySDK);
    console.warn("@@@ RELOADING WITH ORIGINAL LOCATION:", OriginalLocation);

    let prefix = "?";

    console.log("OriginalLocation:", OriginalLocation);

    console.log(OriginalLocation.includes);

    if (OriginalLocation.includes("?")) {
        prefix = "&";
    }

    let redirectUri = OriginalLocation;

    if (window.location.href.includes("localhost") && shouldSkipMenu) {
        if (ThirdPartySDK === "crazygames") {
            redirectUri = `${OriginalLocation}${prefix}skipMenu=true&force-third-party-sdk=crazygames`;
        } else {
            redirectUri = `${OriginalLocation}${prefix}skipMenu=true`;
        }
    } else {
        if (shouldSkipToLoadout) {
            if (ThirdPartySDK === "crazygames") {
                redirectUri = `${OriginalLocation}${prefix}sl=true&force-third-party-sdk=crazygames`;
            } else {
                redirectUri = `${OriginalLocation}${prefix}sl=true`;
            }
        } else {
            if (ThirdPartySDK === "crazygames") {
                redirectUri = `${OriginalLocation}${prefix}force-third-party-sdk=crazygames`;
            } else {
                redirectUri = OriginalLocation;
            }
        }
    }
    // console.warn("@@@ SDK REDIRECTING TO:", redirectUri);
    document.location.href = redirectUri;
};

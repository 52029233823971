import { Circle, Vector } from "sat";
import { NType } from "../SharedNetcodeSchemas";
import { IGameEntityBase, IGameEntityThatMoves, IGameEntityThatUpdates, IGameEntityWithColliders, IGameEntityWithOwner, NetworkEntityId } from "../SharedTypes";
import { clampToWorldBounds } from "../SharedUtils";
import { CollisionGridCell, SharedCollisionSystem } from "../systems/SharedCollisionSystem";
import { EntityProjectileConfig, Projectile } from "../data/Data_Projectiles";
import { worldConfig } from "../config/Config_World";
import { DamageType } from "../data/Data_Items";

export class SharedProjectile implements IGameEntityBase, IGameEntityThatUpdates, IGameEntityWithColliders, IGameEntityThatMoves, IGameEntityWithOwner {
    public nid: NetworkEntityId = -1;
    public ntype: NType = NType.ProjectileEntity;
    public position: Vector = new Vector(0, 0);
    public velocity: Vector = new Vector(0, 0);
    public prevPosition: Vector = new Vector(0, 0);
    public isAlive: boolean;
    public currentCollisionGridCell: CollisionGridCell;

    public HitboxCollider: Circle = new Circle(new Vector(0, 0), 0);

    public owningEntityNid: NetworkEntityId;
    public spawnTrajectoryInRads: number = 0;
    public projectileLifeAccumulatorInSeconds: number = 0;
    public maxProjectileLifetimeInSeconds: number;
    public ToBeDestroyed: boolean = false;

    public damage: number;
    public damageType: DamageType;
    public speedPerSecond: number;
    public dataId: Projectile;
    public hasImpactPfx: boolean;
    public isAIOwnedProjectile: boolean;

    private collisionSystemRef: SharedCollisionSystem;

    public npcDamageModifier: number;
    public playerDamageModifier: number;

    public constructor(collisionSystemRef: SharedCollisionSystem, owningEntityNid: NetworkEntityId, startingXPosition: number, startingYPosition: number, spawnTrajectoryInRads: number, damage: number, damageType: DamageType, projectileDetails: EntityProjectileConfig, distanceMultiplier: number, playerDamageModifier: number, npcDamageModifier: number) {
        // console.log({ collisionSystemRef, owningEntityNid, startingXPosition, startingYPosition, spawnTrajectoryInRads, damage, damageType, projectileDetails });

        // console.log("projectile details in sharedprojectile constructor", projectileDetails);

        this.collisionSystemRef = collisionSystemRef;

        this.owningEntityNid = owningEntityNid;
        this.spawnTrajectoryInRads = spawnTrajectoryInRads;

        this.npcDamageModifier = npcDamageModifier;
        this.playerDamageModifier = playerDamageModifier;

        this.x = clampToWorldBounds(startingXPosition);
        this.y = clampToWorldBounds(startingYPosition);

        this.prevPosition.x = this.position.x;
        this.prevPosition.y = this.position.y;

        this.velocity.x = Math.cos(this.spawnTrajectoryInRads);
        this.velocity.y = Math.sin(this.spawnTrajectoryInRads);

        this.damage = damage;
        this.damageType = damageType;
        this.maxProjectileLifetimeInSeconds = projectileDetails.lifetimeInSeconds * distanceMultiplier;
        this.HitboxCollider.r = projectileDetails.colliderRadius;
        this.speedPerSecond = projectileDetails.speedPerSecond;
        this.dataId = projectileDetails.id;
        this.hasImpactPfx = projectileDetails.hasImpactPfx;

        const cappedVelocityMagnitude = Math.sqrt(this.velocity.x * this.velocity.x + this.velocity.y * this.velocity.y);

        if (cappedVelocityMagnitude !== 0) {
            this.velocity.x /= cappedVelocityMagnitude;
            this.velocity.y /= cappedVelocityMagnitude;
        }
    }

    public get x(): number {
        return this.position.x;
    }
    public set x(newX: number) {
        this.position.x = newX;
        this.HitboxCollider.pos.x = this.x;
    }
    public get y(): number {
        return this.position.y;
    }
    public set y(newY: number) {
        this.position.y = newY;
        this.HitboxCollider.pos.y = this.y;
    }
    public get prevX(): number {
        return this.prevPosition.x;
    }
    public set prevX(newX: number) {
        this.prevPosition.x = newX;
    }
    public get prevY(): number {
        return this.prevPosition.y;
    }
    public set prevY(newY: number) {
        this.prevPosition.y = newY;
    }

    public Move(deltaTime: number): void {
        this.prevX = this.x;
        this.prevY = this.y;
        this.x = clampToWorldBounds(this.x + this.velocity.x * (this.speedPerSecond * deltaTime));
        this.y = clampToWorldBounds(this.y + this.velocity.y * (this.speedPerSecond * deltaTime));

        this._applyMapCollisions();
    }

    private _applyMapCollisions() {
        const currentTileGridX = Math.floor(this.HitboxCollider.pos.x / worldConfig.TILE_SIZE);
        const currentTileGridY = Math.floor(this.HitboxCollider.pos.y / worldConfig.TILE_SIZE);

        for (const offset of SharedCollisionSystem.GridOffsets) {
            const [xOffset, yOffset] = offset;

            const relevantTileGridX = currentTileGridX + xOffset;
            const relevantTileGridY = currentTileGridY + yOffset;

            // Don't include tiles outside of the world
            if (relevantTileGridX > worldConfig.WORLD_TILE_DIMENSIONS - 1 || relevantTileGridY > worldConfig.WORLD_TILE_DIMENSIONS - 1 || relevantTileGridX < 0 || relevantTileGridY < 0) {
                // console.log("adjacent x (", relevantTileGridX, ") or adjacent y (", relevantTileGridY, ") is out of bounds");
                continue;
            }

            const collisionPolygon = this.collisionSystemRef.GetCollisionPolygonAtGridXY(relevantTileGridX, relevantTileGridY);

            // @ts-ignore TODO: hack
            if (collisionPolygon && collisionPolygon.blocksProjectiles) {
                const collisionResponse = this.collisionSystemRef.PerformOneOffCirclePolygonCollisionCheck(this.HitboxCollider, collisionPolygon);

                if (collisionResponse) {
                    this.ToBeDestroyed = true;
                }
            } else {
                continue;
            }
        }
    }

    public Update(__deltaTime: number): void {}
}

import React, { useState } from "react";
import { Screens, UI_LoginEmail, UI_SwapScreen, playerIdentityStateAtom } from "../framework/UI_State";
import { useRecoilValue } from "recoil";
import { t } from "../../../shared/data/Data_I18N";
import { clientIsInIframe } from "../../../shared/SharedUtils";

export const Login = () => {
    const { loadingPlayerIdentity } = useRecoilValue(playerIdentityStateAtom);

    const [emailAddressValue, setEmailAddressValue] = useState("");
    const [passwordValue, setPasswordValue] = useState("");

    const onEmailAddressInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmailAddressValue(e.target.value);
    };

    const onPasswordInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPasswordValue(e.target.value);
    };

    return (
        <>
            <div className="login-panel animate__animated animate__fadeInUp animate__faster">
                <div className="login-header">{t("login__header")}</div>

                {clientIsInIframe() === false && (
                    <>
                        <div className="login-socials">
                            <a className="social-btn discord-oauth-btn" href={process.env.OAUTH_DISCORD_ADDRESS}>
                                {t("signin_sign_in_with_discord_btn")}
                            </a>
                            <a className="social-btn google-oauth-btn" href={process.env.OAUTH_GOOGLE_ADDRESS}>
                                {t("signin_sign_in_with_google_btn")}
                            </a>
                        </div>

                        <div className="login-or-separator">{t("register__or")}</div>
                    </>
                )}

                <div className="login-form">
                    {/* {errorNodes.length > 0 && <div className="errors">{errorNodes}</div>} */}

                    <div className="form-row-wrapper">
                        <div className="form-label">
                            <p>{t("register__email")}</p>
                        </div>
                        <div className="form-input">
                            <input className="login-input" type="text" value={emailAddressValue} onChange={onEmailAddressInputChanged} disabled={loadingPlayerIdentity} placeholder={t("register__email_placeholder")} />
                        </div>
                    </div>

                    <div className="form-row-wrapper">
                        <div className="form-label">
                            <p>{t("register__password")}</p>
                        </div>
                        <div className="form-input">
                            <input className="login-input" type="password" value={passwordValue} onChange={onPasswordInputChanged} disabled={loadingPlayerIdentity} placeholder={""} />
                        </div>
                    </div>
                </div>

                <div className="login-actions">
                    <button
                        className="neutral-button"
                        onClick={() => {
                            UI_SwapScreen(Screens.MainMenu);
                        }}
                    >
                        {t("change_username__back")}
                    </button>

                    <button
                        style={{ width: "225px" }}
                        disabled={loadingPlayerIdentity}
                        className="login-btn affirmative-button"
                        onClick={() => {
                            console.log("Signing in with values:");
                            UI_LoginEmail(emailAddressValue, passwordValue);
                        }}
                    >
                        {t("signin_sign_in")}
                    </button>
                </div>
            </div>
        </>
    );
};

import { cameraConfig } from "../../shared/config/Config_Camera";
import { GameplaySystem } from "../../shared/engine/SharedGameplaySystem";
import debounce from "lodash.debounce";

export class GameResizer extends GameplaySystem {
    private dragHandle: HTMLElement;
    private gameCanvasRef: HTMLCanvasElement;
    private gameContainerRef: HTMLElement;
    private isDragging: boolean = false;
    private dragStartX: number = 0;
    private dragStartY: number = 0;
    private initialGameContainerWidth: number = 0;
    private initialGameContainerHeight: number = 0;
    private lastCommunicatedViewHeight: number;
    private lastCommumicatedViewWidth: number;

    public constructor() {
        super();

        const gameContainer = document.getElementById("game-container") as HTMLElement;

        this.gameContainerRef = gameContainer;
        this.dragHandle = this.gameContainerRef.querySelector(".drag-handle") as HTMLElement;
        this.gameCanvasRef = document.getElementById("game-canvas") as HTMLCanvasElement;
    }

    protected override getSystemName(): string {
        return "Resizer";
    }

    public Initialize(): void {
        this._forceCanvasToMatchContainerSize();

        const { scaledWidth, scaledHeight } = this.GetVisibleWorldBoundsWithCameraScale();
        this.lastCommumicatedViewWidth = scaledWidth;
        this.lastCommunicatedViewHeight = scaledHeight;

        // Add an event listener to the drag handle to detect when the user clicks and starts dragging
        this.dragHandle.addEventListener("mousedown", (e: MouseEvent) => {
            this.LogVerbose("Mouse down of drag handle was fired");
            // Set the isDragging variable to true
            this.isDragging = true;
            // Store the starting position of the mouse and the starting size of the game container
            this.dragStartX = e.pageX;
            this.dragStartY = e.pageY;
            this.initialGameContainerWidth = this._getGameContainerWidth();
            this.initialGameContainerHeight = this._getGameContainerHeight();
            // Prevent default browser behavior (e.g. highlighting text) while dragging
            e.preventDefault();
        });

        // Add an event listener to the document to detect when the user stops dragging
        // Frustratingly, this event needs to listen on document specifically, because if you release your mouse off of
        // the element that started the drag, it doesnt fire the mouse up event. Stupid!
        document.addEventListener("mouseup", () => {
            this.LogVerbose("Mouse up of drag handle was fired");
            if (this.isDragging === true) {
                // Set the isDragging variable to false
                this.isDragging = false;
            }
        });

        // Add an event listener to the document to detect when the user is dragging
        document.addEventListener("mousemove", (e: MouseEvent) => {
            // Check if the user is currently dragging the drag handle
            if (this.isDragging) {
                this.LogVerbose("Mouse move of drag handle was fired");
                // Calculate the difference between the current position of the mouse and the starting position
                const deltaX = e.pageX - this.dragStartX;
                const deltaY = e.pageY - this.dragStartY;
                // Set the new size and position of the game container and canvas element based on the starting size and position plus the difference
                this.gameContainerRef.style.width = this.initialGameContainerWidth + deltaX + "px";
                this.gameContainerRef.style.height = this.initialGameContainerHeight + deltaY + "px";

                this._forceCanvasToMatchContainerSize();

                Game.EmitEvent("Resizer::RealtimeGameWindowResize", { newWidth: this._getGameContainerWidth(), newHeight: this._getGameContainerHeight() });
            }
        });

        document.addEventListener("mouseup", debounce(this._onGameWindowResized.bind(this), 100));

        window.onresize = debounce(this._onGameWindowResized.bind(this), 100);

        this.LogInfo("Ready!");
    }

    public GetVisibleWorldBoundsWithCameraScale(): { width: number; height: number; scaledWidth: number; scaledHeight: number } {
        const canvasDomWidth = parseInt(this.gameCanvasRef.style.width, 10);
        const canvasDomHeight = parseInt(this.gameCanvasRef.style.height, 10);

        return {
            width: canvasDomWidth,
            height: canvasDomHeight,
            scaledWidth: Math.ceil(canvasDomWidth / Game.Renderer.CameraZoomLevel),
            scaledHeight: Math.ceil(canvasDomHeight / Game.Renderer.CameraZoomLevel)
        };
    }

    private _forceCanvasToMatchContainerSize(): void {
        this.gameCanvasRef.style.width = this._getGameContainerWidth() + "px";
        this.gameCanvasRef.style.height = this._getGameContainerHeight() + "px";
    }

    private _getGameContainerWidth(): number {
        return parseInt(window.getComputedStyle(this.gameContainerRef).width, 10);
    }

    private _getGameContainerHeight(): number {
        return parseInt(window.getComputedStyle(this.gameContainerRef).height, 10);
    }

    private _onGameWindowResized(): void {
        this._forceCanvasToMatchContainerSize();

        const { width, height, scaledWidth, scaledHeight } = this.GetVisibleWorldBoundsWithCameraScale();

        if (scaledHeight !== this.lastCommunicatedViewHeight || scaledWidth !== this.lastCommumicatedViewWidth) {
            this.lastCommunicatedViewHeight = scaledHeight;
            this.lastCommumicatedViewWidth = scaledWidth;
            Game.EmitEvent("Resizer::GameWindowResizeComplete", { newWidth: width, newHeight: height, newScaledWidth: scaledWidth, newScaledHeight: scaledHeight });
        }
    }

    public Update(__deltaTime: number): void {}

    public Cleanup(): void {
        this.LogInfo("Cleaning up...");
    }
}

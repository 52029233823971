export const cameraConfig = {
    FOLLOW_SPEED: 0.1,
    FOLLOW_ACCELERATION: 1,
    FOLLOW_RADIUS: 10,
    DEFAULT_CAMERA_ZOOM: 3,

    DEBUG: {
        ALLOW_ZOOM: false,
        DEFAULT_MEGA_ZOOMED_OUT: false
    }
};

import React from "react";
import { ItemSlot, SlotVisualOptions } from "./UI_ItemSlot";
import { Item, getItemData } from "../../../shared/data/Data_Items";
import { useRecoilValue } from "recoil";
import { activeWeaponSlotStateAtom, extractionSuccessfulStateAtom, loadoutChargesStateAtom, loadoutCooldownStateAtom, playerLoadoutStateAtom, youDiedStateAtom } from "../framework/UI_State";
import { LoadoutSlots } from "../../../shared/systems/SharedGearAndWeaponSystem";
import { EmptyItemSlot } from "./UI_EmptyItemSlot";
import { ItemType } from "../../../shared/data/Data_Items";
import { getWeaponData } from "../../../shared/data/Data_Weapons";
import { t } from "../../../shared/data/Data_I18N";

export const GearAndWeaponHUD = () => {
    const { show: playerHasDied } = useRecoilValue(youDiedStateAtom);
    const { show: playerHasExtracted } = useRecoilValue(extractionSuccessfulStateAtom);
    const activeWeaponSlot = useRecoilValue(activeWeaponSlotStateAtom);
    const { loadout } = useRecoilValue(playerLoadoutStateAtom);
    const { weaponOne, weaponTwo, gearOne, gearTwo, gearThree } = loadout;
    const { weaponOneCooldown, weaponTwoCooldown, gearOneCooldown, gearTwoCooldown, gearThreeCooldown } = useRecoilValue(loadoutCooldownStateAtom);
    const { gearOneCharges, gearTwoCharges, gearThreeCharges } = useRecoilValue(loadoutChargesStateAtom);

    const gearOneIsPassive = gearOne === Item.None ? true : getItemData(gearOne)!.itemType === ItemType.PassiveGear;
    const gearTwoIsPassive = gearTwo === Item.None ? true : getItemData(gearTwo)!.itemType === ItemType.PassiveGear;
    const gearThreeIsPassive = gearThree === Item.None ? true : getItemData(gearThree)!.itemType === ItemType.PassiveGear;

    const weaponOneIsOnCooldown = weaponOneCooldown < 100;
    const weaponTwoIsOnCooldown = weaponTwoCooldown < 100;
    const gearOneIsOnCooldown = gearOneCooldown < 100;
    const gearTwoIsOnCooldown = gearTwoCooldown < 100;
    const gearThreeIsOnCooldown = gearThreeCooldown < 100;

    // console.log(gearOneCharges, gearTwoCharges, gearThreeCharges);

    const gearOneHasInfiniteCharges = gearOneCharges === -1;
    const gearTwoHasInfiniteCharges = gearTwoCharges === -1;
    const gearThreeHasInfiniteCharges = gearThreeCharges === -1;

    const weaponOneHasLongCooldown = weaponOne === Item.None ? false : getWeaponData(weaponOne)!.cooldownInSeconds > 0.75;
    const weaponTwoHasLongCooldown = weaponTwo === Item.None ? false : getWeaponData(weaponTwo)!.cooldownInSeconds > 0.75;

    const gearOneMaxCharges = gearOne === Item.None ? 0 : getItemData(gearOne)!.activeAbility.abilityCharges;
    const gearTwoMaxCharges = gearTwo === Item.None ? 0 : getItemData(gearTwo)!.activeAbility.abilityCharges;
    const gearThreeMaxCharges = gearThree === Item.None ? 0 : getItemData(gearThree)!.activeAbility.abilityCharges;

    return playerHasDied || playerHasExtracted ? (
        <></>
    ) : (
        <div className="gear-and-weapon-hud animate__animated animate__slideInUp">
            <div className="weapon-slots">
                <div className={`weapon-one ${weaponOneIsOnCooldown ? "on-cooldown" : ""} ${activeWeaponSlot === LoadoutSlots.WeaponOne ? "active" : "inactive"}`}>
                    <p className="hotkey hotkey-1">1</p>
                    {weaponOne === Item.None ? <EmptyItemSlot slotVisualType={SlotVisualOptions.Weapon} slotSize="large"></EmptyItemSlot> : <ItemSlot itemId={weaponOne} slotVisualType={SlotVisualOptions.Weapon} OnClickFn={() => {}} slotSize="large" />}
                    {activeWeaponSlot === LoadoutSlots.WeaponOne && weaponOneIsOnCooldown && weaponOneHasLongCooldown && (
                        <p className="cooldown">
                            <span>{t("game_hud__cooldown")}...</span>
                            {weaponOneCooldown}%
                        </p>
                    )}
                </div>
                <div className={`weapon-two ${weaponTwoIsOnCooldown ? "on-cooldown" : ""} ${activeWeaponSlot === LoadoutSlots.WeaponTwo ? "active" : "inactive"}`}>
                    <p className="hotkey hotkey-2">2</p>
                    {weaponTwo === Item.None ? <EmptyItemSlot slotVisualType={SlotVisualOptions.Weapon} slotSize="large"></EmptyItemSlot> : <ItemSlot itemId={weaponTwo} slotVisualType={SlotVisualOptions.Weapon} OnClickFn={() => {}} slotSize="large" />}
                    {activeWeaponSlot === LoadoutSlots.WeaponTwo && weaponTwoIsOnCooldown && weaponTwoHasLongCooldown && (
                        <p className="cooldown">
                            <span>{t("game_hud__cooldown")}...</span>
                            {weaponTwoCooldown}%
                        </p>
                    )}
                </div>
            </div>
            <div className="gear-slots">
                <div
                    className={`hud-gear-slot gear-slot-one ${gearOneIsOnCooldown ? "on-cooldown" : ""} ${gearOneCharges === 0 ? "on-cooldown" : ""}`}
                    onClick={() => {
                        console.log("Clicked gear hud #1");
                        Game.Input.clickedGearOneThisFrame = true;
                    }}
                >
                    <p className="hotkey hotkey-q">{gearOne !== Item.None && "3"}</p>
                    {gearOne === Item.None ? <EmptyItemSlot slotVisualType={SlotVisualOptions.Gear}></EmptyItemSlot> : <ItemSlot itemId={gearOne} slotVisualType={SlotVisualOptions.Gear} OnClickFn={() => {}} />}
                    {gearOneIsOnCooldown && <p className="cooldown">{gearOneCooldown}%</p>}
                    {gearOneIsOnCooldown === false && gearOneHasInfiniteCharges === false && (
                        <p className="charges">
                            {gearOneCharges}/{gearOneMaxCharges}
                        </p>
                    )}
                </div>
                <div
                    className={`hud-gear-slot gear-slot-two ${gearTwoIsOnCooldown ? "on-cooldown" : ""} ${gearTwoCharges === 0 ? "on-cooldown" : ""}`}
                    onClick={() => {
                        console.log("Clicked gear hud #2");
                        Game.Input.clickedGearTwoThisFrame = true;
                    }}
                >
                    <p className="hotkey hotkey-e">{gearTwo !== Item.None && "4"}</p>
                    {gearTwo === Item.None ? <EmptyItemSlot slotVisualType={SlotVisualOptions.Gear}></EmptyItemSlot> : <ItemSlot itemId={gearTwo} slotVisualType={SlotVisualOptions.Gear} OnClickFn={() => {}} />}
                    {gearTwoIsOnCooldown && <p className="cooldown">{gearTwoCooldown}%</p>}
                    {gearTwoIsOnCooldown === false && gearTwoHasInfiniteCharges === false && (
                        <p className="charges">
                            {gearTwoCharges}/{gearTwoMaxCharges}
                        </p>
                    )}
                </div>
                <div
                    className={`hud-gear-slot gear-slot-three ${gearThreeIsOnCooldown ? "on-cooldown" : ""} ${gearThreeCharges === 0 ? "on-cooldown" : ""}`}
                    onClick={() => {
                        console.log("Clicked gear hud #3");
                        Game.Input.clickedGearThreeThisFrame = true;
                    }}
                >
                    <p className="hotkey hotkey-space">{gearThree !== Item.None && "5"}</p>
                    {gearThree === Item.None ? <EmptyItemSlot slotVisualType={SlotVisualOptions.Gear}></EmptyItemSlot> : <ItemSlot itemId={gearThree} slotVisualType={SlotVisualOptions.Gear} OnClickFn={() => {}} />}
                    {gearThreeIsOnCooldown && <p className="cooldown">{gearThreeCooldown}%</p>}
                    {gearThreeIsOnCooldown === false && gearThreeHasInfiniteCharges === false && (
                        <p className="charges">
                            {gearThreeCharges}/{gearThreeMaxCharges}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

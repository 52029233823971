export enum CollisionBroadphaseMode {
    SPATIAL_GRID = 0,
    QUADRATIC = 1
}

export const collisionConfig = {
    GRID_CELL_SIZE: 160,
    COLLISION_BROADPHASE_MODE: CollisionBroadphaseMode.SPATIAL_GRID,
    ENABLE_CCD: false,

    DEBUG: {
        DRAW_BROADPHASE_SPATIAL_GRID: false,
        DRAW_COLLISION_TILE_LAYER: false
    }
};

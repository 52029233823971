import React from "react";
import { Screens, UI_GetXsollaLinkForSku, UI_SwapScreen, playerIdentityStateAtom, selectedBundleStateAtom } from "../framework/UI_State";
import { t } from "../../../shared/data/Data_I18N";
import { useRecoilValue } from "recoil";
import { CharacterSkinSlot } from "../components/UI_CharacterSkinSlot";
import { CharacterSkin } from "../../../shared/data/Data_Cosmetics";
import { UserType } from "../../../shared/SharedTypes";

export const BundleDetails = () => {
    const selectedBundle = useRecoilValue(selectedBundleStateAtom);
    const { userDetails, userType } = useRecoilValue(playerIdentityStateAtom);
    const { PVPZ_PurchasedSKUs } = userDetails;

    console.log("selected bundle: ", selectedBundle);

    let sku: string;

    let priceInUSD: number = 0;

    let alreadyOwnThisBundle = false;

    if (selectedBundle === "welcome") {
        sku = "91b9536f-321b-4802-9a8c-3644c1c1bfbf";
        priceInUSD = 5;
    } else if (selectedBundle === "supporter") {
        sku = "6caa849c-c514-4a41-bbf4-c4170022e48a";
        priceInUSD = 25;
    } else if (selectedBundle === "founder") {
        sku = "81d969c4-371d-4c64-9345-808f9fbf2cad";
        priceInUSD = 50;
    }

    if (PVPZ_PurchasedSKUs.includes(sku)) {
        alreadyOwnThisBundle = true;
    }

    return (
        <>
            <div className="bundle-details-panel animate__animated animate__fadeInUp animate__faster">
                <div className="bundle-details-header">Bundles</div>

                {selectedBundle === "supporter" && (
                    <>
                        <h3 className="bundle-title">Supporter Bundle</h3>
                        <p className="bundle-description">Greatly support the development of PvPz.io by purchasing a supporter bundle! This bundle includes...</p>
                        <ul className="bundle-features">
                            <li>Three character skins (see below)</li>
                            <li>1000 Gems</li>
                            <li>
                                A special colored name in-game (<span className="rare-blue">rare blue</span>)
                            </li>
                            <li>
                                A special PvPz.io{" "}
                                <a href="https://discord.com/invite/nfmg9mz8GQ" target="_blank" rel="noreferrer noopener">
                                    Discord
                                </a>{" "}
                                Server role
                            </li>
                        </ul>
                        <div className="skins">
                            <CharacterSkinSlot selected={false} OnClickFn={() => {}} skin={CharacterSkin.AdeptArcher} />

                            <CharacterSkinSlot selected={false} OnClickFn={() => {}} skin={CharacterSkin.WealthyAssassin} />

                            <CharacterSkinSlot selected={false} OnClickFn={() => {}} skin={CharacterSkin.FallenWizardsApprentice} />
                        </div>
                    </>
                )}

                {selectedBundle === "founder" && (
                    <>
                        <h3 className="bundle-title">Founder Bundle</h3>
                        <p className="bundle-description">Tremendously support the development of PvPz.io by purchasing a founder bundle! This bundle includes...</p>
                        <ul className="bundle-features">
                            <li>Five character skins (see below)</li>
                            <li>2000 Gems</li>
                            <li>
                                A special colored name in-game (<span className="epic-purple">epic purple</span>)
                            </li>
                            <li>A special PvPz.io Discord Server role</li>
                            <li>
                                Access to a private founders-only{" "}
                                <a href="https://discord.com/invite/nfmg9mz8GQ" target="_blank" rel="noreferrer noopener">
                                    Discord
                                </a>{" "}
                                channel to help shape the direction of the game
                            </li>
                        </ul>
                        <div className="skins">
                            <CharacterSkinSlot selected={false} OnClickFn={() => {}} skin={CharacterSkin.HeroicKnight} />

                            <CharacterSkinSlot selected={false} OnClickFn={() => {}} skin={CharacterSkin.RadiantKnight} />

                            <CharacterSkinSlot selected={false} OnClickFn={() => {}} skin={CharacterSkin.Coldsteel_Knight} />

                            <CharacterSkinSlot selected={false} OnClickFn={() => {}} skin={CharacterSkin.CursedCorruptedWizard} />

                            <CharacterSkinSlot selected={false} OnClickFn={() => {}} skin={CharacterSkin.CherryBlossomBoltman} />
                        </div>
                    </>
                )}

                {selectedBundle === "welcome" && (
                    <>
                        <h3 className="bundle-title">Welcome Bundle</h3>
                        <p className="bundle-description">Support the development of PvPz.io by purchasing a welcome bundle! This bundle includes...</p>
                        <ul className="bundle-features">
                            <li>One character skin (see below)</li>
                            <li>980 Gems</li>
                        </ul>
                        <div className="skins">
                            <CharacterSkinSlot selected={false} OnClickFn={() => {}} skin={CharacterSkin.Estranged_Wizard} />
                        </div>
                    </>
                )}

                {userType === UserType.Anonymous && (
                    <>
                        <p className="not-registered">Sorry, only registered accounts can buy things from the store! Create an account and try again!</p>
                    </>
                )}

                {userType === UserType.Registered && alreadyOwnThisBundle === true && (
                    <>
                        <p className="not-registered">You already own this!</p>
                    </>
                )}

                {userType === UserType.Registered && alreadyOwnThisBundle === false && (
                    <>
                        <button
                            className="affirmative-button"
                            style={{ width: "475px" }}
                            onClick={() => {
                                UI_GetXsollaLinkForSku(sku);
                            }}
                        >
                            Buy Bundle - ${priceInUSD} USD
                        </button>
                    </>
                )}

                <button
                    className="neutral-button"
                    onClick={() => {
                        UI_SwapScreen(Screens.Shop);
                    }}
                >
                    {t("stats__back_btn")}
                </button>
            </div>
        </>
    );
};

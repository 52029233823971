import { pickRandom } from "../SharedUtils";

export enum CharacterSkin {
    None = -1,
    Wizard_Apprentice = 0,
    Wizard_Corrupted = 1,
    Wizard_Veteran = 2,
    Boltman = 3,
    Knight = 4,
    Thief = 5,
    NobleKnight = 6,
    Assassin = 7,
    AdeptArcher = 8,
    ForestArcher = 9,
    CherryBlossomBoltman = 10,
    Coldsteel_Knight = 11,
    CursedCorruptedWizard = 12,
    FallenWizardsApprentice = 13,
    HeroicKnight = 14,
    MidnightThief = 15,
    RadiantKnight = 16,
    VengefulKnight = 17,
    WealthyAssassin = 18,
    Estranged_Wizard = 19
}

export const pickRandomCharacterSkin = (): CharacterSkin => {
    return pickRandom([CharacterSkin.Wizard_Apprentice, CharacterSkin.Wizard_Corrupted, CharacterSkin.Wizard_Veteran, CharacterSkin.Boltman, CharacterSkin.Knight, CharacterSkin.Thief]);
};

interface CharacterSkinConfig {
    friendlyName: string;
    staticSpriteId: string;
    idleFramesSpriteId: string;
    walkFramesSpriteId: string;
    spriteYOffset: number;
}

export const CharacterSkinData: Map<CharacterSkin, CharacterSkinConfig> = new Map();

CharacterSkinData.set(CharacterSkin.Wizard_Apprentice, {
    friendlyName: "Wizards Apprentice",
    staticSpriteId: "sprites/player_skins/ANIM_wiz001_base.png",
    idleFramesSpriteId: "player_skins/ANIM_wiz001_idle",
    walkFramesSpriteId: "player_skins/ANIM_wiz001_walk",
    spriteYOffset: 0
});

CharacterSkinData.set(CharacterSkin.Wizard_Corrupted, {
    friendlyName: "Corrupted Wizard",
    staticSpriteId: "sprites/player_skins/ANIM_wiz002_base.png",
    idleFramesSpriteId: "player_skins/ANIM_wiz002_idle",
    walkFramesSpriteId: "player_skins/ANIM_wiz002_walk",
    spriteYOffset: 0
});

CharacterSkinData.set(CharacterSkin.Wizard_Veteran, {
    friendlyName: "Veteran Scholar",
    staticSpriteId: "sprites/player_skins/ANIM_wiz003_base.png",
    idleFramesSpriteId: "player_skins/ANIM_wiz003_idle",
    walkFramesSpriteId: "player_skins/ANIM_wiz003_walk",
    spriteYOffset: -4
});

CharacterSkinData.set(CharacterSkin.Boltman, {
    friendlyName: "Boltman",
    staticSpriteId: "sprites/player_skins/ANIM_boltmanGreen_base.png",
    idleFramesSpriteId: "player_skins/ANIM_boltmanGreen_idle",
    walkFramesSpriteId: "player_skins/ANIM_boltmanGreen_walk",
    spriteYOffset: 0
});

CharacterSkinData.set(CharacterSkin.Knight, {
    friendlyName: "Knight",
    staticSpriteId: "sprites/player_skins/ANIM_knightSteel_base.png",
    idleFramesSpriteId: "player_skins/ANIM_knightSteel_idle",
    walkFramesSpriteId: "player_skins/ANIM_knightSteel_walk",
    spriteYOffset: 0
});

CharacterSkinData.set(CharacterSkin.Thief, {
    friendlyName: "Thief",
    staticSpriteId: "sprites/player_skins/ANIM_thiefBlack_base.png",
    idleFramesSpriteId: "player_skins/ANIM_thiefBlack_idle",
    walkFramesSpriteId: "player_skins/ANIM_thiefBlack_walk",
    spriteYOffset: 0
});

CharacterSkinData.set(CharacterSkin.NobleKnight, {
    friendlyName: "Noble Knight",
    staticSpriteId: "sprites/player_skins/newknight_idle1.png",
    idleFramesSpriteId: "player_skins/newknight_idle",
    walkFramesSpriteId: "player_skins/newknight_walk",
    spriteYOffset: 0
});

CharacterSkinData.set(CharacterSkin.AdeptArcher, {
    friendlyName: "Adept Archer",
    staticSpriteId: "sprites/player_skins/ANIM_AdeptArcher_idle001.png",
    idleFramesSpriteId: "player_skins/ANIM_AdeptArcher_idle",
    walkFramesSpriteId: "player_skins/ANIM_AdeptArcher_walk",
    spriteYOffset: 0
});

CharacterSkinData.set(CharacterSkin.ForestArcher, {
    friendlyName: "Forest Archer",
    staticSpriteId: "sprites/player_skins/ANIM_Archer_idle001.png",
    idleFramesSpriteId: "player_skins/ANIM_Archer_idle",
    walkFramesSpriteId: "player_skins/ANIM_Archer_walk",
    spriteYOffset: 0
});

CharacterSkinData.set(CharacterSkin.Assassin, {
    friendlyName: "Assassin",
    staticSpriteId: "sprites/player_skins/ANIM_Assassin_idle001.png",
    idleFramesSpriteId: "player_skins/ANIM_Assassin_idle",
    walkFramesSpriteId: "player_skins/ANIM_Assassin_walk",
    spriteYOffset: 0
});

CharacterSkinData.set(CharacterSkin.CherryBlossomBoltman, {
    friendlyName: "Cherry Blossom Boltman",
    staticSpriteId: "sprites/player_skins/ANIM_boltmanCherryBlossom_idle001.png",
    idleFramesSpriteId: "player_skins/ANIM_boltmanCherryBlossom_idle",
    walkFramesSpriteId: "player_skins/ANIM_boltmanCherryBlossom_walk",
    spriteYOffset: 0
});

CharacterSkinData.set(CharacterSkin.Coldsteel_Knight, {
    friendlyName: "Coldsteel Knight",
    staticSpriteId: "sprites/player_skins/ANIM_ColdsteelKnight_idle001.png",
    idleFramesSpriteId: "player_skins/ANIM_ColdsteelKnight_idle",
    walkFramesSpriteId: "player_skins/ANIM_ColdsteelKnight_walk",
    spriteYOffset: 0
});

CharacterSkinData.set(CharacterSkin.CursedCorruptedWizard, {
    friendlyName: "Cursed Corrupted Wizard",
    staticSpriteId: "sprites/player_skins/ANIM_CursedCorruptedWizard_idle001.png",
    idleFramesSpriteId: "player_skins/ANIM_CursedCorruptedWizard_idle",
    walkFramesSpriteId: "player_skins/ANIM_CursedCorruptedWizard_walk",
    spriteYOffset: 0
});

CharacterSkinData.set(CharacterSkin.FallenWizardsApprentice, {
    friendlyName: "Fallen Wizards Apprentice",
    staticSpriteId: "sprites/player_skins/ANIM_FallenWizardsApprentice_idle001.png",
    idleFramesSpriteId: "player_skins/ANIM_FallenWizardsApprentice_idle",
    walkFramesSpriteId: "player_skins/ANIM_FallenWizardsApprentice_walk",
    spriteYOffset: 0
});

CharacterSkinData.set(CharacterSkin.HeroicKnight, {
    friendlyName: "Heroic Knight",
    staticSpriteId: "sprites/player_skins/ANIM_HeroicKnight_idle001.png",
    idleFramesSpriteId: "player_skins/ANIM_HeroicKnight_idle",
    walkFramesSpriteId: "player_skins/ANIM_HeroicKnight_walk",
    spriteYOffset: 0
});

CharacterSkinData.set(CharacterSkin.MidnightThief, {
    friendlyName: "Midnight Thief",
    staticSpriteId: "sprites/player_skins/ANIM_MidnightThief_idle001.png",
    idleFramesSpriteId: "player_skins/ANIM_MidnightThief_idle",
    walkFramesSpriteId: "player_skins/ANIM_MidnightThief_walk",
    spriteYOffset: 0
});

CharacterSkinData.set(CharacterSkin.RadiantKnight, {
    friendlyName: "Radiant Knight",
    staticSpriteId: "sprites/player_skins/ANIM_RadiantKnight_idle001.png",
    idleFramesSpriteId: "player_skins/ANIM_RadiantKnight_idle",
    walkFramesSpriteId: "player_skins/ANIM_RadiantKnight_walk",
    spriteYOffset: 0
});

CharacterSkinData.set(CharacterSkin.VengefulKnight, {
    friendlyName: "Vengeful Knight",
    staticSpriteId: "sprites/player_skins/ANIM_VengefulKnight_idle001.png",
    idleFramesSpriteId: "player_skins/ANIM_VengefulKnight_idle",
    walkFramesSpriteId: "player_skins/ANIM_VengefulKnight_walk",
    spriteYOffset: 0
});

CharacterSkinData.set(CharacterSkin.WealthyAssassin, {
    friendlyName: "Wealthy Assassin",
    staticSpriteId: "sprites/player_skins/ANIM_WealthyAssassin_idle001.png",
    idleFramesSpriteId: "player_skins/ANIM_WealthyAssassin_idle",
    walkFramesSpriteId: "player_skins/ANIM_WealthyAssassin_walk",
    spriteYOffset: 0
});

CharacterSkinData.set(CharacterSkin.Estranged_Wizard, {
    friendlyName: "Estranged Wizard",
    staticSpriteId: "sprites/player_skins/Wizard_idle1.png",
    idleFramesSpriteId: "player_skins/Wizard_idle",
    walkFramesSpriteId: "player_skins/Wizard_walk",
    spriteYOffset: 0
});

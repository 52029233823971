/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";

export const RightContainer = React.memo(
    () => {
        console.log("Render of right container called!");

        Game.Rewards.ShowInGameRight();

        // const screenHeight = window.innerHeight;
        // const shouldUseSmallContainers = screenHeight <= 600;

        const styles = {
            border: window.location.href.includes("localhost") ? "1px solid red" : "none",
            display: "flex",
            width: "160px",
            height: "600px"
        };

        return (
            <div className="right-container">
                <div className="right-container-inner" id="pvpz-io_160x600_2" style={styles}></div>
            </div>
        );
    },
    (__prepProps, __nextProps) => true
);

import React, { useState } from "react";
import { Screens, UI_EquipItemToSlot, UI_EquipSkin, UI_InsureSlot, UI_SetLoadoutSelectionMode, UI_SetSelectedBundle, UI_SetSelectedSkinToPurchase, UI_SwapScreen, justGearFromStashSelector, justWeaponsFromStashSelector, playerIdentityStateAtom, playerLoadoutStateAtom } from "../framework/UI_State";
import { ItemSlot, SlotVisualOptions } from "../components/UI_ItemSlot";
import { useRecoilValue } from "recoil";
import { CharacterSkinSlot } from "../components/UI_CharacterSkinSlot";
import { ItemContainer } from "../components/UI_ItemContainer";
import { LoadoutSlots } from "../../../shared/systems/SharedGearAndWeaponSystem";
import { EmptyItemSlot } from "../components/UI_EmptyItemSlot";
import { ActiveAbility, DamageType, Item, ItemRarity, ItemType, PassiveStatType, getItemData, getMaxHealthAdjustmentFromLoadout, getPassiveStatAggregateInLoadout } from "../../../shared/data/Data_Items";
import { CharacterSkin } from "../../../shared/data/Data_Cosmetics";
// @ts-ignore
import dividerImageSrc from "../kit/divider.png";
// @ts-ignore
import watchAdImgSrc from "../kit/watch_ad.png";
import { playerConfig } from "../../../shared/config/Config_Player";
import { t } from "../../../shared/data/Data_I18N";
import { UserType } from "../../../shared/SharedTypes";
import { PopToast, ToastStatuses } from "../kit/toast-utils";

export const getAbilitySummaryFromLoadout = (items: Item[]): string[] => {
    const abilitySummaries = [];
    for (let i = 0; i < items.length; i++) {
        if (items[i] === -1) continue;
        const itemConfig = getItemData(items[i]);

        if (itemConfig.itemType === ItemType.PassiveGear || itemConfig.itemType === ItemType.ActiveGear) {
            if (itemConfig.activeAbility.ability !== ActiveAbility.None || itemConfig.passiveAbilities.length > 0) {
                abilitySummaries.push(...t(itemConfig.gameplayText).split(" | "));
            }
        }
    }
    return abilitySummaries;
};

export const RunPrep = () => {
    const justWeaponsFromStash = useRecoilValue(justWeaponsFromStashSelector);
    const justGearFromStash = useRecoilValue(justGearFromStashSelector);

    console.log(useRecoilValue(playerIdentityStateAtom));

    const { userType, userDetails } = useRecoilValue(playerIdentityStateAtom);

    // @ts-ignore
    const { PVPZ_UnlockedSkins, PVPZ_Currency, PVPZ_PurchasedSKUs } = userDetails;
    const { insuranceCredits } = PVPZ_Currency;

    const { loadout, loadoutScreen, playerLoadoutOperationInProgress } = useRecoilValue(playerLoadoutStateAtom);
    const { selectionMode } = loadoutScreen;

    const [isFullscreen, setIsFullscreen] = useState(false);

    const currentUrl = window.location != window.parent.location ? document.referrer : document.location.href;

    const isOnCrazygames = currentUrl.includes("crazygames.com") || OriginalLocation?.includes("crazygames");

    const loadoutWeaponOne = playerConfig.DEBUG.LOADOUT_OVERRIDES.WEAPON_ONE === -1 ? loadout.weaponOne : playerConfig.DEBUG.LOADOUT_OVERRIDES.WEAPON_ONE;
    const loadoutWeaponTwo = playerConfig.DEBUG.LOADOUT_OVERRIDES.WEAPON_TWO === -1 ? loadout.weaponTwo : playerConfig.DEBUG.LOADOUT_OVERRIDES.WEAPON_TWO;
    const loadoutGearOne = playerConfig.DEBUG.LOADOUT_OVERRIDES.GEAR_ONE === -1 ? loadout.gearOne : playerConfig.DEBUG.LOADOUT_OVERRIDES.GEAR_ONE;
    const loadoutGearTwo = playerConfig.DEBUG.LOADOUT_OVERRIDES.GEAR_TWO === -1 ? loadout.gearTwo : playerConfig.DEBUG.LOADOUT_OVERRIDES.GEAR_TWO;
    const loadoutGearThree = playerConfig.DEBUG.LOADOUT_OVERRIDES.GEAR_THREE === -1 ? loadout.gearThree : playerConfig.DEBUG.LOADOUT_OVERRIDES.GEAR_THREE;

    const justItemsFromLoadout = [loadoutWeaponOne, loadoutWeaponTwo, loadoutGearOne, loadoutGearTwo, loadoutGearThree];

    const maxHealthWithBoostsFactoredIn = Math.ceil(100 + getMaxHealthAdjustmentFromLoadout(justItemsFromLoadout));

    const physicalDamageBoost = getPassiveStatAggregateInLoadout(PassiveStatType.DamageBoost, DamageType.Physical, justItemsFromLoadout, true);
    const fireDamageBoost = getPassiveStatAggregateInLoadout(PassiveStatType.DamageBoost, DamageType.Fire, justItemsFromLoadout, true);
    const toxicDamageBoost = getPassiveStatAggregateInLoadout(PassiveStatType.DamageBoost, DamageType.Toxic, justItemsFromLoadout, true);
    const arcaneDamageBoost = getPassiveStatAggregateInLoadout(PassiveStatType.DamageBoost, DamageType.Arcane, justItemsFromLoadout, true);
    const frostDamageBoost = getPassiveStatAggregateInLoadout(PassiveStatType.DamageBoost, DamageType.Frost, justItemsFromLoadout, true);
    const lightningDamageBoost = getPassiveStatAggregateInLoadout(PassiveStatType.DamageBoost, DamageType.Lightning, justItemsFromLoadout, true);
    const holyDamageBoost = getPassiveStatAggregateInLoadout(PassiveStatType.DamageBoost, DamageType.Holy, justItemsFromLoadout, true);
    const shadowDamageBoost = getPassiveStatAggregateInLoadout(PassiveStatType.DamageBoost, DamageType.Shadow, justItemsFromLoadout, true);
    const chaosDamageBoost = getPassiveStatAggregateInLoadout(PassiveStatType.DamageBoost, DamageType.Chaos, justItemsFromLoadout, true);
    const natureDamageBoost = getPassiveStatAggregateInLoadout(PassiveStatType.DamageBoost, DamageType.Nature, justItemsFromLoadout, true);
    const bleedDamageBoost = getPassiveStatAggregateInLoadout(PassiveStatType.DamageBoost, DamageType.Bleed, justItemsFromLoadout, true);

    const physicalDamageReduction = getPassiveStatAggregateInLoadout(PassiveStatType.Defence, DamageType.Physical, justItemsFromLoadout, true);
    const fireDamageReduction = getPassiveStatAggregateInLoadout(PassiveStatType.Defence, DamageType.Fire, justItemsFromLoadout, true);
    const toxicDamageReduction = getPassiveStatAggregateInLoadout(PassiveStatType.Defence, DamageType.Toxic, justItemsFromLoadout, true);
    const arcaneDamageReduction = getPassiveStatAggregateInLoadout(PassiveStatType.Defence, DamageType.Arcane, justItemsFromLoadout, true);
    const frostDamageReduction = getPassiveStatAggregateInLoadout(PassiveStatType.Defence, DamageType.Frost, justItemsFromLoadout, true);
    const lightningDamageReduction = getPassiveStatAggregateInLoadout(PassiveStatType.Defence, DamageType.Lightning, justItemsFromLoadout, true);
    const holyDamageReduction = getPassiveStatAggregateInLoadout(PassiveStatType.Defence, DamageType.Holy, justItemsFromLoadout, true);
    const shadowDamageReduction = getPassiveStatAggregateInLoadout(PassiveStatType.Defence, DamageType.Shadow, justItemsFromLoadout, true);
    const chaosDamageReduction = getPassiveStatAggregateInLoadout(PassiveStatType.Defence, DamageType.Chaos, justItemsFromLoadout, true);
    const natureDamageReduction = getPassiveStatAggregateInLoadout(PassiveStatType.Defence, DamageType.Nature, justItemsFromLoadout, true);
    const bleedDamageReduction = getPassiveStatAggregateInLoadout(PassiveStatType.Defence, DamageType.Bleed, justItemsFromLoadout, true);

    const allAbilityDescriptions = getAbilitySummaryFromLoadout(justItemsFromLoadout);

    const abilityDescriptionsDom =
        allAbilityDescriptions.length === 0 ? (
            <p className="no-abilities">{t("loadout_menu__no_abilities")}</p>
        ) : (
            allAbilityDescriptions.map((abilityDescription, __index) => {
                return (
                    <div className="ability" key={`ability-${__index}`}>
                        <p>&#8226; {abilityDescription}</p>
                    </div>
                );
            })
        );

    //#region Slot Visuals or Empty Slots
    const weaponSlotOne =
        loadoutWeaponOne === -1 ? (
            <EmptyItemSlot
                onClickFn={() => {
                    UI_SetLoadoutSelectionMode("weaponOne");
                }}
                slotVisualType={SlotVisualOptions.Weapon}
                slotSize="large"
                selected={selectionMode === "weaponOne"}
                showInsuredLock={loadout.insuredSlot === LoadoutSlots.WeaponOne}
            />
        ) : (
            <ItemSlot
                OnClickFn={() => {
                    UI_SetLoadoutSelectionMode("weaponOne");
                }}
                itemId={loadoutWeaponOne}
                slotVisualType={SlotVisualOptions.Weapon}
                slotSize="large"
                selected={selectionMode === "weaponOne"}
                showLeftClickEquipPrompt={false}
                showInsuredLock={loadout.insuredSlot === LoadoutSlots.WeaponOne}
            />
        );

    const weaponSlotTwo =
        loadoutWeaponTwo === -1 ? (
            <EmptyItemSlot
                onClickFn={() => {
                    UI_SetLoadoutSelectionMode("weaponTwo");
                }}
                slotVisualType={SlotVisualOptions.Weapon}
                slotSize="large"
                selected={selectionMode === "weaponTwo"}
                showInsuredLock={loadout.insuredSlot === LoadoutSlots.WeaponTwo}
            />
        ) : (
            <ItemSlot
                OnClickFn={() => {
                    UI_SetLoadoutSelectionMode("weaponTwo");
                }}
                itemId={loadoutWeaponTwo}
                slotVisualType={SlotVisualOptions.Weapon}
                slotSize="large"
                selected={selectionMode === "weaponTwo"}
                showLeftClickEquipPrompt={false}
                showInsuredLock={loadout.insuredSlot === LoadoutSlots.WeaponTwo}
            />
        );

    const gearSlotOne =
        loadoutGearOne === -1 ? (
            <EmptyItemSlot
                onClickFn={() => {
                    UI_SetLoadoutSelectionMode("gearOne");
                }}
                slotVisualType={SlotVisualOptions.Gear}
                slotSize="large"
                selected={selectionMode === "gearOne"}
                showInsuredLock={loadout.insuredSlot === LoadoutSlots.GearOne}
            />
        ) : (
            <ItemSlot
                OnClickFn={() => {
                    UI_SetLoadoutSelectionMode("gearOne");
                }}
                itemId={loadoutGearOne}
                slotVisualType={SlotVisualOptions.Gear}
                slotSize="large"
                selected={selectionMode === "gearOne"}
                showLeftClickEquipPrompt={false}
                showInsuredLock={loadout.insuredSlot === LoadoutSlots.GearOne}
            />
        );

    const gearSlotTwo =
        loadoutGearTwo === -1 ? (
            <EmptyItemSlot
                onClickFn={() => {
                    UI_SetLoadoutSelectionMode("gearTwo");
                }}
                slotVisualType={SlotVisualOptions.Gear}
                slotSize="large"
                selected={selectionMode === "gearTwo"}
                showInsuredLock={loadout.insuredSlot === LoadoutSlots.GearTwo}
            />
        ) : (
            <ItemSlot
                OnClickFn={() => {
                    UI_SetLoadoutSelectionMode("gearTwo");
                }}
                itemId={loadoutGearTwo}
                slotVisualType={SlotVisualOptions.Gear}
                slotSize="large"
                selected={selectionMode === "gearTwo"}
                showLeftClickEquipPrompt={false}
                showInsuredLock={loadout.insuredSlot === LoadoutSlots.GearTwo}
            />
        );

    const getInsuredItemName = (slot: LoadoutSlots) => {
        let insuredItem: Item;
        switch (slot) {
            case LoadoutSlots.WeaponOne:
                insuredItem = loadoutWeaponOne;
                break;
            case LoadoutSlots.WeaponTwo:
                insuredItem = loadoutWeaponTwo;
                break;
            case LoadoutSlots.GearOne:
                insuredItem = loadoutGearOne;
                break;
            case LoadoutSlots.GearTwo:
                insuredItem = loadoutGearTwo;
                break;
            case LoadoutSlots.GearThree:
                insuredItem = loadoutGearThree;
                break;
            default:
                insuredItem = -1;
                break;
        }
        if (insuredItem === -1) return t("insured_item_nothing");
        const itemConfig = getItemData(insuredItem);
        return <span className={`item-rarity-${ItemRarity[itemConfig.itemRarity].toLowerCase()}`}>{t(itemConfig.name)}</span>;
    };

    const gearSlotThree =
        loadoutGearThree === -1 ? (
            <EmptyItemSlot
                onClickFn={() => {
                    UI_SetLoadoutSelectionMode("gearThree");
                }}
                slotVisualType={SlotVisualOptions.Gear}
                slotSize="large"
                selected={selectionMode === "gearThree"}
                showInsuredLock={loadout.insuredSlot === LoadoutSlots.GearThree}
            />
        ) : (
            <ItemSlot
                OnClickFn={() => {
                    UI_SetLoadoutSelectionMode("gearThree");
                }}
                itemId={loadoutGearThree}
                slotVisualType={SlotVisualOptions.Gear}
                slotSize="large"
                selected={selectionMode === "gearThree"}
                showLeftClickEquipPrompt={false}
                showInsuredLock={loadout.insuredSlot === LoadoutSlots.GearThree}
            />
        );
    //#endregion

    const equipItemToSlot = (slot: LoadoutSlots, itemId: Item) => {
        // console.log(`equipping item ${Item[itemId]} to slot: ${slot}`);
        UI_EquipItemToSlot(slot, itemId);
    };

    const equipSkin = (skin: CharacterSkin) => {
        UI_EquipSkin(skin);

        // if (userType === UserType.Anonymous && (skin === CharacterSkin.NobleKnight || skin === CharacterSkin.Wizard_Corrupted || skin === CharacterSkin.Wizard_Veteran || skin === CharacterSkin.Boltman)) {
        //     PopToast({ status: ToastStatuses.ERROR, message: t("runprep__skin_not_unlocked_registered") });
        //     return;
        // }

        // if (userType === UserType.Anonymous) {
        //     if (skin === CharacterSkin.NobleKnight || skin === CharacterSkin.Wizard_Corrupted || skin === CharacterSkin.Wizard_Veteran) {
        //         PopToast({ status: ToastStatuses.ERROR, message: t("runprep__skin_not_unlocked_registered") });
        //         return;
        //     } else if (skin === CharacterSkin.MidnightThief || skin === CharacterSkin.ForestArcher || skin === CharacterSkin.Assassin || skin === CharacterSkin.VengefulKnight) {
        //         UI_SetSelectedSkinToPurchase(skin);
        //         UI_SwapScreen(Screens.ConfirmSkinPurchase);
        //     } else if (skin === CharacterSkin.Estranged_Wizard) {
        //         UI_SetSelectedBundle("welcome");
        //         UI_SwapScreen(Screens.BundleDetails);
        //     } else if (skin === CharacterSkin.AdeptArcher || skin === CharacterSkin.WealthyAssassin || skin === CharacterSkin.FallenWizardsApprentice) {
        //         UI_SetSelectedBundle("supporter");
        //         UI_SwapScreen(Screens.BundleDetails);
        //     } else {
        //         UI_SetSelectedBundle("founder");
        //         UI_SwapScreen(Screens.BundleDetails);
        //     }
        // } else {
        //     if (requiresOwnership && PVPZ_UnlockedSkins !== undefined) {
        //         console.log("user is equipping a skin that requires ownership", PVPZ_UnlockedSkins);
        //         if (PVPZ_UnlockedSkins.includes(skin) === false) {
        //             if (skin === CharacterSkin.MidnightThief || skin === CharacterSkin.ForestArcher || skin === CharacterSkin.Assassin || skin === CharacterSkin.VengefulKnight) {
        //                 UI_SetSelectedSkinToPurchase(skin);
        //                 UI_SwapScreen(Screens.ConfirmSkinPurchase);
        //             } else if (skin === CharacterSkin.Estranged_Wizard) {
        //                 UI_SetSelectedBundle("welcome");
        //                 UI_SwapScreen(Screens.BundleDetails);
        //             } else if (skin === CharacterSkin.AdeptArcher || skin === CharacterSkin.WealthyAssassin || skin === CharacterSkin.FallenWizardsApprentice) {
        //                 UI_SetSelectedBundle("supporter");
        //                 UI_SwapScreen(Screens.BundleDetails);
        //             } else {
        //                 UI_SetSelectedBundle("founder");
        //                 UI_SwapScreen(Screens.BundleDetails);
        //             }
        //         } else {
        //             UI_EquipSkin(skin);
        //         }
        //     } else {
        //         // console.log(`equipping skin ${CharacterSkin[skin]}`);
        //     }
        // }
    };

    return (
        <>
            <div className="runprep-panel animate__animated animate__fadeInUp animate__faster">
                {isOnCrazygames === false && (
                    <button
                        className={`fullscreen-button ${isFullscreen ? "is-full-screen neutral-button" : "affirmative-button animate__animated animate__jello animate__infinite animate__slow"}`}
                        onClick={() => {
                            setIsFullscreen(!isFullscreen);
                            Game.Input.ToggleFullScreen();
                        }}
                    >
                        <span>{isFullscreen ? t("exit_fullscreen") : t("fullscreen")}</span>
                    </button>
                )}
                <div className="runprep-header">{t("loadout_menu__title")}</div>

                <div className="runprep-content">
                    <div className="runprep-loadout">
                        <div className="character">
                            <p className="skin-title">~ {t("loadout_menu__skin_header")} ~</p>
                            <CharacterSkinSlot
                                selected={selectionMode === "characterSkin"}
                                OnClickFn={() => {
                                    UI_SetLoadoutSelectionMode("characterSkin");
                                }}
                                skin={loadout.characterSkin}
                            />
                        </div>

                        <div className="weapons">
                            <p className="weps-title">~ {t("loadout_menu__weapons_header")} ~</p>
                            <div className="weapon-slots">
                                <div className="weapon-parent">
                                    {weaponSlotOne}
                                    <button
                                        className={`neutral-button insure-btn ${loadout.insuredSlot === LoadoutSlots.WeaponOne ? "insured" : ""}`}
                                        disabled={playerLoadoutOperationInProgress || loadoutWeaponOne === -1 || insuranceCredits <= 0}
                                        onClick={() => {
                                            UI_InsureSlot(LoadoutSlots.WeaponOne);
                                        }}
                                    >
                                        {t("loadout_menu__insurance_button")}
                                    </button>
                                </div>
                                <div className="weapon-parent">
                                    {weaponSlotTwo}
                                    <button
                                        className={`neutral-button insure-btn ${loadout.insuredSlot === LoadoutSlots.WeaponTwo ? "insured" : ""}`}
                                        disabled={playerLoadoutOperationInProgress || loadoutWeaponTwo === -1 || insuranceCredits <= 0}
                                        onClick={() => {
                                            UI_InsureSlot(LoadoutSlots.WeaponTwo);
                                        }}
                                    >
                                        {t("loadout_menu__insurance_button")}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="gear">
                            <p className="gear-title">~ {t("loadout_menu__gear_header")} ~</p>
                            <div className="gear-slots">
                                <div className="gear-parent">
                                    {gearSlotOne}
                                    <button
                                        className={`neutral-button insure-btn ${loadout.insuredSlot === LoadoutSlots.GearOne ? "insured" : ""}`}
                                        disabled={playerLoadoutOperationInProgress || loadoutGearOne === -1 || insuranceCredits <= 0}
                                        onClick={() => {
                                            UI_InsureSlot(LoadoutSlots.GearOne);
                                            // Game.Rewards.PreInsurance(LoadoutSlots.GearOne);
                                        }}
                                    >
                                        {t("loadout_menu__insurance_button")}
                                    </button>
                                </div>
                                <div className="gear-parent">
                                    {gearSlotTwo}
                                    <button
                                        className={`neutral-button insure-btn ${loadout.insuredSlot === LoadoutSlots.GearTwo ? "insured" : ""}`}
                                        disabled={playerLoadoutOperationInProgress || loadoutGearTwo === -1 || insuranceCredits <= 0}
                                        onClick={() => {
                                            UI_InsureSlot(LoadoutSlots.GearTwo);
                                        }}
                                    >
                                        {t("loadout_menu__insurance_button")}
                                    </button>
                                </div>
                                <div className="gear-parent">
                                    {gearSlotThree}
                                    <button
                                        className={`neutral-button insure-btn ${loadout.insuredSlot === LoadoutSlots.GearThree ? "insured" : ""}`}
                                        disabled={playerLoadoutOperationInProgress || loadoutGearThree === -1 || insuranceCredits <= 0}
                                        onClick={() => {
                                            UI_InsureSlot(LoadoutSlots.GearThree);
                                        }}
                                    >
                                        {t("loadout_menu__insurance_button")}
                                    </button>
                                </div>
                            </div>

                            <p className="insurance-credits-label">Insurance Credits Remaining: {insuranceCredits}</p>
                        </div>
                    </div>

                    <img draggable="false" className="divider divider-one" src={dividerImageSrc} alt="Divider" />

                    <div className="runprep-inventory">
                        <div className="runprep-selector">
                            {selectionMode === "none" && (
                                <div className="no-selection">
                                    <h4>
                                        <span style={{ marginRight: "10px" }}>👈</span>
                                        {t("loadout_menu__no_slot_selected_scrollbox")}
                                    </h4>
                                </div>
                            )}
                            {selectionMode === "characterSkin" && (
                                <>
                                    <div className="character-skin-select">
                                        <h4>{t("loadout_menu__scrollbox_with_skins_text")}</h4>
                                        {loadout.characterSkin !== CharacterSkin.Wizard_Apprentice && (
                                            <CharacterSkinSlot
                                                selected={false}
                                                OnClickFn={() => {
                                                    equipSkin(CharacterSkin.Wizard_Apprentice);
                                                }}
                                                skin={CharacterSkin.Wizard_Apprentice}
                                            />
                                        )}

                                        {loadout.characterSkin !== CharacterSkin.Wizard_Corrupted && (
                                            <CharacterSkinSlot
                                                selected={false}
                                                OnClickFn={() => {
                                                    if (userType === UserType.Anonymous) {
                                                        PopToast({ status: ToastStatuses.ERROR, message: t("runprep__skin_not_unlocked_registered") });
                                                        return;
                                                    } else {
                                                        equipSkin(CharacterSkin.Wizard_Corrupted);
                                                    }
                                                }}
                                                skin={CharacterSkin.Wizard_Corrupted}
                                            />
                                        )}

                                        {loadout.characterSkin !== CharacterSkin.Wizard_Veteran && (
                                            <CharacterSkinSlot
                                                selected={false}
                                                OnClickFn={() => {
                                                    if (userType === UserType.Anonymous) {
                                                        PopToast({ status: ToastStatuses.ERROR, message: t("runprep__skin_not_unlocked_registered") });
                                                        return;
                                                    } else {
                                                        equipSkin(CharacterSkin.Wizard_Veteran);
                                                    }
                                                }}
                                                skin={CharacterSkin.Wizard_Veteran}
                                            />
                                        )}

                                        {loadout.characterSkin !== CharacterSkin.Boltman && (
                                            <CharacterSkinSlot
                                                selected={false}
                                                OnClickFn={() => {
                                                    equipSkin(CharacterSkin.Boltman);
                                                }}
                                                skin={CharacterSkin.Boltman}
                                            />
                                        )}

                                        {loadout.characterSkin !== CharacterSkin.Knight && (
                                            <CharacterSkinSlot
                                                selected={false}
                                                OnClickFn={() => {
                                                    equipSkin(CharacterSkin.Knight);
                                                }}
                                                skin={CharacterSkin.Knight}
                                            />
                                        )}

                                        {loadout.characterSkin !== CharacterSkin.Thief && (
                                            <CharacterSkinSlot
                                                selected={false}
                                                OnClickFn={() => {
                                                    equipSkin(CharacterSkin.Thief);
                                                }}
                                                skin={CharacterSkin.Thief}
                                            />
                                        )}

                                        {loadout.characterSkin !== CharacterSkin.NobleKnight && (
                                            <CharacterSkinSlot
                                                selected={false}
                                                OnClickFn={() => {
                                                    if (userType === UserType.Anonymous) {
                                                        PopToast({ status: ToastStatuses.ERROR, message: t("runprep__skin_not_unlocked_registered") });
                                                        return;
                                                    } else {
                                                        equipSkin(CharacterSkin.NobleKnight);
                                                    }
                                                }}
                                                skin={CharacterSkin.NobleKnight}
                                            />
                                        )}

                                        {loadout.characterSkin !== CharacterSkin.AdeptArcher && (
                                            <CharacterSkinSlot
                                                selected={false}
                                                OnClickFn={() => {
                                                    if (userType === UserType.Anonymous) {
                                                        UI_SetSelectedBundle("supporter");
                                                        UI_SwapScreen(Screens.BundleDetails);
                                                    } else {
                                                        if (PVPZ_UnlockedSkins !== undefined) {
                                                            if (PVPZ_UnlockedSkins.includes(CharacterSkin.AdeptArcher)) {
                                                                equipSkin(CharacterSkin.AdeptArcher);
                                                            } else {
                                                                UI_SetSelectedBundle("supporter");
                                                                UI_SwapScreen(Screens.BundleDetails);
                                                            }
                                                        }
                                                    }
                                                }}
                                                skin={CharacterSkin.AdeptArcher}
                                            />
                                        )}

                                        {loadout.characterSkin !== CharacterSkin.ForestArcher && (
                                            <CharacterSkinSlot
                                                selected={false}
                                                OnClickFn={() => {
                                                    if (userType === UserType.Anonymous) {
                                                        UI_SetSelectedSkinToPurchase(CharacterSkin.ForestArcher);
                                                        UI_SwapScreen(Screens.ConfirmSkinPurchase);
                                                    } else {
                                                        if (PVPZ_UnlockedSkins !== undefined) {
                                                            if (PVPZ_UnlockedSkins.includes(CharacterSkin.ForestArcher)) {
                                                                equipSkin(CharacterSkin.ForestArcher);
                                                            } else {
                                                                UI_SetSelectedSkinToPurchase(CharacterSkin.ForestArcher);
                                                                UI_SwapScreen(Screens.ConfirmSkinPurchase);
                                                            }
                                                        }
                                                    }
                                                }}
                                                skin={CharacterSkin.ForestArcher}
                                            />
                                        )}

                                        {loadout.characterSkin !== CharacterSkin.Assassin && (
                                            <CharacterSkinSlot
                                                selected={false}
                                                OnClickFn={() => {
                                                    if (userType === UserType.Anonymous) {
                                                        UI_SetSelectedSkinToPurchase(CharacterSkin.Assassin);
                                                        UI_SwapScreen(Screens.ConfirmSkinPurchase);
                                                    } else {
                                                        if (PVPZ_UnlockedSkins !== undefined) {
                                                            if (PVPZ_UnlockedSkins.includes(CharacterSkin.Assassin)) {
                                                                equipSkin(CharacterSkin.Assassin);
                                                            } else {
                                                                UI_SetSelectedSkinToPurchase(CharacterSkin.Assassin);
                                                                UI_SwapScreen(Screens.ConfirmSkinPurchase);
                                                            }
                                                        }
                                                    }
                                                }}
                                                skin={CharacterSkin.Assassin}
                                            />
                                        )}

                                        {loadout.characterSkin !== CharacterSkin.CherryBlossomBoltman && (
                                            <CharacterSkinSlot
                                                selected={false}
                                                OnClickFn={() => {
                                                    if (userType === UserType.Anonymous) {
                                                        UI_SetSelectedBundle("founder");
                                                        UI_SwapScreen(Screens.BundleDetails);
                                                    } else {
                                                        if (PVPZ_UnlockedSkins !== undefined) {
                                                            if (PVPZ_UnlockedSkins.includes(CharacterSkin.CherryBlossomBoltman)) {
                                                                equipSkin(CharacterSkin.CherryBlossomBoltman);
                                                            } else {
                                                                UI_SetSelectedBundle("founder");
                                                                UI_SwapScreen(Screens.BundleDetails);
                                                            }
                                                        }
                                                    }
                                                }}
                                                skin={CharacterSkin.CherryBlossomBoltman}
                                            />
                                        )}

                                        {loadout.characterSkin !== CharacterSkin.Coldsteel_Knight && (
                                            <CharacterSkinSlot
                                                selected={false}
                                                OnClickFn={() => {
                                                    if (userType === UserType.Anonymous) {
                                                        UI_SetSelectedBundle("founder");
                                                        UI_SwapScreen(Screens.BundleDetails);
                                                    } else {
                                                        if (PVPZ_UnlockedSkins !== undefined) {
                                                            if (PVPZ_UnlockedSkins.includes(CharacterSkin.Coldsteel_Knight)) {
                                                                equipSkin(CharacterSkin.Coldsteel_Knight);
                                                            } else {
                                                                UI_SetSelectedBundle("founder");
                                                                UI_SwapScreen(Screens.BundleDetails);
                                                            }
                                                        }
                                                    }
                                                }}
                                                skin={CharacterSkin.Coldsteel_Knight}
                                            />
                                        )}

                                        {loadout.characterSkin !== CharacterSkin.CursedCorruptedWizard && (
                                            <CharacterSkinSlot
                                                selected={false}
                                                OnClickFn={() => {
                                                    if (userType === UserType.Anonymous) {
                                                        UI_SetSelectedBundle("founder");
                                                        UI_SwapScreen(Screens.BundleDetails);
                                                    } else {
                                                        if (PVPZ_UnlockedSkins !== undefined) {
                                                            if (PVPZ_UnlockedSkins.includes(CharacterSkin.CursedCorruptedWizard)) {
                                                                equipSkin(CharacterSkin.CursedCorruptedWizard);
                                                            } else {
                                                                UI_SetSelectedBundle("founder");
                                                                UI_SwapScreen(Screens.BundleDetails);
                                                            }
                                                        }
                                                    }
                                                }}
                                                skin={CharacterSkin.CursedCorruptedWizard}
                                            />
                                        )}

                                        {loadout.characterSkin !== CharacterSkin.FallenWizardsApprentice && (
                                            <CharacterSkinSlot
                                                selected={false}
                                                OnClickFn={() => {
                                                    if (userType === UserType.Anonymous) {
                                                        UI_SetSelectedBundle("supporter");
                                                        UI_SwapScreen(Screens.BundleDetails);
                                                    } else {
                                                        if (PVPZ_UnlockedSkins !== undefined) {
                                                            if (PVPZ_UnlockedSkins.includes(CharacterSkin.FallenWizardsApprentice)) {
                                                                equipSkin(CharacterSkin.FallenWizardsApprentice);
                                                            } else {
                                                                UI_SetSelectedBundle("supporter");
                                                                UI_SwapScreen(Screens.BundleDetails);
                                                            }
                                                        }
                                                    }
                                                }}
                                                skin={CharacterSkin.FallenWizardsApprentice}
                                            />
                                        )}

                                        {loadout.characterSkin !== CharacterSkin.HeroicKnight && (
                                            <CharacterSkinSlot
                                                selected={false}
                                                OnClickFn={() => {
                                                    if (userType === UserType.Anonymous) {
                                                        UI_SetSelectedBundle("founder");
                                                        UI_SwapScreen(Screens.BundleDetails);
                                                    } else {
                                                        if (PVPZ_UnlockedSkins !== undefined) {
                                                            if (PVPZ_UnlockedSkins.includes(CharacterSkin.HeroicKnight)) {
                                                                equipSkin(CharacterSkin.HeroicKnight);
                                                            } else {
                                                                UI_SetSelectedBundle("founder");
                                                                UI_SwapScreen(Screens.BundleDetails);
                                                            }
                                                        }
                                                    }
                                                }}
                                                skin={CharacterSkin.HeroicKnight}
                                            />
                                        )}

                                        {loadout.characterSkin !== CharacterSkin.MidnightThief && (
                                            <CharacterSkinSlot
                                                selected={false}
                                                OnClickFn={() => {
                                                    if (userType === UserType.Anonymous) {
                                                        UI_SetSelectedSkinToPurchase(CharacterSkin.MidnightThief);
                                                        UI_SwapScreen(Screens.ConfirmSkinPurchase);
                                                    } else {
                                                        if (PVPZ_UnlockedSkins !== undefined) {
                                                            if (PVPZ_UnlockedSkins.includes(CharacterSkin.MidnightThief)) {
                                                                equipSkin(CharacterSkin.MidnightThief);
                                                            } else {
                                                                UI_SetSelectedSkinToPurchase(CharacterSkin.MidnightThief);
                                                                UI_SwapScreen(Screens.ConfirmSkinPurchase);
                                                            }
                                                        }
                                                    }
                                                }}
                                                skin={CharacterSkin.MidnightThief}
                                            />
                                        )}

                                        {loadout.characterSkin !== CharacterSkin.RadiantKnight && (
                                            <CharacterSkinSlot
                                                selected={false}
                                                OnClickFn={() => {
                                                    if (userType === UserType.Anonymous) {
                                                        UI_SetSelectedBundle("founder");
                                                        UI_SwapScreen(Screens.BundleDetails);
                                                    } else {
                                                        if (PVPZ_UnlockedSkins !== undefined) {
                                                            if (PVPZ_UnlockedSkins.includes(CharacterSkin.RadiantKnight)) {
                                                                equipSkin(CharacterSkin.RadiantKnight);
                                                            } else {
                                                                UI_SetSelectedBundle("founder");
                                                                UI_SwapScreen(Screens.BundleDetails);
                                                            }
                                                        }
                                                    }
                                                }}
                                                skin={CharacterSkin.RadiantKnight}
                                            />
                                        )}

                                        {loadout.characterSkin !== CharacterSkin.VengefulKnight && (
                                            <CharacterSkinSlot
                                                selected={false}
                                                OnClickFn={() => {
                                                    if (userType === UserType.Anonymous) {
                                                        UI_SetSelectedSkinToPurchase(CharacterSkin.VengefulKnight);
                                                        UI_SwapScreen(Screens.ConfirmSkinPurchase);
                                                    } else {
                                                        if (PVPZ_UnlockedSkins !== undefined) {
                                                            if (PVPZ_UnlockedSkins.includes(CharacterSkin.VengefulKnight)) {
                                                                equipSkin(CharacterSkin.VengefulKnight);
                                                            } else {
                                                                UI_SetSelectedSkinToPurchase(CharacterSkin.VengefulKnight);
                                                                UI_SwapScreen(Screens.ConfirmSkinPurchase);
                                                            }
                                                        }
                                                    }
                                                }}
                                                skin={CharacterSkin.VengefulKnight}
                                            />
                                        )}

                                        {loadout.characterSkin !== CharacterSkin.WealthyAssassin && (
                                            <CharacterSkinSlot
                                                selected={false}
                                                OnClickFn={() => {
                                                    if (userType === UserType.Anonymous) {
                                                        UI_SetSelectedBundle("supporter");
                                                        UI_SwapScreen(Screens.BundleDetails);
                                                    } else {
                                                        if (PVPZ_UnlockedSkins !== undefined) {
                                                            if (PVPZ_UnlockedSkins.includes(CharacterSkin.WealthyAssassin)) {
                                                                equipSkin(CharacterSkin.WealthyAssassin);
                                                            } else {
                                                                UI_SetSelectedBundle("supporter");
                                                                UI_SwapScreen(Screens.BundleDetails);
                                                            }
                                                        }
                                                    }
                                                }}
                                                skin={CharacterSkin.WealthyAssassin}
                                            />
                                        )}

                                        {loadout.characterSkin !== CharacterSkin.Estranged_Wizard && (
                                            <CharacterSkinSlot
                                                selected={false}
                                                OnClickFn={() => {
                                                    if (userType === UserType.Anonymous) {
                                                        UI_SetSelectedBundle("welcome");
                                                        UI_SwapScreen(Screens.BundleDetails);
                                                    } else {
                                                        if (PVPZ_UnlockedSkins !== undefined) {
                                                            if (PVPZ_UnlockedSkins.includes(CharacterSkin.Estranged_Wizard)) {
                                                                equipSkin(CharacterSkin.Estranged_Wizard);
                                                            } else {
                                                                UI_SetSelectedBundle("welcome");
                                                                UI_SwapScreen(Screens.BundleDetails);
                                                            }
                                                        }
                                                    }
                                                }}
                                                skin={CharacterSkin.Estranged_Wizard}
                                            />
                                        )}
                                    </div>
                                </>
                            )}
                            {(selectionMode === "weaponOne" || selectionMode === "weaponTwo") && (
                                <>
                                    <div className="weapon-select">
                                        <h4>{t("loadout_menu__scrollbox_with_weapons_text")}</h4>
                                        <ItemContainer
                                            OnSlotClickFn={(itemId: Item) => {
                                                const slot = selectionMode === "weaponOne" ? LoadoutSlots.WeaponOne : LoadoutSlots.WeaponTwo;
                                                equipItemToSlot(slot, itemId);
                                            }}
                                            items={justWeaponsFromStash}
                                            showLeftClickEquipPrompt={true}
                                        />
                                    </div>
                                </>
                            )}

                            {(selectionMode === "gearOne" || selectionMode === "gearTwo" || selectionMode === "gearThree") && (
                                <>
                                    <div className="gear-select">
                                        <h4>{t("loadout_menu__scrollbox_with_gear_text")}</h4>
                                        <ItemContainer
                                            OnSlotClickFn={(itemId: Item) => {
                                                const slot = selectionMode === "gearOne" ? LoadoutSlots.GearOne : selectionMode === "gearTwo" ? LoadoutSlots.GearTwo : LoadoutSlots.GearThree;
                                                equipItemToSlot(slot, itemId);
                                            }}
                                            items={justGearFromStash}
                                            showLeftClickEquipPrompt={true}
                                        />
                                    </div>
                                </>
                            )}
                        </div>

                        <div className="runprep-insurance">
                            {loadout.insuredSlot === -1 && (
                                <>
                                    <p>{t("loadout_menu__no_slot_selected_insurance_text")}</p>
                                    <p className="smol" style={{ fontWeight: "bold" }}>
                                        {t("loadout_menu__no_slot_selected_insurance_text_2")}
                                    </p>
                                </>
                            )}

                            {loadout.insuredSlot !== -1 && (
                                <>
                                    <p>{t("loadout_menu__slot_insured_text")}</p>
                                    <p className="smol">
                                        {t("loadout_menu__your")} {getInsuredItemName(loadout.insuredSlot)} {t("loadout_menu__will_be_safe")}
                                    </p>

                                    {/* <button
                                        disabled={playerLoadoutOperationInProgress || insuranceCredits <= 0}
                                        className="destructive-button"
                                        onClick={() => {
                                            UI_InsureSlot(LoadoutSlots.None);
                                        }}
                                    >
                                        {t("loadout_menu__undo")}
                                    </button> */}
                                </>
                            )}
                        </div>
                    </div>

                    <img draggable="false" className="divider divider-two" src={dividerImageSrc} alt="Divider" />

                    <div className="runprep-summary">
                        <div className="runprep-stats">
                            <p className="health">
                                <span>{t("loadout_menu__health")}:</span> {maxHealthWithBoostsFactoredIn}
                            </p>
                            <div className="stats">
                                <div className="offensive-stats">
                                    <div className="stat-category-title">{t("loadout_menu__damage")}+</div>
                                    <div className="stats-row">
                                        <p>{physicalDamageBoost}</p>
                                    </div>
                                    <div className="stats-row">
                                        <p>{fireDamageBoost}</p>
                                    </div>
                                    <div className="stats-row">
                                        <p>{toxicDamageBoost}</p>
                                    </div>
                                    <div className="stats-row">
                                        <p>{arcaneDamageBoost}</p>
                                    </div>
                                    <div className="stats-row">
                                        <p>{frostDamageBoost}</p>
                                    </div>
                                    <div className="stats-row">
                                        <p>{lightningDamageBoost}</p>
                                    </div>
                                    <div className="stats-row">
                                        <p>{holyDamageBoost}</p>
                                    </div>
                                    <div className="stats-row">
                                        <p>{shadowDamageBoost}</p>
                                    </div>
                                    <div className="stats-row">
                                        <p>{chaosDamageBoost}</p>
                                    </div>
                                    <div className="stats-row">
                                        <p>{natureDamageBoost}</p>
                                    </div>
                                    <div className="stats-row">
                                        <p>{bleedDamageBoost}</p>
                                    </div>
                                </div>
                                <div className="stats-labels">
                                    <div className="stat-category-title">{t("loadout_menu__stat")}</div>
                                    <div className="stats-label">
                                        <div className="stats-row">
                                            <p>{t("stat_type_physical")}</p>
                                        </div>
                                        <div className="stats-row">
                                            <p>{t("stat_type_fire")}</p>
                                        </div>
                                        <div className="stats-row">
                                            <p>{t("stat_type_toxic")}</p>
                                        </div>
                                        <div className="stats-row">
                                            <p>{t("stat_type_arcane")}</p>
                                        </div>
                                        <div className="stats-row">
                                            <p>{t("stat_type_frost")}</p>
                                        </div>
                                        <div className="stats-row">
                                            <p>{t("stat_type_lightning")}</p>
                                        </div>
                                        <div className="stats-row">
                                            <p>{t("stat_type_holy")}</p>
                                        </div>
                                        <div className="stats-row">
                                            <p>{t("stat_type_shadow")}</p>
                                        </div>
                                        <div className="stats-row">
                                            <p>{t("stat_type_chaos")}</p>
                                        </div>
                                        <div className="stats-row">
                                            <p>{t("stat_type_nature")}</p>
                                        </div>
                                        <div className="stats-row">
                                            <p>{t("stat_type_bleed")}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="defensive-stats">
                                    <div className="stat-category-title">{t("loadout_menu__defence")}+</div>
                                    <div className="stats-row">
                                        <p>{physicalDamageReduction}</p>
                                    </div>
                                    <div className="stats-row">
                                        <p>{fireDamageReduction}</p>
                                    </div>
                                    <div className="stats-row">
                                        <p>{toxicDamageReduction}</p>
                                    </div>
                                    <div className="stats-row">
                                        <p>{arcaneDamageReduction}</p>
                                    </div>
                                    <div className="stats-row">
                                        <p>{frostDamageReduction}</p>
                                    </div>
                                    <div className="stats-row">
                                        <p>{lightningDamageReduction}</p>
                                    </div>
                                    <div className="stats-row">
                                        <p>{holyDamageReduction}</p>
                                    </div>
                                    <div className="stats-row">
                                        <p>{shadowDamageReduction}</p>
                                    </div>
                                    <div className="stats-row">
                                        <p>{chaosDamageReduction}</p>
                                    </div>
                                    <div className="stats-row">
                                        <p>{natureDamageReduction}</p>
                                    </div>
                                    <div className="stats-row">
                                        <p>{bleedDamageReduction}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="abilities-and-passives">
                                <h5>{t("loadout_menu__abilities_header")}</h5>
                                {abilityDescriptionsDom}
                            </div>
                        </div>
                        <div className="runprep-actions">
                            <button
                                disabled={playerLoadoutOperationInProgress}
                                className="affirmative-button start-btn"
                                onClick={() => {
                                    Game.Rewards.PreGame();
                                }}
                            >
                                <span>{t("loadout_menu__start_run_button")}</span>
                            </button>
                            <button
                                disabled={playerLoadoutOperationInProgress}
                                className="neutral-button back-btn"
                                onClick={() => {
                                    UI_SwapScreen(Screens.MainMenu);
                                }}
                            >
                                <span>{t("loadout_menu__back_to_main_menu_button")}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

import { isNumber } from "lodash";
import { t } from "./Data_I18N";

//#region Items
/****************************************************/
/*                                                  */
/*               Item System Types                  */
/*                                                  */
/****************************************************/
export enum ItemType {
    Weapon = 0,
    ActiveGear = 1,
    PassiveGear = 2,
    VendorTrash = 3,
    CollectorsItem = 4
}

export enum DamageType {
    None = -1,
    Physical = 0,
    Fire = 1,
    Toxic = 2,
    Arcane = 3,
    Frost = 4,
    Lightning = 5,
    Holy = 6,
    Shadow = 7,
    Chaos = 8,
    Nature = 9,
    Bleed = 10
}

export const getQuantityOfItemsInContainerMap = (itemContainerMap: ItemContainerMap): number => {
    return Object.values(itemContainerMap).reduce((acc, cur) => acc + cur, 0);
};

export const getFriendlyItemTypeName = (itemType: ItemType): string => {
    switch (itemType) {
        case ItemType.Weapon:
            return t("item_type__weapon");
        case ItemType.ActiveGear:
            return t("item_type__gear");
        case ItemType.PassiveGear:
            return t("item_type__gear");
        case ItemType.VendorTrash:
            return t("item_type__vendor");
        case ItemType.CollectorsItem:
            return t("item_type__collector");
        default:
            return t("item_type__unknown");
    }
};

export enum ItemRarity {
    Common = 0,
    Uncommon = 1,
    Rare = 2,
    Epic = 3,
    Legendary = 4
}

export enum LootSources {
    None = -1,
    Ghost = 0,
    Skeleton = 1,
    Ogre = 2,
    GiantRat = 3,
    Treant = 4,
    GRONK = 5,
    AllLootChests = 6,
    IronFox = 7,
    DarkWizard = 8,
    AllEnemies = 9
}

interface BaseItemConfig {
    itemId: Item;
    itemType: ItemType;

    maxItemQuantityPerRoll: number;
    itemRarity: ItemRarity;
    itemShouldBeDestroyedIfChargesExpended: boolean;

    name: string;
    loreText: string;
    gameplayText: string;

    inventorySpriteId: string;

    passiveStats: PassiveStat[];

    passiveAbilities: PassiveAbilityDefinition[];

    activeAbility: ActiveAbilityDefinition;

    tooltipSlotRotation: number;
    tooltipSlotSpriteScale: number;
    tooltipSlotSpriteOffsetLeft: number;
    tooltipSlotSpriteOffsetTop: number;

    largeInventorySlotRotation: number;
    largeInventorySlotSpriteScale: number;
    largeInventorySlotSpriteOffsetLeft: number;
    largeInventorySlotSpriteOffsetTop: number;

    smallInventorySlotRotation: number;
    smallInventorySlotSpriteScale: number;
    smallInventorySlotSpriteOffsetLeft: number;
    smallInventorySlotSpriteOffsetTop: number;
    droppedBy: LootSources[];
}

interface WeaponItemConfig extends BaseItemConfig {
    itemType: ItemType.Weapon;
}

interface ActiveGearItemConfig extends BaseItemConfig {
    itemType: ItemType.ActiveGear;
}

interface PassiveGearItemConfig extends BaseItemConfig {
    itemType: ItemType.PassiveGear;
}

interface VendorTrashItemConfig extends BaseItemConfig {
    itemType: ItemType.VendorTrash;
}

interface CollectorsItemConfig extends BaseItemConfig {
    itemType: ItemType.CollectorsItem;
}

export type ItemConfig = WeaponItemConfig | ActiveGearItemConfig | PassiveGearItemConfig | VendorTrashItemConfig | CollectorsItemConfig;

export enum Item {
    None = -1,

    /*******************/
    /*     Staves      */
    /* IDs 0-99        */
    /*******************/
    W_ApprenticesStaff = 0,
    W_FlamespitterStaff = 1,
    W_StaffOfTheSnake = 2,
    W_ArchmagesSidekick = 3,
    W_SamsStirringStick = 4,
    W_CandlelightStaff = 5,
    W_TwistedBranch = 6,
    W_IronStaff = 7,
    W_PharaohsStaff = 8,
    W_FairyStaff = 9,
    W_MidasStaff = 10,
    W_DemonsEye = 11,
    W_SlimeStaff = 12,
    W_BeesOnAStick = 13,
    W_LichsStaff = 14,
    W_FrostFlicker = 15,

    /**********************/
    /*     Crossbows      */
    /* IDs 100-199        */
    /**********************/
    W_CrudeCrossbow = 100,
    W_GuardsCrossbow = 101,
    W_SteelCrossbow = 102,
    W_ReliqueryCrossbow = 103,
    W_EldritchBlasters = 104,
    W_DarylsInstinct = 105,
    W_FathersBane = 106,
    W_WitchHuntersRepeaters = 107,
    W_WyvernsTalon = 108,
    W_TheCrossBow = 109,

    /**********************/
    /*       Bows         */
    /* IDs 200-299        */
    /**********************/
    W_RangersGuillotine = 200,
    W_DavidBowie = 201,
    W_Emberstrike = 202,
    W_MoonShadow = 203,
    W_FairyBow = 204,
    W_Bloodrider = 205,
    W_PhoenixWing = 206,
    W_DivineWind = 207,
    W_StarShot = 208,
    W_CelestialBow = 209,
    W_ReapersEmbrace = 210,
    W_QuincyBow = 211,
    W_SilentShadow = 212,

    /**********************/
    /*    Wands           */
    /* IDs 300-399        */
    /**********************/
    W_Stick = 300,
    W_SpiraledWand = 301,
    W_NightStick = 302,
    W_DragonsBreath = 303,
    W_PoseidonsWand = 304,
    W_BrokenDeathstick = 305,
    W_HibidyKibity = 306,
    W_MagicalWand = 307,
    W_BlackDahliaWand = 308,
    W_FrostedTips = 309,
    W_RelicWand = 310,
    W_ZappingWand = 311,
    W_IcewindWand = 312,
    W_BlazeRod = 313,
    W_ToxicWand = 314,
    W_StarWand = 315,
    W_Deathstick = 316,

    /**********************/
    /*     Spellbooks     */
    /* IDs 400-499        */
    /**********************/
    W_SpellbindersTome = 400,
    W_Triforce = 401,
    W_WizardsLedger = 402,
    W_NaturesReturn = 403,
    W_KingBible = 404,
    W_BookOfTongues = 405,

    /*******************/
    /*  Passive Gear   */
    /* IDs 1000-1999   */
    /*******************/
    PG_SerpentsRing = 1000,
    PG_WindspeakersRing = 1001,
    PG_IronFoxLocket = 1002,
    PG_HeirloomCloak = 1003,
    PG_ElvenGildedGuardian = 1004,
    PG_FrostFireRing = 1005,
    PG_GirdleOfTheGoldenNecromancer = 1006,
    PG_RingOfConstellations = 1007,
    PG_TantrumsLuckyLoop = 1008,
    PG_GoldPlatedCape = 1009,
    PG_AssassinSignet = 1010,
    PG_CopperWristband = 1011,
    PG_GolemsGrasp = 1012,
    PG_ShieldbearersAmulet = 1013,
    PG_MementoMoriLocket = 1014,
    PG_GuardiansChainmailCollar = 1015,
    PG_NaturesAureateBarrier = 1016,
    PG_QuintsFishingGarb = 1017,
    PG_GineshsBottleOpener = 1018,
    PG_AngelistasPotionPopper = 1019,
    PG_MunchkinsRing = 1020,
    PG_CloakOfModestApproachability = 1021,
    PG_SummonersSign = 1022,
    PG_CoralReef = 1023,
    PG_IceQueensEarrings = 1024,
    PG_RingOfTheOneHandedWarrior = 1025,
    PG_CompactedLimeGreenRing = 1026,
    PG_LittleCompactedLimeGreenRing = 1027,
    PG_InfiniteLoop = 1028,
    PG_JaysesRing = 1029,
    PG_BellsShiningRing = 1030,
    PG_RingOfViolentViolet = 1031,
    PG_CloudedDarkness = 1032,
    PG_CapeOfPureDarkness = 1033,
    PG_BloodGemRing = 1034,
    PG_PurpletratorsRing = 1035,
    PG_AquamoorsBlessing = 1036,
    PG_EssenceConduit = 1037,
    PG_ArcaneEmber = 1038,
    PG_PhoenixsRespite = 1039,
    PG_SoulBindingRing = 1040,
    PG_BloodiedVitality = 1041,
    PG_VenomousBite = 1042,
    PG_RuneboundRing = 1043,
    PG_FrostbiteLoop = 1044,
    PG_PhantomBand = 1045,
    PG_EmbergraspRing = 1046,
    PG_SoulshackleVeil = 1047,
    PG_StormweaverCloak = 1048,
    PG_CursedSignet = 1049,
    PG_EnigmaLoop = 1050,
    PG_VampiricSeal = 1051,
    PG_Wraithband = 1052,
    PG_NecroticSignet = 1053,
    PG_CorruptedTearRing = 1054,
    PG_DeathwhisperWrap = 1055,
    PG_VeilstormCirclet = 1056,
    PG_SoulforgeRing = 1057,
    PG_CursedEternityRing = 1058,
    PG_MidnightsVeilBand = 1059,
    PG_DreadmorneLoop = 1060,
    PG_EbonfireRobelet = 1061,
    PG_EverlastingSpiritCircle = 1062,
    PG_EnigmaticShadowweaveRing = 1063,
    PG_BloodmoonLoop = 1064,
    PG_DreamcasterDrapery = 1065,
    PG_CelestialSolaceBand = 1066,
    PG_TalismanOfVeiledShadows = 1067,
    PG_PendantOfAncientWisdom = 1068,
    PG_SerpentsCoilChoker = 1069,
    PG_ArcaneSigilCollar = 1070,
    PG_ResilientHeartstone = 1071,
    PG_FrostfireShardPendant = 1072,
    PG_PhoenixFeatherTorque = 1073,
    PG_SoulstoneReliquary = 1074,
    PG_EnchantedElixirNecklace = 1075,
    PG_NecroticGraspTalisman = 1076,
    PG_CelestialStarfallPendant = 1077,
    PG_CultistsMask = 1078,
    PG_DepthStrider = 1079,
    PG_GoldenGauntlets = 1080,
    PG_BeastVanquisherBoots = 1081,

    // PG_SamsTestItem = 1999,

    /*******************/
    /*  Active Gear   */
    /* IDs 2000-2999   */
    /*******************/
    AG_VestiasSoothingSignet = 2000,
    AG_ViolasDinnerReminder = 2001,
    AG_WolfMedallion = 2002,
    AG_PepperedCollar = 2003,
    AG_StuddedNecklace = 2004,
    AG_TimeHerosRing = 2005,
    AG_TheDarkLordsEmbrace = 2006,
    AG_Boros = 2007,
    AG_MysteriousRing = 2008,
    AG_DarkPustulesRing = 2009,
    AG_RingOfAnOceanMan = 2010,
    AG_ForestsHaven = 2011,
    AG_DreamWeaversEmbrace = 2012,
    AG_ObsidianEcho = 2013,
    AG_AmuletOfCursedFate = 2014,
    AG_MagicBoots = 2015,

    /*******************/
    /*  Vendor Trash   */
    /* IDs 3000-3999   */
    /*******************/
    VT_OnyxGem = 3000,
    VT_GhostPlasma = 3001,
    VT_GoblinCloth = 3002,
    VT_WellWornRing = 3003,
    VT_RingOfNearPerfection = 3004,
    VT_CubicZirconia = 3005,
    VT_QuartzRing = 3006,
    VT_DukesDiary = 3007,
    VT_Potato = 3008,
    VT_Fossil = 3009,
    VT_Sapphire = 3010,
    VT_AbyssalShard = 3011,
    VT_RedMushroom = 3013,
    VT_SkeletonFemur = 3012,
    VT_BardsDiary = 3014,
    VT_EverlastingCandle = 3015,
    VT_Diamond = 3016,
    VT_WhispersOfTheVoid = 3017,
    VT_EmeraldDust = 3018,
    VT_RubyDust = 3019,
    VT_StellarWeaveRing = 3020,
    VT_Eyeball = 3021,
    VT_TreantBranch = 3022,
    VT_SapphireDust = 3023,
    VT_PileOfAsh = 3024,
    VT_Amber = 3025,
    VT_Skull = 3026,
    VT_GoldNugget = 3027,
    VT_BrokenSword = 3028,
    VT_Cabbage = 3029,
    VT_BrokenIronBand = 3030,
    VT_RatFur = 3031,
    VT_Ruby = 3032,
    VT_ChunkOfIron = 3033,
    VT_FangOfTheBloodMoon = 3034,
    VT_OgreEntrails = 3035,
    VT_Emerald = 3036,

    /*********************/
    /*  Collectors Items */
    /* IDs 4000-4999     */
    /*********************/
    CI_FragmentOfFate = 4000,
    CI_PlayerSoulFragment = 4001,
    CI_LatrosKnavishBand = 4002,
    CI_WeddingRing = 4003,
    CI_PeachRing = 4004,
    CI_RingOfDelerium = 4005,
    CI_LifesSimpleRing = 4006,
    CI_SwampOgresRing = 4007,
    CI_RoyalBrooch = 4008,
    CI_SuperStar = 4009,
    CI_Ouro = 4010,
    CI_AlloyedBend = 4011,
    CI_ProudlyPinkRing = 4012,
    CI_ApposSignet = 4013,
    CI_CelestialEternity = 4014,
    CI_BloodhuntersRing = 4015,
    CI_EbonstarBand = 4016,
    CI_LuminousEnigma = 4017,
    CI_RadiantSunstoneBand = 4018,
    CI_EchoingWhisperNecklace = 4019,
    CI_FeyEnchantmentLocket = 4020
}

export type ItemContainerMap = {
    [Item.None]: 0;

    [Item.W_ApprenticesStaff]: number;
    [Item.W_FlamespitterStaff]: number;
    [Item.W_StaffOfTheSnake]: number;
    [Item.W_ArchmagesSidekick]: number;
    [Item.W_SamsStirringStick]: number;
    [Item.W_CandlelightStaff]: number;
    [Item.W_TwistedBranch]: number;
    [Item.W_IronStaff]: number;
    [Item.W_PharaohsStaff]: number;
    [Item.W_FairyStaff]: number;
    [Item.W_MidasStaff]: number;
    [Item.W_DemonsEye]: number;
    [Item.W_SlimeStaff]: number;
    [Item.W_BeesOnAStick]: number;
    [Item.W_LichsStaff]: number;
    [Item.W_FrostFlicker]: number;

    [Item.W_CrudeCrossbow]: number;
    [Item.W_GuardsCrossbow]: number;
    [Item.W_SteelCrossbow]: number;
    [Item.W_ReliqueryCrossbow]: number;
    [Item.W_EldritchBlasters]: number;
    [Item.W_DarylsInstinct]: number;
    [Item.W_FathersBane]: number;
    [Item.W_WitchHuntersRepeaters]: number;
    [Item.W_WyvernsTalon]: number;
    [Item.W_TheCrossBow]: number;

    [Item.W_RangersGuillotine]: number;
    [Item.W_DavidBowie]: number;
    [Item.W_Emberstrike]: number;
    [Item.W_MoonShadow]: number;
    [Item.W_FairyBow]: number;
    [Item.W_Bloodrider]: number;
    [Item.W_PhoenixWing]: number;
    [Item.W_DivineWind]: number;
    [Item.W_StarShot]: number;
    [Item.W_CelestialBow]: number;
    [Item.W_ReapersEmbrace]: number;
    [Item.W_QuincyBow]: number;
    [Item.W_SilentShadow]: number;

    [Item.W_Stick]: number;
    [Item.W_SpiraledWand]: number;
    [Item.W_NightStick]: number;
    [Item.W_DragonsBreath]: number;
    [Item.W_PoseidonsWand]: number;
    [Item.W_BrokenDeathstick]: number;
    [Item.W_HibidyKibity]: number;
    [Item.W_MagicalWand]: number;
    [Item.W_BlackDahliaWand]: number;
    [Item.W_FrostedTips]: number;
    [Item.W_RelicWand]: number;
    [Item.W_ZappingWand]: number;
    [Item.W_IcewindWand]: number;
    [Item.W_BlazeRod]: number;
    [Item.W_ToxicWand]: number;
    [Item.W_StarWand]: number;
    [Item.W_Deathstick]: number;

    [Item.W_SpellbindersTome]: number;
    [Item.W_Triforce]: number;
    [Item.W_WizardsLedger]: number;
    [Item.W_NaturesReturn]: number;
    [Item.W_KingBible]: number;
    [Item.W_BookOfTongues]: number;

    [Item.PG_SerpentsRing]: number;
    [Item.PG_WindspeakersRing]: number;
    [Item.PG_IronFoxLocket]: number;
    [Item.PG_HeirloomCloak]: number;
    [Item.PG_ElvenGildedGuardian]: number;
    [Item.PG_FrostFireRing]: number;
    [Item.PG_GirdleOfTheGoldenNecromancer]: number;
    [Item.PG_RingOfConstellations]: number;
    [Item.PG_TantrumsLuckyLoop]: number;
    [Item.PG_GoldPlatedCape]: number;
    [Item.PG_AssassinSignet]: number;
    [Item.PG_CopperWristband]: number;
    [Item.PG_GolemsGrasp]: number;
    [Item.PG_ShieldbearersAmulet]: number;
    [Item.PG_MementoMoriLocket]: number;
    [Item.PG_GuardiansChainmailCollar]: number;
    [Item.PG_NaturesAureateBarrier]: number;
    [Item.PG_QuintsFishingGarb]: number;
    [Item.PG_GineshsBottleOpener]: number;
    [Item.PG_AngelistasPotionPopper]: number;
    [Item.PG_MunchkinsRing]: number;
    [Item.PG_CloakOfModestApproachability]: number;
    [Item.PG_SummonersSign]: number;
    [Item.PG_CoralReef]: number;
    [Item.PG_IceQueensEarrings]: number;
    [Item.PG_RingOfTheOneHandedWarrior]: number;
    [Item.PG_CompactedLimeGreenRing]: number;
    [Item.PG_LittleCompactedLimeGreenRing]: number;
    [Item.PG_InfiniteLoop]: number;
    [Item.PG_JaysesRing]: number;
    [Item.PG_BellsShiningRing]: number;
    [Item.PG_RingOfViolentViolet]: number;
    [Item.PG_CloudedDarkness]: number;
    [Item.PG_CapeOfPureDarkness]: number;
    [Item.PG_BloodGemRing]: number;
    [Item.PG_PurpletratorsRing]: number;
    [Item.PG_AquamoorsBlessing]: number;
    [Item.PG_EssenceConduit]: number;
    [Item.PG_ArcaneEmber]: number;
    [Item.PG_PhoenixsRespite]: number;
    [Item.PG_SoulBindingRing]: number;
    [Item.PG_BloodiedVitality]: number;
    [Item.PG_VenomousBite]: number;
    [Item.PG_RuneboundRing]: number;
    [Item.PG_FrostbiteLoop]: number;
    [Item.PG_PhantomBand]: number;
    [Item.PG_EmbergraspRing]: number;
    [Item.PG_SoulshackleVeil]: number;
    [Item.PG_StormweaverCloak]: number;
    [Item.PG_CursedSignet]: number;
    [Item.PG_EnigmaLoop]: number;
    [Item.PG_VampiricSeal]: number;
    [Item.PG_Wraithband]: number;
    [Item.PG_NecroticSignet]: number;
    [Item.PG_CorruptedTearRing]: number;
    [Item.PG_DeathwhisperWrap]: number;
    [Item.PG_VeilstormCirclet]: number;
    [Item.PG_SoulforgeRing]: number;
    [Item.PG_CursedEternityRing]: number;
    [Item.PG_MidnightsVeilBand]: number;
    [Item.PG_DreadmorneLoop]: number;
    [Item.PG_EbonfireRobelet]: number;
    [Item.PG_EverlastingSpiritCircle]: number;
    [Item.PG_EnigmaticShadowweaveRing]: number;
    [Item.PG_BloodmoonLoop]: number;
    [Item.PG_DreamcasterDrapery]: number;
    [Item.PG_CelestialSolaceBand]: number;
    [Item.PG_TalismanOfVeiledShadows]: number;
    [Item.PG_PendantOfAncientWisdom]: number;
    [Item.PG_SerpentsCoilChoker]: number;
    [Item.PG_ArcaneSigilCollar]: number;
    [Item.PG_ResilientHeartstone]: number;
    [Item.PG_FrostfireShardPendant]: number;
    [Item.PG_PhoenixFeatherTorque]: number;
    [Item.PG_SoulstoneReliquary]: number;
    [Item.PG_EnchantedElixirNecklace]: number;
    [Item.PG_NecroticGraspTalisman]: number;
    [Item.PG_CelestialStarfallPendant]: number;
    [Item.PG_CultistsMask]: number;
    [Item.PG_DepthStrider]: number;
    [Item.PG_GoldenGauntlets]: number;
    [Item.PG_BeastVanquisherBoots]: number;

    // [Item.PG_SamsTestItem]: number;

    [Item.AG_VestiasSoothingSignet]: number;
    [Item.AG_ViolasDinnerReminder]: number;
    [Item.AG_WolfMedallion]: number;
    [Item.AG_PepperedCollar]: number;
    [Item.AG_StuddedNecklace]: number;
    [Item.AG_TimeHerosRing]: number;
    [Item.AG_TheDarkLordsEmbrace]: number;
    [Item.AG_Boros]: number;
    [Item.AG_MysteriousRing]: number;
    [Item.AG_DarkPustulesRing]: number;
    [Item.AG_RingOfAnOceanMan]: number;
    [Item.AG_ForestsHaven]: number;
    [Item.AG_DreamWeaversEmbrace]: number;
    [Item.AG_ObsidianEcho]: number;
    [Item.AG_AmuletOfCursedFate]: number;
    [Item.AG_MagicBoots]: number;

    [Item.VT_OnyxGem]: number;
    [Item.VT_GhostPlasma]: number;
    [Item.VT_GoblinCloth]: number;
    [Item.VT_WellWornRing]: number;
    [Item.VT_RingOfNearPerfection]: number;
    [Item.VT_CubicZirconia]: number;
    [Item.VT_QuartzRing]: number;
    [Item.VT_DukesDiary]: number;
    [Item.VT_Potato]: number;
    [Item.VT_SkeletonFemur]: number;
    [Item.VT_RedMushroom]: number;
    [Item.VT_AbyssalShard]: number;
    [Item.VT_Sapphire]: number;
    [Item.VT_Fossil]: number;
    [Item.VT_BardsDiary]: number;
    [Item.VT_EverlastingCandle]: number;
    [Item.VT_Diamond]: number;
    [Item.VT_WhispersOfTheVoid]: number;
    [Item.VT_EmeraldDust]: number;
    [Item.VT_RubyDust]: number;
    [Item.VT_StellarWeaveRing]: number;
    [Item.VT_Eyeball]: number;
    [Item.VT_TreantBranch]: number;
    [Item.VT_SapphireDust]: number;
    [Item.VT_PileOfAsh]: number;
    [Item.VT_Amber]: number;
    [Item.VT_Skull]: number;
    [Item.VT_GoldNugget]: number;
    [Item.VT_BrokenSword]: number;
    [Item.VT_BrokenIronBand]: number;
    [Item.VT_RatFur]: number;
    [Item.VT_Ruby]: number;
    [Item.VT_ChunkOfIron]: number;
    [Item.VT_FangOfTheBloodMoon]: number;
    [Item.VT_OgreEntrails]: number;
    [Item.VT_Emerald]: number;
    [Item.VT_Cabbage]: number;

    [Item.CI_FragmentOfFate]: number;
    [Item.CI_PlayerSoulFragment]: number;
    [Item.CI_LatrosKnavishBand]: number;
    [Item.CI_WeddingRing]: number;
    [Item.CI_PeachRing]: number;
    [Item.CI_RingOfDelerium]: number;
    [Item.CI_LifesSimpleRing]: number;
    [Item.CI_SwampOgresRing]: number;
    [Item.CI_RoyalBrooch]: number;
    [Item.CI_SuperStar]: number;
    [Item.CI_Ouro]: number;
    [Item.CI_AlloyedBend]: number;
    [Item.CI_ProudlyPinkRing]: number;
    [Item.CI_ApposSignet]: number;
    [Item.CI_CelestialEternity]: number;
    [Item.CI_BloodhuntersRing]: number;
    [Item.CI_EbonstarBand]: number;
    [Item.CI_LuminousEnigma]: number;
    [Item.CI_RadiantSunstoneBand]: number;
    [Item.CI_EchoingWhisperNecklace]: number;
    [Item.CI_FeyEnchantmentLocket]: number;
};

export const getAllItemIds = (): Item[] => {
    return Object.values(Item)
        .filter(isNumber)
        .filter((v) => v !== -1);
};

export const getItemContainerMap = (defaultItemQuantities: number = 0): ItemContainerMap => {
    return {
        [Item.None]: 0,

        [Item.W_ApprenticesStaff]: defaultItemQuantities,
        [Item.W_FlamespitterStaff]: defaultItemQuantities,
        [Item.W_StaffOfTheSnake]: defaultItemQuantities,
        [Item.W_ArchmagesSidekick]: defaultItemQuantities,
        [Item.W_SamsStirringStick]: defaultItemQuantities,
        [Item.W_CandlelightStaff]: defaultItemQuantities,
        [Item.W_TwistedBranch]: defaultItemQuantities,
        [Item.W_IronStaff]: defaultItemQuantities,
        [Item.W_PharaohsStaff]: defaultItemQuantities,
        [Item.W_FairyStaff]: defaultItemQuantities,
        [Item.W_MidasStaff]: defaultItemQuantities,
        [Item.W_DemonsEye]: defaultItemQuantities,
        [Item.W_SlimeStaff]: defaultItemQuantities,
        [Item.W_BeesOnAStick]: defaultItemQuantities,
        [Item.W_LichsStaff]: defaultItemQuantities,
        [Item.W_FrostFlicker]: defaultItemQuantities,

        [Item.W_CrudeCrossbow]: defaultItemQuantities,
        [Item.W_GuardsCrossbow]: defaultItemQuantities,
        [Item.W_SteelCrossbow]: defaultItemQuantities,
        [Item.W_ReliqueryCrossbow]: defaultItemQuantities,
        [Item.W_EldritchBlasters]: defaultItemQuantities,
        [Item.W_DarylsInstinct]: defaultItemQuantities,
        [Item.W_FathersBane]: defaultItemQuantities,
        [Item.W_WitchHuntersRepeaters]: defaultItemQuantities,
        [Item.W_WyvernsTalon]: defaultItemQuantities,
        [Item.W_TheCrossBow]: defaultItemQuantities,

        [Item.W_RangersGuillotine]: defaultItemQuantities,
        [Item.W_DavidBowie]: defaultItemQuantities,
        [Item.W_Emberstrike]: defaultItemQuantities,
        [Item.W_MoonShadow]: defaultItemQuantities,
        [Item.W_FairyBow]: defaultItemQuantities,
        [Item.W_Bloodrider]: defaultItemQuantities,
        [Item.W_PhoenixWing]: defaultItemQuantities,
        [Item.W_DivineWind]: defaultItemQuantities,
        [Item.W_StarShot]: defaultItemQuantities,
        [Item.W_CelestialBow]: defaultItemQuantities,
        [Item.W_ReapersEmbrace]: defaultItemQuantities,
        [Item.W_QuincyBow]: defaultItemQuantities,
        [Item.W_SilentShadow]: defaultItemQuantities,

        [Item.W_Stick]: defaultItemQuantities,
        [Item.W_SpiraledWand]: defaultItemQuantities,
        [Item.W_NightStick]: defaultItemQuantities,
        [Item.W_DragonsBreath]: defaultItemQuantities,
        [Item.W_PoseidonsWand]: defaultItemQuantities,
        [Item.W_BrokenDeathstick]: defaultItemQuantities,
        [Item.W_HibidyKibity]: defaultItemQuantities,
        [Item.W_MagicalWand]: defaultItemQuantities,
        [Item.W_BlackDahliaWand]: defaultItemQuantities,
        [Item.W_FrostedTips]: defaultItemQuantities,
        [Item.W_RelicWand]: defaultItemQuantities,
        [Item.W_ZappingWand]: defaultItemQuantities,
        [Item.W_IcewindWand]: defaultItemQuantities,
        [Item.W_BlazeRod]: defaultItemQuantities,
        [Item.W_ToxicWand]: defaultItemQuantities,
        [Item.W_StarWand]: defaultItemQuantities,
        [Item.W_Deathstick]: defaultItemQuantities,

        [Item.W_SpellbindersTome]: defaultItemQuantities,
        [Item.W_Triforce]: defaultItemQuantities,
        [Item.W_WizardsLedger]: defaultItemQuantities,
        [Item.W_NaturesReturn]: defaultItemQuantities,
        [Item.W_KingBible]: defaultItemQuantities,
        [Item.W_BookOfTongues]: defaultItemQuantities,

        [Item.PG_SerpentsRing]: defaultItemQuantities,
        [Item.PG_WindspeakersRing]: defaultItemQuantities,
        [Item.PG_IronFoxLocket]: defaultItemQuantities,
        [Item.PG_HeirloomCloak]: defaultItemQuantities,
        [Item.PG_ElvenGildedGuardian]: defaultItemQuantities,
        [Item.PG_FrostFireRing]: defaultItemQuantities,
        [Item.PG_GirdleOfTheGoldenNecromancer]: defaultItemQuantities,
        [Item.PG_RingOfConstellations]: defaultItemQuantities,
        [Item.PG_TantrumsLuckyLoop]: defaultItemQuantities,
        [Item.PG_GoldPlatedCape]: defaultItemQuantities,
        [Item.PG_AssassinSignet]: defaultItemQuantities,
        [Item.PG_CopperWristband]: defaultItemQuantities,
        [Item.PG_GolemsGrasp]: defaultItemQuantities,
        [Item.PG_ShieldbearersAmulet]: defaultItemQuantities,
        [Item.PG_MementoMoriLocket]: defaultItemQuantities,
        [Item.PG_GuardiansChainmailCollar]: defaultItemQuantities,
        [Item.PG_NaturesAureateBarrier]: defaultItemQuantities,
        [Item.PG_QuintsFishingGarb]: defaultItemQuantities,
        [Item.PG_GineshsBottleOpener]: defaultItemQuantities,
        [Item.PG_AngelistasPotionPopper]: defaultItemQuantities,
        [Item.PG_MunchkinsRing]: defaultItemQuantities,
        [Item.PG_CloakOfModestApproachability]: defaultItemQuantities,
        [Item.PG_SummonersSign]: defaultItemQuantities,
        [Item.PG_CoralReef]: defaultItemQuantities,
        [Item.PG_IceQueensEarrings]: defaultItemQuantities,
        [Item.PG_RingOfTheOneHandedWarrior]: defaultItemQuantities,
        [Item.PG_CompactedLimeGreenRing]: defaultItemQuantities,
        [Item.PG_LittleCompactedLimeGreenRing]: defaultItemQuantities,
        [Item.PG_InfiniteLoop]: defaultItemQuantities,
        [Item.PG_JaysesRing]: defaultItemQuantities,
        [Item.PG_BellsShiningRing]: defaultItemQuantities,
        [Item.PG_RingOfViolentViolet]: defaultItemQuantities,
        [Item.PG_CloudedDarkness]: defaultItemQuantities,
        [Item.PG_CapeOfPureDarkness]: defaultItemQuantities,
        [Item.PG_BloodGemRing]: defaultItemQuantities,
        [Item.PG_PurpletratorsRing]: defaultItemQuantities,
        [Item.PG_AquamoorsBlessing]: defaultItemQuantities,
        [Item.PG_EssenceConduit]: defaultItemQuantities,
        [Item.PG_ArcaneEmber]: defaultItemQuantities,
        [Item.PG_PhoenixsRespite]: defaultItemQuantities,
        [Item.PG_SoulBindingRing]: defaultItemQuantities,
        [Item.PG_BloodiedVitality]: defaultItemQuantities,
        [Item.PG_VenomousBite]: defaultItemQuantities,
        [Item.PG_RuneboundRing]: defaultItemQuantities,
        [Item.PG_FrostbiteLoop]: defaultItemQuantities,
        [Item.PG_PhantomBand]: defaultItemQuantities,
        [Item.PG_EmbergraspRing]: defaultItemQuantities,
        [Item.PG_SoulshackleVeil]: defaultItemQuantities,
        [Item.PG_StormweaverCloak]: defaultItemQuantities,
        [Item.PG_CursedSignet]: defaultItemQuantities,
        [Item.PG_EnigmaLoop]: defaultItemQuantities,
        [Item.PG_VampiricSeal]: defaultItemQuantities,
        [Item.PG_Wraithband]: defaultItemQuantities,
        [Item.PG_NecroticSignet]: defaultItemQuantities,
        [Item.PG_CorruptedTearRing]: defaultItemQuantities,
        [Item.PG_DeathwhisperWrap]: defaultItemQuantities,
        [Item.PG_VeilstormCirclet]: defaultItemQuantities,
        [Item.PG_SoulforgeRing]: defaultItemQuantities,
        [Item.PG_CursedEternityRing]: defaultItemQuantities,
        [Item.PG_MidnightsVeilBand]: defaultItemQuantities,
        [Item.PG_DreadmorneLoop]: defaultItemQuantities,
        [Item.PG_EbonfireRobelet]: defaultItemQuantities,
        [Item.PG_EverlastingSpiritCircle]: defaultItemQuantities,
        [Item.PG_EnigmaticShadowweaveRing]: defaultItemQuantities,
        [Item.PG_BloodmoonLoop]: defaultItemQuantities,
        [Item.PG_DreamcasterDrapery]: defaultItemQuantities,
        [Item.PG_CelestialSolaceBand]: defaultItemQuantities,
        [Item.PG_TalismanOfVeiledShadows]: defaultItemQuantities,
        [Item.PG_PendantOfAncientWisdom]: defaultItemQuantities,
        [Item.PG_SerpentsCoilChoker]: defaultItemQuantities,
        [Item.PG_ArcaneSigilCollar]: defaultItemQuantities,
        [Item.PG_ResilientHeartstone]: defaultItemQuantities,
        [Item.PG_FrostfireShardPendant]: defaultItemQuantities,
        [Item.PG_PhoenixFeatherTorque]: defaultItemQuantities,
        [Item.PG_SoulstoneReliquary]: defaultItemQuantities,
        [Item.PG_EnchantedElixirNecklace]: defaultItemQuantities,
        [Item.PG_NecroticGraspTalisman]: defaultItemQuantities,
        [Item.PG_CelestialStarfallPendant]: defaultItemQuantities,
        [Item.PG_CultistsMask]: defaultItemQuantities,
        [Item.PG_DepthStrider]: defaultItemQuantities,
        [Item.PG_GoldenGauntlets]: defaultItemQuantities,
        [Item.PG_BeastVanquisherBoots]: defaultItemQuantities,

        // [Item.PG_SamsTestItem]: defaultItemQuantities,

        [Item.AG_VestiasSoothingSignet]: defaultItemQuantities,
        [Item.AG_ViolasDinnerReminder]: defaultItemQuantities,
        [Item.AG_WolfMedallion]: defaultItemQuantities,
        [Item.AG_PepperedCollar]: defaultItemQuantities,
        [Item.AG_StuddedNecklace]: defaultItemQuantities,
        [Item.AG_TimeHerosRing]: defaultItemQuantities,
        [Item.AG_TheDarkLordsEmbrace]: defaultItemQuantities,
        [Item.AG_Boros]: defaultItemQuantities,
        [Item.AG_MysteriousRing]: defaultItemQuantities,
        [Item.AG_DarkPustulesRing]: defaultItemQuantities,
        [Item.AG_RingOfAnOceanMan]: defaultItemQuantities,
        [Item.AG_ForestsHaven]: defaultItemQuantities,
        [Item.AG_DreamWeaversEmbrace]: defaultItemQuantities,
        [Item.AG_ObsidianEcho]: defaultItemQuantities,
        [Item.AG_AmuletOfCursedFate]: defaultItemQuantities,
        [Item.AG_MagicBoots]: defaultItemQuantities,

        [Item.VT_OnyxGem]: defaultItemQuantities,
        [Item.VT_GhostPlasma]: defaultItemQuantities,
        [Item.VT_GoblinCloth]: defaultItemQuantities,
        [Item.VT_WellWornRing]: defaultItemQuantities,
        [Item.VT_RingOfNearPerfection]: defaultItemQuantities,
        [Item.VT_CubicZirconia]: defaultItemQuantities,
        [Item.VT_QuartzRing]: defaultItemQuantities,
        [Item.VT_DukesDiary]: defaultItemQuantities,
        [Item.VT_Potato]: defaultItemQuantities,
        [Item.VT_SkeletonFemur]: defaultItemQuantities,
        [Item.VT_RedMushroom]: defaultItemQuantities,
        [Item.VT_AbyssalShard]: defaultItemQuantities,
        [Item.VT_Sapphire]: defaultItemQuantities,
        [Item.VT_Fossil]: defaultItemQuantities,
        [Item.VT_BardsDiary]: defaultItemQuantities,
        [Item.VT_EverlastingCandle]: defaultItemQuantities,
        [Item.VT_Diamond]: defaultItemQuantities,
        [Item.VT_WhispersOfTheVoid]: defaultItemQuantities,
        [Item.VT_EmeraldDust]: defaultItemQuantities,
        [Item.VT_RubyDust]: defaultItemQuantities,
        [Item.VT_StellarWeaveRing]: defaultItemQuantities,
        [Item.VT_Eyeball]: defaultItemQuantities,
        [Item.VT_TreantBranch]: defaultItemQuantities,
        [Item.VT_SapphireDust]: defaultItemQuantities,
        [Item.VT_PileOfAsh]: defaultItemQuantities,
        [Item.VT_Amber]: defaultItemQuantities,
        [Item.VT_Skull]: defaultItemQuantities,
        [Item.VT_GoldNugget]: defaultItemQuantities,
        [Item.VT_BrokenSword]: defaultItemQuantities,
        [Item.VT_BrokenIronBand]: defaultItemQuantities,
        [Item.VT_RatFur]: defaultItemQuantities,
        [Item.VT_Ruby]: defaultItemQuantities,
        [Item.VT_ChunkOfIron]: defaultItemQuantities,
        [Item.VT_FangOfTheBloodMoon]: defaultItemQuantities,
        [Item.VT_OgreEntrails]: defaultItemQuantities,
        [Item.VT_Emerald]: defaultItemQuantities,
        [Item.VT_Cabbage]: defaultItemQuantities,

        [Item.CI_FragmentOfFate]: defaultItemQuantities,
        [Item.CI_PlayerSoulFragment]: defaultItemQuantities,
        [Item.CI_LatrosKnavishBand]: defaultItemQuantities,
        [Item.CI_WeddingRing]: defaultItemQuantities,
        [Item.CI_PeachRing]: defaultItemQuantities,
        [Item.CI_RingOfDelerium]: defaultItemQuantities,
        [Item.CI_LifesSimpleRing]: defaultItemQuantities,
        [Item.CI_SwampOgresRing]: defaultItemQuantities,
        [Item.CI_RoyalBrooch]: defaultItemQuantities,
        [Item.CI_SuperStar]: defaultItemQuantities,
        [Item.CI_Ouro]: defaultItemQuantities,
        [Item.CI_AlloyedBend]: defaultItemQuantities,
        [Item.CI_ProudlyPinkRing]: defaultItemQuantities,
        [Item.CI_ApposSignet]: defaultItemQuantities,
        [Item.CI_CelestialEternity]: defaultItemQuantities,
        [Item.CI_BloodhuntersRing]: defaultItemQuantities,
        [Item.CI_EbonstarBand]: defaultItemQuantities,
        [Item.CI_LuminousEnigma]: defaultItemQuantities,
        [Item.CI_RadiantSunstoneBand]: defaultItemQuantities,
        [Item.CI_EchoingWhisperNecklace]: defaultItemQuantities,
        [Item.CI_FeyEnchantmentLocket]: defaultItemQuantities
    };
};

export const getTotalNumberOfWeaponItems = (): number => {
    return getAllItemIds().filter((itemId) => getItemData(itemId).itemType === ItemType.Weapon).length;
};

export const getTotalNumberOfGearItems = (): number => {
    return getAllItemIds().filter((itemId) => getItemData(itemId).itemType === ItemType.ActiveGear || getItemData(itemId).itemType === ItemType.PassiveGear).length;
};

export const getTotalNumberOfVendorTrashItems = (): number => {
    return getAllItemIds().filter((itemId) => getItemData(itemId).itemType === ItemType.VendorTrash).length;
};

export const getTotalNumberOfCollectorsItems = (): number => {
    return getAllItemIds().filter((itemId) => getItemData(itemId).itemType === ItemType.CollectorsItem).length;
};

const ItemData: Map<Item, ItemConfig> = new Map();

const setItemData = (itemConfig: ItemConfig) => {
    const existingEntryWithId = ItemData.get(itemConfig.itemId);

    if (existingEntryWithId) {
        throw new Error(`ItemConfig with id ${itemConfig.itemId} already exists! Error in Item data file.`);
    }

    ItemData.set(itemConfig.itemId, itemConfig);
};

export const getItemData = (id: Item): ItemConfig => {
    const ItemConfig = ItemData.get(id);

    if (!ItemConfig) {
        throw new Error(`ItemConfig with id ${id} does not exist! Error in Item data file.`);
    }

    return ItemConfig;
};
//#endregion

//#region Passive Stats
/****************************************************/
/*                                                  */
/*               passive Stats                      */
/*                                                  */
/****************************************************/

export enum PassiveStatType {
    Defence = 0,
    DamageBoost = 1
}

export interface PassiveStat {
    passiveStatType: PassiveStatType;
    damageType: DamageType;
    statAmount: number;
}

export const getPassiveStatAggregateInLoadout = (queriedStatType: PassiveStatType, queriedDamageType: DamageType, items: Item[], returnAsFriendlyPercentageString: boolean = false) => {
    let statResult: number = 0.0;

    // console.log({ queriedStatType, queriedDamageType });

    for (let i = 0; i < items.length; i++) {
        if (items[i] === -1) continue;

        const itemConfig = getItemData(items[i]);

        const { passiveStats } = itemConfig;

        for (let j = 0; j < passiveStats.length; j++) {
            const { passiveStatType, damageType, statAmount } = passiveStats[j];

            if (damageType === queriedDamageType && passiveStatType === queriedStatType) {
                statResult += statAmount;
            }
        }
    }

    if (returnAsFriendlyPercentageString) {
        return `${statResult > 0 ? "+" : statResult < 0 ? "-" : ""}${Math.floor(statResult * 100)}%`;
    } else {
        return statResult;
    }
};
//#endregion

//#region Abilities
/****************************************************/
/*                                                  */
/*               Abilities                          */
/*                                                  */
/****************************************************/
interface PassiveAbilityDefinition {
    passiveAbility: PassiveAbility;
    abilityParameter: number;
}

export interface ActiveAbilityDefinition {
    ability: ActiveAbility;
    abilityParameter: number;
    abilityDurationInSeconds: number;
    abilityCharges: number;
    abilityCooldownInSeconds: number;
}

export enum PassiveAbility {
    MoveSpeedUp = 0,
    MoveSpeedDown = 1,
    GainHealthOverTime = 2,
    LoseHealthOverTime = 3,
    ProjectileDistanceUp = 5,
    ProjectileDistanceDown = 6,
    MaxHealthIncrease = 7,
    MaxHealthDecrease = 8,
    GoldRush = 9,
    PlayerDamageLeech = 10,
    // WeaponCooldownReset = 11, // TODO LATER
    WaterWalking = 12,
    // CorpseExplosion = 13, // TODO LATER
    Ethereal = 14,
    Revive = 15,
    AttackSpeedUp = 16,
    AttackSpeedDown = 17,
    Information = 18,
    ExtractionProtection = 19,
    // ScavMode = 20, // TODO LATER
    ExtractionSpeedUp = 21,
    // SmolMode = 22, // TODO LATER
    // BigMode = 23, // TODO LATER
    PlayerDamageUp = 24,
    PlayerDamageDown = 25,
    NPCDamageUp = 26,
    NPCDamageDown = 27,
    Incognito = 28
}

export enum ActiveAbility {
    None = 0,
    // Relocation = 1, // TODO LATER
    Invisibility = 2,
    // ZoomOut = 3, // TODO LATER
    // DamageReduction = 4, // TODO LATER
    // DamageBoost = 5, // TODO LATER
    // ProjectilePierce = 6, // TODO LATER
    TimerExtender = 7,
    Suicide = 8,
    // Random = 9, // TODO LATER
    Heal = 10,
    Dash = 11,
    Extract = 12
    // OgreGang = 13 // TODO LATER
}

const NoneActiveAbility: ActiveAbilityDefinition = {
    ability: ActiveAbility.None,
    abilityParameter: 0,
    abilityCharges: -1,
    abilityCooldownInSeconds: 0,
    abilityDurationInSeconds: 0
};

export const getMaxHealthAdjustmentFromLoadout = (items: Item[]) => {
    let statResult: number = 0;

    // console.log({ queriedStatType, queriedDamageType });

    for (let i = 0; i < items.length; i++) {
        if (items[i] === -1) continue;

        const itemConfig = getItemData(items[i]);

        const { passiveAbilities } = itemConfig;

        for (let j = 0; j < passiveAbilities.length; j++) {
            const { passiveAbility, abilityParameter } = passiveAbilities[j];

            if (passiveAbility === PassiveAbility.MaxHealthDecrease) {
                statResult -= abilityParameter;
            }

            if (passiveAbility === PassiveAbility.MaxHealthIncrease) {
                statResult += abilityParameter;
            }
        }
    }
    return statResult;
};
//#endregion

//#region Item Rolling
/****************************************************/
/*                                                  */
/*               Item Rolling                       */
/*                                                  */
/****************************************************/

// How rare is it to get an item of a particular quality?
const commonRarity = 1 / 1;
const uncommonRarity = 1 / 5;
const rareRarity = 1 / 25;
const epicRarity = 1 / 115;
const legendaryRarity = 1 / 325;

export const ItemRarityThresholds = [
    { rarity: ItemRarity.Common, threshold: 1 - commonRarity },
    { rarity: ItemRarity.Uncommon, threshold: 1 - uncommonRarity },
    { rarity: ItemRarity.Rare, threshold: 1 - rareRarity },
    { rarity: ItemRarity.Epic, threshold: 1 - epicRarity },
    { rarity: ItemRarity.Legendary, threshold: 1 - legendaryRarity }
];

// How many items should you get based on a roll? i.e. how rare should it be to roll 10 items vs 1 item and everywhere in between
const chanceOfOneItemInChest = 1 / 1;
const chanceOfTwoItemsInChest = 1 / 3;
const chanceOfThreeItemsInChest = 1 / 4;
const chanceOfFourItemsInChest = 1 / 6;
const chanceOfFiveItemsInChest = 1 / 7;
const chanceOfTenItemsInChest = 1 / 100;

export const ChestItemQuantityThresholds = [
    { quantity: 1, threshold: 1 - chanceOfOneItemInChest },
    { quantity: 2, threshold: 1 - chanceOfTwoItemsInChest },
    { quantity: 3, threshold: 1 - chanceOfThreeItemsInChest },
    { quantity: 4, threshold: 1 - chanceOfFourItemsInChest },
    { quantity: 5, threshold: 1 - chanceOfFiveItemsInChest },
    { quantity: 10, threshold: 1 - chanceOfTenItemsInChest }
];

const chanceOfOneItemInEnemyDrop = 1 / 1;
const chanceOfTwoItemsInEnemyDrop = 1 / 10;
const chanceOfThreeItemsInEnemyDrop = 1 / 50;

export const EnemyDropItemQuantityThresholds = [
    { quantity: 1, threshold: 1 - chanceOfOneItemInEnemyDrop },
    { quantity: 2, threshold: 1 - chanceOfTwoItemsInEnemyDrop },
    { quantity: 3, threshold: 1 - chanceOfThreeItemsInEnemyDrop }
];

interface LootTableData {
    CommonItems: Item[];
    UncommonItems: Item[];
    RareItems: Item[];
    EpicItems: Item[];
    LegendaryItems: Item[];
}

export const RollNumberOfItemsInEnemyDrop = (randomNumber: number): number => {
    let numberOfItems = 0;

    for (const quantityThreshold of EnemyDropItemQuantityThresholds) {
        if (randomNumber >= quantityThreshold.threshold) {
            // console.log(`Threshold met! Giving player at least ${quantityThreshold.quantity} items due to roll (${quantityThreshold.threshold} threshold was met)`);
            numberOfItems = quantityThreshold.quantity;
        } else {
            // console.log(`Threshold not met, **NOT** giving player ${quantityThreshold.quantity} items (threshold for this many items: ${quantityThreshold.threshold})`);
        }
    }

    // console.log(`Rolled ${numberOfItems} items in enemy drop due to roll ${randomNumber}`);

    return numberOfItems;
};

export const RollNumberOfItemsInChest = (randomNumber: number): number => {
    let numberOfItems = 0;

    for (const quantityThreshold of ChestItemQuantityThresholds) {
        if (randomNumber >= quantityThreshold.threshold) {
            // console.log(`Threshold met! Giving player at least ${quantityThreshold.quantity} items due to roll (${quantityThreshold.threshold} threshold was met)`);
            numberOfItems = quantityThreshold.quantity;
        } else {
            // console.log(`Threshold not met, **NOT** giving player ${quantityThreshold.quantity} items (threshold for this many items: ${quantityThreshold.threshold})`);
        }
    }

    // console.log(`Rolled ${numberOfItems} items in loot chest due to roll ${randomNumber}`);

    return numberOfItems;
};

export const RollSingleItem = (itemRarityRoll: number, lootTable: LootTableData): Item => {
    // console.log("\n");
    let item: Item;

    let itemRarity: ItemRarity = ItemRarity.Common;

    for (const rarity of ItemRarityThresholds) {
        if (itemRarityRoll >= rarity.threshold) {
            itemRarity = rarity.rarity;
        }
    }

    // console.log(`This items rarity resulted in a roll against the table ${ItemRarity[itemRarity]} due to roll ${itemRarityRoll}`);

    switch (itemRarity) {
        case ItemRarity.Common:
            item = lootTable.CommonItems[Math.floor(Math.random() * lootTable.CommonItems.length)];
            break;
        case ItemRarity.Uncommon:
            item = lootTable.UncommonItems[Math.floor(Math.random() * lootTable.UncommonItems.length)];
            break;
        case ItemRarity.Rare:
            item = lootTable.RareItems[Math.floor(Math.random() * lootTable.RareItems.length)];
            break;
        case ItemRarity.Epic:
            item = lootTable.EpicItems[Math.floor(Math.random() * lootTable.EpicItems.length)];
            break;
        case ItemRarity.Legendary:
            item = lootTable.LegendaryItems[Math.floor(Math.random() * lootTable.LegendaryItems.length)];
            break;
    }

    return item;
};

export const RollItems = (lootTable: LootTableData, numberOfItems: number, overrideDropRoll: number = 0): { rolledItems: Item[]; rolledQuantities: number[] } => {
    const rolledItems: Item[] = [];
    const rolledQuantityMultipliers: number[] = [];

    for (let i = 0; i < numberOfItems; i++) {
        let itemRoll = Math.random();

        // console.log("item roll before", itemRoll);

        if (overrideDropRoll !== 0) {
            itemRoll = overrideDropRoll;
        }

        // console.log("item roll after", itemRoll);

        const rolledItem = RollSingleItem(itemRoll, lootTable);

        if (rolledItem === undefined) continue;

        // console.log("Item rolled as a result of boost:", getItemData(rolledItem).itemRarity, rolledItem, Item[rolledItem]);

        // console.log("Rolled item: " + Item[rolledItem] + ` of ID ${rolledItem} (rarity: ${ItemRarity[getItemData(rolledItem).itemRarity]} due to roll ${itemRoll})`);

        if (rolledItems.includes(rolledItem)) {
            // console.log(`Item ${Item[rolledItem]} is not unique so far in this roll! Incrementing quantity multiplier..`);
            const index = rolledItems.indexOf(rolledItem);
            rolledQuantityMultipliers[index] += 1;
        } else {
            // console.log(`Item ${Item[rolledItem]} is unique so far in this roll! Adding..`);
            rolledItems.push(rolledItem);
            rolledQuantityMultipliers.push(1);
        }
    }

    // console.log({ rolledItems, rolledQuantityMultipliers });

    const rolledQuantities: number[] = [];

    for (let i = 0; i < rolledItems.length; i++) {
        const { maxItemQuantityPerRoll } = getItemData(rolledItems[i]);

        rolledQuantities.push(maxItemQuantityPerRoll * rolledQuantityMultipliers[i]);
    }

    return { rolledItems, rolledQuantities };
};

export const RollItemsFromLootTable = (lootTableId: LootTable): { rolledItems: Item[]; rolledQuantities: number[] } => {
    const lootTable = lootTableData.get(lootTableId);

    if (!lootTable) {
        throw new Error(`LootTable with id ${lootTableId} does not exist! Error in Item data file.`);
    }

    const numberOfItems = RollNumberOfItemsInChest(Math.random());

    return RollItems(lootTable, numberOfItems);
};
//#endregion

//#region Item Definitions!
/****************************************************/
/*                                                  */
/*             Item Definitions                     */
/*                                                  */
/****************************************************/

/*****************************/
/* STAVES! Item IDs 0 -> 99  */
/*****************************/

setItemData({
    itemId: Item.W_ApprenticesStaff,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Common,
    maxItemQuantityPerRoll: 1,
    name: "item__W_ApprenticesStaff__name",
    loreText: "item__W_ApprenticesStaff__lore",
    gameplayText: "item__W_ApprenticesStaff__gameplay",
    inventorySpriteId: "weapons/Apprentices_Staff.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2,
    tooltipSlotSpriteOffsetLeft: 34,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.W_FlamespitterStaff,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Uncommon,
    maxItemQuantityPerRoll: 1,
    name: "item__W_FlamespitterStaff__name",
    loreText: "item__W_FlamespitterStaff__lore",
    gameplayText: "item__W_FlamespitterStaff__gameplay",
    inventorySpriteId: "weapons/Flamespitter_Staff.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2,
    tooltipSlotSpriteOffsetLeft: 34,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.W_StaffOfTheSnake,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Uncommon,
    maxItemQuantityPerRoll: 1,
    name: "item__W_StaffOfTheSnake__name",
    loreText: "item__W_StaffOfTheSnake__lore",
    gameplayText: "item__W_StaffOfTheSnake__gameplay",
    inventorySpriteId: "weapons/Toxic_Staff.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2,
    tooltipSlotSpriteOffsetLeft: 34,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.W_ArchmagesSidekick,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Common,
    maxItemQuantityPerRoll: 1,
    name: "item__W_ArchmagesSidekick__name",
    loreText: "item__W_ArchmagesSidekick__lore",
    gameplayText: "item__W_ArchmagesSidekick__gameplay",
    inventorySpriteId: "weapons/Arcane_Staff.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2,
    tooltipSlotSpriteOffsetLeft: 34,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.W_SamsStirringStick,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Uncommon,
    maxItemQuantityPerRoll: 1,
    name: "item__W_SamsStirringStick__name",
    loreText: "item__W_SamsStirringStick__lore",
    gameplayText: "item__W_SamsStirringStick__gameplay",
    inventorySpriteId: "weapons/staff_samsStirringStick.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.5,
    smallInventorySlotSpriteOffsetLeft: 0,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 1.75,
    tooltipSlotSpriteOffsetLeft: 34,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 2.5,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.W_CandlelightStaff,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Common,
    maxItemQuantityPerRoll: 1,
    name: "item__W_CandlelightStaff__name",
    loreText: "item__W_CandlelightStaff__lore",
    gameplayText: "item__W_CandlelightStaff__gameplay",
    inventorySpriteId: "weapons/staff_candleLight.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.5,
    smallInventorySlotSpriteOffsetLeft: 0,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 1.5,
    tooltipSlotSpriteOffsetLeft: 34,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 2.5,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.W_TwistedBranch,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Common,
    maxItemQuantityPerRoll: 1,
    name: "item__W_TwistedBranch__name",
    loreText: "item__W_TwistedBranch__lore",
    gameplayText: "item__W_TwistedBranch__gameplay",
    inventorySpriteId: "weapons/staff_twistedBranch.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.5,
    smallInventorySlotSpriteOffsetLeft: 0,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 1.75,
    tooltipSlotSpriteOffsetLeft: 34,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 2.5,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Treant]
});

setItemData({
    itemId: Item.W_IronStaff,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Common,
    maxItemQuantityPerRoll: 1,
    name: "item__W_IronStaff__name",
    loreText: "item__W_IronStaff__lore",
    gameplayText: "item__W_IronStaff__gameplay",
    inventorySpriteId: "weapons/staff_ironStaff.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.75,
    smallInventorySlotSpriteOffsetLeft: 0,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2,
    tooltipSlotSpriteOffsetLeft: 34,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 2.75,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies]
});

setItemData({
    itemId: Item.W_PharaohsStaff,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Epic,
    maxItemQuantityPerRoll: 1,
    name: "item__W_PharaohsStaff__name",
    loreText: "item__W_PharaohsStaff__lore",
    gameplayText: "item__W_PharaohsStaff__gameplay",
    inventorySpriteId: "weapons/staff_pharoahsStaff.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.5,
    smallInventorySlotSpriteOffsetLeft: 0,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 1.5,
    tooltipSlotSpriteOffsetLeft: 34,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 2.5,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Ghost]
});

setItemData({
    itemId: Item.W_FairyStaff,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Epic,
    maxItemQuantityPerRoll: 1,
    name: "item__W_FairyStaff__name",
    loreText: "item__W_FairyStaff__lore",
    gameplayText: "item__W_FairyStaff__gameplay",
    inventorySpriteId: "weapons/staff_fairyStaff.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.5,
    smallInventorySlotSpriteOffsetLeft: 0,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 1.5,
    tooltipSlotSpriteOffsetLeft: 34,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 2.25,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.GRONK]
});

setItemData({
    itemId: Item.W_MidasStaff,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Legendary,
    maxItemQuantityPerRoll: 1,
    name: "item__W_MidasStaff__name",
    loreText: "item__W_MidasStaff__lore",
    gameplayText: "item__W_MidasStaff__gameplay",
    inventorySpriteId: "weapons/staff_midasStaff.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.8,
    smallInventorySlotSpriteOffsetLeft: 0,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2,
    tooltipSlotSpriteOffsetLeft: 34,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 2.8,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.DarkWizard]
});

setItemData({
    itemId: Item.W_DemonsEye,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Rare,
    maxItemQuantityPerRoll: 1,
    name: "item__W_DemonsEye__name",
    loreText: "item__W_DemonsEye__lore",
    gameplayText: "item__W_DemonsEye__gameplay",
    inventorySpriteId: "weapons/staff_demonsEye.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.5,
    smallInventorySlotSpriteOffsetLeft: 0,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2,
    tooltipSlotSpriteOffsetLeft: 34,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 2.5,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.DarkWizard]
});

setItemData({
    itemId: Item.W_SlimeStaff,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Uncommon,
    maxItemQuantityPerRoll: 1,
    name: "item__W_SlimeStaff__name",
    loreText: "item__W_SlimeStaff__lore",
    gameplayText: "item__W_SlimeStaff__gameplay",
    inventorySpriteId: "weapons/staff_slimeStaff.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.5,
    smallInventorySlotSpriteOffsetLeft: 0,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 1.5,
    tooltipSlotSpriteOffsetLeft: 34,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 2.25,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.GiantRat]
});

setItemData({
    itemId: Item.W_BeesOnAStick,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Legendary,
    maxItemQuantityPerRoll: 1,
    name: "item__W_BeesOnAStick__name",
    loreText: "item__W_BeesOnAStick__lore",
    gameplayText: "item__W_BeesOnAStick__gameplay",
    inventorySpriteId: "weapons/staff_beeStick.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.5,
    smallInventorySlotSpriteOffsetLeft: 0,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 1.75,
    tooltipSlotSpriteOffsetLeft: 34,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 2.25,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.GRONK]
});

setItemData({
    itemId: Item.W_LichsStaff,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Uncommon,
    maxItemQuantityPerRoll: 1,
    name: "item__W_LichsStaff__name",
    loreText: "item__W_LichsStaff__lore",
    gameplayText: "item__W_LichsStaff__gameplay",
    inventorySpriteId: "weapons/staff_lichStaff.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.5,
    smallInventorySlotSpriteOffsetLeft: 0,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 1.75,
    tooltipSlotSpriteOffsetLeft: 34,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 2.25,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Ghost, LootSources.Skeleton, LootSources.DarkWizard]
});

setItemData({
    itemId: Item.W_FrostFlicker,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Epic,
    maxItemQuantityPerRoll: 1,
    name: "item__W_FrostFlicker__name",
    loreText: "item__W_FrostFlicker__lore",
    gameplayText: "item__W_FrostFlicker__gameplay",
    inventorySpriteId: "weapons/staff_frostFlicker.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.5,
    smallInventorySlotSpriteOffsetLeft: 0,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 1.5,
    tooltipSlotSpriteOffsetLeft: 34,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 2.5,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Ghost, LootSources.Treant, LootSources.DarkWizard]
});

/***********************************/
/* CROSSBOWS! Item IDs 100 -> 199  */
/***********************************/

setItemData({
    itemId: Item.W_CrudeCrossbow,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Common,
    maxItemQuantityPerRoll: 1,
    name: "item__W_CrudeCrossbow__name",
    loreText: "item__W_CrudeCrossbow__lore",
    gameplayText: "item__W_CrudeCrossbow__gameplay",
    inventorySpriteId: "weapons/Crossbow_Wood.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.8,
    smallInventorySlotSpriteOffsetLeft: -1,
    smallInventorySlotSpriteOffsetTop: 2,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2,
    tooltipSlotSpriteOffsetLeft: 40,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: -1,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.W_GuardsCrossbow,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Common,
    maxItemQuantityPerRoll: 1,
    name: "item__W_GuardsCrossbow__name",
    loreText: "item__W_GuardsCrossbow__lore",
    gameplayText: "item__W_GuardsCrossbow__gameplay",
    inventorySpriteId: "weapons/crossbow_guards.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.8,
    smallInventorySlotSpriteOffsetLeft: -1,
    smallInventorySlotSpriteOffsetTop: 2,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2,
    tooltipSlotSpriteOffsetLeft: 40,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: -1,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.W_SteelCrossbow,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Common,
    maxItemQuantityPerRoll: 1,
    name: "item__W_SteelCrossbow__name",
    loreText: "item__W_SteelCrossbow__lore",
    gameplayText: "item__W_SteelCrossbow__gameplay",
    inventorySpriteId: "weapons/Crossbow_Steel.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.8,
    smallInventorySlotSpriteOffsetLeft: -1,
    smallInventorySlotSpriteOffsetTop: 2,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2,
    tooltipSlotSpriteOffsetLeft: 40,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: -1,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.W_ReliqueryCrossbow,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Uncommon,
    maxItemQuantityPerRoll: 1,
    name: "item__W_ReliqueryCrossbow__name",
    loreText: "item__W_ReliqueryCrossbow__lore",
    gameplayText: "item__W_ReliqueryCrossbow__gameplay",
    inventorySpriteId: "weapons/Crossbow_Red.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.8,
    smallInventorySlotSpriteOffsetLeft: -1,
    smallInventorySlotSpriteOffsetTop: 2,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2,
    tooltipSlotSpriteOffsetLeft: 40,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: -1,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllLootChests, LootSources.Ghost]
});

setItemData({
    itemId: Item.W_EldritchBlasters,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Rare,
    maxItemQuantityPerRoll: 1,
    name: "item__W_EldritchBlasters__name",
    loreText: "item__W_EldritchBlasters__lore",
    gameplayText: "item__W_EldritchBlasters__gameplay",
    inventorySpriteId: "weapons/crossbow_eldritchBlasters.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.8,
    smallInventorySlotSpriteOffsetLeft: 1,
    smallInventorySlotSpriteOffsetTop: 2,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2,
    tooltipSlotSpriteOffsetLeft: 40,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 1,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.W_DarylsInstinct,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Epic,
    maxItemQuantityPerRoll: 1,
    name: "item__W_DarylsInstinct__name",
    loreText: "item__W_DarylsInstinct__lore",
    gameplayText: "item__W_DarylsInstinct__gameplay",
    inventorySpriteId: "weapons/crossbow_darylsInstinct.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.8,
    smallInventorySlotSpriteOffsetLeft: -1,
    smallInventorySlotSpriteOffsetTop: 2,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2,
    tooltipSlotSpriteOffsetLeft: 40,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: -1,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.W_FathersBane,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Epic,
    maxItemQuantityPerRoll: 1,
    name: "item__W_FathersBane__name",
    loreText: "item__W_FathersBane__lore",
    gameplayText: "item__W_FathersBane__gameplay",
    inventorySpriteId: "weapons/crossbow_fathersBane.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.8,
    smallInventorySlotSpriteOffsetLeft: -1,
    smallInventorySlotSpriteOffsetTop: 2,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2,
    tooltipSlotSpriteOffsetLeft: 40,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: -1,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.W_WitchHuntersRepeaters,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Rare,
    maxItemQuantityPerRoll: 1,
    name: "item__W_WitchHuntersRepeaters__name",
    loreText: "item__W_WitchHuntersRepeaters__lore",
    gameplayText: "item__W_WitchHuntersRepeaters__gameplay",
    inventorySpriteId: "weapons/crossbow_witchHuntersRepeater.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.8,
    smallInventorySlotSpriteOffsetLeft: -1,
    smallInventorySlotSpriteOffsetTop: 2,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2,
    tooltipSlotSpriteOffsetLeft: 40,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: -1,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.DarkWizard]
});

setItemData({
    itemId: Item.W_WyvernsTalon,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Legendary,
    maxItemQuantityPerRoll: 1,
    name: "item__W_WyvernsTalon__name",
    loreText: "item__W_WyvernsTalon__lore",
    gameplayText: "item__W_WyvernsTalon__gameplay",
    inventorySpriteId: "weapons/crossbow_wyvernTalon.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.8,
    smallInventorySlotSpriteOffsetLeft: -1,
    smallInventorySlotSpriteOffsetTop: 2,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2,
    tooltipSlotSpriteOffsetLeft: 36,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: -1,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.GRONK]
});

setItemData({
    itemId: Item.W_TheCrossBow,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Legendary,
    maxItemQuantityPerRoll: 1,
    name: "item__W_TheCrossBow__name",
    loreText: "item__W_TheCrossBow__lore",
    gameplayText: "item__W_TheCrossBow__gameplay",
    inventorySpriteId: "weapons/crossbow_theCrossBow.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.8,
    smallInventorySlotSpriteOffsetLeft: -1,
    smallInventorySlotSpriteOffsetTop: 2,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2,
    tooltipSlotSpriteOffsetLeft: 36,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: -1,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.DarkWizard]
});

/***********************************/
/*   BOWS! Item IDs 200 -> 299     */
/***********************************/

setItemData({
    itemId: Item.W_RangersGuillotine,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Legendary,
    maxItemQuantityPerRoll: 1,
    name: "item__W_RangersGuillotine__name",
    loreText: "item__W_RangersGuillotine__lore",
    gameplayText: "item__W_RangersGuillotine__gameplay",
    inventorySpriteId: "weapons/bow_rangersGuillotine.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.2,
    smallInventorySlotSpriteOffsetLeft: 3,
    smallInventorySlotSpriteOffsetTop: -2,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 1.5,
    tooltipSlotSpriteOffsetLeft: 40,
    tooltipSlotSpriteOffsetTop: 25,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 2,
    largeInventorySlotSpriteOffsetLeft: 5,
    largeInventorySlotSpriteOffsetTop: -3,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.IronFox]
});

setItemData({
    itemId: Item.W_DavidBowie,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Common,
    maxItemQuantityPerRoll: 1,
    name: "item__W_DavidBowie__name",
    loreText: "item__W_DavidBowie__lore",
    gameplayText: "item__W_DavidBowie__gameplay",
    inventorySpriteId: "weapons/bow_woodenShortbow.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.5,
    smallInventorySlotSpriteOffsetLeft: 4,
    smallInventorySlotSpriteOffsetTop: -3,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2,
    tooltipSlotSpriteOffsetLeft: 40,
    tooltipSlotSpriteOffsetTop: 29,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 2.5,
    largeInventorySlotSpriteOffsetLeft: 7,
    largeInventorySlotSpriteOffsetTop: -5,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.W_Emberstrike,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Uncommon,
    maxItemQuantityPerRoll: 1,
    name: "item__W_Emberstrike__name",
    loreText: "item__W_Emberstrike__lore",
    gameplayText: "item__W_Emberstrike__gameplay",
    inventorySpriteId: "weapons/bow_emberStrike.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.5,
    smallInventorySlotSpriteOffsetLeft: 0,
    smallInventorySlotSpriteOffsetTop: -4,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 29,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 2.25,
    largeInventorySlotSpriteOffsetLeft: 1,
    largeInventorySlotSpriteOffsetTop: 0,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Skeleton]
});

setItemData({
    itemId: Item.W_MoonShadow,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Rare,
    maxItemQuantityPerRoll: 1,
    name: "item__W_MoonShadow__name",
    loreText: "item__W_MoonShadow__lore",
    gameplayText: "item__W_MoonShadow__gameplay",
    inventorySpriteId: "weapons/bow_moonShadow.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.5,
    smallInventorySlotSpriteOffsetLeft: 3,
    smallInventorySlotSpriteOffsetTop: -2,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 1.5,
    tooltipSlotSpriteOffsetLeft: 45,
    tooltipSlotSpriteOffsetTop: 23,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 2.3,
    largeInventorySlotSpriteOffsetLeft: 5,
    largeInventorySlotSpriteOffsetTop: -3,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Ghost]
});

setItemData({
    itemId: Item.W_FairyBow,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Rare,
    maxItemQuantityPerRoll: 1,
    name: "item__W_FairyBow__name",
    loreText: "item__W_FairyBow__lore",
    gameplayText: "item__W_FairyBow__gameplay",
    inventorySpriteId: "weapons/bow_fairyBow.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.7,
    smallInventorySlotSpriteOffsetLeft: 3,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2,
    tooltipSlotSpriteOffsetLeft: 40,
    tooltipSlotSpriteOffsetTop: 29,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 2.75,
    largeInventorySlotSpriteOffsetLeft: 5,
    largeInventorySlotSpriteOffsetTop: -3,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Treant, LootSources.IronFox]
});

setItemData({
    itemId: Item.W_Bloodrider,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Rare,
    maxItemQuantityPerRoll: 1,
    name: "item__W_Bloodrider__name",
    loreText: "item__W_Bloodrider__lore",
    gameplayText: "item__W_Bloodrider__gameplay",
    inventorySpriteId: "weapons/bow_bloodRider.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.2,
    smallInventorySlotSpriteOffsetLeft: 3,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 1.5,
    tooltipSlotSpriteOffsetLeft: 40,
    tooltipSlotSpriteOffsetTop: 29,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 2,
    largeInventorySlotSpriteOffsetLeft: 5,
    largeInventorySlotSpriteOffsetTop: -1,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Ogre, LootSources.GRONK]
});

setItemData({
    itemId: Item.W_PhoenixWing,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Epic,
    maxItemQuantityPerRoll: 1,
    name: "item__W_PhoenixWing__name",
    loreText: "item__W_PhoenixWing__lore",
    gameplayText: "item__W_PhoenixWing__gameplay",
    inventorySpriteId: "weapons/bow_phoenixWing.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.5,
    smallInventorySlotSpriteOffsetLeft: 4,
    smallInventorySlotSpriteOffsetTop: -3,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2,
    tooltipSlotSpriteOffsetLeft: 45,
    tooltipSlotSpriteOffsetTop: 29,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 2.3,
    largeInventorySlotSpriteOffsetLeft: 8,
    largeInventorySlotSpriteOffsetTop: 0,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.IronFox]
});

setItemData({
    itemId: Item.W_DivineWind,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Legendary,
    maxItemQuantityPerRoll: 1,
    name: "item__W_DivineWind__name",
    loreText: "item__W_DivineWind__lore",
    gameplayText: "item__W_DivineWind__gameplay",
    inventorySpriteId: "weapons/bow_divineWind.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.5,
    smallInventorySlotSpriteOffsetLeft: 3,
    smallInventorySlotSpriteOffsetTop: -2,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 1.5,
    tooltipSlotSpriteOffsetLeft: 45,
    tooltipSlotSpriteOffsetTop: 23,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 2.3,
    largeInventorySlotSpriteOffsetLeft: 5,
    largeInventorySlotSpriteOffsetTop: -3,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.IronFox, LootSources.GRONK]
});

setItemData({
    itemId: Item.W_StarShot,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Rare,
    maxItemQuantityPerRoll: 1,
    name: "item__W_StarShot__name",
    loreText: "item__W_StarShot__lore",
    gameplayText: "item__W_StarShot__gameplay",
    inventorySpriteId: "weapons/bow_starShot.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.5,
    smallInventorySlotSpriteOffsetLeft: 4,
    smallInventorySlotSpriteOffsetTop: -3,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 1.75,
    tooltipSlotSpriteOffsetLeft: 45,
    tooltipSlotSpriteOffsetTop: 29,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 2.3,
    largeInventorySlotSpriteOffsetLeft: 7,
    largeInventorySlotSpriteOffsetTop: -5,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.DarkWizard]
});

setItemData({
    itemId: Item.W_CelestialBow,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Legendary,
    maxItemQuantityPerRoll: 1,
    name: "item__W_CelestialBow__name",
    loreText: "item__W_CelestialBow__lore",
    gameplayText: "item__W_CelestialBow__gameplay",
    inventorySpriteId: "weapons/bow_celestialBow.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.5,
    smallInventorySlotSpriteOffsetLeft: 4,
    smallInventorySlotSpriteOffsetTop: -3,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 1.7,
    tooltipSlotSpriteOffsetLeft: 40,
    tooltipSlotSpriteOffsetTop: 29,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 2.3,
    largeInventorySlotSpriteOffsetLeft: 5,
    largeInventorySlotSpriteOffsetTop: -3,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.W_ReapersEmbrace,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Epic,
    maxItemQuantityPerRoll: 1,
    name: "item__W_ReapersEmbrace__name",
    loreText: "item__W_ReapersEmbrace__lore",
    gameplayText: "item__W_ReapersEmbrace__gameplay",
    inventorySpriteId: "weapons/bow_reapersEmbrace.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.5,
    smallInventorySlotSpriteOffsetLeft: 4,
    smallInventorySlotSpriteOffsetTop: -3,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 1.75,
    tooltipSlotSpriteOffsetLeft: 40,
    tooltipSlotSpriteOffsetTop: 29,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 2.3,
    largeInventorySlotSpriteOffsetLeft: 5,
    largeInventorySlotSpriteOffsetTop: -3,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.W_QuincyBow,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Epic,
    maxItemQuantityPerRoll: 1,
    name: "item__W_QuincyBow__name",
    loreText: "item__W_QuincyBow__lore",
    gameplayText: "item__W_QuincyBow__gameplay",
    inventorySpriteId: "weapons/bow_quincyBow.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.5,
    smallInventorySlotSpriteOffsetLeft: 0,
    smallInventorySlotSpriteOffsetTop: 1,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 1.75,
    tooltipSlotSpriteOffsetLeft: 40,
    tooltipSlotSpriteOffsetTop: 29,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 2.5,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.W_SilentShadow,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Legendary,
    maxItemQuantityPerRoll: 1,
    name: "item__W_SilentShadow__name",
    loreText: "item__W_SilentShadow__lore",
    gameplayText: "item__W_SilentShadow__gameplay",
    inventorySpriteId: "weapons/bow_silentShadow.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.5,
    smallInventorySlotSpriteOffsetLeft: 3,
    smallInventorySlotSpriteOffsetTop: -2,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 1.5,
    tooltipSlotSpriteOffsetLeft: 45,
    tooltipSlotSpriteOffsetTop: 23,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 2.3,
    largeInventorySlotSpriteOffsetLeft: 5,
    largeInventorySlotSpriteOffsetTop: -3,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.GiantRat]
});

/***********************************/
/*   Wands! Item IDs 300 -> 399    */
/***********************************/

setItemData({
    itemId: Item.W_Stick,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Common,
    maxItemQuantityPerRoll: 1,
    name: "item__W_Stick__name",
    loreText: "item__W_Stick__lore",
    gameplayText: "item__W_Stick__gameplay",
    inventorySpriteId: "weapons/wand_stick.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.7,
    smallInventorySlotSpriteOffsetLeft: 4,
    smallInventorySlotSpriteOffsetTop: -3,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2,
    tooltipSlotSpriteOffsetLeft: 45,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 1,
    largeInventorySlotSpriteOffsetTop: 1,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Treant]
});

setItemData({
    itemId: Item.W_SpiraledWand,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Common,
    maxItemQuantityPerRoll: 1,
    name: "item__W_SpiraledWand__name",
    loreText: "item__W_SpiraledWand__lore",
    gameplayText: "item__W_SpiraledWand__gameplay",
    inventorySpriteId: "weapons/wand_spiraleWand.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.7,
    smallInventorySlotSpriteOffsetLeft: 4,
    smallInventorySlotSpriteOffsetTop: -3,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2,
    tooltipSlotSpriteOffsetLeft: 45,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 1,
    largeInventorySlotSpriteOffsetTop: 1,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.W_NightStick,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Common,
    maxItemQuantityPerRoll: 1,
    name: "item__W_NightStick__name",
    loreText: "item__W_NightStick__lore",
    gameplayText: "item__W_NightStick__gameplay",
    inventorySpriteId: "weapons/wand_nightStick.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.7,
    smallInventorySlotSpriteOffsetLeft: 4,
    smallInventorySlotSpriteOffsetTop: -3,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2.5,
    tooltipSlotSpriteOffsetLeft: 40,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 1,
    largeInventorySlotSpriteOffsetTop: 1,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.W_DragonsBreath,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Epic,
    maxItemQuantityPerRoll: 1,
    name: "item__W_DragonsBreath__name",
    loreText: "item__W_DragonsBreath__lore",
    gameplayText: "item__W_DragonsBreath__gameplay",
    inventorySpriteId: "weapons/wand_dragonsBreath.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.7,
    smallInventorySlotSpriteOffsetLeft: 4,
    smallInventorySlotSpriteOffsetTop: -5,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2,
    tooltipSlotSpriteOffsetLeft: 40,
    tooltipSlotSpriteOffsetTop: 30,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 5,
    largeInventorySlotSpriteOffsetTop: -10,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Skeleton]
});

setItemData({
    itemId: Item.W_PoseidonsWand,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Epic,
    maxItemQuantityPerRoll: 1,
    name: "item__W_PoseidonsWand__name",
    loreText: "item__W_PoseidonsWand__lore",
    gameplayText: "item__W_PoseidonsWand__gameplay",
    inventorySpriteId: "weapons/wand_poissidonsWandn.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.7,
    smallInventorySlotSpriteOffsetLeft: 0,
    smallInventorySlotSpriteOffsetTop: 1,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2.5,
    tooltipSlotSpriteOffsetLeft: 40,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 2,
    largeInventorySlotSpriteOffsetTop: -3,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Treant, LootSources.GiantRat]
});

setItemData({
    itemId: Item.W_BrokenDeathstick,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Rare,
    maxItemQuantityPerRoll: 1,
    name: "item__W_BrokenDeathstick__name",
    loreText: "item__W_BrokenDeathstick__lore",
    gameplayText: "item__W_BrokenDeathstick__gameplay",
    inventorySpriteId: "weapons/wand_brokenDeathstick.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.7,
    smallInventorySlotSpriteOffsetLeft: 4,
    smallInventorySlotSpriteOffsetTop: -3,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2.5,
    tooltipSlotSpriteOffsetLeft: 40,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 3,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Treant]
});

setItemData({
    itemId: Item.W_HibidyKibity,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Legendary,
    maxItemQuantityPerRoll: 1,
    name: "item__W_HibidyKibity__name",
    loreText: "item__W_HibidyKibity__lore",
    gameplayText: "item__W_HibidyKibity__gameplay",
    inventorySpriteId: "weapons/wand_hibidyKibity.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 6,
    smallInventorySlotSpriteOffsetTop: -4,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 4,
    tooltipSlotSpriteOffsetLeft: 50,
    tooltipSlotSpriteOffsetTop: 30,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 4,
    largeInventorySlotSpriteOffsetLeft: 10,
    largeInventorySlotSpriteOffsetTop: -8,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.GRONK, LootSources.IronFox]
});

setItemData({
    itemId: Item.W_MagicalWand,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Legendary,
    maxItemQuantityPerRoll: 1,
    name: "item__W_MagicalWand__name",
    loreText: "item__W_MagicalWand__lore",
    gameplayText: "item__W_MagicalWand__gameplay",
    inventorySpriteId: "weapons/wand_magicalWand.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.7,
    smallInventorySlotSpriteOffsetLeft: 4,
    smallInventorySlotSpriteOffsetTop: -3,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2.5,
    tooltipSlotSpriteOffsetLeft: 40,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 1,
    largeInventorySlotSpriteOffsetTop: -1,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.IronFox]
});

setItemData({
    itemId: Item.W_BlackDahliaWand,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Common,
    maxItemQuantityPerRoll: 1,
    name: "item__W_BlackDahliaWand__name",
    loreText: "item__W_BlackDahliaWand__lore",
    gameplayText: "item__W_BlackDahliaWand__gameplay",
    inventorySpriteId: "weapons/wand_blackDahlia.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.7,
    smallInventorySlotSpriteOffsetLeft: 4,
    smallInventorySlotSpriteOffsetTop: -3,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2.5,
    tooltipSlotSpriteOffsetLeft: 45,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.W_FrostedTips,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Rare,
    maxItemQuantityPerRoll: 1,
    name: "item__W_FrostedTips__name",
    loreText: "item__W_FrostedTips__lore",
    gameplayText: "item__W_FrostedTips__gameplay",
    inventorySpriteId: "weapons/wand_frostedTips.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.7,
    smallInventorySlotSpriteOffsetLeft: 4,
    smallInventorySlotSpriteOffsetTop: -3,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2.5,
    tooltipSlotSpriteOffsetLeft: 45,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.W_RelicWand,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Rare,
    maxItemQuantityPerRoll: 1,
    name: "item__W_RelicWand__name",
    loreText: "item__W_RelicWand__lore",
    gameplayText: "item__W_RelicWand__gameplay",
    inventorySpriteId: "weapons/wand_relicWand.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.7,
    smallInventorySlotSpriteOffsetLeft: 4,
    smallInventorySlotSpriteOffsetTop: -3,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2.5,
    tooltipSlotSpriteOffsetLeft: 45,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 5,
    largeInventorySlotSpriteOffsetTop: -3,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Skeleton]
});

setItemData({
    itemId: Item.W_ZappingWand,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Uncommon,
    maxItemQuantityPerRoll: 1,
    name: "item__W_ZappingWand__name",
    loreText: "item__W_ZappingWand__lore",
    gameplayText: "item__W_ZappingWand__gameplay",
    inventorySpriteId: "weapons/wand_zappingWand.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.7,
    smallInventorySlotSpriteOffsetLeft: 4,
    smallInventorySlotSpriteOffsetTop: -3,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2.5,
    tooltipSlotSpriteOffsetLeft: 40,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.W_IcewindWand,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Uncommon,
    maxItemQuantityPerRoll: 1,
    name: "item__W_IcewindWand__name",
    loreText: "item__W_IcewindWand__lore",
    gameplayText: "item__W_IcewindWand__gameplay",
    inventorySpriteId: "weapons/wand_icewindWand.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.7,
    smallInventorySlotSpriteOffsetLeft: 4,
    smallInventorySlotSpriteOffsetTop: -3,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2.5,
    tooltipSlotSpriteOffsetLeft: 40,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.W_BlazeRod,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Uncommon,
    maxItemQuantityPerRoll: 1,
    name: "item__W_BlazeRod__name",
    loreText: "item__W_BlazeRod__lore",
    gameplayText: "item__W_BlazeRod__gameplay",
    inventorySpriteId: "weapons/wand_blazeRod.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.7,
    smallInventorySlotSpriteOffsetLeft: 4,
    smallInventorySlotSpriteOffsetTop: -3,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2.5,
    tooltipSlotSpriteOffsetLeft: 40,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Skeleton]
});

setItemData({
    itemId: Item.W_ToxicWand,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Uncommon,
    maxItemQuantityPerRoll: 1,
    name: "item__W_ToxicWand__name",
    loreText: "item__W_ToxicWand__lore",
    gameplayText: "item__W_ToxicWand__gameplay",
    inventorySpriteId: "weapons/wand_toxicWand.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.7,
    smallInventorySlotSpriteOffsetLeft: 4,
    smallInventorySlotSpriteOffsetTop: -3,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2.5,
    tooltipSlotSpriteOffsetLeft: 40,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.GiantRat]
});

setItemData({
    itemId: Item.W_StarWand,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Rare,
    maxItemQuantityPerRoll: 1,
    name: "item__W_StarWand__name",
    loreText: "item__W_StarWand__lore",
    gameplayText: "item__W_StarWand__gameplay",
    inventorySpriteId: "weapons/wand_starWand.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.7,
    smallInventorySlotSpriteOffsetLeft: 4,
    smallInventorySlotSpriteOffsetTop: -3,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2,
    tooltipSlotSpriteOffsetLeft: 40,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Ogre, LootSources.DarkWizard]
});

/*******************&&&&&&****************/
/*   Spellbooks! Item IDs 400 -> 499     */
/********************&&&&&&***************/

setItemData({
    itemId: Item.W_SpellbindersTome,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Common,
    maxItemQuantityPerRoll: 1,
    name: "item__W_SpellbindersTome__name",
    loreText: "item__W_SpellbindersTome__lore",
    gameplayText: "item__W_SpellbindersTome__gameplay",
    inventorySpriteId: "weapons/spell_spellbindersTome.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.7,
    smallInventorySlotSpriteOffsetLeft: 0,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2,
    tooltipSlotSpriteOffsetLeft: 42,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.W_Triforce,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Epic,
    maxItemQuantityPerRoll: 1,
    name: "item__W_Triforce__name",
    loreText: "item__W_Triforce__lore",
    gameplayText: "item__W_Triforce__gameplay",
    inventorySpriteId: "weapons/spell_triForce.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.7,
    smallInventorySlotSpriteOffsetLeft: 0,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2,
    tooltipSlotSpriteOffsetLeft: 42,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.DarkWizard]
});

setItemData({
    itemId: Item.W_WizardsLedger,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Uncommon,
    maxItemQuantityPerRoll: 1,
    name: "item__W_WizardsLedger__name",
    loreText: "item__W_WizardsLedger__lore",
    gameplayText: "item__W_WizardsLedger__gameplay",
    inventorySpriteId: "weapons/spell_wizardsLedger.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.7,
    smallInventorySlotSpriteOffsetLeft: 0,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2,
    tooltipSlotSpriteOffsetLeft: 42,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Ghost, LootSources.DarkWizard]
});

setItemData({
    itemId: Item.W_NaturesReturn,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Legendary,
    maxItemQuantityPerRoll: 1,
    name: "item__W_NaturesReturn__name",
    loreText: "item__W_NaturesReturn__lore",
    gameplayText: "item__W_NaturesReturn__gameplay",
    inventorySpriteId: "weapons/spell_naturesReturn.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.7,
    smallInventorySlotSpriteOffsetLeft: 0,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2,
    tooltipSlotSpriteOffsetLeft: 42,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Treant]
});

setItemData({
    itemId: Item.W_KingBible,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Epic,
    maxItemQuantityPerRoll: 1,
    name: "item__W_KingBible__name",
    loreText: "item__W_KingBible__lore",
    gameplayText: "item__W_KingBible__gameplay",
    inventorySpriteId: "weapons/spell_kingBible.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.7,
    smallInventorySlotSpriteOffsetLeft: 0,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2,
    tooltipSlotSpriteOffsetLeft: 42,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Skeleton, LootSources.Ghost]
});

setItemData({
    itemId: Item.W_BookOfTongues,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Legendary,
    maxItemQuantityPerRoll: 1,
    name: "item__W_BookOfTongues__name",
    loreText: "item__W_BookOfTongues__lore",
    gameplayText: "item__W_BookOfTongues__gameplay",
    inventorySpriteId: "weapons/spell_bookOfTongues.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.7,
    smallInventorySlotSpriteOffsetLeft: 0,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2,
    tooltipSlotSpriteOffsetLeft: 42,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.DarkWizard, LootSources.GRONK]
});

setItemData({
    itemId: Item.W_Deathstick,
    itemType: ItemType.Weapon,
    itemRarity: ItemRarity.Legendary,
    maxItemQuantityPerRoll: 1,
    name: "item__W_Deathstick__name",
    loreText: "item__W_Deathstick__lore",
    gameplayText: "item__W_Deathstick__gameplay",
    inventorySpriteId: "weapons/wand_Deathstick.png",
    smallInventorySlotRotation: -45,
    smallInventorySlotSpriteScale: 1.7,
    smallInventorySlotSpriteOffsetLeft: 0,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: -45,
    tooltipSlotSpriteScale: 2,
    tooltipSlotSpriteOffsetLeft: 42,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: -45,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.IronFox, LootSources.GRONK, LootSources.Ghost, LootSources.Treant]
});

/*********************************************/
/*   Passive Gear! Item IDs 1000 -> 1999     */
/*********************************************/

setItemData({
    itemId: Item.PG_SerpentsRing,
    itemType: ItemType.PassiveGear,
    itemRarity: ItemRarity.Common,
    maxItemQuantityPerRoll: 1,
    name: "item__PG_SerpentsRing__name",
    loreText: "item__PG_SerpentsRing__lore",
    gameplayText: "item__PG_SerpentsRing__gameplay",
    inventorySpriteId: "gear/ring_002.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    passiveStats: [
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Toxic,
            statAmount: 0.065
        }
    ],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.GiantRat]
});

setItemData({
    itemId: Item.PG_WindspeakersRing,
    itemType: ItemType.PassiveGear,
    itemRarity: ItemRarity.Uncommon,
    maxItemQuantityPerRoll: 1,
    name: "item__PG_WindspeakersRing__name",
    loreText: "item__PG_WindspeakersRing__lore",
    gameplayText: "item__PG_WindspeakersRing__gameplay",
    inventorySpriteId: "gear/ring_001.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    passiveStats: [
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Nature,
            statAmount: 0.05
        }
    ],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.Information,
            abilityParameter: 0
        }
    ],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.PG_IronFoxLocket,
    itemType: ItemType.ActiveGear,
    itemRarity: ItemRarity.Legendary,
    maxItemQuantityPerRoll: 1,
    name: "item__PG_IronFoxLocket__name",
    loreText: "item__PG_IronFoxLocket__lore",
    gameplayText: "item__PG_IronFoxLocket__gameplay",
    inventorySpriteId: "gear/necklace_03.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3.5,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Physical,
            statAmount: 0.13
        },
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Fire,
            statAmount: 0.13
        },
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Toxic,
            statAmount: 0.13
        },
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Arcane,
            statAmount: 0.13
        },
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Frost,
            statAmount: 0.13
        },
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Lightning,
            statAmount: 0.13
        },
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Holy,
            statAmount: 0.13
        },
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Shadow,
            statAmount: 0.13
        },
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Chaos,
            statAmount: 0.13
        },
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Nature,
            statAmount: 0.13
        },
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Bleed,
            statAmount: 0.13
        }
    ],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.Information,
            abilityParameter: 0
        },
        {
            passiveAbility: PassiveAbility.MaxHealthIncrease,
            abilityParameter: 30

        }
    ],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.IronFox]
});

setItemData({
    itemId: Item.PG_HeirloomCloak,
    itemType: ItemType.ActiveGear,
    itemRarity: ItemRarity.Common,
    maxItemQuantityPerRoll: 1,
    name: "item__PG_HeirloomCloak__name",
    loreText: "item__PG_HeirloomCloak__lore",
    gameplayText: "item__PG_HeirloomCloak__gameplay",
    inventorySpriteId: "gear/armor_14.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3.5,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: {
        ability: ActiveAbility.Dash,
        abilityParameter: 0.20,
        abilityDurationInSeconds: 2,
        abilityCharges: 3,
        abilityCooldownInSeconds: 9
    },
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.PassiveGear,
    itemId: Item.PG_ElvenGildedGuardian,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Common,
    name: "item__PG_ElvenGildedGuardian__name",
    loreText: "item__PG_ElvenGildedGuardian__lore",
    gameplayText: "item__PG_ElvenGildedGuardian__gameplay",
    inventorySpriteId: "gear/belt_02.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.ExtractionSpeedUp,
            abilityParameter: 0.05
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.PG_FrostFireRing,
    itemType: ItemType.PassiveGear,
    itemRarity: ItemRarity.Uncommon,
    maxItemQuantityPerRoll: 1,
    name: "item__PG_FrostFireRing__name",
    loreText: "item__PG_FrostFireRing__lore",
    gameplayText: "item__PG_FrostFireRing__gameplay",
    inventorySpriteId: "gear/ring_004.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    passiveStats: [
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Fire,
            statAmount: 0.07
        },
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Frost,
            statAmount: 0.085
        },
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Fire,
            statAmount: -0.65
        }
    ],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_GirdleOfTheGoldenNecromancer,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Rare,
    name: "item__PG_GirdleOfTheGoldenNecromancer__name",
    loreText: "item__PG_GirdleOfTheGoldenNecromancer__lore",
    gameplayText: "item__PG_GirdleOfTheGoldenNecromancer__gameplay",
    inventorySpriteId: "gear/belt_01.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.MoveSpeedUp,
            abilityParameter: 0.06
        }
    ],
    activeAbility: {
        ability: ActiveAbility.Heal,
        abilityParameter: 10,
        abilityDurationInSeconds: 0,
        abilityCharges: 3,
        abilityCooldownInSeconds: 15
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Ghost, LootSources.Skeleton]
});

setItemData({
    itemType: ItemType.PassiveGear,
    itemId: Item.PG_RingOfConstellations,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Uncommon,
    name: "item__PG_RingOfConstellations__name",
    loreText: "item__PG_RingOfConstellations__lore",
    gameplayText: "item__PG_RingOfConstellations__gameplay",
    inventorySpriteId: "gear/ring_050.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.ProjectileDistanceUp,
            abilityParameter: 0.06
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.PassiveGear,
    itemId: Item.PG_TantrumsLuckyLoop,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Common,
    name: "item__PG_TantrumsLuckyLoop__name",
    loreText: "item__PG_TantrumsLuckyLoop__lore",
    gameplayText: "item__PG_TantrumsLuckyLoop__gameplay",
    inventorySpriteId: "gear/ring_056.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.MoveSpeedUp,
            abilityParameter: 0.03
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.GiantRat]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_GoldPlatedCape,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Common,
    name: "item__PG_GoldPlatedCape__name",
    loreText: "item__PG_GoldPlatedCape__lore",
    gameplayText: "item__PG_GoldPlatedCape__gameplay",
    inventorySpriteId: "gear/armor_15.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.PlayerDamageUp,
            abilityParameter: 0.04
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_AssassinSignet,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Common,
    name: "item__PG_AssassinSignet__name",
    loreText: "item__PG_AssassinSignet__lore",
    gameplayText: "item__PG_AssassinSignet__gameplay",
    inventorySpriteId: "gear/brooch_02.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.ProjectileDistanceDown,
            abilityParameter: 0.03
        },
        {
            passiveAbility: PassiveAbility.MoveSpeedDown,
            abilityParameter: 0.03
        }
    ],
    activeAbility: {
        ability: ActiveAbility.Invisibility,
        abilityParameter: 1,
        abilityDurationInSeconds: 5,
        abilityCharges: 2,
        abilityCooldownInSeconds: 10
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.PassiveGear,
    itemId: Item.PG_CopperWristband,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Common,
    name: "item__PG_CopperWristband__name",
    loreText: "item__PG_CopperWristband__lore",
    gameplayText: "item__PG_CopperWristband__gameplay",
    inventorySpriteId: "gear/ring_115.png",
    passiveStats: [
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Physical,
            statAmount: 0.065
        },
    ],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.PassiveGear,
    itemId: Item.PG_GolemsGrasp,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Uncommon,
    name: "item__PG_GolemsGrasp__name",
    loreText: "item__PG_GolemsGrasp__lore",
    gameplayText: "item__PG_GolemsGrasp__gameplay",
    inventorySpriteId: "gear/ring_060.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.MaxHealthIncrease,
            abilityParameter: 30
        },
        {
            passiveAbility: PassiveAbility.MoveSpeedDown,
            abilityParameter: 0.06
        },
        {
            passiveAbility: PassiveAbility.WaterWalking,
            abilityParameter: 0.06
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Ogre]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_ShieldbearersAmulet,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Uncommon,
    name: "item__PG_ShieldbearersAmulet__name",
    loreText: "item__PG_ShieldbearersAmulet__lore",
    gameplayText: "item__PG_ShieldbearersAmulet__gameplay",
    inventorySpriteId: "gear/necklace_02.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.NPCDamageUp,
            abilityParameter: 0.065
        }
    ],
    activeAbility: {
        ability: ActiveAbility.Heal,
        abilityParameter: 10,
        abilityDurationInSeconds: 0,
        abilityCharges: 1,
        abilityCooldownInSeconds: 0
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.PassiveGear,
    itemId: Item.PG_MementoMoriLocket,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Common,
    name: "item__PG_MementoMoriLocket__name",
    loreText: "item__PG_MementoMoriLocket__lore",
    gameplayText: "item__PG_MementoMoriLocket__gameplay",
    inventorySpriteId: "gear/necklace_09.png",
    passiveStats: [
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Shadow,
            statAmount: 0.085
        },
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Chaos,
            statAmount: -0.065
        }
    ],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_GuardiansChainmailCollar,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Common,
    name: "item__PG_GuardiansChainmailCollar__name",
    loreText: "item__PG_GuardiansChainmailCollar__lore",
    gameplayText: "item__PG_GuardiansChainmailCollar__gameplay",
    inventorySpriteId: "gear/necklace_20.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.AttackSpeedDown,
            abilityParameter: 0.05
        }
    ],
    activeAbility: {
        ability: ActiveAbility.Heal,
        abilityParameter: 15,
        abilityDurationInSeconds: 0,
        abilityCharges: 3,
        abilityCooldownInSeconds: 15
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_NaturesAureateBarrier,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Uncommon,
    name: "item__PG_NaturesAureateBarrier__name",
    loreText: "item__PG_NaturesAureateBarrier__lore",
    gameplayText: "item__PG_NaturesAureateBarrier__gameplay",
    inventorySpriteId: "gear/belt_03.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.Incognito,
            abilityParameter: 0
        },
        {
            passiveAbility: PassiveAbility.PlayerDamageDown,
            abilityParameter: 0.055
        }
    ],
    activeAbility: {
        ability: ActiveAbility.TimerExtender,
        abilityParameter: 30,
        abilityDurationInSeconds: 0,
        abilityCharges: 2,
        abilityCooldownInSeconds: 30
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Treant]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_QuintsFishingGarb,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Common,
    name: "item__PG_QuintsFishingGarb__name",
    loreText: "item__PG_QuintsFishingGarb__lore",
    gameplayText: "item__PG_QuintsFishingGarb__gameplay",
    inventorySpriteId: "gear/armor_06.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.AttackSpeedUp,
            abilityParameter: 0.05
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.DarkWizard]
});

setItemData({
    itemType: ItemType.PassiveGear,
    itemId: Item.PG_GineshsBottleOpener,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Epic,
    name: "item__PG_GineshsBottleOpener__name",
    loreText: "item__PG_GineshsBottleOpener__lore",
    gameplayText: "item__PG_GineshsBottleOpener__gameplay",
    inventorySpriteId: "gear/ring_105.png",
    passiveStats: [
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Physical,
            statAmount: 0.12
        },
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Arcane,
            statAmount: 0.12
        },
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Bleed,
            statAmount: 0.12
        },
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Chaos,
            statAmount: 0.12
        },
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Fire,
            statAmount: 0.12
        },
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Frost,
            statAmount: 0.12
        },
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Holy,
            statAmount: 0.12
        },
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Lightning,
            statAmount: 0.12
        },
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Nature,
            statAmount: 0.12
        },
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Shadow,
            statAmount: 0.12
        },
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Toxic,
            statAmount: 0.12
        }
    ],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.LoseHealthOverTime,
            abilityParameter: 0.3
        },
        {
            passiveAbility: PassiveAbility.MoveSpeedUp,
            abilityParameter: 0.085
        },
        {
            passiveAbility: PassiveAbility.ProjectileDistanceUp,
            abilityParameter: 0.085
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.GiantRat]
});

setItemData({
    itemType: ItemType.PassiveGear,
    itemId: Item.PG_AngelistasPotionPopper,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Common,
    name: "item__PG_AngelistasPotionPopper__name",
    loreText: "item__PG_AngelistasPotionPopper__lore",
    gameplayText: "item__PG_AngelistasPotionPopper__gameplay",
    inventorySpriteId: "gear/ring_107.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.GainHealthOverTime,
            abilityParameter: 0.2
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.PassiveGear,
    itemId: Item.PG_MunchkinsRing,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Common,
    name: "item__PG_MunchkinsRing__name",
    loreText: "item__PG_MunchkinsRing__lore",
    gameplayText: "item__PG_MunchkinsRing__gameplay",
    inventorySpriteId: "gear/ring_118.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.MoveSpeedUp,
            abilityParameter: 0.05
        },
        {
            passiveAbility: PassiveAbility.MaxHealthDecrease,
            abilityParameter: 10
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_CloakOfModestApproachability,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Rare,
    name: "item__PG_CloakOfModestApproachability__name",
    loreText: "item__PG_CloakOfModestApproachability__lore",
    gameplayText: "item__PG_CloakOfModestApproachability__gameplay",
    inventorySpriteId: "gear/armor_52.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.ProjectileDistanceUp,
            abilityParameter: 0.06
        }
    ],
    activeAbility: {
        ability: ActiveAbility.TimerExtender,
        abilityParameter: 30,
        abilityDurationInSeconds: 0,
        abilityCharges: 2,
        abilityCooldownInSeconds: 30
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.PassiveGear,
    itemId: Item.PG_SummonersSign,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Common,
    name: "item__PG_SummonersSign__name",
    loreText: "item__PG_SummonersSign__lore",
    gameplayText: "item__PG_SummonersSign__gameplay",
    inventorySpriteId: "gear/brooch_01.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.MaxHealthIncrease,
            abilityParameter: 10
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_CoralReef,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Uncommon,
    name: "item__PG_CoralReef__name",
    loreText: "item__PG_CoralReef__lore",
    gameplayText: "item__PG_CoralReef__gameplay",
    inventorySpriteId: "gear/necklace_13.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.WaterWalking,
            abilityParameter: 0.085
        },
        {
            passiveAbility: PassiveAbility.ExtractionSpeedUp,
            abilityParameter: -0.10
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_IceQueensEarrings,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Epic,
    name: "item__PG_IceQueensEarrings__name",
    loreText: "item__PG_IceQueensEarrings__lore",
    gameplayText: "item__PG_IceQueensEarrings__gameplay",
    inventorySpriteId: "gear/earring_01.png",
    passiveStats: [
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Frost,
            statAmount: 0.065
        },
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Frost,
            statAmount: 0.05
        }
    ],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.PlayerDamageUp,
            abilityParameter: 0.04
        }
    ],
    activeAbility: {
        ability: ActiveAbility.Dash,
        abilityParameter: 0.20,
        abilityDurationInSeconds: 3,
        abilityCharges: 2,
        abilityCooldownInSeconds: 7
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.PassiveGear,
    itemId: Item.PG_RingOfTheOneHandedWarrior,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Rare,
    name: "item__PG_RingOfTheOneHandedWarrior__name",
    loreText: "item__PG_RingOfTheOneHandedWarrior__lore",
    gameplayText: "item__PG_RingOfTheOneHandedWarrior__gameplay",
    inventorySpriteId: "gear/ring_116.png",
    passiveStats: [
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Physical,
            statAmount: 0.12
        },
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Bleed,
            statAmount: 0.12
        },
    ],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.MaxHealthDecrease,
            abilityParameter: 10
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Ghost]
});

setItemData({
    itemType: ItemType.PassiveGear,
    itemId: Item.PG_CompactedLimeGreenRing,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Uncommon,
    name: "item__PG_CompactedLimeGreenRing__name",
    loreText: "item__PG_CompactedLimeGreenRing__lore",
    gameplayText: "item__PG_CompactedLimeGreenRing__gameplay",
    inventorySpriteId: "gear/ring_032.png",
    passiveStats: [
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Toxic,
            statAmount: 0.07
        }
    ],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.ProjectileDistanceUp,
            abilityParameter: 0.05
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.PassiveGear,
    itemId: Item.PG_LittleCompactedLimeGreenRing,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Rare,
    name: "item__PG_LittleCompactedLimeGreenRing__name",
    loreText: "item__PG_LittleCompactedLimeGreenRing__lore",
    gameplayText: "item__PG_LittleCompactedLimeGreenRing__gameplay",
    inventorySpriteId: "gear/ring_044.png",
    passiveStats: [
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Toxic,
            statAmount: 0.13
        }
    ],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.ProjectileDistanceUp,
            abilityParameter: 0.06
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_InfiniteLoop,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Epic,
    name: "item__PG_InfiniteLoop__name",
    loreText: "item__PG_InfiniteLoop__lore",
    gameplayText: "item__PG_InfiniteLoop__gameplay",
    inventorySpriteId: "gear/ring_053.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.MaxHealthDecrease,
            abilityParameter: 10
        },
        {
            passiveAbility: PassiveAbility.GainHealthOverTime,
            abilityParameter: 0.5
        }
    ],
    activeAbility: {
        ability: ActiveAbility.TimerExtender,
        abilityParameter: 45,
        abilityDurationInSeconds: 0,
        abilityCharges: 3,
        abilityCooldownInSeconds: 60
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.PassiveGear,
    itemId: Item.PG_JaysesRing,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Legendary,
    name: "item__PG_JaysesRing__name",
    loreText: "item__PG_JaysesRing__lore",
    gameplayText: "item__PG_JaysesRing__gameplay",
    inventorySpriteId: "gear/ring_003.png",
    passiveStats: [
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Lightning,
            statAmount: 0.12
        },
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Fire,
            statAmount: 0.12
        }
    ],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.Ethereal,
            abilityParameter: 0.15
        },
        {
            passiveAbility: PassiveAbility.NPCDamageDown,
            abilityParameter: 0.09
        },
        {
            passiveAbility: PassiveAbility.PlayerDamageUp,
            abilityParameter: 0.095
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_BellsShiningRing,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Legendary,
    name: "item__PG_BellsShiningRing__name",
    loreText: "item__PG_BellsShiningRing__lore",
    gameplayText: "item__PG_BellsShiningRing__gameplay",
    inventorySpriteId: "gear/ring_010.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.GainHealthOverTime,
            abilityParameter: 0.5
        },
        {
            passiveAbility: PassiveAbility.MaxHealthIncrease,
            abilityParameter: 40
        },
        {
            passiveAbility: PassiveAbility.NPCDamageUp,
            abilityParameter: 0.155
        },
        {
            passiveAbility: PassiveAbility.PlayerDamageDown,
            abilityParameter: 0.095
        }
    ],
    activeAbility: {
        ability: ActiveAbility.TimerExtender,
        abilityParameter: 60,
        abilityDurationInSeconds: 0,
        abilityCharges: 3,
        abilityCooldownInSeconds: 45
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.IronFox]
});

setItemData({
    itemType: ItemType.PassiveGear,
    itemId: Item.PG_RingOfViolentViolet,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Epic,
    name: "item__PG_RingOfViolentViolet__name",
    loreText: "item__PG_RingOfViolentViolet__lore",
    gameplayText: "item__PG_RingOfViolentViolet__gameplay",
    inventorySpriteId: "gear/ring_113.png",
    passiveStats: [
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Physical,
            statAmount: -0.15
        },
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Arcane,
            statAmount: -0.15
        },
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Bleed,
            statAmount: -0.15
        },
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Chaos,
            statAmount: -0.15
        },
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Fire,
            statAmount: -0.15
        },
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Frost,
            statAmount: -0.15
        },
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Holy,
            statAmount: -0.15
        },
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Lightning,
            statAmount: -0.15
        },
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Nature,
            statAmount: -0.15
        },
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Shadow,
            statAmount: -0.15
        },
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Toxic,
            statAmount: -0.15
        },
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Physical,
            statAmount: 0.17
        },
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Arcane,
            statAmount: 0.17
        },
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Bleed,
            statAmount: 0.17
        },
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Chaos,
            statAmount: 0.17
        },
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Fire,
            statAmount: 0.17
        },
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Frost,
            statAmount: 0.17
        },
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Holy,
            statAmount: 0.17
        },
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Lightning,
            statAmount: 0.17
        },
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Nature,
            statAmount: 0.17
        },
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Shadow,
            statAmount: 0.17
        },
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Toxic,
            statAmount: 0.17
        }
    ],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.MaxHealthIncrease,
            abilityParameter: 30
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.PassiveGear,
    itemId: Item.PG_CloudedDarkness,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Epic,
    name: "item__PG_CloudedDarkness__name",
    loreText: "item__PG_CloudedDarkness__lore",
    gameplayText: "item__PG_CloudedDarkness__gameplay",
    inventorySpriteId: "gear/ring_096.png",
    passiveStats: [
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Shadow,
            statAmount: 0.10
        },
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Shadow,
            statAmount: 0.13
        }
    ],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.Ethereal,
            abilityParameter: 0.20
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_CapeOfPureDarkness,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Epic,
    name: "item__PG_CapeOfPureDarkness__name",
    loreText: "item__PG_CapeOfPureDarkness__lore",
    gameplayText: "item__PG_CapeOfPureDarkness__gameplay",
    inventorySpriteId: "gear/armor_11.png",
    passiveStats: [
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Shadow,
            statAmount: 0.09
        },
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Toxic,
            statAmount: 0.09
        },
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Toxic,
            statAmount: 0.07
        }
    ],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.LoseHealthOverTime,
            abilityParameter: 0.2
        }
    ],
    activeAbility: {
        ability: ActiveAbility.Dash,
        abilityParameter: 0.25,
        abilityDurationInSeconds: 4,
        abilityCharges: 4,
        abilityCooldownInSeconds: 10
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.PassiveGear,
    itemId: Item.PG_BloodGemRing,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Rare,
    name: "item__PG_BloodGemRing__name",
    loreText: "item__PG_BloodGemRing__lore",
    gameplayText: "item__PG_BloodGemRing__gameplay",
    inventorySpriteId: "gear/ring_093.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.MaxHealthDecrease,
            abilityParameter: 20
        },
        {
            passiveAbility: PassiveAbility.Revive,
            abilityParameter: 0.5
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.GRONK]
});

setItemData({
    itemType: ItemType.PassiveGear,
    itemId: Item.PG_PurpletratorsRing,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Common,
    name: "item__PG_PurpletratorsRing__name",
    loreText: "item__PG_PurpletratorsRing__lore",
    gameplayText: "item__PG_PurpletratorsRing__gameplay",
    inventorySpriteId: "gear/ring_092.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.PlayerDamageLeech,
            abilityParameter: 0.03
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_AquamoorsBlessing,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Uncommon,
    name: "item__PG_AquamoorsBlessing__name",
    loreText: "item__PG_AquamoorsBlessing__lore",
    gameplayText: "item__PG_AquamoorsBlessing__gameplay",
    inventorySpriteId: "gear/armor_05.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.GainHealthOverTime,
            abilityParameter: 0.03
        },
        {
            passiveAbility: PassiveAbility.MaxHealthDecrease,
            abilityParameter: 20
        }
    ],
    activeAbility: {
        ability: ActiveAbility.Dash,
        abilityParameter: 0.25,
        abilityDurationInSeconds: 3,
        abilityCharges: 4,
        abilityCooldownInSeconds: 9
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_EssenceConduit,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Common,
    name: "item__PG_EssenceConduit__name",
    loreText: "item__PG_EssenceConduit__lore",
    gameplayText: "item__PG_EssenceConduit__gameplay",
    inventorySpriteId: "gear/armor_17.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.NPCDamageUp,
            abilityParameter: 0.09
        },
        {
            passiveAbility: PassiveAbility.PlayerDamageDown,
            abilityParameter: 0.04
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.PassiveGear,
    itemId: Item.PG_ArcaneEmber,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Common,
    name: "item__PG_ArcaneEmber__name",
    loreText: "item__PG_ArcaneEmber__lore",
    gameplayText: "item__PG_ArcaneEmber__gameplay",
    inventorySpriteId: "gear/ring_088.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.NPCDamageDown,
            abilityParameter: 0.065
        },
        {
            passiveAbility: PassiveAbility.PlayerDamageUp,
            abilityParameter: 0.056
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_PhoenixsRespite,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Rare,
    name: "item__PG_PhoenixsRespite__name",
    loreText: "item__PG_PhoenixsRespite__lore",
    gameplayText: "item__PG_PhoenixsRespite__gameplay",
    inventorySpriteId: "gear/armor_13.png",
    passiveStats: [
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Fire,
            statAmount: 0.065
        },
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Fire,
            statAmount: 0.05
        }
    ],
    passiveAbilities: [],
    activeAbility: {
        ability: ActiveAbility.Invisibility,
        abilityParameter: 1,
        abilityDurationInSeconds: 3,
        abilityCharges: 1,
        abilityCooldownInSeconds: 8
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.PassiveGear,
    itemId: Item.PG_SoulBindingRing,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Rare,
    name: "item__PG_SoulBindingRing__name",
    loreText: "item__PG_SoulBindingRing__lore",
    gameplayText: "item__PG_SoulBindingRing__gameplay",
    inventorySpriteId: "gear/ring_083.png",
    passiveStats: [
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Holy,
            statAmount: 0.1
        }
    ],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.Revive,
            abilityParameter: 0.40
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_BloodiedVitality,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Epic,
    name: "item__PG_BloodiedVitality__name",
    loreText: "item__PG_BloodiedVitality__lore",
    gameplayText: "item__PG_BloodiedVitality__gameplay",
    inventorySpriteId: "gear/armor_08.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.MaxHealthIncrease,
            abilityParameter: 30
        },
        {
            passiveAbility: PassiveAbility.GainHealthOverTime,
            abilityParameter: 0.6
        },
        {
            passiveAbility: PassiveAbility.PlayerDamageDown,
            abilityParameter: 0.08
        },
        {
            passiveAbility: PassiveAbility.ExtractionProtection,
            abilityParameter: -0.1
        }
    ],
    activeAbility: {
        ability: ActiveAbility.Extract,
        abilityParameter: 0,
        abilityDurationInSeconds: 0,
        abilityCharges: 1,
        abilityCooldownInSeconds: 0
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: true,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_VenomousBite,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Uncommon,
    name: "item__PG_VenomousBite__name",
    loreText: "item__PG_VenomousBite__lore",
    gameplayText: "item__PG_VenomousBite__gameplay",
    inventorySpriteId: "gear/armor_04.png",
    passiveStats: [
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Toxic,
            statAmount: 0.1
        }
    ],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.LoseHealthOverTime,
            abilityParameter: 0.2
        }
    ],
    activeAbility: {
        ability: ActiveAbility.TimerExtender,
        abilityParameter: 30,
        abilityDurationInSeconds: 0,
        abilityCharges: 2,
        abilityCooldownInSeconds: 30
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.GiantRat]
});

setItemData({
    itemType: ItemType.PassiveGear,
    itemId: Item.PG_RuneboundRing,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Uncommon,
    name: "item__PG_RuneboundRing__name",
    loreText: "item__PG_RuneboundRing__lore",
    gameplayText: "item__PG_RuneboundRing__gameplay",
    inventorySpriteId: "gear/ring_067.png",
    passiveStats: [
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Arcane,
            statAmount: 0.07
        }
    ],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.WaterWalking,
            abilityParameter: 0.05
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.PassiveGear,
    itemId: Item.PG_FrostbiteLoop,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Uncommon,
    name: "item__PG_FrostbiteLoop__name",
    loreText: "item__PG_FrostbiteLoop__lore",
    gameplayText: "item__PG_FrostbiteLoop__gameplay",
    inventorySpriteId: "gear/ring_079.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.MoveSpeedUp,
            abilityParameter: 0.06
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.PassiveGear,
    itemId: Item.PG_PhantomBand,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Rare,
    name: "item__PG_PhantomBand__name",
    loreText: "item__PG_PhantomBand__lore",
    gameplayText: "item__PG_PhantomBand__gameplay",
    inventorySpriteId: "gear/ring_071.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.Ethereal,
            abilityParameter: 0.3
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.PassiveGear,
    itemId: Item.PG_EmbergraspRing,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Uncommon,
    name: "item__PG_EmbergraspRing__name",
    loreText: "item__PG_EmbergraspRing__lore",
    gameplayText: "item__PG_EmbergraspRing__gameplay",
    inventorySpriteId: "gear/ring_075.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.MaxHealthIncrease,
            abilityParameter: 10
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_SoulshackleVeil,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Uncommon,
    name: "item__PG_SoulshackleVeil__name",
    loreText: "item__PG_SoulshackleVeil__lore",
    gameplayText: "item__PG_SoulshackleVeil__gameplay",
    inventorySpriteId: "gear/armor_51.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.Ethereal,
            abilityParameter: 0.085
        },
        {
            passiveAbility: PassiveAbility.MoveSpeedDown,
            abilityParameter: 0.05
        }
    ],
    activeAbility: {
        ability: ActiveAbility.Dash,
        abilityParameter: 0.25,
        abilityDurationInSeconds: 3,
        abilityCharges: 3,
        abilityCooldownInSeconds: 9
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_StormweaverCloak,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Common,
    name: "item__PG_StormweaverCloak__name",
    loreText: "item__PG_StormweaverCloak__lore",
    gameplayText: "item__PG_StormweaverCloak__gameplay",
    inventorySpriteId: "gear/ring_070.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.Incognito,
            abilityParameter: 0
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.PassiveGear,
    itemId: Item.PG_CursedSignet,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Epic,
    name: "item__PG_CursedSignet__name",
    loreText: "item__PG_CursedSignet__lore",
    gameplayText: "item__PG_CursedSignet__gameplay",
    inventorySpriteId: "gear/ring_069.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.PlayerDamageLeech,
            abilityParameter: 0.2
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.PassiveGear,
    itemId: Item.PG_EnigmaLoop,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Uncommon,
    name: "item__PG_EnigmaLoop__name",
    loreText: "item__PG_EnigmaLoop__lore",
    gameplayText: "item__PG_EnigmaLoop__gameplay",
    inventorySpriteId: "gear/ring_063.png",
    passiveStats: [
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Chaos,
            statAmount: 0.5
        }
    ],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.Incognito,
            abilityParameter: 0
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_VampiricSeal,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Uncommon,
    name: "item__PG_VampiricSeal__name",
    loreText: "item__PG_VampiricSeal__lore",
    gameplayText: "item__PG_VampiricSeal__gameplay",
    inventorySpriteId: "gear/armor_20.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.PlayerDamageLeech,
            abilityParameter: 0.03
        }
    ],
    activeAbility: {
        ability: ActiveAbility.Heal,
        abilityParameter: 7,
        abilityDurationInSeconds: 0,
        abilityCharges: 2,
        abilityCooldownInSeconds: 15
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.PassiveGear,
    itemId: Item.PG_Wraithband,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Uncommon,
    name: "item__PG_Wraithband__name",
    loreText: "item__PG_Wraithband__lore",
    gameplayText: "item__PG_Wraithband__gameplay",
    inventorySpriteId: "gear/ring_057.png",
    passiveStats: [
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Holy,
            statAmount: 0.07
        }
    ],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.Ethereal,
            abilityParameter: 0.085
        },
        {
            passiveAbility: PassiveAbility.MaxHealthDecrease,
            abilityParameter: 10
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_NecroticSignet,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Rare,
    name: "item__PG_NecroticSignet__name",
    loreText: "item__PG_NecroticSignet__lore",
    gameplayText: "item__PG_NecroticSignet__gameplay",
    inventorySpriteId: "gear/armor_12.png",
    passiveStats: [
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Holy,
            statAmount: 0.13
        }
    ],
    passiveAbilities: [],
    activeAbility: {
        ability: ActiveAbility.TimerExtender,
        abilityParameter: 45,
        abilityDurationInSeconds: 0,
        abilityCharges: 2,
        abilityCooldownInSeconds: 30
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Skeleton]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_CorruptedTearRing,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Epic,
    name: "item__PG_CorruptedTearRing__name",
    loreText: "item__PG_CorruptedTearRing__lore",
    gameplayText: "item__PG_CorruptedTearRing__gameplay",
    inventorySpriteId: "gear/ring_034.png",
    passiveStats: [
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Chaos,
            statAmount: -0.07
        },
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Chaos,
            statAmount: 0.09
        },
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Lightning,
            statAmount: 0.09
        }
    ],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.PlayerDamageLeech,
            abilityParameter: 0.07
        }
    ],
    activeAbility: {
        ability: ActiveAbility.Invisibility,
        abilityParameter: 1,
        abilityDurationInSeconds: 5,
        abilityCharges: 3,
        abilityCooldownInSeconds: 9
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.PassiveGear,
    itemId: Item.PG_DeathwhisperWrap,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Epic,
    name: "item__PG_DeathwhisperWrap__name",
    loreText: "item__PG_DeathwhisperWrap__lore",
    gameplayText: "item__PG_DeathwhisperWrap__gameplay",
    inventorySpriteId: "gear/armor_50.png",
    passiveStats: [
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Chaos,
            statAmount: 0.13
        },
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Toxic,
            statAmount: 0.1
        }
    ],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.NPCDamageUp,
            abilityParameter: 0.13
        }
    ],
    //TODO: Give this the 'Random' active ability when implemented
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_VeilstormCirclet,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Rare,
    name: "item__PG_VeilstormCirclet__name",
    loreText: "item__PG_VeilstormCirclet__lore",
    gameplayText: "item__PG_VeilstormCirclet__gameplay",
    inventorySpriteId: "gear/armor_02.png",
    passiveStats: [
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Nature,
            statAmount: 0.12
        }
    ],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.AttackSpeedDown,
            abilityParameter: 0.10
        }
    ],
    activeAbility: {
        ability: ActiveAbility.Heal,
        abilityParameter: 30,
        abilityDurationInSeconds: 0,
        abilityCharges: 2,
        abilityCooldownInSeconds: 15
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_SoulforgeRing,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Rare,
    name: "item__PG_SoulforgeRing__name",
    loreText: "item__PG_SoulforgeRing__lore",
    gameplayText: "item__PG_SoulforgeRing__gameplay",
    inventorySpriteId: "gear/ring_031.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.ExtractionSpeedUp,
            abilityParameter: -0.10
        }
    ],
    activeAbility: {
        ability: ActiveAbility.Extract,
        abilityParameter: 0,
        abilityDurationInSeconds: 0,
        abilityCharges: 1,
        abilityCooldownInSeconds: 0
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: true,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_CursedEternityRing,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Legendary,
    name: "item__PG_CursedEternityRing__name",
    loreText: "item__PG_CursedEternityRing__lore",
    gameplayText: "item__PG_CursedEternityRing__gameplay",
    inventorySpriteId: "gear/ring_028.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.LoseHealthOverTime,
            abilityParameter: 0.3
        },
        {
            passiveAbility: PassiveAbility.Revive,
            abilityParameter: 0.48
        },
        {
            passiveAbility: PassiveAbility.PlayerDamageLeech,
            abilityParameter: 0.085
        },
        {
            passiveAbility: PassiveAbility.GoldRush,
            abilityParameter: 0.12
        }
    ],
    activeAbility: {
        ability: ActiveAbility.Dash,
        abilityParameter: 0.3,
        abilityDurationInSeconds: 5,
        abilityCharges: 5,
        abilityCooldownInSeconds: 10
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_MidnightsVeilBand,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Uncommon,
    name: "item__PG_MidnightsVeilBand__name",
    loreText: "item__PG_MidnightsVeilBand__lore",
    gameplayText: "item__PG_MidnightsVeilBand__gameplay",
    inventorySpriteId: "gear/armor_16.png",
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: {
        ability: ActiveAbility.Invisibility,
        abilityParameter: 0,
        abilityDurationInSeconds: 5,
        abilityCharges: 2,
        abilityCooldownInSeconds: 10
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_DreadmorneLoop,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Uncommon,
    name: "item__PG_DreadmorneLoop__name",
    loreText: "item__PG_DreadmorneLoop__lore",
    gameplayText: "item__PG_DreadmorneLoop__gameplay",
    inventorySpriteId: "gear/ring_025.png",
    passiveStats: [
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Chaos,
            statAmount: 0.1
        },
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Holy,
            statAmount: -0.065
        }
    ],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.AttackSpeedDown,
            abilityParameter: 0.05
        }
    ],
    activeAbility: {
        ability: ActiveAbility.Dash,
        abilityParameter: 0.3,
        abilityDurationInSeconds: 3,
        abilityCharges: 5,
        abilityCooldownInSeconds: 8
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.PassiveGear,
    itemId: Item.PG_EbonfireRobelet,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Uncommon,
    name: "item__PG_EbonfireRobelet__name",
    loreText: "item__PG_EbonfireRobelet__lore",
    gameplayText: "item__PG_EbonfireRobelet__gameplay",
    inventorySpriteId: "gear/armor_49.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.ProjectileDistanceUp,
            abilityParameter: 0.06
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_EverlastingSpiritCircle,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Rare,
    name: "item__PG_EverlastingSpiritCircle__name",
    loreText: "item__PG_EverlastingSpiritCircle__lore",
    gameplayText: "item__PG_EverlastingSpiritCircle__gameplay",
    inventorySpriteId: "gear/ring_019.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.GoldRush,
            abilityParameter: 0.10
        }
    ],
    activeAbility: {
        ability: ActiveAbility.Dash,
        abilityParameter: 25,
        abilityDurationInSeconds: 3,
        abilityCharges: 2,
        abilityCooldownInSeconds: 8
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Treant]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_EnigmaticShadowweaveRing,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Uncommon,
    name: "item__PG_EnigmaticShadowweaveRing__name",
    loreText: "item__PG_EnigmaticShadowweaveRing__lore",
    gameplayText: "item__PG_EnigmaticShadowweaveRing__gameplay",
    inventorySpriteId: "gear/ring_018.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.NPCDamageDown,
            abilityParameter: 0.09
        },
        {
            passiveAbility: PassiveAbility.PlayerDamageUp,
            abilityParameter: 0.055
        }
    ],
    activeAbility: {
        ability: ActiveAbility.Heal,
        abilityParameter: 20,
        abilityDurationInSeconds: 0,
        abilityCharges: 1,
        abilityCooldownInSeconds: 0
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_BloodmoonLoop,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Uncommon,
    name: "item__PG_BloodmoonLoop__name",
    loreText: "item__PG_BloodmoonLoop__lore",
    gameplayText: "item__PG_BloodmoonLoop__gameplay",
    inventorySpriteId: "gear/armor_01.png",
    passiveStats: [
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Bleed,
            statAmount: 0.05
        }
    ],
    passiveAbilities: [],
    activeAbility: {
        ability: ActiveAbility.Heal,
        abilityParameter: 5,
        abilityDurationInSeconds: 0,
        abilityCharges: 3,
        abilityCooldownInSeconds: 20
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.PassiveGear,
    itemId: Item.PG_DreamcasterDrapery,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Uncommon,
    name: "item__PG_DreamcasterDrapery__name",
    loreText: "item__PG_DreamcasterDrapery__lore",
    gameplayText: "item__PG_DreamcasterDrapery__gameplay",
    inventorySpriteId: "gear/armor_19.png",
    passiveStats: [
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Chaos,
            statAmount: 0.065
        }
    ],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.NPCDamageUp,
            abilityParameter: 0.065
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Ghost]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_CelestialSolaceBand,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Rare,
    name: "item__PG_CelestialSolaceBand__name",
    loreText: "item__PG_CelestialSolaceBand__lore",
    gameplayText: "item__PG_CelestialSolaceBand__gameplay",
    inventorySpriteId: "gear/ring_014.png",
    passiveStats: [
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Shadow,
            statAmount: 0.065
        }
    ],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.Information,
            abilityParameter: 0
        }
    ],
    activeAbility: {
        ability: ActiveAbility.Heal,
        abilityParameter: 7,
        abilityDurationInSeconds: 0,
        abilityCharges: 3,
        abilityCooldownInSeconds: 20
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_TalismanOfVeiledShadows,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Rare,
    name: "item__PG_TalismanOfVeiledShadows__name",
    loreText: "item__PG_TalismanOfVeiledShadows__lore",
    gameplayText: "item__PG_TalismanOfVeiledShadows__gameplay",
    inventorySpriteId: "gear/necklace_04.png",
    passiveStats: [
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Chaos,
            statAmount: 0.085
        },
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Shadow,
            statAmount: 0.085
        }
    ],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.MoveSpeedDown,
            abilityParameter: 0.05
        }
    ],
    activeAbility: {
        ability: ActiveAbility.Invisibility,
        abilityParameter: 1,
        abilityDurationInSeconds: 3,
        abilityCharges: 2,
        abilityCooldownInSeconds: 7
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.PassiveGear,
    itemId: Item.PG_PendantOfAncientWisdom,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Rare,
    name: "item__PG_PendantOfAncientWisdom__name",
    loreText: "item__PG_PendantOfAncientWisdom__lore",
    gameplayText: "item__PG_PendantOfAncientWisdom__gameplay",
    inventorySpriteId: "gear/necklace_06.png",
    passiveStats: [
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Arcane,
            statAmount: 0.085
        },
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Arcane,
            statAmount: 0.07
        }
    ],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.NPCDamageUp,
            abilityParameter: 0.0875
        },
        {
            passiveAbility: PassiveAbility.PlayerDamageDown,
            abilityParameter: 0.07
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.DarkWizard]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_SerpentsCoilChoker,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Common,
    name: "item__PG_SerpentsCoilChoker__name",
    loreText: "item__PG_SerpentsCoilChoker__lore",
    gameplayText: "item__PG_SerpentsCoilChoker__gameplay",
    inventorySpriteId: "gear/necklace_08.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.Information,
            abilityParameter: 0
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.GiantRat]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_ArcaneSigilCollar,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Rare,
    name: "item__PG_ArcaneSigilCollar__name",
    loreText: "item__PG_ArcaneSigilCollar__lore",
    gameplayText: "item__PG_ArcaneSigilCollar__gameplay",
    inventorySpriteId: "gear/necklace_10.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.NPCDamageDown,
            abilityParameter: 0.085
        },
        {
            passiveAbility: PassiveAbility.PlayerDamageUp,
            abilityParameter: 0.095
        }
    ],
    activeAbility: {
        ability: ActiveAbility.Dash,
        abilityParameter: 0.30,
        abilityDurationInSeconds: 3,
        abilityCharges: 5,
        abilityCooldownInSeconds: 15
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_ResilientHeartstone,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Epic,
    name: "item__PG_ResilientHeartstone__name",
    loreText: "item__PG_ResilientHeartstone__lore",
    gameplayText: "item__PG_ResilientHeartstone__gameplay",
    inventorySpriteId: "gear/necklace_11.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.Information,
            abilityParameter: 0
        },
        {
            passiveAbility: PassiveAbility.ExtractionProtection,
            abilityParameter: 0.05
        },
        {
            passiveAbility: PassiveAbility.GoldRush,
            abilityParameter: 0.05
        }
    ],
    activeAbility: {
        ability: ActiveAbility.Heal,
        abilityParameter: 10,
        abilityDurationInSeconds: 0,
        abilityCharges: 3,
        abilityCooldownInSeconds: 20
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_FrostfireShardPendant,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Rare,
    name: "item__PG_FrostfireShardPendant__name",
    loreText: "item__PG_FrostfireShardPendant__lore",
    gameplayText: "item__PG_FrostfireShardPendant__gameplay",
    inventorySpriteId: "gear/necklace_12.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.PlayerDamageUp,
            abilityParameter: 0.08
        },
        {
            passiveAbility: PassiveAbility.ExtractionProtection,
            abilityParameter: 0.1
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.PassiveGear,
    itemId: Item.PG_PhoenixFeatherTorque,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Legendary,
    name: "item__PG_PhoenixFeatherTorque__name",
    loreText: "item__PG_PhoenixFeatherTorque__lore",
    gameplayText: "item__PG_PhoenixFeatherTorque__gameplay",
    inventorySpriteId: "gear/necklace_15.png",
    passiveStats: [
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Fire,
            statAmount: 0.195
        },
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Frost,
            statAmount: 0.195
        },
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Toxic,
            statAmount: -0.195
        }
    ],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.Revive,
            abilityParameter: 0.6
        },
        {
            passiveAbility: PassiveAbility.MaxHealthIncrease,
            abilityParameter: 60
        },
        {
            passiveAbility: PassiveAbility.AttackSpeedDown,
            abilityParameter: 0.15
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_SoulstoneReliquary,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Epic,
    name: "item__PG_SoulstoneReliquary__name",
    loreText: "item__PG_SoulstoneReliquary__lore",
    gameplayText: "item__PG_SoulstoneReliquary__gameplay",
    inventorySpriteId: "gear/necklace_16.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.PlayerDamageLeech,
            abilityParameter: 0.05
        },
        {
            passiveAbility: PassiveAbility.Revive,
            abilityParameter: 0.20
        },
        {
            passiveAbility: PassiveAbility.ExtractionProtection,
            abilityParameter: 0.05
        }
    ],
    activeAbility: {
        ability: ActiveAbility.Invisibility,
        abilityParameter: 1,
        abilityDurationInSeconds: 4,
        abilityCharges: 1,
        abilityCooldownInSeconds: 8
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_EnchantedElixirNecklace,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Epic,
    name: "item__PG_EnchantedElixirNecklace__name",
    loreText: "item__PG_EnchantedElixirNecklace__lore",
    gameplayText: "item__PG_EnchantedElixirNecklace__gameplay",
    inventorySpriteId: "gear/necklace_17.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.Revive,
            abilityParameter: 0.6
        }
    ],
    activeAbility: {
        ability: ActiveAbility.TimerExtender,
        abilityParameter: 60,
        abilityDurationInSeconds: 0,
        abilityCharges: 1,
        abilityCooldownInSeconds: 120
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_NecroticGraspTalisman,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Rare,
    name: "item__PG_NecroticGraspTalisman__name",
    loreText: "item__PG_NecroticGraspTalisman__lore",
    gameplayText: "item__PG_NecroticGraspTalisman__gameplay",
    inventorySpriteId: "gear/necklace_19.png",
    passiveStats: [
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Shadow,
            statAmount: 0.12
        }
    ],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.LoseHealthOverTime,
            abilityParameter: 0.5
        }
    ],
    activeAbility: {
        ability: ActiveAbility.Dash,
        abilityParameter: 0.3,
        abilityDurationInSeconds: 5,
        abilityCharges: 1,
        abilityCooldownInSeconds: 10
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.DarkWizard]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.PG_CelestialStarfallPendant,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Rare,
    name: "item__PG_CelestialStarfallPendant__name",
    loreText: "item__PG_CelestialStarfallPendant__lore",
    gameplayText: "item__PG_CelestialStarfallPendant__gameplay",
    inventorySpriteId: "gear/necklace_21.png",
    passiveStats: [
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Holy,
            statAmount: 0.05
        }
    ],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.NPCDamageUp,
            abilityParameter: 0.065
        }
    ],
    activeAbility: {
        ability: ActiveAbility.Invisibility,
        abilityParameter: 1,
        abilityDurationInSeconds: 3,
        abilityCharges: 2,
        abilityCooldownInSeconds: 7
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.PassiveGear,
    itemId: Item.PG_CultistsMask,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Legendary,
    name: "item__PG_CultistsMask__name",
    loreText: "item__PG_CultistsMask__lore",
    gameplayText: "item__PG_CultistsMask__gameplay",
    inventorySpriteId: "gear/icon735.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.Incognito,
            abilityParameter: 0
        },
        {
            passiveAbility: PassiveAbility.AttackSpeedUp,
            abilityParameter: 0.15
        }
    ],
    activeAbility: {
        ability: ActiveAbility.Invisibility,
        abilityParameter: 1,
        abilityDurationInSeconds: 6,
        abilityCharges: 3,
        abilityCooldownInSeconds: 9
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Ghost, LootSources.Skeleton]
});

setItemData({
    itemType: ItemType.PassiveGear,
    itemId: Item.PG_DepthStrider,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Common,
    name: "item__PG_DepthStrider__name",
    loreText: "item__PG_DepthStrider__lore",
    gameplayText: "item__PG_DepthStrider__gameplay",
    inventorySpriteId: "gear/armor_07.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.WaterWalking,
            abilityParameter: 0.05
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.PassiveGear,
    itemId: Item.PG_GoldenGauntlets,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Uncommon,
    name: "item__PG_GoldenGauntlets__name",
    loreText: "item__PG_GoldenGauntlets__lore",
    gameplayText: "item__PG_GoldenGauntlets__gameplay",
    inventorySpriteId: "gear/icon745.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.GoldRush,
            abilityParameter: 0.1
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllLootChests, LootSources.Skeleton]
});

setItemData({
    itemType: ItemType.PassiveGear,
    itemId: Item.PG_BeastVanquisherBoots,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Legendary,
    name: "item__PG_BeastVanquisherBoots__name",
    loreText: "item__PG_BeastVanquisherBoots__lore",
    gameplayText: "item__PG_BeastVanquisherBoots__gameplay",
    inventorySpriteId: "gear/icon773.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.MoveSpeedUp,
            abilityParameter: 0.12
        },
        {
            passiveAbility: PassiveAbility.MaxHealthIncrease,
            abilityParameter: 45
        },
        {
            passiveAbility: PassiveAbility.NPCDamageUp,
            abilityParameter: 0.25
        },
        {
            passiveAbility: PassiveAbility.PlayerDamageDown,
            abilityParameter: 0.12
        },
        {
            passiveAbility: PassiveAbility.ProjectileDistanceDown,
            abilityParameter: 0.12
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.GiantRat, LootSources.Ogre]
});

// setItemData({
//     itemType: ItemType.PassiveGear,
//     itemId: Item.PG_SamsTestItem,
//     maxItemQuantityPerRoll: 1,
//     itemRarity: ItemRarity.Legendary,
//     name: "Sam's Super Illegal Test Item",
//     loreText: "You should not have this.",
//     gameplayText: "Probably everything!",
//     inventorySpriteId: "items/crystal_side_6.png",
//     passiveStats: [],
//     passiveAbilities: [],
//     activeAbility: {
//         ability: ActiveAbility.Dash,
//         abilityParameter: 2,
//         abilityCharges: -1,
//         abilityCooldownInSeconds: 15,
//         abilityDurationInSeconds: 5
//     },
//     smallInventorySlotRotation: 0,
//     smallInventorySlotSpriteScale: 2,
//     smallInventorySlotSpriteOffsetLeft: 0.5,
//     smallInventorySlotSpriteOffsetTop: -1,
//     tooltipSlotRotation: 0,
//     tooltipSlotSpriteScale: 3,
//     tooltipSlotSpriteOffsetLeft: 37,
//     tooltipSlotSpriteOffsetTop: 35,
//     largeInventorySlotRotation: 0,
//     largeInventorySlotSpriteScale: 3,
//     largeInventorySlotSpriteOffsetLeft: 0,
//     largeInventorySlotSpriteOffsetTop: 0
// });

/*********************************************/
/*    Active Gear! Item IDs 2000 -> 2999     */
/*********************************************/
setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.AG_VestiasSoothingSignet,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Rare,
    name: "item__AG_VestiasSoothingSignet__name",
    loreText: "item__AG_VestiasSoothingSignet__lore",
    gameplayText: "item__AG_VestiasSoothingSignet__gameplay",
    inventorySpriteId: "gear/ring_103.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.Incognito,
            abilityParameter: 0
        }
    ],
    activeAbility: {
        ability: ActiveAbility.Heal,
        abilityParameter: 30,
        abilityDurationInSeconds: 0,
        abilityCharges: 1,
        abilityCooldownInSeconds: 11
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.GiantRat]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.AG_ViolasDinnerReminder,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Rare,
    name: "item__AG_ViolasDinnerReminder__name",
    loreText: "item__AG_ViolasDinnerReminder__lore",
    gameplayText: "item__AG_ViolasDinnerReminder__gameplay",
    inventorySpriteId: "gear/ring_054.png",
    passiveStats: [
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Nature,
            statAmount: 0.085
        },
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Frost,
            statAmount: 0.085
        }
    ],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.ProjectileDistanceDown,
            abilityParameter: 0.05
        },
        {
            passiveAbility: PassiveAbility.ExtractionProtection,
            abilityParameter: 0.07
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.AG_WolfMedallion,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Epic,
    name: "item__AG_WolfMedallion__name",
    loreText: "item__AG_WolfMedallion__lore",
    gameplayText: "item__AG_WolfMedallion__gameplay",
    inventorySpriteId: "gear/necklace_23.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.PlayerDamageLeech,
            abilityParameter: 0.1
        },
        {
            passiveAbility: PassiveAbility.AttackSpeedUp,
            abilityParameter: 0.1
        }
    ],
    activeAbility: {
        ability: ActiveAbility.Heal,
        abilityParameter: 10,
        abilityDurationInSeconds: 0,
        abilityCharges: 3,
        abilityCooldownInSeconds: 15
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.IronFox]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.AG_PepperedCollar,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Common,
    name: "item__AG_PepperedCollar__name",
    loreText: "item__AG_PepperedCollar__lore",
    gameplayText: "item__AG_PepperedCollar__gameplay",
    inventorySpriteId: "gear/necklace_22.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.ProjectileDistanceDown,
            abilityParameter: 0.03
        }
    ],
    activeAbility: {
        ability: ActiveAbility.Dash,
        abilityParameter: 0.25,
        abilityDurationInSeconds: 4,
        abilityCharges: 1,
        abilityCooldownInSeconds: 10
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.GiantRat]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.AG_StuddedNecklace,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Uncommon,
    name: "item__AG_StuddedNecklace__name",
    loreText: "item__AG_StuddedNecklace__lore",
    gameplayText: "item__AG_StuddedNecklace__gameplay",
    inventorySpriteId: "gear/necklace_01.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.MoveSpeedDown,
            abilityParameter: 0.03
        }
    ],
    activeAbility: {
        ability: ActiveAbility.Invisibility,
        abilityParameter: 1,
        abilityDurationInSeconds: 4,
        abilityCharges: 3,
        abilityCooldownInSeconds: 9
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.AG_TimeHerosRing,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Legendary,
    name: "item__AG_TimeHerosRing__name",
    loreText: "item__AG_TimeHerosRing__lore",
    gameplayText: "item__AG_TimeHerosRing__gameplay",
    inventorySpriteId: "gear/ring_008.png",
    passiveStats: [
        {
            passiveStatType: PassiveStatType.Defence,
            damageType: DamageType.Nature,
            statAmount: 0.09
        },
        {
            passiveStatType: PassiveStatType.DamageBoost,
            damageType: DamageType.Nature,
            statAmount: 0.07
        }
    ],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.MoveSpeedDown,
            abilityParameter: 0.05
        },
        {
            passiveAbility: PassiveAbility.WaterWalking,
            abilityParameter: 0.07
        },
        {
            passiveAbility: PassiveAbility.AttackSpeedUp,
            abilityParameter: 0.07
        }
    ],
    activeAbility: {
        ability: ActiveAbility.TimerExtender,
        abilityParameter: 45,
        abilityDurationInSeconds: 0,
        abilityCharges: 2,
        abilityCooldownInSeconds: 30
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Skeleton]
});

setItemData({
    itemType: ItemType.PassiveGear,
    itemId: Item.AG_TheDarkLordsEmbrace,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Rare,
    name: "item__AG_TheDarkLordsEmbrace__name",
    loreText: "item__AG_TheDarkLordsEmbrace__lore",
    gameplayText: "item__AG_TheDarkLordsEmbrace__gameplay",
    inventorySpriteId: "gear/ring_001.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.MaxHealthIncrease,
            abilityParameter: 30
        }
    ],
    //TODO: Give this the 'Random' active ability when implemented
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: true,
    droppedBy: [LootSources.Skeleton]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.AG_Boros,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Epic,
    name: "item__AG_Boros__name",
    loreText: "item__AG_Boros__lore",
    gameplayText: "item__AG_Boros__gameplay",
    inventorySpriteId: "gear/ring_012.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.PlayerDamageLeech,
            abilityParameter: 0.12
        },
        {
            passiveAbility: PassiveAbility.NPCDamageDown,
            abilityParameter: 0.12
        }
    ],
    activeAbility: {
        ability: ActiveAbility.Invisibility,
        abilityParameter: 1,
        abilityDurationInSeconds: 7,
        abilityCharges: 3,
        abilityCooldownInSeconds: 10
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.DarkWizard]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.AG_MysteriousRing,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Rare,
    name: "item__AG_MysteriousRing__name",
    loreText: "item__AG_MysteriousRing__lore",
    gameplayText: "item__AG_MysteriousRing__gameplay",
    inventorySpriteId: "gear/ring_101.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.WaterWalking,
            abilityParameter: 0.07
        },
        {
            passiveAbility: PassiveAbility.MaxHealthIncrease,
            abilityParameter: 10
        }
    ],
    activeAbility: {
        ability: ActiveAbility.TimerExtender,
        abilityParameter: 30,
        abilityDurationInSeconds: 0,
        abilityCharges: 2,
        abilityCooldownInSeconds: 0
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.PassiveGear,
    itemId: Item.AG_DarkPustulesRing,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Uncommon,
    name: "item__AG_DarkPustulesRing__name",
    loreText: "item__AG_DarkPustulesRing__lore",
    gameplayText: "item__AG_DarkPustulesRing__gameplay",
    inventorySpriteId: "gear/ring_119.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.ExtractionProtection,
            abilityParameter: 0.05
        },
        {
            passiveAbility: PassiveAbility.ExtractionSpeedUp,
            abilityParameter: 0.05
        }
    ],
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Ghost, LootSources.DarkWizard]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.AG_RingOfAnOceanMan,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Rare,
    name: "item__AG_RingOfAnOceanMan__name",
    loreText: "item__AG_RingOfAnOceanMan__lore",
    gameplayText: "item__AG_RingOfAnOceanMan__gameplay",
    inventorySpriteId: "gear/ring_112.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.WaterWalking,
            abilityParameter: 0.05
        },
        {
            passiveAbility: PassiveAbility.ExtractionSpeedUp,
            abilityParameter: 0.05
        }
    ],
    activeAbility: {
        ability: ActiveAbility.Dash,
        abilityParameter: 0.20,
        abilityDurationInSeconds: 2,
        abilityCharges: 3,
        abilityCooldownInSeconds: 7
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.AG_ForestsHaven,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Legendary,
    name: "item__AG_ForestsHaven__name",
    loreText: "item__AG_ForestsHaven__lore",
    gameplayText: "item__AG_ForestsHaven__gameplay",
    inventorySpriteId: "gear/ring_090.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.Ethereal,
            abilityParameter: 0.2
        },
        {
            passiveAbility: PassiveAbility.Incognito,
            abilityParameter: 0
        },
        {
            passiveAbility: PassiveAbility.ExtractionSpeedUp,
            abilityParameter: 0.1
        }
    ],
    activeAbility: {
        ability: ActiveAbility.Heal,
        abilityParameter: 20,
        abilityDurationInSeconds: 0,
        abilityCharges: 4,
        abilityCooldownInSeconds: 15
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Treant]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.AG_DreamWeaversEmbrace,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Epic,
    name: "item__AG_DreamWeaversEmbrace__name",
    loreText: "item__AG_DreamWeaversEmbrace__lore",
    gameplayText: "item__AG_DreamWeaversEmbrace__gameplay",
    inventorySpriteId: "gear/ring_082.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.Incognito,
            abilityParameter: 0
        },
        {
            passiveAbility: PassiveAbility.ExtractionSpeedUp,
            abilityParameter: 0.1
        },
        {
            passiveAbility: PassiveAbility.PlayerDamageDown,
            abilityParameter: 0.05
        }
    ],
    activeAbility: {
        ability: ActiveAbility.Invisibility,
        abilityParameter: 1,
        abilityDurationInSeconds: 4,
        abilityCharges: 2,
        abilityCooldownInSeconds: 8
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.AG_ObsidianEcho,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Legendary,
    name: "item__AG_ObsidianEcho__name",
    loreText: "item__AG_ObsidianEcho__lore",
    gameplayText: "item__AG_ObsidianEcho__gameplay",
    inventorySpriteId: "gear/ring_047.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.MoveSpeedUp,
            abilityParameter: 0.12
        },
        {
            passiveAbility: PassiveAbility.Revive,
            abilityParameter: 0.70
        },
        {
            passiveAbility: PassiveAbility.MaxHealthDecrease,
            abilityParameter: 30
        }
    ],
    activeAbility: {
        ability: ActiveAbility.Extract,
        abilityParameter: 0,
        abilityDurationInSeconds: 0,
        abilityCharges: 1,
        abilityCooldownInSeconds: 0
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: true,
    droppedBy: [LootSources.GRONK]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.AG_AmuletOfCursedFate,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Rare,
    name: "item__AG_AmuletOfCursedFate__name",
    loreText: "item__AG_AmuletOfCursedFate__lore",
    gameplayText: "item__AG_AmuletOfCursedFate__gameplay",
    inventorySpriteId: "gear/necklace_14.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.Revive,
            abilityParameter: 0.40
        },
        {
            passiveAbility: PassiveAbility.AttackSpeedUp,
            abilityParameter: 0.1
        }
    ],
    //TODO: Give this the 'Random' active ability when implemented
    activeAbility: NoneActiveAbility,
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemType: ItemType.ActiveGear,
    itemId: Item.AG_MagicBoots,
    maxItemQuantityPerRoll: 1,
    itemRarity: ItemRarity.Rare,
    name: "item__AG_MagicBoots__name",
    loreText: "item__AG_MagicBoots__lore",
    gameplayText: "item__AG_MagicBoots__gameplay",
    inventorySpriteId: "gear/icon759.png",
    passiveStats: [],
    passiveAbilities: [
        {
            passiveAbility: PassiveAbility.GainHealthOverTime,
            abilityParameter: 0.5
        },
        {
            passiveAbility: PassiveAbility.ExtractionSpeedUp,
            abilityParameter: -0.1
        }
    ],
    //TODO: Give this the 'Random' active ability when implemented
    activeAbility: {
        ability: ActiveAbility.Invisibility,
        abilityCharges: 2,
        abilityCooldownInSeconds: 9,
        abilityDurationInSeconds: 5,
        abilityParameter: 1
    },
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: -1,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 0,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Ghost, LootSources.Treant, LootSources.GiantRat]
});

/**********************************************/
/*    Vendor Trash! Item IDs 3000 -> 3999     */
/**********************************************/

setItemData({
    itemId: Item.VT_OnyxGem,
    itemType: ItemType.VendorTrash,
    itemRarity: ItemRarity.Epic,
    maxItemQuantityPerRoll: 1,
    name: "item__VT_OnyxGem__name",
    loreText: "item__VT_OnyxGem__lore",
    gameplayText: "item__VT_OnyxGem__gameplay",
    inventorySpriteId: "items/material_38.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 36,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3.5,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.IronFox]
});

setItemData({
    itemId: Item.VT_GhostPlasma,
    itemType: ItemType.VendorTrash,
    itemRarity: ItemRarity.Common,
    maxItemQuantityPerRoll: 1,
    name: "item__VT_GhostPlasma__name",
    loreText: "item__VT_GhostPlasma__lore",
    gameplayText: "item__VT_GhostPlasma__gameplay",
    inventorySpriteId: "items/icon271.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 36,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3.5,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Ghost]
});

setItemData({
    itemId: Item.VT_GoblinCloth,
    itemType: ItemType.VendorTrash,
    itemRarity: ItemRarity.Common,
    maxItemQuantityPerRoll: 1,
    name: "item__VT_GoblinCloth__name",
    loreText: "item__VT_GoblinCloth__lore",
    gameplayText: "item__VT_GoblinCloth__gameplay",
    inventorySpriteId: "items/icon257.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3.5,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Ogre]
});

setItemData({
    itemId: Item.VT_Fossil,
    itemType: ItemType.VendorTrash,
    itemRarity: ItemRarity.Uncommon,
    maxItemQuantityPerRoll: 1,
    name: "item__VT_Fossil__name",
    loreText: "item__VT_Fossil__lore",
    gameplayText: "item__VT_Fossil__gameplay",
    inventorySpriteId: "items/icon171.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3.5,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Ogre]
});

setItemData({
    itemId: Item.VT_BrokenSword,
    itemType: ItemType.VendorTrash,
    itemRarity: ItemRarity.Common,
    maxItemQuantityPerRoll: 1,
    name: "item__VT_BrokenSword__name",
    loreText: "item__VT_BrokenSword__lore",
    gameplayText: "item__VT_BrokenSword__gameplay",
    inventorySpriteId: "items/brokensword.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3.5,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.VT_PileOfAsh,
    itemType: ItemType.VendorTrash,
    itemRarity: ItemRarity.Common,
    maxItemQuantityPerRoll: 1,
    name: "item__VT_PileOfAsh__name",
    loreText: "item__VT_PileOfAsh__lore",
    gameplayText: "item__VT_PileOfAsh__gameplay",
    inventorySpriteId: "items/chunk_11.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 1.5,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 2,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 32,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Ghost]
});

setItemData({
    itemId: Item.VT_Skull,
    itemType: ItemType.VendorTrash,
    itemRarity: ItemRarity.Common,
    maxItemQuantityPerRoll: 1,
    name: "item__VT_Skull__name",
    loreText: "item__VT_Skull__lore",
    gameplayText: "item__VT_Skull__gameplay",
    inventorySpriteId: "items/icon894.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 6,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3.5,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Skeleton]
});

setItemData({
    itemId: Item.VT_Emerald,
    itemType: ItemType.VendorTrash,
    itemRarity: ItemRarity.Uncommon,
    maxItemQuantityPerRoll: 1,
    name: "item__VT_Emerald__name",
    loreText: "item__VT_Emerald__lore",
    gameplayText: "item__VT_Emerald__gameplay",
    inventorySpriteId: "items/material_01.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3.5,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.VT_Ruby,
    itemType: ItemType.VendorTrash,
    itemRarity: ItemRarity.Uncommon,
    maxItemQuantityPerRoll: 1,
    name: "item__VT_Ruby__name",
    loreText: "item__VT_Ruby__lore",
    gameplayText: "item__VT_Ruby__gameplay",
    inventorySpriteId: "items/material_29.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3.5,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.VT_CubicZirconia,
    itemType: ItemType.VendorTrash,
    itemRarity: ItemRarity.Uncommon,
    maxItemQuantityPerRoll: 1,
    name: "item__VT_CubicZirconia__name",
    loreText: "item__VT_CubicZirconia__lore",
    gameplayText: "item__VT_CubicZirconia__gameplay",
    inventorySpriteId: "items/material_06.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3.5,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.VT_Sapphire,
    itemType: ItemType.VendorTrash,
    itemRarity: ItemRarity.Uncommon,
    maxItemQuantityPerRoll: 1,
    name: "item__VT_Sapphire__name",
    loreText: "item__VT_Sapphire__lore",
    gameplayText: "item__VT_Sapphire__gameplay",
    inventorySpriteId: "items/material_03.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3.5,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.VT_Diamond,
    itemType: ItemType.VendorTrash,
    itemRarity: ItemRarity.Rare,
    maxItemQuantityPerRoll: 1,
    name: "item__VT_Diamond__name",
    loreText: "item__VT_Diamond__lore",
    gameplayText: "item__VT_Diamond__gameplay",
    inventorySpriteId: "items/material_08.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3.5,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.VT_Amber,
    itemType: ItemType.VendorTrash,
    itemRarity: ItemRarity.Uncommon,
    maxItemQuantityPerRoll: 1,
    name: "item__VT_Amber__name",
    loreText: "item__VT_Amber__lore",
    gameplayText: "item__VT_Amber__gameplay",
    inventorySpriteId: "items/material_05.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3.5,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.VT_Eyeball,
    itemType: ItemType.VendorTrash,
    itemRarity: ItemRarity.Common,
    maxItemQuantityPerRoll: 1,
    name: "item__VT_Eyeball__name",
    loreText: "item__VT_Eyeball__lore",
    gameplayText: "item__VT_Eyeball__gameplay",
    inventorySpriteId: "items/icon895.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3.5,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Ogre]
});

setItemData({
    itemId: Item.VT_FangOfTheBloodMoon,
    itemType: ItemType.VendorTrash,
    itemRarity: ItemRarity.Legendary,
    maxItemQuantityPerRoll: 1,
    name: "item__VT_FangOfTheBloodMoon__name",
    loreText: "item__VT_FangOfTheBloodMoon__lore",
    gameplayText: "item__VT_FangOfTheBloodMoon__gameplay",
    inventorySpriteId: "items/icon887.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 36,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3.5,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.IronFox]
});

setItemData({
    itemId: Item.VT_ChunkOfIron,
    itemType: ItemType.VendorTrash,
    itemRarity: ItemRarity.Uncommon,
    maxItemQuantityPerRoll: 1,
    name: "item__VT_ChunkOfIron__name",
    loreText: "item__VT_ChunkOfIron__lore",
    gameplayText: "item__VT_ChunkOfIron__gameplay",
    inventorySpriteId: "items/material_12.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3.5,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Ogre]
});

setItemData({
    itemId: Item.VT_BrokenIronBand,
    itemType: ItemType.VendorTrash,
    itemRarity: ItemRarity.Common,
    maxItemQuantityPerRoll: 1,
    name: "item__VT_BrokenIronBand__name",
    loreText: "item__VT_BrokenIronBand__lore",
    gameplayText: "item__VT_BrokenIronBand__gameplay",
    inventorySpriteId: "items/broken_ring.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3.5,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.VT_RedMushroom,
    itemType: ItemType.VendorTrash,
    itemRarity: ItemRarity.Common,
    maxItemQuantityPerRoll: 1,
    name: "item__VT_RedMushroom__name",
    loreText: "item__VT_RedMushroom__lore",
    gameplayText: "item__VT_RedMushroom__gameplay",
    inventorySpriteId: "items/icon337.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 36,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3.5,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Treant]
});

setItemData({
    itemId: Item.VT_SkeletonFemur,
    itemType: ItemType.VendorTrash,
    itemRarity: ItemRarity.Common,
    maxItemQuantityPerRoll: 1,
    name: "item__VT_SkeletonFemur__name",
    loreText: "item__VT_SkeletonFemur__lore",
    gameplayText: "item__VT_SkeletonFemur__gameplay",
    inventorySpriteId: "items/icon892.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 38,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3.5,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Skeleton]
});

setItemData({
    itemId: Item.VT_GoldNugget,
    itemType: ItemType.VendorTrash,
    itemRarity: ItemRarity.Epic,
    maxItemQuantityPerRoll: 1,
    name: "item__VT_GoldNugget__name",
    loreText: "item__VT_GoldNugget__lore",
    gameplayText: "item__VT_GoldNugget__gameplay",
    inventorySpriteId: "items/icon890.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 38,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3.5,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.VT_AbyssalShard,
    itemType: ItemType.VendorTrash,
    itemRarity: ItemRarity.Epic,
    maxItemQuantityPerRoll: 1,
    name: "item__VT_AbyssalShard__name",
    loreText: "item__VT_AbyssalShard__lore",
    gameplayText: "item__VT_AbyssalShard__gameplay",
    inventorySpriteId: "items/material_07.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 2.5,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.DarkWizard]
});

setItemData({
    itemId: Item.VT_DukesDiary,
    itemType: ItemType.VendorTrash,
    itemRarity: ItemRarity.Legendary,
    maxItemQuantityPerRoll: 1,
    name: "item__VT_DukesDiary__name",
    loreText: "item__VT_DukesDiary__lore",
    gameplayText: "item__VT_DukesDiary__gameplay",
    inventorySpriteId: "items/icon380.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3.5,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.IronFox]
});

setItemData({
    itemId: Item.VT_QuartzRing,
    itemType: ItemType.VendorTrash,
    itemRarity: ItemRarity.Rare,
    maxItemQuantityPerRoll: 1,
    name: "item__VT_QuartzRing__name",
    loreText: "item__VT_QuartzRing__lore",
    gameplayText: "item__VT_QuartzRing__gameplay",
    inventorySpriteId: "gear/ring_097.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3.5,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.VT_RingOfNearPerfection,
    itemType: ItemType.VendorTrash,
    itemRarity: ItemRarity.Rare,
    maxItemQuantityPerRoll: 1,
    name: "item__VT_RingOfNearPerfection__name",
    loreText: "item__VT_RingOfNearPerfection__lore",
    gameplayText: "item__VT_RingOfNearPerfection__gameplay",
    inventorySpriteId: "gear/ring_099.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3.5,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.IronFox]
});

setItemData({
    itemId: Item.VT_OgreEntrails,
    itemType: ItemType.VendorTrash,
    itemRarity: ItemRarity.Common,
    maxItemQuantityPerRoll: 1,
    name: "item__VT_OgreEntrails__name",
    loreText: "item__VT_OgreEntrails__lore",
    gameplayText: "item__VT_OgreEntrails__gameplay",
    inventorySpriteId: "items/icon522.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3.5,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Ogre]
});

setItemData({
    itemId: Item.VT_Potato,
    itemType: ItemType.VendorTrash,
    itemRarity: ItemRarity.Common,
    maxItemQuantityPerRoll: 1,
    name: "item__VT_Potato__name",
    loreText: "item__VT_Potato__lore",
    gameplayText: "item__VT_Potato__gameplay",
    inventorySpriteId: "items/icon311.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 4,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies]
});

setItemData({
    itemId: Item.VT_TreantBranch,
    itemType: ItemType.VendorTrash,
    itemRarity: ItemRarity.Common,
    maxItemQuantityPerRoll: 1,
    name: "item__VT_TreantBranch__name",
    loreText: "item__VT_TreantBranch__lore",
    gameplayText: "item__VT_TreantBranch__gameplay",
    inventorySpriteId: "items/icon885.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3.5,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Treant]
});

setItemData({
    itemId: Item.VT_RatFur,
    itemType: ItemType.VendorTrash,
    itemRarity: ItemRarity.Common,
    maxItemQuantityPerRoll: 1,
    name: "item__VT_RatFur__name",
    loreText: "item__VT_RatFur__lore",
    gameplayText: "item__VT_RatFur__gameplay",
    inventorySpriteId: "items/icon948.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 4,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.GiantRat]
});

setItemData({
    itemId: Item.VT_EverlastingCandle,
    itemType: ItemType.VendorTrash,
    itemRarity: ItemRarity.Epic,
    maxItemQuantityPerRoll: 1,
    name: "item__VT_EverlastingCandle__name",
    loreText: "item__VT_EverlastingCandle__lore",
    gameplayText: "item__VT_EverlastingCandle__gameplay",
    inventorySpriteId: "items/icon431.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3.5,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Skeleton]
});

setItemData({
    itemId: Item.VT_StellarWeaveRing,
    itemType: ItemType.VendorTrash,
    itemRarity: ItemRarity.Rare,
    maxItemQuantityPerRoll: 1,
    name: "item__VT_StellarWeaveRing__name",
    loreText: "item__VT_StellarWeaveRing__lore",
    gameplayText: "item__VT_StellarWeaveRing__gameplay",
    inventorySpriteId: "gear/ring_120.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3.5,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.VT_BardsDiary,
    itemType: ItemType.VendorTrash,
    itemRarity: ItemRarity.Rare,
    maxItemQuantityPerRoll: 1,
    name: "item__VT_BardsDiary__name",
    loreText: "item__VT_BardsDiary__lore",
    gameplayText: "item__VT_BardsDiary__gameplay",
    inventorySpriteId: "items/icon368.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3.75,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.GRONK]
});

setItemData({
    itemId: Item.VT_RubyDust,
    itemType: ItemType.VendorTrash,
    itemRarity: ItemRarity.Uncommon,
    maxItemQuantityPerRoll: 1,
    name: "item__VT_RubyDust__name",
    loreText: "item__VT_RubyDust__lore",
    gameplayText: "item__VT_RubyDust__gameplay",
    inventorySpriteId: "items/chunk_7.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 1.5,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 2,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 32,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.VT_EmeraldDust,
    itemType: ItemType.VendorTrash,
    itemRarity: ItemRarity.Uncommon,
    maxItemQuantityPerRoll: 1,
    name: "item__VT_EmeraldDust__name",
    loreText: "item__VT_EmeraldDust__lore",
    gameplayText: "item__VT_EmeraldDust__gameplay",
    inventorySpriteId: "items/chunk_8.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 1.5,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 2,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 32,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.VT_SapphireDust,
    itemType: ItemType.VendorTrash,
    itemRarity: ItemRarity.Uncommon,
    maxItemQuantityPerRoll: 1,
    name: "item__VT_SapphireDust__name",
    loreText: "item__VT_SapphireDust__lore",
    gameplayText: "item__VT_SapphireDust__gameplay",
    inventorySpriteId: "items/chunk_5.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 1.5,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 2,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 32,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.VT_WellWornRing,
    itemType: ItemType.VendorTrash,
    itemRarity: ItemRarity.Common,
    maxItemQuantityPerRoll: 1,
    name: "item__VT_WellWornRing__name",
    loreText: "item__VT_WellWornRing__lore",
    gameplayText: "item__VT_WellWornRing__gameplay",
    inventorySpriteId: "gear/ring_100.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3.5,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.VT_WhispersOfTheVoid,
    itemType: ItemType.VendorTrash,
    itemRarity: ItemRarity.Rare,
    maxItemQuantityPerRoll: 1,
    name: "item__VT_WhispersOfTheVoid__name",
    loreText: "item__VT_WhispersOfTheVoid__lore",
    gameplayText: "item__VT_WhispersOfTheVoid__gameplay",
    inventorySpriteId: "items/icon370.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3.5,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.DarkWizard]
});

setItemData({
    itemId: Item.VT_Cabbage,
    itemType: ItemType.VendorTrash,
    itemRarity: ItemRarity.Rare,
    maxItemQuantityPerRoll: 1,
    name: "item__VT_Cabbage__name",
    loreText: "item__VT_Cabbage__lore",
    gameplayText: "item__VT_Cabbage__gameplay",
    inventorySpriteId: "items/cabbage.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3.5,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

/**********************************************/
/*    Collectors Items! Item IDs 4000 -> 4999     */
/**********************************************/

setItemData({
    itemId: Item.CI_FragmentOfFate,
    itemType: ItemType.CollectorsItem,
    itemRarity: ItemRarity.Legendary,
    maxItemQuantityPerRoll: 1,
    name: "item__CI_FragmentOfFate__name",
    loreText: "item__CI_FragmentOfFate__lore",
    gameplayText: "item__CI_FragmentOfFate__gameplay",
    inventorySpriteId: "items/skill_033.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 3,
    tooltipSlotSpriteOffsetLeft: 37,
    tooltipSlotSpriteOffsetTop: 40,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3.5,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.DarkWizard]
});

setItemData({
    itemId: Item.CI_PlayerSoulFragment,
    itemType: ItemType.CollectorsItem,
    itemRarity: ItemRarity.Epic,
    maxItemQuantityPerRoll: 1,
    name: "item__CI_PlayerSoulFragment__name",
    loreText: "item__CI_PlayerSoulFragment__lore",
    gameplayText: "item__CI_PlayerSoulFragment__gameplay",
    inventorySpriteId: "items/material_10.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 2.75,
    tooltipSlotSpriteOffsetLeft: 42,
    tooltipSlotSpriteOffsetTop: 41,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: []
});

setItemData({
    itemId: Item.CI_LatrosKnavishBand,
    itemType: ItemType.CollectorsItem,
    itemRarity: ItemRarity.Rare,
    maxItemQuantityPerRoll: 1,
    name: "item__CI_LatrosKnavishBand__name",
    loreText: "item__CI_LatrosKnavishBand__lore",
    gameplayText: "item__CI_LatrosKnavishBand__gameplay",
    inventorySpriteId: "gear/ring_104.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 2.75,
    tooltipSlotSpriteOffsetLeft: 42,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.CI_WeddingRing,
    itemType: ItemType.CollectorsItem,
    itemRarity: ItemRarity.Uncommon,
    maxItemQuantityPerRoll: 1,
    name: "item__CI_WeddingRing__name",
    loreText: "item__CI_WeddingRing__lore",
    gameplayText: "item__CI_WeddingRing__gameplay",
    inventorySpriteId: "gear/ring_073.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 2.75,
    tooltipSlotSpriteOffsetLeft: 42,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Skeleton]
});

setItemData({
    itemId: Item.CI_PeachRing,
    itemType: ItemType.CollectorsItem,
    itemRarity: ItemRarity.Rare,
    maxItemQuantityPerRoll: 1,
    name: "item__CI_PeachRing__name",
    loreText: "item__CI_PeachRing__lore",
    gameplayText: "item__CI_PeachRing__gameplay",
    inventorySpriteId: "gear/ring_108.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 2.75,
    tooltipSlotSpriteOffsetLeft: 42,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Ghost]
});

setItemData({
    itemId: Item.CI_RingOfDelerium,
    itemType: ItemType.CollectorsItem,
    itemRarity: ItemRarity.Uncommon,
    maxItemQuantityPerRoll: 1,
    name: "item__CI_RingOfDelerium__name",
    loreText: "item__CI_RingOfDelerium__lore",
    gameplayText: "item__CI_RingOfDelerium__gameplay",
    inventorySpriteId: "gear/ring_038.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 2.75,
    tooltipSlotSpriteOffsetLeft: 42,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.CI_LifesSimpleRing,
    itemType: ItemType.CollectorsItem,
    itemRarity: ItemRarity.Uncommon,
    maxItemQuantityPerRoll: 1,
    name: "item__CI_LifesSimpleRing__name",
    loreText: "item__CI_LifesSimpleRing__lore",
    gameplayText: "item__CI_LifesSimpleRing__gameplay",
    inventorySpriteId: "gear/ring_055.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 2.75,
    tooltipSlotSpriteOffsetLeft: 42,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.AllEnemies, LootSources.AllLootChests]
});

setItemData({
    itemId: Item.CI_SwampOgresRing,
    itemType: ItemType.CollectorsItem,
    itemRarity: ItemRarity.Legendary,
    maxItemQuantityPerRoll: 1,
    name: "item__CI_SwampOgresRing__name",
    loreText: "item__CI_SwampOgresRing__lore",
    gameplayText: "item__CI_SwampOgresRing__gameplay",
    inventorySpriteId: "gear/ring_002.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 2.75,
    tooltipSlotSpriteOffsetLeft: 42,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Ogre, LootSources.GRONK]
});

setItemData({
    itemId: Item.CI_RoyalBrooch,
    itemType: ItemType.CollectorsItem,
    itemRarity: ItemRarity.Epic,
    maxItemQuantityPerRoll: 1,
    name: "item__CI_RoyalBrooch__name",
    loreText: "item__CI_RoyalBrooch__lore",
    gameplayText: "item__CI_RoyalBrooch__gameplay",
    inventorySpriteId: "gear/brooch_03.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 1,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 2.75,
    tooltipSlotSpriteOffsetLeft: 42,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 1,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.DarkWizard]
});

setItemData({
    itemId: Item.CI_SuperStar,
    itemType: ItemType.CollectorsItem,
    itemRarity: ItemRarity.Legendary,
    maxItemQuantityPerRoll: 1,
    name: "item__CI_SuperStar__name",
    loreText: "item__CI_SuperStar__lore",
    gameplayText: "item__CI_SuperStar__gameplay",
    inventorySpriteId: "gear/brooch_04.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 2.75,
    tooltipSlotSpriteOffsetLeft: 42,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Treant]
});

setItemData({
    itemId: Item.CI_Ouro,
    itemType: ItemType.CollectorsItem,
    itemRarity: ItemRarity.Legendary,
    maxItemQuantityPerRoll: 1,
    name: "item__CI_Ouro__name",
    loreText: "item__CI_Ouro__lore",
    gameplayText: "item__CI_Ouro__gameplay",
    inventorySpriteId: "gear/ring_011.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 2.75,
    tooltipSlotSpriteOffsetLeft: 42,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.GRONK]
});

setItemData({
    itemId: Item.CI_AlloyedBend,
    itemType: ItemType.CollectorsItem,
    itemRarity: ItemRarity.Rare,
    maxItemQuantityPerRoll: 1,
    name: "item__CI_AlloyedBend__name",
    loreText: "item__CI_AlloyedBend__lore",
    gameplayText: "item__CI_AlloyedBend__gameplay",
    inventorySpriteId: "gear/ring_117.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 2.75,
    tooltipSlotSpriteOffsetLeft: 42,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Ogre]
});

setItemData({
    itemId: Item.CI_ProudlyPinkRing,
    itemType: ItemType.CollectorsItem,
    itemRarity: ItemRarity.Uncommon,
    maxItemQuantityPerRoll: 1,
    name: "item__CI_ProudlyPinkRing__name",
    loreText: "item__CI_ProudlyPinkRing__lore",
    gameplayText: "item__CI_ProudlyPinkRing__gameplay",
    inventorySpriteId: "gear/ring_114.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 2.75,
    tooltipSlotSpriteOffsetLeft: 42,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Skeleton]
});

setItemData({
    itemId: Item.CI_ApposSignet,
    itemType: ItemType.CollectorsItem,
    itemRarity: ItemRarity.Rare,
    maxItemQuantityPerRoll: 1,
    name: "item__CI_ApposSignet__name",
    loreText: "item__CI_ApposSignet__lore",
    gameplayText: "item__CI_ApposSignet__gameplay",
    inventorySpriteId: "gear/ring_109.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 2.75,
    tooltipSlotSpriteOffsetLeft: 42,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.GiantRat]
});

setItemData({
    itemId: Item.CI_CelestialEternity,
    itemType: ItemType.CollectorsItem,
    itemRarity: ItemRarity.Epic,
    maxItemQuantityPerRoll: 1,
    name: "item__CI_CelestialEternity__name",
    loreText: "item__CI_CelestialEternity__lore",
    gameplayText: "item__CI_CelestialEternity__gameplay",
    inventorySpriteId: "gear/ring_087.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 2.75,
    tooltipSlotSpriteOffsetLeft: 42,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Treant]
});

setItemData({
    itemId: Item.CI_BloodhuntersRing,
    itemType: ItemType.CollectorsItem,
    itemRarity: ItemRarity.Legendary,
    maxItemQuantityPerRoll: 1,
    name: "item__CI_BloodhuntersRing__name",
    loreText: "item__CI_BloodhuntersRing__lore",
    gameplayText: "item__CI_BloodhuntersRing__gameplay",
    inventorySpriteId: "gear/ring_080.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 2.75,
    tooltipSlotSpriteOffsetLeft: 42,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.IronFox]
});

setItemData({
    itemId: Item.CI_EbonstarBand,
    itemType: ItemType.CollectorsItem,
    itemRarity: ItemRarity.Legendary,
    maxItemQuantityPerRoll: 1,
    name: "item__CI_EbonstarBand__name",
    loreText: "item__CI_EbonstarBand__lore",
    gameplayText: "item__CI_EbonstarBand__gameplay",
    inventorySpriteId: "gear/ring_052.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 2.75,
    tooltipSlotSpriteOffsetLeft: 42,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Ghost]
});

setItemData({
    itemId: Item.CI_LuminousEnigma,
    itemType: ItemType.CollectorsItem,
    itemRarity: ItemRarity.Epic,
    maxItemQuantityPerRoll: 1,
    name: "item__CI_LuminousEnigma__name",
    loreText: "item__CI_LuminousEnigma__lore",
    gameplayText: "item__CI_LuminousEnigma__gameplay",
    inventorySpriteId: "gear/armor_03.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 2.75,
    tooltipSlotSpriteOffsetLeft: 42,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.DarkWizard]
});

setItemData({
    itemId: Item.CI_RadiantSunstoneBand,
    itemType: ItemType.CollectorsItem,
    itemRarity: ItemRarity.Epic,
    maxItemQuantityPerRoll: 1,
    name: "item__CI_RadiantSunstoneBand__name",
    loreText: "item__CI_RadiantSunstoneBand__lore",
    gameplayText: "item__CI_RadiantSunstoneBand__gameplay",
    inventorySpriteId: "gear/ring_009.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 2.75,
    tooltipSlotSpriteOffsetLeft: 42,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.IronFox]
});

setItemData({
    itemId: Item.CI_EchoingWhisperNecklace,
    itemType: ItemType.CollectorsItem,
    itemRarity: ItemRarity.Rare,
    maxItemQuantityPerRoll: 1,
    name: "item__CI_EchoingWhisperNecklace__name",
    loreText: "item__CI_EchoingWhisperNecklace__lore",
    gameplayText: "item__CI_EchoingWhisperNecklace__gameplay",
    inventorySpriteId: "gear/necklace_07.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 2.75,
    tooltipSlotSpriteOffsetLeft: 42,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 1,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.IronFox]
});

setItemData({
    itemId: Item.CI_FeyEnchantmentLocket,
    itemType: ItemType.CollectorsItem,
    itemRarity: ItemRarity.Uncommon,
    maxItemQuantityPerRoll: 1,
    name: "item__CI_FeyEnchantmentLocket__name",
    loreText: "item__CI_FeyEnchantmentLocket__lore",
    gameplayText: "item__CI_FeyEnchantmentLocket__gameplay",
    inventorySpriteId: "gear/necklace_18.png",
    smallInventorySlotRotation: 0,
    smallInventorySlotSpriteScale: 2,
    smallInventorySlotSpriteOffsetLeft: 0.5,
    smallInventorySlotSpriteOffsetTop: 0,
    tooltipSlotRotation: 0,
    tooltipSlotSpriteScale: 2.75,
    tooltipSlotSpriteOffsetLeft: 42,
    tooltipSlotSpriteOffsetTop: 35,
    largeInventorySlotRotation: 0,
    largeInventorySlotSpriteScale: 3,
    largeInventorySlotSpriteOffsetLeft: 0,
    largeInventorySlotSpriteOffsetTop: 2,
    passiveStats: [],
    passiveAbilities: [],
    activeAbility: NoneActiveAbility,
    itemShouldBeDestroyedIfChargesExpended: false,
    droppedBy: [LootSources.Treant]
});
//#endregion

//#region Loot Tables!
/********************************************************/
/*                                                      */
/*                     Loot Tables                      */
/*                                                      */
/********************************************************/

const allCommonItems: Item[] = getAllItemIds().filter((v) => getItemData(v).itemRarity === ItemRarity.Common);
const allUncommonItems: Item[] = getAllItemIds().filter((v) => getItemData(v).itemRarity === ItemRarity.Uncommon);
const allRareItems: Item[] = getAllItemIds().filter((v) => getItemData(v).itemRarity === ItemRarity.Rare);
const allEpicItems: Item[] = getAllItemIds().filter((v) => getItemData(v).itemRarity === ItemRarity.Epic);
const allLegendaryItems: Item[] = getAllItemIds().filter((v) => getItemData(v).itemRarity === ItemRarity.Legendary);
export const quantityOfAllItems = getAllItemIds().length;
export const quantityOfAllCommonItems = allCommonItems.length;
export const quantityOfAllUncommonItems = allUncommonItems.length;
export const quantityOfAllRareItems = allRareItems.length;
export const quantityOfAllEpicItems = allEpicItems.length;
export const quantityOfAllLegendaryItems = allLegendaryItems.length;

export enum LootTable {
    AllItems = 0,
    LootChest = 1,
    GRONK = 2,
    Skeleton = 3,
    Ghost = 4,
    Rat = 5,
    Treant = 6,
    Ogre = 7,
    IronFox = 8,
    DarkWizard = 9
}

const lootTableData: Map<LootTable, LootTableData> = new Map();

export const setLootTableData = (lootTableId: LootTable, lootTable: LootTableData) => {
    const existingEntryWithId = lootTableData.get(lootTableId);

    if (existingEntryWithId) {
        throw new Error(`Loot Table with id ${lootTableId} already exists! Error in Item data file.`);
    }

    lootTableData.set(lootTableId, lootTable);
};

export const getLootTableData = (lootTableId: LootTable): LootTableData => {
    const foundLootTable = lootTableData.get(lootTableId);

    if (!foundLootTable) {
        throw new Error(`Loot Table with id ${lootTableId} does not exist! Error in Item data file.`);
    }

    return foundLootTable;
};

/*******************/
/*  All Items      */
/*******************/
setLootTableData(LootTable.AllItems, {
    CommonItems: allCommonItems,
    UncommonItems: allUncommonItems,
    RareItems: allRareItems,
    EpicItems: allEpicItems,
    LegendaryItems: allLegendaryItems
});

/*******************/
/*  Loot Chests    */
/*******************/
const lootChestCommonItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Common && data.droppedBy.includes(LootSources.AllLootChests);
});
const lootChestUncommonItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Uncommon && data.droppedBy.includes(LootSources.AllLootChests);
});
const lootChestRareItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Rare && data.droppedBy.includes(LootSources.AllLootChests);
});
const lootChestEpicItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Epic && data.droppedBy.includes(LootSources.AllLootChests);
});
const lootChestLegendaryItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Legendary && data.droppedBy.includes(LootSources.AllLootChests);
});

setLootTableData(LootTable.LootChest, {
    CommonItems: lootChestCommonItems,
    UncommonItems: lootChestUncommonItems,
    RareItems: lootChestRareItems,
    EpicItems: lootChestEpicItems,
    LegendaryItems: lootChestLegendaryItems
});

/*******************/
/*  Iron Fox       */
/*******************/
const ironFoxCommonItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Common && data.droppedBy.includes(LootSources.IronFox);
});
const ironFoxUncommonItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Uncommon && data.droppedBy.includes(LootSources.IronFox);
});
const ironFoxRareItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Rare && data.droppedBy.includes(LootSources.IronFox);
});
const ironFoxEpicItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Epic && data.droppedBy.includes(LootSources.IronFox);
});
const ironFoxLegendaryItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Legendary && data.droppedBy.includes(LootSources.IronFox);
});

setLootTableData(LootTable.IronFox, {
    CommonItems: ironFoxCommonItems,
    UncommonItems: ironFoxUncommonItems,
    RareItems: ironFoxRareItems,
    EpicItems: ironFoxEpicItems,
    LegendaryItems: ironFoxLegendaryItems
});

/*******************/
/*  GRONK          */
/*******************/
const gronkCommonItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Common && data.droppedBy.includes(LootSources.GRONK);
});
const gronkUncommonItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Uncommon && data.droppedBy.includes(LootSources.GRONK);
});
const gronkRareItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Rare && data.droppedBy.includes(LootSources.GRONK);
});
const gronkEpicItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Epic && data.droppedBy.includes(LootSources.GRONK);
});
const gronkLegendaryItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Legendary && data.droppedBy.includes(LootSources.GRONK);
});

setLootTableData(LootTable.GRONK, {
    CommonItems: gronkCommonItems,
    UncommonItems: gronkUncommonItems,
    RareItems: gronkRareItems,
    EpicItems: gronkEpicItems,
    LegendaryItems: gronkLegendaryItems
});

/*******************/
/*  Skeleton       */
/*******************/
const skeletonCommonItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Common && (data.droppedBy.includes(LootSources.Skeleton) || data.droppedBy.includes(LootSources.AllEnemies));
});
const skeletonUncommonItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Uncommon && (data.droppedBy.includes(LootSources.Skeleton) || data.droppedBy.includes(LootSources.AllEnemies));
});
const skeletonRareItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Rare && (data.droppedBy.includes(LootSources.Skeleton) || data.droppedBy.includes(LootSources.AllEnemies));
});
const skeletonEpicItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Epic && (data.droppedBy.includes(LootSources.Skeleton) || data.droppedBy.includes(LootSources.AllEnemies));
});
const skeletonLegendaryItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Legendary && (data.droppedBy.includes(LootSources.Skeleton) || data.droppedBy.includes(LootSources.AllEnemies));
});

setLootTableData(LootTable.Skeleton, {
    CommonItems: skeletonCommonItems,
    UncommonItems: skeletonUncommonItems,
    RareItems: skeletonRareItems,
    EpicItems: skeletonEpicItems,
    LegendaryItems: skeletonLegendaryItems
});

/*******************/
/*  Ghost          */
/*******************/
const ghostCommonItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Common && (data.droppedBy.includes(LootSources.Ghost) || data.droppedBy.includes(LootSources.AllEnemies));
});
const ghostUncommonItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Uncommon && (data.droppedBy.includes(LootSources.Ghost) || data.droppedBy.includes(LootSources.AllEnemies));
});
const ghostRareItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Rare && (data.droppedBy.includes(LootSources.Ghost) || data.droppedBy.includes(LootSources.AllEnemies));
});
const ghostEpicItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Epic && (data.droppedBy.includes(LootSources.Ghost) || data.droppedBy.includes(LootSources.AllEnemies));
});
const ghostLegendaryItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Legendary && (data.droppedBy.includes(LootSources.Ghost) || data.droppedBy.includes(LootSources.AllEnemies));
});

setLootTableData(LootTable.Ghost, {
    CommonItems: ghostCommonItems,
    UncommonItems: ghostUncommonItems,
    RareItems: ghostRareItems,
    EpicItems: ghostEpicItems,
    LegendaryItems: ghostLegendaryItems
});

/*******************/
/*  Rat            */
/*******************/
const ratCommonItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Common && (data.droppedBy.includes(LootSources.GiantRat) || data.droppedBy.includes(LootSources.AllEnemies));
});
const ratUncommonItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Uncommon && (data.droppedBy.includes(LootSources.GiantRat) || data.droppedBy.includes(LootSources.AllEnemies));
});
const ratRareItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Rare && (data.droppedBy.includes(LootSources.GiantRat) || data.droppedBy.includes(LootSources.AllEnemies));
});
const ratEpicItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Epic && (data.droppedBy.includes(LootSources.GiantRat) || data.droppedBy.includes(LootSources.AllEnemies));
});
const ratLegendaryItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Legendary && (data.droppedBy.includes(LootSources.GiantRat) || data.droppedBy.includes(LootSources.AllEnemies));
});

setLootTableData(LootTable.Rat, {
    CommonItems: ratCommonItems,
    UncommonItems: ratUncommonItems,
    RareItems: ratRareItems,
    EpicItems: ratEpicItems,
    LegendaryItems: ratLegendaryItems
});

/*******************/
/*  Treant         */
/*******************/
const treantCommonItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Common && (data.droppedBy.includes(LootSources.Treant) || data.droppedBy.includes(LootSources.AllEnemies));
});
const treantUncommonItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Uncommon && (data.droppedBy.includes(LootSources.Treant) || data.droppedBy.includes(LootSources.AllEnemies));
});
const treantRareItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Rare && (data.droppedBy.includes(LootSources.Treant) || data.droppedBy.includes(LootSources.AllEnemies));
});
const treantEpicItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Epic && (data.droppedBy.includes(LootSources.Treant) || data.droppedBy.includes(LootSources.AllEnemies));
});
const treantLegendaryItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Legendary && (data.droppedBy.includes(LootSources.Treant) || data.droppedBy.includes(LootSources.AllEnemies));
});

setLootTableData(LootTable.Treant, {
    CommonItems: treantCommonItems,
    UncommonItems: treantUncommonItems,
    RareItems: treantRareItems,
    EpicItems: treantEpicItems,
    LegendaryItems: treantLegendaryItems
});

/*******************/
/*  Ogre           */
/*******************/
const ogreCommonItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Common && (data.droppedBy.includes(LootSources.Ogre) || data.droppedBy.includes(LootSources.AllEnemies));
});
const ogreUncommonItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Uncommon && (data.droppedBy.includes(LootSources.Ogre) || data.droppedBy.includes(LootSources.AllEnemies));
});
const ogreRareItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Rare && (data.droppedBy.includes(LootSources.Ogre) || data.droppedBy.includes(LootSources.AllEnemies));
});
const ogreEpicItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Epic && (data.droppedBy.includes(LootSources.Ogre) || data.droppedBy.includes(LootSources.AllEnemies));
});
const ogreLegendaryItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Legendary && (data.droppedBy.includes(LootSources.Ogre) || data.droppedBy.includes(LootSources.AllEnemies));
});

setLootTableData(LootTable.Ogre, {
    CommonItems: ogreCommonItems,
    UncommonItems: ogreUncommonItems,
    RareItems: ogreRareItems,
    EpicItems: ogreEpicItems,
    LegendaryItems: ogreLegendaryItems
});

/*******************/
/*  Dark Wizard    */
/*******************/
const darkWizardCommonItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Common && (data.droppedBy.includes(LootSources.DarkWizard) || data.droppedBy.includes(LootSources.AllEnemies));
});
const darkWizardUncommonItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Uncommon && (data.droppedBy.includes(LootSources.DarkWizard) || data.droppedBy.includes(LootSources.AllEnemies));
});
const darkWizardRareItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Rare && (data.droppedBy.includes(LootSources.DarkWizard) || data.droppedBy.includes(LootSources.AllEnemies));
});
const darkWizardEpicItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Epic && (data.droppedBy.includes(LootSources.DarkWizard) || data.droppedBy.includes(LootSources.AllEnemies));
});
const darkWizardLegendaryItems: Item[] = getAllItemIds().filter((v) => {
    const data = getItemData(v);
    return data.itemRarity === ItemRarity.Legendary && (data.droppedBy.includes(LootSources.DarkWizard) || data.droppedBy.includes(LootSources.AllEnemies));
});

setLootTableData(LootTable.DarkWizard, {
    CommonItems: darkWizardCommonItems,
    UncommonItems: darkWizardUncommonItems,
    RareItems: darkWizardRareItems,
    EpicItems: darkWizardEpicItems,
    LegendaryItems: darkWizardLegendaryItems
});
//#endregion

/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { useRecoilValue } from "recoil";
import { regionStateAtom } from "../framework/UI_State";

export const RegionPopUp = () => {
    const { shown, regionName } = useRecoilValue(regionStateAtom);
    return (
        <div className={`region-pop-up animate__animated ${shown ? "animate__fadeIn" : "animate__fadeOut"}`}>
            <h2>{regionName}</h2>
            <div className="underline"></div>
        </div>
    );
};

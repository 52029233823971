import { AnimatedSprite, Container, Graphics, Texture, Text } from "pixi.js";
import { NType, ItemContainerEntityCreationPayload } from "../../shared/SharedNetcodeSchemas";
import { Vector } from "sat";
import { IRenderedEntity } from "../ClientTypes";
import { lootConfig } from "../../shared/config/Config_Loot";
import { SharedItemContainer } from "../../shared/entities/SharedItemContainer";
import { OutlineFilter } from "pixi-filters";
import { distanceBetweenPoints } from "../../shared/SharedUtils";
import { t } from "../../shared/data/Data_I18N";

export class ClientItemContainer extends SharedItemContainer implements IRenderedEntity {
    public override nid: number;
    public override ntype: NType;
    public override position: SAT.Vector = new Vector(0, 0);
    public RenderedEntity: Container = new Container();
    public hitboxGraphics: Graphics = new Graphics();
    private promptText: Text;
    private defaultFilter: OutlineFilter = new OutlineFilter(2, 0x8fffac);
    private interactingWithFilter: OutlineFilter = new OutlineFilter(3, 0xffffff);
    private itemContainerSprite: AnimatedSprite;
    private spriteFrames: Array<Texture>;
    private readonly animationSpeed: number = 1 / 9;
    private uniqueContainerId: string;

    public constructor(createItemContainerPayload: ItemContainerEntityCreationPayload) {
        const { nid, x, y, spriteId, uniqueContainerId, containerName, owningPlayerNid } = createItemContainerPayload;

        super(x, y);

        if (owningPlayerNid === Game.Renderer.MyLocalNonPredictedEntity?.nid) {
            // console.log("got container creat msg for my gravestone!");
            this.RenderedEntity.alpha = 0;
        } else if (containerName !== "Loot Chest") {
            this.RenderedEntity.alpha = 0;
            setTimeout(() => {
                this.RenderedEntity.alpha = 1;
            }, 750);
        }

        this.uniqueContainerId = uniqueContainerId;

        this.nid = nid;

        this.x = x;
        this.y = y;
        this.RenderedEntity.x = x;
        this.RenderedEntity.y = y;
        this.RenderedEntity.zIndex = y + 10;

        // console.log("Using container name on client:", containerName);

        let spriteAnimationName;

        if (containerName === "Loot Chest") {
            spriteAnimationName = "objects/stuff_box";
        } else if (containerName === "Loot Drop") {
            spriteAnimationName = "objects/stuff_bag";
        } else {
            spriteAnimationName = spriteId;
        }

        const spriteAnimation = Game.Loader.GetAnimation(spriteAnimationName);

        // console.log("Using sprite name on client:", spriteAnimation);

        this.spriteFrames = new Array<Texture>();
        for (let i = 0; i < spriteAnimation.length; i++) {
            this.spriteFrames.push(Texture.from(spriteAnimation[i]));
        }

        this.promptText = new Text(t("loot_prompt"), {
            fontFamily: "alagardmedium",
            fontSize: 64,
            fill: 0xffffff
        });
        this.promptText.scale.set(0.15);
        this.promptText.anchor.set(0.5, 0.5);
        this.promptText.y -= 25;
        this.promptText.alpha = 0;
        this.RenderedEntity.addChild(this.promptText);

        this.itemContainerSprite = new AnimatedSprite(this.spriteFrames);
        this.itemContainerSprite.scale.set(1);
        this.itemContainerSprite.anchor.set(0.5, 0.5);
        this.itemContainerSprite.animationSpeed = this.animationSpeed;
        this.itemContainerSprite.play();
        this.itemContainerSprite.filters = [this.defaultFilter];
        this.RenderedEntity.addChild(this.itemContainerSprite);

        if (lootConfig.DEBUG.DRAW_ITEM_CONTAINER_COLLIDER) {
            this.hitboxGraphics.lineStyle(2, 0x00ff00);
            this.hitboxGraphics.position.set(0, 0);
            this.hitboxGraphics.drawCircle(this.x, this.y, lootConfig.ITEM_CONTAINER_COLLIDER_RADIUS);
            this.RenderedEntity.addChild(this.hitboxGraphics);
        }
    }

    private _displayInteractionPrompt(): void {
        this.itemContainerSprite.filters = [this.interactingWithFilter];
        this.promptText.alpha = 1;
        // console.log("Showing interaction prompt");
    }

    private _hideInteractionPrompt(): void {
        this.itemContainerSprite.filters = [this.defaultFilter];
        this.promptText.alpha = 0;
        // console.log("Hiding interaction prompt");
    }

    private _updateInteractiveState(): void {
        const myEntity = Game.Renderer.MyLocalNonPredictedEntity;
        // TODO: very hacky but it works :shrug:
        if (myEntity) {
            const distanceToPlayer = distanceBetweenPoints(myEntity.position.x, myEntity.position.y, this.x, this.y);
            if (distanceToPlayer <= lootConfig.ITEM_CONTAINER_COLLIDER_RADIUS) {
                myEntity.InteractingWithContainerOfId = this.uniqueContainerId;
                this._displayInteractionPrompt();
            } else {
                this._hideInteractionPrompt();
            }
        }
    }

    public override Update(deltaTime: number): void {
        this._updateInteractiveState();
    }
}

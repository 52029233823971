import React from "react";
import { useRecoilValue } from "recoil";
import { UI_ToggleInventoryPanel, itemsLootedOnRunStateAtom, quantityOfItemsInRunInventorySelector } from "../framework/UI_State";

//@ts-ignore
import inventoryIconImageSrc from "../kit/inventory-icon.png";
import { t } from "../../../shared/data/Data_I18N";

export const BottomLeftHUD = () => {
    const quantityOfItemsInRunInventory = useRecoilValue(quantityOfItemsInRunInventorySelector);
    const { showInventoryPanel } = useRecoilValue(itemsLootedOnRunStateAtom);

    return (
        <div className="bottom-left-hud animate__animated animate__slideInLeft">
            <div
                style={{ pointerEvents: "all", cursor: "pointer" }}
                className="bottom-left-inventory"
                onClick={() => {
                    UI_ToggleInventoryPanel();
                }}
            >
                <div className="inventory-sprite">
                    <img src={inventoryIconImageSrc} alt="Inventory" />
                    <div className="item-quantity">{quantityOfItemsInRunInventory}</div>
                </div>
                <div className="inventory-texts">
                    <div className="inventory-text">
                        [ {t("hotkey_open_inventory")} ] <span>{showInventoryPanel === false && t("game_hud__inventory_hud")}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

{
    "main_menu__shop_btn": "Shop",
    "shop__title": "Shop",
    "loadout_menu__no_slot_selected_insurance_text_2": "No slot is insured! Spend an insurance token to insure 1 item.",

    "adblock_detected_failed_to_insure": "Something went wrong insuring that item. Please ensure your adblock is disabled!",
    "ability_type_active": "Active",
    "ability_type_passive": "Passive",
    "change_username__back": "Back",
    "change_username__cant_be_empty_error": "Username cannot be empty",
    "change_username__current_prompt": "Your current username",
    "change_username__enter_new": "Enter a new username",
    "change_username__header": "Username",
    "change_username__save": "Save",
    "change_username__too_long_error": "Username cannot be longer than 20 characters.",
    "character_already_on_run_menu__abondon_run_button": "Abandon Run",
    "character_already_on_run_menu__back_to_main_menu_button": "Back",
    "character_already_on_run_menu__bolded_header": "Your character is already on a run!",
    "character_already_on_run_menu__paragraph_one": "Your character has not yet returned from their previous run. If you start a new run now, all of your items from the prior run (minus your insured items) will be lost!",
    "character_already_on_run_menu__paragraph_two": "If you think this is a bug, or if you intentionally want to abandon your run, you can forcibly abandon your run using the button below.",
    "character_already_on_run_menu__title": "WARNING!",
    "connection_error__back_to_main_menu": "Return to main menu",
    "connection_error__error_reason": "Reason",
    "connection_error__oof": "Oof",
    "connection_error__server_error_header": "Server connection error!",
    "death_menu__back_to_main_menu_button": "Back To Menu",
    "death_menu__gear_lost": "Lost Gear",
    "death_menu__gold_lost": "Gold Lost",
    "death_menu__insured_items": "Insured Items",
    "death_menu__loot_lost": "Lost Loot",
    "death_menu__nothing_insured": "No items were insured!",
    "death_menu__saving_your_run_button": "Saving...",
    "death_menu__spectate_button": "Spectate Killer",
    "death_menu__survived_for": "Survived For",
    "death_menu__title": "R.I.P",
    "death_menu__weapons_lost": "Lost Weapons",
    "death_menu__your_killer": "Your Killer",
    "extracted_menu__back_to_main_menu_button": "Back To Menu",
    "extracted_menu__gold_looted": "Gold Looted",
    "extracted_menu__loot_acquired": "Loot Acquired",
    "extracted_menu__run_duration": "Run Duration",
    "extracted_menu__title": "Extracted!",
    "game_hud__cooldown": "Cooldown",
    "game_hud__find_an_extraction_point": "Find an extraction point!",
    "game_hud__inventory_extract_prompt": "You have to extract to use this loot!",
    "game_hud__inventory_hud": "Open Inventory",
    "game_hud__leaderboard": "Leaderboard",
    "game_hud__leaderboard_current_world": "Current World",
    "game_hud__leaderboard_hide": "Hide",
    "game_hud__leaderboard_show": "Show",
    "game_hud__looted_container_close": "Close",
    "game_hud__spectating": "Spectating",
    "game_hud__time_left_to_extract": "left to extract",
    "game_hud__your_inventory_header": "Your Inventory!",
    "game_hud__your_inventory_hide": "to close",
    "game_transition__finding_match": "Finding Match",
    "hotkey_interact": "E",
    "hotkey_open_inventory": "i",
    "hotkey_toggle_leaderboard": "L",
    "how_to_play__ability_one_key": "Ability #1",
    "how_to_play__ability_three_key": "Ability #3",
    "how_to_play__ability_two_key": "Ability #2",
    "how_to_play__back_to_main_menu_button": "Back",
    "how_to_play__chat_key": "Chat",
    "how_to_play__extraction": "Find an extraction point!",
    "how_to_play__inventory_key": "Inventory",
    "how_to_play__leaderboard_key": "Leaderboard",
    "how_to_play__mouse_aim_controls": ": Aim",
    "how_to_play__mouse_shoot_controls": ": Shoot",
    "how_to_play__move_down_key": "Move Down",
    "how_to_play__move_left_key": "Move Left",
    "how_to_play__move_right_key": "Move Right",
    "how_to_play__move_up_key": "Move Up",
    "how_to_play__small_paragraph_one": "If you fail to extract in time, you lose all of the items you brought with you (unless you have insurance!) and all the items you looted on your run. Extracting is very important. You must EXTRACT with your loot to keep it and use it.",
    "how_to_play__small_paragraph_three": "Kill or be killed, may the best adventurer win!",
    "how_to_play__small_paragraph_two": " ",
    "how_to_play__test_keys_header": "Test the keys!",
    "how_to_play__title": "PvPz.io",
    "how_to_play__top_big_paragraph": "Kill other players, find loot, and extract!",
    "how_to_play__weapon_one_key": "Weapon #1",
    "how_to_play__weapon_two_key": "Weapon #2",
    "in_game__extracting": "Extracting...",
    "in_game__time_remaining": "Time Remaining...",
    "in_game__warning_time_almost_up": "Find an extraction point!",
    "insured_item_nothing": "Nothing",
    "item__AG_AmuletOfCursedFate__gameplay": "+10% Attack Speed | REVIVE with 40% HP when you die (does not stack with other revive items)",
    "item__AG_AmuletOfCursedFate__lore": "A malevolent amulet that twists destinies and brings forth dark omens.",
    "item__AG_AmuletOfCursedFate__name": "Amulet of Cursed Fate",
    "item__AG_MagicBoots__gameplay": "Become invisible for 9 seconds on use (2 Uses) | Your health regenerates all the time | Extract 10% slower",
    "item__AG_MagicBoots__lore": "These boots make no noise and invigorate their wearer",
    "item__AG_MagicBoots__name": "Magic Boots",
    "item__AG_Boros__gameplay": "Become invisible for 7 seconds on use | -12% Damage to NPCs | +12% Leech from all damage you deal to players",
    "item__AG_Boros__lore": "Its gem is rumored to be created from the eye of a shapeshifter.",
    "item__AG_Boros__name": "Boros",
    "item__AG_DarkPustulesRing__gameplay": "Take 5% less damage while extracting | Extract 5% faster",
    "item__AG_DarkPustulesRing__lore": "This ring can be used to magically procure a days worth of sweet, dark liquid.",
    "item__AG_DarkPustulesRing__name": "Dark Pustules Ring",
    "item__AG_DreamWeaversEmbrace__gameplay": "Become invisible for 4 seconds on use | -5% Damage to Players | Extract 10% faster | INCOGNITO: Hides your loot and location messages from the rest of the world",
    "item__AG_DreamWeaversEmbrace__lore": "The stones on this ring change color around areas where the veil is thin.",
    "item__AG_DreamWeaversEmbrace__name": "DreamWeaver's Embrace",
    "item__AG_ForestsHaven__gameplay": "Heals 20HP when used | Extract 10% faster | INCOGNITO: Hides your loot and location messages from the rest of the world | +20% Ethereal",
    "item__AG_ForestsHaven__lore": "To those who wear this ring, seek the grove of trees hidden amongst dreams.",
    "item__AG_ForestsHaven__name": "Forest's Haven",
    "item__AG_MysteriousRing__gameplay": "Adds 30 seconds to your extraction timer on use | +10 Maximum Health | +7% Movement Speed while walking in water",
    "item__AG_MysteriousRing__lore": "???",
    "item__AG_MysteriousRing__name": "Mysterious Ring",
    "item__AG_ObsidianEcho__gameplay": "EXTRACT when used, no matter where you are | +12% Movement Speed | REVIVE with 70% HP when you die (does not stack with other revive items) | -30 Maximum Health | This item is destroyed when fully used",
    "item__AG_ObsidianEcho__lore": "Forged from a shard of Malachi's blade. If you listen closely, you can hear the echo of souls from those he killed",
    "item__AG_ObsidianEcho__name": "Obsidian Echo",
    "item__AG_PepperedCollar__gameplay": "Grants 4 seconds of +25% movement speed when used (1 Use) | -3% Projectile Distance",
    "item__AG_PepperedCollar__lore": "A collar necklace created as a tribute to a famous feline beast.",
    "item__AG_PepperedCollar__name": "Peppered Collar",
    "item__AG_RingOfAnOceanMan__gameplay": "Grants 2 seconds of +20% movement speed when used | Extract 5% faster | +5% Movement Speed while walking in water",
    "item__AG_RingOfAnOceanMan__lore": "It feels as though it is trying to take your hand and lead you to a land only it understands.",
    "item__AG_RingOfAnOceanMan__name": "Ring of an Ocean Man",
    "item__AG_StuddedNecklace__gameplay": "Become invisible for 4 seconds on use (3 Charges) | -3% Movement Speed",
    "item__AG_StuddedNecklace__lore": "I should probably get this insured",
    "item__AG_StuddedNecklace__name": "Studded Necklace",
    "item__AG_TheDarkLordsEmbrace__gameplay": "+30 Maximum Health",
    "item__AG_TheDarkLordsEmbrace__lore": "Cursed by the various atrocities commited by its wielder.",
    "item__AG_TheDarkLordsEmbrace__name": "The Dark Lord's Embrace",
    "item__AG_TimeHerosRing__gameplay": "Adds 45 seconds to your extraction timer on use | +7% Attack Speed | +7% Movement Speed while walking in water | -5% Movement Speed | +9% Nature Defence | +7% Nature Damage",
    "item__AG_TimeHerosRing__lore": "Worn by an elvish boy who could travel through time.",
    "item__AG_TimeHerosRing__name": "Time Hero's Ring",
    "item__AG_VestiasSoothingSignet__gameplay": "Heals 30HP when used | INCOGNITO: Hides your loot and location messages from the rest of the world",
    "item__AG_VestiasSoothingSignet__lore": "A ring that enables the wearer to soothe both injuries and party tensions alike.",
    "item__AG_VestiasSoothingSignet__name": "Vestia's Soothing Signet",
    "item__AG_ViolasDinnerReminder__gameplay": "+8.5% Nature Defence | +8.5% Frost Defence | Take 7% less damage while extracting | Your projectiles travel 5% shorter",
    "item__AG_ViolasDinnerReminder__lore": "A ring that lets everyone around you know when you're hungry.",
    "item__AG_ViolasDinnerReminder__name": "Viola's Dinner Reminder",
    "item__AG_WolfMedallion__gameplay": "Heals 10HP when used | +10% Attack Speed | +10% Leech from all damage you deal to players",
    "item__AG_WolfMedallion__lore": "Attuned to magic this can detect nearby places of power.",
    "item__AG_WolfMedallion__name": "Wolf Medallion",
    "item__CI_AlloyedBend__gameplay": "",
    "item__CI_AlloyedBend__lore": "A ring made from a number of rare metals that have been roughly fused together.",
    "item__CI_AlloyedBend__name": "Alloyed Bend",
    "item__CI_ApposSignet__gameplay": "",
    "item__CI_ApposSignet__lore": "Rumored to belong to a half-dragon, half-human hybrid who had the ability to control and speak to ducks.",
    "item__CI_ApposSignet__name": "Appo's Signet",
    "item__CI_BloodhuntersRing__gameplay": "",
    "item__CI_BloodhuntersRing__lore": "The bloodhunter seeks her kin, and you as well.",
    "item__CI_BloodhuntersRing__name": "Bloodhunter's Ring",
    "item__CI_CelestialEternity__gameplay": "",
    "item__CI_CelestialEternity__lore": "Those who rose up against Malachi were gifted this ring by the gods.",
    "item__CI_CelestialEternity__name": "Celestial Eternity",
    "item__CI_EbonstarBand__gameplay": "",
    "item__CI_EbonstarBand__lore": "This ring holds part of the fallen star that changed everything.",
    "item__CI_EbonstarBand__name": "Ebonstar Band",
    "item__CI_EchoingWhisperNecklace__gameplay": "",
    "item__CI_EchoingWhisperNecklace__lore": "A necklace that captures and echoes the murmurs of lost souls.",
    "item__CI_EchoingWhisperNecklace__name": "Echoing Whisper Necklace",
    "item__CI_FeyEnchantmentLocket__gameplay": "",
    "item__CI_FeyEnchantmentLocket__lore": "A locket infused with enchantments from the Fey realm, granting beguiling allure.",
    "item__CI_FeyEnchantmentLocket__name": "Fey Enchantment Locket",
    "item__CI_FragmentOfFate__gameplay": "",
    "item__CI_FragmentOfFate__lore": "Once whole, now shattered. Once united, now divided. Once eternal, now obsolete.",
    "item__CI_FragmentOfFate__name": "Fragment of Fate",
    "item__CI_LatrosKnavishBand__gameplay": "",
    "item__CI_LatrosKnavishBand__lore": "A ring that only the vilest of thieves with moral certainty would wear.",
    "item__CI_LatrosKnavishBand__name": "Latro's Knavish Band",
    "item__CI_LifesSimpleRing__gameplay": "",
    "item__CI_LifesSimpleRing__lore": "Live simply so others may simply live.",
    "item__CI_LifesSimpleRing__name": "Life's Simple Ring",
    "item__CI_LuminousEnigma__gameplay": "",
    "item__CI_LuminousEnigma__lore": "I hold the key to knowledge vast, providing answers, unsurpassed.",
    "item__CI_LuminousEnigma__name": "Luminous Enigma",
    "item__CI_Ouro__gameplay": "",
    "item__CI_Ouro__lore": "It is reportedly the key to unlocking the knowledge of all living creatures.",
    "item__CI_Ouro__name": "Ouro",
    "item__CI_PeachRing__gameplay": "",
    "item__CI_PeachRing__lore": "It tastes sweet",
    "item__CI_PeachRing__name": "Peach Ring",
    "item__CI_PlayerSoulFragment__gameplay": "A soul harvester would be interested in this.",
    "item__CI_PlayerSoulFragment__lore": "A fragment of a lost adventurers soul.",
    "item__CI_PlayerSoulFragment__name": "Player Soul Fragment",
    "item__CI_ProudlyPinkRing__gameplay": "",
    "item__CI_ProudlyPinkRing__lore": "This ring is a stunning fashion accessory that will definitely drawn the attention of others.",
    "item__CI_ProudlyPinkRing__name": "Proudly Pink Ring",
    "item__CI_RadiantSunstoneBand__gameplay": "",
    "item__CI_RadiantSunstoneBand__lore": "A paradoxical creation, this ring symbolizes a delicate balance between illumination and destruction.",
    "item__CI_RadiantSunstoneBand__name": "Radiant Sunstone Band",
    "item__CI_RingOfDelerium__gameplay": "",
    "item__CI_RingOfDelerium__lore": "If you listen closely you can hear the voices of the past owners of this ring.",
    "item__CI_RingOfDelerium__name": "Ring of Delerium",
    "item__CI_RoyalBrooch__gameplay": "",
    "item__CI_RoyalBrooch__lore": "The symbol of the royal family. Worth a pretty penny.",
    "item__CI_RoyalBrooch__name": "Royal Brooch",
    "item__CI_SuperStar__gameplay": "",
    "item__CI_SuperStar__lore": "The remnant of a tall tale about a Mushroom filled kingdom.",
    "item__CI_SuperStar__name": "Super Star",
    "item__CI_SwampOgresRing__gameplay": "",
    "item__CI_SwampOgresRing__lore": "Once belonged to a green ogre who was very protective of his swamp.",
    "item__CI_SwampOgresRing__name": "Swamp Ogre's Ring",
    "item__CI_WeddingRing__gameplay": "",
    "item__CI_WeddingRing__lore": "This is worth a lot, I should return it to the original owner",
    "item__CI_WeddingRing__name": "Wedding Ring",
    "item__PG_AngelistasPotionPopper__gameplay": "Your health regenerates all the time",
    "item__PG_AngelistasPotionPopper__lore": "A ring you wear when your healer forbids you from drinking healing potions.",
    "item__PG_AngelistasPotionPopper__name": "Angelista's Potion Popper",
    "item__PG_AquamoorsBlessing__gameplay": "Grants 3 seconds of +25% movement speed when used (3 Charges) | Your health regenerates all the time | -20 Maximum Health",
    "item__PG_AquamoorsBlessing__lore": "Said to be blessed by the spirit of the lake before the corruption of the lands.",
    "item__PG_AquamoorsBlessing__name": "Aquamoor's Blessing",
    "item__PG_ArcaneEmber__gameplay": "-6.5% Damage to NPCs | +5.6% Damage to players",
    "item__PG_ArcaneEmber__lore": "A small ember can be seen still glowing in this ring's gem.",
    "item__PG_ArcaneEmber__name": "Arcane Ember",
    "item__PG_ArcaneSigilCollar__gameplay": "Grants 4 seconds of +30% movement speed when used | -8.5% Damage to NPCs | +9.5% Damage to Players",
    "item__PG_ArcaneSigilCollar__lore": "Etched with powerful symbols, it amplifies the wearer's arcane abilities.",
    "item__PG_ArcaneSigilCollar__name": "Arcane Sigil Collar",
    "item__PG_AssassinSignet__gameplay": "Become invisible for 5 seconds on use (2 Charges) | -3% Movement Speed | -3% Projectile Distance",
    "item__PG_AssassinSignet__lore": "A signet held by an infamous assassin who fought with her hair.",
    "item__PG_AssassinSignet__name": "Assassin Signet",
    "item__PG_BellsShiningRing__gameplay": "Adds 60 seconds to your extraction timer on use | -9.5% Damage to players | +15.5% Damage to NPCs | +40 Maximum Health | Your health regenerates all the time",
    "item__PG_BellsShiningRing__lore": "Known to many as the best healer in the realm.",
    "item__PG_BellsShiningRing__name": "Belle's Shining Ring",
    "item__PG_BloodGemRing__gameplay": "REVIVE with 50% HP when you die | -20 Maximum Health",
    "item__PG_BloodGemRing__lore": "Those who were in the temple at the time of the ritual fell into a frenzy of bloodshed. This ring symbolizes desperate times.",
    "item__PG_BloodGemRing__name": "Blood Gem Ring",
    "item__PG_BloodiedVitality__gameplay": "EXTRACT when used, no matter where you are | Your health regenerates all the time | +30 Maximum Health | -8% Player Damage | You take 10% more damage from ALL damage types when extracting | This item is destroyed when fully used",
    "item__PG_BloodiedVitality__lore": "Your connection to reality and life itself feels stronger with this around your body.",
    "item__PG_BloodiedVitality__name": "Bloodied Vitality",
    "item__PG_BloodmoonLoop__gameplay": "Heals 5HP when used (3 Charges) | +5% Bleed Damage",
    "item__PG_BloodmoonLoop__lore": "Linked to lunar cycles. This heightens physical prowess during a blood moon.",
    "item__PG_BloodmoonLoop__name": "Bloodmoon Armor",
    "item__PG_CapeOfPureDarkness__gameplay": "Grants 4 seconds of +25% movement speed when used | +9% Shadow Defence | +9% Toxic Defence | +7% Toxic Damage | Your health drains all the time",
    "item__PG_CapeOfPureDarkness__lore": "No one dared question him out of fear. For the Dark Ruler was not to be questioned.",
    "item__PG_CapeOfPureDarkness__name": "Cape of Pure Darkness",
    "item__PG_CelestialSolaceBand__gameplay": "Heals 7HP when used | INSPECTION: Reveals information about your opponents | +6.5% Shadow Damage",
    "item__PG_CelestialSolaceBand__lore": "Forged by the gods themselves, this ring offers solace amidst the darkness.",
    "item__PG_CelestialSolaceBand__name": "Celestial Solace Band",
    "item__PG_CelestialStarfallPendant__gameplay": "Become invisible for 3 seconds on use | +6.5% Damage to NPCs | +5% Holy Damage",
    "item__PG_CelestialStarfallPendant__lore": "A pendant adorned with a celestial gem, summoning celestial energy.",
    "item__PG_CelestialStarfallPendant__name": "Celestial Starfall Pendant",
    "item__PG_CultistsMask__name": "Cultist's Mask",
    "item__PG_CultistsMask__lore": "Those who fell victim to the corruption saught to hide their appearance as the corruption changed their very being.",
    "item__PG_CultistsMask__gameplay": "Become invisible for 6 seconds on use | INCOGNITO: Hides your loot and location messages from the rest of the world | +15% Attack Speed",
    "item__PG_DepthStrider__name": "Depth Strider",
    "item__PG_DepthStrider__lore": "A weak enchantment clings to this cloak making walking in water easier.",
    "item__PG_DepthStrider__gameplay": "+5% Movement speed while walking in water",
    "item__PG_GoldenGauntlets__name": "Golden Gauntlets",
    "item__PG_GoldenGauntlets__lore": "Fit for looters, these gauntlets seem to help you find extra gold.",
    "item__PG_GoldenGauntlets__gameplay": "+10% gold from chests",
    "item__PG_BeastVanquisherBoots__name": "Beast Vanquisher Boots",
    "item__PG_BeastVanquisherBoots__lore": "Worn by those on the frontlines of battle. These boots enabled the enemy's swift demise.",
    "item__PG_BeastVanquisherBoots__gameplay": "+12% Movement Speed | +45 Maximum Health | +25% NPC Damage | -12% Player Damage | -12% Projectile Distance",
    "item__PG_CloakOfModestApproachability__gameplay": "Your projectiles travel 6% farther | Adds 30 seconds to your extraction timer on use",
    "item__PG_CloakOfModestApproachability__lore": "Others seem drawn to you and are unsure why.",
    "item__PG_CloakOfModestApproachability__name": "Cloak of Modest Approachability",
    "item__PG_CloudedDarkness__gameplay": "+20% Ethereal | +10% Shadow Damage | +13% Shadow Defence",
    "item__PG_CloudedDarkness__lore": "When the time came and the deed was done, a cloud of darkness washed over Malachi and was imbued into this ring.",
    "item__PG_CloudedDarkness__name": "Clouded Darkness",
    "item__PG_CompactedLimeGreenRing__gameplay": "Your projectiles travel 5% farther | +7% Toxic Damage",
    "item__PG_CompactedLimeGreenRing__lore": "Held by the successor to a dragon's throne.",
    "item__PG_CompactedLimeGreenRing__name": "Compacted Lime Green Ring",
    "item__PG_CopperWristband__gameplay": "+6.5% Physical Defence",
    "item__PG_CopperWristband__lore": "A comically large ring that fits better as a wristband... One can only imagine the size of the creature that wore this as a ring.",
    "item__PG_CopperWristband__name": "Copper Wristband",
    "item__PG_CoralReef__gameplay": "+8.5% Movement Speed while walking in water | Extract 10% slower",
    "item__PG_CoralReef__lore": "Created as a gift for the mermaid princess.",
    "item__PG_CoralReef__name": "Coral Reef",
    "item__PG_CorruptedTearRing__gameplay": "Become invisible for 5 seconds on use | +7% Leech from all damage you deal to players | -7% Chaos Defence | +9% Chaos Damage | +9% Lightning Damage",
    "item__PG_CorruptedTearRing__lore": "A single tear was shed before the corruption took hold... \"Wha-what have I become!?\".",
    "item__PG_CorruptedTearRing__name": "Corrupted Tear Ring",
    "item__PG_CursedEternityRing__gameplay": "Grants 5 seconds of +30% movement speed when used | REVIVE with 48% HP when you die (does not stack with other revive items) | +12% Gold from chests | +8.5% leech from all damage dealth to players | Your health drains all the time",
    "item__PG_CursedEternityRing__lore": "A symbol of eternal damnation. It binds to its wearing, cursing them for eternity but giving them great power.",
    "item__PG_CursedEternityRing__name": "Cursed Eternity Ring",
    "item__PG_CursedSignet__gameplay": "+20% Leech from all damage you deal to players",
    "item__PG_CursedSignet__lore": "A malevolent presence emanates from this ring, bringing misfortune to all who wear it.",
    "item__PG_CursedSignet__name": "Cursed Signet",
    "item__PG_DeathwhisperWrap__gameplay": "+13% Damage to NPCs | +13% Chaos Defence | +10% Toxic Damage",
    "item__PG_DeathwhisperWrap__lore": "As the blade plunged into his heart and all become black, a faint whisper was heard: 'It is not yet time for me to claim your soul.'",
    "item__PG_DeathwhisperWrap__name": "Deathwhisper Wrap",
    "item__PG_DreadmorneLoop__gameplay": "Grants 3 seconds of +30% movement speed when used (5 Charges) | -5% Attack Speed | -6.5% Holy Defence | +10% Chaos Damage",
    "item__PG_DreadmorneLoop__lore": "This ring instills fear in the hearts of enemies, inspiring terror and causing dread.",
    "item__PG_DreadmorneLoop__name": "Dreadmorne Loop",
    "item__PG_DreamcasterDrapery__gameplay": "+6.5% Damage to NPCs | +6.5% Chaos Damage",
    "item__PG_DreamcasterDrapery__lore": "Born of nightmares, this vestment is said to manipulate the subsconcious",
    "item__PG_DreamcasterDrapery__name": "Dreamcaster Drapery",
    "item__PG_EbonfireRobelet__gameplay": "Your projectiles travel 6% further",
    "item__PG_EbonfireRobelet__lore": "Engulfs its wearer in an aura of smoldering shadows, granting protection against elemental harm.",
    "item__PG_EbonfireRobelet__name": "Ebonfire Robelet",
    "item__PG_ElvenGildedGuardian__gameplay": "Extract 5% faster",
    "item__PG_ElvenGildedGuardian__lore": "A beautifully-crafted, golden ivy-adorned belt that enhances the wearer's nimbleness and repels adversaries with elven finesse.",
    "item__PG_ElvenGildedGuardian__name": "Elven Gilded Guardian",
    "item__PG_EmbergraspRing__gameplay": "+10 Maximum Health",
    "item__PG_EmbergraspRing__lore": "Flames dance and flicker upon your touch, scorching your enemies.",
    "item__PG_EmbergraspRing__name": "Embergrasp Ring",
    "item__PG_EnchantedElixirNecklace__gameplay": "Adds 60 seconds to your extraction timer on use | REVIVE with 60% HP when you die (does not stack with other revive items)",
    "item__PG_EnchantedElixirNecklace__lore": "A necklace containing a vial of enchanted elixir, enhancing magical potency.",
    "item__PG_EnchantedElixirNecklace__name": "Enchanted Elixir Necklace",
    "item__PG_EnigmaLoop__gameplay": "INCOGNITO: Hides your loot and location messages from the rest of the world | +5% Chaos Damage",
    "item__PG_EnigmaLoop__lore": "An enigmatic ring that confounds foes, twisting reality in your favor.",
    "item__PG_EnigmaLoop__name": "Enigma Loop",
    "item__PG_EnigmaticShadowweaveRing__gameplay": "Heals 20HP on use (1 Charge) | +5.5% Damage to Players | -9% Damage to NPCs",
    "item__PG_EnigmaticShadowweaveRing__lore": "Infused with enigmatic enchantments, this ring weaves together threads of darkness.",
    "item__PG_EnigmaticShadowweaveRing__name": "Enigmatic Shadowweave Ring",
    "item__PG_EssenceConduit__gameplay": "+9% Damage to NPCs | -4% Damage to players",
    "item__PG_EssenceConduit__lore": "Grants power to those who tend to nature.",
    "item__PG_EssenceConduit__name": "Essence Conduit",
    "item__PG_EverlastingSpiritCircle__gameplay": "Grants 3 seconds of +25% movement speed when used (2 Charges) | +10% Gold from chests",
    "item__PG_EverlastingSpiritCircle__lore": "Crafted by ancient sorcery, this ring acts as a conduit for spiritual energy.",
    "item__PG_EverlastingSpiritCircle__name": "Everlasting Spirit Circle",
    "item__PG_FrostFireRing__gameplay": "+7% Fire Damage | +8.5% Frost Defense | -6.5% Fire Defense",
    "item__PG_FrostFireRing__lore": "A ring inset with elemental stones, that can imbue attacks with additional power -\"Cause you're hot then you're cold\"",
    "item__PG_FrostFireRing__name": "Frostfire Ring",
    "item__PG_FrostbiteLoop__gameplay": "+6% Movement Speed",
    "item__PG_FrostbiteLoop__lore": "A chilling aura surrounds this ring, freezing the hearts of foes with a touch.",
    "item__PG_FrostbiteLoop__name": "Frostbite Loop",
    "item__PG_FrostfireShardPendant__gameplay": "Take 10% less damage while extracting | +8% Damage to Players",
    "item__PG_FrostfireShardPendant__lore": "A pendant housing a shard infused with both icy and fiery energies.",
    "item__PG_FrostfireShardPendant__name": "Frostfire Shard Pendant",
    "item__PG_GineshsBottleOpener__gameplay": "Your health drains all the time | +12% Damage to ALL damage types | +8.5% Movement Speed | Your projectiles travel 8.5% farther",
    "item__PG_GineshsBottleOpener__lore": "A ring that unlocks both the cause of, and solution to, all of life's problems.",
    "item__PG_GineshsBottleOpener__name": "Ginesh's Bottle Opener",
    "item__PG_GirdleOfTheGoldenNecromancer__gameplay": "Heals 10HP when used | +6% Movement Speed",
    "item__PG_GirdleOfTheGoldenNecromancer__lore": "An opulent, gold-encrusted belt that confers a gilded aura of necromantic protection, warding off injury.",
    "item__PG_GirdleOfTheGoldenNecromancer__name": "Girdle of the Golden Necromancer",
    "item__PG_GoldPlatedCape__gameplay": "+4% Damage to players",
    "item__PG_GoldPlatedCape__lore": "This somehow counts as armor",
    "item__PG_GoldPlatedCape__name": "Gold Plated Cape",
    "item__PG_GolemsGrasp__gameplay": "+30 Maximum Health | -6% Movement Speed | -6% Movement Speed while walking in water",
    "item__PG_GolemsGrasp__lore": "The power of stone surges through this ring, turning your skin into armor.",
    "item__PG_GolemsGrasp__name": "Golem's Grasp",
    "item__PG_GuardiansChainmailCollar__gameplay": "Heals 15HP when used ( 3 Uses)| -5% Attack Speed",
    "item__PG_GuardiansChainmailCollar__lore": "A sturdy collar of interlocking chainmail, providing stalwart protection.",
    "item__PG_GuardiansChainmailCollar__name": "Guardian's Chainmail Collar",
    "item__PG_HeirloomCloak__gameplay": "Grants 2 seconds of +20% movement speed on use (3 Uses).",
    "item__PG_HeirloomCloak__lore": "A simple cloak passed down through your family for generations.",
    "item__PG_HeirloomCloak__name": "Heirloom Cloak",
    "item__PG_IceQueensEarrings__gameplay": "Grants 3 seconds of +20% movement speed on use | +4% Damage to players | +6.5% Frost Defence | +5% Frost Damage",
    "item__PG_IceQueensEarrings__lore": "Earrings befitting of the Queen of Ice herself. Cold to the touch.",
    "item__PG_IceQueensEarrings__name": "Ice Queen's Earrings",
    "item__PG_InfiniteLoop__gameplay": "Adds 45 seconds to your extraction timer on use | -10 Maximum Health | Your health regenerates all the time",
    "item__PG_InfiniteLoop__lore": "Able to convert life energy to mana, and mana into life energy.",
    "item__PG_InfiniteLoop__name": "Infinite Loop",
    "item__PG_IronFoxLocket__gameplay": "INSPECTION: Reveals information about your opponents | +13% Defence to ALL damage types | +30 Maximum Health",
    "item__PG_IronFoxLocket__lore": "Legends speak of a fox, donning iron armor, renowned for outwitting even the cleverest hunters.",
    "item__PG_IronFoxLocket__name": "Iron Fox Locket",
    "item__PG_JaysesRing__gameplay": "+9.5% Damage to players | -9% Damage to NPCs | +15% Ethereal | +12% Lightning Damage | +12% Fire Damage",
    "item__PG_JaysesRing__lore": "The ring of the famous mage Jayse.",
    "item__PG_JaysesRing__name": "Jayse's Ring",
    "item__PG_LittleCompactedLimeGreenRing__gameplay": "Your projectiles travel 6% further | +13% Toxic Defence",
    "item__PG_LittleCompactedLimeGreenRing__lore": "Once belonged to a luxourious dragon kin.",
    "item__PG_LittleCompactedLimeGreenRing__name": "Little Compacted Lime Green Ring",
    "item__PG_MementoMoriLocket__gameplay": "-6.5% Chaos Defence | +8.5% Shadow Defence",
    "item__PG_MementoMoriLocket__lore": "A haunting locket that serves as a reminder of mortality, strengthening resolve.",
    "item__PG_MementoMoriLocket__name": "Memento Mori Locket",
    "item__PG_MidnightsVeilBand__gameplay": "Become Invisible for 5 seconds on use (2 Charges)",
    "item__PG_MidnightsVeilBand__lore": "Worn with strands of shadow, this cloak enhances its wearer stealth.",
    "item__PG_MidnightsVeilBand__name": "Midnight's Veil",
    "item__PG_MunchkinsRing__gameplay": "+7% Movement Speed | -10 Max Health",
    "item__PG_MunchkinsRing__lore": "Its previous owner had stubby little legs but they never slowed him down.",
    "item__PG_MunchkinsRing__name": "Munchkin's Ring",
    "item__PG_NaturesAureateBarrier__gameplay": "Adds 30 seconds to your extraction timer on use (2 Uses) | -5.5% Damage to Players | INCOGNITO: Hides your loot and location messages from the rest of the world",
    "item__PG_NaturesAureateBarrier__lore": "A robust, gold-woven belt infused with the fortitude of ancient trees, granting the wearer a shield of gleaming natural force.",
    "item__PG_NaturesAureateBarrier__name": "Nature's Aureate Barrier",
    "item__PG_NecroticGraspTalisman__gameplay": "Grants 5 seconds of +30% movement speed when used | Your health drains all the time | +12% Shadow Damage",
    "item__PG_NecroticGraspTalisman__lore": "A talisman pulsating with necromantic power, commanding the forces of the undead.",
    "item__PG_NecroticGraspTalisman__name": "Necrotic Grasp Talisman",
    "item__PG_NecroticSignet__gameplay": "Adds 45 seconds to your extraction timer on use | +13% Holy Defence",
    "item__PG_NecroticSignet__lore": "A macabre symbol of death and decay, it drains vitality from your foes.",
    "item__PG_NecroticSignet__name": "Necrotic Gambeson",
    "item__PG_PendantOfAncientWisdom__gameplay": "-7% Damage to Players | +8.75% Damage to NPCs | +8.5% Arcane Defence | +7% Arcane Damage",
    "item__PG_PendantOfAncientWisdom__lore": "An age-old pendant that imparts profound knowledge to the one who wears it.",
    "item__PG_PendantOfAncientWisdom__name": "Pendant of Ancient Wisdom",
    "item__PG_PhantomBand__gameplay": "+30% Ethereal",
    "item__PG_PhantomBand__lore": "Wearing this ethereal ring allows you to walk unseen among the living.",
    "item__PG_PhantomBand__name": "Phantom Band",
    "item__PG_PhoenixFeatherTorque__gameplay": "REVIVE with 60% HP when you die (does not stack with other revive items) | +60 Maximum Health | +19.5% Fire Defence | +19.5% Frost Defence | -19.5% Toxic Defence | -15% Attack Speed",
    "item__PG_PhoenixFeatherTorque__lore": "A majestic torque adorned with a phoenix feather, granting rebirth and renewal.",
    "item__PG_PhoenixFeatherTorque__name": "Phoenix Feather Torque",
    "item__PG_PhoenixsRespite__gameplay": "Become invisible for 3 seconds on use | +6.5% Fire Defence | +5% Fire Damage",
    "item__PG_PhoenixsRespite__lore": "They who willingly threw themselves upon the flame were reborn with greater power than before.",
    "item__PG_PhoenixsRespite__name": "Phoenix's Respite",
    "item__PG_PurpletratorsRing__gameplay": "+3% Leech from all damage you deal to players",
    "item__PG_PurpletratorsRing__lore": "Those who dared laugh at this ring's name were often killed by its wearer.",
    "item__PG_PurpletratorsRing__name": "Purpletrator's Ring",
    "item__PG_QuintsFishingGarb__gameplay": "+5% Attack Speed",
    "item__PG_QuintsFishingGarb__lore": "A ring that creates an endless amount of fishing grubs, often distributed unwittingly to party members.",
    "item__PG_QuintsFishingGarb__name": "Quint's Fishing Garb ",
    "item__PG_ResilientHeartstone__gameplay": "Heals 10HP when used | +5% Gold from chests | Take 5% less damage while extracting | INSPECTION: Reveals information about your opponents",
    "item__PG_ResilientHeartstone__lore": "A heart-shaped gem that instills unwavering fortitude in the wearer.",
    "item__PG_ResilientHeartstone__name": "Resilient Heartstone",
    "item__PG_RingOfConstellations__gameplay": "Your projectiles travel 6% farther",
    "item__PG_RingOfConstellations__lore": "Rumor has it a warrior of the night owned this and it provided guidance under the dark of night.",
    "item__PG_RingOfConstellations__name": "Ring of Constellations",
    "item__PG_RingOfTheOneHandedWarrior__gameplay": "-10 Maximum Health | +12% Physical Damage | +12% Bleed Damage",
    "item__PG_RingOfTheOneHandedWarrior__lore": "You only need one hand to wear a ring.",
    "item__PG_RingOfTheOneHandedWarrior__name": "Ring of the One Handed Warrior",
    "item__PG_RingOfViolentViolet__gameplay": "+30 Maximum Health | +17% Damage to ALL damage types | -15% Defence to ALL damage types",
    "item__PG_RingOfViolentViolet__lore": "You feel a sudden surge of power and bloodlust as you equip this ring.",
    "item__PG_RingOfViolentViolet__name": "Ring of Violent Violet",
    "item__PG_RuneboundRing__gameplay": "+5% Movement Speed while walking in water | +7% Arcane Damage",
    "item__PG_RuneboundRing__lore": "Etched with ancient runes, it channels arcane energies to empower the wearer.",
    "item__PG_RuneboundRing__name": "Runebound Ring",
    "item__PG_SerpentsCoilChoker__gameplay": "INSPECTION: Reveals information about your opponents",
    "item__PG_SerpentsCoilChoker__lore": "Coiling around the neck, it imbues the wearer with serpentine grace and cunning.",
    "item__PG_SerpentsCoilChoker__name": "Serpent's Coil Choker",
    "item__PG_SerpentsRing__gameplay": "+6.5% Toxic Resistance",
    "item__PG_SerpentsRing__lore": "A ring made from the scales of a serpent, enchanged to protect the wearer from poison.",
    "item__PG_SerpentsRing__name": "Serpent's Ring",
    "item__PG_ShieldbearersAmulet__gameplay": "Heals 10HP when used (1 Charge) | +6.5% damage to NPCs",
    "item__PG_ShieldbearersAmulet__lore": "Emblazoned with a heraldic crest, it bolsters the wearer's defenses.",
    "item__PG_ShieldbearersAmulet__name": "Shieldbearer's Amulet",
    "item__PG_SoulBindingRing__gameplay": "REVIVE with 40% HP when you die | +10% Holy Damage",
    "item__PG_SoulBindingRing__lore": "This ring has three stones. One is clear and see through. The other two contain an odd, golden swirling mist.",
    "item__PG_SoulBindingRing__name": "Soul Binding Ring",
    "item__PG_SoulforgeRing__gameplay": "EXTRACT when used, no matter where you are | Extract 10% slower | This item is destroyed when fully used",
    "item__PG_SoulforgeRing__lore": "Infused with the essence of tormented souls, this ring increases your physical and mystical prowess.",
    "item__PG_SoulforgeRing__name": "Soulforge Ring",
    "item__PG_SoulshackleVeil__gameplay": "Grants 3 seconds of +25% movement speed when used (3 Charges) | +8.5% Ethereal | -5% Movement Speed",
    "item__PG_SoulshackleVeil__lore": "Bound by dark magic, it ensnares the souls of the fallen, connecting you to the spirit world.",
    "item__PG_SoulshackleVeil__name": "Soulshackle Veil",
    "item__PG_SoulstoneReliquary__gameplay": "Become invisible for 4 seconds on use | Take 5% less damage while extracting | REVIVE with 20% HP when you die (does not stack with other revive items) | +5% Leech from all damage you deal to players",
    "item__PG_SoulstoneReliquary__lore": "A relic necklace that allows the wearer to harness and control captured souls.",
    "item__PG_SoulstoneReliquary__name": "Soulstone Reliquary",
    "item__PG_StormweaverCloak__gameplay": "INCOGNITO: Hides your loot and location messages from the rest of the world",
    "item__PG_StormweaverCloak__lore": "Harness the fury of thunderstorms, commanding the wrath of the skies.",
    "item__PG_StormweaverCloak__name": "Stormweaver Loop",
    "item__PG_SummonersSign__gameplay": "+10 Maximum Health",
    "item__PG_SummonersSign__lore": "Used by summoners to gather mana from the land.",
    "item__PG_SummonersSign__name": "Summoner's Sign",
    "item__PG_TalismanOfVeiledShadows__gameplay": "Become invisible for 3 seconds on use | -5% Movement Speed | +8.5% Chaos Defence | +8.5% Shadow Defence",
    "item__PG_TalismanOfVeiledShadows__lore": "A mystical talisman that cloaks the wearer in ephemeral darkness.",
    "item__PG_TalismanOfVeiledShadows__name": "Talisman of Veiled Shadows",
    "item__PG_TantrumsLuckyLoop__gameplay": "+3% Movement Speed",
    "item__PG_TantrumsLuckyLoop__lore": "A ring that makes you as fast as a greyhound, and just as lazy.",
    "item__PG_TantrumsLuckyLoop__name": "Tantrum's Lucky Loop",
    "item__PG_VampiricSeal__gameplay": "Heals 7HP when used (2 Charges) | +3% Leech from all damage you deal to players",
    "item__PG_VampiricSeal__lore": "Feeding on the life force of your enemies, it grants vitality with every strike.",
    "item__PG_VampiricSeal__name": "Vampiric Vestement",
    "item__PG_VeilstormCirclet__gameplay": "Heals 30HP when used | -10% Attack Speed | +12% Nature Damage",
    "item__PG_VeilstormCirclet__lore": "Holds the power of a raging tempest. Grants its wearer control over wind and lightning.",
    "item__PG_VeilstormCirclet__name": "Veilstorm Cape",
    "item__PG_VenomousBite__gameplay": "Adds 30 seconds to your extraction timer on use | Your health drains all the time | +10% Toxic Damage",
    "item__PG_VenomousBite__lore": "Armor worn by ancient plague doctors",
    "item__PG_VenomousBite__name": "Venomous Bite",
    "item__PG_WindspeakersRing__gameplay": "INSPECTION: Reveals information about your opponents | +7% Nature Damage",
    "item__PG_WindspeakersRing__lore": "Once owned by a powerful mage who was pursued around the world by his own shadow, this ring is said to have the power to control the winds.",
    "item__PG_WindspeakersRing__name": "Windspeaker's Ring",
    "item__PG_Wraithband__gameplay": "+8.5% Ethereal | 7% Holy Damage | -10 Maximum Health",
    "item__PG_Wraithband__lore": "Phantasmal energy courses through this ring, granting spectral powers.",
    "item__PG_Wraithband__name": "Wraithband",
    "item__VT_AbyssalShard__gameplay": "",
    "item__VT_AbyssalShard__lore": "A black chunk of stone gathered from the underworld.",
    "item__VT_AbyssalShard__name": "Abyssal Shard",
    "item__VT_Amber__gameplay": "",
    "item__VT_Amber__lore": "This stone has a deep orange tinge to it.",
    "item__VT_Amber__name": "Amber",
    "item__VT_BardsDiary__gameplay": "",
    "item__VT_BardsDiary__lore": "This song is scribbled with poetry and musical scores.",
    "item__VT_BardsDiary__name": "Bard's Songbook",
    "item__VT_BrokenIronBand__gameplay": "",
    "item__VT_BrokenIronBand__lore": "A simple iron ring, that has lost its lusture.",
    "item__VT_BrokenIronBand__name": "Broken Iron Band",
    "item__VT_BrokenSword__gameplay": "",
    "item__VT_BrokenSword__lore": "This sword has seen better days.",
    "item__VT_BrokenSword__name": "Broken Iron Sword",
    "item__VT_Cabbage__gameplay": "",
    "item__VT_Cabbage__lore": "You're cabbage, you'll always be cabbage.",
    "item__VT_Cabbage__name": "Cabbage",
    "item__VT_ChunkOfIron__gameplay": "",
    "item__VT_ChunkOfIron__lore": "A small chunk of iron, a blacksmith might be able to make something with this.",
    "item__VT_ChunkOfIron__name": "Chunk of Iron",
    "item__VT_CubicZirconia__gameplay": "",
    "item__VT_CubicZirconia__lore": "A square chunk of a Zirconia",
    "item__VT_CubicZirconia__name": "Cubic Zirconia",
    "item__VT_Diamond__gameplay": "A rare goods collector would probably be interested in this.",
    "item__VT_Diamond__lore": "A precious gemstone.",
    "item__VT_Diamond__name": "Diamond",
    "item__VT_DukesDiary__gameplay": "",
    "item__VT_DukesDiary__lore": "The musings of a once powerful Duke.",
    "item__VT_DukesDiary__name": "Duke of Ert's Diary",
    "item__VT_EmeraldDust__gameplay": "",
    "item__VT_EmeraldDust__lore": "An emerald that has been turned into a fine powder",
    "item__VT_EmeraldDust__name": "Emerald Dust",
    "item__VT_Emerald__gameplay": "",
    "item__VT_Emerald__lore": "A chunk of an Emerald.",
    "item__VT_Emerald__name": "Emerald",
    "item__VT_EverlastingCandle__gameplay": "",
    "item__VT_EverlastingCandle__lore": "This candle mysteriously will burn forever.",
    "item__VT_EverlastingCandle__name": "Everlasting Candle",
    "item__VT_Eyeball__gameplay": "",
    "item__VT_Eyeball__lore": "The eye of a slain beast.",
    "item__VT_Eyeball__name": "Eyeball",
    "item__VT_FangOfTheBloodMoon__gameplay": "",
    "item__VT_FangOfTheBloodMoon__lore": "This blood covered fang is from a creature that prowls only when the sky is bleeding.",
    "item__VT_FangOfTheBloodMoon__name": "Fang of the Blood Moon",
    "item__VT_Fossil__gameplay": "",
    "item__VT_Fossil__lore": "The remains of an ancient being is permanently imprinted on this rock.",
    "item__VT_Fossil__name": "Fossil",
    "item__VT_GhostPlasma__gameplay": "",
    "item__VT_GhostPlasma__lore": "This viscous liquid glows with etheral energies.",
    "item__VT_GhostPlasma__name": "Ghost Plasma",
    "item__VT_GoblinCloth__gameplay": "",
    "item__VT_GoblinCloth__lore": "This cloth is very dirty and has some blood on it.",
    "item__VT_GoblinCloth__name": "Goblin's Loin Cloth",
    "item__VT_GoldNugget__gameplay": "",
    "item__VT_GoldNugget__lore": "A small chunk of gold. Probably worth a lot.",
    "item__VT_GoldNugget__name": "Gold Nugget",
    "item__VT_OgreEntrails__gameplay": "",
    "item__VT_OgreEntrails__lore": "Entrails from the insides of an Ogre.",
    "item__VT_OgreEntrails__name": "Ogre Entrails",
    "item__VT_OnyxGem__gameplay": "A jewel expert might be interested in this.",
    "item__VT_OnyxGem__lore": "A rare gem made of onyx.",
    "item__VT_OnyxGem__name": "Onyx Gem",
    "item__VT_PileOfAsh__gameplay": "",
    "item__VT_PileOfAsh__lore": "Just a small pile of ashes.",
    "item__VT_PileOfAsh__name": "Pile of Ashes",
    "item__VT_Potato__gameplay": "Boil 'em, Mash'em, Stick 'em in a stew.",
    "item__VT_Potato__lore": "You've probably never heard of them.",
    "item__VT_Potato__name": "Potato",
    "item__VT_QuartzRing__gameplay": "",
    "item__VT_QuartzRing__lore": "Inlaid with a chunck of polished quartz. Not worth much but at least it's something.",
    "item__VT_QuartzRing__name": "Quartz Ring",
    "item__VT_RatFur__gameplay": "",
    "item__VT_RatFur__lore": "A gross clump of hair, probably ridden with diseases.",
    "item__VT_RatFur__name": "Clump of Rat Fur",
    "item__VT_RedMushroom__gameplay": "",
    "item__VT_RedMushroom__lore": "This mushroom is commonly dried and turned into a powder used for helping illness.",
    "item__VT_RedMushroom__name": "Red Mushroom",
    "item__VT_RingOfNearPerfection__gameplay": "",
    "item__VT_RingOfNearPerfection__lore": "It'd be better if it had magic.",
    "item__VT_RingOfNearPerfection__name": "Ring of Near Perfection",
    "item__VT_RubyDust__gameplay": "",
    "item__VT_RubyDust__lore": "A ruby that has been crushed into a fine powder.",
    "item__VT_RubyDust__name": "Ruby Dust",
    "item__VT_Ruby__gameplay": "",
    "item__VT_Ruby__lore": "A chunk of a deep red ruby.",
    "item__VT_Ruby__name": "Ruby",
    "item__VT_SapphireDust__gameplay": "",
    "item__VT_SapphireDust__lore": "A sapphire that has been turned into a fine powder",
    "item__VT_SapphireDust__name": "Sapphire Dust",
    "item__VT_Sapphire__gameplay": "",
    "item__VT_Sapphire__lore": "A deep blue chunk of a sapphire",
    "item__VT_Sapphire__name": "Sapphire",
    "item__VT_SkeletonFemur__gameplay": "",
    "item__VT_SkeletonFemur__lore": "I guess there is a skeleton with one leg somewhere.",
    "item__VT_SkeletonFemur__name": "Skeleton's Femur",
    "item__VT_Skull__gameplay": "",
    "item__VT_Skull__lore": "A skull. Probably from ones of these roaming skeletons.",
    "item__VT_Skull__name": "Skull",
    "item__VT_StellarWeaveRing__gameplay": "",
    "item__VT_StellarWeaveRing__lore": "A ring made of woven branches. It's unremarkable.",
    "item__VT_StellarWeaveRing__name": "Stellar Weave Ring",
    "item__VT_TreantBranch__gameplay": "",
    "item__VT_TreantBranch__lore": "This piece of wood is wriggling a little bit. Weird",
    "item__VT_TreantBranch__name": "Treant Branch",
    "item__VT_WellWornRing__gameplay": "",
    "item__VT_WellWornRing__lore": "This simple ring has a number of scuffs and scratches visible on its surface.",
    "item__VT_WellWornRing__name": "Well Worn Ring",
    "item__VT_WhispersOfTheVoid__gameplay": "",
    "item__VT_WhispersOfTheVoid__lore": "Madness is common amongst those who hear his voice. And who doesn't in this time of crisis?",
    "item__VT_WhispersOfTheVoid__name": "Whispers of the Void Vol. 1",
    "item__W_ApprenticesStaff__gameplay": "Places a visible trap near your feet that deals physical damage.",
    "item__W_ApprenticesStaff__lore": "A novice wizards staff commonly used for training.",
    "item__W_ApprenticesStaff__name": "Apprentice's Staff",
    "item__W_ArchmagesSidekick__gameplay": "Places a visible trap near your feet that deals arcane damage.",
    "item__W_ArchmagesSidekick__lore": "Little is known about this staff, other than it was transmuted into existence by the Archmage of the Arcane Order.",
    "item__W_ArchmagesSidekick__name": "Archmage's Sidekick",
    "item__W_BeesOnAStick__gameplay": "Places a visible trap near your feet that deals nature damage.",
    "item__W_BeesOnAStick__lore": "A staff adorned with a beehive, buzzing with the power of swarming bees, delivering stinging spells to adversaries.",
    "item__W_BeesOnAStick__name": "Bee's On A Stick",
    "item__W_BlackDahliaWand__gameplay": "Fires shadow projectiles.",
    "item__W_BlackDahliaWand__lore": "Its dark nature corrupts the hearts of those who dare to wield it.",
    "item__W_BlackDahliaWand__name": "Black Dahlia Wand",
    "item__W_BlazeRod__gameplay": "Fires blazes!",
    "item__W_BlazeRod__lore": "Found in the depths of the nether, this rod is imbued with the soul of a Blaze.",
    "item__W_BlazeRod__name": "Blaze Rod",
    "item__W_Bloodrider__gameplay": "Shoots arrows that cause heavy bleeding damage.",
    "item__W_Bloodrider__lore": "Fashioned from fallen warriors' bones, this malevolent bow drains life with each arrow, leaving behind a trail of crimson despair.",
    "item__W_Bloodrider__name": "Bloodrider",
    "item__W_BrokenDeathstick__gameplay": "Fires chaos bolts that deal moderate damage.",
    "item__W_BrokenDeathstick__lore": "It would be deadly... if it wasn't broken.",
    "item__W_BrokenDeathstick__name": "Broken Death Stick",
    "item__W_CandlelightStaff__gameplay": "Places a visible trap near your feet that deals fire damage.",
    "item__W_CandlelightStaff__lore": "A staff that emits a soft glow, illuminating the darkness.",
    "item__W_CandlelightStaff__name": "Candlelight Staff",
    "item__W_CelestialBow__gameplay": "A celestial emissary has infused these arrows with the cold of the void.",
    "item__W_CelestialBow__lore": "Gifted by ancient deities, this radiant bow channels divine blessings, piercing even the most formidable defenses.",
    "item__W_CelestialBow__name": "Celestial Bow",
    "item__W_CrudeCrossbow__gameplay": "Fires a basic low damage bolt that deals physical damage.",
    "item__W_CrudeCrossbow__lore": "A crude crossbow crafted from wood scraps and twine. It is not very powerful, but it will do.",
    "item__W_CrudeCrossbow__name": "Crude Crossbow",
    "item__W_DarylsInstinct__gameplay": "Fires powerful bolts that inflict bleed damage.",
    "item__W_DarylsInstinct__lore": "A crossbow used by the legendary hunter Daryl.",
    "item__W_DarylsInstinct__name": "Daryl's Instinct",
    "item__W_DavidBowie__gameplay": "Shoots weak physical projectiles.",
    "item__W_DavidBowie__lore": "Transforms you into an instant star!",
    "item__W_DavidBowie__name": "David Bowie",
    "item__W_DemonsEye__gameplay": "Places a visible trap near your feet that deals shadow damage.",
    "item__W_DemonsEye__lore": "this staff invokes the ominous whispers of the nether realm, allowing wielders to pierce through illusions and reveal the hidden truths that lie in the shadows.",
    "item__W_DemonsEye__name": "Demon's Eye",
    "item__W_DivineWind__gameplay": "Shoots powerful holy arrows.",
    "item__W_DivineWind__lore": "Forged in sacred mountain sanctuaries, this ethereal bow propels arrows with otherworldly gusts, tearing through obstacles and foes.",
    "item__W_DivineWind__name": "Divine Wind",
    "item__W_DragonsBreath__gameplay": "Creates a torrent of flames in front of the caster.",
    "item__W_DragonsBreath__lore": "I cast... Fireball?",
    "item__W_DragonsBreath__name": "Dragon's Breath",
    "item__W_EldritchBlasters__gameplay": "Rapidly shoots shadow bolts.",
    "item__W_EldritchBlasters__lore": "Dual crossbows imbued with the power of shadow.",
    "item__W_EldritchBlasters__name": "Eldritch Blasters",
    "item__W_Emberstrike__gameplay": "Shoots powerful fire arrows.",
    "item__W_Emberstrike__lore": "Forged in the heart of a volcano, this obsidian bow unleashes scorching arrows, leaving behind trails of smoldering devastation.",
    "item__W_Emberstrike__name": "Emberstrike",
    "item__W_FairyBow__gameplay": "Shoots arrows infused with fairy magic.",
    "item__W_FairyBow__lore": "Infuses the arrows fired from this bow with random powerful energy",
    "item__W_FairyBow__name": "Fairy Bow",
    "item__W_FairyStaff__gameplay": "Places a visible trap near your feet that deals nature damage.",
    "item__W_FairyStaff__lore": "Belonged to a fairy queen.",
    "item__W_FairyStaff__name": "Fairy Staff",
    "item__W_FathersBane__gameplay": "Rapidly shoots weak, poison-tipped bolts.",
    "item__W_FathersBane__lore": "Used by a mournful son who had no choice but to slay his father.",
    "item__W_FathersBane__name": "Father's Bane",
    "item__W_FlamespitterStaff__gameplay": "Places a visible trap near your feet that deals fire damage.",
    "item__W_FlamespitterStaff__lore": "Furious flames burst forth from this staff.",
    "item__W_FlamespitterStaff__name": "Flamespitter Staff",
    "item__W_FrostedTips__gameplay": "Deals frost damage.",
    "item__W_FrostedTips__lore": "Holy moly, Stromboli!",
    "item__W_FrostedTips__name": "Frosted Tips",
    "item__W_GuardsCrossbow__gameplay": "Easier and faster to load than a normal crossbow.",
    "item__W_GuardsCrossbow__lore": "Crossbow used by the city guards of the Arcane Order.",
    "item__W_GuardsCrossbow__name": "Guards Crossbow",
    "item__W_HibidyKibity__gameplay": "Shoots deadly chaos projectiles",
    "item__W_HibidyKibity__lore": "A wand embedded with the fel energy of the killing curse.",
    "item__W_HibidyKibity__name": "Hibidy Kibity",
    "item__W_IcewindWand__gameplay": "Create a torrent of freezing clouds in front of the caster.",
    "item__W_IcewindWand__lore": "Is able to create the absolute worst weather.",
    "item__W_IcewindWand__name": "Ice Wind Wand",
    "item__W_IronStaff__gameplay": "Places a visible trap near your feet that deals physical damage.",
    "item__W_IronStaff__lore": "A staff made of iron, used by the guards of the Arcane Order.",
    "item__W_IronStaff__name": "Iron Staff",
    "item__W_KingBible__gameplay": "Launch powerful holy bibles at your opponents.",
    "item__W_KingBible__lore": "A revered, gilded tome holding sacred words of ancient monarchs, granting authority and shaping destinies.",
    "item__W_KingBible__name": "King Bible",
    "item__W_Deathstick__gameplay": "Brings death by shooting rapid short ranged projectiles, at the cost of slowing the user.",
    "item__W_Deathstick__lore": "Legend has it that this powerful weapon was dropped by a mysterious figure centuries ago.",
    "item__W_Deathstick__name": "Deathstick",
    "item__W_BookOfTongues__gameplay": "Shoots the strongest fire from the depths of hell. Has a VERY long cooldown",
    "item__W_BookOfTongues__lore": "An illegible name can be seen etched upon its pages. All you can make out is the letter \"M\".",
    "item__W_BookOfTongues__name": "Book of Tongues",
    "item__W_LichsStaff__gameplay": "Places a visible trap near your feet that deals shadow damage.",
    "item__W_LichsStaff__lore": "Wielded by dark necromancers, this staff harnesses the unholy powers of the undead, bringing death and decay to all it touches.",
    "item__W_LichsStaff__name": "Lich's Staff",
    "item__W_FrostFlicker__gameplay": "Places a visible trap near your feet that deals frost damage.",
    "item__W_FrostFlicker__lore": "A staff with the ability to freeze water in the surrounding area. A weaker version of this is often used during a holiday season.",
    "item__W_FrostFlicker__name": "Frost Flicker",
    "item__W_MagicalWand__gameplay": "Fires magical arcane bolts.",
    "item__W_MagicalWand__lore": "In the name of the moon, I'll punish you!",
    "item__W_MagicalWand__name": "Magical Wand",
    "item__W_MidasStaff__gameplay": "Places a visible trap near your feet that deals lightning damage.",
    "item__W_MidasStaff__lore": "The staff of the legendary King Midas.",
    "item__W_MidasStaff__name": "Midas Staff",
    "item__W_MoonShadow__gameplay": "Rapidly shoots shadow arrows.",
    "item__W_MoonShadow__lore": "Bestowed by moon spirits, this mystical bow shoots arrows that travel through shadows, striking from unexpected angles.",
    "item__W_MoonShadow__name": "Moon Shadow",
    "item__W_NaturesReturn__gameplay": "Harness the power of the forest to deal large amounts of nature damage to your opponents.",
    "item__W_NaturesReturn__lore": "A tome adorned with foliage, channeling the forces of nature for growth, healing, and storms.",
    "item__W_NaturesReturn__name": "Nature's Return",
    "item__W_NightStick__gameplay": "Fires projectiles colder than the night.",
    "item__W_NightStick__lore": "Used by those who intend to keep the peace.",
    "item__W_NightStick__name": "Night Stick",
    "item__W_PharaohsStaff__gameplay": "Places a visible trap near your feet that deals holy damage.",
    "item__W_PharaohsStaff__lore": "Excavated from the tomb of an ancient Pharaoh, this staff is said to have been used to summon the dead.",
    "item__W_PharaohsStaff__name": "Pharaoh's Staff",
    "item__W_PhoenixWing__gameplay": "Shoots arrows infused with the flames of a fallen phoenix.",
    "item__W_PhoenixWing__lore": "Crafted from phoenix feathers, this radiant bow channels fiery rebirth, igniting arrows with searing flames.",
    "item__W_PhoenixWing__name": "Phoenix Wing",
    "item__W_PoseidonsWand__gameplay": "Shoots fish.",
    "item__W_PoseidonsWand__lore": "FIIIIIIIIIIIIIIIISH!",
    "item__W_PoseidonsWand__name": "Poseidon's Wand",
    "item__W_QuincyBow__gameplay": "Fires arrows infused with electrical energy.",
    "item__W_QuincyBow__lore": "Shoots arrows made from the spirit energy of the wielder.",
    "item__W_QuincyBow__name": "Quincy Bow",
    "item__W_SilentShadow__gameplay": "Fires arrows imbued with shadow energy.",
    "item__W_SilentShadow__lore": "\"They came without warning and took us by suprise. We managed to fend them off but lost many in the process.\"",
    "item__W_SilentShadow__name": "Silent Shadow",
    "item__W_RangersGuillotine__gameplay": "Shoots poisoned arrows.",
    "item__W_RangersGuillotine__lore": "With deadly precision this brutal bow slices through defenses, embedding its victim with poisoned arrows.",
    "item__W_RangersGuillotine__name": "Ranger's Guillotine",
    "item__W_ReapersEmbrace__gameplay": "Shoots powerful chaos projectiles.",
    "item__W_ReapersEmbrace__lore": "A spectral bow that drains life with each arrow, ensnaring foes in the icy embrace of the Reaper.",
    "item__W_ReapersEmbrace__name": "Reaper's Embrace",
    "item__W_RelicWand__gameplay": "Manifests physical projectiles that deal high damage.",
    "item__W_RelicWand__lore": "Channels the essence of lost civilizations and forgotten magic.",
    "item__W_RelicWand__name": "Relic Wand",
    "item__W_ReliqueryCrossbow__gameplay": "Shoots high damage chaos bolts.",
    "item__W_ReliqueryCrossbow__lore": "Crossbow used by the priests of the Vampiric Reliquary.",
    "item__W_ReliqueryCrossbow__name": "Reliquary Crossbow",
    "item__W_SamsStirringStick__gameplay": "Places a visible trap near your feet that deals arcane damage.",
    "item__W_SamsStirringStick__lore": "A legendary chef's stirring stick, imbued with the power of the (cooking) elements.",
    "item__W_SamsStirringStick__name": "Sam's Stirring Stick",
    "item__W_SlimeStaff__gameplay": "Places a visible trap near your feet that deals nature damage.",
    "item__W_SlimeStaff__lore": "A staff pulsating with the essence of slimes, it allows the wielder to summon and command these gelatinous creatures.",
    "item__W_SlimeStaff__name": "Slime Staff",
    "item__W_SpellbindersTome__gameplay": "Emit arcane projectiles at your opponents.",
    "item__W_SpellbindersTome__lore": "A mystical leather-bound book that ensnares minds with intricate spells of enchantment.",
    "item__W_SpellbindersTome__name": "Spellbinder's Tome",
    "item__W_SpiraledWand__gameplay": "Shoots bolts of kinetic magic energy.",
    "item__W_SpiraledWand__lore": "Really, it's just a fancier stick.",
    "item__W_SpiraledWand__name": "Spiraled Wand",
    "item__W_StaffOfTheSnake__gameplay": "Places a visible trap near your feet that deals toxic damage.",
    "item__W_StaffOfTheSnake__lore": "A staff adorned with the head of a cobra, chiseled in Emerald. It is said to be able to spit venom at foes.",
    "item__W_StaffOfTheSnake__name": "Staff of the Snake",
    "item__W_StarShot__gameplay": "Shoots strong arcane arrows infused with star energy.",
    "item__W_StarShot__lore": "Carved from celestial fragments, this bow releases a cascade of sparkling projectiles, a celestial spectacle of destruction.",
    "item__W_StarShot__name": "Star Shot",
    "item__W_StarWand__gameplay": "Emit the power of the stars at your opponents.",
    "item__W_StarWand__lore": "A whimsical and glittery instrument.",
    "item__W_StarWand__name": "Star Wand",
    "item__W_SteelCrossbow__gameplay": "Shoots crossbow bolts that pierce even the thickest of hides.",
    "item__W_SteelCrossbow__lore": "A standard crossbow made of steel.",
    "item__W_SteelCrossbow__name": "Steel Crossbow",
    "item__W_Stick__gameplay": "Shoots sparks of magic energy.",
    "item__W_Stick__lore": "There are many like it, but this one is mine!",
    "item__W_Stick__name": "Stick",
    "item__W_TheCrossBow__gameplay": "Shoots holy projectiles at a fast pace.",
    "item__W_TheCrossBow__lore": "Deliver the lords justice with this legendary crossbow.",
    "item__W_TheCrossBow__name": "The Cross Bow",
    "item__W_ToxicWand__gameplay": "Fires a toxic orb.",
    "item__W_ToxicWand__lore": "It may be addicting but you should know that its toxic.",
    "item__W_ToxicWand__name": "Toxic Wand",
    "item__W_Triforce__gameplay": "Emit the power of the triforce at your opponents.",
    "item__W_Triforce__lore": "A radiant tome that unlocks the divine powers of courage, wisdom, and power.",
    "item__W_Triforce__name": "Triforce",
    "item__W_TwistedBranch__gameplay": "Places a visible trap near your feet that deals physical damage.",
    "item__W_TwistedBranch__lore": "A crude walking stick that has been enchanted by a late wizard.",
    "item__W_TwistedBranch__name": "Twisted Branch",
    "item__W_WitchHuntersRepeaters__gameplay": "Shoots bolts infused with a holy radiance.",
    "item__W_WitchHuntersRepeaters__lore": "Common crossbows used by the Witch Hunters of the Arcane Order.",
    "item__W_WitchHuntersRepeaters__name": "Witch Hunter's Repeater",
    "item__W_WizardsLedger__gameplay": "RAPIDLY fires coins. Yes, coins.",
    "item__W_WizardsLedger__lore": "Is this pay to win?",
    "item__W_WizardsLedger__name": "Wizard's Ledger",
    "item__W_WyvernsTalon__gameplay": "Shoots powerful bolts that deal bleed damage.",
    "item__W_WyvernsTalon__lore": "Crossbow crafted from the talons of a great wyvern.",
    "item__W_WyvernsTalon__name": "Wyvern's Talon",
    "item__W_ZappingWand__gameplay": "Fires lightning projectiles.",
    "item__W_ZappingWand__lore": "Brings a surge of electrifying might to your magical repertoire.",
    "item__W_ZappingWand__name": "Zapping Wand",
    "item_container__loot_chest": "Loot Chest",
    "item_container__loot_drop": "Loot Drop",
    "item_rarity__common": "Common",
    "item_rarity__epic": "Epic",
    "item_rarity__legendary": "Legendary",
    "item_rarity__rare": "Rare",
    "item_rarity__uncommon": "Uncommon",
    "common": "Common",
    "uncommon": "Uncommon",
    "rare": "Rare",
    "epic": "Epic",
    "legendary": "Legendary",
    "item_slot__damage": "damage",
    "item_slot__equip_prompt": "Left click to equip!",
    "item_slot__extract_prompt": "Extract to use this item!",
    "item_slot__grants_ability": "Grants Ability",
    "item_slot__grants_passive": "Passive Effect",
    "item_slot__loot_prompt": "Left click to loot!",
    "loadout_menu__abilities_header": "Abilities",
    "loadout_menu__back_to_main_menu_button": "Back",
    "loadout_menu__damage": "Damage",
    "loadout_menu__defence": "Defence",
    "loadout_menu__gear_header": "Gear",
    "loadout_menu__health": "Health",
    "loadout_menu__insurance_button": "Insure",
    "loadout_menu__no_abilities": "Your loadout does not contain any abilities.",
    "loadout_menu__no_slot_selected_insurance_text": "🚨 Nothing is insured! 🚨",
    "loadout_menu__no_slot_selected_scrollbox": " Select a slot to change your loadout",
    "loadout_menu__scrollbox_with_gear_text": "Choose some gear",
    "loadout_menu__scrollbox_with_skins_text": "Choose a skin",
    "loadout_menu__scrollbox_with_weapons_text": "Choose a weapon",
    "loadout_menu__skin_header": "Choose A Skin",
    "loadout_menu__slot_insured_text": "✅ You have insurance! ✅",
    "loadout_menu__start_run_button": "Play",
    "loadout_menu__stat": "Stat",
    "loadout_menu__title": "Loadout",
    "loadout_menu__undo": "Undo",
    "loadout_menu__weapons_header": "Weapons",
    "loadout_menu__will_be_safe": "will be safe if you die.",
    "loadout_menu__your": "Your",
    "login__header": "Log In",
    "fullscreen": "Full Screen",
    "exit_fullscreen": "Quit Full Screen",
    "loot_prompt": "[ E ] to loot",
    "leaderboard__player_kills": "PK",
    "leaderboard__loot": "Loot",
    "leaderboard__npc_kills": "NPCK",
    "main_menu__patch_notes_btn": "Patch Notes",
    "main_menu__change_username_btn": "Change Username",
    "main_menu__create_account_btn": "Create Account",
    "main_menu__collection_log_btn": "Collection Log",
    "collection_log__header": "Collection Log",
    "main_menu__discord_btn_tooltip": "Find friends and join us on Discord!",
    "main_menu__game_is_in_maintenance_mode": "PvPz.io is being updated! The new update will be deployed soon, try again in a few minutes",
    "main_menu__how_to_play_btn": "How to play",
    "main_menu__identity_error_title": "Uh Oh",
    "main_menu__loading_bar_text": "Loading...",
    "main_menu__logout_btn": "Log Out",
    "main_menu__partners": "Partners",
    "main_menu__partners_and_credits": "All partners & credits",
    "main_menu__patch_prefix": "Patch",
    "main_menu__patch_suffix": "coming June 1st!",
    "main_menu__play_btn": "Play",
    "main_menu__rankings_btn": "Rankings",
    "main_menu__settings_btn": "Settings",
    "main_menu__sign_in_btn": "Sign In",
    "main_menu__sign_in_with_discord_btn": "Sign in with Discord",
    "main_menu__sign_in_with_facebook_btn": "Sign in with Facebook",
    "main_menu__sign_in_with_google_btn": "Sign in with Google",
    "main_menu__twitter_btn_tooltip": "Follow us on twitter!",
    "main_menu__vendors_btn": "Vendors",
    "main_menu__view_inventory_btn": "Inventory",
    "main_menu__view_your_stats_btn": "Your Stats",
    "main_menu__website_btn_tooltip": "Visit our website!",
    "main_menu__welcome_panel_title": "Welcome",
    "main_menu__welcome_panel_title_suffix": " back,",
    "main_menu__welcome_sign_in_cta": "Want to change your username? Sign In!",
    "main_menu_stash__collectors_items": "Collectors Items",
    "main_menu_stash__discovered": "Discovered",
    "main_menu_stash__gear": "Gear",
    "main_menu_stash__header": "Stash",
    "main_menu_stash__total_items_discovered": "Items Discovered",
    "main_menu_stash__vendor_items": "Vendor Items",
    "main_menu_stash__weapons": "Weapons",
    "player_skins__boltman": "Ranger",
    "player_skins__corrupted_wizard": "Corrupted Wizard",
    "player_skins__knight": "Knight",
    "player_skins__red_wizard": "Wizard's Apprentice",
    "player_skins__thief": "Thief",
    "player_skins__veteran_wizard": "Dragon School Wizard",
    "rankings__back_to_main_menu_button": "Back",
    "rankings__coming_soon": "Coming Soon!",
    "rankings__title": "Rankings",
    "region__dreamers_forest": "Dreamers Forest",
    "region__ert_lich": "Ert Lich",
    "region__erthel": "Erthel",
    "region__forgotten_temple": "Forgotten Temple",
    "region__skull_lake": "Skull Lake",
    "region__swamp_of_sorrows": "Swamp of Sorrows",
    "region__the_edgelands": "Edgelands",
    "region__wilderness": "Wilderness",
    "dreamers forest": "Dreamers Forest",
    "ert lich": "Ert Lich",
    "erthel": "Erthel",
    "forgotten temple": "Forgotten Temple",
    "skull lake": "Skull Lake",
    "swamp of sorrows": "Swamp of Sorrows",
    "edgelands": "Edgelands",
    "wilderness": "Wilderness",
    "register__terms_label_1": "By registering an account with PvPz.io & Second Monitor Games, you are agreeing to our",
    "register__terms_label_2": "Terms of Service",
    "register__terms_label_3": "Privacy Policy",
    "register__promo_label": "It's OK for Second Monitor Games to email me about promotions and game updates",
    "register__delete_account_cta": "You are an anonymous user so your account will be deleted in 2 weeks! If you want to keep your items and stats, and change your username, please register!",
    "register__delete_account_cta_2": "You are an anonymous user so you cannot see stats! If you want to see your stats, change your username, and unlock special skins, please register!",
    "register__delete_account_cta_less_aggressive": "If you want to save your items and stats forever, and change your username, please register!",
    "register__email": "Enter your email address",
    "register__email_confirm": "Confirm email address",
    "register__email_placeholder": "Email Address",
    "register__email_placeholder_confirm": "Confirm Email Address",
    "register__header": "Register",
    "register__or": "Or",
    "register__password": "Enter a password",
    "register__password_confirm": "Confirm your password",
    "register__register": "Register",
    "register__register_with_discord_btn": "Register with Discord",
    "register__register_with_google_btn": "Register with Google",
    "register__username": "Choose a username",
    "runprep__skin_not_unlocked_registered": "Only REGISTERED USERS can use that skin!",
    "settings__back_to_main_menu_button": "Back",
    "settings__enable_music": "Enable Music",
    "settings__enable_sfx": "Enable Sound Effects",
    "settings__language": "Language",
    "settings__language_dropdown_english": "English",
    "settings__language_dropdown_portuguese": "Portuguese / Português",
    "settings__language_dropdown_russian": "Russian / русский",
    "settings__language_dropdown_spanish": "Spanish / Español",
    "settings__matchmaking_region": "Matchmaking Region",
    "settings__matchmaking_region_dropdown_africa": "Africa",
    "settings__matchmaking_region_dropdown_any": "Any Region (fastest)",
    "settings__matchmaking_region_dropdown_asia": "Asia",
    "settings__matchmaking_region_dropdown_australia_oceania": "Australia/Oceania",
    "settings__matchmaking_region_dropdown_eu": "Europe",
    "settings__matchmaking_region_dropdown_middle_east": "Middle East",
    "settings__matchmaking_region_dropdown_na_east": "North America (East)",
    "settings__matchmaking_region_dropdown_na_west": "North America (West)",
    "settings__matchmaking_region_dropdown_south_america": "South America",
    "settings__save_button": "Save",
    "settings__title": "Settings",
    "settings__settings_saved": "Settings Saved!",
    "signin_sign_in": "Sign In",
    "signin_sign_in_with_discord_btn": "Sign In with Discord",
    "signin_sign_in_with_google_btn": "Sign In with Google",
    "item_type__weapon": "Weapon",
    "item_type__gear": "Gear",
    "item_type__vendor": "Vendor Trash",
    "item_type__collector": "Special Item",
    "item_type__unknown": "Unknown",
    "stat_type_arcane": "Arcane",
    "stat_type_bleed": "Bleed",
    "stat_type_chaos": "Chaos",
    "stat_type_fire": "Fire",
    "stat_type_frost": "Frost",
    "stat_type_holy": "Holy",
    "stat_type_lightning": "Lightning",
    "stat_type_nature": "Nature",
    "stat_type_physical": "Physical",
    "stat_type_shadow": "Shadow",
    "stat_type_toxic": "Toxic",
    "stats__back_btn": "Back",
    "stats__boss_kills": "Boss kills",
    "stats__common_items_looted": "common items looted",
    "stats__distanced_travelled": "distance travelled",
    "stats__epic_items_looted": "epic items looted",
    "stats__failed_runs": "failed runs",
    "stats__global_rank": "global rank",
    "stats__gold_looted": "gold looted",
    "stats__gold_lost_to_deaths": "gold lost to deaths",
    "stats__header": "Stats",
    "stats__items_looted": "items looted",
    "stats__items_lost_to_deaths": "items lost to deaths",
    "stats__legendary_items_looted": "legendary items looted",
    "stats__npc_kills": "NPC kills",
    "stats__player_kills": "player kills",
    "stats__rare_items_looted": "rare items looted",
    "stats__run_success_percentage": "run success percentage",
    "stats__runs": "runs",
    "stats__successful_runs": "successful runs",
    "stats__time_played": "time played",
    "stats__uncommon_items_looted": "uncommon items looted",
    "toast__email_doesnt_match": "Email addresses dont match!",
    "toast__email_not_long_enough": "Email address isn't long enough!",
    "toast__gods_granted_you_items": "The gods granted you some basic equipment!",
    "toast__not_an_email_address": "That doesnt look like a real email address!",
    "toast__password_not_long_enough": "Password isnt long enough!",
    "toast__passwords_dont_match": "Passwords dont match!",
    "toast__username_changed": "Username changed successfully!",
    "toast__username_too_short": "Username too short!",
    "vendors__back_to_main_menu_button": "Back",
    "vendors__coming_soon": "Coming Soon!",
    "vendors__title": "Vendors",
    "event_feed__looted_a": "looted a",
    "event_feed__in_the": "in the",
    "event_feed__at": "at",
    "event_feed__killed": "killed",
    "event_feed__boss": "boss",
    "event_feed__is_extracting": "is extracting",
    "event_feed__has_extracted": "has successfully extracted!",
    "event_feed__has_spawned": "has spawned at",
    "event_feed__under_attack": "is under attack at"
}

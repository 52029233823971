import { settings, Application, Container, IApplicationOptions, UPDATE_PRIORITY, SCALE_MODES, Sprite, Assets, Point, Graphics } from "pixi.js";
// @ts-ignore
import { addStats, Stats } from "pixi-stats";
import { IBounceOptions, IFollowOptions, Viewport } from "pixi-viewport";
import { GameplaySystem } from "../../shared/engine/SharedGameplaySystem";
import { ClientPlayer } from "../entities/ClientPlayer";
import { worldConfig } from "../../shared/config/Config_World";
import { IdentityMessage } from "../../shared/SharedNetcodeSchemas";
import { ClientOneOffPfx } from "../pfx/ClientOneOffPfx";
import { NetworkEntityId } from "../../shared/SharedTypes";
import { playerConfig } from "../../shared/config/Config_Player";
import { cameraConfig } from "../../shared/config/Config_Camera";
import { perfConfig } from "../../shared/config/Config_Perf";
import { collisionConfig } from "../../shared/config/Config_Collision";
import { IRenderedEntity } from "../ClientTypes";
import { WeaponClass } from "../../shared/data/Data_Weapons";

settings.SCALE_MODE = SCALE_MODES.NEAREST;

export class ClientRenderer extends GameplaySystem {
    public camera: Viewport;
    private pixiApplication: Application;
    private pixiOptions: IApplicationOptions;
    public MyLocalNonPredictedEntityNid: NetworkEntityId;
    public MyLocalNonPredictedEntity: ClientPlayer;
    private stats: Stats;
    public CameraZoomLevel = cameraConfig.DEFAULT_CAMERA_ZOOM;

    private backgroundLayerOne: Container = new Container();
    private backgroundLayerTwo: Container = new Container();
    private middlegroundLayer: Container = new Container();
    private entityLayer: Container = new Container();
    private foregroundLayerOne: Container = new Container();
    private foregroundLayerTwo: Container = new Container();

    private backgroundImageOne: Sprite;
    private backgroundImageTwo: Sprite;
    private middlegroundImage: Sprite;
    private foregroundImageOne: Sprite;
    private foregroundImageTwo: Sprite;
    private collisionImage: Sprite;

    public MyLocalEntityHasInformationPassive: boolean = false;

    private clickedGameRendererThisFrame: boolean = false;

    private readonly cameraFollowSettings: IFollowOptions = {
        speed: cameraConfig.FOLLOW_SPEED,
        acceleration: cameraConfig.FOLLOW_ACCELERATION,
        radius: cameraConfig.FOLLOW_RADIUS
    };

    public constructor() {
        super();
    }

    public Initialize(): void {
        const canvas = document.getElementById("game-canvas") as HTMLCanvasElement;
        const gameContainer = document.getElementById("game-container") as HTMLElement;

        const rendererContainerWidth = parseInt(window.getComputedStyle(gameContainer).width, 10);
        const rendererContainerHeight = parseInt(window.getComputedStyle(gameContainer).height, 10);

        this.pixiOptions = {
            view: canvas,
            width: rendererContainerWidth,
            height: rendererContainerHeight,
            antialias: false,
            resolution: 1,
            backgroundColor: "#000000",
            backgroundAlpha: 1,
            clearBeforeRender: true,
            context: null,
            powerPreference: "default",
            premultipliedAlpha: false,
            preserveDrawingBuffer: false,
            hello: true
        };

        this.pixiApplication = new Application(this.pixiOptions);

        if (perfConfig.DEBUG.ENABLE_STATS) {
            this.stats = addStats(document, this.pixiApplication);
        }

        this.camera = new Viewport({
            screenWidth: rendererContainerWidth,
            screenHeight: rendererContainerHeight,
            worldWidth: worldConfig.WORLD_SIZE,
            worldHeight: worldConfig.WORLD_SIZE,
            events: this.pixiApplication.renderer.events
        });

        if (cameraConfig.DEBUG.DEFAULT_MEGA_ZOOMED_OUT) {
            this.camera.setZoom(1);
        } else {
            if (window.innerWidth >= 1600 && window.innerHeight >= 900) {
                console.log("Setting zoom to default");
                this.CameraZoomLevel = cameraConfig.DEFAULT_CAMERA_ZOOM;
            } else if (window.innerWidth >= 1280 && window.innerHeight >= 720) {
                // console.log("Setting zoom to 2.5");
                this.CameraZoomLevel = 2.5;
            } else if (window.innerWidth > 1000 && window.innerHeight > 900) {
                // console.log("Setting zoom to 2.25");
                this.CameraZoomLevel = 2.25;
            } else if (window.innerWidth > 860 && window.innerHeight > 720) {
                // console.log("Setting zoom to 2");
                this.CameraZoomLevel = 2;
            } else if (window.innerWidth > 650 && window.innerHeight > 550) {
                // console.log("Setting zoom to 2");
                this.CameraZoomLevel = 1.75;
            } else {
                // console.log("Setting zoom to 1.5");
                this.CameraZoomLevel = 1.5;
            }

            this.camera.setZoom(this.CameraZoomLevel, true);
        }

        this.backgroundImageOne = new Sprite(Assets.get("background-layer1"));
        this.backgroundImageOne.position.set(0, 0);
        this.backgroundLayerOne.addChild(this.backgroundImageOne);
        this.camera.addChild(this.backgroundLayerOne);

        this.backgroundImageTwo = new Sprite(Assets.get("background-layer2"));
        this.backgroundImageTwo.position.set(0, 0);
        this.backgroundLayerTwo.addChild(this.backgroundImageTwo);
        this.camera.addChild(this.backgroundLayerTwo);

        this.middlegroundImage = new Sprite(Assets.get("middleground-layer"));
        this.middlegroundImage.position.set(0, 0);
        this.middlegroundLayer.addChild(this.middlegroundImage);
        this.camera.addChild(this.middlegroundLayer);

        this.entityLayer.sortableChildren = true;
        this.camera.addChild(this.entityLayer);

        this.foregroundImageOne = new Sprite(Assets.get("foreground-layer1"));
        this.foregroundImageOne.position.set(0, 0);
        this.foregroundLayerOne.addChild(this.foregroundImageOne);
        this.camera.addChild(this.foregroundLayerOne);

        this.foregroundImageTwo = new Sprite(Assets.get("foreground-layer2"));
        this.foregroundImageTwo.position.set(0, 0);
        this.foregroundLayerTwo.addChild(this.foregroundImageTwo);
        this.camera.addChild(this.foregroundLayerTwo);

        if (collisionConfig.DEBUG.DRAW_COLLISION_TILE_LAYER) {
            this.collisionImage = new Sprite(Assets.get("collisions-layer"));
            this.collisionImage.position.set(0, 0);
            this.camera.addChild(this.collisionImage);
        }

        let crossbowCrosshair: string;
        let bowCrosshair: string;
        let wandCrosshair: string;
        let spellbookCrosshair: string;
        let staffCrosshair: string;

        if (window.innerWidth >= 1280) {
            crossbowCrosshair = "url('crosshairs/crosshair_crossbow.png') 28 28,auto";
            bowCrosshair = "url('crosshairs/crosshair_bow.png') 28 28,auto";
            wandCrosshair = "url('crosshairs/crosshair_wand.png') 28 28,auto";
            spellbookCrosshair = "url('crosshairs/crosshair_spellbook.png') 28 28,auto";
            staffCrosshair = "url('crosshairs/crosshair_staff.png') 28 28,auto";
        } else {
            crossbowCrosshair = "url('crosshairs/crosshair_crossbow_small.png') 14 14,auto";
            bowCrosshair = "url('crosshairs/crosshair_bow_small.png') 14 14,auto";
            wandCrosshair = "url('crosshairs/crosshair_wand_small.png') 14 14,auto";
            spellbookCrosshair = "url('crosshairs/crosshair_spellbook_small.png') 14 14,auto";
            staffCrosshair = "url('crosshairs/crosshair_staff_small.png') 14 14,auto";
        }

        this.pixiApplication.renderer.events.cursorStyles.spellbook = spellbookCrosshair;
        this.pixiApplication.renderer.events.cursorStyles.wand = wandCrosshair;
        this.pixiApplication.renderer.events.cursorStyles.bow = bowCrosshair;
        this.pixiApplication.renderer.events.cursorStyles.crossbow = crossbowCrosshair;
        this.pixiApplication.renderer.events.cursorStyles.staff = staffCrosshair;

        this.pixiApplication.renderer.events.cursorStyles.default = staffCrosshair;

        // this.pixiApplication.renderer.events.setCursor("staff");

        // @ts-ignore
        // this.pixiApplication.renderer.events.currentCursor = "spellbook";

        // console.warn(this.pixiApplication.renderer.events);

        Game.ListenForEvent("Gameplay::NewWeaponClassEquipped", (event) => {
            // @ts-ignore
            const { newWeaponClass } = event;

            switch (newWeaponClass) {
                case WeaponClass.Staff:
                    // console.log("setting to Staff crosshair");
                    this.pixiApplication.renderer.events.cursorStyles.default = staffCrosshair;
                    this.pixiApplication.renderer.events.setCursor(staffCrosshair);
                    break;
                case WeaponClass.Crossbow:
                    // console.log("setting to Crossbow crosshair");
                    this.pixiApplication.renderer.events.cursorStyles.default = crossbowCrosshair;
                    this.pixiApplication.renderer.events.setCursor(crossbowCrosshair);
                    break;
                case WeaponClass.Bow:
                    // console.log("setting to Bow crosshair");
                    this.pixiApplication.renderer.events.cursorStyles.default = bowCrosshair;
                    this.pixiApplication.renderer.events.setCursor(bowCrosshair);
                    break;
                case WeaponClass.Wand:
                    // console.log("setting to Wand crosshair");
                    this.pixiApplication.renderer.events.cursorStyles.default = wandCrosshair;
                    this.pixiApplication.renderer.events.setCursor(wandCrosshair);
                    break;
                case WeaponClass.Spellbook:
                    // console.log("setting to Spellbook crosshair");
                    this.pixiApplication.renderer.events.cursorStyles.default = spellbookCrosshair;
                    this.pixiApplication.renderer.events.setCursor(spellbookCrosshair);
                    break;
                default:
                    // console.warn("default cause hit");
                    this.pixiApplication.renderer.events.cursorStyles.default = staffCrosshair;
                    this.pixiApplication.renderer.events.setCursor(staffCrosshair);
                    break;
            }

            // console.log("new weapon class equipped: ", WeaponClass[newWeaponClass]);
        });

        this.pixiApplication.stage.addChild(this.camera);
        this.pixiApplication.stage.interactive = true;

        this.pixiApplication.stage.on("pointerdown", (event) => {
            this.clickedGameRendererThisFrame = true;
        });

        this.pixiApplication.stage.on("pointerup", (event) => {
            this.clickedGameRendererThisFrame = false;
        });

        if (cameraConfig.DEBUG.ALLOW_ZOOM) {
            this.camera.wheel().pinch();
        }

        this.camera.clamp({
            direction: "all"
        });

        Game.ListenForEvent("Resizer::RealtimeGameWindowResize", this.Resize.bind(this));

        Game.ListenForEvent("Resizer::GameWindowResizeComplete", this.Resize.bind(this));

        Game.ListenForEvent("Bootstrap::MyEntitiesNidIsKnown", (event: IdentityMessage) => {
            const { myId, myX, myY } = event;
            this.MyLocalNonPredictedEntityNid = myId;
            // this.camera.position.set(myX, myY);
            this.camera.animate({
                time: 0,
                position: new Point(myX, myY)
            });
        });

        // TODO: Why doesnt this work?!
        Game.ListenForEvent("debug:testCameraBounce", () => {
            const cameraBounceOptions: IBounceOptions = {
                sides: "all",
                friction: 0.5,
                time: 5000
            };
            this.camera.bounce(cameraBounceOptions);
        });

        // this.debugBackground = new Graphics();
        // this.debugBackground.beginFill(0x698d41);
        // this.debugBackground.drawRect(0, 0, worldConfig.WORLD_SIZE, worldConfig.WORLD_SIZE);
        // this.debugBackground.endFill();
        // this.AddRenderedEntity(this.debugBackground);

        this.LogInfo("Ready!");
    }

    public PlayOneOffPfx(x: number, y: number, pfxId: string, pfxScale: number, rotation?: number, overrideAnimationSpeed?: number): void {
        const pfx = new ClientOneOffPfx(x, y, pfxId, pfxScale, rotation, overrideAnimationSpeed);
        this.AddRenderedEntity(pfx);
    }

    protected override getSystemName(): string {
        return "Renderer";
    }

    public ClickedRendererThisFrame(): boolean {
        return this.clickedGameRendererThisFrame;
    }

    public UnsetClickedRendererThisFrame(): void {
        this.clickedGameRendererThisFrame = false;
    }

    public Update(__deltaTime: number): void {
        if (perfConfig.DEBUG.ENABLE_STATS && this.stats) {
            this.stats.update(UPDATE_PRIORITY.UTILITY);
        }

        // Lazily associated our local entity if it isnt already
        if (this.MyLocalNonPredictedEntityNid !== undefined && this.MyLocalNonPredictedEntity === undefined) {
            // It may not be created yet! Try grabbing it by nid
            const entity = Game.GetEntityByNid(this.MyLocalNonPredictedEntityNid);

            // If we successfully found it, that means it exists and is fully initialized
            if (entity) {
                this.LogInfo("my entity is identified, and we got their actual entity");
                this.MyLocalNonPredictedEntity = entity as ClientPlayer;
                this.MyLocalNonPredictedEntity.IdentifyAsMyPlayer();
                Game.EmitEvent("Bootstrap::MyServerSideEntityIsFullyCreatedLocally", { myEntity: this.MyLocalNonPredictedEntity });

                if (this.MyLocalNonPredictedEntity.hasInformationPassiveItem) {
                    this.MyLocalEntityHasInformationPassive = true;
                }

                if (playerConfig.ENABLE_PREDICTION) {
                    Game.EmitEvent("Prediction::ReadyToCreatePredictedEntity", { originalCreationPayload: this.MyLocalNonPredictedEntity.originalCreationPayload });
                } else {
                    this.StartFollowing(this.MyLocalNonPredictedEntityNid, this.MyLocalNonPredictedEntity);
                }
            }
        }

        // uncomment to see how many rendered entities exist. handy to see if we are leaking memory by not cleaning up children
        // if (this.camera) this.LogInfo(this.camera.children.length);
    }

    public Cleanup(): void {}

    public AddDebugGraphics(graphics: Graphics): void {
        this.foregroundLayerTwo.addChild(graphics);
    }

    public AddRenderedEntity(renderedEntity: IRenderedEntity) {
        this.entityLayer.addChild(renderedEntity.RenderedEntity);
    }

    public RemoveRenderedEntity(renderedEntity: IRenderedEntity) {
        this.entityLayer.removeChild(renderedEntity.RenderedEntity);
    }

    public Resize({ newWidth, newHeight }: { newWidth: number; newHeight: number }) {
        this.camera.resize(newWidth, newHeight);
        this.pixiApplication.renderer.resize(newWidth, newHeight);
    }

    public StartFollowing(nid: number, entity: IRenderedEntity) {
        this.LogInfo(`Started following entity with nid: ${nid}`);
        if (entity && entity.RenderedEntity) {
            this.camera.follow(entity.RenderedEntity, this.cameraFollowSettings);
        } else {
            console.warn(`unable to follow entity with nid ${nid} because our client doesnt think it exists`);
        }
    }

    public StopFollowing() {
        this.LogInfo("Stopping following entity");
        this.camera.plugins.remove("follow");
    }

    public GetMyLocalAuthoritativeEntityX(): number {
        if (this.MyLocalNonPredictedEntity === undefined) return 0;
        return this.MyLocalNonPredictedEntity.x;
    }

    public GetMyLocalAuthoritativeEntityY(): number {
        if (this.MyLocalNonPredictedEntity === undefined) return 0;
        return this.MyLocalNonPredictedEntity.y;
    }

    private _drawGrid(): void {
        // const grid = new Container();
        // const { GRID_CELL_SIZE } = collisionConfig;
        // for (let x = 0; x < worldConfig.WORLD_SIZE; x += GRID_CELL_SIZE) {
        //     for (let y = 0; y < worldConfig.WORLD_SIZE; y += GRID_CELL_SIZE) {
        //         const cell = new Graphics();
        //         cell.lineStyle(30, 0x000000);
        //         cell.drawRect(x, y, GRID_CELL_SIZE, GRID_CELL_SIZE);
        //         grid.addChild(cell);
        //     }
        // }
        // const WORLD_EDGE_LINE_WIDTH = 8;
        // const LINE_WIDTH = 40; // Width of the lines
        // const WORLD_EDGE_LINE_COLOR = 0x000000;
        // const LINE_COLOR = 0x64863e; // Color of the lines
        // // Loop through and draw the horizontal lines (excluding world bounds lines)
        // for (let y = GRID_CELL_SIZE; y <= worldConfig.WORLD_SIZE - GRID_CELL_SIZE; y += GRID_CELL_SIZE) {
        //     const line = new Graphics();
        //     line.lineStyle(LINE_WIDTH, LINE_COLOR);
        //     line.moveTo(0, y);
        //     line.lineTo(worldConfig.WORLD_SIZE, y);
        //     grid.addChild(line);
        // }
        // // Loop through and draw the vertical lines (excluding world bounds lines)
        // for (let x = GRID_CELL_SIZE; x <= worldConfig.WORLD_SIZE - GRID_CELL_SIZE; x += GRID_CELL_SIZE) {
        //     const line = new Graphics();
        //     line.lineStyle(LINE_WIDTH, LINE_COLOR);
        //     line.moveTo(x, 0);
        //     line.lineTo(x, worldConfig.WORLD_SIZE);
        //     grid.addChild(line);
        // }
        // // Draw the horizontal line on the top of the world border
        // const topBorder = new Graphics();
        // topBorder.lineStyle(WORLD_EDGE_LINE_WIDTH, WORLD_EDGE_LINE_COLOR);
        // topBorder.moveTo(0, 0);
        // topBorder.lineTo(worldConfig.WORLD_SIZE, 0);
        // grid.addChild(topBorder);
        // // Draw the horizontal line on the bottom of the world border
        // const bottomBorder = new Graphics();
        // bottomBorder.lineStyle(WORLD_EDGE_LINE_WIDTH, WORLD_EDGE_LINE_COLOR);
        // bottomBorder.moveTo(0, worldConfig.WORLD_SIZE);
        // bottomBorder.lineTo(worldConfig.WORLD_SIZE, worldConfig.WORLD_SIZE);
        // grid.addChild(bottomBorder);
        // // Draw the vertical line on the left side of the world border
        // const leftBorder = new Graphics();
        // leftBorder.lineStyle(WORLD_EDGE_LINE_WIDTH, WORLD_EDGE_LINE_COLOR);
        // leftBorder.moveTo(0, 0);
        // leftBorder.lineTo(0, worldConfig.WORLD_SIZE);
        // grid.addChild(leftBorder);
        // // Draw the vertical line on the right side of the world border
        // const rightBorder = new Graphics();
        // rightBorder.lineStyle(WORLD_EDGE_LINE_WIDTH, WORLD_EDGE_LINE_COLOR);
        // rightBorder.moveTo(worldConfig.WORLD_SIZE, 0);
        // rightBorder.lineTo(worldConfig.WORLD_SIZE, worldConfig.WORLD_SIZE);
        // grid.addChild(rightBorder);
        // if (collisionConfig.DEBUG.DRAW_COLLISION_GRID_TEXT) {
        //     for (let x = 0; x <= worldConfig.WORLD_SIZE; x += GRID_CELL_SIZE) {
        //         if (x === worldConfig.WORLD_SIZE) continue;
        //         for (let y = 0; y < worldConfig.WORLD_SIZE; y += GRID_CELL_SIZE) {
        //             const somePixiText = new Text(`${x}x,${y}y`, {
        //                 fontFamily: 'Fira Code',
        //                 fontSize: 64,
        //                 fill: 0xffffff
        //             });
        //             somePixiText.x = x + 5;
        //             somePixiText.y = y + 5;
        //             somePixiText.scale.set(0.1);
        //             const rect = new Graphics();
        //             rect.beginFill(0x000000);
        //             rect.drawRect(0, 0, LINE_WIDTH, LINE_WIDTH);
        //             rect.endFill();
        //             rect.x = x - LINE_WIDTH / 2;
        //             rect.y = y - LINE_WIDTH / 2;
        //             grid.addChild(rect);
        //             this.AddRenderedEntity(somePixiText);
        //         }
        //     }
        // }
        // this.AddRenderedEntity(grid);
    }
}

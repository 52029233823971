{
    "register__terms_label_1": "Регистрируя учетную запись в PvPz.io & Second Monitor Games, вы соглашаетесь с нашими",
    "register__terms_label_2": "Условиями использования",
    "register__terms_label_3": "Политикой конфиденциальности",
    "register__promo_label": "Мне не против получать электронные письма о акциях и обновлениях игр от Second Monitor Games",
    "adblock_detected_failed_to_insure": "Что-то пошло не так при страховании этого предмета. Пожалуйста, убедитесь, что ваш блокировщик рекламы отключен!",
    "ability_type_active": "Активный",
    "ability_type_passive": "Пассивный",
    "change_username__back": "Назад",
    "change_username__cant_be_empty_error": "Имя пользователя не может быть пустым",
    "change_username__current_prompt": "Ваше текущее имя пользователя",
    "change_username__enter_new": "Введите новое имя пользователя",
    "change_username__header": "Имя пользователя",
    "change_username__save": "Сохранить",
    "change_username__too_long_error": "Имя пользователя не может превышать 20 символов.",
    "character_already_on_run_menu__abondon_run_button": "Отменить прохождение",
    "character_already_on_run_menu__back_to_main_menu_button": "Назад",
    "character_already_on_run_menu__bolded_header": "Ваш персонаж уже находится в процессе прохождения!",
    "character_already_on_run_menu__paragraph_one": "Ваш персонаж ещё не вернулся с предыдущего прохождения. Если вы начнёте новое прохождение сейчас, все предметы из предыдущего прохождения (за исключением застрахованных предметов) будут потеряны!",
    "character_already_on_run_menu__paragraph_two": "Если вы считаете, что это ошибка, или если вы намеренно хотите отменить прохождение, вы можете принудительно отменить прохождение, используя кнопку ниже.",
    "character_already_on_run_menu__title": "ПРЕДУПРЕЖДЕНИЕ!",
    "connection_error__back_to_main_menu": "Вернуться в главное меню",
    "connection_error__error_reason": "Причина ошибки",
    "connection_error__oof": "Ой",
    "connection_error__server_error_header": "Ошибка подключения к серверу!",
    "death_menu__back_to_main_menu_button": "Вернуться в меню",
    "death_menu__gear_lost": "Потерянное снаряжение",
    "death_menu__gold_lost": "Потерянное золото",
    "death_menu__insured_items": "Застрахованные предметы",
    "death_menu__loot_lost": "Потерянная добыча",
    "death_menu__nothing_insured": "Ни один предмет не был застрахован!",
    "death_menu__saving_your_run_button": "Сохранение...",
    "death_menu__spectate_button": "Смотреть убийцу",
    "death_menu__survived_for": "Продержались",
    "death_menu__title": "R.I.P",
    "death_menu__weapons_lost": "Потерянное оружие",
    "death_menu__your_killer": "Ваш убийца",
    "extracted_menu__back_to_main_menu_button": "Вернуться в меню",
    "extracted_menu__gold_looted": "Добыто золота",
    "extracted_menu__loot_acquired": "Добыча получена",
    "extracted_menu__run_duration": "Продолжительность прохождения",
    "extracted_menu__title": "Извлечено!",
    "game_hud__cooldown": "Перезарядка",
    "game_hud__find_an_extraction_point": "Найдите точку извлечения!",
    "game_hud__inventory_extract_prompt": "Вы должны извлечься, чтобы использовать эту добычу!",
    "game_hud__inventory_hud": "Открыть инвентарь",
    "game_hud__leaderboard": "Таблица лидеров",
    "game_hud__leaderboard_current_world": "Текущий мир",
    "game_hud__leaderboard_hide": "Скрыть",
    "game_hud__leaderboard_show": "Показать",
    "game_hud__looted_container_close": "Закрыть",
    "game_hud__spectating": "Наблюдение",
    "game_hud__time_left_to_extract": "осталось для извлечения",
    "game_hud__your_inventory_header": "Ваш инвентарь!",
    "game_hud__your_inventory_hide": "для закрытия",
    "game_transition__finding_match": "Поиск матча",
    "hotkey_interact": "E",
    "hotkey_open_inventory": "i",
    "hotkey_toggle_leaderboard": "L",
    "how_to_play__ability_one_key": "Навык #1",
    "how_to_play__ability_three_key": "Навык #3",
    "how_to_play__ability_two_key": "Навык #2",
    "how_to_play__back_to_main_menu_button": "Назад",
    "how_to_play__chat_key": "Чат",
    "how_to_play__extraction": "Найдите точку извлечения!",
    "how_to_play__inventory_key": "Инвентарь",
    "how_to_play__leaderboard_key": "Таблица лидеров",
    "how_to_play__mouse_aim_controls": " : Прицеливание",
    "how_to_play__mouse_shoot_controls": " : Выстрел",
    "how_to_play__move_down_key": "Движение вниз",
    "how_to_play__move_left_key": "Движение влево",
    "how_to_play__move_right_key": "Движение вправо",
    "how_to_play__move_up_key": "Движение вверх",
    "how_to_play__small_paragraph_one": "Если вы не успеете извлечься вовремя, вы потеряете все предметы, которые взяли с собой (если у вас нет страховки!) и все предметы, которые вы добыли во время прохождения. Извлечение очень важно. Вы должны ИЗВЛЕЧЬСЯ со своей добычей, чтобы сохранить ее и использовать.",
    "how_to_play__small_paragraph_three": "Убивайте или будьте убитыми, пусть победит лучший искатель приключений!",
    "how_to_play__small_paragraph_two": "Убивайте других игроков, находите добычу и извлекайтесь!",
    "how_to_play__test_keys_header": "Проверьте клавиши!",
    "how_to_play__title": "PvPz.io",
    "how_to_play__top_big_paragraph": "Убивайте других игроков, находите добычу и извлекайтесь!",
    "how_to_play__weapon_one_key": "Оружие #1",
    "how_to_play__weapon_two_key": "Оружие #2",
    "in_game__extracting": "Извлечение...",
    "in_game__time_remaining": "Оставшееся время...",
    "in_game__warning_time_almost_up": "Найдите точку извлечения!",
    "insured_item_nothing": "Ничего",
    "item__AG_AmuletOfCursedFate__gameplay": "+10% скорость атаки | ВОЗРОЖДАЕТСЯ с 40% Здоровья, когда вы умираете (не суммируется с другими предметами возрождения)",
    "item__AG_AmuletOfCursedFate__lore": "Зловещий амулет, искривляющий судьбы и приносящий зловещие предзнаменования.",
    "item__AG_AmuletOfCursedFate__name": "Амулет Зловещей Судьбы",
    "item__AG_MagicBoots__name": "Магические Сапоги",
    "item__AG_MagicBoots__gameplay": "Сделать невидимым на 9 секунд при использовании (2 раза) | Ваше здоровье постоянно восстанавливается | Извлечение замедлено на 10%.",
    "item__AG_MagicBoots__lore": "Эти сапоги не издают никакого шума и бодрят своего обладателя.",
    "item__AG_Boros__gameplay": "На 7 секунд становитесь невидимыми при использовании | -12% Урон против NPC | +12% Поглощение урона от всего урона, который вы наносите игрокам",
    "item__AG_Boros__lore": "Говорят, что его камень создан из глаза оборотня.",
    "item__AG_Boros__name": "Борос",
    "item__AG_DarkPustulesRing__gameplay": "Принимайте на 5% меньше урона во время извлечения | Извлекайтесь на 5% быстрее",
    "item__AG_DarkPustulesRing__lore": "Это кольцо можно использовать для магического получения темной сладкой жидкости на целый день.",
    "item__AG_DarkPustulesRing__name": "Кольцо Темных Пузырей",
    "item__AG_DreamWeaversEmbrace__gameplay": "На 4 секунд становитесь невидимыми при использовании | -5% Урон игрокам | Извлекайтесь на 10% быстрее | ИНКОГНИТО: Скрывает ваши предметы и сообщения о местоположении от остального мира",
    "item__AG_DreamWeaversEmbrace__lore": "Камни на этом кольце меняют цвет в областях, где завеса тонкая.",
    "item__AG_DreamWeaversEmbrace__name": "Объятия Сновидцев",
    "item__AG_ForestsHaven__gameplay": "Исцеляет на 20 ЗДОРОВЬЯ при использовании | Извлекайтесь на 10% быстрее | ИНКОГНИТО: Скрывает ваши предметы и сообщения о местоположении от остального мира | +20% Эфирное",
    "item__AG_ForestsHaven__lore": "Тем, кто носит это кольцо, ищите рощу деревьев, скрытую среди снов.",
    "item__AG_ForestsHaven__name": "Убежище Леса",
    "item__AG_MysteriousRing__gameplay": "Добавляет 30 секунд к вашему таймеру извлечения при использовании | +10 максимального здоровья | +7% к скорости передвижения при ходьбе по воде",
    "item__AG_MysteriousRing__lore": "???",
    "item__AG_MysteriousRing__name": "Таинственное Кольцо",
    "item__AG_ObsidianEcho__gameplay": "ИЗВЛЕЧЕНИЕ при использовании, независимо от вашего местоположения | +12% к скорости передвижения | ВОЗРОЖДАЕТСЯ с 70% Здоровья, когда вы умираете (не суммируется с другими предметами возрождения) | -30 максимального здоровья | Этот предмет уничтожается при полном использовании",
    "item__AG_ObsidianEcho__lore": "Скованное из осколка клинка Малахая. Если вы внимательно слушаете, вы можете услышать эхо душ тех, кого он убил.",
    "item__AG_ObsidianEcho__name": "Обсидиановое Эхо",
    "item__AG_PepperedCollar__gameplay": "Предоставляет увеличение скорости передвижения на 25% на 4 секунды при использовании (1 раз) | -3% Дальность полета снаряда",
    "item__AG_PepperedCollar__lore": "Ошейник-ожерелье, созданное в честь знаменитого кошачьего зверя.",
    "item__AG_PepperedCollar__name": "Ошейник с перцем",
    "item__AG_RingOfAnOceanMan__gameplay": "Даёт 2 секунды +20% скорости передвижения при использовании | Извлечение ускорено на 5% | +5% к скорости передвижения при ходьбе по воде",
    "item__AG_RingOfAnOceanMan__lore": "Кажется, что оно пытается взять вас за руку и провести в место, которое понимает только оно.",
    "item__AG_RingOfAnOceanMan__name": "Кольцо Океана",
    "item__AG_StuddedNecklace__gameplay": "Становится невидимым на 4 секунды при использовании (3 заряда) | -3% скорости передвижения",
    "item__AG_StuddedNecklace__lore": "Мне, наверное, стоит застраховать это",
    "item__AG_StuddedNecklace__name": "Заколка с шипами",
    "item__AG_TheDarkLordsEmbrace__gameplay": "+30 Максимальное Здоровье",
    "item__AG_TheDarkLordsEmbrace__lore": "Проклято всеми различными злодеяниями, совершенными его обладателем.",
    "item__AG_TheDarkLordsEmbrace__name": "Объятия Тёмного Лорда",
    "item__AG_TimeHerosRing__gameplay": "Добавляет 45 секунд к вашему таймеру извлечения при использовании | +7% скорость атаки | +7% скорость передвижения при ходьбе по воде | -5% скорость передвижения | +9% защита от Природы | +7% урон Природой",
    "item__AG_TimeHerosRing__lore": "Носился эльфийским мальчиком, который мог путешествовать во времени.",
    "item__AG_TimeHerosRing__name": "Кольцо Героя Времени",
    "item__AG_VestiasSoothingSignet__gameplay": "Восстанавливает 30 ОЗ при использовании | INCOGNITO: Скрывает ваши сообщения о добыче и местоположении от остального мира",
    "item__AG_VestiasSoothingSignet__lore": "Кольцо, которое позволяет владельцу успокаивать и травмы, и напряжение в группе.",
    "item__AG_VestiasSoothingSignet__name": "Успокаивающий Знак Вестии",
    "item__AG_ViolasDinnerReminder__gameplay": "+8,5% защиты от Природы | +8,5% защиты от Мороза | Получаете на 7% меньше урона во время извлечения | Ваши снаряды летят на 5% короче",
    "item__AG_ViolasDinnerReminder__lore": "Кольцо, которое позволяет всем вокруг знать, когда вы голодны.",
    "item__AG_ViolasDinnerReminder__name": "Напоминание о Ужине Виолы",
    "item__AG_WolfMedallion__gameplay": "Восстанавливает 10 ед. здоровья при использовании | +10% скорость атаки | +10% Leech от всего урона, который вы наносите игрокам",
    "item__AG_WolfMedallion__lore": "Настроенный на магию, он может обнаружить близлежащие места силы.",
    "item__AG_WolfMedallion__name": "Волчий Медальон",
    "item__CI_AlloyedBend__gameplay": "",
    "item__CI_AlloyedBend__lore": "Кольцо, изготовленное из нескольких редких металлов, грубо сплавленных вместе.",
    "item__CI_AlloyedBend__name": "Сплавленный Изгиб",
    "item__CI_ApposSignet__gameplay": "",
    "item__CI_ApposSignet__lore": "Говорят, что принадлежало полу-дракону, полу-человеку, который обладал способностью контролировать и разговаривать с утками.",
    "item__CI_ApposSignet__name": "Сигнет Аппо",
    "item__CI_BloodhuntersRing__gameplay": "",
    "item__CI_BloodhuntersRing__lore": "Охотница за кровью ищет своих сородичей, а также вас.",
    "item__CI_BloodhuntersRing__name": "Кольцо Охотника за Кровью",
    "item__CI_CelestialEternity__gameplay": "",
    "item__CI_CelestialEternity__lore": "Те, кто восстал против Малакая, были одарены богами этим кольцом.",
    "item__CI_CelestialEternity__name": "Небесная Вечность",
    "item__CI_EbonstarBand__gameplay": "",
    "item__CI_EbonstarBand__lore": "Это кольцо хранит часть упавшей звезды, изменившей все.",
    "item__CI_EbonstarBand__name": "Кольцо Звезды Полуночи",
    "item__CI_EchoingWhisperNecklace__gameplay": "",
    "item__CI_EchoingWhisperNecklace__lore": "Ожерелье, которое запечатывает и эхом отражает шепот потерянных душ.",
    "item__CI_EchoingWhisperNecklace__name": "Ожерелье Эха Шепота",
    "item__CI_FeyEnchantmentLocket__gameplay": "",
    "item__CI_FeyEnchantmentLocket__lore": "Медальон, пропитанный чарами из мира Фей, придавая обольстительную привлекательность.",
    "item__CI_FeyEnchantmentLocket__name": "Медальон Очарования Фей",
    "item__CI_FragmentOfFate__gameplay": "",
    "item__CI_FragmentOfFate__lore": "Однажды целое, теперь разбитое. Однажды объединенное, теперь разделенное. Однажды вечное, теперь устаревшее.",
    "item__CI_FragmentOfFate__name": "Фрагмент Судьбы",
    "item__CI_LatrosKnavishBand__gameplay": "",
    "item__CI_LatrosKnavishBand__lore": "Кольцо, которое носили только самые злобные воры с непоколебимой уверенностью в своей морали.",
    "item__CI_LatrosKnavishBand__name": "Коварное Кольцо Латроса",
    "item__CI_LifesSimpleRing__gameplay": "",
    "item__CI_LifesSimpleRing__lore": "Живи просто, чтобы другим было проще жить.",
    "item__CI_LifesSimpleRing__name": "Простое Кольцо Жизни",
    "item__CI_LuminousEnigma__gameplay": "",
    "item__CI_LuminousEnigma__lore": "Я храню ключ к обширному знанию, предоставляя непревзойденные ответы.",
    "item__CI_LuminousEnigma__name": "Светящаяся Загадка",
    "item__CI_Ouro__gameplay": "",
    "item__CI_Ouro__lore": "Сообщается, что это ключ к разгадке знания всех живых существ.",
    "item__CI_Ouro__name": "Оуро",
    "item__CI_PeachRing__gameplay": "",
    "item__CI_PeachRing__lore": "Вкусно сладкий",
    "item__CI_PeachRing__name": "Персиковое Кольцо",
    "item__CI_PlayerSoulFragment__gameplay": "Это может заинтересовать собирателя душ.",
    "item__CI_PlayerSoulFragment__lore": "Фрагмент души потерянного искателя приключений.",
    "item__CI_PlayerSoulFragment__name": "Фрагмент Души Игрока",
    "item__CI_ProudlyPinkRing__gameplay": "",
    "item__CI_ProudlyPinkRing__lore": "Это кольцо — потрясающий аксессуар, который определенно привлечет внимание других.",
    "item__CI_ProudlyPinkRing__name": "Гордо Розовое Кольцо",
    "item__CI_RadiantSunstoneBand__gameplay": "",
    "item__CI_RadiantSunstoneBand__lore": "Парадоксальное создание, это кольцо символизирует хрупкое равновесие между просвещением и разрушением.",
    "item__CI_RadiantSunstoneBand__name": "Сияющее Кольцо Солнечного Камня",
    "item__CI_RingOfDelerium__gameplay": "",
    "item__CI_RingOfDelerium__lore": "Если вы прислушаетесь, вы можете услышать голоса прежних владельцев этого кольца.",
    "item__CI_RingOfDelerium__name": "Кольцо Безумия",
    "item__CI_RoyalBrooch__gameplay": "",
    "item__CI_RoyalBrooch__lore": "Символ королевской семьи. Стоит целое состояние.",
    "item__CI_RoyalBrooch__name": "Королевская Брошь",
    "item__CI_SuperStar__gameplay": "",
    "item__CI_SuperStar__lore": "Остаток выдуманной истории о королевстве, заполненном грибами.",
    "item__CI_SuperStar__name": "Суперзвезда",
    "item__CI_SwampOgresRing__gameplay": "",
    "item__CI_SwampOgresRing__lore": "Принадлежал зеленому огру, который очень берег свою трясину.",
    "item__CI_SwampOgresRing__name": "Кольцо Болотного Огра",
    "item__CI_WeddingRing__gameplay": "",
    "item__CI_WeddingRing__lore": "Это стоит немало, я должен вернуть его правильному владельцу",
    "item__CI_WeddingRing__name": "Обручальное Кольцо",
    "item__PG_AngelistasPotionPopper__gameplay": "Ваше здоровье восстанавливается постоянно",
    "item__PG_AngelistasPotionPopper__lore": "Кольцо, которое вы носите, когда ваш лекарь запрещает вам пить лечебные зелья.",
    "item__PG_AngelistasPotionPopper__name": "Затыкалка для Зелий Ангелисты",
    "item__PG_AquamoorsBlessing__gameplay": "Даёт 3 секунды +25% скорости передвижения при использовании (3 зарядов) | Ваше здоровье восстанавливается постоянно | -20 максимального здоровья",
    "item__PG_AquamoorsBlessing__lore": "Говорят, что оно благословлено духом озера перед коррупцией земли.",
    "item__PG_AquamoorsBlessing__name": "Благословение Аквамура",
    "item__PG_ArcaneEmber__gameplay": "-6.5% Урон по НИПам | +5.6% Урон по игрокам",
    "item__PG_ArcaneEmber__lore": "В этом кольце видно маленький тлеющий уголь.",
    "item__PG_ArcaneEmber__name": "Таинственный Уголь",
    "item__PG_ArcaneSigilCollar__gameplay": "Предоставляет 4 секунды +30% скорости передвижения при использовании | -8.5% урона по НИПам | +9.5% урона по игрокам",
    "item__PG_ArcaneSigilCollar__lore": "Гравированный мощными символами, он усиливает арканную мощь владельца.",
    "item__PG_ArcaneSigilCollar__name": "Ошейник Арканного Символа",
    "item__PG_AssassinSignet__gameplay": "Становитесь невидимым на 5 секунд при использовании (2 заряда) | -3% Скорость передвижения | -3% Дальность полета снаряда",
    "item__PG_AssassinSignet__lore": "Печать, принадлежавшая печально известному убийце, сражавшемуся с помощью своих волос.",
    "item__PG_AssassinSignet__name": "Печать Убийцы",
    "item__PG_BellsShiningRing__gameplay": "Добавляет 60 секунд к вашему таймеру извлечения при использовании | -9,5% к урону по игрокам | +15,5% к урону по НПК | +40 максимального здоровья | Ваше здоровье восстанавливается постоянно",
    "item__PG_BellsShiningRing__lore": "Известен многим как лучший целитель в царстве.",
    "item__PG_BellsShiningRing__name": "Сияющее Кольцо Беллы",
    "item__PG_BloodGemRing__gameplay": "Возрождает с 50% здоровья после смерти | -20 максимального здоровья",
    "item__PG_BloodGemRing__lore": "Те, кто находились в храме во время ритуала, пали в ярость кровавого бойня. Это кольцо символизирует отчаянные времена.",
    "item__PG_BloodGemRing__name": "Кровавое Каменное Кольцо",
    "item__PG_BloodiedVitality__gameplay": "ИЗВЛЕЧЕНИЕ при использовании, независимо от вашего местоположения | Ваше здоровье восстанавливается постоянно | +30 максимального здоровья | -8% урона по игрокам | Вы получаете на 10% больше урона от ВСЕХ типов урона при извлечении | Этот предмет уничтожается при полном использовании",
    "item__PG_BloodiedVitality__lore": "Ваша связь с реальностью и самой жизнью кажется крепче с этим предметом у вас на теле.",
    "item__PG_BloodiedVitality__name": "Кровавая Жизненная Сила",
    "item__PG_BloodmoonLoop__gameplay": "Исцеляет 5HP при использовании (3 заряда) | +5% урона кровотечением",
    "item__PG_BloodmoonLoop__lore": "Связан с лунными циклами. Это повышает физическую мощь во время кровавой луны.",
    "item__PG_BloodmoonLoop__name": "Броня Кровавой Луны",
    "item__PG_CapeOfPureDarkness__gameplay": "Дарует 4 секунды +25% скорости передвижения при использовании | +9% Защиты Тени | +9% Защиты от Токсинов | +7% Урона от Токсинов | Ваше здоровье постоянно уменьшается.",
    "item__PG_CapeOfPureDarkness__lore": "Никто не осмеливался задавать ему вопросы из страха. Темный Правитель не подлежал опросу.",
    "item__PG_CapeOfPureDarkness__name": "Плащ Абсолютной Тьмы",
    "item__PG_CelestialSolaceBand__gameplay": "Восстанавливает 7 здоровья при использовании | ОСМОТР: Раскрывает информацию о ваших оппонентах | +6.5% урона от тени",
    "item__PG_CelestialSolaceBand__lore": "Выковано самими богами, это кольцо приносит утешение среди тьмы.",
    "item__PG_CelestialSolaceBand__name": "Кольцо Небесного Утешения",
    "item__PG_CelestialStarfallPendant__gameplay": "Становитесь невидимым на 3 секунды при использовании | +6.5% урона НИП | +5% урона Светом",
    "item__PG_CelestialStarfallPendant__lore": "Подвеска, украшенная небесным камнем, вызывающая небесную энергию.",
    "item__PG_CelestialStarfallPendant__name": "Подвеска Небесного Звездопада",
    "item__PG_CultistsMask__name": "Маска культиста",
    "item__PG_CultistsMask__lore": "Те, кто стал жертвами порчи, стремились скрыть свою внешность, поскольку порча изменила их самое существо.",
    "item__PG_CultistsMask__gameplay": "Становится невидимым на 6 секунд при использовании | ИНКОГНИТО: Скрывает ваши сообщения о добыче и местоположении от остального мира | +15% скорость атаки",
    "item__PG_DepthStrider__name": "Глубокий Странник",
    "item__PG_DepthStrider__lore": "На этом плаще есть слабое заклинание, облегчающее ходьбу по воде.",
    "item__PG_DepthStrider__gameplay": "+5% к скорости передвижения при ходьбе по воде.",
    "item__PG_GoldenGauntlets__name": "Золотые Наручи",
    "item__PG_GoldenGauntlets__lore": "Подходят для ограблений, эти наручи кажется, помогают найти дополнительное золото.",
    "item__PG_GoldenGauntlets__gameplay": "+10% к золоту из сундуков.",
    "item__PG_BeastVanquisherBoots__name": "Сапоги покорителя зверей",
    "item__PG_BeastVanquisherBoots__lore": "Носились теми, кто находился на передовой битвы. Эти сапоги обеспечивали быструю гибель врага.",
    "item__PG_BeastVanquisherBoots__gameplay": "+12% к скорости передвижения | +45 максимального здоровья | +25% к урону по НПК | -12% к урону по игрокам | -12% к дальности снарядов",
    "item__PG_CloakOfModestApproachability__gameplay": "Ваши снаряды летят на 6% дальше | Добавляет 30 секунд к вашему времени извлечения при использовании",
    "item__PG_CloakOfModestApproachability__lore": "Другие кажутся притянутыми к вам и не уверены, почему.",
    "item__PG_CloakOfModestApproachability__name": "Плащ Скромной Привлекательности",
    "item__PG_CloudedDarkness__gameplay": "+20% Эфирности | +10% урона Тьмой | +13% защиты от Тьмы",
    "item__PG_CloudedDarkness__lore": "Когда пришло время и дело было сделано, тьма окутала Малакая и воплотилась в этом кольце.",
    "item__PG_CloudedDarkness__name": "Темная Пелена",
    "item__PG_CompactedLimeGreenRing__gameplay": "Ваши снаряды летят на 5% дальше | +7% урона от яда",
    "item__PG_CompactedLimeGreenRing__lore": "Держалось преемником трона дракона.",
    "item__PG_CompactedLimeGreenRing__name": "Скомпонованное известью зеленое кольцо",
    "item__PG_CopperWristband__gameplay": "+6.5% Физической защиты",
    "item__PG_CopperWristband__lore": "Смешно большое кольцо, которое лучше подходит в качестве браслета... Можно только представить, какого размера было существо, которое носило это как кольцо.",
    "item__PG_CopperWristband__name": "Медный Браслет",
    "item__PG_CoralReef__gameplay": "+8,5% к скорости передвижения при ходьбе по воде | Извлечение замедлено на 10%",
    "item__PG_CoralReef__lore": "Создан в подарок русалочьей принцессе.",
    "item__PG_CoralReef__name": "Коралловый риф",
    "item__PG_CorruptedTearRing__gameplay": "Становитесь невидимым на 5 секунд при использовании | +7% Поглощение всего наносимого вами урона игрокам | -7% Защита от Хаоса | +9% Хаотический урон | +9% Урон молнией",
    "item__PG_CorruptedTearRing__lore": "Прежде чем коррупция овладела им, была пролита одна слеза... \"Ч-что я стал?!\"",
    "item__PG_CorruptedTearRing__name": "Искаженное Слезное Кольцо",
    "item__PG_CursedEternityRing__gameplay": "Даёт 5 секунд +30% скорости передвижения при использовании | ВОЗРОЖДАЕТСЯ с 48% Здоровья, когда вы умираете (не суммируется с другими предметами возрождения) | +12% Золота из сундуков | +8,5% Похищение Жизни от всего урона, нанесенного игрокам | Ваше здоровье уменьшается постоянно",
    "item__PG_CursedEternityRing__lore": "Символ вечного проклятия. Он привязывает своего носителя, проклиная его навсегда, но даруя ему великую силу.",
    "item__PG_CursedEternityRing__name": "Проклятое Кольцо Вечности",
    "item__PG_CursedSignet__gameplay": "+20% Поглощение здоровья из всего урона, который вы наносите игрокам",
    "item__PG_CursedSignet__lore": "Из этого кольца исходит зловещая энергия, приносящая несчастье всем, кто его носит.",
    "item__PG_CursedSignet__name": "Проклятое Печати",
    "item__PG_DeathwhisperWrap__gameplay": "+13% Урон по НИП | +13% Защита от Хаоса | +10% Токсический урон",
    "item__PG_DeathwhisperWrap__lore": "Когда клинок пронзил его сердце и все погрузилось во тьму, прозвучал слабый шепот: 'Еще не пришло время для меня, чтобы взять твою душу'.",
    "item__PG_DeathwhisperWrap__name": "Обертка Смертного Шепота",
    "item__PG_DreadmorneLoop__gameplay": "Даёт 3 секунды +30% скорости передвижения при использовании (5 зарядов) | -5% скорости атаки | -6,5% защиты от Святого урона | +10% урона Хаоса",
    "item__PG_DreadmorneLoop__lore": "Это кольцо внушает страх в сердцах врагов, наводя ужас и вызывая трепет.",
    "item__PG_DreadmorneLoop__name": "Петля Мрачной Долины",
    "item__PG_DreamcasterDrapery__gameplay": "+6.5% урона НИП | +6.5% урона Хаосом",
    "item__PG_DreamcasterDrapery__lore": "Рожденный из кошмаров, говорят, что этот облачный плащ управляет подсознанием.",
    "item__PG_DreamcasterDrapery__name": "Облачение Сновидцев",
    "item__PG_EbonfireRobelet__gameplay": "Ваши снаряды летят на 6% дальше",
    "item__PG_EbonfireRobelet__lore": "Окутывает своего владельца аурой тлеющих теней, предоставляя защиту от элементарных вредителей.",
    "item__PG_EbonfireRobelet__name": "Полурукав из Эбонитового Огня",
    "item__PG_ElvenGildedGuardian__gameplay": "Извлекайте на 5% быстрее",
    "item__PG_ElvenGildedGuardian__lore": "Красиво изготовленный золотой пояс, украшенный плющом, который повышает проворность владельца и отталкивает противников эльфийской изящностью.",
    "item__PG_ElvenGildedGuardian__name": "Эльфийский Позолоченный Страж",
    "item__PG_EmbergraspRing__gameplay": "+10 Максимального здоровья",
    "item__PG_EmbergraspRing__lore": "Пламя танцует и мерцает при вашем касании, жгучих ваших врагов.",
    "item__PG_EmbergraspRing__name": "Кольцо Пламенного Схвата",
    "item__PG_EnchantedElixirNecklace__gameplay": "Добавляет 60 секунд к таймеру извлечения при использовании | ВОСКРЕШЕНИЕ с 60% HP при смерти (не суммируется с другими предметами воскрешения)",
    "item__PG_EnchantedElixirNecklace__lore": "Ожерелье, содержащее флакон с волшебным эликсиром, усиливающим магическую силу.",
    "item__PG_EnchantedElixirNecklace__name": "Ожерелье Волшебного Эликсира",
    "item__PG_EnigmaLoop__gameplay": "ИНКОГНИТО: Скрывает ваш добычу и сообщения о местоположении от остального мира | +5% урона Хаосом",
    "item__PG_EnigmaLoop__lore": "Загадочное кольцо, запутывающее врагов, искажая реальность в вашу пользу.",
    "item__PG_EnigmaLoop__name": "Кольцо Загадки",
    "item__PG_EnigmaticShadowweaveRing__gameplay": "Исцеляет 20HP при использовании (1 заряд) | +5,5% урона по игрокам | -9% урона по НПК",
    "item__PG_EnigmaticShadowweaveRing__lore": "Пропитанное загадочными очарованиями, это кольцо сплетает вместе нити тьмы.",
    "item__PG_EnigmaticShadowweaveRing__name": "Загадочное Кольцо Теней",
    "item__PG_EssenceConduit__gameplay": "+9% Урон по НИПам | -4% Урон по игрокам",
    "item__PG_EssenceConduit__lore": "Дарует силу тем, кто заботится о природе.",
    "item__PG_EssenceConduit__name": "Кондуктор Сущности",
    "item__PG_EverlastingSpiritCircle__gameplay": "Даёт 3 секунды +25% скорости передвижения при использовании (2 заряда) | +10% Золота из сундуков",
    "item__PG_EverlastingSpiritCircle__lore": "Созданное древними заклинаниями, это кольцо действует как проводник духовной энергии.",
    "item__PG_EverlastingSpiritCircle__name": "Вечный Круг Духа",
    "item__PG_FrostFireRing__gameplay": "+7% Урон Огнем | +8,5% Защита от Холода | -6,5% Защита от Огня",
    "item__PG_FrostFireRing__lore": "Кольцо, украшенное элементарными камнями, которое может наделять атаки дополнительной силой - \"Cause you're hot then you're cold\"",
    "item__PG_FrostFireRing__name": "Кольцо Ледяного Пламени",
    "item__PG_FrostbiteLoop__gameplay": "+6% Скорость передвижения",
    "item__PG_FrostbiteLoop__lore": "Вокруг этого кольца царит холодный воздух, замораживающий сердца врагов при касании.",
    "item__PG_FrostbiteLoop__name": "Петля Морозного Укуса",
    "item__PG_FrostfireShardPendant__gameplay": "Получайте на 10% меньше урона во время извлечения | +8% Урон игрокам",
    "item__PG_FrostfireShardPendant__lore": "Подвеска, в которой содержится осколок, пропитанный и ледяными и огненными энергиями.",
    "item__PG_FrostfireShardPendant__name": "Подвеска Ледяного Пламени",
    "item__PG_GineshsBottleOpener__gameplay": "Ваше здоровье постоянно уменьшается | +12% Урон всем видам урона | +8.5% Скорость передвижения | Ваши снаряды летят на 8.5% дальше",
    "item__PG_GineshsBottleOpener__lore": "Кольцо, открывающее как причину, так и решение всех проблем жизни.",
    "item__PG_GineshsBottleOpener__name": "Открывалка для бутылок Гинеша",
    "item__PG_GirdleOfTheGoldenNecromancer__gameplay": "Исцеляет 10HP при использовании | +6% Скорость передвижения",
    "item__PG_GirdleOfTheGoldenNecromancer__lore": "Пышный золотом покрытый пояс, наделяющий своего владельца позолоченной аурой некромантической защиты, отталкивающей травмы.",
    "item__PG_GirdleOfTheGoldenNecromancer__name": "Пояс Золотого Некроманта",
    "item__PG_GoldPlatedCape__gameplay": "+4% Урон игрокам",
    "item__PG_GoldPlatedCape__lore": "Почему-то считается доспехом",
    "item__PG_GoldPlatedCape__name": "Золотой Плащ",
    "item__PG_GolemsGrasp__gameplay": "+30 максимального здоровья | -6% скорость передвижения | -6% скорость передвижения при ходьбе по воде",
    "item__PG_GolemsGrasp__lore": "Сила камня пронизывает это кольцо, превращая вашу кожу в броню.",
    "item__PG_GolemsGrasp__name": "Хватка Голема",
    "item__PG_GuardiansChainmailCollar__gameplay": "Исцеляет 15HP при использовании (3 раза) | -5% Скорость атаки",
    "item__PG_GuardiansChainmailCollar__lore": "Прочный воротник из переплетенных кольчуг, обеспечивающий надежную защиту.",
    "item__PG_GuardiansChainmailCollar__name": "Воротник Цепной Кольчуги Хранителя",
    "item__PG_HeirloomCloak__gameplay": "Предоставляет 2 секунды увеличения скорости передвижения на 20% при использовании (3 раза).",
    "item__PG_HeirloomCloak__lore": "Простой плащ, передаваемый в вашей семье из поколения в поколение.",
    "item__PG_HeirloomCloak__name": "Наследный Плащ",
    "item__PG_IceQueensEarrings__gameplay": "Предоставляет 5 секунд +20% скорости передвижения при использовании | +4% Урон игрокам | +6.5% Защита от Мороза | +5% Урон Морозом",
    "item__PG_IceQueensEarrings__lore": "Серьги, достойные Снежной Королевы. Холодные на ощупь.",
    "item__PG_IceQueensEarrings__name": "Серьги Ледяной Королевы",
    "item__PG_InfiniteLoop__gameplay": "Добавляет 45 секунд к таймеру извлечения при использовании | -10 Здоровья максимума | Ваше здоровье постоянно восстанавливается",
    "item__PG_InfiniteLoop__lore": "Способен преобразовывать жизненную энергию в ману и ману в жизненную энергию.",
    "item__PG_InfiniteLoop__name": "Бесконечное Замкнутое Кольцо",
    "item__PG_IronFoxLocket__gameplay": "ОСМОТР: Раскрывает информацию о ваших противниках | +13% защита от ВСЕХ видов урона | +30 максимального здоровья",    
    "item__PG_IronFoxLocket__lore": "Легенды рассказывают о лисе, облаченной в железную броню, известной своей способностью обмануть даже самых хитрых охотников.",
    "item__PG_IronFoxLocket__name": "Железный Замочек Лисы",
    "item__PG_JaysesRing__gameplay": "+9,5% к урону по игрокам | -9% к урону по НПК | +15% к Эфиру | +12% к урону молнией | +12% к урону огнем",
    "item__PG_JaysesRing__lore": "Кольцо знаменитого мага Джейса.",
    "item__PG_JaysesRing__name": "Кольцо Джейса",
    "item__PG_LittleCompactedLimeGreenRing__gameplay": "Ваши снаряды летят на 6% дальше | +13% Защиты от Токсинов.",
    "item__PG_LittleCompactedLimeGreenRing__lore": "Когда-то принадлежало роскошному драконьему роду.",
    "item__PG_LittleCompactedLimeGreenRing__name": "Маленькое Сжатое Лаймово-Зеленое Кольцо",
    "item__PG_MementoMoriLocket__gameplay": "-6.5% Защита от Хаоса | +8.5% Защита от теней",
    "item__PG_MementoMoriLocket__lore": "Пугающий медальон, напоминающий о смертности и укрепляющий решимость.",
    "item__PG_MementoMoriLocket__name": "Медальон Memento Mori",
    "item__PG_MidnightsVeilBand__gameplay": "Становится невидимым на 5 секунд при использовании (2 заряда)",
    "item__PG_MidnightsVeilBand__lore": "Носимый с тенями, этот плащ усиливает скрытность его владельца.",
    "item__PG_MidnightsVeilBand__name": "Полуночный Вуаль",
    "item__PG_MunchkinsRing__gameplay": "+7% Скорость передвижения | -10 Максимальное Здоровье",
    "item__PG_MunchkinsRing__lore": "Его предыдущий владелец имел короткие ножки, но они никогда его не замедляли.",
    "item__PG_MunchkinsRing__name": "Кольцо Манчкина",
    "item__PG_NaturesAureateBarrier__gameplay": "Добавляет 30 секунд к вашему таймеру извлечения при использовании (2 раза) | -5,5% урона по игрокам | INCOGNITO: Скрывает ваши сообщения о добыче и местоположении от остального мира.",
    "item__PG_NaturesAureateBarrier__lore": "Прочный, связанный золотом пояс, пропитанный стойкостью древних деревьев, дарующий владельцу щит блестящей природной силы.",
    "item__PG_NaturesAureateBarrier__name": "Природный Золотистый Барьер",
    "item__PG_NecroticGraspTalisman__gameplay": "Дает 5 секунд +30% скорость передвижения при использовании | Ваше здоровье постоянно падает | +12% Урон Тенями",
    "item__PG_NecroticGraspTalisman__lore": "Талисман, пульсирующий некромантической силой, подчиняющий силы нежити.",
    "item__PG_NecroticGraspTalisman__name": "Талисман Некротического Ухвата",
    "item__PG_NecroticSignet__gameplay": "Добавляет 45 секунд к вашему таймеру извлечения при использовании | +13% Защита от Святости",
    "item__PG_NecroticSignet__lore": "Макабрный символ смерти и разложения, он истощает жизненные силы ваших врагов.",
    "item__PG_NecroticSignet__name": "Некротический Гамбезон",
    "item__PG_PendantOfAncientWisdom__gameplay": "-7% Урон игрокам | +8.75% Урон НИП | +8.5% Аркана Защита | +7% Аркана Урон",
    "item__PG_PendantOfAncientWisdom__lore": "Древний кулон, передающий глубокие знания тому, кто его носит.",
    "item__PG_PendantOfAncientWisdom__name": "Кулон Древней Мудрости",
    "item__PG_PhantomBand__gameplay": "+30% Эфирное",
    "item__PG_PhantomBand__lore": "Ношение этого эфирного кольца позволяет вам незаметно ходить среди живых.",
    "item__PG_PhantomBand__name": "Фантомное кольцо",
    "item__PG_PhoenixFeatherTorque__gameplay": "ВОЗРОЖДАЕТСЯ с 60% Здоровья, когда вы умираете (не суммируется с другими предметами возрождения) | +60 максимального здоровья | +19,5% защита от Огня | +19,5% защита от Мороза | -19,5% защита от Токсинов | -15% скорость атаки",
    "item__PG_PhoenixFeatherTorque__lore": "Величественный торк, украшенный пером феникса, дарующий возрождение и обновление.",
    "item__PG_PhoenixFeatherTorque__name": "Торк из пера феникса",
    "item__PG_PhoenixsRespite__gameplay": "Становитесь невидимыми на 3 секунд при использовании | +6.5% Огненная защита | +5% Огненный урон",
    "item__PG_PhoenixsRespite__lore": "Те, кто добровольно бросали себя на пламя, возрождались с большей силой, чем прежде.",
    "item__PG_PhoenixsRespite__name": "Отдых Феникса",
    "item__PG_PurpletratorsRing__gameplay": "+3% Поглощение от всего урона, который вы наносите игрокам",
    "item__PG_PurpletratorsRing__lore": "Те, кто осмеливался смеяться над названием этого кольца, часто погибали от его владельца.",
    "item__PG_PurpletratorsRing__name": "Фиолетовое кольцо",
    "item__PG_QuintsFishingGarb__gameplay": "+5% Скорость атаки",
    "item__PG_QuintsFishingGarb__lore": "Кольцо, создающее бесконечное количество рыбацких червей, часто непреднамеренно распространяемых между участниками группы.",
    "item__PG_QuintsFishingGarb__name": "Рыбацкий наряд Квинта",
    "item__PG_ResilientHeartstone__gameplay": "Исцеляет 10HP при использовании | +5% Золота из сундуков | Получаете на 5% меньше урона во время извлечения | ОСМОТР: Раскрывает информацию о ваших оппонентах",
    "item__PG_ResilientHeartstone__lore": "Сердцевидный драгоценный камень, внушающий несгибаемую силу владельцу.",
    "item__PG_ResilientHeartstone__name": "Сопротивляющийся камень сердца",
    "item__PG_RingOfConstellations__gameplay": "Ваши снаряды летят на 6% дальше",
    "item__PG_RingOfConstellations__lore": "Ходят слухи, что этим обладал воин ночи, и кольцо предоставляло ему руководство во тьме ночи.",
    "item__PG_RingOfConstellations__name": "Кольцо созвездий",
    "item__PG_RingOfTheOneHandedWarrior__gameplay": "-10 максимального здоровья | +12% физического урона | +12% урона кровотечением",
    "item__PG_RingOfTheOneHandedWarrior__lore": "Вам нужна только одна рука, чтобы носить кольцо.",
    "item__PG_RingOfTheOneHandedWarrior__name": "Кольцо одноручного воина",
    "item__PG_RingOfViolentViolet__gameplay": "+30 максимального здоровья | +17% Урон всем типам урона | -15% Защита от всех типов урона",
    "item__PG_RingOfViolentViolet__lore": "Вы чувствуете внезапное всплеск силы и жажды крови, когда надеваете это кольцо.",
    "item__PG_RingOfViolentViolet__name": "Кольцо яростного фиолетового",
    "item__PG_RuneboundRing__gameplay": "+5% Скорость передвижения при ходьбе в воде | +7% Аркана урон",
    "item__PG_RuneboundRing__lore": "Выгравированное древними рунами, оно канализирует арканную энергию, даруя силу владельцу.",
    "item__PG_RuneboundRing__name": "Кольцо рунной связи",
    "item__PG_SerpentsCoilChoker__gameplay": "ИНСПЕКЦИЯ: Раскрывает информацию о ваших противниках",
    "item__PG_SerpentsCoilChoker__lore": "Обвиваясь вокруг шеи, оно наделяет владельца змеиной грацией и хитростью.",
    "item__PG_SerpentsCoilChoker__name": "Ожерелье Серпента",
    "item__PG_SerpentsRing__gameplay": "+6.5% защита от яда",
    "item__PG_SerpentsRing__lore": "Кольцо, изготовленное из чешуи змеи и околдованное для защиты владельца от яда.",
    "item__PG_SerpentsRing__name": "Кольцо Змеи",
    "item__PG_ShieldbearersAmulet__gameplay": "Исцеляет 10HP при использовании (1 заряд) | +6,5% урона по НПК",
    "item__PG_ShieldbearersAmulet__lore": "Украшенный гербовым гербом, он укрепляет защиту владельца.",
    "item__PG_ShieldbearersAmulet__name": "Амулет Щитоносца",
    "item__PG_SoulBindingRing__gameplay": "Воскресните с 40% здоровья после смерти | +10% Священный урон",
    "item__PG_SoulBindingRing__lore": "Это кольцо имеет три камня. Один прозрачный и просвечивающий. В других двух находится странная золотистая кружащаяся мгла.",
    "item__PG_SoulBindingRing__name": "Кольцо Связывания Души",
    "item__PG_SoulforgeRing__gameplay": "ИЗВЛЕЧЕНИЕ при использовании, независимо от вашего местоположения | Извлечение замедлено на 10% | Этот предмет уничтожается при полном использовании",
    "item__PG_SoulforgeRing__lore": "Пропитанная сущностью мученых душ, это кольцо повышает вашу физическую и мистическую силу.",
    "item__PG_SoulforgeRing__name": "Кольцо Кузни Душ",
    "item__PG_SoulshackleVeil__gameplay": "Даёт 3 секунды +25% скорости передвижения при использовании (3 заряда) | +8,5% К эфиру | -5% скорости передвижения",
    "item__PG_SoulshackleVeil__lore": "Связанный темной магией, он запутывает души павших, связывая вас с миром духов.",
    "item__PG_SoulshackleVeil__name": "Вуаль Душевных Оков",
    "item__PG_SoulstoneReliquary__gameplay": "Становится невидимым на 4 секунды при использовании | Получаете на 5% меньше урона во время извлечения | ВОЗРОЖДАЕТСЯ с 20% Здоровья, когда вы умираете (не суммируется с другими предметами возрождения) | +5% Похищение Жизни от всего урона, который вы наносите игрокам",
    "item__PG_SoulstoneReliquary__lore": "Реликвия на ожерелье, позволяющая владельцу управлять пойманными душами.",
    "item__PG_SoulstoneReliquary__name": "Реликварий Душекамня",
    "item__PG_StormweaverCloak__gameplay": "ИНКОГНИТО: Скрывает ваш добычу и сообщения о местонахождении от остального мира",
    "item__PG_StormweaverCloak__lore": "Овладевайте яростью грозовых бурь, повелевая гневом небес.",
    "item__PG_StormweaverCloak__name": "Плащ Штормовика",
    "item__PG_SummonersSign__gameplay": "+10 Максимальное здоровье",
    "item__PG_SummonersSign__lore": "Используется призывателями для сбора маны с земли.",
    "item__PG_SummonersSign__name": "Знак Призывателя",
    "item__PG_TalismanOfVeiledShadows__gameplay": "Становитесь невидимым на 3 секунд при использовании | -5% скорость передвижения | +8.5% защита от хаоса | +8.5% Защита от Теней",
    "item__PG_TalismanOfVeiledShadows__lore": "Мистический талисман, покрывающий обладателя эфемерной тьмой.",
    "item__PG_TalismanOfVeiledShadows__name": "Талисман Закрытых Теней",
    "item__PG_TantrumsLuckyLoop__gameplay": "+3% скорости передвижения",
    "item__PG_TantrumsLuckyLoop__lore": "Кольцо, которое делает вас быстрым как гончую собаку, но таким же ленивым.",
    "item__PG_TantrumsLuckyLoop__name": "Счастливая Петля Тантрума",
    "item__PG_VampiricSeal__gameplay": "Исцеляет 7HP при использовании (2 заряда) | +3% Похищение Жизни от всего урона, который вы наносите игрокам",
    "item__PG_VampiricSeal__lore": "Питаясь жизненной силой ваших врагов, оно дарует жизненные силы с каждым ударом.",
    "item__PG_VampiricSeal__name": "Вампирское Одеяние",
    "item__PG_VeilstormCirclet__gameplay": "Исцеляет на 30 ед. здоровья при использовании | -10% скорость атаки | +12% Природный урон",
    "item__PG_VeilstormCirclet__lore": "Хранит в себе мощь неистового шторма. Предоставляет обладателю контроль над ветром и молнией.",
    "item__PG_VeilstormCirclet__name": "Вуальштормовая Мантия",
    "item__PG_VenomousBite__gameplay": "Добавляет 30 секунд к вашему времени извлечения при использовании | Ваше здоровье постоянно падает | +10% Токсический урон",
    "item__PG_VenomousBite__lore": "Доспехи, носимые древними чумными врачами",
    "item__PG_VenomousBite__name": "Ядовитый Укус",
    "item__PG_WindspeakersRing__gameplay": "INSPECTION: Раскрывает информацию о ваших оппонентах | +7% Природный урон",
    "item__PG_WindspeakersRing__lore": "Когда-то принадлежало могущественному магу, который был преследован по всему миру своей собственной тенью, говорят, что это кольцо обладает способностью контролировать ветры.",
    "item__PG_WindspeakersRing__name": "Кольцо Ветроговорца",
    "item__PG_Wraithband__gameplay": "+8,5% к Эфиру | 7% Святой Урон | -10 Максимального Здоровья",
    "item__PG_Wraithband__lore": "Фантомная энергия пронизывает это кольцо, даруя спектральные силы.",
    "item__PG_Wraithband__name": "Пояс Вурдалака",
    "item__VT_AbyssalShard__gameplay": "",
    "item__VT_AbyssalShard__lore": "Черный кусок камня, добытый из подземного мира.",
    "item__VT_AbyssalShard__name": "Абиссальный осколок",
    "item__VT_Amber__gameplay": "",
    "item__VT_Amber__lore": "Этот камень имеет глубокий оранжевый оттенок.",
    "item__VT_Amber__name": "Янтарь",
    "item__VT_BardsDiary__gameplay": "",
    "item__VT_BardsDiary__lore": "На этой книге запечатлены стихи и ноты музыки.",
    "item__VT_BardsDiary__name": "Песенник Барда",
    "item__VT_BrokenIronBand__gameplay": "",
    "item__VT_BrokenIronBand__lore": "Простое железное кольцо, потерявшее свой блеск.",
    "item__VT_BrokenIronBand__name": "Сломанное железное кольцо",
    "item__VT_BrokenSword__gameplay": "",
    "item__VT_BrokenSword__lore": "Этот меч видел лучшие дни.",
    "item__VT_BrokenSword__name": "Сломанный железный меч",
    "item__VT_Cabbage__gameplay": "",
    "item__VT_Cabbage__lore": "Ты капуста, ты всегда будешь капустой.",
    "item__VT_Cabbage__name": "Капуста",
    "item__VT_ChunkOfIron__gameplay": "",
    "item__VT_ChunkOfIron__lore": "Небольшой кусок железа, кузнец мог бы что-то сделать с этим.",
    "item__VT_ChunkOfIron__name": "Кусок железа",
    "item__VT_CubicZirconia__gameplay": "",
    "item__VT_CubicZirconia__lore": "Квадратный осколок циркония",
    "item__VT_CubicZirconia__name": "Кубический цирконий",
    "item__VT_Diamond__gameplay": "Редкий коллекционер, возможно, проявит интерес к этому.",
    "item__VT_Diamond__lore": "Драгоценный самоцвет.",
    "item__VT_Diamond__name": "Алмаз",
    "item__VT_DukesDiary__gameplay": "",
    "item__VT_DukesDiary__lore": "Заметки некогда могущественного герцога.",
    "item__VT_DukesDiary__name": "Дневник Герцога Эрта",
    "item__VT_EmeraldDust__gameplay": "",
    "item__VT_EmeraldDust__lore": "Изумруд, превращенный в мелкую пыль.",
    "item__VT_EmeraldDust__name": "Изумрудная пыль",
    "item__VT_Emerald__gameplay": "",
    "item__VT_Emerald__lore": "Кусок изумруда.",
    "item__VT_Emerald__name": "Изумруд",
    "item__VT_EverlastingCandle__gameplay": "",
    "item__VT_EverlastingCandle__lore": "Эта свеча таинственным образом будет гореть вечно.",
    "item__VT_EverlastingCandle__name": "Вечная свеча",
    "item__VT_Eyeball__gameplay": "",
    "item__VT_Eyeball__lore": "Глаз убитого зверя.",
    "item__VT_Eyeball__name": "Глазное яблоко",
    "item__VT_FangOfTheBloodMoon__gameplay": "",
    "item__VT_FangOfTheBloodMoon__lore": "Этот кровавый клык принадлежит существу, охотящемуся только тогда, когда небо кровоточит.",
    "item__VT_FangOfTheBloodMoon__name": "Клык Кровавой Луны",
    "item__VT_Fossil__gameplay": "",
    "item__VT_Fossil__lore": "Останки древнего существа навсегда впечатлены в этой скале.",
    "item__VT_Fossil__name": "Ископаемое",
    "item__VT_GhostPlasma__gameplay": "",
    "item__VT_GhostPlasma__lore": "Эта вязкая жидкость светится эфирной энергией.",
    "item__VT_GhostPlasma__name": "Призрачная плазма",
    "item__VT_GoblinCloth__gameplay": "",
    "item__VT_GoblinCloth__lore": "Эта ткань очень грязная и на ней есть кровь.",
    "item__VT_GoblinCloth__name": "Гоблинская повязка",
    "item__VT_GoldNugget__gameplay": "",
    "item__VT_GoldNugget__lore": "Небольшой кусок золота. Возможно, стоит много.",
    "item__VT_GoldNugget__name": "Золотая самородная",
    "item__VT_OgreEntrails__gameplay": "",
    "item__VT_OgreEntrails__lore": "Внутренности огра.",
    "item__VT_OgreEntrails__name": "Внутренности огра",
    "item__VT_OnyxGem__gameplay": "Этим может заинтересоваться эксперт по драгоценным камням.",
    "item__VT_OnyxGem__lore": "Редкий драгоценный камень из оникса.",
    "item__VT_OnyxGem__name": "Ониксовый камень",
    "item__VT_PileOfAsh__gameplay": "",
    "item__VT_PileOfAsh__lore": "Просто небольшая кучка пепла.",
    "item__VT_PileOfAsh__name": "Кучка пепла",
    "item__VT_Potato__gameplay": "Вари, толчи, клади их в похлебку.",
    "item__VT_Potato__lore": "Вероятно, вы никогда о них не слышали.",
    "item__VT_Potato__name": "Картофель",
    "item__VT_QuartzRing__gameplay": "",
    "item__VT_QuartzRing__lore": "Вставленный полированный кусок кварца. Не стоит много, но хоть что-то.",
    "item__VT_QuartzRing__name": "Кварцевое кольцо",
    "item__VT_RatFur__gameplay": "",
    "item__VT_RatFur__lore": "Отвратительный комок шерсти, возможно, полный болезней.",
    "item__VT_RatFur__name": "Комок шерсти крысы",
    "item__VT_RedMushroom__gameplay": "",
    "item__VT_RedMushroom__lore": "Этот гриб обычно сушат и превращают в порошок, который помогает при болезнях.",
    "item__VT_RedMushroom__name": "Красный гриб",
    "item__VT_RingOfNearPerfection__gameplay": "",
    "item__VT_RingOfNearPerfection__lore": "Было бы лучше, если бы у него была магия.",
    "item__VT_RingOfNearPerfection__name": "Кольцо почти идеальности",
    "item__VT_RubyDust__gameplay": "",
    "item__VT_RubyDust__lore": "Рубин, размолотый в мелкую пыль.",
    "item__VT_RubyDust__name": "Пыль рубина",
    "item__VT_Ruby__gameplay": "",
    "item__VT_Ruby__lore": "Кусок глубоко красного рубина.",
    "item__VT_Ruby__name": "Рубин",
    "item__VT_SapphireDust__gameplay": "",
    "item__VT_SapphireDust__lore": "Сапфир, превращенный в мелкую пыль.",
    "item__VT_SapphireDust__name": "Пыль сапфира",
    "item__VT_Sapphire__gameplay": "",
    "item__VT_Sapphire__lore": "Глубоко синий кусок сапфира.",
    "item__VT_Sapphire__name": "Сапфир",
    "item__VT_SkeletonFemur__gameplay": "",
    "item__VT_SkeletonFemur__lore": "Предполагаю, что где-то есть скелет с одной ногой.",
    "item__VT_SkeletonFemur__name": "Бедро скелета",
    "item__VT_Skull__gameplay": "",
    "item__VT_Skull__lore": "Череп. Вероятно, от одного из этих бродячих скелетов.",
    "item__VT_Skull__name": "Череп",
    "item__VT_StellarWeaveRing__gameplay": "",
    "item__VT_StellarWeaveRing__lore": "Кольцо из сплетенных ветвей. Оно ничем не примечательно.",
    "item__VT_StellarWeaveRing__name": "Кольцо звездной плетки",
    "item__VT_TreantBranch__gameplay": "",
    "item__VT_TreantBranch__lore": "Этот кусок дерева немного шевелится. Странно.",
    "item__VT_TreantBranch__name": "Ветка тренты",
    "item__VT_WellWornRing__gameplay": "",
    "item__VT_WellWornRing__lore": "На поверхности этого простого кольца видны несколько потертостей и царапин.",
    "item__VT_WellWornRing__name": "Изношенное кольцо",
    "item__VT_WhispersOfTheVoid__gameplay": "",
    "item__VT_WhispersOfTheVoid__lore": "Безумие распространено среди тех, кто слышит его голос. И кто не слышит его в этот кризисный период?",
    "item__VT_WhispersOfTheVoid__name": "Шепоты Пустоты. Том 1",
    "item__W_ApprenticesStaff__gameplay": "Устанавливает видимую ловушку возле ваших ног, наносящую физический урон.",
    "item__W_ApprenticesStaff__lore": "Посох начинающего волшебника, часто используемый для тренировок.",
    "item__W_ApprenticesStaff__name": "Посох ученика",
    "item__W_ArchmagesSidekick__gameplay": "Устанавливает видимую ловушку возле ваших ног, наносящую урон арканой магией.",
    "item__W_ArchmagesSidekick__lore": "Мало что известно об этом посохе, кроме того, что его сотворил Архимаг Арканного Ордена.",
    "item__W_ArchmagesSidekick__name": "Помощник Архимага",
    "item__W_BeesOnAStick__gameplay": "Размещает видимую ловушку возле ваших ног, наносящую природный урон.",
    "item__W_BeesOnAStick__lore": "Посох, украшенный ульем, жужжащим от силы роя пчел и наносящим жгучие заклинания противникам.",
    "item__W_BeesOnAStick__name": "Пчелы на палочке",
    "item__W_BlackDahliaWand__gameplay": "Выстреливает теневыми снарядами.",
    "item__W_BlackDahliaWand__lore": "Его темная природа порабощает сердца тех, кто осмеливается держать его в руках.",
    "item__W_BlackDahliaWand__name": "Посох Чёрной Далии",
    "item__W_BlazeRod__gameplay": "Выстреливает огненными шарами!",
    "item__W_BlazeRod__lore": "Найденный в глубинах Нижнего мира, этот жезл наделен душой Блейза.",
    "item__W_BlazeRod__name": "Жезл Блейза",
    "item__W_Bloodrider__gameplay": "Выстреливает стрелами, наносящими сильный кровоточивый урон.",
    "item__W_Bloodrider__lore": "Изготовленный из костей павших воинов, этот зловещий лук поглощает жизнь с каждой стрелой, оставляя за собой след карминного отчаяния.",
    "item__W_Bloodrider__name": "Кровавый всадник",
    "item__W_BrokenDeathstick__gameplay": "Выстреливает хаотическими снарядами, наносящими умеренный урон.",
    "item__W_BrokenDeathstick__lore": "Был бы смертельным... если бы не был сломан.",
    "item__W_BrokenDeathstick__name": "Сломанный жезл смерти",
    "item__W_CandlelightStaff__gameplay": "Устанавливает видимую ловушку возле ваших ног, наносящую огненный урон.",
    "item__W_CandlelightStaff__lore": "Посох, излучающий мягкий свет, освещающий тьму.",
    "item__W_CandlelightStaff__name": "Посох Света",
    "item__W_CelestialBow__gameplay": "Небесный посланник наполнил эти стрелы холодом пустоты.",
    "item__W_CelestialBow__lore": "Дарованный древними богами, этот сияющий лук направляет божественные благословения, пронзая даже самую стойкую защиту.",
    "item__W_CelestialBow__name": "Небесный Лук",
    "item__W_CrudeCrossbow__gameplay": "Выстреливает базовыми болтами, наносящими физический урон.",
    "item__W_CrudeCrossbow__lore": "Грубый арбалет, изготовленный из обрезков дерева и веревки. Он не очень мощный, но сойдет.",
    "item__W_CrudeCrossbow__name": "Грубый Арбалет",
    "item__W_DarylsInstinct__gameplay": "Выстреливает мощными болтами, наносящими урон кровотечением.",
    "item__W_DarylsInstinct__lore": "Арбалет, использованный легендарным охотником Дэрилом.",
    "item__W_DarylsInstinct__name": "Инстинкт Дэрила",
    "item__W_DavidBowie__gameplay": "Выстреливает слабыми физическими снарядами.",
    "item__W_DavidBowie__lore": "Превращает вас в мгновенную звезду!",
    "item__W_DavidBowie__name": "Дэвид Боуи",
    "item__W_DemonsEye__gameplay": "Устанавливает видимую ловушку у ваших ног, наносящую теневой урон.",
    "item__W_DemonsEye__lore": "Этот посох призывает зловещие шепоты пустоты, позволяя владельцу проникать сквозь иллюзии и раскрывать скрытые правды, которые скрываются в тени.",
    "item__W_DemonsEye__name": "Глаз Демона",
    "item__W_DivineWind__gameplay": "Выстреливает мощными святыми стрелами.",
    "item__W_DivineWind__lore": "Выкованный в священных горных убежищах, этот эфирный лук приводит стрелы в движение с помощью потустороннего порыва, проникая сквозь препятствия и врагов.",
    "item__W_DivineWind__name": "Божественный Ветер",
    "item__W_DragonsBreath__gameplay": "Создает поток огня перед заклинателем.",
    "item__W_DragonsBreath__lore": "Я кастую... Огненный шар?",
    "item__W_DragonsBreath__name": "Дыхание Дракона",
    "item__W_EldritchBlasters__gameplay": "Быстро выстреливает теневыми болтами.",
    "item__W_EldritchBlasters__lore": "Двойные арбалеты, наполненные силой тьмы.",
    "item__W_EldritchBlasters__name": "Эльдрические Бластеры",
    "item__W_Emberstrike__gameplay": "Выстреливает мощными огненными стрелами.",
    "item__W_Emberstrike__lore": "Выкованный в сердце вулкана, этот обсидиановый лук выпускает палящие стрелы, оставляя за собой следы тлеющего опустошения.",
    "item__W_Emberstrike__name": "Пылающий Удар",
    "item__W_FairyBow__gameplay": "Выстреливает стрелами, наполненными феерической магией.",
    "item__W_FairyBow__lore": "Наполняет стрелы, выпущенные из этого лука, случайной мощной энергией.",
    "item__W_FairyBow__name": "Феерический Лук",
    "item__W_FairyStaff__gameplay": "Размещает видимую ловушку возле ваших ног, наносящую природный урон.",
    "item__W_FairyStaff__lore": "Принадлежал феерической королеве.",
    "item__W_FairyStaff__name": "Феерический Посох",
    "item__W_FathersBane__gameplay": "Быстро стреляет слабыми болтами с ядовитыми наконечниками.",
    "item__W_FathersBane__lore": "Использовался печальным сыном, которому не оставалось выбора, кроме как убить своего отца.",
    "item__W_FathersBane__name": "Погибель Отца",
    "item__W_FlamespitterStaff__gameplay": "Устанавливает видимую ловушку возле ваших ног, наносящую урон арканой магией.",
    "item__W_FlamespitterStaff__lore": "Яростные пламена вырываются из этого посоха.",
    "item__W_FlamespitterStaff__name": "Посох Огненного Вихря",
    "item__W_FrostedTips__gameplay": "Наносит урон морозом.",
    "item__W_FrostedTips__lore": "Святая вакханалия, Стромболи!",
    "item__W_FrostedTips__name": "Застывшие Наконечники",
    "item__W_GuardsCrossbow__gameplay": "Более простой и быстрый в использовании, чем обычный арбалет.",
    "item__W_GuardsCrossbow__lore": "Арбалет, используемый стражами Арканного Ордена.",
    "item__W_GuardsCrossbow__name": "Арбалет Стражей",
    "item__W_HibidyKibity__gameplay": "Выстреливает смертоносными хаотическими снарядами.",
    "item__W_HibidyKibity__lore": "Волшебная палочка, пропитанная темной энергией убийственного проклятия.",
    "item__W_HibidyKibity__name": "Хибиди Кибити",
    "item__W_IcewindWand__gameplay": "Создает поток замерзающих облаков перед заклинателем.",
    "item__W_IcewindWand__lore": "Способен создавать самую неприятную погоду.",
    "item__W_IcewindWand__name": "Посох Ледяного Ветра",
    "item__W_IronStaff__gameplay": "Устанавливает видимую ловушку возле ваших ног, наносящую физический урон.",
    "item__W_IronStaff__lore": "Посох из железа, используемый стражами Арканного Ордена.",
    "item__W_IronStaff__name": "Железный Посох",
    "item__W_KingBible__gameplay": "Запускает мощные святые библии в сторону ваших противников.",
    "item__W_KingBible__lore": "Почтенный, золоченый том, хранящий священные слова древних монархов, дарующих власть и формирующих судьбы.",
    "item__W_KingBible__name": "Королевская Библия",
    "item__W_Deathstick__gameplay": "Приносит смерть, выпуская быстрые короткодальностные снаряды, за счет замедления пользователя.",
    "item__W_Deathstick__lore": "Легенда гласит, что эта мощная оружия была уронена загадочной фигурой века назад.",
    "item__W_Deathstick__name": "Дэтстик",
    "item__W_BookOfTongues__gameplay": "Выстреливает самым могучим огнем из глубин ада. Имеет ОЧЕНЬ долгое время восстановления.",
    "item__W_BookOfTongues__lore": "На его страницах видно неразборчивое имя. Вы можете разглядеть только букву \"М\".",
    "item__W_BookOfTongues__name": "Книга Языков",
    "item__W_LichsStaff__gameplay": "Устанавливает видимую ловушку у ваших ног, наносящую теневой урон.",
    "item__W_LichsStaff__lore": "Используется темными некромантами, этот посох оснащен нечестивой силой нежити, принося смерть и разложение всему, что касается.",
    "item__W_LichsStaff__name": "Посох Лича",
    "item__W_FrostFlicker__gameplay": "Устанавливает видимую ловушку рядом с вашими ногами, наносящую урон морозом.",
    "item__W_FrostFlicker__lore": "Посох с возможностью замораживать воду в окружающей области. Слабая версия этого часто используется в праздничный сезон.",
    "item__W_FrostFlicker__name": "Мерцающий Мороз",
    "item__W_MagicalWand__gameplay": "Выстреливает магическими арканскими снарядами.",
    "item__W_MagicalWand__lore": "Во имя Луны, я накажу тебя!",
    "item__W_MagicalWand__name": "Магическая Палочка",
    "item__W_MidasStaff__gameplay": "Размещает видимую ловушку у ваших ног, наносящую урон молнией.",
    "item__W_MidasStaff__lore": "Посох легендарного Короля Мидаса.",
    "item__W_MidasStaff__name": "Посох Мидаса",
    "item__W_MoonShadow__gameplay": "Быстро стреляет теневыми стрелами.",
    "item__W_MoonShadow__lore": "Подаренный лунными духами, этот мистический лук стреляет стрелами, проникающими сквозь тени и поражающими с неожиданных углов.",
    "item__W_MoonShadow__name": "Лунная Тень",
    "item__W_NaturesReturn__gameplay": "Используйте силу леса, чтобы нанести большой урон природы вашим противникам.",
    "item__W_NaturesReturn__lore": "Том, украшенный листвой, канализующий силы природы для роста, исцеления и бурь.",
    "item__W_NaturesReturn__name": "Возвращение Природы",
    "item__W_NightStick__gameplay": "Выстреливает снарядами холоднее ночи.",
    "item__W_NightStick__lore": "Используется теми, кто стремится поддерживать мир.",
    "item__W_NightStick__name": "Ночной Дубинка",
    "item__W_PharaohsStaff__gameplay": "Размещает видимую ловушку у ваших ног, наносящую святой урон.",
    "item__W_PharaohsStaff__lore": "Извлечен из гробницы древнего фараона, этот посох, как говорят, использовался для призыва мертвых.",
    "item__W_PharaohsStaff__name": "Посох фараона",
    "item__W_PhoenixWing__gameplay": "Выстреливает стрелами, пропитанными пламенем павшего феникса.",
    "item__W_PhoenixWing__lore": "Изготовлен из перьев феникса, этот светящийся лук канализирует огненное возрождение, поджигая стрелы пылающими пламенем.",
    "item__W_PhoenixWing__name": "Крыло Феникса",
    "item__W_PoseidonsWand__gameplay": "Выстреливает рыбой.",
    "item__W_PoseidonsWand__lore": "РЫБААААААААА!",
    "item__W_PoseidonsWand__name": "Посох Посейдона",
    "item__W_QuincyBow__gameplay": "Выстреливает стрелами, пропитанными электрической энергией.",
    "item__W_QuincyBow__lore": "Стреляет стрелами, созданными из духовной энергии обладателя.",
    "item__W_QuincyBow__name": "Лук Куинси",
    "item__W_SilentShadow__gameplay": "Выстреливает стрелами, пропитанными теневой энергией.",
    "item__W_SilentShadow__lore": "\"Они пришли без предупреждения и застали нас врасплох. Мы сумели отразить их, но потеряли многих в процессе.\"",
    "item__W_SilentShadow__name": "Тихая Тень",
    "item__W_RangersGuillotine__gameplay": "Выстреливает отравленными стрелами.",
    "item__W_RangersGuillotine__lore": "Смертоносный и точный, этот жестокий лук проникает сквозь защиту, заполняя жертву отравленными стрелами.",
    "item__W_RangersGuillotine__name": "Гильотина Рейнджера",
    "item__W_ReapersEmbrace__gameplay": "Выстреливает мощными хаосными снарядами.",
    "item__W_ReapersEmbrace__lore": "Призрачный лук, который высасывает жизнь с каждой стрелой, запутывая врагов в ледяном объятии Жнеца.",
    "item__W_ReapersEmbrace__name": "Объятия Жнеца",
    "item__W_RelicWand__gameplay": "Создает физические снаряды, наносящие большой урон.",
    "item__W_RelicWand__lore": "Канализует сущность потерянных цивилизаций и забытой магии.",
    "item__W_RelicWand__name": "Реликвийная Палочка",
    "item__W_ReliqueryCrossbow__gameplay": "Выстреливает хаосными снарядами, наносящими большой урон.",
    "item__W_ReliqueryCrossbow__lore": "Арбалет, используемый священниками Вампирской Реликварии.",
    "item__W_ReliqueryCrossbow__name": "Арбалет Реликвария",
    "item__W_SamsStirringStick__gameplay": "Устанавливает видимую ловушку возле ваших ног, наносящую урон арканой магией.",
    "item__W_SamsStirringStick__lore": "Легендарная варежка шеф-повара, наделенная силой (кулинарных) элементов.",
    "item__W_SamsStirringStick__name": "Варежка Сэма",
    "item__W_SlimeStaff__gameplay": "Размещает видимую ловушку возле ваших ног, наносящую природный урон.",
    "item__W_SlimeStaff__lore": "Посох, пульсирующий сущностью слизней, позволяет владельцу призывать и командовать этими желеобразными существами.",
    "item__W_SlimeStaff__name": "Посох Слизней",
    "item__W_SpellbindersTome__gameplay": "Излучает арканную магическую энергию в сторону противников.",
    "item__W_SpellbindersTome__lore": "Мистическая кожаная книга, запутывающая разум своими сложными заклинаниями чародейства.",
    "item__W_SpellbindersTome__name": "Том Чародея",
    "item__W_SpiraledWand__gameplay": "Выстреливает магической энергией в виде магических снарядов.",
    "item__W_SpiraledWand__lore": "На самом деле, это всего лишь более изысканный палочка.",
    "item__W_SpiraledWand__name": "Витая Палочка",
    "item__W_StaffOfTheSnake__gameplay": "Устанавливает видимую ловушку возле ваших ног, наносящую токсический урон.",
    "item__W_StaffOfTheSnake__lore": "Посох, украшенный головой кобры, высеченной из изумруда. Говорят, что он способен плевать ядом на врагов.",
    "item__W_StaffOfTheSnake__name": "Посох Змеи",
    "item__W_StarShot__gameplay": "Выстреливает сильными арканными стрелами, пропитанными энергией звезд.",
    "item__W_StarShot__lore": "Вырезанный из небесных фрагментов, этот лук выпускает каскад искрящихся снарядов, небесное зрелище разрушения.",
    "item__W_StarShot__name": "Звездный Выстрел",
    "item__W_StarWand__gameplay": "Излучает мощь звезд на ваших оппонентов.",
    "item__W_StarWand__lore": "Капризный и блестящий инструмент.",
    "item__W_StarWand__name": "Звездная Палочка",
    "item__W_SteelCrossbow__gameplay": "Выстреливает арбалетными болтами, проникающими даже сквозь самые толстые шкуры.",
    "item__W_SteelCrossbow__lore": "Стандартный арбалет, сделанный из стали.",
    "item__W_SteelCrossbow__name": "Стальной Арбалет",
    "item__W_Stick__gameplay": "Выстреливает искрами магической энергии.",
    "item__W_Stick__lore": "Их много, но этот - мой!",
    "item__W_Stick__name": "Палка",
    "item__W_TheCrossBow__gameplay": "Выстреливает священными снарядами с высокой скоростью.",
    "item__W_TheCrossBow__lore": "Доставьте правосудие лорду с помощью этого легендарного арбалета.",
    "item__W_TheCrossBow__name": "Перекрестный Арбалет",
    "item__W_ToxicWand__gameplay": "Выстреливает токсичным шаром.",
    "item__W_ToxicWand__lore": "Это может вызвать привыкание, но вы должны знать, что это токсично.",
    "item__W_ToxicWand__name": "Токсичная Палочка",
    "item__W_Triforce__gameplay": "Излучает силу Треугольника на ваших оппонентов.",
    "item__W_Triforce__lore": "Сияющий том, открывающий божественные силы мужества, мудрости и силы.",
    "item__W_Triforce__name": "Треугольник",
    "item__W_TwistedBranch__gameplay": "Размещает видимую ловушку у ваших ног, наносящую урон молнией.",
    "item__W_TwistedBranch__lore": "Грубая трость, которую очаровал покойный волшебник.",
    "item__W_TwistedBranch__name": "Изогнутая Ветка",
    "item__W_WitchHuntersRepeaters__gameplay": "Выстреливает болтами, пропитанными святым сиянием.",
    "item__W_WitchHuntersRepeaters__lore": "Обычные арбалеты, используемые Охотниками на Ведьм из Арканного Ордена.",
    "item__W_WitchHuntersRepeaters__name": "Арбалет Охотника на Ведьм",
    "item__W_WizardsLedger__gameplay": "БЫСТРО стреляет монетами. Да, монетами.",
    "item__W_WizardsLedger__lore": "Это платная победа?",
    "item__W_WizardsLedger__name": "Записи Волшебника",
    "item__W_WyvernsTalon__gameplay": "Выстреливает мощными болтами, наносящими кровотечение.",
    "item__W_WyvernsTalon__lore": "Арбалет, созданный из когтей великого виверна.",
    "item__W_WyvernsTalon__name": "Коготь Виверны",
    "item__W_ZappingWand__gameplay": "Выстреливает молниями.",
    "item__W_ZappingWand__lore": "Принесите взрыв электрической мощи в свой магический арсенал.",
    "item__W_ZappingWand__name": "Палочка Записи",
    "item_container__loot_chest": "Сундук добычи",
    "item_container__loot_drop": "Падение добычи",
    "item_rarity__common": "Обычный",
    "item_rarity__epic": "Эпический",
    "item_rarity__legendary": "Легендарный",
    "item_rarity__rare": "Редкий",
    "item_rarity__uncommon": "Необычный",
    "common": "Обычный",
    "uncommon": "Необычный",
    "rare": "Редкий",
    "epic": "Эпический",
    "legendary": "Легендарный",
    "item_slot__damage": "урон",
    "item_slot__equip_prompt": "Нажмите левой кнопкой мыши, чтобы экипировать!",
    "item_slot__extract_prompt": "Извлеките, чтобы использовать этот предмет!",
    "item_slot__grants_ability": "Предоставляет способность",
    "item_slot__grants_passive": "Пассивный эффект",
    "item_slot__loot_prompt": "Нажмите левой кнопкой мыши, чтобы собрать добычу!",
    "loadout_menu__abilities_header": "Способности",
    "loadout_menu__back_to_main_menu_button": "Назад",
    "loadout_menu__damage": "Урон",
    "loadout_menu__defence": "Защита",
    "loadout_menu__gear_header": "Снаряжение",
    "loadout_menu__health": "Здоровье",
    "loadout_menu__insurance_button": "Страховка",
    "loadout_menu__no_abilities": "В вашем наборе отсутствуют способности.",
    "loadout_menu__no_slot_selected_insurance_text": "🚨 Ничто не застраховано! 🚨",
    "loadout_menu__no_slot_selected_insurance_text_2": "Ни один слот не застрахован! Посмотрите рекламу, чтобы защитить 1 предмет.",
    "loadout_menu__no_slot_selected_scrollbox": "Выберите слот, чтобы изменить набор",
    "loadout_menu__scrollbox_with_gear_text": "Выберите снаряжение",
    "loadout_menu__scrollbox_with_skins_text": "Выберите скин",
    "loadout_menu__scrollbox_with_weapons_text": "Выберите оружие",
    "loadout_menu__skin_header": "Выберите скин",
    "loadout_menu__slot_insured_text": "✅ У вас есть страховка! ✅",
    "loadout_menu__start_run_button": "Играть",
    "loadout_menu__stat": "Статистика",
    "loadout_menu__title": "Набор",
    "loadout_menu__undo": "Отменить",
    "loadout_menu__weapons_header": "Оружие",
    "loadout_menu__will_be_safe": "будет защищен, если вы умрете.",
    "loadout_menu__your": "Ваш",
    "login__header": "Войти",
    "fullscreen": "Полноэкранный режим",
    "exit_fullscreen": "Выйти из полноэкранного режима",
    "loot_prompt": "[ E ] чтобы собрать добычу",
    "leaderboard__player_kills": "PK",
    "leaderboard__loot": "Добыча",
    "leaderboard__npc_kills": "NPCK",
    "main_menu__patch_notes_btn": "Заметки об обновлении",
    "main_menu__change_username_btn": "Изменить имя пользователя",
    "main_menu__create_account_btn": "Создать аккаунт",
    "main_menu__collection_log_btn": "Журнал коллекций",
    "collection_log__header": "Журнал коллекций",
    "main_menu__discord_btn_tooltip": "Находите друзей и присоединяйтесь к нам в Discord!",
    "main_menu__game_is_in_maintenance_mode": "PvPz.io обновляется! Новое обновление будет установлено в ближайшее время, попробуйте еще раз через несколько минут",
    "main_menu__how_to_play_btn": "Как играть",
    "main_menu__identity_error_title": "Упс",
    "main_menu__loading_bar_text": "Загрузка...",
    "main_menu__logout_btn": "Выйти",
    "main_menu__partners": "Партнеры",
    "main_menu__partners_and_credits": "Все партнеры и заслуги",
    "main_menu__patch_prefix": "Обновление",
    "main_menu__patch_suffix": "выходит 1 июня!",
    "main_menu__play_btn": "Играть",
    "main_menu__rankings_btn": "Рейтинги",
    "main_menu__settings_btn": "Настройки",
    "main_menu__sign_in_btn": "Войти",
    "main_menu__sign_in_with_discord_btn": "Войти через Discord",
    "main_menu__sign_in_with_facebook_btn": "Войти через Facebook",
    "main_menu__sign_in_with_google_btn": "Войти через Google",
    "main_menu__twitter_btn_tooltip": "Подпишитесь на нас в Twitter!",
    "main_menu__vendors_btn": "Торговцы",
    "main_menu__view_inventory_btn": "Инвентарь",
    "main_menu__view_your_stats_btn": "Ваша статистика",
    "main_menu__website_btn_tooltip": "Посетите наш вебсайт!",
    "main_menu__welcome_panel_title": "Добро пожаловать",
    "main_menu__welcome_panel_title_suffix": ",",
    "main_menu__welcome_sign_in_cta": "Хотите изменить имя пользователя? Войдите!",
    "main_menu_stash__collectors_items": "Предметы коллекционера",
    "main_menu_stash__discovered": "Обнаружено",
    "main_menu_stash__gear": "Снаряжение",
    "main_menu_stash__header": "Склад",
    "main_menu_stash__total_items_discovered": "Обнаруженные предметы",
    "main_menu_stash__vendor_items": "Предметы торговцев",
    "main_menu_stash__weapons": "Оружие",
    "player_skins__boltman": "Лучник",
    "player_skins__corrupted_wizard": "Искаженный волшебник",
    "player_skins__knight": "Рыцарь",
    "player_skins__red_wizard": "Ученик волшебника",
    "player_skins__thief": "Вор",
    "player_skins__veteran_wizard": "Волшебник Драконьей Школы",
    "rankings__back_to_main_menu_button": "Назад",
    "rankings__coming_soon": "Скоро!",
    "rankings__title": "Рейтинги",
    "region__dreamers_forest": "Лес Мечтателей",
    "region__ert_lich": "Ирт Лич",
    "region__erthel": "Эртель",
    "region__forgotten_temple": "Забытый храм",
    "region__skull_lake": "Озеро Черепов",
    "region__swamp_of_sorrows": "Болото Печали",
    "region__the_edgelands": "Граничные земли",
    "region__wilderness": "Дикая местность",
    "dreamers forest": "Лес Мечтателей",
    "ert lich": "Ирт Лич",
    "erthel": "Эртель",
    "forgotten temple": "Забытый храм",
    "skull lake": "Озеро Черепов",
    "swamp of sorrows": "Болото Печали",
    "edgelands": "Граничные земли",
    "wilderness": "Дикая местность",
    "register__delete_account_cta": "Вы являетесь анонимным пользователем, поэтому ваша учетная запись будет удалена через 2 недели! Если вы хотите сохранить свои предметы и статистику, а также изменить имя пользователя, пожалуйста, зарегистрируйтесь!",
    "register__delete_account_cta_2": "Вы являетесь анонимным пользователем, поэтому вы не можете просматривать статистику! Если вы хотите увидеть свою статистику, изменить имя пользователя и разблокировать особые скины, пожалуйста, зарегистрируйтесь!",
    "register__delete_account_cta_less_aggressive": "Если вы хотите сохранить свои предметы и статистику навсегда, а также изменить имя пользователя, пожалуйста, зарегистрируйтесь!",
    "register__email": "Введите адрес электронной почты",
    "register__email_confirm": "Подтвердите адрес электронной почты",
    "register__email_placeholder": "Адрес электронной почты",
    "register__email_placeholder_confirm": "Подтвердите адрес электронной почты",
    "register__header": "Регистрация",
    "register__or": "Или",
    "register__password": "Введите пароль",
    "register__password_confirm": "Подтвердите пароль",
    "register__register": "Зарегистрироваться",
    "register__register_with_discord_btn": "Зарегистрироваться через Discord",
    "register__register_with_google_btn": "Зарегистрироваться через Google",
    "register__username": "Выберите имя пользователя",
    "runprep__skin_not_unlocked_registered": "Только ЗАРЕГИСТРИРОВАННЫЕ ПОЛЬЗОВАТЕЛИ могут использовать этот скин!",
    "settings__back_to_main_menu_button": "Назад",
    "settings__enable_music": "Включить музыку",
    "settings__enable_sfx": "Включить звуковые эффекты",
    "settings__language": "Язык / LANGUAGE",
    "settings__language_dropdown_english": "Английский / English",
    "settings__language_dropdown_portuguese": "Португальский / Portuguese / Português",
    "settings__language_dropdown_russian": "Русский / Russian",
    "settings__language_dropdown_spanish": "Испанский / Spanish / Español",
    "settings__matchmaking_region": "Регион подбора игроков",
    "settings__matchmaking_region_dropdown_africa": "Африка",
    "settings__matchmaking_region_dropdown_any": "Любой регион (самый быстрый)",
    "settings__matchmaking_region_dropdown_asia": "Азия",
    "settings__matchmaking_region_dropdown_australia_oceania": "Австралия/Океания",
    "settings__matchmaking_region_dropdown_eu": "Европа",
    "settings__matchmaking_region_dropdown_middle_east": "Ближний Восток",
    "settings__matchmaking_region_dropdown_na_east": "Северная Америка (Восток)",
    "settings__matchmaking_region_dropdown_na_west": "Северная Америка (Запад)",
    "settings__matchmaking_region_dropdown_south_america": "Южная Америка",
    "settings__save_button": "Сохранить",
    "settings__title": "Настройки",
    "settings__settings_saved": "Настройки сохранены!",
    "signin_sign_in": "Войти",
    "signin_sign_in_with_discord_btn": "Войти через Discord",
    "signin_sign_in_with_google_btn": "Войти через Google",
    "item_type__weapon": "Оружие",
    "item_type__gear": "Снаряжение",
    "item_type__vendor": "Предмет для торговцев",
    "item_type__collector": "Специальный предмет",
    "item_type__unknown": "Неизвестно",
    "stat_type_arcane": "Магия",
    "stat_type_bleed": "Кровотечение",
    "stat_type_chaos": "Хаос",
    "stat_type_fire": "Огонь",
    "stat_type_frost": "Мороз",
    "stat_type_holy": "Святость",
    "stat_type_lightning": "Молния",
    "stat_type_nature": "Природа",
    "stat_type_physical": "Физический",
    "stat_type_shadow": "Тень",
    "stat_type_toxic": "Токсичность",
    "stats__back_btn": "Назад",
    "stats__boss_kills": "Убийства боссов",
    "stats__common_items_looted": "обычные предметы собраны",
    "stats__distanced_travelled": "пройденное расстояние",
    "stats__epic_items_looted": "эпические предметы собраны",
    "stats__failed_runs": "неудачные попытки",
    "stats__global_rank": "глобальное место",
    "stats__gold_looted": "собрано золота",
    "stats__gold_lost_to_deaths": "потеряно золота при смерти",
    "stats__header": "Статистика",
    "stats__items_looted": "собранные предметы",
    "stats__items_lost_to_deaths": "потерянные предметы при смерти",
    "stats__legendary_items_looted": "собранные легендарные предметы",
    "stats__npc_kills": "убийства NPC",
    "stats__player_kills": "убийства игроков",
    "stats__rare_items_looted": "собранные редкие предметы",
    "stats__run_success_percentage": "процент успешных попыток",
    "stats__runs": "попытки",
    "stats__successful_runs": "успешные попытки",
    "stats__time_played": "время игры",
    "stats__uncommon_items_looted": "собранные необычные предметы",
    "toast__email_doesnt_match": "Адреса электронной почты не совпадают!",
    "toast__email_not_long_enough": "Адрес электронной почты слишком короткий!",
    "toast__gods_granted_you_items": "Боги дали вам некоторое базовое снаряжение!",
    "toast__not_an_email_address": "Похоже, это не настоящий адрес электронной почты!",
    "toast__password_not_long_enough": "Пароль слишком короткий!",
    "toast__passwords_dont_match": "Пароли не совпадают!",
    "toast__username_changed": "Имя пользователя успешно изменено!",
    "toast__username_too_short": "Имя пользователя слишком короткое!",
    "vendors__back_to_main_menu_button": "Назад",
    "vendors__coming_soon": "Скоро!",
    "vendors__title": "Торговцы",
    "event_feed__looted_a": "получил(а)",
    "event_feed__in_the": "в",
    "event_feed__at": "на",
    "event_feed__killed": "убил",
    "event_feed__boss": "босса",
    "event_feed__is_extracting": "извлекает",
    "event_feed__has_extracted": "успешно извлёк!",
    "event_feed__has_spawned": "появился в",
    "event_feed__under_attack": "атакуют в"
}

import { AnimatedSprite, Container, Graphics, Texture, Text } from "pixi.js";
import { SharedAIEntity } from "../../shared/entities/SharedAIEntity";
import { enemyConfig } from "../../shared/config/Config_Enemy";
import { AIEntityCreationPayload } from "../../shared/SharedNetcodeSchemas";
import { IRenderedEntity } from "../ClientTypes";
import { EnemyClass, EnemyConfig, EnemySkin, EnemySkinData } from "../../shared/data/Data_Enemies";
import { EnemyState } from "../../shared/data/Data_EnemyBehaviourStates";

export class ClientAIEntity extends SharedAIEntity implements IRenderedEntity {
    private debugPositionText: Text;
    private debugHitboxCollider: Graphics;
    private debugCenterpoint: Graphics;
    private debugStateText: Text;
    private debugAggroCollider: Graphics;
    private debugMaxChaseCollider: Graphics;
    private debugTargetPosition: Graphics;
    private debugPathfindingNodes: Graphics;
    private debugRotatingVisualsParent: Container = new Container();
    private debugPathfindingVisualParent: Container = new Container();

    private clientLastFrameX: number;
    private clientLastFrameY: number;

    private wasDeadOnInit: boolean = false;

    public RenderedEntity: Container = new Container();
    private walkFrames: Texture[];
    private idleFrames: Texture[];
    private attackFrames: Texture[];
    protected characterSprite: AnimatedSprite;
    private healthBarContainer: Container = new Container();
    private healthBarBackground: Graphics;
    private healthBarForeground: Graphics;
    private readonly healthBarWidth: number = 25;
    private readonly healthBarHeight: number = 3;
    private readonly healthBarHeightOffset: number = 20;
    private readonly animationSpeed: number = 1 / 9;

    private lastKnownLivelihoodState: boolean = true;
    private lastKnownHealthPercentage: number = 1;
    private lastTookDamageTimestamp: number = 0;
    private readonly damageTakenFlashDurationInMilliseconds: number = 250;
    private readonly damageTakenFlashColor: number = 0xff0000;
    private readonly timeBeforeHealthBarFadesInMilliseconds: number = 1500;

    public enemySkin: EnemySkin;
    private wasMovingLastFrame: boolean;
    private isMovingThisFrame: boolean;
    private isAttacking: boolean;

    protected nameText: Text;
    protected healthText: Text;
    protected characterSpriteIdleAnimationId: string;
    protected characterSpriteWalkAnimationId: string;
    protected characterSpriteAttackAnimationId: string;
    protected characterSpriteYOffset: number;
    protected characterSpriteScale: number;

    public constructor(createAIEntityPayload: AIEntityCreationPayload) {
        const { nid, x, y, isAlive, currentHealthPercentage, enemySkin, currentState, spawnX, spawnY, targetX, targetY, attackedThisFrame } = createAIEntityPayload;

        super(enemySkin);

        this.nid = nid;

        this.enemySkin = enemySkin;

        // console.warn("Set enemy config for enemy:", this.enemySkinData);

        this.characterSpriteIdleAnimationId = this.enemySkinData.idleFramesSpriteId;
        this.characterSpriteWalkAnimationId = this.enemySkinData.walkFramesSpriteId;
        this.characterSpriteAttackAnimationId = this.enemySkinData.attackFramesSpriteId;
        this.characterSpriteYOffset = this.enemySkinData.spriteYOffset;
        this.characterSpriteScale = this.enemySkinData.spriteScale;
        this.healthBarHeightOffset = this.enemySkinData.spriteYOffset + 22;

        this.x = x;
        this.y = y;
        this.RenderedEntity.x = x;
        this.RenderedEntity.y = y;
        this.RenderedEntity.zIndex = y;
        this.spawnPosition.x = spawnX;
        this.spawnPosition.y = spawnY;
        this.targetPosition.x = targetX;
        this.targetPosition.y = targetY;
        this.attackedThisFrame = attackedThisFrame;
        // this.debug_pathfindingTileIndices = debug_pathfindingTileIndices;

        this.currentState = currentState;

        this.isAlive = isAlive;
        if (this.isAlive === false) {
            this.wasDeadOnInit = true;
        }
        this.lastKnownLivelihoodState = isAlive;
        this.currentHealthPercentage = currentHealthPercentage;

        this._setupCharacterVisuals();
        this._setupDebugVisuals();

        this.RenderedEntity.addChild(this.debugRotatingVisualsParent);
        this.RenderedEntity.addChild(this.debugPathfindingVisualParent);

        // Hide dead entities when they are first created (as this is indicative of them already being in thet process of being destroyed, no point in showing them on this client)
        if (this.isAlive === false) {
            this._die();
        }
    }

    private _die(): void {
        // console.log("Enemy died!");
        Game.Renderer.PlayOneOffPfx(this.x, this.y + this.characterSpriteYOffset, "pfx/death/effect_death", 1, 1, 1);
        this.lastKnownLivelihoodState = false;
        this._hideVisuals();
    }

    private _setupCharacterVisuals(): void {
        //#region Enemy Character
        /****************************************/
        /*                                      */
        /*             Character                */
        /*                                      */
        /****************************************/

        const idleAnimation = Game.Loader.GetAnimation(this.characterSpriteIdleAnimationId);
        const movingAnimation = Game.Loader.GetAnimation(this.characterSpriteWalkAnimationId);
        const attackAnimation = Game.Loader.GetAnimation(this.characterSpriteAttackAnimationId);

        this.idleFrames = new Array<Texture>();
        for (let i = 0; i < idleAnimation.length; i++) {
            this.idleFrames.push(Texture.from(idleAnimation[i]));
        }

        this.walkFrames = new Array<Texture>();
        for (let i = 0; i < movingAnimation.length; i++) {
            this.walkFrames.push(Texture.from(movingAnimation[i]));
        }

        this.attackFrames = new Array<Texture>();
        for (let i = 0; i < attackAnimation.length; i++) {
            this.attackFrames.push(Texture.from(attackAnimation[i]));
        }
        this.characterSprite = new AnimatedSprite(this.idleFrames);
        this.characterSprite.position.y = this.characterSpriteYOffset;
        this.characterSprite.scale.set(this.characterSpriteScale);
        this.characterSprite.anchor.set(0.5, 0.5);
        this.characterSprite.animationSpeed = this.animationSpeed;
        this.characterSprite.play();
        this.RenderedEntity.addChild(this.characterSprite);
        //#endregion

        //#region Username
        /****************************************/
        /*                                      */
        /*              Name                    */
        /*                                      */
        /****************************************/
        if (this.enemySkinData.enemyClass === EnemyClass.Boss) {
            this.nameText = new Text(this.enemySkinData.friendlyName, {
                fontFamily: "alagardmedium",
                fontSize: 96,
                fill: 0x8b0000,
                dropShadow: true,
                dropShadowDistance: 10
            });
            this.nameText.scale.set(0.15);
            this.nameText.anchor.set(0.5, 0.5);
            if (this.enemySkin === EnemySkin.GRONK) {
                this.nameText.y += this.enemySkinData.spriteYOffset - 25;
            } else if (this.enemySkin === EnemySkin.IronFox) {
                this.nameText.y += this.enemySkinData.spriteYOffset - 35;
            } else {
                this.nameText.y += this.enemySkinData.spriteYOffset - 20;
            }
            this.RenderedEntity.addChild(this.nameText);
        }
        //#endregion

        //#region Health Text
        /****************************************/
        /*                                      */
        /*              Health Text             */
        /*                                      */
        /****************************************/
        if (Game.Renderer.MyLocalEntityHasInformationPassive) {
            this.healthText = new Text(`${Math.ceil(this.currentHealthPercentage * 100)}/100`, {
                fontFamily: "alagardmedium",
                fontSize: 56,
                fill: 0xffffff
            });
            this.healthText.scale.set(0.15);
            this.healthText.anchor.set(0.5, 0.5);
            if (this.enemySkin === EnemySkin.GiantRat) {
                this.healthText.y -= 20;
            } else {
                this.healthText.y -= 35;
            }
            this.RenderedEntity.addChild(this.healthText);
        }
        //#endregion

        //#region Health Bar
        /****************************************/
        /*                                      */
        /*              Healthbar               */
        /*                                      */
        /****************************************/
        // Create the healthBarBackground rectangle
        this.healthBarBackground = new Graphics();
        this.healthBarBackground.beginFill(0x000000);
        this.healthBarBackground.drawRect(0 - this.healthBarWidth / 2, this.healthBarHeightOffset, this.healthBarWidth, this.healthBarHeight);
        this.healthBarBackground.endFill();
        this.healthBarContainer.addChild(this.healthBarBackground);

        // Create the foreground rectangle
        this.healthBarForeground = new Graphics();
        this.healthBarForeground.beginFill(0xff0000);
        this.healthBarForeground.drawRect(0 - this.healthBarWidth / 2, this.healthBarHeightOffset, this.healthBarWidth, this.healthBarHeight);
        this.healthBarForeground.endFill();
        this.healthBarContainer.addChild(this.healthBarForeground);

        // Position the healthBarForeground rectangle relative to the healthBarBackground rectangle
        this.healthBarForeground.position.x = this.healthBarBackground.position.x;
        this.healthBarForeground.position.y = this.healthBarBackground.position.y;

        // Set the mask for the healthBarForeground rectangle to be a rectangle with the same dimensions as the background rectangle
        this.healthBarForeground.mask = new Graphics();
        (this.healthBarForeground.mask as Graphics).beginFill(0xffffff);
        (this.healthBarForeground.mask as Graphics).drawRect(0 - this.healthBarWidth / 2, this.healthBarHeightOffset, this.healthBarWidth, this.healthBarHeight);
        (this.healthBarForeground.mask as Graphics).endFill();
        this.healthBarContainer.addChild(this.healthBarForeground.mask);

        if (Game.Renderer.MyLocalEntityHasInformationPassive === false) {
            this.healthBarContainer.alpha = 0;
        }

        if (this.enemySkinData.enemyClass === EnemyClass.Boss) {
            this.healthBarContainer.scale.set(1.5);
            this.healthBarContainer.y += 30;

            if (this.healthText !== undefined) {
                this.healthText.scale.set(0.3);
                this.healthText.y += 50;
            }
        }

        this.RenderedEntity.addChild(this.healthBarContainer);
        //#endregion
    }

    private _setupDebugVisuals(): void {
        if (enemyConfig.DEBUG.DRAW_HITBOX_COLLIDER) {
            this.debugHitboxCollider = new Graphics();
            // this.debugHitboxCollider.beginFill(0x000000);
            this.debugHitboxCollider.lineStyle(1, 0x000000);
            this.debugHitboxCollider.drawRect(0 - this.enemySkinData.hitboxWidth / 2, 0 - this.enemySkinData.hitboxHeight / 2 + this.enemySkinData.colliderYOffset, this.enemySkinData.hitboxWidth, this.enemySkinData.hitboxHeight);
            this.debugHitboxCollider.endFill();
            this.RenderedEntity.addChild(this.debugHitboxCollider);
        }

        if (enemyConfig.DEBUG.DRAW_CENTERPOINT) {
            this.debugCenterpoint = new Graphics();
            this.debugCenterpoint.beginFill(0xffffff);
            this.debugCenterpoint.drawCircle(0, 0, 2);
            this.debugCenterpoint.endFill();
            this.debugRotatingVisualsParent.addChild(this.debugCenterpoint);
        }

        if (enemyConfig.DEBUG.DRAW_POSITION_TEXT) {
            this.debugPositionText = new Text(`${Math.floor(this.x)}x, ${Math.floor(this.y)}y`, {
                fontFamily: "alagardmedium",
                fontSize: 64,
                fill: 0xffffff
            });
            this.debugPositionText.scale.set(0.1);
            this.debugPositionText.anchor.set(0.5, 0.5);
            this.debugPositionText.y += 30;
            this.RenderedEntity.addChild(this.debugPositionText);
        }

        if (enemyConfig.DEBUG.DRAW_BEHAVIOUR_STATE) {
            this.debugStateText = new Text(`State: ${EnemyState[this.currentState]}`, {
                fontFamily: "alagardmedium",
                fontSize: 64,
                fill: 0xffffff
            });
            this.debugStateText.scale.set(0.1);
            this.debugStateText.anchor.set(0.5, 0.5);
            this.debugStateText.y += 40;
            this.RenderedEntity.addChild(this.debugStateText);
        }

        if (enemyConfig.DEBUG.DRAW_AGGRO_RADIUS) {
            this.debugAggroCollider = new Graphics();
            this.debugAggroCollider.lineStyle(1, 0xff0000);
            this.debugAggroCollider.drawCircle(0, 0, enemyConfig.AGGRO_COLLIDER_RADIUS);
            this.debugAggroCollider.endFill();
            this.RenderedEntity.addChild(this.debugAggroCollider);
        }

        if (enemyConfig.DEBUG.DRAW_MAX_CHASE_RADIUS) {
            this.debugMaxChaseCollider = new Graphics();
            this.debugMaxChaseCollider.lineStyle(1, 0x00ff00);
            this.debugMaxChaseCollider.drawCircle(0, 0, enemyConfig.MAX_CHASE_OUTER_COLLIDER_RADIUS);
            this.debugMaxChaseCollider.endFill();
            this.debugMaxChaseCollider.lineStyle(1, 0x00ff00);
            this.debugMaxChaseCollider.drawCircle(0, 0, enemyConfig.MAX_CHASE_INNER_COLLIDER_RADIUS);
            this.debugMaxChaseCollider.endFill();
            this.RenderedEntity.addChild(this.debugMaxChaseCollider);
        }

        if (enemyConfig.DEBUG.DRAW_BEHAVIOUR_DESTINATION) {
            this.debugTargetPosition = new Graphics();
            this.debugTargetPosition.lineStyle(1, 0x00ff00);
            this.debugTargetPosition.lineTo(this.targetPosition.x, this.targetPosition.y);
            this.debugTargetPosition.endFill();
            this.RenderedEntity.addChild(this.debugTargetPosition);
        }

        if (enemyConfig.DEBUG.DRAW_PATHFINDING_GRID) {
            this.debugPathfindingNodes = new Graphics();
            this.debugPathfindingVisualParent.addChild(this.debugPathfindingNodes);
        }
    }

    protected _showVisuals(): void {
        this.RenderedEntity.alpha = 1;
    }

    protected _fadeVisuals(): void {
        this.RenderedEntity.alpha = 0.3;
    }

    protected _hideVisuals(): void {
        this.RenderedEntity.alpha = 0;
    }

    // TODO make damage flashes better, likely via the pixi-heaven plugin
    private _triggerDamageFlash(): void {
        this.characterSprite.tint = this.damageTakenFlashColor;
    }

    private _undoDamageFlash(): void {
        this.characterSprite.tint = 0xffffff;
    }

    protected _updateCharacterVisuals(deltaTime: number, currentTimeInMilliseconds: number): void {
        if (this.currentHealthPercentage !== this.lastKnownHealthPercentage) {
            this.healthBarContainer.alpha = 1;

            this._triggerDamageFlash();

            this.lastTookDamageTimestamp = currentTimeInMilliseconds;
            this.lastKnownHealthPercentage = this.currentHealthPercentage;

            if (Game.Renderer.MyLocalEntityHasInformationPassive) {
                if (this.healthText !== undefined) {
                    this.healthText.text = `${Math.ceil(this.currentHealthPercentage * 100)}/100`;
                }
            }
        }

        const timeSinceLastTookDamage = currentTimeInMilliseconds - this.lastTookDamageTimestamp;

        if (timeSinceLastTookDamage > this.timeBeforeHealthBarFadesInMilliseconds && Game.Renderer.MyLocalEntityHasInformationPassive === false) {
            this.healthBarContainer.alpha -= deltaTime;
        }

        if (timeSinceLastTookDamage > this.damageTakenFlashDurationInMilliseconds) {
            this._undoDamageFlash();
        }

        if (this.x !== this.clientLastFrameX || this.y !== this.clientLastFrameY) {
            this.isMovingThisFrame = true;
        } else {
            this.isMovingThisFrame = false;
        }

        this.RenderedEntity.x = this.x;
        this.RenderedEntity.y = this.y;
        this.RenderedEntity.zIndex = this.y;

        if (this.wasMovingLastFrame === true && this.isMovingThisFrame === false) {
            this.characterSprite.textures = this.idleFrames;
            this.characterSprite.play();
        }

        if (this.wasMovingLastFrame === false && this.isMovingThisFrame === true) {
            this.characterSprite.textures = this.walkFrames;
            this.characterSprite.play();
        }

        if (this.attackedThisFrame === true) {
            this.isAttacking = true;
            this.characterSprite.loop = false;
            this.characterSprite.textures = this.attackFrames;
            this.characterSprite.onComplete = this.onAttackAnimComplete.bind(this);
            this.characterSprite.play();
        }

        if (this.healthBarForeground !== undefined) {
            (this.healthBarForeground.mask as Graphics).width = this.currentHealthPercentage * this.healthBarWidth;
        }

        // Strictly greater than for movement direction change, for no movement (same x) stay facing the same direction
        if (this.x > this.clientLastFrameX) {
            this.characterSprite.scale.x = this.characterSpriteScale; // Left
        } else if (this.x < this.clientLastFrameX) {
            this.characterSprite.scale.x = -this.characterSpriteScale; // Right
        }

        this.clientLastFrameX = this.x;
        this.clientLastFrameY = this.y;
        this.wasMovingLastFrame = this.isMovingThisFrame;
    }

    private onAttackAnimComplete(): void {
        if (this.attackedThisFrame === true) {
            this.isAttacking = true;
            this.characterSprite.loop = false;
            this.characterSprite.textures = this.attackFrames;
            this.characterSprite.play();
            this.characterSprite.onComplete = this.onAttackAnimComplete.bind(this);
        } else if (this.isMovingThisFrame === false) {
            this.isAttacking = false;
            this.characterSprite.loop = true;
            this.characterSprite.textures = this.idleFrames;
            this.characterSprite.play();
            this.characterSprite.onComplete = undefined;
        } else if (this.isMovingThisFrame === true) {
            this.isAttacking = false;
            this.characterSprite.loop = true;
            this.characterSprite.textures = this.walkFrames;
            this.characterSprite.play();
            this.characterSprite.onComplete = undefined;
        }
    }

    protected _updateDebugVisuals(deltaTime: number) {
        if (enemyConfig.DEBUG.DRAW_POSITION_TEXT) {
            this.debugPositionText.text = `${Math.floor(this.x)}x, ${Math.floor(this.y)}y`;
        }
        if (enemyConfig.DEBUG.DRAW_BEHAVIOUR_STATE) {
            this.debugStateText.text = `State: ${EnemyState[this.currentState]}`;
        }
        if (enemyConfig.DEBUG.DRAW_MAX_CHASE_RADIUS) {
            this.debugMaxChaseCollider.x = this.spawnPosition.x - this.x;
            this.debugMaxChaseCollider.y = this.spawnPosition.y - this.y;
        }

        if (enemyConfig.DEBUG.DRAW_BEHAVIOUR_DESTINATION) {
            this.debugTargetPosition.clear();
            this.debugTargetPosition.lineStyle(1, this.hasLineOfSightWithTarget ? 0x00ff00 : 0xff0000);
            this.debugTargetPosition.lineTo(this.targetPosition.x - this.x, this.targetPosition.y - this.y);
            this.debugTargetPosition.endFill();
        }
        if (enemyConfig.DEBUG.DRAW_PATHFINDING_GRID) {
            // World space
            this.debugPathfindingVisualParent.x = -this.x;
            this.debugPathfindingVisualParent.y = -this.y;
        }
    }

    private _updatePathfindingVisuals() {
        // if (enemyConfig.DEBUG.DRAW_PATHFINDING_GRID && this.debugPathfindingNodes != null) {
        //     this.debugPathfindingNodes.clear();
        //     const worldWidth = worldConfig.WORLD_SIZE / pathfindingConfig.TILE_SIZE;
        //     const worldHeight = worldConfig.WORLD_SIZE / pathfindingConfig.TILE_SIZE;
        //     for (let i = 0; i < this._debug_pathfindingTileIndices.length; i++) {
        //         const tileIndex = this._debug_pathfindingTileIndices[i];
        //         const column = Math.floor(tileIndex % worldWidth);
        //         const row = Math.floor(tileIndex / worldHeight);
        //         const xPosition = column * pathfindingConfig.TILE_SIZE;
        //         const yPosition = row * pathfindingConfig.TILE_SIZE;
        //         this.debugPathfindingNodes.lineStyle(2, 0x0000ff);
        //         this.debugPathfindingNodes.beginFill(0x000099);
        //         this.debugPathfindingNodes.drawCircle(xPosition + 8, yPosition + 8, 4);
        //         this.debugPathfindingNodes.endFill();
        //     }
        // }
    }

    // public override set debug_pathfindingTileIndices(newTileIndices: number[]) {
    //     this._debug_pathfindingTileIndices = newTileIndices;
    //     this._updatePathfindingVisuals();
    // }

    public override Update(deltaTime: number, currentTimeInMilliseconds: number): void {
        if (this.wasDeadOnInit === true) {
            return;
        }

        if (this.isAlive === false && this.lastKnownLivelihoodState === true) {
            this._die();
            return;
        }

        super.Update(deltaTime);

        this._updateCharacterVisuals(deltaTime, currentTimeInMilliseconds);
        this._updateDebugVisuals(deltaTime);
    }
}

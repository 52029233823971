import React from "react";
import { Screens, UI_SwapScreen, justCollectorsItemsFromStashSelector, justGearFromStashSelector, justVendorTrashFromStashSelector, justWeaponsFromStashSelector, playerIdentityStateAtom, playerInventoryStateAtom } from "../framework/UI_State";
import { useRecoilValue } from "recoil";
import { ItemContainer } from "../components/UI_ItemContainer";
import { ItemContainerMap, getTotalNumberOfCollectorsItems, getTotalNumberOfGearItems, getTotalNumberOfVendorTrashItems, getTotalNumberOfWeaponItems, quantityOfAllItems, quantityOfAllCommonItems, quantityOfAllUncommonItems, quantityOfAllRareItems, quantityOfAllEpicItems, quantityOfAllLegendaryItems, ItemRarity, getItemData } from "../../../shared/data/Data_Items";
import { t } from "../../../shared/data/Data_I18N";
import { UserType } from "../../../shared/SharedTypes";

const getQuantityOfItems = (items: ItemContainerMap): number => {
    let q = 0;
    for (const quantity of Object.values(items)) {
        if (quantity > 0) q++;
    }
    return q;
};

const getQuantityOfItemsOfRarity = (rarity: ItemRarity, items: ItemContainerMap): number => {
    let q = 0;
    for (const [itemID, quantity] of Object.entries(items)) {
        if (quantity === 0) continue;

        const itemRarity = getItemData(parseInt(itemID))!.itemRarity;

        if (itemRarity === rarity) {
            q++;
        }
    }
    return q;
};

export const Inventory = () => {
    const { userType } = useRecoilValue(playerIdentityStateAtom);
    const { items: allItemsInStash } = useRecoilValue(playerInventoryStateAtom);
    const justWeaponsFromStash = useRecoilValue(justWeaponsFromStashSelector);
    const justGearFromStash = useRecoilValue(justGearFromStashSelector);
    const justVendorTrashFromStash = useRecoilValue(justVendorTrashFromStashSelector);
    const justCollectorsItemsFromStash = useRecoilValue(justCollectorsItemsFromStashSelector);

    const numberOfWeapons = getQuantityOfItems(justWeaponsFromStash);
    const numberOfGear = getQuantityOfItems(justGearFromStash);
    const numberOfVendorTrash = getQuantityOfItems(justVendorTrashFromStash);
    const numberOfCollectorsItems = getQuantityOfItems(justCollectorsItemsFromStash);

    const quantityOfCommonItems = getQuantityOfItemsOfRarity(ItemRarity.Common, allItemsInStash);
    const quantityOfUncommonItems = getQuantityOfItemsOfRarity(ItemRarity.Uncommon, allItemsInStash);
    const quantityOfRareItems = getQuantityOfItemsOfRarity(ItemRarity.Rare, allItemsInStash);
    const quantityOfEpicItems = getQuantityOfItemsOfRarity(ItemRarity.Epic, allItemsInStash);
    const quantityOfLegendaryItems = getQuantityOfItemsOfRarity(ItemRarity.Legendary, allItemsInStash);

    const quantityOfAllObtainedItems = quantityOfCommonItems + quantityOfUncommonItems + quantityOfRareItems + quantityOfEpicItems + quantityOfLegendaryItems;

    return (
        <div className="inventory-panel animate__animated animate__fadeInUp animate__faster">
            <div className="inventory-header">{t("main_menu_stash__header")}</div>

            <div className="totals">
                <div className="all-total">
                    <div className="rarity-total">
                        {t("main_menu_stash__total_items_discovered")}: {quantityOfAllObtainedItems} / {quantityOfAllItems}
                    </div>
                </div>
                <div className="rarity-totals">
                    <div className="rarity-total">
                        <span className="common">{t("item_rarity__common")}</span> {t("main_menu_stash__discovered")}:
                        <br />
                        {quantityOfCommonItems} / {quantityOfAllCommonItems}
                    </div>
                    <div className="rarity-total">
                        <span className="uncommon">{t("item_rarity__uncommon")}</span> {t("main_menu_stash__discovered")}:
                        <br />
                        {quantityOfUncommonItems} / {quantityOfAllUncommonItems}
                    </div>
                    <div className="rarity-total">
                        <span className="rare">{t("item_rarity__rare")}</span> {t("main_menu_stash__discovered")}:
                        <br />
                        {quantityOfRareItems} / {quantityOfAllRareItems}
                    </div>
                    <div className="rarity-total">
                        <span className="epic">{t("item_rarity__epic")}</span> {t("main_menu_stash__discovered")}:
                        <br />
                        {quantityOfEpicItems} / {quantityOfAllEpicItems}
                    </div>
                    <div className="rarity-total">
                        <span className="legendary">{t("item_rarity__legendary")}</span> {t("main_menu_stash__discovered")}:
                        <br />
                        {quantityOfLegendaryItems} / {quantityOfAllLegendaryItems}
                    </div>
                </div>
            </div>

            <div className="items">
                <div className="left-col">
                    <div className="inventory-contents weapons">
                        <p className="item-category-title">
                            {t("main_menu_stash__weapons")} {t("main_menu_stash__discovered")}: {numberOfWeapons}/{getTotalNumberOfWeaponItems()}
                        </p>
                        <ItemContainer OnSlotClickFn={() => {}} items={justWeaponsFromStash} />
                    </div>
                    <div className="inventory-contents gear">
                        <p className="item-category-title">
                            {t("main_menu_stash__gear")} {t("main_menu_stash__discovered")}: {numberOfGear}/{getTotalNumberOfGearItems()}
                        </p>
                        <ItemContainer OnSlotClickFn={() => {}} items={justGearFromStash} />
                    </div>
                </div>
                <div className="right-col">
                    <div className="inventory-contents vendor-trash">
                        <p className="item-category-title">
                            {t("main_menu_stash__vendor_items")} {t("main_menu_stash__discovered")}: {numberOfVendorTrash}/{getTotalNumberOfVendorTrashItems()}
                        </p>
                        <ItemContainer OnSlotClickFn={() => {}} items={justVendorTrashFromStash} />
                    </div>
                    <div className="inventory-contents collectors-items">
                        <p className="item-category-title">
                            {t("main_menu_stash__collectors_items")} {t("main_menu_stash__discovered")}: {numberOfCollectorsItems}/{getTotalNumberOfCollectorsItems()}
                        </p>
                        <ItemContainer OnSlotClickFn={() => {}} items={justCollectorsItemsFromStash} />
                    </div>
                </div>
            </div>

            <div className="bottom-actions">
                {userType === UserType.Anonymous && <span className="delete-acc-cta">{t("register__delete_account_cta_less_aggressive")}</span>}

                <button
                    className="neutral-button"
                    onClick={() => {
                        UI_SwapScreen(Screens.MainMenu);
                    }}
                >
                    {t("change_username__back")}
                </button>
            </div>
        </div>
    );
};

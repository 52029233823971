import React from "react";
import ReactDOM from "react-dom";
import { Tooltip } from "react-tooltip";
import { t } from "../../../shared/data/Data_I18N";

export const LoadingSpinner = () => {
    const tooltipContainer = document.getElementById("tooltip-container") as Element;
    return (
        <div className="loading-spinner">
            <div className="lds-ring" data-tooltip-id="twitter-tooltip" data-tooltip-content={t("main_menu__loading_bar_text")}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            {ReactDOM.createPortal(<Tooltip id="twitter-tooltip" style={{ fontSize: "32px" }} />, tooltipContainer)}
        </div>
    );
};

/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import ReactDOM from "react-dom";
import { Tooltip } from "react-tooltip";
import { t } from "../../../shared/data/Data_I18N";

export const Socials = () => {
    const tooltipContainer = document.getElementById("tooltip-container") as Element;

    return (
        <div className="socials">
            <div className="social-links-wrapper">
                <a className="social-link twitter" href="https://twitter.com/2ndmonitorgames" rel="noreferrer noopener" target="_blank" data-tooltip-id="twitter-tooltip" data-tooltip-content={t("main_menu__twitter_btn_tooltip")}></a>
                {ReactDOM.createPortal(<Tooltip id="twitter-tooltip" style={{ fontSize: "32px" }} />, tooltipContainer)}

                <a className="social-link discord" href="https://discord.gg/nfmg9mz8GQ" rel="noreferrer noopener" target="_blank" data-tooltip-id="discord-tooltip" data-tooltip-content={t("main_menu__discord_btn_tooltip")}></a>
                {ReactDOM.createPortal(<Tooltip id="discord-tooltip" style={{ fontSize: "32px" }} />, tooltipContainer)}

                {/* <a className="social-link website" href="https://secondmonitorgames.com/" rel="noreferrer noopener" target="_blank" data-tooltip-id="website-tooltip" data-tooltip-content={t("main_menu__website_btn_tooltip")}></a>
                {ReactDOM.createPortal(<Tooltip id="website-tooltip" style={{ fontSize: "32px" }} />, tooltipContainer)} */}
            </div>
        </div>
    );
};

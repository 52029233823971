import React, { useEffect, useRef, useState } from "react";
import { Screens, UI_SwapScreen } from "../framework/UI_State";

// @ts-ignore
import wKeyImage from "../kit/pxkw_w.png";
// @ts-ignore
import upArrowImage from "../kit/pxkw_arrow_up.png";
// @ts-ignore
import aKeyImage from "../kit/pxkw_a.png";
// @ts-ignore
import leftArrowImage from "../kit/pxkw_arrow_left.png";
// @ts-ignore
import sKeyImage from "../kit/pxkw_s.png";
// @ts-ignore
import downArrowImage from "../kit/pxkw_arrow_down.png";
// @ts-ignore
import dKeyImage from "../kit/pxkw_d.png";
// @ts-ignore
import rightArrowImage from "../kit/pxkw_arrow_right.png";
// @ts-ignore
import oneKeyImage from "../kit/pxkw_1.png";
// @ts-ignore
import twoKeyImage from "../kit/pxkw_2.png";
// @ts-ignore
import threeKeyImage from "../kit/pxkw_3.png";
// @ts-ignore
import fourKeyImage from "../kit/pxkw_4.png";
// @ts-ignore
import ikeyImage from "../kit/pxkw_i.png";
// @ts-ignore
import lKeyImage from "../kit/pxkw_l.png";
// @ts-ignore
import fiveKeyImage from "../kit/pxkw_5.png";
// @ts-ignore
import mouseMoveImage from "../kit/mouse-move.png";
// @ts-ignore
import mouseMoveArrow from "../kit/arrow.png";
// @ts-ignore
import mouseClickImage from "../kit/mouse-leftclick.png";
// @ts-ignore
import extractionImage from "../kit/extraction.gif";
import { t } from "../../../shared/data/Data_I18N";

export const HowToPlay = () => {
    const [keyState, updateKeyState] = useState({
        w: false,
        a: false,
        s: false,
        d: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        ArrowUp: false,
        ArrowDown: false,
        ArrowLeft: false,
        ArrowRight: false,
        t: false,
        l: false,
        i: false,
        leftClick: false,
        mouseMove: false
    });

    useEffect(() => {
        const bindKeyPress = (event: KeyboardEvent) => {
            updateKeyState({ ...keyState, [event.key]: true });
        };
        const bindKeyUnpress = (event: KeyboardEvent) => {
            updateKeyState({ ...keyState, [event.key]: false });
        };
        const bindMouseDown = (event: MouseEvent) => {
            if (event.button === 0) {
                updateKeyState({ ...keyState, leftClick: true });
            }
        };
        const bindMouseUp = (event: MouseEvent) => {
            if (event.button === 0) {
                updateKeyState({ ...keyState, leftClick: false });
            }
        };

        document.addEventListener("keydown", bindKeyPress);
        document.addEventListener("keyup", bindKeyUnpress);
        document.addEventListener("mousedown", bindMouseDown);
        document.addEventListener("mouseup", bindMouseUp);

        // 👇️ remove event listeners when the how to play screen unmounts
        return () => {
            document.removeEventListener("keydown", bindKeyPress);
            document.removeEventListener("keyup", bindKeyUnpress);
            document.removeEventListener("mousedown", bindMouseDown);
            document.removeEventListener("mouseup", bindMouseUp);
        };
    }, []);

    return (
        <>
            <div className="how-to-play-panel animate__animated animate__fadeInUp animate__faster">
                <div className="how-to-play-header">{t("how_to_play__title")}</div>

                <p style={{ textAlign: "center", alignSelf: "center", fontSize: "32px" }}>
                    <strong>{t("how_to_play__top_big_paragraph")}</strong>
                </p>

                <p style={{ textAlign: "center", alignSelf: "center" }}>{t("how_to_play__small_paragraph_one")}</p>

                {/* <p style={{ textAlign: "center", alignSelf: "center" }}>{t("how_to_play__small_paragraph_two")}</p> */}

                <p style={{ textAlign: "center", alignSelf: "center" }}>{t("how_to_play__small_paragraph_three")}</p>

                <h2>{t("how_to_play__test_keys_header")}</h2>

                <div className="control-list-parent">
                    <ul className="control-list chonky-control-list">
                        <li className="control">
                            <div className="control-label">
                                <img src={wKeyImage} alt="W Key" className={`${keyState["w"] === true ? "active" : ""}`} />
                                <span>&amp;</span>
                                <img src={upArrowImage} alt="Up Arrow Key" className={`${keyState["ArrowUp"] === true ? "active" : ""}`} /> :
                            </div>
                            <div className="control-description">{t("how_to_play__move_up_key")}</div>
                        </li>

                        <li className="control">
                            <div className="control-label">
                                <img src={aKeyImage} alt="A Key" className={`${keyState["a"] === true ? "active" : ""}`} />
                                <span>&amp;</span>
                                <img src={leftArrowImage} alt="Left Arrow Key" className={`${keyState["ArrowLeft"] === true ? "active" : ""}`} /> :
                            </div>
                            <div className="control-description">{t("how_to_play__move_left_key")}</div>
                        </li>

                        <li className="control">
                            <div className="control-label">
                                <img src={sKeyImage} alt="S Key" className={`${keyState["s"] === true ? "active" : ""}`} />
                                <span>&amp;</span>
                                <img src={downArrowImage} alt="Down Arrow Key" className={`${keyState["ArrowDown"] === true ? "active" : ""}`} /> :
                            </div>
                            <div className="control-description">{t("how_to_play__move_down_key")}</div>
                        </li>

                        <li className="control">
                            <div className="control-label">
                                <img src={dKeyImage} alt="D Key" className={`${keyState["d"] === true ? "active" : ""}`} />
                                <span>&amp;</span>
                                <img src={rightArrowImage} alt="Right Arrow Key" className={`${keyState["ArrowRight"] === true ? "active" : ""}`} /> :
                            </div>
                            <div className="control-description">{t("how_to_play__move_right_key")}</div>
                        </li>

                        <br />

                        <br />

                        <li className="control mouse">
                            <div className="control-label mouse mousedrag">
                                <img src={mouseMoveArrow} className="mousearrow mal" alt="Mouse Move Arrow Left" />
                                <img src={mouseMoveArrow} className="mousearrow mar" alt="Mouse Move Arrow Right" />
                                <img src={mouseMoveArrow} className="mousearrow mau" alt="Mouse Move Arrow Up" />
                                <img src={mouseMoveArrow} className="mousearrow mad" alt="Mouse Move Arrow Down" />
                                <img src={mouseMoveImage} alt="Mouse Move" className={`${keyState["mouseMove"] === true ? "active" : ""}`} />
                            </div>
                            <div className="control-description">: {t("how_to_play__mouse_aim_controls")}</div>
                        </li>
                    </ul>

                    <ul className="control-list">
                        <li className="control">
                            <div className="control-label">
                                <img src={threeKeyImage} alt="3 Key" className={`${keyState["3"] === true ? "active" : ""}`} /> :
                            </div>
                            <div className="control-description">{t("how_to_play__ability_one_key")}</div>
                        </li>

                        <li className="control">
                            <div className="control-label">
                                <img src={fourKeyImage} alt="4 Key" className={`${keyState["4"] === true ? "active" : ""}`} /> :
                            </div>
                            <div className="control-description">{t("how_to_play__ability_two_key")}</div>
                        </li>

                        <li className="control">
                            <div className="control-label">
                                <img className={`${keyState["5"] === true ? "active" : ""}`} src={fiveKeyImage} alt="5 Key" /> :
                            </div>
                            <div className="control-description">{t("how_to_play__ability_three_key")}</div>
                        </li>

                        <li className="control">
                            <div className="control-label">
                                <img src={ikeyImage} alt="i Key" className={`${keyState["i"] === true ? "active" : ""}`} /> :
                            </div>
                            <div className="control-description">{t("how_to_play__inventory_key")}</div>
                        </li>

                        <li className="control">
                            <div className="control-label">
                                <img src={lKeyImage} alt="L Key" className={`${keyState["l"] === true ? "active" : ""}`} /> :
                            </div>
                            <div className="control-description">{t("how_to_play__leaderboard_key")}</div>
                        </li>

                        <br />

                        <br />

                        <li className="control mouse">
                            <div className="control-label mouse">
                                <img src={mouseClickImage} alt="Mouse Move" className={`${keyState["leftClick"] === true ? "active" : ""}`} /> :
                            </div>
                            <div className="control-description">{t("how_to_play__mouse_shoot_controls")}</div>
                        </li>
                    </ul>

                    <ul className="control-list">
                        <li className="control">
                            <div className="control-label">
                                <img src={oneKeyImage} alt="1 Key" className={`${keyState["1"] === true ? "active" : ""}`} /> :
                            </div>
                            <div className="control-description">{t("how_to_play__weapon_one_key")}</div>
                        </li>

                        <li className="control">
                            <div className="control-label">
                                <img src={twoKeyImage} alt="2 Key" className={`${keyState["2"] === true ? "active" : ""}`} /> :
                            </div>
                            <div className="control-description">{t("how_to_play__weapon_two_key")}</div>
                        </li>

                        <li className="control extraction-control">
                            <div className="control-label">
                                <img src={extractionImage} alt="Extraction" className="extraction" /> :
                            </div>
                            <div className="control-description">{t("how_to_play__extraction")}</div>
                        </li>
                    </ul>
                </div>

                <button
                    className="neutral-button"
                    onClick={() => {
                        UI_SwapScreen(Screens.MainMenu);
                    }}
                >
                    {t("how_to_play__back_to_main_menu_button")}
                </button>
            </div>
        </>
    );
};
